
<div class="row pt-2">
    <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card">
        <div class="card-body text-left card-box p-0">
          <!-- <span class="card-text card-news-title display-5 p-3">Trending {{RssData?.rss.channel[0].title[0]}} News</span> -->
          <span class="card-text card-news-title display-5 p-3">Trending {{code}} News</span>

          <div *ngFor="let o of RssData?.rss.channel[0].item; let i=index">
            <div class="news-text-contain p-3" *ngIf="i<5">
                <span class="card-text card-contain d-block">{{o.title[0]}}</span>
                <span class="text-muted d-inline card-subtext"> <span class="dot"> </span> {{getDataDiff(o.pubDate)}}&nbsp;&nbsp;<a href={{o.link[0]}} target="_blank"><i class="bi bi-arrow-up-right-circle-fill"></i></a> </span>
              </div>
          </div>
        </div>
      </div>
    </div>
</div>