import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/storage";
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthenticationserviceService } from '../authenticationservice.service'
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AngularFirestore } from '@angular/fire/firestore';
import { Profile } from '../Profile';
import { async } from '@angular/core/testing';
import { JsonPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})
export class EditprofileComponent implements OnInit {

  emailId;
  displayName;
  //start properties for profile page
  equities;
  options;
  forex;
  futures;
  bonds;
  privateCompanies;


  technical;
  fundamental;
  globalmacro;
  momentum;
  growth;
  value;

  daytrader;
  swingtrader;
  positiontrader;
  longterminvestor;

  novice;
  intermediate;
  professional;

  //end properties for profile page
  currentProfileID;
  PrivateCompanies;
  name = 'Angular 6';
  marked = false;
  theCheckbox = false;
  //constructor() { }
  prof: Profile;
  SaveProfile() {
    //console.log(this.currentProfileID);
    //this.firestore.doc('/profile/' + this.currentProfileID).set({
    this.firestore.doc('/profile/' + this.currentProfileID).update({
      equities: this.equities,
      options: this.options,
      forex: this.forex,
      futures: this.futures,
      bonds: this.bonds,
      privateCompanies: this.privateCompanies,

      technical: this.technical,
      fundamental: this.fundamental,
      globalmacro: this.globalmacro,
      momentum: this.momentum,
      growth: this.growth,
      value: this.value,

      daytrader: this.daytrader,
      swingtrader: this.swingtrader,
      positiontrader: this.positiontrader,
      longterminvestor: this.longterminvestor,

      novice: this.novice,
      intermediate: this.intermediate,
      professional: this.professional,
      'updatedDate': new Date(),
      //emailid:this.authservice.authuser.email,
      //emailid:this.prof.emailid,
      //useruid:this.prof.useruid
    })
      .then(() => {
        //alert("Data saved successfully.");
        this.toastr.success('Saved!', '');
      }).catch((error) => {
        this.toastr.success('Data could not be saved.', error);
        //alert("Data could not be saved." + error);
      })
      ;

    //this.toastr.success('Saved!', ''); 
  }
  toggleVisibility(e) {
    this.marked = e.target.checked;
  }
  ngOnInit(): void {
    this.downloadURL = this.authservice.imageUrl;
    this.emailId = this.authservice.authuser.email;
    this.displayName = this.authservice.authuser.displayName;
    this.firestore.collection<Profile>('/profile', ref => ref.where("emailid", "==", this.authservice.authuser.email).limit(1))
      .valueChanges({ idField: 'documentid' })
      .subscribe(register => {
        this.prof = register[0];
        this.setFromDB();
      });
  }
  setFromDB() {
    this.equities = this.prof.equities;
    this.options = this.prof.options;
    this.forex = this.prof.forex;
    this.futures = this.prof.futures;
    this.bonds = this.prof.bonds;
    this.privateCompanies = this.prof.privateCompanies;

    this.technical = this.prof.technical;
    this.fundamental = this.prof.fundamental;
    this.globalmacro = this.prof.globalmacro;
    this.momentum = this.prof.momentum;
    this.growth = this.prof.growth;
    this.value = this.prof.value;

    this.daytrader = this.prof.daytrader;
    this.swingtrader = this.prof.swingtrader;
    this.positiontrader = this.prof.positiontrader;
    this.longterminvestor = this.prof.longterminvestor;

    this.novice = this.prof.novice;
    this.intermediate = this.prof.intermediate;
    this.professional = this.prof.professional;


    this.currentProfileID = this.prof.documentid
  }

  setIntoDB() {


  }

  selectedFile: File = null;
  fb;
  downloadURL;//: Observable<string>;
  profile;
  constructor(private storage: AngularFireStorage
    , public authservice: AuthenticationserviceService
    , private toastr: ToastrService
    , public firestore: AngularFirestore) {

  }
}

