
import { Component, OnInit, Input, HostListener,ViewChild,ElementRef} from '@angular/core';
import { analyzeAndValidateNgModules, IfStmt } from '@angular/compiler';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Stock } from '../Stock';
import { AngularFirestore } from '@angular/fire/firestore';
import {Stocks} from '../Stocks'
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject } from 'rxjs';
import { getLocaleDateFormat } from '@angular/common';
import {AuthenticationserviceService} from '../authenticationservice.service';
import {SharedserviceService} from '../sharedservice.service';
import { AngularFireStorage } from "@angular/fire/storage";
import {BSE_Equity} from '../BSE_Equity'
import { positionElements } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { faThumbsUp,faRetweet,faReply,faChartLine,faAngleDoubleUp,faAngleDoubleDown,faArrowRight} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  




//below are in postcontrol

@Input() placeholder:string = "Share a fintwit (use $ for ₹ before ticker:eg ₹SYMBL)";

@ViewChild("tagDiv") tagDivElement: ElementRef;

changecharacter;
symbols: string[] = [];
isuserbackspace:boolean;
ispositionrequired:boolean;
isvisisblecompanylist:boolean;
selectedcompany;
selectedcompanywithLink;
items;
bullbearnutral="Nutral";
likedby: string[] = [];
retweetby: string[] = [];
public focusElement: number = -1;


//below are in fintwitcontrol

posts: Observable<any[]>;

faThumbsUp = faThumbsUp;
  faRetweet=faRetweet;
  faReply=faReply;
  faAngleDoubleUp=faAngleDoubleUp;
  faAngleDoubleDown=faAngleDoubleDown; 
  faArrowRight=faArrowRight;
  faChartLine=faChartLine;


downloadURL;
myEmailId;
falseValue=false;
trueValue=true;
closeResult = '';

  //not used

//itemscopy=Stocks;
//authuser;
//liked:false;


//not used
@ViewChild("tagcompanylist") tagcompanylistElement: ElementRef;

//not used
calltofocus()
{
this.tagDivElement.nativeElement.focus();

}
// @HostListener('mousemove', ['$event'])
// onMousemove(event: MouseEvent): void  { 
//   console.log("x"+event.clientX);
//   console.log("Y"+event.clientY);
// }
constructor(public firestore: AngularFirestore
  ,public firebaseAuth: AngularFireAuth
  ,public sharedserviceService: SharedserviceService
  ,public authservice :AuthenticationserviceService
  ,private storage: AngularFireStorage
  ,private router:Router
  ,private modalService: NgbModal) {
  
  

//   firebaseAuth.authState.subscribe(user => {
//     if (user) {
//       this.authuser=user;
// this.posts = firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", user.email).orderBy('createddate','desc')).valueChanges();

//       return;
//     }
//     this.posts = null;
//   })
}


//can be removed postcontrol
onfocusout()
{
  this.items=null;
}
//can be removed postcontrol
getSelectedCompany(m){
  //var el = document.getElementById("box");
  var el=this.tagDivElement.nativeElement;
  this.selectedcompany=m;
  this.symbols.push(this.selectedcompany);
  let oldtext=el.innerHTML;
  //<a href="www.bar.com" title="Description">₹</a>
  //console.log();
  //this.selectedcompanywithLink="<a href=\"symbol\" title=\""+this.selectedcompany+"\">₹"+this.selectedcompany+"</a>";
  this.selectedcompanywithLink="<a href=\"symbol/"+this.selectedcompany+"\" title=\""+this.selectedcompany+"\">₹"+this.selectedcompany+"</a>";
  //this.selectedcompanywithLink="<a routerLink=\"Gotosymbol/"+this.selectedcompany+"\" title=\""+this.selectedcompany+"\">₹"+this.selectedcompany+"</a>";
  el.innerHTML = oldtext.substring(0,oldtext.lastIndexOf('₹'))+this.selectedcompanywithLink+'&nbsp;';
  this.isvisisblecompanylist=false
  // this.calltofocus();
  //this.setCaretToEnd(el);

  this.sharedserviceService.setCaretToEnd(el,this.ispositionrequired);

  // this.tagDivElement.nativeElement.focus();
  // el.focus();
  // console.log(el.innerHTML);
  // this.setCaretToEnd(el);
}
//can be removed postcontrol
onbackspace()
{
  this.isuserbackspace=true;

//  if(this.tagDivElement.nativeElement.innerHTML.split('</a>').pop()=='')
// {
//   let removethis=this.tagDivElement.nativeElement.innerHTML.split('<a href=').pop();
//   this.tagDivElement.nativeElement.innerHTML=this.tagDivElement.nativeElement.innerHTML.split(removethis)[0];
//   this.setCaretToEnd(this.tagDivElement.nativeElement);
// }
}
//can be removed postcontrol
onkeydown(event)
{
  this.ispositionrequired=false;
  if (event.key === "Backspace" || event.code === "Backspace" || event.keyCode === 8 || event.which === 8) 
  {
  
  }

 else if (event.key === "Enter" || event.code === "Enter" || event.keyCode === 13 || event.which === 13) {
    //console.log("Enter Entered");
    if(this.isvisisblecompanylist)
    {
   // console.log("this.focusElement"+this.focusElement);
    this.getSelectedCompany(this.items[this.focusElement].SecurityId);
    event.preventDefault();
    }
    this.isvisisblecompanylist=false;
  }
 else if (event.key === " " || event.code === "Space" || event.keyCode === 32 || event.which === 32) {
    //console.log("space Entered");
    this.isvisisblecompanylist=false;
  }
  else if (event.key === "$") {
    //console.log("$ Entered");
    this.isvisisblecompanylist=true;
  }

}
//can be removed postcontrol
ifBackspaceIsTrue()
{
  const innerhtml=this.tagDivElement.nativeElement.innerHTML;
    
  if(innerhtml!='' && innerhtml.split('</a></div>').pop()=='')
  {
    
    this.symbols = this.symbols.filter(item => item !== this.selectedcompany);

    let beforeupdate = (event.target as HTMLInputElement).innerHTML; 
    var thiscomp=this.selectedcompanywithLink;
    let afterupdate=beforeupdate.replace(thiscomp , ' ');
    (event.target as HTMLInputElement).innerHTML=afterupdate;
    this.isuserbackspace=false;
  }
  else if(innerhtml!='' && innerhtml.split('</a>').pop()=='')
  {
    this.symbols = this.symbols.filter(item => item !== this.selectedcompany);
    //let removethis=innerhtml.split('<a href=').pop();
    let removethis=innerhtml.split('<a routerLink=').pop();
    
    this.tagDivElement.nativeElement.innerHTML=innerhtml.split(removethis)[0];
    this.isuserbackspace=false;
  }

}
//can be removed postcontrol
ifVisisbleCompanyListisTrue()
{
  const innerHtml =  (event.target as HTMLInputElement).innerHTML;
  (event.target as HTMLInputElement).innerHTML = innerHtml.replace('$', '₹');
      if((event.target as HTMLInputElement).innerHTML.includes('₹') || (event.target as HTMLInputElement).innerHTML.includes('$'))
      {
        this.ispositionrequired=true;
        let term=(event.target as HTMLInputElement).innerHTML.split('₹').pop().toUpperCase();
       // console.log("term:"+term);
        if(term.includes('</DIV>'))
            {
      term=term.split('</DIV>')[0];        
            }
        this.focusElement = 0;
              if(term==='')
              { 
                this.items = BSE_Equity.slice(0,10);  
                
              }
              else{
                this.ispositionrequired=false;
                      this.items = BSE_Equity.filter(function(tag) {
                          if(tag.SecurityName.indexOf(term) >= 0 || tag.SecurityId.indexOf(term) >= 0)
                          return true;
                      });  
                } 
      }
      else
      {
        this.items=null;
        

      }

}
//can be removed postcontrol
change(event)
{
 // console.log("this.symbols:"+this.symbols);
  if(this.isuserbackspace==true)
  {
    this.ifBackspaceIsTrue();
  }
  if(this.isvisisblecompanylist==true)
  {
  this.ifVisisbleCompanyListisTrue();
  }
  event.preventDefault();
  this.sharedserviceService.setCaretToEnd(event.target,this.ispositionrequired)
}

// getUpdatedContent(event: KeyboardEvent)
// {
//   event.preventDefault();
//   const innerHtml =  (event.target as HTMLInputElement).innerHTML;
//   (event.target as HTMLInputElement).innerHTML = innerHtml.replace(/foo/i, '<a href="www.bar.com" title="Description">bar</a>');
  
//   //(event.target as HTMLInputElement).innerHTML = innerHtml.replace(/foo/i, '<a href="www.bar.com" title="Description">bar</a>');
//   this.setCaretToEnd(event.target);
// }





//can be removed postcontrol
post()
{
  
  
  //let postContent=document.getElementById("box").innerHTML;
  let postContent=document.getElementById("box").innerHTML.replace('href', 'routerLink');
  // console.log(this.tagDivElement.nativeElement.textContent);
  // console.log("in post :"+this.changecharacter);
let temp=this.tagDivElement.nativeElement.textContent.split(' ');
//console.log(temp);
   //var r= temp.contains
  const postsCollection = this.firestore.collection('/Posts');
  postsCollection.add(
    { bullbearnutral:this.bullbearnutral,
      retweetby:this.retweetby,
      retweetcount:0,
     likedby:this.likedby,
     likedcount:0,
     symbolcount:this.symbols.length,
     symbols:this.symbols,
     useruid:this.authservice.authuser.uid,
     emailid: this.authservice.authuser.email, 
     displayName:this.authservice.authuser.displayName,
      post: postContent,//this.changecharacter,
      createddate:new Date(),
      isdeleted:false });
this.changecharacter='';
}
  ngOnInit(): void {


  //   this.firebaseAuth.authState.subscribe(user => {
  //     if (user) {
  //       this.authuser=user;
  // this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", user.email).orderBy('createddate','desc')).valueChanges();
  
  //       return;
  //     }
  //     this.posts = null;
  //   })


    //this.downloadURL = this.storage.ref('/desifintwit/K1ifOpuXTqU0LQZu1gBpwBtKUhR2').getDownloadURL();
    
  //console.log(this.authservice.authuser.uid);
  //console.log(this.authservice.authuser.email);
  this.myEmailId=this.authservice.authuser.email;
  this.downloadURL = this.authservice.imageUrl;
  //this.downloadURL = this.authservice.getimageURl();
  // this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).orderBy('createddate','desc')).valueChanges();
  
  //commented on Dec 2020
  //this.posts = this.firestore.collection<Stock>('/Posts').valueChanges({ idField: 'documentid' });
  
  //just copied from view profile
  this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).orderBy('createddate','desc')).valueChanges({ idField: 'documentid' });
    
  
}


//not used
  onLocationFocus() {
    this.items = BSE_Equity.slice(0,10);
    this.focusElement = 0;

  }

  //can be removed postcontrol
  onLocationBlur() {
    this.focusElement = -1;

  }
//can be removed postcontrol
  onArrowUp() {
    if (this.focusElement > 0) {
      this.focusElement--;

    }
  }
//can be removed postcontrol
  onArrowDown() {
    if (this.focusElement <= this.items.length - 2) {
      this.focusElement++;
    }
  }
  //not used
  onEnter()
  {
   // console.log(this.items[this.focusElement].SecurityId);
    //this.router.navigate(['/symbol']);
   // this.items=null;
    //this.changecharacter=null;
    this.getSelectedCompany(this.items[this.focusElement].SecurityId)
  }
  // goto(link):any
  // {
  //   console.log("link:" + link);
  // }
  
  

  //not used

  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
this.sharedserviceService.hostlistnerNavigateToSymbol(event);
  }
 //can be removed fintwitcontrol
  onLike(post)
  {
    post.likedby.push(this.authservice.authuser.email);
    post.likedcount= post.likedcount+1;//wants to like
    this.firestore.doc('/Posts/' + post.documentid).update({'likedcount': post.likedcount,'likedby':post.likedby}); 
  }
  //can be removed fintwitcontrol
  onUnLike(post)
  {

    post.likedby =  post.likedby.filter(item => item !== this.authservice.authuser.email);
    post.likedcount= post.likedcount-1;//wants to unlike
    this.firestore.doc('/Posts/' + post.documentid).update({'likedcount': post.likedcount,'likedby':post.likedby}); 
  }
  //can be removed fintwitcontrol
  onRetweet(post)
  {
    post.retweetby.push(this.authservice.authuser.email);
    post.retweetcount= post.retweetcount+1;//wants to like
    this.firestore.doc('/Posts/' + post.documentid).update({'retweetcount': post.retweetcount,'retweetby':post.retweetby}); 

  }
  //can be removed fintwitcontrol
  onUndoRetweet(post)
  {
    post.retweetby =  post.retweetby.filter(item => item !== this.authservice.authuser.email);
    post.retweetcount= post.retweetcount-1;//wants to unlike
    this.firestore.doc('/Posts/' + post.documentid).update({'retweetcount': post.retweetcount,'retweetby':post.retweetby}); 

  }
//can be removed fintwitcontrol
  onReply(documentid)
  {
      //console.log("onReply: " + documentid);

  }
//can be removed fintwitcontrol
  Delete(post)
  {
    this.firestore.collection('/Posts').doc(post.documentid).delete();
  }
  
  //can be removed fintwitcontrol
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true,size: 'lg',scrollable: true, backdropClass: 'light-blue-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
//can be removed fintwitcontrol
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
//can be removed fintwitcontrol
  openLikedBy(popupLikedBy)
  {
    this.modalService.open(popupLikedBy, {ariaLabelledBy: 'modal-basic-title', centered: true,size: 'lg',scrollable: true, backdropClass: 'light-blue-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  //can be removed fintwitcontrol
calculateDiff(dateStr){

  return this.sharedserviceService.timeSincePost(dateStr);
}

}
