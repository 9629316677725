import { Component, OnInit } from '@angular/core';
import { Stock } from '../Stock';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationserviceService } from '../authenticationservice.service';
import { WatchlistInterface } from '../WatchlistInterface';


@Component({
  selector: 'app-recentposts',
  templateUrl: './recentposts.component.html',
  styleUrls: ['./recentposts.component.css']
})
export class RecentpostsComponent implements OnInit {
  posts: Observable<any[]>;
  downloadURL;
  currentDocumentID;
  active = 'Recent';
  stocknames: string[] = [];
  constructor(public firestore: AngularFirestore
    , private authservice: AuthenticationserviceService
  ) { }

  ngOnInit(): void {
    this.downloadURL = this.authservice.getimageURl();
    if(this.authservice.authuser.email=="dhan.tracker@gmail.com")
    this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.orderBy('createddate', 'desc')).valueChanges({ idField: 'documentid' });
else
    this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("isdeleted", "==", false).orderBy('createddate', 'desc')).valueChanges({ idField: 'documentid' });
  }
  reloadPosts(param) {
    //console.log(param);

    if (param == 'recent') {
      
      if(this.authservice.authuser.email=="dhan.tracker@gmail.com")
      this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.orderBy('createddate', 'desc')).valueChanges({ idField: 'documentid' });
  
      else
      
      this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("isdeleted", "==", false).orderBy('createddate', 'desc')).valueChanges({ idField: 'documentid' });

      
    }
    else if (param = 'watchlist') {
      this.firestore.collection<WatchlistInterface>('/watchlist', ref => ref.where("emailid", "==", this.authservice.authuser.email).limit(1))
        .valueChanges({ idField: 'documentid' })
        .subscribe(register => {
          // console.log(register.length);

          if (register.length == 0) {
            this.posts = null;
          }
          else {

            this.stocknames = register[0].name.slice(0, 10);
            //console.log(this.stocknames);
            this.stocknames.forEach((item, i, self) => self[i] = item.substring(0, item.lastIndexOf(' (')));
            //console.log(this.stocknames);



            this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("symbols", 'array-contains-any', this.stocknames).where("isdeleted", "==", false).orderBy('createddate', 'desc')).valueChanges({ idField: 'documentid' });

            //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("symbols", 'array-contains-any', ['ABB', 'HDFC']).orderBy('createddate','desc')).valueChanges({ idField: 'documentid' });
            this.currentDocumentID = register[0].documentid;
          }



        });
    }
  }
}
