export interface IStockDto {
    name: string,
    symbol: string,
    price: number
}

export class StockDtoModel {
    public name: string;
    public symbol: string;
    public price: number;
    constructor(StockDtoModel: IStockDto = {
        name: null,
        price: null,
        symbol: null
    }) {
        this.name = StockDtoModel.name;
        this.price = StockDtoModel.price;
        this.symbol = StockDtoModel.symbol;
    }
}