import { switchMap } from 'rxjs/operators';

import { DataService } from 'src/shared/data.service';
import { StockDtoModel } from './stockDto';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AttributeMarker } from '@angular/compiler/src/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-stockscroll',
  templateUrl: './stockscroll.component.html',
  styleUrls: ['./stockscroll.component.css'],
})  
export class StockscrollComponent implements OnInit {
  public stockScroll: any = [];
  public stockName: Array<StockDtoModel> = new Array<StockDtoModel>();
  public stockPriceValue: any = [];
  marqueTag;
  public playButton: any = false;
  subscription: Subscription;

  constructor(private http: HttpClient, private router: Router, private dataService: DataService) { }
  ngOnInit(): void { 
    this.subscription = timer(0, 6000000).pipe(switchMap(() =>
    this.dataService.getMultiStock()))
    .subscribe((result: any) => {
      this.stockScroll = result;
      this.getStock();
    });
  }

  getStock(): void {
    for (let i = 0; i < this.stockScroll.quoteResponse.result.length; i++) {
      this.stockName.push(
        new StockDtoModel({
          name: this.stockScroll.quoteResponse.result[i].longName ?? this.stockScroll.quoteResponse.result[i].shortName
            ?? this.stockScroll.quoteResponse.result[i].symbol,
          symbol: this.stockScroll.quoteResponse.result[i].symbol.split('.')[0],
          price: (this.stockScroll.quoteResponse.result[i].regularMarketPrice-this.stockScroll.quoteResponse.result[i].regularMarketPreviousClose)*100/this.stockScroll.quoteResponse.result[i].regularMarketPreviousClose
          // price:
          //   (this.stockScroll.quoteResponse.result[i].regularMarketPrice *
          //     100) /
          //   this.stockScroll.quoteResponse.result[i].regularMarketOpen /
          //   100 /
          //   this.stockScroll.quoteResponse.result[i].regularMarketPrice,
        })
      );
    }
  }

  togglePlay(playButton) {
    if (playButton == false) {
      let stoptag: any = document.getElementById('stock');
      this.playButton = true;
      stoptag.stop();
      return true;
    } else {
      let stoptag: any = document.getElementById('stock');
      stoptag.start();
      this.playButton = false;
      return false;
    }
  }

  goChart(code) {
    //this.router.navigate(['main/symbol'], { queryParams: { q: name } });

    this.router.navigate(['/symbol/' + code.split('.')[0]]);
  }
}
