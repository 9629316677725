import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linkify' })

export class LinkifyPipe implements PipeTransform {
	transform(link: string): string {
		return this.linkify(link);
	}

	private linkify(plainText): string {
		let replacedText;
		let replacePattern1;
		let replacePattern2;
		let replacePattern3;
		let replacePattern4;
		let replacePattern5;



		//URLs starting with http://, https://, or ftp://
		replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
		replacedText = plainText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

		//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

		//Change email addresses to mailto:: links.
		replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
		replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

		//#tag
		//https://regex101.com/r/78GYyD/5
		//https://regex101.com/r/lmsipc/1
		//https://regex101.com/r/Bq0qNM/4 
		// /^#\w+$/gm //#hashtag@hashtag this will fail here
//https://stackoverflow.com/questions/42065872/regex-for-a-valid-hashtag/42551826
//https://stackoverflow.com/questions/39276617/angular2-way-of-converting-plain-text-to-url-anchor-links
//https://regex101.com/r/78GYyD/5
		replacePattern4 = /(^|\B)#(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/gim; // /^#\w+$ /gim; 
		replacedText = replacedText.replace(replacePattern4, '<a href="symbol/$2" target="_blank">#$2</a>');


		//₹Ticker
		replacePattern5 = /(^|\B)₹(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/gim;
		//below one will identify without spaces as well (this is our req , but replacement part is not working)
		//replacePattern5= /(₹(?:\w+ ?\w+(?=₹)|\w+\b))/gim;
		replacedText = replacedText.replace(replacePattern5, '<a href="symbol/$2" target="_blank">₹$2</a>');

		//if tooltip required
		//<a href="symbol/TIIL" title="TIIL">₹TIIL</a>


		return replacedText;
	}
}
