import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { analyzeAndValidateNgModules } from '@angular/compiler';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject } from 'rxjs';
import { getLocaleDateFormat } from '@angular/common';
import { AngularFireStorage } from "@angular/fire/storage";
import * as firebase from 'firebase/app';
import { auth } from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationserviceService {
  authuser;
  imageUrl;
  isLoggedIn: boolean = false;
  ErrorCode;
  emailid;
  //cDisplayName;
  //displayName;
  phoneRecaptchaVerifier: firebase.auth.RecaptchaVerifier;
  constructor(public firestore: AngularFirestore, public firebaseAuth: AngularFireAuth
    , private storage: AngularFireStorage
    , public auth: AngularFireAuth,
  ) {

    firebaseAuth.authState.subscribe(user => {
      if (user) {
        this.authuser = user;
        //this.displayName = user.displayName;

        this.imageUrl = this.getimageURl();
//this.cDisplayName=this.GetCurrentUserDisplayName(this.authuser.emailid);
      }
    })
  }

  getimageURl() {
    return this.storage.ref('/desifintwit/' + this.authuser.uid).getDownloadURL();

  }

  loginWithPhoneNumber() {
    this.phoneRecaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-sign-in-recaptcha', {
      'size': 'invisible',
      'callback': function (response) {
        // reCAPTCHA solved - will proceed with submit function
      },
      'expired-callback': function () {
        // Reset reCAPTCHA?
      }
    });
    var phoneNumber = "+12088500904";

    this.auth.signInWithPhoneNumber(phoneNumber, this.phoneRecaptchaVerifier).then(function (confirmationResult) {
      var code = prompt(`We have send a code to ${phoneNumber}, please enter it here`, "");
      if (code) {
        confirmationResult.confirm(code).then(function (result) {
          // User signed in successfully.
          // Reset reCAPTCHA?
          // ...
          var user = result.user;
          // console.log(user) ;
          // console.log(result.user) ;
          // console.log(result);
        }).catch(function (error) {
          // User couldn't sign in (bad verification code?)
          // Reset reCAPTCHA?
          // ...
        });
      }
    }).catch(function (error) {
      //console.log(error.message);
    });

  }
  // this.afAuth.auth.onAuthStateChanged((user) => {
  //   if (user) {
  //     this.router.navigate('home');
  //   } else {
  //     this.router.navigate('login');
  //   } 
  // });

  // firebase.auth().onAuthStateChanged(function(result) {
  //   if (result) {
  //     // User is signed in.
  // this.amiNew=false;
  //     if(result.metadata.creationTime==result.metadata.lastSignInTime)

  //     {
  //       this.amiNew=false;
  //     }

  //   }
  // });

  async UpdateProfile(displayName: string) {
//     if(displayName=="")
//     {
//       firebase.database().ref()

//       return firebase.database().ref('/users/').once('value').then((snapshot) => {
//         var username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
//         // ...
//       });
// this.firestore.collection('/users', ref => ref.where("emailid", "==", "s@fintwit.in")).
// var userId = firebase.auth().currentUser.uid;
// return firebase.database().ref('/users/' + userId).once('value').then((snapshot) => {
//   var username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
//   // ...
// });
//     }
    const profile = {
        displayName: displayName,
        //photoURL: "https://example.com/jane-q-user/profile.jpg"
    }
    return (await this.auth.currentUser).updateProfile(profile);
}

  async  loginWithEmailAndPassword(email,password)
  {
    //console.log("emal"+email );

    //console.log("password"+password );
    var  error1=await this.auth.signInWithEmailAndPassword(email, password)
    .then((result) => {

      this.isLoggedIn = true;
      this.emailid = result.user.email
      var user = result.user;
     
      
     //result.user.displayName="Test Mame";
     //firebase.auth().updateCurrentUser( result.user);

     //firebase.auth().updateCurrentUser( result.user.uid, {displayName: "newName"});
      if (result.additionalUserInfo.isNewUser) {
        //DO NOT DELETE BELOW
        //console.log("New user");
        var dt = new Date();
        this.AddToSettings(result, dt);

        this.AddToProfile(result, dt);

        this.AddToWatchlist(result, dt);


      }
      else {
        //DO NOT DELETE BELOW
        //console.log("Old user");

      //   firestore.collection("records").doc("12345").onSnapshot(function(doc) {
      //     let data = doc.data();
      //     Object.keys(data).forEach(function(key) {
      //       console.log(key+": "+data[key]);
      //     });
      // })
      
        this.UpdateProfile("");

      }
    })
    .catch((error) => {
    // Handle Errors here.
    this.isLoggedIn = false;
    this.ErrorCode = error.code;
    //console.log(this.ErrorCode);
    //console.log("before return"+this.ErrorCode);

    var errorMessage = error.message;
  //  console.log(errorMessage);
  return errorMessage;

   // return `Caught an error: ${this.ErrorCode}`
    });
    //this.loginStatus();
    //firebase.auth().updateCurrentUser(, {displayName: newName});
    //this.auth.setPersistence(remeberMe===true ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
   // firebase.auth().setPersistence(this.remember.checked ? firebase.auth.Auth.Persistence.LOCAL : fireauth.Auth.Persistence.SESSION)

   return error1;


  }
  async SignUpWithEmailAndPassword(email,password,DisplayName,UserName)
  {
    console.log("emal"+email );

    console.log("password"+password );
    var  error1=await this.auth.createUserWithEmailAndPassword(email, password)
    .then((result) => {

      this.isLoggedIn = true;
      this.emailid = result.user.email
      var user = result.user;
     this.UpdateProfile(DisplayName);
      if (result.additionalUserInfo.isNewUser) {
        //DO NOT DELETE BELOW
        //console.log("New user");
        var dt = new Date();
        
        this.AddToUsers(result, dt, DisplayName, UserName);

        this.AddToSettings(result, dt);

        this.AddToProfile(result, dt, DisplayName, UserName);

        this.AddToWatchlist(result, dt);


      }
      else {
       //DO NOT DELETE BELOW
        //console.log("Old user");
        

      }
    })
    .catch((error) => {
    // Handle Errors here.
    this.isLoggedIn = false;
    this.ErrorCode = error.code;
    //console.log(this.ErrorCode);
    //console.log("before return"+this.ErrorCode);

    var errorMessage = error.message;
   //console.log(errorMessage);
  //this.loginStatus();
    return errorMessage;
    //return `Caught an error: ${this.ErrorCode}`
    });



    return error1;


  }
  loginWithTwitter()
  {
    var providerTwitter = new auth.TwitterAuthProvider();
    providerTwitter.setCustomParameters({
      //'login_hint': 'user@example.com'
      prompt: 'select_account',
      //auth_type: 'reauthenticate'
    });

    this.signinwithpop(providerTwitter);
    //this.loginStatus();
  }

  loginWithGmail() {

    //console.log("log with email");

    var providerGoogle = new auth.GoogleAuthProvider();
    providerGoogle.setCustomParameters({
      prompt: 'select_account'
    });
    // providerGoogle.addScope("profile");
    // providerGoogle.addScope("‘email’");
    // providerGoogle.addScope("https://www.googleapis.com/auth/plus.me");
    
 
    //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    // providerGoogle.setCustomParameters({
    //   'login_hint': 'user@example.com'
    // });
    
    this.signinwithpop(providerGoogle);
   
    //this.loginStatus();





  }

  signinwithpop(provider)
  {
    this.auth.signInWithPopup(provider).then((result) => {
      // code which runs on success
      // This gives you a Google Access Token. You can use it to access the Google API.
      //var token = result.credential.accessToken;
      // The signed-in user info.
      this.isLoggedIn = true;
      this.emailid = result.user.email
      var user = result.user;

      if (result.additionalUserInfo.isNewUser) {
        //console.log("New user");
        var dt = new Date();
        this.AddToSettings(result, dt);

        this.AddToProfile(result, dt);

        this.AddToWatchlist(result, dt);


      }
      else {
        //console.log("Old user");
        // var dt=new Date();
        // this.AddToProfile(result,dt);
        // var dt=new Date();
        // this.AddToWatchlist(result,dt);

      }

    }).catch((error) => {
      // Handle Errors here.
      this.isLoggedIn = false;
      var errorCode = error.code;
      //console.log(errorCode);
      var errorMessage = error.message;
      //console.log(errorMessage);
    })

      ;

  }
  AddToSettings(result, dt) {

    const settingsCollection = this.firestore.collection('/settings');
    settingsCollection.add(
      {
        'emailid': result.user.email,
        'displayName': result.user.displayName,
        'sNewsletter': false,
        sMessagerNotification: false,
        sFollowerNotification: false,
        sLikedNotification: false,
        sReshareNotification: false,
        sMentionNotification: false,
        'uid': result.user.uid,
        'createdDate': dt,
        'updatedDate': dt
      });



  }
  AddToUsers(result, dt,DisplayName?, UserName?) {

    const usersCollection = this.firestore.collection('/users');
    usersCollection.add(
      {
        'emailid': result.user.email,
        'displayName': result.user.displayName,
       
        'uid': result.user.uid,
        'createdDate': dt,
        'updatedDate': dt,
        DisplayName: DisplayName,
        UserName: UserName
      });



  }
  AddToProfile(result, dt , DisplayName?, UserName?) {
    const profileCollection = this.firestore.collection('/profile');

    profileCollection.add(
      {
        equities: false,
        options: false,
        forex: false,
        futures: false,
        bonds: false,
        privateCompanies: false,

        technical: false,
        fundamental: false,
        globalmacro: false,
        momentum: false,
        growth: false,
        value: false,

        daytrader: false,
        swingtrader: false,
        positiontrader: false,
        longterminvestor: false,

        novice: false,
        intermediate: false,
        professional: false,
        emailid: result.user.email,
        //emailid:this.prof.emailid,
        useruid: result.user.uid,
        'createdDate': dt,
        'updatedDate': dt,
        DisplayName: DisplayName,
        UserName: UserName
      });


  }

  AddToWatchlist(result, dt) {

    const watchlistCollection = this.firestore.collection('/watchlist');
    watchlistCollection.add(
      {
        'emailid': result.user.email,
        'displayName': result.user.displayName,
        'useruid': result.user.uid,
        'createdDate': dt,
        'updatedDate': dt,
        name: []
      });


  }
//   loginStatus() { 
//     firebase.auth().onAuthStateChanged(firebaseUser => {
//       if (firebaseUser) {
//         console.log(firebaseUser.uid)
//         console.log(firebaseUser.displayName);
//         console.log(firebaseUser);
//         // firebaseUser.displayName="Test check";
//         // console.log(firebaseUser);
// this.emailid=firebaseUser.email;
//        // admin.auth().updateUser(userRecord.uid, {displayName: newName});
//       return (firebaseUser.uid)
//     } else {
//       return (null)
//     }
//   })
// }
// disname;
// getPolicies(){

//   this.firestore.collection('/users', ref => ref.limit(1))
//   .snapshotChanges().subscribe(data => {
//     this.disname = data.map(e => { 
//       return {
//         disname: e.payload.doc.id
//       } ;
//     })
//   });
// }

// getTotalQuestions(): Observable<string> {
//   let totalQuestions;
//   var subject = new Subject<string>();
//   this.firestore.collection('/users', ref => ref.limit(1)).valueChanges()
//   .subscribe(items => {
//       items.map(item => {
  
//         totalQuestions=item;
//         console.log(totalQuestions);
//         subject.next(totalQuestions);
//       });
//     }
//   );
//     return subject.asObservable();
//   }
//   GetMarketListCall()
//   {
//     return this.firestore.collection('/users', ref => ref.limit(1))
//     .valueChanges();

//   }
// async getTest()
// {

//    await this.firestore.collection('/users', ref => ref.limit(1))
//   .valueChanges().subscribe(action => {
//     console.log(action);
//     this.disname=action ;
//     console.log("this.disname1" +this.disname);
//     return this.disname;
//     for(var obj in action) { 
//         console.log("1"+obj);
//         console.log("2" + obj['DisplayName']);
//         // console.log("3" + obj[DisplayName];

    
//         // this.disname = Number.parseInt(obj);
//     }
//   });
// // console.log("this.disname2" +this.disname);
// //   return this.disname;
// }
// GetCurrentUserDisplayName(emailid)
// {
//   var test;
  
//  // return this.firestore.collection('/users', ref => ref.where("emailid", "==", "s@fintwit.in").limit(1)).valueChanges();
//   this.firestore.collection('/users', ref => ref.limit(1))
//    .valueChanges()
//    .subscribe(
//     (datas) => 
//     {
//        console.log("datas", datas); 
//        console.log("datas12", datas[0]); 
        
// this.cDisplayName=datas[0];
// //return this.cDisplayName;       
//   test=datas[0];
//   datas.map(item => {
//     this.cDisplayName=item;

//   });
//   }

//    );
  
// console.log("hellpw"  + test);
// console.log("hellpwwww"  + test?.DisplayName);
  
// return test;








// }
  
}
