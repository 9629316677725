<ng-container *ngIf="IsSecurityNameFound; else showNoDataFound">
<div *ngIf="auth.user | async as user1">

    <app-postcontrol [code]=this.code></app-postcontrol>
</div>
<h4>{{this.item.SecurityName}} ({{this.item.SecurityId}}) </h4>
<!-- <h1>{{this.code}}</h1> -->
<!-- <h1>{{this.item.SecurityId}}</h1> -->

<!-- <app-ticker-info [ticker]=this.code></app-ticker-info> -->
<!-- <app-watchorunwatch [stickerName]="this.ToAddinWatchliststring"></app-watchorunwatch> -->
<app-news></app-news> 
<app-centralchart></app-centralchart>
<app-fintwitscontrol [posts]="posts"></app-fintwitscontrol>
</ng-container>


<ng-template #showNoDataFound>
    
        <!-- <div class="d-flex justify-content-between">
            <div class="p-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
      </svg>
      <p>404</p>

      <p>Not found</p>
      <p>This page may have been deleted or the link might be off.</p>

    </div>
    
      </div> -->
      
      <h1><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
      </svg>   This page may have been deleted or the symbol might be wrong.</h1>
   
  </ng-template>