
import { ActivatedRoute } from '@angular/router';
import { NewsRss } from './news-rss';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as xml2js from 'xml2js';
import { Subscription, timer } from 'rxjs';
import { DataService } from 'src/shared/data.service';
import { switchMap } from 'rxjs/operators';
import { SharedserviceService } from '../sharedservice.service';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  RssData: NewsRss;

  constructor(private http: HttpClient,private route: ActivatedRoute
    , public sharedserviceService: SharedserviceService
    ,private dataService: DataService,) {}
    code='';
  subscription: Subscription;
  ngOnInit(): void {

  //   if (this.ticker && this.ticker !== '') {
  //     this.subscription = timer(0, 6000000)
  //       .pipe(switchMap(() =>
  //         this.dataService.getGadgetRssFeedData(this.ticker)))
  //       .subscribe(result => {
  //         let response: any;
  //    //     let xml = result["_body"]
  //  // console.log("ticker xml" + xml);
  //   console.log("ticker xml" + result);

  //       });

  //     }

  this.route.paramMap.subscribe(params => {
    //var symbol1 = params.get("code");
  this.code=params.get("code");
  console.log("news code" + this.code);
    var stockSymbol= this.sharedserviceService.getYFCompanyCodeFromSecurityId(this.code);
    //this.stockSymbol="AAPL";
    var symbol=stockSymbol;    


    // this.route.queryParamMap.subscribe((params: any) => {
    //   var symbol = params.params.q ?? 'AAPL';

if (symbol=='NotFound')
{
  
  symbol='SENSEX.BO';
}

    this.GetGogleRssFeedData(symbol);
   });
  }
  //return this.httpClient.get(api, {responseType: 'text'});
  GetRssFeedData(symbol) {
    const requestOptions: Object = {
      observe: 'body',
      responseType: 'text'
    }; ///https://news.google.com/rss/search?q="+ symbol +"&hl=en-US&gl=US&ceid=US:en
    const _url = "https://gadgets.ndtv.com/rss/feeds";
   // const _url = "https://gadgets.ndtv.com/rss/feeds";
   
    this.http   // https://gadgets.ndtv.com/rss/feeds
      .get<any>(
        _url,
        requestOptions
      )
      .subscribe((data) => {
        let parseString = xml2js.parseString;
        parseString(data, (err, result: NewsRss) => {
          this.RssData = result;
        });
      });
  }

  GetGogleRssFeedData(symbol) {
    

      //this.dataService.getGadgetRssFeedData(symbol)
     this.dataService.getNewsInformation(symbol)
       
      .subscribe((data) => {
        console.log("news data"+data);

        let parseString = xml2js.parseString;
        parseString(data, (err, result: NewsRss) => {
          this.RssData = result;
          //this.RssData = this.sortData(this.RssData);
          console.log("news "+this.RssData);
          console.log("error "+err);
          

        });
      });
  }

  // sortData(result) {
  //   return result?.rss.channel[0].sort((a, b) => {
  //     //return <any>new Date(b.pubDate) - <any>new Date(a.pubDate);
  //     return Date.parse(a.pubDate) - Date.parse(b.pubDate) ;
  //   });
  // }
   getDataDiff(endDate) {
    let setDate= new Date(endDate).toISOString();
    var diff = (new Date()).getTime() - new Date(setDate).getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    // var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    let dayString =  days == 0 ?  "" : days + "days ";
    let hoursString =  hours == 0 ?  "" : hours + "hr ";
    let minutesString =  minutes == 0 ?  "" : minutes + "m ";

    return dayString + hoursString + minutesString;
  }

  ngOnDestroy() {
    this.RssData = null;
    this.subscription.unsubscribe();
  }
}
