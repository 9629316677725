<div class="row justify-content-between">

  <div class="col-sm-2">
    <h1>Settings</h1>
  </div>
  <div class="col-sm-9">
    <div class="form-check">
      <label>
        <input type="checkbox" [(ngModel)]="sNewsletter" /> Include me in newsletter</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="sMessagerNotification" /> Send me Diect message notification via
        email</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="sFollowerNotification" /> Send me follower notification via email</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="sLikedNotification" /> Send me liked notification via email</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="sReshareNotification" /> Send me Reshare notification via email</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="sMentionNotification" /> Send me Mention notification via email</label>
    </div>
  </div>
</div>
<br>
<!-- <div class="row justify-content-between" >
    <div class="col-sm-4">
      New Password
    </div>
    <div class="col-sm-8">
      <div class="form-check">
           <input [(ngModel)] = "newpassword" />
    </div>
    </div>
  </div>
  <br>
  <div class="row justify-content-between" >
    <div class="col-sm-4">
      Confirm Password
    </div>
    <div class="col-sm-8">
      <div class="form-check">
           <input [(ngModel)] = "confirmpassword" />
    </div>
    </div>
  </div> -->
<br>
<br>
<div class="row justify-content-between">
  <div class="col-sm-4">
  </div>
  <div class="col-sm-8">
    <div class="form-check">
      <button type="button" class="btn btn-outline-primary" (click)="UpdateSettings()">Update Settings</button>
    </div>
  </div>
</div>