

<!-- (keydown.backspace)="onbackspace()"     -->
<!-- (keydown.backspace)="onbackspace()"  -->
<!-- (keydown)="onkeydown($event)" -->
<app-postcontrol [code]=""></app-postcontrol>
 
  <div id="search-component">
    <!-- <textarea id="box" (keyup)="this.remove($event)" [(ngModel)] = "changecharacter" (focusout)="onfocusout()" placeholder="Post a fintwit"></textarea> -->
    <div id="box" #tagDiv (input)="this.change($event)" 
    (keydown.backspace)="onbackspace()" 
    (keydown)="onkeydown($event)"

                (blur)="onLocationBlur()"
               (keydown.ArrowUp)="onArrowUp()"
               (keydown.ArrowDown)="onArrowDown()"
              
    attr.placeholder ="{{placeholder}}" 
    contenteditable="true" [innerHTML] ="changecharacter" (focusout)="onfocusout()" placeholder="Post a fintwit" ></div>
    <ng-container *ngIf="isvisisblecompanylist">
    <ul id="companylist" #tagcompanylist  class="post-result">
      <li #item [ngClass]="{'focus-background': focusElement == item.tabIndex}"
      *ngFor="let company of items | slice:0:10; let i = index" tabIndex="{{i}}" (mousedown)="getSelectedCompany(company.SecurityId)" >
        &#8377;{{company.SecurityId}}(&#x20B9;{{company.SecurityId}})
      
      </li>
    </ul>
    </ng-container>
    <div class="bullbearnutralCSS">
    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="bullbearnutral">
      <label ngbButtonLabel class="btn btn-outline-success">
        <input ngbButton type="radio" value="Bullish">Bullish 
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-graph-up" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </label>
      <label ngbButtonLabel class="tn btn-outline-danger">
        <input ngbButton type="radio" value="Bearish">Bearish 
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-graph-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5z"/>
        </svg>
      </label>
      <label ngbButtonLabel class="bbtn btn-outline-primary">
        <input ngbButton type="radio" value="Nutral">Neutral 
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-emoji-neutral" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path fill-rule="evenodd" d="M4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"/>
          <path d="M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
        </svg>
      </label>

       
    </div></div>
  </div>
  
<button type="button" class="btn btn-primary ShareButtonCSS"  (click)="post()">Share{{changecharacter}}</button>
<br>
<ng-container *ngFor="let post of posts | async"  >

  <div class="container">
    <div class="row postCSS" style="padding-bottom: 10px; padding-top: 10px; "  >
        <div class="fixed">
          <div class="container" *ngIf="downloadURL | async; let url">
            <img [src]="url" width="50" height="50"   class="img-fluid z-depth-1 rounded-circle"
            alt="../assets/avatar.png"  onerror="this.src='../assets/avatar.png';">
          </div>
        </div>
        <div class="col" > 
          <div class="row">
            <div class="col-7"><b>{{post.displayName}}</b></div>
            <div class="col-2">
               <!-- <div [ngStyle]="post.bullbearnutral ==='Bullish' && {'font-weight': 'bold', 'font-style': 'italic','background-color': 'rgb(165, 207, 207)','color': 'blue'}">{{post.bullbearnutral}}</div>
              </div> -->
              <!-- <div 
  [ngStyle]="{'color': (post.bullbearnutral ==='Bullish' && {'font-weight': 'bold', 'font-style': 'italic','background-color': 'rgb(165, 207, 207)'}) ? 'green' : 
                        (post.bullbearnutral ==='Bearish') ? 'red' :
                          (post.bullbearnutral ==='Nutral') ? 'blue' :
                           ''}">{{post.bullbearnutral}}
</div> -->
<div [ngClass]="{
  'BullishCSS' : post.bullbearnutral === 'Bullish',
  'BearishCSS' : post.bullbearnutral === 'Bearish',
  'NeutralCSS' : post.bullbearnutral === 'Nutral'
}" > {{post.bullbearnutral}} </div>
</div> 
            <div class="col-3" >{{calculateDiff(post.createddate.toDate())}}</div>
          </div>
          <div class="row" style="padding-bottom: 10px;">
            <div class="col">    <div class="blueAnchors" [innerHTML] = "post.post" ></div>
          </div>
            </div>
            <!-- <div class="row">
              <br>
            </div> -->
          <div class="row">

            <div class="col-3" >
    <div class="row">
    <div *ngIf="post.likedby.includes(this.authservice.authuser.email); else elseBlock" >
      <div class="btn-group btn-group-toggle">
        <label class="btn-like" ngbButtonLabel>
          <input type="checkbox" ngbButton [(ngModel)]="trueValue" (click)="onUnLike(post)"> <fa-icon [icon]="faThumbsUp"></fa-icon>
        </label>
      </div> 
      <button *ngIf="post.likedcount>=1" class="btn border-radius: 50%;" (click)="openLikedBy(popupLikedBy)">{{post.likedcount}}</button>
      
    </div>
    <ng-template #elseBlock>
      <div class="btn-group btn-group-toggle">
        <label class="btn-like" ngbButtonLabel>
          <input type="checkbox" ngbButton [(ngModel)]="falseValue" (click)="onLike(post)"> 
          <fa-icon [icon]="faThumbsUp"></fa-icon>
         
        </label>
      </div> 
      <button *ngIf="post.likedcount>=1" class="btn border-radius: 50%;" (click)="openLikedBy(popupLikedBy)">{{post.likedcount}}</button>
    
    </ng-template>
    </div>
              
            
    
            </div>
            <div class="col-3" >
            <button class="btn border-radius: 50%;" (click)="open(content)"><fa-icon [icon]="faReply"></fa-icon>  </button>
            </div>
            
    
            <div class="col-3" >
              <div class="row">


              <div *ngIf="post.retweetby.includes(this.authservice.authuser.email); else retweetelseBlock">
                <div class="btn-group btn-group-toggle">
                  <label class="btn-like" ngbButtonLabel>
                    <input type="checkbox" ngbButton [(ngModel)]="trueValue" (click)="onUndoRetweet(post)"> <fa-icon [icon]="faRetweet"></fa-icon>
                  </label>
                </div> 
                <button *ngIf="post.retweetcount>=1" class="btn border-radius: 50%;" (click)="openLikedBy(popupRetweetBy)">{{post.retweetcount}}</button>
                
              </div>
              <ng-template #retweetelseBlock>
                <div class="btn-group btn-group-toggle">
                  <label class="btn-like" ngbButtonLabel>
                    <input type="checkbox" ngbButton [(ngModel)]="falseValue" (click)="onRetweet(post)"> <fa-icon [icon]="faRetweet"></fa-icon>
                  </label>
                </div>   
                <button *ngIf="post.retweetcount>=1"  class="btn border-radius: 50%;" (click)="openLikedBy(popupRetweetBy)">{{post.retweetcount}}</button>
              </ng-template>
            
            </div>
    
            </div>

            
            <div ngbDropdown placement="bottom-right" class="col-3" >
              <button class="btn border-radius: 50%;"  ngbDropdownToggle>...</button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem routerLink="/viewprofile">Share to Twitter</button>
                <button ngbDropdownItem routerLink="/editprofile">Copy Link to Post</button>
                <button ngbDropdownItem routerLink="/settings">Mute</button>
                <button ngbDropdownItem routerLink="/posts">Report</button>
                <button ngbDropdownItem routerLink="/posts">Blocked</button>
                <button ngbDropdownItem  (click)="Delete(post)">Delete</button>
              </div>
            </div>

          </div>
        
        </div>
    </div>        
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reply</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table>
      <tr>
      <td class="align-top">
        
        <div class="container" *ngIf="downloadURL | async; let url">
          <img [src]="url" width="50" height="50"   class="img-fluid z-depth-1 rounded-circle"
          alt="../assets/avatar.png"  onerror="this.src='../assets/avatar.png';">
        </div>
        
      </td>
      <td class="align-top">
        <table>
          <tr>
            <td><b>{{post.emailid}}</b></td>
            <td class="text-right">{{calculateDiff(post.createddate.toDate())}}</td>
          </tr>
          <tr>
            <td>
              <!-- <div [innerHTML] = "post.post | safehtml: 'html resourceUrl'" ></div> -->
              <!-- {{post.post}} -->
              <div class="blueAnchors" [innerHTML] = "post.post" ></div>
    
            </td>
          </tr>
    </table>
    </td>
    </tr>
    </table>
    <div id="search-component">
      <!-- <textarea id="box" (keyup)="this.remove($event)" [(ngModel)] = "changecharacter" (focusout)="onfocusout()" placeholder="Post a fintwit"></textarea> -->
      <div id="box" #tagDiv (input)="this.change($event)" 
      (keydown.backspace)="onbackspace()" 
      (keydown)="onkeydown($event)"
  
                  (blur)="onLocationBlur()"
                 (keydown.ArrowUp)="onArrowUp()"
                 (keydown.ArrowDown)="onArrowDown()"
                
      attr.placeholder ="{{placeholder}}" 
      contenteditable="true" [innerHTML] ="changecharacter" (focusout)="onfocusout()" placeholder="Post a fintwit" ></div>
      <ng-container *ngIf="isvisisblecompanylist">
      <ul id="companylist" #tagcompanylist  class="post-result">
        <li #item [ngClass]="{'focus-background': focusElement == item.tabIndex}"
        *ngFor="let company of items | slice:0:10; let i = index" tabIndex="{{i}}" (mousedown)="getSelectedCompany(company.SecurityId)" >
          &#8377;{{company.SecurityId}}(&#x20B9;{{company.SecurityId}})
        
        </li>
      </ul>
      </ng-container>
      <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="bullbearnutral">
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="Bullish">Bullish</label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="Bearish">Bearish</label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" value="Nutral">Nutral</label>
  
         
      </div>
    </div>
    
  <button type="button" class="btn btn-primary" (click)="post()">Post{{changecharacter}}</button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

<ng-template #popupLikedBy let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Liked by</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul>
      <li class="text" *ngFor="let likedby of post.likedby">
       <a>{{likedby}}</a>
      </li>
    </ul>
 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #popupRetweetBy let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Retweet by</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ul>
      <li class="text" *ngFor="let retweetby of post.retweetby">
       <a>{{retweetby}}</a>
      </li>
    </ul>
 
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
</ng-container>





