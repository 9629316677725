<!-- (keydown.backspace)="onbackspace()"     -->
<!-- (keydown.backspace)="onbackspace()"  -->
<!-- (keydown)="onkeydown($event)" -->
<div id="search-component1">
  <!-- <textarea id="box" (keyup)="this.remove($event)" [(ngModel)] = "changecharacter" (focusout)="onfocusout()" placeholder="Post a fintwit"></textarea> -->
  <div id="box" #tagDiv (input)="this.change($event)" (keydown.backspace)="onbackspace()" (keydown)="onkeydown($event)" (keyup)="onkeyup($event)"
    attr.placeholder="{{placeholder}}" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp()"
    (keydown.ArrowDown)="onArrowDown()" contenteditable="true" [innerHTML]="changecharacter" (focusout)="onfocusout()"
    (paste)="onPaste($event)"
    >




  </div>



  <div class="bullbearnutralCSS">
    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="bullbearnutral">
      <label ngbButtonLabel class="btn btn-outline-success">

        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-graph-up" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z" />
        </svg>
        <input ngbButton type="radio" value="Bullish">
      </label>
      <label ngbButtonLabel class="tn btn-outline-danger">

        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-graph-down" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M0 0h1v15h15v1H0V0zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5z" />
        </svg>

        <input ngbButton type="radio" value="Bearish">
      </label>

      <label ngbButtonLabel class="bbtn btn-outline-primary">

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-megaphone"
          viewBox="0 0 16 16">
          <path
            d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975l1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
        </svg>
        <input ngbButton type="radio" value="Nutral">
      </label>


    </div>
    <label class="btn border-radius: 50%;"  (click)="toggleEmojiPicker()" >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi btn-outline-primary bi-emoji-smile" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
      </svg>
      </label>
  </div>
  
  <button [disabled]="isShareButtonDisable" type="button" class="btn btn-primary ShareButtonCSS"
    (click)="post()">Share{{changecharacter}}</button>

</div>

<!-- <div id="upload_button" class="mediaCSS">
  <label class="hand-cursor">
      <input type="file" id="file" (change)="AddMedia($event)" accept=".png,.jpg" />
     
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi btn-outline-primary bi-card-image" viewBox="0 0 16 16">
        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
      </svg>
  </label>
</div> -->

<label class="charactersleft">{{this.charactersLeft}}</label>

<ng-container *ngIf="isvisisblecompanylist">
  <ul id="companylist" #tagcompanylist class="post-result">
    <li #item [ngClass]="{'focus-background': focusElement == item.tabIndex}"
      *ngFor="let company of items | slice:0:10; let i = index" tabIndex="{{i}}" (mouseover)="onmouseoverChange(i)"
      (mousedown)="getSelectedCompany(company.SecurityId)">

      <div class="row">
        <div class="col-md-10 col-sm-10 col-xs-10">

          &#8377;{{company.SecurityId}}

        </div>


      </div>

      <div class="row">
        <div class="col" style="font-size: 10px;">
          {{company.SecurityName}}
        </div>
      </div>
    </li>
  </ul>
</ng-container>
<br>
<emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="Choose your emoji" (onLeave)="onBlurEmoji()" autoFocus="true" size=12 set='twitter' ></emoji-mart>

<br>

<br>
