import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Stock } from '../Stock';
import { AuthenticationserviceService } from '../authenticationservice.service';
import { SharedserviceService } from '../sharedservice.service';
import { faThumbsUp, faRetweet, faReply, faChartLine, faAngleDoubleUp, faAngleDoubleDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
//import { AngularFireAuth } from '@angular/fire/auth';
//import { getLocaleDateFormat } from '@angular/common';
//import { AngularFireStorage } from "@angular/fire/storage";
//import { Router } from '@angular/router';

@Component({
  selector: 'app-fintwitscontrol',
  templateUrl: './fintwitscontrol.component.html',
  styleUrls: ['./fintwitscontrol.component.css']
})
export class FintwitscontrolComponent implements OnInit {

  @Input()
  posts: Observable<any[]>;

  //posts: Observable<any[]>;

  faThumbsUp = faThumbsUp;
  faRetweet = faRetweet;
  faReply = faReply;
  faAngleDoubleUp = faAngleDoubleUp;
  faAngleDoubleDown = faAngleDoubleDown;
  faArrowRight = faArrowRight;
  faChartLine = faChartLine;


  downloadURL;
  myEmailId;
  falseValue = false;
  trueValue = true;
  closeResult = '';


  constructor(public firestore: AngularFirestore
    //,public firebaseAuth: AngularFireAuth
    //,private storage: AngularFireStorage
    //,private router:Router
    , private toastr: ToastrService
    , public authservice: AuthenticationserviceService
    , public sharedserviceService: SharedserviceService
    , private modalService: NgbModal
  ) {
    //   firebaseAuth.authState.subscribe(user => {
    //     if (user) {
    //       this.authuser=user;
    // this.posts = firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", user.email).orderBy('createddate','desc')).valueChanges();

    //       return;
    //     }
    //     this.posts = null;
    //   })

  }

  ngOnInit(): void {
    //   this.firebaseAuth.authState.subscribe(user => {
    //     if (user) {
    //       this.authuser=user;
    // this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", user.email).orderBy('createddate','desc')).valueChanges();

    //       return;
    //     }
    //     this.posts = null;
    //   })

    //this.downloadURL = this.storage.ref('/desifintwit/K1ifOpuXTqU0LQZu1gBpwBtKUhR2').getDownloadURL();
    //this.downloadURL = this.authservice.getimageURl();
    // this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).orderBy('createddate','desc')).valueChanges();

    //commented on Dec 2020
    //this.posts = this.firestore.collection<Stock>('/Posts').valueChanges({ idField: 'documentid' });

    //just copied from view profile
    //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).orderBy('createddate','desc')).valueChanges({ idField: 'documentid' });


    this.myEmailId = this.authservice.authuser.email;
    this.downloadURL = this.authservice.imageUrl;
  }
  calculateDiff(dateStr) {

    return this.sharedserviceService.timeSincePost(dateStr);
  }
  onLike(post) {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();
      return;
    }
    post.likedby.push(this.authservice.authuser.email);
    post.likedcount = post.likedcount + 1;//wants to like
    this.firestore.doc('/Posts/' + post.documentid).update({ 'likedcount': post.likedcount, 'likedby': post.likedby });
  }
  onUnLike(post) {

    post.likedby = post.likedby.filter(item => item !== this.authservice.authuser.email);
    post.likedcount = post.likedcount - 1;//wants to unlike
    this.firestore.doc('/Posts/' + post.documentid).update({ 'likedcount': post.likedcount, 'likedby': post.likedby });
  }
  onRetweet(post) {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();
      return;

    }
    post.retweetby.push(this.authservice.authuser.email);
    post.retweetcount = post.retweetcount + 1;//wants to like
    this.firestore.doc('/Posts/' + post.documentid).update({ 'retweetcount': post.retweetcount, 'retweetby': post.retweetby });

  }
  onUndoRetweet(post) {
    post.retweetby = post.retweetby.filter(item => item !== this.authservice.authuser.email);
    post.retweetcount = post.retweetcount - 1;//wants to unlike
    this.firestore.doc('/Posts/' + post.documentid).update({ 'retweetcount': post.retweetcount, 'retweetby': post.retweetby });

  }
  open()//open(content)
   {

    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();
      return;
    }
    this.toastr.error('Comments will be enabled after your 1st post!', '', {
      timeOut: 4000,
    });
    // FutureEnhancement
    // this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg', scrollable: true, backdropClass: 'light-blue-backdrop' }).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });

  }
  openLikedBy(popupLikedBy) {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();
      return;
    }
    this.modalService.open(popupLikedBy, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg', scrollable: true, backdropClass: 'light-blue-backdrop' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  onReply(documentid) {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();
      return;

    }
    //console.log("onReply: " + documentid);

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  Delete(post) {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();
      return;

    }
    //check condition before post deleting of the current user then only
    //console.log(post.documentid);

    if (this.authservice.emailid == post.emailid) {
      this.firestore.collection('/Posts').doc(post.documentid).delete();
      this.toastr.success('Deleted!', '');
    }
    else {
      this.toastr.error('You can delete your own post!', '');

    }
  }
  ShareTwitter(post) {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();

      return;

    }
    this.toastr.error('We are working hard to fix it!', '');
  }
  copyLink(post) {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();

      return;

    }
    this.toastr.error('We are working hard to fix it!', '');
  }
  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
    this.sharedserviceService.hostlistnerNavigateToSymbol(event);
  }
}
