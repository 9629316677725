<div class="row" style="padding-left: 16px;">
  <div *ngIf="watchList.includes(stickerName); else elseBlock">
    <div class="btn-group btn-group-toggle">
      <label class="btn btn-outline-primary" ngbButtonLabel>
        <input type="checkbox" ngbButton [(ngModel)]="trueValue" (click)="RemoveWatch()"><i class="bi bi-eye-slash"></i> Unwatch
      </label>
    </div>

  </div>
  <ng-template #elseBlock>
    <div class="btn-group btn-group-toggle">
      <label class="btn btn-outline-primary" ngbButtonLabel>
        <input type="checkbox" ngbButton [(ngModel)]="falseValue" (click)="AddWatch()"><i class="bi bi-eye"></i>
        Watch

      </label>
    </div>

  </ng-template>
</div>

