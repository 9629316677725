import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/storage";
import { AuthenticationserviceService } from '../authenticationservice.service'
import { AngularFirestore } from '@angular/fire/firestore';
import { Profile } from '../Profile';
import { ToastrService } from 'ngx-toastr';
//import yahooFinance from 'yahoo-finance2';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  sNewsletter;
  sMessagerNotification;
  sFollowerNotification;
  sLikedNotification;
  sReshareNotification;
  sMentionNotification;

  currentProfileID;

  confirmpassword;
  newpassword;
  prof: Profile;
  constructor(private storage: AngularFireStorage
    , private toastr: ToastrService
    , public authservice: AuthenticationserviceService, public firestore: AngularFirestore) {

  }
  async UpdateSettings() {
    
    //yahoo-finance2
    
    //https://www.npmjs.com/package/yahoo-finance2
    //npm install -g yahoo-finance2
    //npm install --save--dev yahoo-finance2
//     const result=yahooFinance.quote('AAPL').then(console.log);
//     const query = 'TSLA';
// const queryOptions = { period1: '2021-02-01', /* ... */ };
//     const result1=yahooFinance.historical (query, queryOptions).then(console.log);
//     const result3=yahooFinance.quoteSummary('AAPL').then(console.log);

//     const result4 = await yahooFinance.autoc(query, /* queryOptions */).then(console.log);

//     const query2 = 'GOO';
// const result5 = await yahooFinance.search(query2, /* queryOptions */).then(console.log);
    

    //stock info
//https://www.npmjs.com/package/stock-info
//npm install --save stock-info
//npm install --save--dev stock-info
    // const si = require('stock-info');
    // const stocks = ['AAPL', 'TSLA','PLTR'];
    // si.getStocksInfo(stocks).then(console.log); 

// console.log(this.currentProfileID);
    this.firestore.doc('/settings/' + this.currentProfileID)
      .update({

        'sNewsletter': this.sNewsletter,
        sMessagerNotification: this.sMessagerNotification,
        sFollowerNotification: this.sFollowerNotification,
        sLikedNotification: this.sLikedNotification,
        sReshareNotification: this.sReshareNotification,
        sMentionNotification: this.sMentionNotification,
        'updatedDate': new Date(),

      });


    this.toastr.success('Saved!', '');
  };
  ngOnInit(): void {
    this.firestore.collection<Profile>('/settings', ref => ref.where("emailid", "==", this.authservice.authuser.email).limit(1))
      .valueChanges({ idField: 'documentid' })
      .subscribe(register => {
        this.prof = register[0];
        this.setFromDB();
      });
  }
  setFromDB() {

    this.sNewsletter = this.prof.sNewsletter;
    this.sMessagerNotification = this.prof.sMessagerNotification;
    this.sFollowerNotification = this.prof.sFollowerNotification;
    this.sLikedNotification = this.prof.sLikedNotification;
    this.sReshareNotification = this.prof.sReshareNotification;
    this.sMentionNotification = this.prof.sMentionNotification;


    this.currentProfileID = this.prof.documentid
  }
}
