import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { stat } from 'fs';
//import {BSE_Equity} from '../app/BSE_Equity'

import { BSE_Equity } from '../app/BSE_Equity';
import { BSE_Equity_Top10byMCAP } from '../app/BSE_Equity_Top10byMCAP';
import { NSE_Equity } from '../app/NSE_Equity';

@Injectable({
  providedIn: 'root'
})
export class SharedserviceService {

  constructor(private router:Router) { }
  change(event,isuserbackspace,symbols,selectedcompany,selectedcompanywithLink
    ,isvisisblecompanylist,tagDivElement,ispositionrequired,focusElement
    ,items,BSE_Equity)
  {
    ///removed
    this.setCaretToEnd(event.target,ispositionrequired);
    //return { items: items, k2: 99 };
    //return { items: items,right:right,bottom:bottom};
  }
  //from posts-below remove code is for text area
remove(event: KeyboardEvent) { 
  // console.log("changecharacter");
  // console.log("changecharacter : "+this.changecharacter);
  //   this.changecharacter = (event.target as HTMLInputElement).value.replace('$', '₹');
  //     if((event.target as HTMLInputElement).value.includes('₹') || (event.target as HTMLInputElement).value.includes('$'))
  //     {
  //       let term=(event.target as HTMLInputElement).value.split('₹').pop().toUpperCase();
  //       console.log("term : "+term);

  //             if(term==='')
  //             { this.items = BSE_Equity.slice(0,10);  
  //             }
  //             else{

  //                     this.items = BSE_Equity.filter(function(tag) {
      
  //                         if(tag.SecurityName.indexOf(term) >= 0 || tag.SecurityId.indexOf(term) >= 0)
  //                         return true;

  //                     });  
  //               } 
  //     }
  //     else
  //     {
  //       this.items=null;

  //     }

}
hostlistnerNavigateToSymbol(event :MouseEvent)
{

      // If we don't have an anchor tag, we don't need to do anything.
      if (event.target instanceof HTMLAnchorElement === false) { 
        return;
      }
      // Prevent page from reloading
      event.preventDefault();
    
         
     //let code= (event.target as HTMLAnchorElement).innerHTML;
     let code = (event.target as HTMLAnchorElement).innerHTML.split('₹').pop().toUpperCase();
    this.router.navigate(['/symbol/' + code]);

    // let target = <HTMLAnchorElement>event.target;
    // this.router.navigate([target.pathname]);

  }

  setCaretToEnd(target/*: HTMLDivElement*/, ispositionrequired) {
    //console.log(ispositionrequired);
    const range = document.createRange();
    ///need to check why this is not working
    //const range = this.tagDivElement.nativeElement.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(target);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    target.focus();
    range.detach(); // optimization
    // set scroll to the end if multiline
    target.scrollTop = target.scrollHeight;
    //below id for company serach result position 
    if (ispositionrequired) {
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(target);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      var getrct = preCaretRange.getClientRects();
      //var caretOffset = preCaretRange.toString().length;
      //console.log("caretOffset:"+caretOffset);    
      //var rec=preCaretRange.getBoundingClientRect();
      var right = getrct[getrct.length - 1].right;
      var bottom = getrct[getrct.length - 1].bottom;
      var div = document.getElementById("companylist");
      //var div=this.tagcompanylistElement.nativeElement;
      div.style.left = right + "px";
      div.style.top = bottom + "px";

    }
  }



  setCaretToEnd1(target/*: HTMLDivElement*/, ispositionrequired) {
    //     getRange(target)
    //     {
    //       target.focus();
    //       let _range = document.getSelection().getRangeAt(0);
    //       let range = _range.cloneRange();
    //       range.selectNodeContents(target);
    //       range.setEnd(_range.endContainer, _range.endOffset);
    //       console.log("range.length " +range.toString().length);

    // return range;

    //     }

    //     setRange(target, range1)
    //     {
    // //       console.log("rang1e1.length " +range1.toString().length);
    // // //range1.toString().length=16;
    // //       const sel = window.getSelection();
    // //       range1.collapse(false);
    // //       //sel.removeAllRanges();
    // //       sel.addRange(range1);
    // //       target.focus();
    // //       range1.detach(); // optimization
    // //       // set scroll to the end if multiline
    // //       target.scrollTop = target.scrollHeight;
    // // target.focus();
    // //document.getSelection().collapse(target, 2);

    //     }
    // target.focus();
    //   let _range = document.getSelection().getRangeAt(0);
    //   let range = _range.cloneRange();
    //   range.selectNodeContents(target);
    //   range.setEnd(_range.endContainer, _range.endOffset);
    //   console.log("target.childNodes.length" + target.childNodes.length);
    //   //target.setSelectionRange(1,1);

    //   console.log("range" +range); 

    // // console.log(term2.length);
    // console.log("range.endContainer " +range.endContainer); 
    //   console.log("range.toString() PressDollar "+range.toString().length) ;


    // var preCaretRange = range.cloneRange();
    //     console.log("preCaretRange:"+preCaretRange);    

    //     preCaretRange.selectNodeContents(target);
    //     preCaretRange.setEnd(range.endContainer, range.endOffset);
    //     var getrct=preCaretRange.getClientRects();
    //     console.log("getrct" + getrct);
    //     var caretOffset = preCaretRange.toString().length;
    //     console.log("caretOffset:"+caretOffset);    
    //     //var rec=preCaretRange.getBoundingClientRect();
    //     console.log("getrct.length" + getrct.length);
    //     console.log("right" + right);

    //     var right=getrct[getrct.length-1].right;
    //     var bottom=getrct[getrct.length-1].bottom;
    //     console.log("right" + right);
    //     console.log("bottom" + bottom);
    // var el = document.getElementById("box")
    // let _range1 = document.getSelection().getRangeAt(0);
    // var range = document.createRange()
    // var sel = window.getSelection()
    // console.log("el.childNodes.length " +el.childNodes.length);
    // //range.setStart(el.childNodes[1],0 );
    // //range.setStart(el.firstChild, 15);

    // //range.setStart(null, 1);

    // //range.setEnd();
    // //range.startOffset[5];
    // range.collapse(true);

    // sel.removeAllRanges();
    // //sel.collapse(target, 1);
    // //range.setSelectionRange(1, 1)
    // sel.addRange(_range1)
    // sel.collapse(el, 2)
    // target.focus(); 

    //target.focus()
    //document.getSelection().collapse(target, 10)
    // var mainDiv = document.getElementById("box")
    // var startNode = mainDiv.firstChild;
    // var endNode = mainDiv.childNodes[2].firstChild;

    // var range = document.createRange();
    // range.setStart(startNode, 6); // 6 is the offset of "world" within "Hello world"
    // range.setEnd(endNode, 7); // 7 is the length of "this is"
    // range.collapse(false)
    // var sel = window.getSelection();
    // sel.removeAllRanges();
    // sel.addRange(range);
    // target.focus();
    // range.detach(); // optimization
    // // set scroll to the end if multiline
    // target.scrollTop = target.scrollHeight;

    //console.log(ispositionrequired);
    // let term = (event.target as HTMLInputElement).innerHTML.split('₹').pop().toUpperCase();
    // let term2 = (event.target as HTMLInputElement).innerHTML.split('₹')[0];
    // console.log(term);
    // console.log(term2.length);

    //     var node = document.querySelector("div");
    // target.focus();
    // var textNode = node.firstChild;
    // var caret = 10; // insert caret after the 10th character say
    // var range = document.createRange();
    // range.setStart(textNode, caret);
    // range.setEnd(textNode, caret);
    // range.collapse(false)
    // var sel = window.getSelection();
    // sel.removeAllRanges();
    // sel.addRange(range);

    // var node = document.querySelector("div");
    // node.focus();
    // var textNode = node.firstChild;
    // var caret = 10; // insert caret after the 10th character say
    // var range = document.createRange();
    // range.setStart(textNode, caret);
    // range.setEnd(textNode, caret);
    // var sel = window.getSelection();
    // sel.removeAllRanges();
    // sel.addRange(range);

    // let term = (event.target as HTMLInputElement).innerHTML.split('₹').pop().toUpperCase();
    //     let term2 = (event.target as HTMLInputElement).innerHTML.split('₹')[0];
    //     console.log(term);
    //     console.log(term2.length)
    //     var el = document.getElementById("box")
    //     var range = document.createRange()
    //     var sel = window.getSelection()
    //     console.log("el.childNodes.length " +el.childNodes.length);
    //     range.setStart(el.childNodes[0], 0);
    //     range.setStart(el.firstChild, 15);

    //range.setStart(null, 1);

    //range.setEnd();
    //range.startOffset[5];
    // range.collapse(true)

    // sel.removeAllRanges()
    // sel.addRange(range)
    // target.focus();

    //var sStart = target.selectionStart;



    //   target.focus();
    //  target.setSelectionRange(5, 15);

    // const range = document.createRange();
    //     ///need to check why this is not working
    //     //const range = this.tagDivElement.nativeElement.createRange();
    //     console.log("range " +range);
    //     const sel = window.getSelection();
    //     range.selectNodeContents(target);
    //     console.log("range " +range);

    //     range.collapse(false);
    //     console.log("range " +range);
    //     sel.removeAllRanges();
    //     sel.addRange(range);
    //     console.log("sel.rangeCount" + sel.rangeCount);


    //     range.detach(); // optimization
    //     // set scroll to the end if multiline
    //     target.scrollTop = target.scrollHeight;
    //     //below id for company serach result position 


    // var preCaretRange1 = range.cloneRange();
    //   console.log("preCaretRange:"+preCaretRange1);    

    //   preCaretRange1.selectNodeContents(target);
    //   preCaretRange1.setStart(range.startContainer,range.startOffset);
    //   //preCaretRange1.setEnd(range.endContainer, range.endOffset);
    //   console.log("range.endContainer:"+range.endContainer);    
    //   console.log("range.endOffset:"+range.endOffset);    

    //   var getrct1=preCaretRange1.getClientRects();
    //   console.log("getrct:"+getrct1);    
    //   var right1=getrct1[getrct1.length-1].right;
    //   var bottom1=getrct1[getrct1.length-1].bottom;
    //   console.log("right1:"+right1);   
    //   console.log("bottom1:"+right1);   
    //   var caretOffset = preCaretRange1.toString().length;

    //       //sel.selectAllChildren(target);
    //       //sel.setPosition(target,caretOffset-1);
    //       //target.setSelectionRange(0, caretOffset-5);
    //       target.focus();
    //sel.setPosition(caretOffset);

    //  if(ispositionrequired)
    //  {
    //   var preCaretRange = range.cloneRange();
    //   console.log("preCaretRange:"+preCaretRange);    

    //   preCaretRange.selectNodeContents(target);
    //   preCaretRange.setEnd(range.endContainer, range.endOffset);
    //   var getrct=preCaretRange.getClientRects();
    //   var caretOffset = preCaretRange.toString().length;
    //   console.log("caretOffset:"+caretOffset);    
    //   //var rec=preCaretRange.getBoundingClientRect();
    //   var right=getrct[getrct.length-1].right;
    //   var bottom=getrct[getrct.length-1].bottom;
    //   var div = document.getElementById("companylist"); 
    //   //var div=this.tagcompanylistElement.nativeElement;
    //   div.style.left = right+"px";
    //   div.style.top = bottom+"px";

    // }

    ///below if relative
    // div.style.left = rec.width+"px";
    // div.style.top = rec.height-150+"px";

  }
  timeSincePost(dateStr) {
    var date1 = new Date(dateStr);
    let date2 = new Date();
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_secs = Difference_In_Time / 1000;
    if (Difference_In_secs > 59) {
      Difference_In_secs = Difference_In_secs
    }

    var msec = Difference_In_Time;
    var secs = Math.floor(msec / 1000);
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    var days = Math.floor(hrs / 24);
    var yrs = Math.floor(days / 365);

    if (secs <= 60) {

      return parseInt(secs.toString()) + "s";
    }
    else if (mins <= 60) {

      return parseInt(mins.toString()) + "m";
    }
    else if (hrs <= 24) {

      return parseInt(hrs.toString()) + "hr";
    }
    else if (days <= 365) {

      return parseInt(days.toString()) + "d";
    }
    else
      return date1.getTime();

  }


  isAfter(container, offset, node) {
    var c = node;
    while (c.parentNode != container) {
      c = c.parentNode;
    }
    var i = offset;
    while (c != null && i > 0) {
      c = c.previousSibling;
      i -= 1;
    }
    return i > 0;
  }
  compareCaretPositons(node1, offset1, node2, offset2) {
    if (node1 === node2) {
      return offset1 - offset2;
    }
    var c = node1.compareDocumentPosition(node2);
    if ((c & Node.DOCUMENT_POSITION_CONTAINED_BY) !== 0) {
      return this.isAfter(node1, offset1, node2) ? +1 : -1;
    } else if ((c & Node.DOCUMENT_POSITION_CONTAINS) !== 0) {
      return this.isAfter(node2, offset2, node1) ? -1 : +1;
    } else if ((c & Node.DOCUMENT_POSITION_FOLLOWING) !== 0) {
      return -1;
    } else if ((c & Node.DOCUMENT_POSITION_PRECEDING) !== 0) {
      return +1;
    }
  }

  stringifyElementStart(node, isLineStart) {
    if (node.tagName.toLowerCase() === 'br') {
      if (true) {
        return '\n';
      }
    }
    if (node.tagName.toLowerCase() === 'div') { // Is a block-level element?
      if (!isLineStart) { //TODO: Is not at start of a line?
        return '\n';
      }
    }
    return '';
  }
  * positions(node, isLineStart = true) {
    console.assert(node.nodeType === Node.ELEMENT_NODE);
    var child = node.firstChild;
    var offset = 0;
    yield { node: node, offset: offset, text: this.stringifyElementStart(node, isLineStart) };
    while (child != null) {
      if (child.nodeType === Node.TEXT_NODE) {
        yield { node: child, offset: 0 / 0, text: child.data };
        isLineStart = false;
      } else {
        isLineStart = yield* this.positions(child, isLineStart);
      }
      child = child.nextSibling;
      offset += 1;
      yield { node: node, offset: offset, text: '' };
    }
    return isLineStart;
  }
  getCaretPosition(contenteditable, textPosition) {
    var textOffset = 0;
    var lastNode = null;
    var lastOffset = 0;
    for (var p of this.positions(contenteditable)) {
      if (p.text.length > textPosition - textOffset) {
        return { node: p.node, offset: p.node.nodeType === Node.TEXT_NODE ? textPosition - textOffset : p.offset };
      }
      textOffset += p.text.length;
      lastNode = p.node;
      lastOffset = p.node.nodeType === Node.TEXT_NODE ? p.text.length : p.offset;
    }
    return { node: lastNode, offset: lastOffset };
  }
  getTextOffset(contenteditable, selectionNode, selectionOffset) {
    var textOffset = 0;
    for (var p of this.positions(contenteditable)) {
      if (selectionNode.nodeType !== Node.TEXT_NODE && selectionNode === p.node && selectionOffset === p.offset) {
        return textOffset;
      }
      if (selectionNode.nodeType === Node.TEXT_NODE && selectionNode === p.node) {
        return textOffset + selectionOffset;
      }
      textOffset += p.text.length;
    }
    return this.compareCaretPositons(selectionNode, selectionOffset, contenteditable, 0) < 0 ? 0 : textOffset;
  }
  getValue(contenteditable) {
    var value = '';
    for (var p of this.positions(contenteditable)) {
      value += p.text;
    }
    return value;
  }

  getFocusNodeTextContent(contenteditable, start, end) {
    var selection = window.getSelection();
    var s = this.getCaretPosition(contenteditable, start);
    var e = this.getCaretPosition(contenteditable, end);
    //selection.focusNode.textContent.replace('test4','test100');
    //selection.setBaseAndExtent(s.node, s.offset, e.node, e.offset);
    return selection.focusNode.textContent;
  }
  getfocusOffset(contenteditable, start, end) {
    var selection = window.getSelection();
    var s = this.getCaretPosition(contenteditable, start);
    var e = this.getCaretPosition(contenteditable, end);
    //selection.focusNode.textContent.replace('test4','test100');
    //selection.setBaseAndExtent(s.node, s.offset, e.node, e.offset);
    return selection.focusOffset.toString();
  }
  replaceSelectedText(replacementText) {
    var sel, range;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(document.createTextNode(replacementText));
        }
    } else if (document && document.createRange) {
        range = document.createRange();
        range.text = replacementText;
    }
}
//will not work and can be deleted
colorSetSelectionRange(contenteditable, start, end) {
//  var selection = window.getSelection();
var sel = window.getSelection();

var s = this.getCaretPosition(contenteditable, start);
  var e = this.getCaretPosition(contenteditable, end);
  var range = document.createRange();
range.setStart(s.node, s.offset); // 6 is the offset of "world" within "Hello world"
range.setEnd(e.node, e.offset); // 7 is the length of "this is"
sel.removeAllRanges();
sel.addRange(range);
contenteditable.focus();
  //selection.setBaseAndExtent(s.node, s.offset, e.node, e.offset);
}
  setSelectionRange(contenteditable, start, end, ispositionrequired, lenth) {
    var selection = window.getSelection();
    var s = this.getCaretPosition(contenteditable, start);
    var e = this.getCaretPosition(contenteditable, end);
    
    selection.setBaseAndExtent(s.node, s.offset, e.node, e.offset);
    contenteditable.focus();
    //range.detach(); // optimization
    // set scroll to the end if multiline
    contenteditable.scrollTop = contenteditable.scrollHeight;
    if (ispositionrequired) {
      const range = document.createRange();
      ///need to check why this is not working
      //const range = this.tagDivElement.nativeElement.createRange();

      range.selectNodeContents(contenteditable);
      range.collapse(false);

      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(contenteditable);
      contenteditable.inner
      if (lenth > start)
        preCaretRange.setEnd(selection.anchorNode, selection.anchorOffset);
      else
        preCaretRange.setEnd(selection.focusNode, selection.focusOffset);

      //preCaretRange.setEnd(range.endContainer, range.endOffset);
      var getrct = preCaretRange.getClientRects();
      //var caretOffset = preCaretRange.toString().length;
      //console.log("caretOffset:"+caretOffset);    
      //var rec=preCaretRange.getBoundingClientRect();
      var right = getrct[getrct.length - 1].right;
      var bottom = getrct[getrct.length - 1].bottom;
      var div = document.getElementById("companylist");
      //var div=this.tagcompanylistElement.nativeElement;
      div.style.left = right + "px";
      div.style.top = bottom + "px";

    }
  }
  //TODO: Ctrl+A - rangeCount is 2
  getSelectionDirection(contenteditable) {
    var selection = window.getSelection();
    var c = this.compareCaretPositons(selection.anchorNode, selection.anchorOffset, selection.focusNode, selection.focusOffset);
    return c < 0 ? 'forward' : 'none';
  }
  getSelectionStart(contenteditable) {
    var selection = window.getSelection();
    var c = this.compareCaretPositons(selection.anchorNode, selection.anchorOffset, selection.focusNode, selection.focusOffset);
    return c < 0 ? this.getTextOffset(contenteditable, selection.anchorNode, selection.anchorOffset) : this.getTextOffset(contenteditable, selection.focusNode, selection.focusOffset);
  }
  getSelectionEnd(contenteditable) {
    var selection = window.getSelection();
    var c = this.compareCaretPositons(selection.anchorNode, selection.anchorOffset, selection.focusNode, selection.focusOffset);
    return c < 0 ? this.getTextOffset(contenteditable, selection.focusNode, selection.focusOffset) : this.getTextOffset(contenteditable, selection.anchorNode, selection.anchorOffset);
  }


  getCaretPositionWithInnerHtml(editableDiv) {
    var caretPos = 0,
      sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.getSelection() && document.createRange()) {
      range = document.createRange();
      if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  }
  getYFCompanyCodeFromSecurityIdAndExchange(SecurityId,Exchange)
  {
    //console.log(SecurityId);
    //console.log(Exchange);
let code=Exchange=='BSE'?'.BO':Exchange=='NSE'?'.NS':'';
//console.log(code);

    return SecurityId + code
  }
  getYFCompanyCodeFromSecurityId(SecurityId)
  {

let item = BSE_Equity.find(e => e.SecurityId === SecurityId);

if(item?.SecurityName!=undefined)
{
return item?.SecurityId+".BO";
}
  else if (item?.SecurityName == undefined) {


      item = NSE_Equity.find(e => e.SecurityId === SecurityId);

    }

if(item?.SecurityName != undefined)
{

return item?.SecurityId+ ".NS"

}
return "NotFound";

  }
  getSecurityIdFromYFCompanyCode(YFCompanyCode)
{
return YFCompanyCode.split('.')[0];

}
getCompanyNamefromCompanyCode(code)
{
  var item = BSE_Equity.find(e => e.SecurityId === code);

  if (item?.SecurityName == undefined) {


    item = NSE_Equity.find(e => e.SecurityId === code);

  }

  return item;
}

}
