

<!-- <div class="backgroundimage">

</div> -->

<br>
<br>
<br>
<br>
<br>
<br>
<h1>
    Let's see what’s going on in the markets..
</h1>
<h3>
    See what actual investors and traders are saying in real time about the Indian stocks, Nify, crypto, futures, and forex you care about for free.
</h3>

