// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//below is for adding cusotom domain here
//https://stackoverflow.com/questions/40856179/custom-authdomain-in-firebase
export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB8S3r-ra4rPnqcYxEji6zYW6XvD0LKNo0",
    authDomain: "fintwit.in",
    databaseURL: "https://desifintwit.firebaseio.com",
    projectId: "desifintwit",
    storageBucket: "desifintwit.appspot.com",
    messagingSenderId: "561619111941",
    appId: "1:561619111941:web:be6dddd46883a3ed4acc6c",
    measurementId: "G-EZVDM3NRC2"

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
