export interface IStockDeatilsDto {
    companyName: string,
    name: string,
    price: number,
    mainvalue: number,
    maindiffValue: number,
    pvalue: number,
    pdiffValue: number,
    sentimnet: number,
    mVolumn: number,
    Date: string,
}

export class StockDeatilsDtoModel {
    public companyName: string;
    public name: string;
    public price: number;
    public mainvalue: number;
    public maindiffValue: number;
    public pvalue: number;
    public pdiffValue: number;
    public sentimnet: number;
    public mVolumn: number;
    public Date: string;
    constructor(StockDeatilsDtoModel: IStockDeatilsDto = {
        companyName: null,
        name: null,
        price: null,
        mainvalue: null,
        maindiffValue: null,
        pvalue: null,
        pdiffValue: null,
        sentimnet: null,
        mVolumn: null,
        Date: null,
    }) {
        this.companyName = StockDeatilsDtoModel.companyName;
        this.name = StockDeatilsDtoModel.name;
        this.price = StockDeatilsDtoModel.price;
        this.mainvalue = StockDeatilsDtoModel.mainvalue;
        this.maindiffValue = StockDeatilsDtoModel.maindiffValue;
        this.pvalue = StockDeatilsDtoModel.pvalue;
        this.pdiffValue = StockDeatilsDtoModel.pdiffValue;
        this.sentimnet = StockDeatilsDtoModel.sentimnet;
        this.mVolumn = StockDeatilsDtoModel.mVolumn;
        this.Date = StockDeatilsDtoModel.Date;
    }
}