export interface IWatchDto {
    name: string,
    YFCode:string,
    price: number,
    value: number,
    diffValue: number,
    companyName: string
}

export class WatchDtoModel {
    public name: string;
    public YFCode: string;    
    public price: number;
    public value: number;
    public diffValue: number;
    public companyName: string;
    constructor(WatchDtoModel: IWatchDto = {
        name: null,
        YFCode:null,
        price: null,
        value: null,
        diffValue: null,
        companyName: null
    }) {
        this.name = WatchDtoModel.name;
        this.YFCode=WatchDtoModel.YFCode;
        this.price = WatchDtoModel.price;
        this.value = WatchDtoModel.value;
        this.diffValue = WatchDtoModel.diffValue;
        this.companyName = WatchDtoModel.companyName;
    }
}