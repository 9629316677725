<div class="container">
  <div class="row">
    <div class="col-md-3 col-sm-3 col-xs-3">
      <a [routerLink]="['/home']">
        <table>
          <tr>
            <td><img src="../assets/FF_Logo.png" height="40" width="40" alt="../assets/FF_Logo.png"
                onerror="this.src='../assets/FF_Logo.png';"></td>
            <td style="font-size: xx-large; color: #138808;">FinTwit.in</td>

          </tr>
        </table>


      </a>
    </div>
    <div id="phone-sign-in-recaptcha"></div>

    <!-- Login screens
    https://codepen.io/FlorinPop17/pen/vPKWjd
    https://codepen.io/davinci/pen/YxwwWd
    https://codepen.io/larirabello/pen/eojQww
    https://freefrontend.com/css-login-forms/
    https://therichpost.com/angular-8-bootstrap-popup-login-form/
    https://stackblitz.com/edit/wizdm-places?file=src%2Fapp%2Flogin%2Flogin.component.html
    https://stackblitz.com/edit/angular-7-reactive-form-validation?file=app%2Fapp.component.html
     -->
    <!-- https://codebeautify.org/jsonviewer -->

    <!-- https://stackoverflow.com/questions/53973926/filling-a-circle-border-by-percent -->
    <!-- charts https://d3js.org/ 
    https://www.npmjs.com/package/d3.js
    -->
    <!-- beutyfy json https://codebeautify.org/jsonviewer -->
    <!-- replace bootstrap icons to https://www.npmjs.com/package/ng-bootstrap-icons -->
    <!-- https://www.npmjs.com/package/rupee-formatter -->
    <!-- https://www.npmjs.com/package/@extra-number/to-indian-system -->
    <!-- https://www.npmjs.com/package/angularindiasvgmap -->
    <!-- markey holiday list -->
    <!-- https://www.npmjs.com/package/indian-currency-formatter -->
    <!-- https://stackoverflow.com/questions/62742445/angular-how-to-make-a-content-editable-divs-placeholder-persist-until-only-whe 
do not delete
place holder

focus on highlight
https://stackoverflow.com/questions/37628228/input-with-custom-search-result-how-to-change-focus-to-jump-over-the-results
my work for div
https://stackblitz.com/edit/angular-ivy-psrxgn?file=src%2Fapp%2Fapp.component.html
-->
    <!-- account create add settings and profile and emailid and useruid -->
    <!-- settings and profile in 1 table reduce  db calls -->
    <!-- https://www.digitalocean.com/community/tutorials/angular-binding-keyup-keydown-events -->
    <!-- https://stackoverflow.com/questions/53091382/angular-2-creating-list-from-selections-from-a-datalist -->
    <!-- below is from above -->
    <!-- https://codepen.io/gfrancesca/pen/gbpoxQ -->
    <!-- after signup focus login textbox -->
    <!-- https://stackoverflow.com/questions/35436284/giving-focus-after-a-href -->
    <!-- https://css-tricks.com/a-css-approach-to-trap-focus-inside-of-an-element/ -->
    <!-- https://stackoverflow.com/questions/49927624/how-to-focus-on-an-input-in-angular -->
    <!-- below is for renderer2 exapmle -->
    <!-- https://www.concretepage.com/angular-2/angular-4-renderer2-example -->
    <!-- below is for right side delete/reminder functionality on mouse over -->
    <!-- https://codepen.io/c0un7z3r0/pen/LZWXEw -->
    <!-- belwo fpr keyup kewedon etc -->
    <!-- https://stackoverflow.com/questions/35359358/angular-2-change-event-on-every-keypress -->
    <!-- rendere2 exapmle -->
    <!-- https://www.concretepage.com/angular-2/angular-4-renderer2-example -->
    <!-- https://stackoverflow.com/questions/36055585/renderer-multiple-selectrootelement-issue/36059595#36059595 -->
    <!-- nice exapmles of angular  -->
    <!-- https://lishman.io/angular-2-event-binding -->
    <!-- check below for @text and space as link -->
    <!-- https://stackoverflow.com/questions/20623123/links-in-contenteditable-div -->
    <!-- below for routerlinks inside div -->
    <!-- https://www.digitalocean.com/community/tutorials/angular-navigation-routerlink-navigate-navigatebyurl -->
    <!-- https://stackblitz.com/edit/angular-routed?file=app%2Fhome.component.html -->
    <!-- original q for above -->
    <!-- https://stackoverflow.com/questions/49373649/angular-routerlink-inside-routerlink -->
    <!-- below is forcss of DIV -->
    <!-- https://stackoverflow.com/questions/43439931/border-of-div-bound-to-routerlink-directive -->
    <!-- check below for div post aniheight animation -->
    <!-- https://stackoverflow.com/questions/32839844/contenteditable-height-transition -->
    <!-- https://stackblitz.com/edit/angular-rxanqx?file=src%2Fapp%2Fapp.component.html -->
    <div class="col-md-6 col-sm-6 col-xs-6 align-self-center" align="center">
      <app-companysearchcontrol [SearchType]="SearchType"></app-companysearchcontrol>
    </div>


    <div class="col-md-3 col-sm-3 col-xs-3" style="padding:5px">
      <div ngbDropdown placement="bottom-right" class="d-inline-block"
        *ngIf="authabc.user | async as user1; else showLogin">
        <button class="btn border-radius: 50%;" style="width: 150px;" ngbDropdownToggle>{{user1.displayName === null ? user1.email : user1.displayName}}</button>

        <!-- <div *ngIf="user1.displayName; else DisplayUserName">
          <button class="btn border-radius: 50%;" style="width: 150px;" ngbDropdownToggle>{{user1.email}}</button>
        </div>
        <ng-template #DisplayUserName>
          <div *ngIf= "this.UserDetails | async as user21">
            <button class="btn border-radius: 50%;" style="width: 150px;" ngbDropdownToggle>{{user21[0].DisplayName}}</button>
          </div>
      </ng-template> -->
        <!-- <ng-template #DisplayUserName>
            <div *ngIf= "UserDetails | async as user21">
              <button class="btn border-radius: 50%;" style="width: 150px;" ngbDropdownToggle>{{user21[0].DisplayName}}</button>
            </div>
        </ng-template> -->
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

          <button ngbDropdownItem routerLink="/viewprofile">
            <div class="row align-items-start">
              <div class="col-1 text-right">
                <fa-icon class="btn-outline-primary" [icon]="faLightbulb"></fa-icon>
              </div>
              <div class="col-9 text-left">My Posts</div>
            </div>
          </button>
          <button ngbDropdownItem routerLink="/editprofile">

            <div class="row align-items-start">
              <div class="col-1 text-right">
                <fa-icon class="btn-outline-primary" [icon]="faUserEdit"></fa-icon>
              </div>
              <div class="col-9 text-left">Edit Profile</div>
            </div>
          </button>
          <button ngbDropdownItem routerLink="/settings">

            <div class="row align-items-center">
              <div class="col-1 text-right">
                <fa-icon class="btn-outline-primary" [icon]="faUserCog"></fa-icon>
              </div>
              <div class="col-9 text-left">Settings</div>
            </div>
          </button>
          <!-- <button ngbDropdownItem routerLink="/posts">
            <div class="row align-items-center">
              <div class="col-1 text-right">
                <fa-icon class="btn-outline-primary" [icon]="faLightbulb"></fa-icon>
              </div>
              <div class="col-9 text-left">Posts</div>
            </div>
          </button> -->
          <button ngbDropdownItem routerLink="/recentposts">
            <div class="row align-items-center">
              <div class="col-1 text-right">
                <fa-icon class="btn-outline-primary" [icon]="faLightbulb"></fa-icon>
              </div>
              <div class="col-9 text-left">Recent Posts</div>
            </div>
          </button>

          <!-- <button ngbDropdownItem routerLink="/watchlist">
            <div class="row align-items-center">
              <div class="col-1 text-right">
                <fa-icon class="btn-outline-primary" [icon]="faEye"></fa-icon>
              </div>
              <div class="col-9 text-left">Watchlist</div>
            </div>
          </button> -->
          <button ngbDropdownItem (click)="logout()">
            <div class="row align-items-center">
              <div class="col-1 text-right">
                <fa-icon class="btn-outline-primary" [icon]="faSignOutAlt"></fa-icon>
              </div>
              <div class="col-9 text-left">Logout</div>
            </div>
          </button>

        </div>
      </div>
      <ng-template #showLogin>
        <button type="button" class="btn btn-outline-primary" (click)="show()">Login / Register</button>
      </ng-template>
    </div>

    <!-- <div class="col-sm-1">
    <div *ngIf="auth.user | async as user; else showLogin">
       <button type="button" class="btn btn-primary" (click)="logout()">Logout</button>
    </div>
    <ng-template #showLogin>

      <button type="button" class="btn btn-primary" (click)="login()">Login</button>
    </ng-template>

  </div> -->
    <!-- {{auth | async | json}} -->
  </div>
</div>


<!-- https://freefrontend.com/css-login-forms/ -->
<!-- https://codepen.io/FlorinPop17/pen/vPKWjd -->

<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content modalContentLogin" style="width: 768px;">
      <!-- <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Login</h4>
      </div> -->
      <div class="modal-body">
        <!-- <button type="button" class="btn btn-outline-primary" (click)="loginWithGmail1()">Continue With Google</button> -->
          <!-- <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                  <label>Email</label>
                  <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                      <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
              </div>
              <div class="form-group">
                  <label>Password</label>
                  <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">Password is required</div>
                      <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                  </div>
                </div>
            <div class="form-group form-check">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" name="remember"> Remember me
              </label>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form> -->
          <div class="containerLogin" id="container234">
          <div class="form-container sign-up-container">
            <form action="#" [formGroup]="signupForm" (ngSubmit)="onSignup()">
                <h1>Create Account</h1>
                <div>
                  <button type="button" class="btn btn-outline-primary" style="width: 170px;margin-top: 10px;" (click)="loginWithGmail1()">Continue With Google</button>
                  <button type="button" class="btn btn-outline-primary" style="width: 170px;margin: 4px;" (click)="loginWithTwitter()"> Continue With Twitter </button>
  
                </div>
                <span><strong>OR</strong></span>
               
                <div class="form-group inputTest">
                  <!-- <label>Email</label> -->
                  <input type="text" placeholder="Display Name"  formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted2 && f2.name.errors }" />
                  <div *ngIf="submitted2 && f2.name.errors" class="invalid-feedback">
                      <div *ngIf="f2.name.errors.required">Name is required</div>
                      <div *ngIf="f2.name.errors.minlength">Name must be at least 3  characters</div>
                  </div>
              </div>
              <div class="form-group inputTest">
                <!-- <label>Email</label> -->
                <input type="text" placeholder="@username"  formControlName="UserName" class="form-control" [ngClass]="{ 'is-invalid': submitted2 && f2.UserName.errors }" />
                <div *ngIf="submitted2 && f2.UserName.errors" class="invalid-feedback">
                    <div *ngIf="f2.UserName.errors.required">User Name is required</div>
                    <div *ngIf="f2.UserName.errors.minlength">User Name must be at least 3 characters</div>
                </div>
            </div>
                <div class="form-group inputTest">
                  <!-- <label>Email</label> -->
                  <input type="text" placeholder="Email"  formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted2 && f2.email.errors }" />
                  <div *ngIf="submitted2 && f2.email.errors" class="invalid-feedback">
                      <div *ngIf="f2.email.errors.required">Email is required</div>
                      <div *ngIf="f2.email.errors.email">Email must be a valid email address</div>
                  </div>
              </div>
              <div class="form-group inputTest">
                  <!-- <label>Password</label> -->
                  <input type="password" placeholder="Password"  formControlName="password2" class="form-control" [ngClass]="{ 'is-invalid': submitted2 && f2.password2.errors }" />
                  <div *ngIf="submitted2 && f2.password2.errors" class="invalid-feedback">
                      <div *ngIf="f2.password2.errors.required">Password is required</div>
                      <div *ngIf="f2.password2.errors.minlength">Password must be at least 6 characters</div>
                  </div>
                </div>
                <div class="form-group inputTest">
                  
                  <input type="password" placeholder="Confirm Password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted2 && f2.confirmPassword.errors }" />
                  <div *ngIf="submitted2 && f2.confirmPassword.errors" class="invalid-feedback">
                      <div *ngIf="f2.confirmPassword.errors.required">Confirm Password is required</div>
                      <div *ngIf="f2.confirmPassword.errors.mustMatch">Passwords must match</div>
                  </div>
              </div>
                <button   type="submit" class="btn btn-primary">Register</button> <!-- [disabled]="!signupForm.valid && submitted2"  -->
            </form>
        </div>
        <div class="form-container sign-in-container">
            <form action="#" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <h1>Login</h1>
                <div>
                  <button type="button" class="btn btn-outline-primary" style="width: 170px;margin-top: 10px;" (click)="loginWithGmail1()">Continue With Google</button>
                  <button type="button" class="btn btn-outline-primary" style="width: 170px;margin: 4px;" (click)="loginWithTwitter()"> Continue With Twitter </button>
  
                </div>
                <span><strong>OR</strong></span>
                <!-- <form [formGroup]="registerForm" (ngSubmit)="onSubmit()"> -->
                  <div class="form-group inputTest">
                      <!-- <label>Email</label> -->
                      <input type="text" placeholder="Email"  formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">Email is required</div>
                          <div *ngIf="f.email.errors.email || f.email.errors.maxLength">Email must be a valid email address</div>
                      </div>
                  </div>
                  <div class="form-group inputTest">
                      <!-- <label>Password</label> -->
                      <input type="password" placeholder="Password"  formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': !f.email.errors && submitted && f.password.errors }" />
                      <button class="button-link"  type="submit" (click)="ForgotPassword1()">Forgot?</button>
                      <!-- <div style="float: right;padding-bottom: 20px;">
                        <button class="button-link"  type="submit" (click)="ForgotPassword1()">Forgot?</button>
                        
                      </div> -->
                      <div *ngIf=" !f.email.errors && submitted && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password is required</div>
                          <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                      </div>
                    </div>
                    <!-- do not delete this we have to implement rememberme -->
                <!-- <div class="form-group form-check inputTestRem">
                  <label class="form-check-label">
                    <input class="form-check-input" formControlName="rememberMe" type="checkbox" name="remember"> Remember me
                  </label>
                </div> -->
                
               
                
                <button  type="submit" class="btn btn-primary">Login</button>
              </form>
             
                <!-- <input type="email" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <a href="#">Forgot your password?</a>
                <button>Sign In</button> -->
            <!-- </form> -->
        </div>
        <div class="overlay-container">
            <div class="overlay">
                <div class="overlay-panel overlay-left">
                    <h1>Welcome Back!</h1>
                    <p>To keep connected with us please login with your info</p>
                    <button class="ghost btn btn-outline-primary" id="LsignIn">Login</button>
                </div>
                <div class="overlay-panel overlay-right">
                    <h1>Hello, FinTwitter!</h1>
                    <p>Enter your details and start journey with us</p>
                    <button class="ghost btn btn-outline-primary" id="LsignUp">Register</button>
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal" (click) = "hide()">Close</button>
    
    </div> -->
  
    </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click) = "hide()">Close</button>
      
      </div>
    </div>
  </div>
  </div>