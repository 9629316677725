import { Component, OnInit, Input } from '@angular/core';
import { BSE_Equity } from '../BSE_Equity';
import { BSE_Equity_Top10byMCAP } from '../BSE_Equity_Top10byMCAP';
import { NSE_Equity } from '../NSE_Equity';



import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-companysearchcontrol',
  templateUrl: './companysearchcontrol.component.html',
  styleUrls: ['./companysearchcontrol.component.css']
})
export class CompanysearchcontrolComponent implements OnInit {

  constructor(private router: Router

    , private ToastrService: ToastrService) { }

  @Input()
  SearchType: string = "";
  items;
  public focusElement: number = -1;
  changecharacter;
  stocknames: string[] = [];
  ngOnInit(): void {


  }

  onfocus() {
    //console.log("on mouse change");
    //console.log(this.auth.user.subscribe());
    //this.items = BSE_Equity.slice(0,10);
    this.items = BSE_Equity_Top10byMCAP;
    //this.focusElement = 0;
    //console.log(this.focusElement);
  }
  onmouseoverChange(i) {
    this.focusElement = i;
  }
  onfocusout() {
    this.items = null;
  }
  changeInSearch(event: KeyboardEvent) {


    const innerHtml = (event.target as HTMLInputElement).value;
    (event.target as HTMLInputElement).value = innerHtml.replace('$', '₹');
    let term = (event.target as HTMLInputElement).value.split('₹').pop().toUpperCase();
    if (term === '') {
    this.items = BSE_Equity.slice(0, 10);
    }
    else {
      this.items = BSE_Equity.filter(function (tag) {
        if (tag.SecurityName.indexOf(term) == 0 || tag.SecurityId.indexOf(term) == 0)
          return true;
      });
      if (this.items.length == 0) {
        this.items = NSE_Equity.filter(function (tag) {
          if (tag.SecurityName.indexOf(term) == 0 || tag.SecurityId.indexOf(term) == 0)
            return true;
        });

      }
      //
    }
  }



  onLocationBlur() {
    this.focusElement = -1;
    //console.log("on onLocationBlur");
  }

  onArrowUp() {
    if (this.focusElement > 0) {
      this.focusElement--;

    }
  }

  onArrowDown() {

    if (this.focusElement <= this.items.length - 2) {
      this.focusElement++;
    }

  }

  onEnter($event) {
    //console.log(this.SearchType + "on enter");
    if (this.SearchType == "default") {
      //console.log(this.items[this.focusElement].SecurityId);
      let stickerName = this.items[this.focusElement].SecurityId;
      //console.log(stickerName + "on enter");

      this.router.navigate(['/symbol/' + stickerName]);
      this.items = null;
      this.changecharacter = null;
      $event.target.blur();

    } else if (this.SearchType == "WatchListSearch") {


    }
  }
  navigateOraddToWatchList(StockId, StockName) {

    // console.log(this.SearchType+ "on mouse down");
    if (this.SearchType == "default") {

      this.router.navigate(['/symbol/' + StockId]);
      this.changecharacter = null;

    } else if (this.SearchType == "WatchListSearch") {


    }

  }
}
