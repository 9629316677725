import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationserviceService } from '../authenticationservice.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { WatchlistInterface } from '../WatchlistInterface';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-watchorunwatch',
  templateUrl: './watchorunwatch.component.html',
  styleUrls: ['./watchorunwatch.component.css']
})
export class WatchorunwatchComponent implements OnInit {

  constructor(public firestore: AngularFirestore
    , public auth: AngularFireAuth
    , public authservice: AuthenticationserviceService) {

  }
  ngOnInit(): void {

    this.firestore.collection<WatchlistInterface>('/watchlist', ref => ref.where("emailid", "==", this.authservice.authuser.email).limit(1))
      .valueChanges({ idField: 'documentid' })
      .subscribe(register => {
        this.watchList = register[0].name;
        this.currentDocumentID = register[0].documentid
      });


  }
  @Input()
  stickerName: string = "";
  currentDocumentID;
  watchList: string[] = [];
  falseValue = false;
  trueValue = true;
  AddWatch() {
    if (!this.authservice.isLoggedIn) {
      this.authservice.loginWithGmail();
      return;

    }


    this.watchList.push(this.stickerName);

    this.firestore.doc('/watchlist/' + this.currentDocumentID)
      .update({
        name: this.watchList

      });


  }
  RemoveWatch() {
    this.watchList = this.watchList.filter(item => item !== this.stickerName);

    this.firestore.doc('/watchlist/' + this.currentDocumentID)
      .update({
        name: this.watchList

      });

  }
}
