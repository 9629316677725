import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { WatchlistComponent } from './watchlist/watchlist.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { PostComponent } from './post/post.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ViewprofileComponent } from './viewprofile/viewprofile.component';
import { EditprofileComponent } from './editprofile/editprofile.component';
import { SettingsComponent } from './settings/settings.component';
import { HttpClientModule } from "@angular/common/http";
import { HttpClientJsonpModule } from "@angular/common/http";


import { AngularFireAuthGuard } from '@angular/fire/auth-guard';


import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,
  //StorageBucket
} from "@angular/fire/storage";

import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadphotoComponent } from './uploadphoto/uploadphoto.component';
import { SymbolComponent } from './symbol/symbol.component';
import { SafehtmlPipe } from './safehtml.pipe';
import {  LinkifyPipe } from  "./linkify.pipe";
import {  Utf8EmojisToImagesPipe } from  "./Utf8EmojisToImages.pipe";


import { HomeComponent } from './home/home.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WatchorunwatchComponent } from './watchorunwatch/watchorunwatch.component';
import { FintwitscontrolComponent } from './fintwitscontrol/fintwitscontrol.component';
import { PostcontrolComponent } from './postcontrol/postcontrol.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { CompanysearchcontrolComponent } from './companysearchcontrol/companysearchcontrol.component';
import { RecentpostsComponent } from './recentposts/recentposts.component';
import { TickerInfoComponent } from './ticker-info/ticker-info.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SigninComponent } from './signin/signin.component';
import { StockscrollComponent } from './stockscroll/stockscroll.component';
import { CentralchartComponent } from './centralchart/centralchart.component';
import { NewsComponent } from './news/news.component';


@NgModule({
  declarations: [
    AppComponent,
    WatchlistComponent,
    SignupComponent,
    LoginComponent,
    PostComponent,
    ViewprofileComponent,
    EditprofileComponent,
    SettingsComponent,
    UploadphotoComponent,
    SymbolComponent,
    SafehtmlPipe,
    LinkifyPipe,
    Utf8EmojisToImagesPipe,
    HomeComponent,
    WatchorunwatchComponent,
    FintwitscontrolComponent,
    PostcontrolComponent,
    CompanysearchcontrolComponent,
    RecentpostsComponent,
    TickerInfoComponent,
    SigninComponent,
    StockscrollComponent,
    CentralchartComponent,
    NewsComponent
  ],
  imports: [
    PickerModule,
    BrowserModule,
    HttpClientJsonpModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AngularFireStorageModule,
    ImageCropperModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      // { path: '', component: LoginComponent },

      { path: 'posts', component: PostComponent, canActivate: [AngularFireAuthGuard] },
      { path: 'recentposts', component: RecentpostsComponent, canActivate: [AngularFireAuthGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AngularFireAuthGuard] },
      { path: 'editprofile', component: EditprofileComponent, canActivate: [AngularFireAuthGuard] },

      { path: 'viewprofile', component: ViewprofileComponent, canActivate: [AngularFireAuthGuard] },
      { path: 'uploadphoto', component: UploadphotoComponent, canActivate: [AngularFireAuthGuard] },
      { path: 'symbol/:code', component: SymbolComponent },
     
      { path: '', outlet: "forWatchList",component: WatchlistComponent, canActivate: [AngularFireAuthGuard] },
      // { path: '', outlet: "forNews",component: NewsComponent},

      
      { path: 'home', component: HomeComponent },

      //{ path: 'signup', component: SignupComponent },
      //{ path: 'signin', component: SigninComponent },

      
      // { path: 'symbol', component: SymbolComponent, canActivate: [AngularFireAuthGuard]  },



    ]
      //,{ enableTracing: true }// debugging purpose
    ),
    FontAwesomeModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(
      {
        timeOut: 2000,
        positionClass: 'toast-bottom-center'
        // preventDuplicates: true,
      }
    ), // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
