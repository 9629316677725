import { NSE_Equity_Interface } from './NSE_Equity_Interface';
//convert excel sheet to json
//http://beautifytools.com/excel-to-json-converter.php
//my google sheets
export const NSE_Equity:NSE_Equity_Interface[]=[
            {
                "Exchange": "NSE",
                "SecurityCode": "INE144J01027",
                "SecurityId": "20MICRONS",
                "SecurityName": "20 MICRONS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE144J01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE253B01015",
                "SecurityId": "21STCENMGM",
                "SecurityName": "21ST CENTURY MANAGEMENT SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE253B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE748C01020",
                "SecurityId": "3IINFOTECH",
                "SecurityName": "3I INFOTECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE748C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE470A01017",
                "SecurityId": "3MINDIA",
                "SecurityName": "3M INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE470A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE105C01023",
                "SecurityId": "3PLAND",
                "SecurityName": "3P LAND HOLDINGS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE105C01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE618L01018",
                "SecurityId": "5PAISA",
                "SecurityName": "5PAISA CAPITAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE618L01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE111B01023",
                "SecurityId": "63MOONS",
                "SecurityName": "63 MOONS TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE111B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE619I01012",
                "SecurityId": "A2ZINFRA",
                "SecurityName": "A2Z INFRA ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE619I01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE767A01016",
                "SecurityId": "AARTIDRUGS",
                "SecurityName": "AARTI DRUGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE767A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE769A01020",
                "SecurityId": "AARTIIND",
                "SecurityName": "AARTI INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE769A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE273D01019",
                "SecurityId": "AARVEEDEN",
                "SecurityName": "AARVEE DENIMS & EXPORTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE273D01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE216P01012",
                "SecurityId": "AAVAS",
                "SecurityName": "AAVAS FINANCIERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE216P01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE421A01028",
                "SecurityId": "ABAN",
                "SecurityName": "ABAN OFFSHORE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE421A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE117A01022",
                "SecurityId": "ABB",
                "SecurityName": "ABB INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE117A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE358A01014",
                "SecurityId": "ABBOTINDIA",
                "SecurityName": "ABBOTT INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE358A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE674K01013",
                "SecurityId": "ABCAPITAL",
                "SecurityName": "ADITYA BIRLA CAPITAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE674K01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE647O01011",
                "SecurityId": "ABFRL",
                "SecurityName": "ADITYA BIRLA FASHION AND RETAIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE647O01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE251C01017",
                "SecurityId": "ABMINTLTD",
                "SecurityName": "ABM INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE251C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE012A01025",
                "SecurityId": "ACC",
                "SecurityName": "ACC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE012A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE793A01012",
                "SecurityId": "ACCELYA",
                "SecurityName": "ACCELYA SOLUTIONS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE793A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE731H01025",
                "SecurityId": "ACE",
                "SecurityName": "ACTION CONSTRUCTION EQUIPMENT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE731H01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE423A01024",
                "SecurityId": "ADANIENT",
                "SecurityName": "ADANI ENTERPRISES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE423A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE399L01023",
                "SecurityId": "ADANIGAS",
                "SecurityName": "ADANI GAS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE399L01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE364U01010",
                "SecurityId": "ADANIGREEN",
                "SecurityName": "ADANI GREEN ENERGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE364U01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE742F01042",
                "SecurityId": "ADANIPORTS",
                "SecurityName": "ADANI PORTS AND SPECIAL ECONOMIC ZONE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE742F01042",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE814H01011",
                "SecurityId": "ADANIPOWER",
                "SecurityName": "ADANI POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE814H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE931S01010",
                "SecurityId": "ADANITRANS",
                "SecurityName": "ADANI TRANSMISSION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE931S01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE982B01019",
                "SecurityId": "ADFFOODS",
                "SecurityName": "ADF FOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE982B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE452L01012",
                "SecurityId": "ADHUNIKIND",
                "SecurityName": "ADHUNIK INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE452L01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE172N01012",
                "SecurityId": "ADLABS",
                "SecurityName": "ADLABS ENTERTAINMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE172N01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE045A01017",
                "SecurityId": "ADORWELD",
                "SecurityName": "ADOR WELDING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE045A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE737B01033",
                "SecurityId": "ADROITINFO",
                "SecurityName": "ADROIT INFOTECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE737B01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE102I01027",
                "SecurityId": "ADSL",
                "SecurityName": "ALLIED DIGITAL SERVICES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE102I01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE199C01026",
                "SecurityId": "ADVANIHOTR",
                "SecurityName": "ADVANI HOTELS & RESORTS (INDIA) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE199C01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE837H01020",
                "SecurityId": "ADVENZYMES",
                "SecurityName": "ADVANCED ENZYME TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE837H01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE208C01025",
                "SecurityId": "AEGISCHEM",
                "SecurityName": "AEGIS LOGISTICS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE208C01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE00WC01019",
                "SecurityId": "AFFLE",
                "SecurityName": "AFFLE (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE00WC01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE204E01012",
                "SecurityId": "AGARIND",
                "SecurityName": "AGARWAL INDUSTRIAL CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE204E01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE676A01019",
                "SecurityId": "AGCNET",
                "SecurityName": "AGC NETWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE676A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE449G01018",
                "SecurityId": "AGRITECH",
                "SecurityName": "AGRI-TECH (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE449G01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE740V01019",
                "SecurityId": "AGROPHOS",
                "SecurityName": "AGRO PHOS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE740V01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE926K01017",
                "SecurityId": "AHLEAST",
                "SecurityName": "ASIAN HOTELS (EAST) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE926K01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE758C01029",
                "SecurityId": "AHLUCONT",
                "SecurityName": "AHLUWALIA CONTRACTS (INDIA) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE758C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE915K01010",
                "SecurityId": "AHLWEST",
                "SecurityName": "ASIAN HOTELS (WEST) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE915K01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE212H01026",
                "SecurityId": "AIAENG",
                "SecurityName": "AIA ENGINEERING LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE212H01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE743C01021",
                "SecurityId": "AIONJSW",
                "SecurityName": "MONNET ISPAT & ENERGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE743C01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE645W01026",
                "SecurityId": "AIRAN",
                "SecurityName": "AIRAN LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE645W01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE031B01049",
                "SecurityId": "AJANTPHARM",
                "SecurityName": "AJANTA PHARMA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE031B01049",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE298G01027",
                "SecurityId": "AJMERA",
                "SecurityName": "AJMERA REALTY & INFRA INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE298G01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE737W01013",
                "SecurityId": "AKASH",
                "SecurityName": "AKASH INFRA-PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE737W01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE542B01011",
                "SecurityId": "AKSHARCHEM",
                "SecurityName": "AKSHARCHEM INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE542B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE523B01011",
                "SecurityId": "AKSHOPTFBR",
                "SecurityName": "AKSH OPTIFIBRE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE523B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE133A01011",
                "SecurityId": "AKZOINDIA",
                "SecurityName": "AKZO NOBEL INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE133A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE914E01040",
                "SecurityId": "ALANKIT",
                "SecurityName": "ALANKIT LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE914E01040",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE155C01010",
                "SecurityId": "ALBERTDAVD",
                "SecurityName": "ALBERT DAVID LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE155C01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE428A01015",
                "SecurityId": "ALBK",
                "SecurityName": "ALLAHABAD BANK",
                "FaceValue": "10",
                "ISINNo": "INE428A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE964B01033",
                "SecurityId": "ALCHEM",
                "SecurityName": "ALCHEMIST LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE964B01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE426A01027",
                "SecurityId": "ALEMBICLTD",
                "SecurityName": "ALEMBIC LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE426A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE062D01024",
                "SecurityId": "ALICON",
                "SecurityName": "ALICON CASTALLOY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE062D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE773I01017",
                "SecurityId": "ALKALI",
                "SecurityName": "ALKALI METALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE773I01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE540L01014",
                "SecurityId": "ALKEM",
                "SecurityName": "ALKEM LABORATORIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE540L01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE150B01021",
                "SecurityId": "ALKYLAMINE",
                "SecurityName": "ALKYL AMINES CHEMICALS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE150B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE418H01029",
                "SecurityId": "ALLCARGO",
                "SecurityName": "ALLCARGO LOGISTICS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE418H01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE835G01018",
                "SecurityId": "ALLSEC",
                "SecurityName": "ALLSEC TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE835G01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE326B01027",
                "SecurityId": "ALMONDZ",
                "SecurityName": "ALMONDZ GLOBAL SECURITIES LIMITED",
                "FaceValue": "6",
                "ISINNo": "INE326B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE270A01029",
                "SecurityId": "ALOKINDS",
                "SecurityName": "ALOK INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE270A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE385I01010",
                "SecurityId": "ALPA",
                "SecurityName": "ALPA LABORATORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE385I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE137C01018",
                "SecurityId": "ALPHAGEO",
                "SecurityName": "ALPHAGEO (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE137C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE093B01015",
                "SecurityId": "ALPSINDUS",
                "SecurityName": "ALPS INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE093B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE885A01032",
                "SecurityId": "AMARAJABAT",
                "SecurityName": "AMARA RAJA BATTERIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE885A01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE371P01015",
                "SecurityId": "AMBER",
                "SecurityName": "AMBER ENTERPRISES INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE371P01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE540G01014",
                "SecurityId": "AMBIKCO",
                "SecurityName": "AMBIKA COTTON MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE540G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE079A01024",
                "SecurityId": "AMBUJACEM",
                "SecurityName": "AMBUJA CEMENTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE079A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE005I01014",
                "SecurityId": "AMDIND",
                "SecurityName": "AMD INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE005I01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE606A01024",
                "SecurityId": "AMJLAND",
                "SecurityName": "AMJ LAND HOLDINGS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE606A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE098F01031",
                "SecurityId": "AMRUTANJAN",
                "SecurityName": "AMRUTANJAN HEALTH CARE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE098F01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE242C01024",
                "SecurityId": "ANANTRAJ",
                "SecurityName": "ANANT RAJ LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE242C01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE434A01013",
                "SecurityId": "ANDHRABANK",
                "SecurityName": "ANDHRA BANK",
                "FaceValue": "10",
                "ISINNo": "INE434A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE666E01012",
                "SecurityId": "ANDHRACEMT",
                "SecurityName": "ANDHRA CEMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE666E01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE435A01028",
                "SecurityId": "ANDHRAPAP",
                "SecurityName": "ANDHRA PAPER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE435A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE715B01013",
                "SecurityId": "ANDHRSUGAR",
                "SecurityName": "THE ANDHRA SUGARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE715B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE087B01017",
                "SecurityId": "ANIKINDS",
                "SecurityName": "ANIK INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE087B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE106I01010",
                "SecurityId": "ANKITMETAL",
                "SecurityName": "ANKIT METAL & POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE106I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE436A01026",
                "SecurityId": "ANSALAPI",
                "SecurityName": "ANSAL PROPERTIES & INFRASTRUCTURE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE436A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE880B01015",
                "SecurityId": "ANSALHSG",
                "SecurityName": "ANSAL HOUSING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE880B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE294Z01018",
                "SecurityId": "ANUP",
                "SecurityName": "THE ANUP ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE294Z01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE372A01015",
                "SecurityId": "APARINDS",
                "SecurityName": "APAR INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE372A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE071F01012",
                "SecurityId": "APCL",
                "SecurityName": "ANJANI PORTLAND CEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE071F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE116A01032",
                "SecurityId": "APCOTEXIND",
                "SecurityName": "APCOTEX INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE116A01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE346W01013",
                "SecurityId": "APEX",
                "SecurityName": "APEX FROZEN FOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE346W01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE702C01019",
                "SecurityId": "APLAPOLLO",
                "SecurityName": "APL APOLLO TUBES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE702C01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE901L01018",
                "SecurityId": "APLLTD",
                "SecurityName": "ALEMBIC PHARMACEUTICALS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE901L01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE713T01010",
                "SecurityId": "APOLLO",
                "SecurityName": "APOLLO MICRO SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE713T01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE437A01024",
                "SecurityId": "APOLLOHOSP",
                "SecurityName": "APOLLO HOSPITALS ENTERPRISE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE437A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE126J01016",
                "SecurityId": "APOLLOPIPE",
                "SecurityName": "APOLLO PIPES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE126J01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE438A01022",
                "SecurityId": "APOLLOTYRE",
                "SecurityName": "APOLLO TYRES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE438A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE451F01024",
                "SecurityId": "APOLSINHOT",
                "SecurityName": "APOLLO SINDOORI HOTELS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE451F01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE266F01018",
                "SecurityId": "APTECHT",
                "SecurityName": "APTECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE266F01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE877I01016",
                "SecurityId": "ARCHIDPLY",
                "SecurityName": "ARCHIDPLY INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE877I01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE731A01020",
                "SecurityId": "ARCHIES",
                "SecurityName": "ARCHIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE731A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE574I01035",
                "SecurityId": "ARCOTECH",
                "SecurityName": "ARCOTECH LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE574I01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE610C01014",
                "SecurityId": "ARENTERP",
                "SecurityName": "RAJDARSHAN INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE610C01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE298I01015",
                "SecurityId": "ARIES",
                "SecurityName": "ARIES AGRO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE298I01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE413D01011",
                "SecurityId": "ARIHANT",
                "SecurityName": "ARIHANT FOUNDATIONS & HOUSING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE413D01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE643K01018",
                "SecurityId": "ARIHANTSUP",
                "SecurityName": "ARIHANT SUPERSTRUCTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE643K01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE109C01017",
                "SecurityId": "ARMANFIN",
                "SecurityName": "ARMAN FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE109C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE210C01013",
                "SecurityId": "AROGRANITE",
                "SecurityName": "ARO GRANITE INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE210C01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE570D01018",
                "SecurityId": "ARROWGREEN",
                "SecurityName": "ARROW GREENTECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE570D01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE968D01022",
                "SecurityId": "ARSHIYA",
                "SecurityName": "ARSHIYA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE968D01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE267I01010",
                "SecurityId": "ARSSINFRA",
                "SecurityName": "ARSS INFRASTRUCTURE PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE267I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE025R01013",
                "SecurityId": "ARTEMISMED",
                "SecurityName": "ARTEMIS MEDICARE SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE025R01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE034A01011",
                "SecurityId": "ARVIND",
                "SecurityName": "ARVIND LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE034A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE955V01021",
                "SecurityId": "ARVINDFASN",
                "SecurityName": "ARVIND FASHIONS LIMITED",
                "FaceValue": "4",
                "ISINNo": "INE955V01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE034S01021",
                "SecurityId": "ARVSMART",
                "SecurityName": "ARVIND SMARTSPACES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE034S01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE439A01020",
                "SecurityId": "ASAHIINDIA",
                "SecurityName": "ASAHI INDIA GLASS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE439A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE228I01012",
                "SecurityId": "ASAHISONG",
                "SecurityName": "ASAHI SONGWON COLORS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE228I01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE900C01027",
                "SecurityId": "ASAL",
                "SecurityName": "AUTOMOTIVE STAMPINGS AND ASSEMBLIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE900C01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE073G01016",
                "SecurityId": "ASALCBR",
                "SecurityName": "ASSOCIATED ALCOHOLS & BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE073G01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE348A01023",
                "SecurityId": "ASHAPURMIN",
                "SecurityName": "ASHAPURA MINECHEM LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE348A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE365D01021",
                "SecurityId": "ASHIANA",
                "SecurityName": "ASHIANA HOUSING LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE365D01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE440A01010",
                "SecurityId": "ASHIMASYN",
                "SecurityName": "ASHIMA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE440A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE442H01029",
                "SecurityId": "ASHOKA",
                "SecurityName": "ASHOKA BUILDCON LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE442H01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE208A01029",
                "SecurityId": "ASHOKLEY",
                "SecurityName": "ASHOK LEYLAND LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE208A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE363A01022",
                "SecurityId": "ASIANHOTNR",
                "SecurityName": "ASIAN HOTELS (NORTH) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE363A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE021A01026",
                "SecurityId": "ASIANPAINT",
                "SecurityName": "ASIAN PAINTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE021A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE022I01019",
                "SecurityId": "ASIANTILES",
                "SecurityName": "ASIAN GRANITO INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE022I01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE991I01015",
                "SecurityId": "ASPINWALL",
                "SecurityName": "ASPINWALL AND COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE991I01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE563J01010",
                "SecurityId": "ASTEC",
                "SecurityName": "ASTEC LIFESCIENCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE563J01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE914M01019",
                "SecurityId": "ASTERDM",
                "SecurityName": "ASTER DM HEALTHCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE914M01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE006I01046",
                "SecurityId": "ASTRAL",
                "SecurityName": "ASTRAL POLY TECHNIK LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE006I01046",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE386C01029",
                "SecurityId": "ASTRAMICRO",
                "SecurityName": "ASTRA MICROWAVE PRODUCTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE386C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE203A01020",
                "SecurityId": "ASTRAZEN",
                "SecurityName": "ASTRAZENECA PHARMA INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE203A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE646X01014",
                "SecurityId": "ASTRON",
                "SecurityName": "ASTRON PAPER & BOARD MILL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE646X01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE209A01019",
                "SecurityId": "ATFL",
                "SecurityName": "AGRO TECH FOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE209A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE285H01022",
                "SecurityId": "ATLANTA",
                "SecurityName": "ATLANTA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE285H01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE446A01025",
                "SecurityId": "ATLASCYCLE",
                "SecurityName": "ATLAS CYCLES (HARYANA) LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE446A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE803A01027",
                "SecurityId": "ATNINTER",
                "SecurityName": "ATN INTERNATIONAL LIMITED",
                "FaceValue": "4",
                "ISINNo": "INE803A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE100A01010",
                "SecurityId": "ATUL",
                "SecurityName": "ATUL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE100A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE951D01028",
                "SecurityId": "ATULAUTO",
                "SecurityName": "ATUL AUTO LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE951D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE949L01017",
                "SecurityId": "AUBANK",
                "SecurityName": "AU SMALL FINANCE BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE949L01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE132H01018",
                "SecurityId": "AURIONPRO",
                "SecurityName": "AURIONPRO SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE132H01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE406A01037",
                "SecurityId": "AUROPHARMA",
                "SecurityName": "AUROBINDO PHARMA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE406A01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE218C01016",
                "SecurityId": "AUSOMENT",
                "SecurityName": "AUSOM ENTERPRISE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE218C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE449A01011",
                "SecurityId": "AUTOAXLES",
                "SecurityName": "AUTOMOTIVE AXLES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE449A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE718H01014",
                "SecurityId": "AUTOIND",
                "SecurityName": "AUTOLINE INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE718H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE448A01013",
                "SecurityId": "AUTOLITIND",
                "SecurityName": "AUTOLITE (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE448A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE349W01017",
                "SecurityId": "AVADHSUGAR",
                "SecurityName": "AVADH SUGAR & ENERGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE349W01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE871C01038",
                "SecurityId": "AVANTIFEED",
                "SecurityName": "AVANTI FEEDS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE871C01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE488D01021",
                "SecurityId": "AVTNPL",
                "SecurityName": "AVT NATURAL PRODUCTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE488D01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE238A01034",
                "SecurityId": "AXISBANK",
                "SecurityName": "AXIS BANK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE238A01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE555B01013",
                "SecurityId": "AXISCADES",
                "SecurityName": "AXISCADES ENGINEERING TECHNOLOGIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE555B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE193B01039",
                "SecurityId": "AYMSYNTEX",
                "SecurityName": "AYM SYNTEX LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE193B01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE878I01022",
                "SecurityId": "BAFNAPH",
                "SecurityName": "BAFNA PHARMACEUTICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE878I01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE116D01028",
                "SecurityId": "BAGFILMS",
                "SecurityName": "B.A.G FILMS AND MEDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE116D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE917I01010",
                "SecurityId": "BAJAJ-AUTO",
                "SecurityName": "BAJAJ AUTO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE917I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE933K01021",
                "SecurityId": "BAJAJCON",
                "SecurityName": "BAJAJ CONSUMER CARE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE933K01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE193E01025",
                "SecurityId": "BAJAJELEC",
                "SecurityName": "BAJAJ ELECTRICALS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE193E01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE918I01018",
                "SecurityId": "BAJAJFINSV",
                "SecurityName": "BAJAJ FINSERV LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE918I01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE306A01021",
                "SecurityId": "BAJAJHIND",
                "SecurityName": "BAJAJ HINDUSTHAN SUGAR LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE306A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE118A01012",
                "SecurityId": "BAJAJHLDNG",
                "SecurityName": "BAJAJ HOLDINGS & INVESTMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE118A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE296A01024",
                "SecurityId": "BAJFINANCE",
                "SecurityName": "BAJAJ FINANCE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE296A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE794B01026",
                "SecurityId": "BALAJITELE",
                "SecurityName": "BALAJI TELEFILMS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE794B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE050E01027",
                "SecurityId": "BALAMINES",
                "SecurityName": "BALAJI AMINES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE050E01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE618N01014",
                "SecurityId": "BALAXI",
                "SecurityName": "BALAXI VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE618N01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE875R01011",
                "SecurityId": "BALKRISHNA",
                "SecurityName": "BALKRISHNA PAPER MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE875R01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE787D01026",
                "SecurityId": "BALKRISIND",
                "SecurityName": "BALKRISHNA INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE787D01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE294A01037",
                "SecurityId": "BALLARPUR",
                "SecurityName": "BALLARPUR INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE294A01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE164A01016",
                "SecurityId": "BALMLAWRIE",
                "SecurityName": "BALMER LAWRIE & COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE164A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE083D01012",
                "SecurityId": "BALPHARMA",
                "SecurityName": "BAL PHARMA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE083D01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE119A01028",
                "SecurityId": "BALRAMCHIN",
                "SecurityName": "BALRAMPUR CHINI MILLS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE119A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE655B01011",
                "SecurityId": "BANARBEADS",
                "SecurityName": "BANARAS BEADS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE655B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE459A01010",
                "SecurityId": "BANARISUG",
                "SecurityName": "BANNARI AMMAN SUGARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE459A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE213C01025",
                "SecurityId": "BANCOINDIA",
                "SecurityName": "BANCO PRODUCTS (I) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE213C01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE545U01014",
                "SecurityId": "BANDHANBNK",
                "SecurityName": "BANDHAN BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE545U01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE863I01016",
                "SecurityId": "BANG",
                "SecurityName": "BANG OVERSEAS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE863I01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE028A01039",
                "SecurityId": "BANKBARODA",
                "SecurityName": "BANK OF BARODA",
                "FaceValue": "2",
                "ISINNo": "INE028A01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE084A01016",
                "SecurityId": "BANKINDIA",
                "SecurityName": "BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE084A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE629D01012",
                "SecurityId": "BANSWRAS",
                "SecurityName": "BANSWARA SYNTEX LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE629D01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE855F01034",
                "SecurityId": "BARTRONICS",
                "SecurityName": "BARTRONICS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE855F01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE373A01013",
                "SecurityId": "BASF",
                "SecurityName": "BASF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE373A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE186H01014",
                "SecurityId": "BASML",
                "SecurityName": "BANNARI AMMAN SPINNING MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE186H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE176A01028",
                "SecurityId": "BATAINDIA",
                "SecurityName": "BATA INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE176A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE462A01022",
                "SecurityId": "BAYERCROP",
                "SecurityName": "BAYER CROPSCIENCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE462A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE464A01028",
                "SecurityId": "BBL",
                "SecurityName": "BHARAT BIJLEE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE464A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE050A01025",
                "SecurityId": "BBTC",
                "SecurityName": "BOMBAY BURMAH TRADING CORPORATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE050A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE425B01027",
                "SecurityId": "BCG",
                "SecurityName": "BRIGHTCOM GROUP LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE425B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE905P01028",
                "SecurityId": "BCP",
                "SecurityName": "B.C. POWER CONTROLS LTD",
                "FaceValue": "2",
                "ISINNo": "INE905P01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE171Z01018",
                "SecurityId": "BDL",
                "SecurityName": "BHARAT DYNAMICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE171Z01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE520H01022",
                "SecurityId": "BEARDSELL",
                "SecurityName": "BEARDSELL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE520H01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE844K01012",
                "SecurityId": "BEDMUTHA",
                "SecurityName": "BEDMUTHA INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE844K01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE263A01024",
                "SecurityId": "BEL",
                "SecurityName": "BHARAT ELECTRONICS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE263A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE258A01016",
                "SecurityId": "BEML",
                "SecurityName": "BEML LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE258A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE922A01025",
                "SecurityId": "BEPL",
                "SecurityName": "BHANSALI ENGINEERING POLYMERS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE922A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE463A01038",
                "SecurityId": "BERGEPAINT",
                "SecurityName": "BERGER PAINTS (I) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE463A01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE878K01010",
                "SecurityId": "BFINVEST",
                "SecurityName": "BF INVESTMENT LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE878K01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE243D01012",
                "SecurityId": "BFUTILITIE",
                "SecurityName": "BF UTILITIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE243D01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE661I01014",
                "SecurityId": "BGRENERGY",
                "SecurityName": "BGR ENERGY SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE661I01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE354C01027",
                "SecurityId": "BHAGERIA",
                "SecurityName": "BHAGERIA INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE354C01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE458B01036",
                "SecurityId": "BHAGYANGR",
                "SecurityName": "BHAGYANAGAR INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE458B01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE363W01018",
                "SecurityId": "BHAGYAPROP",
                "SecurityName": "BHAGYANAGAR PROPERTIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE363W01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE474E01029",
                "SecurityId": "BHANDARI",
                "SecurityName": "BHANDARI HOSIERY EXPORTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE474E01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE465A01025",
                "SecurityId": "BHARATFORG",
                "SecurityName": "BHARAT FORGE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE465A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE561C01019",
                "SecurityId": "BHARATGEAR",
                "SecurityName": "BHARAT GEARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE561C01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE838B01013",
                "SecurityId": "BHARATRAS",
                "SecurityName": "BHARAT RASAYAN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE838B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE316L01019",
                "SecurityId": "BHARATWIRE",
                "SecurityName": "BHARAT WIRE ROPES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE316L01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE397D01024",
                "SecurityId": "BHARTIARTL",
                "SecurityName": "BHARTI AIRTEL LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE397D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE257A01026",
                "SecurityId": "BHEL",
                "SecurityName": "BHARAT HEAVY ELECTRICALS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE257A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE412U01017",
                "SecurityId": "BIGBLOC",
                "SecurityName": "BIGBLOC CONSTRUCTION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE412U01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE828A01016",
                "SecurityId": "BIL",
                "SecurityName": "BHARTIYA INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE828A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE607L01029",
                "SecurityId": "BILENERGY",
                "SecurityName": "BIL ENERGY SYSTEMS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE607L01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE143A01010",
                "SecurityId": "BINDALAGRO",
                "SecurityName": "OSWAL CHEMICALS & FERTILIZERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE143A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE376G01013",
                "SecurityId": "BIOCON",
                "SecurityName": "BIOCON LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE376G01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE829A01014",
                "SecurityId": "BIOFILCHEM",
                "SecurityName": "BIOFIL CHEMICALS & PHARMACEUTICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE829A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE800A01015",
                "SecurityId": "BIRLACABLE",
                "SecurityName": "BIRLA CABLE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE800A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE340A01012",
                "SecurityId": "BIRLACORPN",
                "SecurityName": "BIRLA CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE340A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE865C01022",
                "SecurityId": "BIRLAMONEY",
                "SecurityName": "ADITYA BIRLA MONEY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE865C01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE0AEJ01013",
                "SecurityId": "BIRLATYRE",
                "SecurityName": "BIRLA TYRES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE0AEJ01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE831Q01016",
                "SecurityId": "BKMINDST",
                "SecurityName": "BKM INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE831Q01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE791A01024",
                "SecurityId": "BLBLIMITED",
                "SecurityName": "BLB LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE791A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE416D01022",
                "SecurityId": "BLISSGVS",
                "SecurityName": "BLISS GVS PHARMA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE416D01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE350H01032",
                "SecurityId": "BLKASHYAP",
                "SecurityName": "B. L. KASHYAP AND SONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE350H01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE153T01027",
                "SecurityId": "BLS",
                "SecurityName": "BLS INTERNATIONAL SERVICES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE153T01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE657B01025",
                "SecurityId": "BLUECHIP",
                "SecurityName": "BLUE CHIP INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE657B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE472B01011",
                "SecurityId": "BLUECOAST",
                "SecurityName": "BLUE COAST HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE472B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE233B01017",
                "SecurityId": "BLUEDART",
                "SecurityName": "BLUE DART EXPRESS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE233B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE472A01039",
                "SecurityId": "BLUESTARCO",
                "SecurityName": "BLUE STAR LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE472A01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE338D01028",
                "SecurityId": "BODALCHEM",
                "SecurityName": "BODAL CHEMICALS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE338D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE032A01023",
                "SecurityId": "BOMDYEING",
                "SecurityName": "BOMBAY DYEING & MFG COMPANY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE032A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE666D01022",
                "SecurityId": "BORORENEW",
                "SecurityName": "BOROSIL RENEWABLES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE666D01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE323A01026",
                "SecurityId": "BOSCHLTD",
                "SecurityName": "BOSCH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE323A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE029A01011",
                "SecurityId": "BPCL",
                "SecurityName": "BHARAT PETROLEUM CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE029A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE110A01019",
                "SecurityId": "BPL",
                "SecurityName": "BPL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE110A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE589G01011",
                "SecurityId": "BRFL",
                "SecurityName": "BOMBAY RAYON FASHIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE589G01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE791I01019",
                "SecurityId": "BRIGADE",
                "SecurityName": "BRIGADE ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE791I01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE216A01030",
                "SecurityId": "BRITANNIA",
                "SecurityName": "BRITANNIA INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE216A01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE727S01012",
                "SecurityId": "BRNL",
                "SecurityName": "BHARAT ROAD NETWORK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE727S01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE650L01011",
                "SecurityId": "BROOKS",
                "SecurityName": "BROOKS LABORATORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE650L01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE118H01025",
                "SecurityId": "BSE",
                "SecurityName": "BSE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE118H01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE395A01016",
                "SecurityId": "BSELINFRA",
                "SecurityName": "BSEL INFRASTRUCTURE REALTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE395A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE594B01012",
                "SecurityId": "BSL",
                "SecurityName": "BSL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE594B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE836A01035",
                "SecurityId": "BSOFT",
                "SecurityName": "BIRLASOFT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE836A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE817H01014",
                "SecurityId": "BURNPUR",
                "SecurityName": "BURNPUR CEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE817H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE295F01017",
                "SecurityId": "BUTTERFLY",
                "SecurityName": "BUTTERFLY GANDHIMATHI APPLIANCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE295F01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE139I01011",
                "SecurityId": "BVCL",
                "SecurityName": "BARAK VALLEY CEMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE139I01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE319B01014",
                "SecurityId": "BYKE",
                "SecurityName": "THE BYKE HOSPITALITY LTD",
                "FaceValue": "10",
                "ISINNo": "INE319B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE010B01027",
                "SecurityId": "CADILAHC",
                "SecurityName": "CADILA HEALTHCARE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE010B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE526B01014",
                "SecurityId": "CALSOFT",
                "SecurityName": "CALIFORNIA SOFTWARE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE526B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE052I01032",
                "SecurityId": "CAMLINFINE",
                "SecurityName": "CAMLIN FINE SCIENCES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE052I01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE476A01014",
                "SecurityId": "CANBK",
                "SecurityName": "CANARA BANK",
                "FaceValue": "10",
                "ISINNo": "INE476A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE874H01015",
                "SecurityId": "CANDC",
                "SecurityName": "C & C CONSTRUCTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE874H01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE477A01020",
                "SecurityId": "CANFINHOME",
                "SecurityName": "CAN FIN HOMES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE477A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE068L01016",
                "SecurityId": "CANTABIL",
                "SecurityName": "CANTABIL RETAIL INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE068L01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE264T01014",
                "SecurityId": "CAPACITE",
                "SecurityName": "CAPACIT'E INFRAPROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE264T01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE475E01026",
                "SecurityId": "CAPLIPOINT",
                "SecurityName": "CAPLIN POINT LABORATORIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE475E01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE707C01018",
                "SecurityId": "CAPTRUST",
                "SecurityName": "CAPITAL TRUST LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE707C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE120A01034",
                "SecurityId": "CARBORUNIV",
                "SecurityName": "CARBORUNDUM UNIVERSAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE120A01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE521J01018",
                "SecurityId": "CAREERP",
                "SecurityName": "CAREER POINT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE521J01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE752H01013",
                "SecurityId": "CARERATING",
                "SecurityName": "CARE RATINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE752H01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE068D01021",
                "SecurityId": "CASTEXTECH",
                "SecurityName": "CASTEX TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE068D01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE172A01027",
                "SecurityId": "CASTROLIND",
                "SecurityName": "CASTROL INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE172A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE429I01024",
                "SecurityId": "CCCL",
                "SecurityName": "CONSOLIDATED CONSTRUCTION CONSORTIUM LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE429I01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE652F01027",
                "SecurityId": "CCHHL",
                "SecurityName": "COUNTRY CLUB HOSPITALITY & HOLIDAYS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE652F01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE421D01022",
                "SecurityId": "CCL",
                "SecurityName": "CCL PRODUCTS (INDIA) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE421D01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE736A01011",
                "SecurityId": "CDSL",
                "SecurityName": "CENTRAL DEPOSITORY SERVICES (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE736A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE482A01020",
                "SecurityId": "CEATLTD",
                "SecurityName": "CEAT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE482A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE209L01016",
                "SecurityId": "CEBBCO",
                "SecurityName": "COMMERCIAL ENGINEERS & BODY BUILDERS CO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE209L01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE185H01016",
                "SecurityId": "CELEBRITY",
                "SecurityName": "CELEBRITY FASHIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE185H01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE485A01015",
                "SecurityId": "CENTENKA",
                "SecurityName": "CENTURY ENKA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE485A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE281A01026",
                "SecurityId": "CENTEXT",
                "SecurityName": "CENTURY EXTRUSIONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE281A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE483A01010",
                "SecurityId": "CENTRALBK",
                "SecurityName": "CENTRAL BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE483A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE660C01027",
                "SecurityId": "CENTRUM",
                "SecurityName": "CENTRUM CAPITAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE660C01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE320B01020",
                "SecurityId": "CENTUM",
                "SecurityName": "CENTUM ELECTRONICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE320B01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE348B01021",
                "SecurityId": "CENTURYPLY",
                "SecurityName": "CENTURY PLYBOARDS (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE348B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE055A01016",
                "SecurityId": "CENTURYTEX",
                "SecurityName": "CENTURY TEXTILES & INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE055A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE739E01017",
                "SecurityId": "CERA",
                "SecurityName": "CERA SANITARYWARE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE739E01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE345B01019",
                "SecurityId": "CEREBRAINT",
                "SecurityName": "CEREBRA INTEGRATED TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE345B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE486A01013",
                "SecurityId": "CESC",
                "SecurityName": "CESC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE486A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE425Y01011",
                "SecurityId": "CESCVENT",
                "SecurityName": "CESC VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE425Y01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE180C01026",
                "SecurityId": "CGCL",
                "SecurityName": "CAPRI GLOBAL CAPITAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE180C01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE067A01029",
                "SecurityId": "CGPOWER",
                "SecurityName": "CG POWER AND INDUSTRIAL SOLUTIONS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE067A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE427F01016",
                "SecurityId": "CHALET",
                "SecurityName": "CHALET HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE427F01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE085A01013",
                "SecurityId": "CHAMBLFERT",
                "SecurityName": "CHAMBAL FERTILIZERS & CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE085A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE995D01025",
                "SecurityId": "CHEMBOND",
                "SecurityName": "CHEMBOND CHEMICALS LTD",
                "FaceValue": "5",
                "ISINNo": "INE995D01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE783X01023",
                "SecurityId": "CHEMFAB",
                "SecurityName": "CHEMFAB ALKALIS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE783X01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE178A01016",
                "SecurityId": "CHENNPETRO",
                "SecurityName": "CHENNAI PETROLEUM CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE178A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE121A01024",
                "SecurityId": "CHOLAFIN",
                "SecurityName": "CHOLAMANDALAM INVESTMENT AND FINANCE COMPANY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE121A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE149A01033",
                "SecurityId": "CHOLAHLDNG",
                "SecurityName": "CHOLAMANDALAM FINANCIAL HOLDINGS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE149A01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE662C01015",
                "SecurityId": "CHROMATIC",
                "SecurityName": "CHROMATIC INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE662C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE675C01017",
                "SecurityId": "CIGNITITEC",
                "SecurityName": "CIGNITI TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE675C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE184C01028",
                "SecurityId": "CIMMCO",
                "SecurityName": "CIMMCO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE184C01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE704H01022",
                "SecurityId": "CINELINE",
                "SecurityName": "CINELINE INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE704H01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE039B01026",
                "SecurityId": "CINEVISTA",
                "SecurityName": "CINEVISTA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE039B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE059A01026",
                "SecurityId": "CIPLA",
                "SecurityName": "CIPLA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE059A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE391Z01012",
                "SecurityId": "CKFSL",
                "SecurityName": "COX & KINGS FINANCIAL SERVICE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE391Z01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE201M01011",
                "SecurityId": "CLEDUCATE",
                "SecurityName": "CL EDUCATE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE201M01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE492A01029",
                "SecurityId": "CLNINDIA",
                "SecurityName": "CLARIANT CHEMICALS (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE492A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE981B01011",
                "SecurityId": "CMICABLES",
                "SecurityName": "CMI LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE981B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE672K01025",
                "SecurityId": "CNOVAPETRO",
                "SecurityName": "CIL NOVA PETROCHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE672K01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE522F01014",
                "SecurityId": "COALINDIA",
                "SecurityName": "COAL INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE522F01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE704P01017",
                "SecurityId": "COCHINSHIP",
                "SecurityName": "COCHIN SHIPYARD LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE704P01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE259A01022",
                "SecurityId": "COLPAL",
                "SecurityName": "COLGATE PALMOLIVE (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE259A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE070C01037",
                "SecurityId": "COMPINFO",
                "SecurityName": "COMPUAGE INFOCOM LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE070C01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE453B01029",
                "SecurityId": "COMPUSOFT",
                "SecurityName": "COMPUCOM SOFTWARE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE453B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE111A01025",
                "SecurityId": "CONCOR",
                "SecurityName": "CONTAINER CORPORATION OF INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE111A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE552D01024",
                "SecurityId": "CONFIPET",
                "SecurityName": "CONFIDENCE PETROLEUM INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE552D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE025A01027",
                "SecurityId": "CONSOFINVT",
                "SecurityName": "CONSOLIDATED FINVEST & HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE025A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE663B01015",
                "SecurityId": "CONTROLPR",
                "SecurityName": "CONTROL PRINT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE663B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE558D01021",
                "SecurityId": "CORALFINAC",
                "SecurityName": "CORAL INDIA FINANCE & HOUSING LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE558D01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE792I01017",
                "SecurityId": "CORDSCABLE",
                "SecurityName": "CORDS CABLE INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE792I01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE169A01031",
                "SecurityId": "COROMANDEL",
                "SecurityName": "COROMANDEL INTERNATIONAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE169A01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE112A01023",
                "SecurityId": "CORPBANK",
                "SecurityName": "CORPORATION BANK",
                "FaceValue": "2",
                "ISINNo": "INE112A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE757A01017",
                "SecurityId": "COSMOFILMS",
                "SecurityName": "COSMO FILMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE757A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE695B01025",
                "SecurityId": "COUNCODOS",
                "SecurityName": "COUNTRY CONDO'S LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE695B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE008I01026",
                "SecurityId": "COX&KINGS",
                "SecurityName": "COX & KINGS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE008I01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE985W01018",
                "SecurityId": "CREATIVE",
                "SecurityName": "CREATIVE PERIPHERALS AND DISTRIBUTION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE985W01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE230B01021",
                "SecurityId": "CREATIVEYE",
                "SecurityName": "CREATIVE EYE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE230B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE741K01010",
                "SecurityId": "CREDITACC",
                "SecurityName": "CREDITACCESS GRAMEEN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE741K01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE559D01011",
                "SecurityId": "CREST",
                "SecurityName": "CREST VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE559D01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE007A01025",
                "SecurityId": "CRISIL",
                "SecurityName": "CRISIL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE007A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE299U01018",
                "SecurityId": "CROMPTON",
                "SecurityName": "CROMPTON GREAVES CONSUMER ELECTRICALS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE299U01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE679A01013",
                "SecurityId": "CSBBANK",
                "SecurityName": "CSB BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE679A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE627H01017",
                "SecurityId": "CTE",
                "SecurityName": "CAMBRIDGE TECHNOLOGY ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE627H01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE491A01021",
                "SecurityId": "CUB",
                "SecurityName": "CITY UNION BANK LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE491A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE144D01012",
                "SecurityId": "CUBEXTUB",
                "SecurityName": "CUBEX TUBINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE144D01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE298A01020",
                "SecurityId": "CUMMINSIND",
                "SecurityName": "CUMMINS INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE298A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE509F01011",
                "SecurityId": "CUPID",
                "SecurityName": "CUPID LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE509F01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE214A01019",
                "SecurityId": "CYBERTECH",
                "SecurityName": "CYBERTECH SYSTEMS AND SOFTWARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE214A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE136B01020",
                "SecurityId": "CYIENT",
                "SecurityName": "CYIENT LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE136B01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE818H01020",
                "SecurityId": "DAAWAT",
                "SecurityName": "LT FOODS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE818H01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE016A01026",
                "SecurityId": "DABUR",
                "SecurityName": "DABUR INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE016A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE00R701025",
                "SecurityId": "DALBHARAT",
                "SecurityName": "DALMIA BHARAT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE00R701025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE495A01022",
                "SecurityId": "DALMIASUG",
                "SecurityName": "DALMIA BHARAT SUGAR AND INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE495A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE497D01022",
                "SecurityId": "DAMODARIND",
                "SecurityName": "DAMODAR INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE497D01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE365B01017",
                "SecurityId": "DATAMATICS",
                "SecurityName": "DATAMATICS GLOBAL SERVICES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE365B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE950I01011",
                "SecurityId": "DBCORP",
                "SecurityName": "D.B.CORP LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE950I01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE917M01012",
                "SecurityId": "DBL",
                "SecurityName": "DILIP BUILDCON LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE917M01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE879I01012",
                "SecurityId": "DBREALTY",
                "SecurityName": "D B REALTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE879I01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE921B01025",
                "SecurityId": "DBSTOCKBRO",
                "SecurityName": "DB (INTERNATIONAL) STOCK BROKERS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE921B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE385W01011",
                "SecurityId": "DCAL",
                "SecurityName": "DISHMAN CARBOGEN AMCIS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE385W01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE503A01015",
                "SecurityId": "DCBBANK",
                "SecurityName": "DCB BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE503A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE498A01018",
                "SecurityId": "DCM",
                "SecurityName": "DCM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE498A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE891B01012",
                "SecurityId": "DCMFINSERV",
                "SecurityName": "DCM FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE891B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE08KP01019",
                "SecurityId": "DCMNVL",
                "SecurityName": "DCM NOUVELLE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE08KP01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE499A01024",
                "SecurityId": "DCMSHRIRAM",
                "SecurityName": "DCM SHRIRAM LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE499A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE500A01029",
                "SecurityId": "DCW",
                "SecurityName": "DCW LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE500A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE583C01021",
                "SecurityId": "DECCANCE",
                "SecurityName": "DECCAN CEMENTS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE583C01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE501A01019",
                "SecurityId": "DEEPAKFERT",
                "SecurityName": "DEEPAK FERTILIZERS AND PETROCHEMICALS CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE501A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE288B01029",
                "SecurityId": "DEEPAKNTR",
                "SecurityName": "DEEPAK NITRITE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE288B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE677H01012",
                "SecurityId": "DEEPIND",
                "SecurityName": "DEEP INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE677H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE124G01033",
                "SecurityId": "DELTACORP",
                "SecurityName": "DELTA CORP LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE124G01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE393A01011",
                "SecurityId": "DELTAMAGNT",
                "SecurityName": "DELTA MANUFACTURING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE393A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE947J01015",
                "SecurityId": "DEN",
                "SecurityName": "DEN NETWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE947J01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE244A01016",
                "SecurityId": "DENORA",
                "SecurityName": "DE NORA INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE244A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE456C01020",
                "SecurityId": "DFMFOODS",
                "SecurityName": "DFM FOODS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE456C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE03JI01017",
                "SecurityId": "DGCONTENT",
                "SecurityName": "DIGICONTENT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE03JI01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE041A01016",
                "SecurityId": "DHAMPURSUG",
                "SecurityName": "DHAMPUR SUGAR MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE041A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE680A01011",
                "SecurityId": "DHANBANK",
                "SecurityName": "DHANLAXMI BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE680A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE435G01025",
                "SecurityId": "DHANUKA",
                "SecurityName": "DHANUKA AGRITECH LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE435G01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE988C01014",
                "SecurityId": "DHARSUGAR",
                "SecurityName": "DHARANI SUGARS & CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE988C01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE202B01012",
                "SecurityId": "DHFL",
                "SecurityName": "DEWAN HOUSING FINANCE CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE202B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE320L01011",
                "SecurityId": "DHUNINV",
                "SecurityName": "DHUNSERI INVESTMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE320L01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE393P01035",
                "SecurityId": "DIAMONDYD",
                "SecurityName": "PRATAAP SNACKS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE393P01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE989C01012",
                "SecurityId": "DIAPOWER",
                "SecurityName": "DIAMOND POWER INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE989C01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE303A01010",
                "SecurityId": "DICIND",
                "SecurityName": "DIC INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE303A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE927C01020",
                "SecurityId": "DIGISPICE",
                "SecurityName": "DIGISPICE TECHNOLOGIES LIMITED",
                "FaceValue": "3",
                "ISINNo": "INE927C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE731U01010",
                "SecurityId": "DIGJAMLTD",
                "SecurityName": "DIGJAM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE731U01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE836F01026",
                "SecurityId": "DISHTV",
                "SecurityName": "DISH TV INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE836F01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE361B01024",
                "SecurityId": "DIVISLAB",
                "SecurityName": "DIVI'S LABORATORIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE361B01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE935N01012",
                "SecurityId": "DIXON",
                "SecurityName": "DIXON TECHNOLOGIES (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE935N01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE271C01023",
                "SecurityId": "DLF",
                "SecurityName": "DLF LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE271C01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE250K01012",
                "SecurityId": "DLINKINDIA",
                "SecurityName": "D-LINK (INDIA) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE250K01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE192R01011",
                "SecurityId": "DMART",
                "SecurityName": "AVENUE SUPERMARTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE192R01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE016M01021",
                "SecurityId": "DNAMEDIA",
                "SecurityName": "DILIGENT MEDIA CORPORATION LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE016M01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE966A01022",
                "SecurityId": "DOLAT",
                "SecurityName": "DOLAT INVESTMENTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE966A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE325C01035",
                "SecurityId": "DOLLAR",
                "SecurityName": "DOLLAR INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE325C01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE668D01028",
                "SecurityId": "DONEAR",
                "SecurityName": "DONEAR INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE668D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE360C01024",
                "SecurityId": "DPSCLTD",
                "SecurityName": "DPSC LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE360C01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE864X01013",
                "SecurityId": "DPWIRES",
                "SecurityName": "D P WIRES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE864X01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE656K01010",
                "SecurityId": "DQE",
                "SecurityName": "DQ ENTERTAINMENT (INTERNATIONAL) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE656K01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE506A01018",
                "SecurityId": "DREDGECORP",
                "SecurityName": "DREDGING CORPORATION OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE506A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE089A01023",
                "SecurityId": "DRREDDY",
                "SecurityName": "DR. REDDY'S LABORATORIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE089A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE417B01040",
                "SecurityId": "DSSL",
                "SecurityName": "DYNACONS SYSTEMS & SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE417B01040",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE341R01014",
                "SecurityId": "DTIL",
                "SecurityName": "DHUNSERI TEA & INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE341R01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE741L01018",
                "SecurityId": "DUCON",
                "SecurityName": "DUCON INFRATECHNOLOGIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE741L01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE477B01010",
                "SecurityId": "DVL",
                "SecurityName": "DHUNSERI VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE477B01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE366A01041",
                "SecurityId": "DWARKESH",
                "SecurityName": "DWARIKESH SUGAR INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE366A01041",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE221B01012",
                "SecurityId": "DYNAMATECH",
                "SecurityName": "DYNAMATIC TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE221B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE256H01015",
                "SecurityId": "DYNPRO",
                "SecurityName": "DYNEMIC PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE256H01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE962C01027",
                "SecurityId": "EASTSILK",
                "SecurityName": "EASTERN SILK INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE962C01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE268C01029",
                "SecurityId": "EASUNREYRL",
                "SecurityName": "EASUN REYROLLE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE268C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE738I01010",
                "SecurityId": "ECLERX",
                "SecurityName": "ECLERX SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE738I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE532F01054",
                "SecurityId": "EDELWEISS",
                "SecurityName": "EDELWEISS FINANCIAL SERVICES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE532F01054",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE180G01019",
                "SecurityId": "EDL",
                "SecurityName": "EMPEE DISTILLERIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE180G01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE216H01027",
                "SecurityId": "EDUCOMP",
                "SecurityName": "EDUCOMP SOLUTIONS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE216H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE066A01013",
                "SecurityId": "EICHERMOT",
                "SecurityName": "EICHER MOTORS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE066A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE126A01031",
                "SecurityId": "EIDPARRY",
                "SecurityName": "EID PARRY INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE126A01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE276C01014",
                "SecurityId": "EIHAHOTELS",
                "SecurityName": "EIH ASSOCIATED HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE276C01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE230A01023",
                "SecurityId": "EIHOTEL",
                "SecurityName": "EIH LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE230A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE158B01016",
                "SecurityId": "EIMCOELECO",
                "SecurityName": "EIMCO ELECON (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE158B01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE184H01027",
                "SecurityId": "EKC",
                "SecurityName": "EVEREST KANTO CYLINDER LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE184H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE205B01023",
                "SecurityId": "ELECON",
                "SecurityName": "ELECON ENGINEERING COMPANY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE205B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE086A01029",
                "SecurityId": "ELECTCAST",
                "SecurityName": "ELECTROSTEEL CASTINGS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE086A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE822G01016",
                "SecurityId": "ELECTHERM",
                "SecurityName": "ELECTROTHERM (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE822G01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE285A01027",
                "SecurityId": "ELGIEQUIP",
                "SecurityName": "ELGI EQUIPMENTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE285A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE819L01012",
                "SecurityId": "ELGIRUBCO",
                "SecurityName": "ELGI RUBBER COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE819L01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE548C01032",
                "SecurityId": "EMAMILTD",
                "SecurityName": "EMAMI LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE548C01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE830C01026",
                "SecurityId": "EMAMIPAP",
                "SecurityName": "EMAMI PAPER MILLS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE830C01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE778K01012",
                "SecurityId": "EMAMIREAL",
                "SecurityName": "EMAMI REALTY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE778K01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE078A01026",
                "SecurityId": "EMCO",
                "SecurityName": "EMCO LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE078A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE296H01011",
                "SecurityId": "EMKAY",
                "SecurityName": "EMKAY GLOBAL FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE296H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE753K01015",
                "SecurityId": "EMMBI",
                "SecurityName": "EMMBI INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE753K01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE913H01037",
                "SecurityId": "ENDURANCE",
                "SecurityName": "ENDURANCE TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE913H01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE306C01019",
                "SecurityId": "ENERGYDEV",
                "SecurityName": "ENERGY DEVELOPMENT COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE306C01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE510A01028",
                "SecurityId": "ENGINERSIN",
                "SecurityName": "ENGINEERS INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE510A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE265F01028",
                "SecurityId": "ENIL",
                "SecurityName": "ENTERTAINMENT NETWORK (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE265F01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE076H01025",
                "SecurityId": "EON",
                "SecurityName": "EON ELECTRIC LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE076H01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE988K01017",
                "SecurityId": "EQUITAS",
                "SecurityName": "EQUITAS HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE988K01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE406M01024",
                "SecurityId": "ERIS",
                "SecurityName": "ERIS LIFESCIENCES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE406M01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE416L01017",
                "SecurityId": "EROSMEDIA",
                "SecurityName": "EROS INTERNATIONAL MEDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE416L01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE284A01012",
                "SecurityId": "ESABINDIA",
                "SecurityName": "ESAB INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE284A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE042A01014",
                "SecurityId": "ESCORTS",
                "SecurityName": "ESCORTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE042A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE122M01019",
                "SecurityId": "ESSARSHPNG",
                "SecurityName": "ESSAR SHIPPING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE122M01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE255A01020",
                "SecurityId": "ESSELPACK",
                "SecurityName": "ESSEL PROPACK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE255A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE778B01029",
                "SecurityId": "ESTER",
                "SecurityName": "ESTER INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE778B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE649H01011",
                "SecurityId": "EUROCERA",
                "SecurityName": "EURO CERAMICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE649H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE063J01011",
                "SecurityId": "EUROMULTI",
                "SecurityName": "EURO MULTIVISION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE063J01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE022C01012",
                "SecurityId": "EUROTEXIND",
                "SecurityName": "EUROTEX INDUSTRIES AND EXPORTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE022C01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE128A01029",
                "SecurityId": "EVEREADY",
                "SecurityName": "EVEREADY INDUSTRIES INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE128A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE295A01018",
                "SecurityId": "EVERESTIND",
                "SecurityName": "EVEREST INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE295A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE688J01015",
                "SecurityId": "EXCEL",
                "SecurityName": "EXCEL REALTY N INFRA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE688J01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE369A01029",
                "SecurityId": "EXCELINDUS",
                "SecurityName": "EXCEL INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE369A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE302A01020",
                "SecurityId": "EXIDEIND",
                "SecurityName": "EXIDE INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE302A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE201K01015",
                "SecurityId": "EXPLEOSOL",
                "SecurityName": "EXPLEO SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE201K01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE188A01015",
                "SecurityId": "FACT",
                "SecurityName": "FERTILIZERS AND CHEMICALS TRAVANCORE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE188A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE959A01019",
                "SecurityId": "FAIRCHEM",
                "SecurityName": "FAIRCHEM SPECIALITY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE959A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE045J01026",
                "SecurityId": "FCL",
                "SecurityName": "FINEOTEX CHEMICAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE045J01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE220J01025",
                "SecurityId": "FCONSUMER",
                "SecurityName": "FUTURE CONSUMER LIMITED",
                "FaceValue": "6",
                "ISINNo": "INE220J01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE512B01022",
                "SecurityId": "FCSSOFT",
                "SecurityName": "FCS SOFTWARE SOLUTIONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE512B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE258B01022",
                "SecurityId": "FDC",
                "SecurityName": "FDC LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE258B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE171A01029",
                "SecurityId": "FEDERALBNK",
                "SecurityName": "THE FEDERAL BANK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE171A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE623B01027",
                "SecurityId": "FEL",
                "SecurityName": "FUTURE ENTERPRISES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE623B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "IN9623B01058",
                "SecurityId": "FELDVR",
                "SecurityName": "FUTURE ENTERPRISES LIMITED",
                "FaceValue": "2",
                "ISINNo": "IN9623B01058",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE737H01014",
                "SecurityId": "FIEMIND",
                "SecurityName": "FIEM INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE737H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE816B01027",
                "SecurityId": "FILATEX",
                "SecurityName": "FILATEX INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE816B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE235A01022",
                "SecurityId": "FINCABLES",
                "SecurityName": "FINOLEX CABLES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE235A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE686Y01026",
                "SecurityId": "FINEORG",
                "SecurityName": "FINE ORGANIC INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE686Y01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE183A01016",
                "SecurityId": "FINPIPE",
                "SecurityName": "FINOLEX INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE183A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE060J01017",
                "SecurityId": "FLEXITUFF",
                "SecurityName": "FLEXITUFF VENTURES INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE060J01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE452O01016",
                "SecurityId": "FLFL",
                "SecurityName": "FUTURE LIFESTYLE FASHIONS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE452O01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE09N301011",
                "SecurityId": "FLUOROCHEM",
                "SecurityName": "GUJARAT FLUOROCHEMICALS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE09N301011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE529A01010",
                "SecurityId": "FMGOETZE",
                "SecurityName": "FEDERAL-MOGUL GOETZE (INDIA) LIMITED.",
                "FaceValue": "10",
                "ISINNo": "INE529A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE360L01017",
                "SecurityId": "FMNL",
                "SecurityName": "FUTURE MARKET NETWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE360L01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE451A01017",
                "SecurityId": "FORCEMOT",
                "SecurityName": "FORCE MOTORS LTD",
                "FaceValue": "10",
                "ISINNo": "INE451A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE061F01013",
                "SecurityId": "FORTIS",
                "SecurityName": "FORTIS HEALTHCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE061F01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE519A01011",
                "SecurityId": "FOSECOIND",
                "SecurityName": "FOSECO INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE519A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE752P01024",
                "SecurityId": "FRETAIL",
                "SecurityName": "FUTURE RETAIL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE752P01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE935Q01015",
                "SecurityId": "FSC",
                "SecurityName": "FUTURE SUPPLY CHAIN SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE935Q01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE684F01012",
                "SecurityId": "FSL",
                "SecurityName": "FIRSTSOURCE SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE684F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE524A01029",
                "SecurityId": "GABRIEL",
                "SecurityName": "GABRIEL INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE524A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE036B01022",
                "SecurityId": "GAEL",
                "SecurityName": "GUJARAT AMBUJA EXPORTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE036B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE129A01019",
                "SecurityId": "GAIL",
                "SecurityName": "GAIL (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE129A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE482J01021",
                "SecurityId": "GAL",
                "SecurityName": "GYSCOAL ALLOYS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE482J01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE600K01018",
                "SecurityId": "GALAXYSURF",
                "SecurityName": "GALAXY SURFACTANTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE600K01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE297H01019",
                "SecurityId": "GALLANTT",
                "SecurityName": "GALLANTT METAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE297H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE528K01029",
                "SecurityId": "GALLISPAT",
                "SecurityName": "GALLANTT ISPAT LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE528K01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE181G01025",
                "SecurityId": "GAMMNINFRA",
                "SecurityName": "GAMMON INFRASTRUCTURE PROJECTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE181G01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE524B01027",
                "SecurityId": "GANDHITUBE",
                "SecurityName": "GANDHI SPECIAL TUBES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE524B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE845D01014",
                "SecurityId": "GANECOS",
                "SecurityName": "GANESHA ECOSPHERE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE845D01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE460C01014",
                "SecurityId": "GANESHHOUC",
                "SecurityName": "GANESH HOUSING CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE460C01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE335W01016",
                "SecurityId": "GANGESSECU",
                "SecurityName": "GANGES SECURITIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE335W01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE526A01016",
                "SecurityId": "GARDENSILK",
                "SecurityName": "GARDEN SILK MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE526A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE276A01018",
                "SecurityId": "GARFIBRES",
                "SecurityName": "GARWARE TECHNICAL FIBRES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE276A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE152B01027",
                "SecurityId": "GATI",
                "SecurityName": "GATI LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE152B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE287Z01012",
                "SecurityId": "GAYAHWS",
                "SecurityName": "GAYATRI HIGHWAYS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE287Z01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE336H01023",
                "SecurityId": "GAYAPROJ",
                "SecurityName": "GAYATRI PROJECTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE336H01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE087J01028",
                "SecurityId": "GBGLOBAL",
                "SecurityName": "GB GLOBAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE087J01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE852F01015",
                "SecurityId": "GDL",
                "SecurityName": "GATEWAY DISTRIPARKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE852F01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE916G01016",
                "SecurityId": "GEECEE",
                "SecurityName": "GEECEE VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE916G01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE669X01016",
                "SecurityId": "GEEKAYWIRE",
                "SecurityName": "GEEKAY WIRES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE669X01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE727B01026",
                "SecurityId": "GENESYS",
                "SecurityName": "GENESYS INTERNATIONAL CORPORATION LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE727B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE949P01018",
                "SecurityId": "GENUSPAPER",
                "SecurityName": "GENUS PAPER & BOARDS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE949P01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE955D01029",
                "SecurityId": "GENUSPOWER",
                "SecurityName": "GENUS POWER INFRASTRUCTURES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE955D01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE007B01023",
                "SecurityId": "GEOJITFSL",
                "SecurityName": "GEOJIT FINANCIAL SERVICES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE007B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE878A01011",
                "SecurityId": "GEPIL",
                "SecurityName": "GE POWER INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE878A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE017A01032",
                "SecurityId": "GESHIP",
                "SecurityName": "THE GREAT EASTERN SHIPPING COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE017A01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE200A01026",
                "SecurityId": "GET&D",
                "SecurityName": "GE T&D INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE200A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE538A01037",
                "SecurityId": "GFLLIMITED",
                "SecurityName": "GFL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE538A01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE534A01028",
                "SecurityId": "GFSTEELS",
                "SecurityName": "GRAND FOUNDRY LIMITED",
                "FaceValue": "4",
                "ISINNo": "INE534A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE539A01019",
                "SecurityId": "GHCL",
                "SecurityName": "GHCL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE539A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE289B01019",
                "SecurityId": "GICHSGFIN",
                "SecurityName": "GIC HOUSING FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE289B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE481Y01014",
                "SecurityId": "GICRE",
                "SecurityName": "GENERAL INSURANCE CORPORATION OF INDIA",
                "FaceValue": "5",
                "ISINNo": "INE481Y01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE047B01011",
                "SecurityId": "GILLANDERS",
                "SecurityName": "GILLANDERS ARBUTHNOT & COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE047B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE322A01010",
                "SecurityId": "GILLETTE",
                "SecurityName": "GILLETTE INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE322A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE424C01010",
                "SecurityId": "GINNIFILA",
                "SecurityName": "GINNI FILAMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE424C01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE162A01010",
                "SecurityId": "GIPCL",
                "SecurityName": "GUJARAT INDUSTRIES POWER COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE162A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE065J01016",
                "SecurityId": "GISOLUTION",
                "SecurityName": "GI ENGINEERING SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE065J01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE528A01020",
                "SecurityId": "GKWLIMITED",
                "SecurityName": "GKW LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE528A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE159A01016",
                "SecurityId": "GLAXO",
                "SecurityName": "GLAXOSMITHKLINE PHARMACEUTICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE159A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE935A01035",
                "SecurityId": "GLENMARK",
                "SecurityName": "GLENMARK PHARMACEUTICALS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE935A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE540A01017",
                "SecurityId": "GLFL",
                "SecurityName": "GUJARAT LEASE FINANCING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE540A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE792H01019",
                "SecurityId": "GLOBALVECT",
                "SecurityName": "GLOBAL VECTRA HELICORP LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE792H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE446C01013",
                "SecurityId": "GLOBOFFS",
                "SecurityName": "GLOBAL OFFSHORE SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE446C01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE615I01010",
                "SecurityId": "GLOBUSSPR",
                "SecurityName": "GLOBUS SPIRITS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE615I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE075D01018",
                "SecurityId": "GMBREW",
                "SecurityName": "GM BREWERIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE075D01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE131A01031",
                "SecurityId": "GMDCLTD",
                "SecurityName": "GUJARAT MINERAL DEVELOPMENT CORPORATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE131A01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE541A01023",
                "SecurityId": "GMMPFAUDLR",
                "SecurityName": "GMM PFAUDLER LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE541A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE776C01039",
                "SecurityId": "GMRINFRA",
                "SecurityName": "GMR INFRASTRUCTURE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE776C01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE934S01014",
                "SecurityId": "GNA",
                "SecurityName": "GNA AXLES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE934S01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE113A01013",
                "SecurityId": "GNFC",
                "SecurityName": "GUJARAT NARMADA VALLEY FERTILIZERS AND CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE113A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE426D01013",
                "SecurityId": "GOACARBON",
                "SecurityName": "GOA CARBON LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE426D01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE077F01035",
                "SecurityId": "GOCLCORP",
                "SecurityName": "GOCL CORPORATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE077F01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE260B01028",
                "SecurityId": "GODFRYPHLP",
                "SecurityName": "GODFREY PHILLIPS INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE260B01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE850D01014",
                "SecurityId": "GODREJAGRO",
                "SecurityName": "GODREJ AGROVET LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE850D01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE102D01028",
                "SecurityId": "GODREJCP",
                "SecurityName": "GODREJ CONSUMER PRODUCTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE102D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE233A01035",
                "SecurityId": "GODREJIND",
                "SecurityName": "GODREJ INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE233A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE484J01027",
                "SecurityId": "GODREJPROP",
                "SecurityName": "GODREJ PROPERTIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE484J01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE516K01024",
                "SecurityId": "GOENKA",
                "SecurityName": "GOENKA DIAMOND AND JEWELS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE516K01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE887G01027",
                "SecurityId": "GOKEX",
                "SecurityName": "GOKALDAS EXPORTS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE887G01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE020J01029",
                "SecurityId": "GOKUL",
                "SecurityName": "GOKUL REFOILS AND SOLVENT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE020J01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE314T01025",
                "SecurityId": "GOKULAGRO",
                "SecurityName": "GOKUL AGRO RESOURCES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE314T01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE973A01010",
                "SecurityId": "GOLDENTOBC",
                "SecurityName": "GOLDEN TOBACCO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE973A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE025B01017",
                "SecurityId": "GOLDIAM",
                "SecurityName": "GOLDIAM INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE025B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE805A01014",
                "SecurityId": "GOLDTECH",
                "SecurityName": "GOLDSTONE TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE805A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE127I01024",
                "SecurityId": "GOODLUCK",
                "SecurityName": "GOODLUCK INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE127I01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE177H01013",
                "SecurityId": "GPIL",
                "SecurityName": "GODAWARI POWER AND ISPAT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE177H01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE517F01014",
                "SecurityId": "GPPL",
                "SecurityName": "GUJARAT PIPAVAV PORT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE517F01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE390G01014",
                "SecurityId": "GPTINFRA",
                "SecurityName": "GPT INFRAPROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE390G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE101D01020",
                "SecurityId": "GRANULES",
                "SecurityName": "GRANULES INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE101D01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE371A01025",
                "SecurityId": "GRAPHITE",
                "SecurityName": "GRAPHITE INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE371A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE047A01021",
                "SecurityId": "GRASIM",
                "SecurityName": "GRASIM INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE047A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE024L01027",
                "SecurityId": "GRAVITA",
                "SecurityName": "GRAVITA INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE024L01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE224A01026",
                "SecurityId": "GREAVESCOT",
                "SecurityName": "GREAVES COTTON LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE224A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE544R01013",
                "SecurityId": "GREENLAM",
                "SecurityName": "GREENLAM INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE544R01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE08ZM01014",
                "SecurityId": "GREENPANEL",
                "SecurityName": "GREENPANEL INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE08ZM01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE461C01038",
                "SecurityId": "GREENPLY",
                "SecurityName": "GREENPLY INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE461C01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE999K01014",
                "SecurityId": "GREENPOWER",
                "SecurityName": "ORIENT GREEN POWER COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE999K01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE536A01023",
                "SecurityId": "GRINDWELL",
                "SecurityName": "GRINDWELL NORTON LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE536A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE646C01018",
                "SecurityId": "GROBTEA",
                "SecurityName": "THE GROB TEA COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE646C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE137I01015",
                "SecurityId": "GRPLTD",
                "SecurityName": "GRP LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE137I01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE382Z01011",
                "SecurityId": "GRSE",
                "SecurityName": "GARDEN REACH SHIPBUILDERS & ENGINEERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE382Z01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE542A01039",
                "SecurityId": "GSCLCEMENT",
                "SecurityName": "GUJARAT SIDHEE CEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE542A01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE026A01025",
                "SecurityId": "GSFC",
                "SecurityName": "GUJARAT STATE FERTILIZERS & CHEMICALS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE026A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE264A01014",
                "SecurityId": "GSKCONS",
                "SecurityName": "GLAXOSMITHKLINE CONSUMER HEALTHCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE264A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE246F01010",
                "SecurityId": "GSPL",
                "SecurityName": "GUJARAT STATE PETRONET LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE246F01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE871H01011",
                "SecurityId": "GSS",
                "SecurityName": "GSS INFOTECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE871H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE043A01012",
                "SecurityId": "GTL",
                "SecurityName": "GTL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE043A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE221H01019",
                "SecurityId": "GTLINFRA",
                "SecurityName": "GTL INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE221H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE537A01013",
                "SecurityId": "GTNIND",
                "SecurityName": "GTN INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE537A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE302H01017",
                "SecurityId": "GTNTEX",
                "SecurityName": "GTN TEXTILES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE302H01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE869I01013",
                "SecurityId": "GTPL",
                "SecurityName": "GTPL HATHWAY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE869I01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE742B01025",
                "SecurityId": "GUFICBIO",
                "SecurityName": "GUFIC BIOSCIENCES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE742B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE186A01019",
                "SecurityId": "GUJALKALI",
                "SecurityName": "GUJARAT ALKALIES AND CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE186A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE826C01016",
                "SecurityId": "GUJAPOLLO",
                "SecurityName": "GUJARAT APOLLO INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE826C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE844O01030",
                "SecurityId": "GUJGASLTD",
                "SecurityName": "GUJARAT GAS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE844O01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE610B01024",
                "SecurityId": "GUJRAFFIA",
                "SecurityName": "GUJARAT RAFFIA INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE610B01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE635Q01029",
                "SecurityId": "GULFOILLUB",
                "SecurityName": "GULF OIL LUBRICANTS INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE635Q01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE586G01017",
                "SecurityId": "GULFPETRO",
                "SecurityName": "GP PETROLEUMS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE586G01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE255D01024",
                "SecurityId": "GULPOLY",
                "SecurityName": "GULSHAN POLYOLS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE255D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE251H01024",
                "SecurityId": "GVKPIL",
                "SecurityName": "GVK POWER & INFRASTRUCTURE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE251H01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE066F01012",
                "SecurityId": "HAL",
                "SecurityName": "HINDUSTAN AERONAUTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE066F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE939D01015",
                "SecurityId": "HARITASEAT",
                "SecurityName": "HARITA SEATING SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE939D01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE544A01019",
                "SecurityId": "HARRMALAYA",
                "SecurityName": "HARRISONS MALAYALAM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE544A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE982F01036",
                "SecurityId": "HATHWAY",
                "SecurityName": "HATHWAY CABLE & DATACOM LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE982F01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE473B01035",
                "SecurityId": "HATSUN",
                "SecurityName": "HATSUN AGRO PRODUCT LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE473B01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE176B01034",
                "SecurityId": "HAVELLS",
                "SecurityName": "HAVELLS INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE176B01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE293B01029",
                "SecurityId": "HAVISHA",
                "SecurityName": "SRI HAVISHA HOSPITALITY AND INFRASTRUCTURE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE293B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE292B01021",
                "SecurityId": "HBLPOWER",
                "SecurityName": "HBL POWER SYSTEMS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE292B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE550B01022",
                "SecurityId": "HBSL",
                "SecurityName": "HB STOCKHOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE550B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE549A01026",
                "SecurityId": "HCC",
                "SecurityName": "HINDUSTAN CONSTRUCTION COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE549A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE075I01017",
                "SecurityId": "HCG",
                "SecurityName": "HEALTHCARE GLOBAL ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE075I01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE236A01020",
                "SecurityId": "HCL-INSYS",
                "SecurityName": "HCL INFOSYSTEMS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE236A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE860A01027",
                "SecurityId": "HCLTECH",
                "SecurityName": "HCL TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE860A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE001A01036",
                "SecurityId": "HDFC",
                "SecurityName": "HOUSING DEVELOPMENT FINANCE CORPORATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE001A01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE127D01025",
                "SecurityId": "HDFCAMC",
                "SecurityName": "HDFC ASSET MANAGEMENT COMPANY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE127D01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE040A01034",
                "SecurityId": "HDFCBANK",
                "SecurityName": "HDFC BANK LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE040A01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE795G01014",
                "SecurityId": "HDFCLIFE",
                "SecurityName": "HDFC LIFE INSURANCE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE795G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE191I01012",
                "SecurityId": "HDIL",
                "SecurityName": "HOUSING DEVELOPMENT AND INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE191I01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE545A01016",
                "SecurityId": "HEG",
                "SecurityName": "HEG LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE545A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE578A01017",
                "SecurityId": "HEIDELBERG",
                "SecurityName": "HEIDELBERGCEMENT INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE578A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE688E01024",
                "SecurityId": "HERCULES",
                "SecurityName": "HERCULES HOISTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE688E01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE978A01027",
                "SecurityId": "HERITGFOOD",
                "SecurityName": "HERITAGE FOODS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE978A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE158A01026",
                "SecurityId": "HEROMOTOCO",
                "SecurityName": "HERO MOTOCORP LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE158A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE782E01017",
                "SecurityId": "HESTERBIO",
                "SecurityName": "HESTER BIOSCIENCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE782E01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE750M01017",
                "SecurityId": "HEXATRADEX",
                "SecurityName": "HEXA TRADEX LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE750M01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE093A01033",
                "SecurityId": "HEXAWARE",
                "SecurityName": "HEXAWARE TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE093A01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE548A01028",
                "SecurityId": "HFCL",
                "SecurityName": "HFCL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE548A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE926X01010",
                "SecurityId": "HGINFRA",
                "SecurityName": "H.G. INFRA ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE926X01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE170I01016",
                "SecurityId": "HGS",
                "SecurityName": "HINDUJA GLOBAL SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE170I01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE475B01022",
                "SecurityId": "HIKAL",
                "SecurityName": "HIKAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE475B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE557A01011",
                "SecurityId": "HIL",
                "SecurityName": "HIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE557A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE788H01017",
                "SecurityId": "HILTON",
                "SecurityName": "HILTON METAL FORGING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE788H01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE049A01027",
                "SecurityId": "HIMATSEIDE",
                "SecurityName": "HIMATSINGKA SEIDE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE049A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE038A01020",
                "SecurityId": "HINDALCO",
                "SecurityName": "HINDALCO INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE038A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE310C01029",
                "SecurityId": "HINDCOMPOS",
                "SecurityName": "HINDUSTAN COMPOSITES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE310C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE531E01026",
                "SecurityId": "HINDCOPPER",
                "SecurityName": "HINDUSTAN COPPER LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE531E01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE253A01025",
                "SecurityId": "HINDMOTORS",
                "SecurityName": "HINDUSTAN MOTORS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE253A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE952A01022",
                "SecurityId": "HINDNATGLS",
                "SecurityName": "HINDUSTHAN NATIONAL GLASS & INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE952A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE345A01011",
                "SecurityId": "HINDOILEXP",
                "SecurityName": "HINDUSTAN OIL EXPLORATION COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE345A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE094A01015",
                "SecurityId": "HINDPETRO",
                "SecurityName": "HINDUSTAN PETROLEUM CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE094A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE030A01027",
                "SecurityId": "HINDUNILVR",
                "SecurityName": "HINDUSTAN UNILEVER LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE030A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE267A01025",
                "SecurityId": "HINDZINC",
                "SecurityName": "HINDUSTAN ZINC LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE267A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE835D01023",
                "SecurityId": "HIRECT",
                "SecurityName": "HIND RECTIFIERS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE835D01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE598C01011",
                "SecurityId": "HISARMETAL",
                "SecurityName": "HISAR METAL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE598C01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE106T01017",
                "SecurityId": "HITECH",
                "SecurityName": "HI-TECH PIPES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE106T01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE120D01012",
                "SecurityId": "HITECHCORP",
                "SecurityName": "HITECH CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE120D01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE127B01011",
                "SecurityId": "HITECHGEAR",
                "SecurityName": "THE HI-TECH GEARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE127B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE102A01024",
                "SecurityId": "HLVLTD",
                "SecurityName": "HLV LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE102A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE262A01018",
                "SecurityId": "HMT",
                "SecurityName": "HMT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE262A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE871K01015",
                "SecurityId": "HMVL",
                "SecurityName": "HINDUSTAN MEDIA VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE871K01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE254N01018",
                "SecurityId": "HNDFDS",
                "SecurityName": "HINDUSTAN FOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE254N01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE671A01010",
                "SecurityId": "HONAUT",
                "SecurityName": "HONEYWELL AUTOMATION INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE671A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE634A01018",
                "SecurityId": "HONDAPOWER",
                "SecurityName": "HONDA SIEL POWER PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE634A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE275F01019",
                "SecurityId": "HOTELRUGBY",
                "SecurityName": "HOTEL RUGBY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE275F01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE596H01014",
                "SecurityId": "HOVS",
                "SecurityName": "HOV SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE596H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE495S01016",
                "SecurityId": "HPL",
                "SecurityName": "HPL ELECTRIC & POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE495S01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE019C01026",
                "SecurityId": "HSCL",
                "SecurityName": "HIMADRI SPECIALITY CHEMICAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE019C01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE415A01038",
                "SecurityId": "HSIL",
                "SecurityName": "HSIL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE415A01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE501G01024",
                "SecurityId": "HTMEDIA",
                "SecurityName": "HT MEDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE501G01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE703H01016",
                "SecurityId": "HUBTOWN",
                "SecurityName": "HUBTOWN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE703H01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE031A01017",
                "SecurityId": "HUDCO",
                "SecurityName": "HOUSING & URBAN DEVELOPMENT CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE031A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE069I01010",
                "SecurityId": "IBREALEST",
                "SecurityName": "INDIABULLS REAL ESTATE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE069I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE148I01020",
                "SecurityId": "IBULHSGFIN",
                "SecurityName": "INDIABULLS HOUSING FINANCE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE148I01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE126M01010",
                "SecurityId": "IBULISL",
                "SecurityName": "INDIABULLS INTEGRATED SERVICES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE126M01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE274G01010",
                "SecurityId": "IBVENTURES",
                "SecurityName": "INDIABULLS VENTURES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE274G01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE090A01021",
                "SecurityId": "ICICIBANK",
                "SecurityName": "ICICI BANK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE090A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE765G01017",
                "SecurityId": "ICICIGI",
                "SecurityName": "ICICI LOMBARD GENERAL INSURANCE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE765G01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE726G01019",
                "SecurityId": "ICICIPRULI",
                "SecurityName": "ICICI PRUDENTIAL LIFE INSURANCE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE726G01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE483B01026",
                "SecurityId": "ICIL",
                "SecurityName": "INDO COUNT INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE483B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE725G01011",
                "SecurityId": "ICRA",
                "SecurityName": "ICRA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE725G01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE008A01015",
                "SecurityId": "IDBI",
                "SecurityName": "IDBI BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE008A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE669E01016",
                "SecurityId": "IDEA",
                "SecurityName": "VODAFONE IDEA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE669E01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE043D01016",
                "SecurityId": "IDFC",
                "SecurityName": "IDFC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE043D01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE092T01019",
                "SecurityId": "IDFCFIRSTB",
                "SecurityName": "IDFC FIRST BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE092T01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE022Q01020",
                "SecurityId": "IEX",
                "SecurityName": "INDIAN ENERGY EXCHANGE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE022Q01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE076C01018",
                "SecurityId": "IFBAGRO",
                "SecurityName": "IFB AGRO INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE076C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE559A01017",
                "SecurityId": "IFBIND",
                "SecurityName": "IFB INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE559A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE039A01010",
                "SecurityId": "IFCI",
                "SecurityName": "IFCI LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE039A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE133Y01011",
                "SecurityId": "IFGLEXPOR",
                "SecurityName": "IFGL REFRACTORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE133Y01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE188B01013",
                "SecurityId": "IGARASHI",
                "SecurityName": "IGARASHI MOTORS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE188B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE203G01027",
                "SecurityId": "IGL",
                "SecurityName": "INDRAPRASTHA GAS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE203G01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE204A01010",
                "SecurityId": "IGPL",
                "SecurityName": "IG PETROCHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE204A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE530B01024",
                "SecurityId": "IIFL",
                "SecurityName": "IIFL FINANCE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE530B01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE489L01022",
                "SecurityId": "IIFLSEC",
                "SecurityName": "IIFL SECURITIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE489L01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE466L01020",
                "SecurityId": "IIFLWAM",
                "SecurityName": "IIFL WEALTH MANAGEMENT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE466L01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE886A01014",
                "SecurityId": "IITL",
                "SecurityName": "INDUSTRIAL INVESTMENT TRUST LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE886A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE369I01014",
                "SecurityId": "IL&FSENGG",
                "SecurityName": "IL&FS ENGINEERING AND CONSTRUCTION COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE369I01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE975G01012",
                "SecurityId": "IL&FSTRANS",
                "SecurityName": "IL&FS TRANSPORTATION NETWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE975G01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE919H01018",
                "SecurityId": "IMFA",
                "SecurityName": "INDIAN METALS & FERRO ALLOYS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE919H01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE547E01014",
                "SecurityId": "IMPAL",
                "SecurityName": "INDIA MOTOR PARTS AND ACCESSORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE547E01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE691G01015",
                "SecurityId": "IMPEXFERRO",
                "SecurityName": "IMPEX FERRO TECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE691G01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE841B01017",
                "SecurityId": "INDBANK",
                "SecurityName": "INDBANK MERCHANT BANKING SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE841B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE053A01029",
                "SecurityId": "INDHOTEL",
                "SecurityName": "THE INDIAN HOTELS COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE053A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE383A01012",
                "SecurityId": "INDIACEM",
                "SecurityName": "THE INDIA CEMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE383A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE560A01015",
                "SecurityId": "INDIAGLYCO",
                "SecurityName": "INDIA GLYCOLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE560A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE933S01016",
                "SecurityId": "INDIAMART",
                "SecurityName": "INDIAMART INTERMESH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE933S01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE562A01011",
                "SecurityId": "INDIANB",
                "SecurityName": "INDIAN BANK",
                "FaceValue": "10",
                "ISINNo": "INE562A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE061A01014",
                "SecurityId": "INDIANCARD",
                "SecurityName": "INDIAN CARD CLOTHING COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE061A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE323C01030",
                "SecurityId": "INDIANHUME",
                "SecurityName": "INDIAN HUME PIPE COMPANY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE323C01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE646L01027",
                "SecurityId": "INDIGO",
                "SecurityName": "INTERGLOBE AVIATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE646L01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE065B01013",
                "SecurityId": "INDLMETER",
                "SecurityName": "IMP POWERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE065B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE092B01025",
                "SecurityId": "INDNIPPON",
                "SecurityName": "INDIA NIPPON ELECTRICALS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE092B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE873D01024",
                "SecurityId": "INDOCO",
                "SecurityName": "INDOCO REMEDIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE873D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE156A01020",
                "SecurityId": "INDORAMA",
                "SecurityName": "INDO RAMA SYNTHETICS (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE156A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE866K01015",
                "SecurityId": "INDOSOLAR",
                "SecurityName": "INDOSOLAR LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE866K01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE896L01010",
                "SecurityId": "INDOSTAR",
                "SecurityName": "INDOSTAR CAPITAL FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE896L01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE332H01014",
                "SecurityId": "INDOTECH",
                "SecurityName": "INDO TECH TRANSFORMERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE332H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE337M01013",
                "SecurityId": "INDOTHAI",
                "SecurityName": "INDO THAI SECURITIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE337M01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE227G01018",
                "SecurityId": "INDOWIND",
                "SecurityName": "INDOWIND ENERGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE227G01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE681B01017",
                "SecurityId": "INDRAMEDCO",
                "SecurityName": "INDRAPRASTHA MEDICAL CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE681B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE915B01019",
                "SecurityId": "INDSWFTLAB",
                "SecurityName": "IND-SWIFT LABORATORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE915B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE788B01028",
                "SecurityId": "INDSWFTLTD",
                "SecurityName": "IND-SWIFT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE788B01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE611L01021",
                "SecurityId": "INDTERRAIN",
                "SecurityName": "INDIAN TERRAIN FASHIONS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE611L01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE095A01012",
                "SecurityId": "INDUSINDBK",
                "SecurityName": "INDUSIND BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE095A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE189B01011",
                "SecurityId": "INEOSSTYRO",
                "SecurityName": "INEOS STYROLUTION INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE189B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE483S01020",
                "SecurityId": "INFIBEAM",
                "SecurityName": "INFIBEAM AVENUES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE483S01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE344S01016",
                "SecurityId": "INFOBEAN",
                "SecurityName": "INFOBEANS TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE344S01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE669A01022",
                "SecurityId": "INFOMEDIA",
                "SecurityName": "INFOMEDIA PRESS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE669A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE121J01017",
                "SecurityId": "INFRATEL",
                "SecurityName": "BHARTI INFRATEL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE121J01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE009A01021",
                "SecurityId": "INFY",
                "SecurityName": "INFOSYS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE009A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE177A01018",
                "SecurityId": "INGERRAND",
                "SecurityName": "INGERSOLL RAND (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE177A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE312H01016",
                "SecurityId": "INOXLEISUR",
                "SecurityName": "INOX LEISURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE312H01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE066P01011",
                "SecurityId": "INOXWIND",
                "SecurityName": "INOX WIND LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE066P01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE070I01018",
                "SecurityId": "INSECTICID",
                "SecurityName": "INSECTICIDES (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE070I01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE020G01017",
                "SecurityId": "INSPIRISYS",
                "SecurityName": "INSPIRISYS SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE020G01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE418N01027",
                "SecurityId": "INTEGRA",
                "SecurityName": "INTEGRA GARMENTS AND TEXTILES LIMITED",
                "FaceValue": "3",
                "ISINNo": "INE418N01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE306R01017",
                "SecurityId": "INTELLECT",
                "SecurityName": "INTELLECT DESIGN ARENA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE306R01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE781A01025",
                "SecurityId": "INTENTECH",
                "SecurityName": "INTENSE TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE781A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE878H01016",
                "SecurityId": "INVENTURE",
                "SecurityName": "INVENTURE GROWTH & SECURITIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE878H01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE565A01014",
                "SecurityId": "IOB",
                "SecurityName": "INDIAN OVERSEAS BANK",
                "FaceValue": "10",
                "ISINNo": "INE565A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE242A01010",
                "SecurityId": "IOC",
                "SecurityName": "INDIAN OIL CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE242A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE485C01011",
                "SecurityId": "IOLCP",
                "SecurityName": "IOL CHEMICALS AND PHARMACEUTICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE485C01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE571A01020",
                "SecurityId": "IPCALAB",
                "SecurityName": "IPCA LABORATORIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE571A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE821I01014",
                "SecurityId": "IRB",
                "SecurityName": "IRB INFRASTRUCTURE DEVELOPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE821I01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE962Y01013",
                "SecurityId": "IRCON",
                "SecurityName": "IRCON INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE962Y01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE335Y01012",
                "SecurityId": "IRCTC",
                "SecurityName": "INDIAN RAILWAY CATERING AND TOURISM CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE335Y01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE763G01038",
                "SecurityId": "ISEC",
                "SecurityName": "ICICI SECURITIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE763G01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE566K01011",
                "SecurityId": "ISFT",
                "SecurityName": "INTRASOFT TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE566K01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE732F01019",
                "SecurityId": "ISMTLTD",
                "SecurityName": "ISMT LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE732F01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE154A01025",
                "SecurityId": "ITC",
                "SecurityName": "ITC LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE154A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE353K01014",
                "SecurityId": "ITDC",
                "SecurityName": "INDIA TOURISM DEVELOPMENT CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE353K01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE686A01026",
                "SecurityId": "ITDCEM",
                "SecurityName": "ITD CEMENTATION INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE686A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE248A01017",
                "SecurityId": "ITI",
                "SecurityName": "ITI LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE248A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE050B01023",
                "SecurityId": "IVC",
                "SecurityName": "IL&FS INVESTMENT MANAGERS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE050B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE043C01018",
                "SecurityId": "IVP",
                "SecurityName": "IVP LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE043C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE848A01014",
                "SecurityId": "IZMO",
                "SecurityName": "IZMO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE848A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE168A01041",
                "SecurityId": "J&KBANK",
                "SecurityName": "THE JAMMU & KASHMIR BANK LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE168A01041",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE199G01027",
                "SecurityId": "JAGRAN",
                "SecurityName": "JAGRAN PRAKASHAN LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE199G01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE048B01027",
                "SecurityId": "JAGSNPHARM",
                "SecurityName": "JAGSONPAL PHARMACEUTICALS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE048B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE091G01018",
                "SecurityId": "JAIBALAJI",
                "SecurityName": "JAI BALAJI INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE091G01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE070D01027",
                "SecurityId": "JAICORPLTD",
                "SecurityName": "JAI CORP LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE070D01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE343D01010",
                "SecurityId": "JAIHINDPRO",
                "SecurityName": "JAIHIND PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE343D01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE486B01011",
                "SecurityId": "JAINSTUDIO",
                "SecurityName": "JAIN STUDIOS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE486B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE039C01032",
                "SecurityId": "JAMNAAUTO",
                "SecurityName": "JAMNA AUTO INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE039C01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE039O01011",
                "SecurityId": "JASH",
                "SecurityName": "JASH ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE039O01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE785A01026",
                "SecurityId": "JAYAGROGN",
                "SecurityName": "JAYANT AGRO ORGANICS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE785A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE571B01028",
                "SecurityId": "JAYBARMARU",
                "SecurityName": "JAY BHARAT MARUTI LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE571B01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE854B01010",
                "SecurityId": "JAYNECOIND",
                "SecurityName": "JAYASWAL NECO INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE854B01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE364A01020",
                "SecurityId": "JAYSREETEA",
                "SecurityName": "JAYSHREE TEA & INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE364A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE572A01028",
                "SecurityId": "JBCHEPHARM",
                "SecurityName": "JB CHEMICALS & PHARMACEUTICALS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE572A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE187A01017",
                "SecurityId": "JBFIND",
                "SecurityName": "JBF INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE187A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE927D01028",
                "SecurityId": "JBMA",
                "SecurityName": "JBM AUTO LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE927D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE782A01015",
                "SecurityId": "JCHAC",
                "SecurityName": "JOHNSON CONTROLS - HITACHI AIR CONDITIONING INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE782A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE802G01018",
                "SecurityId": "JETAIRWAYS",
                "SecurityName": "JET AIRWAYS (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE802G01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE544H01014",
                "SecurityId": "JHS",
                "SecurityName": "JHS SVENDGAARD LABORATORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE544H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE026B01049",
                "SecurityId": "JIKIND",
                "SecurityName": "JIK INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE026B01049",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE796G01012",
                "SecurityId": "JINDALPHOT",
                "SecurityName": "JINDAL PHOTO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE796G01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE197D01010",
                "SecurityId": "JINDALPOLY",
                "SecurityName": "JINDAL POLY FILMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE197D01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE324A01024",
                "SecurityId": "JINDALSAW",
                "SecurityName": "JINDAL SAW LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE324A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE749A01030",
                "SecurityId": "JINDALSTEL",
                "SecurityName": "JINDAL STEEL & POWER LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE749A01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE742C01031",
                "SecurityId": "JINDRILL",
                "SecurityName": "JINDAL DRILLING AND INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE742C01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE247D01039",
                "SecurityId": "JINDWORLD",
                "SecurityName": "JINDAL WORLDWIDE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE247D01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "IN9175A01010",
                "SecurityId": "JISLDVREQS",
                "SecurityName": "JAIN IRRIGATION SYSTEMS LIMITED",
                "FaceValue": "2",
                "ISINNo": "IN9175A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE175A01038",
                "SecurityId": "JISLJALEQS",
                "SecurityName": "JAIN IRRIGATION SYSTEMS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE175A01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE863T01013",
                "SecurityId": "JITFINFRA",
                "SecurityName": "JITF INFRALOGISTICS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE863T01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE023S01016",
                "SecurityId": "JIYAECO",
                "SecurityName": "JIYA ECO-PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE023S01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE823G01014",
                "SecurityId": "JKCEMENT",
                "SecurityName": "JK CEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE823G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE576I01022",
                "SecurityId": "JKIL",
                "SecurityName": "J.KUMAR INFRAPROJECTS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE576I01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE786A01032",
                "SecurityId": "JKLAKSHMI",
                "SecurityName": "JK LAKSHMI CEMENT LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE786A01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE789E01012",
                "SecurityId": "JKPAPER",
                "SecurityName": "JK PAPER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE789E01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE573A01042",
                "SecurityId": "JKTYRE",
                "SecurityName": "JK TYRE & INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE573A01042",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE412C01023",
                "SecurityId": "JMA",
                "SecurityName": "JULLUNDUR MOTOR AGENCY (DELHI) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE412C01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE890A01024",
                "SecurityId": "JMCPROJECT",
                "SecurityName": "JMC PROJECTS (INDIA) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE890A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE780C01023",
                "SecurityId": "JMFINANCIL",
                "SecurityName": "JM FINANCIAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE780C01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE988E01036",
                "SecurityId": "JMTAUTOLTD",
                "SecurityName": "JMT AUTO LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE988E01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE839G01010",
                "SecurityId": "JOCIL",
                "SecurityName": "JOCIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE839G01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE455F01025",
                "SecurityId": "JPASSOCIAT",
                "SecurityName": "JAIPRAKASH ASSOCIATES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE455F01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE099J01015",
                "SecurityId": "JPINFRATEC",
                "SecurityName": "JAYPEE INFRATECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE099J01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE147P01019",
                "SecurityId": "JPOLYINVST",
                "SecurityName": "JINDAL POLY INVESTMENT AND FINANCE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE147P01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE351F01018",
                "SecurityId": "JPPOWER",
                "SecurityName": "JAIPRAKASH POWER VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE351F01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE220G01021",
                "SecurityId": "JSL",
                "SecurityName": "JINDAL STAINLESS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE220G01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE455T01018",
                "SecurityId": "JSLHISAR",
                "SecurityName": "JINDAL STAINLESS (HISAR) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE455T01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE121E01018",
                "SecurityId": "JSWENERGY",
                "SecurityName": "JSW ENERGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE121E01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE824G01012",
                "SecurityId": "JSWHL",
                "SecurityName": "JSW HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE824G01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE019A01038",
                "SecurityId": "JSWSTEEL",
                "SecurityName": "JSW STEEL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE019A01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE643A01035",
                "SecurityId": "JTEKTINDIA",
                "SecurityName": "JTEKT INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE643A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE700A01033",
                "SecurityId": "JUBILANT",
                "SecurityName": "JUBILANT LIFE SCIENCES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE700A01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE797F01012",
                "SecurityId": "JUBLFOOD",
                "SecurityName": "JUBILANT FOODWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE797F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE645L01011",
                "SecurityId": "JUBLINDS",
                "SecurityName": "JUBILANT INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE645L01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE974C01022",
                "SecurityId": "JUMPNET",
                "SecurityName": "JUMP NETWORKS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE974C01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE599M01018",
                "SecurityId": "JUSTDIAL",
                "SecurityName": "JUST DIAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE599M01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE430G01026",
                "SecurityId": "JVLAGRO",
                "SecurityName": "JVL AGRO INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE430G01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE668F01031",
                "SecurityId": "JYOTHYLAB",
                "SecurityName": "JYOTHY LABS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE668F01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE197A01024",
                "SecurityId": "JYOTISTRUC",
                "SecurityName": "JYOTI STRUCTURES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE197A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE900B01029",
                "SecurityId": "KABRAEXTRU",
                "SecurityName": "KABRA EXTRUSION TECHNIK LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE900B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE217B01036",
                "SecurityId": "KAJARIACER",
                "SecurityName": "KAJARIA CERAMICS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE217B01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE437B01014",
                "SecurityId": "KAKATCEM",
                "SecurityName": "KAKATIYA CEMENT SUGAR & INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE437B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE220B01022",
                "SecurityId": "KALPATPOWR",
                "SecurityName": "KALPATARU POWER TRANSMISSION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE220B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE610E01010",
                "SecurityId": "KALYANI",
                "SecurityName": "KALYANI COMMERCIALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE610E01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE314G01014",
                "SecurityId": "KALYANIFRG",
                "SecurityName": "KALYANI FORGE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE314G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE967C01018",
                "SecurityId": "KAMATHOTEL",
                "SecurityName": "KAMAT HOTELS (I) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE967C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE390H01012",
                "SecurityId": "KAMDHENU",
                "SecurityName": "KAMDHENU LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE390H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE879E01037",
                "SecurityId": "KANANIIND",
                "SecurityName": "KANANI INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE879E01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE138C01024",
                "SecurityId": "KANORICHEM",
                "SecurityName": "KANORIA CHEMICALS & INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE138C01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE531A01024",
                "SecurityId": "KANSAINER",
                "SecurityName": "KANSAI NEROLAC PAINTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE531A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE278R01018",
                "SecurityId": "KARDA",
                "SecurityName": "KARDA CONSTRUCTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE278R01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE725L01011",
                "SecurityId": "KARMAENG",
                "SecurityName": "KARMA ENERGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE725L01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE036D01028",
                "SecurityId": "KARURVYSYA",
                "SecurityName": "KARUR VYSYA BANK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE036D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE234I01010",
                "SecurityId": "KAUSHALYA",
                "SecurityName": "KAUSHALYA INFRASTRUCTURE DEVELOPMENT CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE234I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE587G01015",
                "SecurityId": "KAYA",
                "SecurityName": "KAYA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE587G01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE805C01028",
                "SecurityId": "KCP",
                "SecurityName": "KCP LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE805C01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE790B01024",
                "SecurityId": "KCPSUGIND",
                "SecurityName": "KCP SUGAR AND INDUSTRIES CORPORATION LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE790B01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE291D01011",
                "SecurityId": "KDDL",
                "SecurityName": "KDDL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE291D01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE389H01022",
                "SecurityId": "KEC",
                "SecurityName": "KEC INTERNATIONAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE389H01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE134B01017",
                "SecurityId": "KECL",
                "SecurityName": "KIRLOSKAR ELECTRIC COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE134B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE878B01027",
                "SecurityId": "KEI",
                "SecurityName": "KEI INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE878B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE164B01022",
                "SecurityId": "KELLTONTEC",
                "SecurityName": "KELLTON TECH SOLUTIONS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE164B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE717A01029",
                "SecurityId": "KENNAMET",
                "SecurityName": "KENNAMETAL INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE717A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE202H01019",
                "SecurityId": "KERNEX",
                "SecurityName": "KERNEX MICROSYSTEMS (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE202H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE087A01019",
                "SecurityId": "KESORAMIND",
                "SecurityName": "KESORAM INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE087A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE681C01015",
                "SecurityId": "KEYFINSERV",
                "SecurityName": "KEYNOTE FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE681C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE299C01024",
                "SecurityId": "KGL",
                "SecurityName": "KARUTURI GLOBAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE299C01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE834I01025",
                "SecurityId": "KHADIM",
                "SecurityName": "KHADIM INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE834I01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE060B01014",
                "SecurityId": "KHANDSE",
                "SecurityName": "KHANDWALA SECURITIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE060B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE029L01018",
                "SecurityId": "KICL",
                "SecurityName": "KALYANI INVESTMENT COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE029L01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE729D01010",
                "SecurityId": "KILITCH",
                "SecurityName": "KILITCH DRUGS (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE729D01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE473D01015",
                "SecurityId": "KINGFA",
                "SecurityName": "KINGFA SCIENCE & TECHNOLOGY (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE473D01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE880L01014",
                "SecurityId": "KIOCL",
                "SecurityName": "KIOCL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE880L01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE415I01015",
                "SecurityId": "KIRIINDUS",
                "SecurityName": "KIRI INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE415I01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE884B01025",
                "SecurityId": "KIRLFER",
                "SecurityName": "KIRLOSKAR FERROUS INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE884B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE732A01036",
                "SecurityId": "KIRLOSBROS",
                "SecurityName": "KIRLOSKAR BROTHERS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE732A01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE146L01010",
                "SecurityId": "KIRLOSENG",
                "SecurityName": "KIRLOSKAR OIL ENGINES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE146L01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE250A01039",
                "SecurityId": "KIRLOSIND",
                "SecurityName": "KIRLOSKAR INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE250A01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE602G01020",
                "SecurityId": "KITEX",
                "SecurityName": "KITEX GARMENTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE602G01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE401H01017",
                "SecurityId": "KKCL",
                "SecurityName": "KEWAL KIRAN CLOTHING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE401H01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE157H01023",
                "SecurityId": "KMSUGAR",
                "SecurityName": "K.M.SUGAR MILLS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE157H01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE634I01029",
                "SecurityId": "KNRCON",
                "SecurityName": "KNR CONSTRUCTIONS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE634I01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE080B01012",
                "SecurityId": "KOHINOOR",
                "SecurityName": "KOHINOOR FOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE080B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE760A01029",
                "SecurityId": "KOKUYOCMLN",
                "SecurityName": "KOKUYO CAMLIN LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE760A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE094I01018",
                "SecurityId": "KOLTEPATIL",
                "SecurityName": "KOLTE - PATIL DEVELOPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE094I01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE082A01010",
                "SecurityId": "KOPRAN",
                "SecurityName": "KOPRAN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE082A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE237A01028",
                "SecurityId": "KOTAKBANK",
                "SecurityName": "KOTAK MAHINDRA BANK LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE237A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE419A01022",
                "SecurityId": "KOTARISUG",
                "SecurityName": "KOTHARI SUGARS AND CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE419A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE720A01015",
                "SecurityId": "KOTHARIPET",
                "SecurityName": "KOTHARI PETROCHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE720A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE823A01017",
                "SecurityId": "KOTHARIPRO",
                "SecurityName": "KOTHARI PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE823A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE04I401011",
                "SecurityId": "KPITTECH",
                "SecurityName": "KPIT TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE04I401011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE930H01023",
                "SecurityId": "KPRMILL",
                "SecurityName": "K.P.R. MILL LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE930H01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE001B01026",
                "SecurityId": "KRBL",
                "SecurityName": "KRBL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE001B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE268B01013",
                "SecurityId": "KREBSBIO",
                "SecurityName": "KREBS BIOCHEMICALS AND INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE268B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE524L01026",
                "SecurityId": "KRIDHANINF",
                "SecurityName": "KRIDHAN INFRA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE524L01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE506W01012",
                "SecurityId": "KRISHANA",
                "SecurityName": "KRISHANA PHOSCHEM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE506W01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE999A01015",
                "SecurityId": "KSB",
                "SecurityName": "KSB LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE999A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE455I01029",
                "SecurityId": "KSCL",
                "SecurityName": "KAVERI SEED COMPANY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE455I01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE216D01026",
                "SecurityId": "KSERASERA",
                "SecurityName": "KSS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE216D01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE143H01015",
                "SecurityId": "KSK",
                "SecurityName": "KSK ENERGY VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE143H01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE907A01026",
                "SecurityId": "KSL",
                "SecurityName": "KALYANI STEELS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE907A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE614B01018",
                "SecurityId": "KTKBANK",
                "SecurityName": "THE KARNATAKA BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE614B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE529I01013",
                "SecurityId": "KUANTUM",
                "SecurityName": "KUANTUM PAPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE529I01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE775B01025",
                "SecurityId": "KWALITY",
                "SecurityName": "KWALITY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE775B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE498L01015",
                "SecurityId": "L&TFH",
                "SecurityName": "L&T FINANCE HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE498L01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE651C01018",
                "SecurityId": "LAKPRE",
                "SecurityName": "LAKSHMI PRECISION SCREWS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE651C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE694C01018",
                "SecurityId": "LAKSHVILAS",
                "SecurityName": "LAKSHMI VILAS BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE694C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE600L01024",
                "SecurityId": "LALPATHLAB",
                "SecurityName": "DR. LAL PATH LABS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE600L01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE112F01022",
                "SecurityId": "LAMBODHARA",
                "SecurityName": "LAMBODHARA TEXTILES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE112F01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE059D01020",
                "SecurityId": "LAOPALA",
                "SecurityName": "LA OPALA RG LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE059D01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE670X01014",
                "SecurityId": "LASA",
                "SecurityName": "LASA SUPERGENERICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE670X01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE947Q01010",
                "SecurityId": "LAURUSLABS",
                "SecurityName": "LAURUS LABS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE947Q01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE269B01029",
                "SecurityId": "LAXMIMACH",
                "SecurityName": "LAKSHMI MACHINE WORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE269B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE970X01018",
                "SecurityId": "LEMONTREE",
                "SecurityName": "LEMON TREE HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE970X01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE850E01012",
                "SecurityId": "LFIC",
                "SecurityName": "LAKSHMI FINANCE & INDUSTRIAL CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE850E01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE337A01034",
                "SecurityId": "LGBBROSLTD",
                "SecurityName": "LG BALAKRISHNAN & BROS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE337A01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE201J01017",
                "SecurityId": "LGBFORGE",
                "SecurityName": "LGB FORGE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE201J01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE908V01012",
                "SecurityId": "LIBAS",
                "SecurityName": "LIBAS DESIGNS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE908V01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE557B01019",
                "SecurityId": "LIBERTSHOE",
                "SecurityName": "LIBERTY SHOES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE557B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE115A01026",
                "SecurityId": "LICHSGFIN",
                "SecurityName": "LIC HOUSING FINANCE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE115A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE405C01035",
                "SecurityId": "LINCOLN",
                "SecurityName": "LINCOLN PHARMACEUTICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE405C01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE802B01019",
                "SecurityId": "LINCPEN",
                "SecurityName": "LINC PEN & PLASTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE802B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE473A01011",
                "SecurityId": "LINDEINDIA",
                "SecurityName": "LINDE INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE473A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE397H01017",
                "SecurityId": "LOKESHMACH",
                "SecurityName": "LOKESH MACHINES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE397H01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE947I01017",
                "SecurityId": "LOTUSEYE",
                "SecurityName": "LOTUS EYE HOSPITAL AND INSTITUTE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE947I01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE597L01014",
                "SecurityId": "LOVABLE",
                "SecurityName": "LOVABLE LINGERIE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE597L01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE197J01017",
                "SecurityId": "LPDC",
                "SecurityName": "LANDMARK PROPERTY DEVELOPMENT COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE197J01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE093R01011",
                "SecurityId": "LSIL",
                "SecurityName": "LLOYDS STEELS INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE093R01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE018A01030",
                "SecurityId": "LT",
                "SecurityName": "LARSEN & TOUBRO LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE018A01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE214T01019",
                "SecurityId": "LTI",
                "SecurityName": "LARSEN & TOUBRO INFOTECH LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE214T01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE010V01017",
                "SecurityId": "LTTS",
                "SecurityName": "L&T TECHNOLOGY SERVICES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE010V01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE162B01018",
                "SecurityId": "LUMAXIND",
                "SecurityName": "LUMAX INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE162B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE872H01027",
                "SecurityId": "LUMAXTECH",
                "SecurityName": "LUMAX AUTO TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE872H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE326A01037",
                "SecurityId": "LUPIN",
                "SecurityName": "LUPIN LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE326A01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE150G01020",
                "SecurityId": "LUXIND",
                "SecurityName": "LUX INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE150G01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE933A01014",
                "SecurityId": "LYKALABS",
                "SecurityName": "LYKA LABS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE933A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE142K01011",
                "SecurityId": "LYPSAGEMS",
                "SecurityName": "LYPSA GEMS & JEWELLERY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE142K01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE101A01026",
                "SecurityId": "M&M",
                "SecurityName": "MAHINDRA & MAHINDRA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE101A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE774D01024",
                "SecurityId": "M&MFIN",
                "SecurityName": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE774D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE215I01019",
                "SecurityId": "MAANALU",
                "SecurityName": "MAAN ALUMINIUM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE215I01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE925C01016",
                "SecurityId": "MADHAV",
                "SecurityName": "MADHAV MARBLES AND GRANITES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE925C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE378D01032",
                "SecurityId": "MADHUCON",
                "SecurityName": "MADHUCON PROJECTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE378D01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE414A01015",
                "SecurityId": "MADRASFERT",
                "SecurityName": "MADRAS FERTILIZERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE414A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE347W01011",
                "SecurityId": "MAGADSUGAR",
                "SecurityName": "MAGADH SUGAR & ENERGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE347W01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE511C01022",
                "SecurityId": "MAGMA",
                "SecurityName": "MAGMA FINCORP LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE511C01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE387I01016",
                "SecurityId": "MAGNUM",
                "SecurityName": "MAGNUM VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE387I01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE457A01014",
                "SecurityId": "MAHABANK",
                "SecurityName": "BANK OF MAHARASHTRA",
                "FaceValue": "10",
                "ISINNo": "INE457A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE843B01013",
                "SecurityId": "MAHAPEXLTD",
                "SecurityName": "MAHA RASHTRA APEX CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE843B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE451L01014",
                "SecurityId": "MAHASTEEL",
                "SecurityName": "MAHAMAYA STEEL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE451L01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE215D01010",
                "SecurityId": "MAHEPC",
                "SecurityName": "MAHINDRA EPC IRRIGATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE215D01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE263W01010",
                "SecurityId": "MAHESHWARI",
                "SecurityName": "MAHESHWARI LOGISTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE263W01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE536H01010",
                "SecurityId": "MAHINDCIE",
                "SecurityName": "MAHINDRA CIE AUTOMOTIVE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE536H01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE813A01018",
                "SecurityId": "MAHLIFE",
                "SecurityName": "MAHINDRA LIFESPACE DEVELOPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE813A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE766P01016",
                "SecurityId": "MAHLOG",
                "SecurityName": "MAHINDRA LOGISTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE766P01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE288A01013",
                "SecurityId": "MAHSCOOTER",
                "SecurityName": "MAHARASHTRA SCOOTERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE288A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE271B01025",
                "SecurityId": "MAHSEAMLES",
                "SecurityName": "MAHARASHTRA SEAMLESS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE271B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE683C01011",
                "SecurityId": "MAITHANALL",
                "SecurityName": "MAITHAN ALLOYS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE683C01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE898S01029",
                "SecurityId": "MAJESCO",
                "SecurityName": "MAJESCO LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE898S01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE383H01017",
                "SecurityId": "MALUPAPER",
                "SecurityName": "MALU PAPER MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE383H01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE859Q01017",
                "SecurityId": "MANAKALUCO",
                "SecurityName": "MANAKSIA ALUMINIUM COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE859Q01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE830Q01018",
                "SecurityId": "MANAKCOAT",
                "SecurityName": "MANAKSIA COATED METALS & INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE830Q01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE015D01022",
                "SecurityId": "MANAKSIA",
                "SecurityName": "MANAKSIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE015D01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE824Q01011",
                "SecurityId": "MANAKSTEEL",
                "SecurityName": "MANAKSIA STEELS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE824Q01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE201A01024",
                "SecurityId": "MANALIPETC",
                "SecurityName": "MANALI PETROCHEMICALS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE201A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE522D01027",
                "SecurityId": "MANAPPURAM",
                "SecurityName": "MANAPPURAM FINANCE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE522D01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE584F01014",
                "SecurityId": "MANGALAM",
                "SecurityName": "MANGALAM DRUGS AND ORGANICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE584F01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE558B01017",
                "SecurityId": "MANGCHEFER",
                "SecurityName": "MANGALORE CHEMICALS & FERTILIZERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE558B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE347A01017",
                "SecurityId": "MANGLMCEM",
                "SecurityName": "MANGALAM CEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE347A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE805B01012",
                "SecurityId": "MANGTIMBER",
                "SecurityName": "MANGALAM TIMBER PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE805B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE993A01026",
                "SecurityId": "MANINDS",
                "SecurityName": "MAN INDUSTRIES (INDIA) LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE993A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE949H01023",
                "SecurityId": "MANINFRA",
                "SecurityName": "MAN INFRACONSTRUCTION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE949H01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE867A01022",
                "SecurityId": "MANUGRAPH",
                "SecurityName": "MANUGRAPH INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE867A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE882A01013",
                "SecurityId": "MARALOVER",
                "SecurityName": "MARAL OVERSEAS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE882A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE182D01020",
                "SecurityId": "MARATHON",
                "SecurityName": "MARATHON NEXTGEN REALTY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE182D01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE196A01026",
                "SecurityId": "MARICO",
                "SecurityName": "MARICO LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE196A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE750C01026",
                "SecurityId": "MARKSANS",
                "SecurityName": "MARKSANS PHARMA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE750C01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE585B01010",
                "SecurityId": "MARUTI",
                "SecurityName": "MARUTI SUZUKI INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE585B01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE348L01012",
                "SecurityId": "MASFIN",
                "SecurityName": "MAS FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE348L01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE885F01015",
                "SecurityId": "MASKINVEST",
                "SecurityName": "MASK INVESTMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE885F01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE759A01021",
                "SecurityId": "MASTEK",
                "SecurityName": "MASTEK LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE759A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE866R01028",
                "SecurityId": "MATRIMONY",
                "SecurityName": "MATRIMONY.COM LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE866R01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE636A01039",
                "SecurityId": "MAWANASUG",
                "SecurityName": "MAWANA SUGARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE636A01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE153U01017",
                "SecurityId": "MAXINDIA",
                "SecurityName": "MAX INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE153U01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE154U01015",
                "SecurityId": "MAXVIL",
                "SecurityName": "MAX VENTURES AND INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE154U01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE040D01038",
                "SecurityId": "MAYURUNIQ",
                "SecurityName": "MAYUR UNIQUOTERS LTD",
                "FaceValue": "5",
                "ISINNo": "INE040D01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE885E01034",
                "SecurityId": "MAZDA",
                "SecurityName": "MAZDA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE885E01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE900L01010",
                "SecurityId": "MBAPL",
                "SecurityName": "MADHYA BHARAT AGRO PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE900L01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE748A01016",
                "SecurityId": "MBECL",
                "SecurityName": "MCNALLY BHARAT ENGINEERING COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE748A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE912H01013",
                "SecurityId": "MBLINFRA",
                "SecurityName": "MBL INFRASTRUCTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE912H01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE836H01014",
                "SecurityId": "MCDHOLDING",
                "SecurityName": "MCDOWELL HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE836H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE854D01024",
                "SecurityId": "MCDOWELL-N",
                "SecurityName": "UNITED SPIRITS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE854D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE942G01012",
                "SecurityId": "MCLEODRUSS",
                "SecurityName": "MCLEOD RUSSEL INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE942G01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE745G01035",
                "SecurityId": "MCX",
                "SecurityName": "MULTI COMMODITY EXCHANGE OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE745G01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE933B01012",
                "SecurityId": "MEGASOFT",
                "SecurityName": "MEGASOFT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE933B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE974H01013",
                "SecurityId": "MEGH",
                "SecurityName": "MEGHMANI ORGANICS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE974H01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE817A01019",
                "SecurityId": "MELSTAR",
                "SecurityName": "MELSTAR INFORMATION TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE817A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE071D01033",
                "SecurityId": "MENONBE",
                "SecurityName": "MENON BEARINGS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE071D01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE776I01010",
                "SecurityId": "MEP",
                "SecurityName": "MEP INFRASTRUCTURE DEVELOPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE776I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE934B01028",
                "SecurityId": "MERCATOR",
                "SecurityName": "MERCATOR LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE934B01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE425A01011",
                "SecurityId": "METALFORGE",
                "SecurityName": "METALYST FORGINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE425A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE592I01029",
                "SecurityId": "METKORE",
                "SecurityName": "METKORE ALLOYS & INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE592I01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE112L01020",
                "SecurityId": "METROPOLIS",
                "SecurityName": "METROPOLIS HEALTHCARE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE112L01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE180A01020",
                "SecurityId": "MFSL",
                "SecurityName": "MAX FINANCIAL SERVICES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE180A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE002S01010",
                "SecurityId": "MGL",
                "SecurityName": "MAHANAGAR GAS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE002S01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE998I01010",
                "SecurityId": "MHRIL",
                "SecurityName": "MAHINDRA HOLIDAYS & RESORTS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE998I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE287C01029",
                "SecurityId": "MIC",
                "SecurityName": "MIC ELECTRONICS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE287C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE099Z01011",
                "SecurityId": "MIDHANI",
                "SecurityName": "MISHRA DHATU NIGAM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE099Z01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE842C01021",
                "SecurityId": "MINDACORP",
                "SecurityName": "MINDA CORPORATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE842C01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE405E01023",
                "SecurityId": "MINDAIND",
                "SecurityName": "MINDA INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE405E01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE110B01017",
                "SecurityId": "MINDTECK",
                "SecurityName": "MINDTECK (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE110B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE018I01017",
                "SecurityId": "MINDTREE",
                "SecurityName": "MINDTREE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE018I01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE831A01028",
                "SecurityId": "MIRCELECTR",
                "SecurityName": "MIRC ELECTRONICS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE831A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE771A01026",
                "SecurityId": "MIRZAINT",
                "SecurityName": "MIRZA INTERNATIONAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE771A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE227C01017",
                "SecurityId": "MMFL",
                "SecurityName": "MM FORGINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE227C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE511Y01018",
                "SecurityId": "MMP",
                "SecurityName": "MMP INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE511Y01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE123F01029",
                "SecurityId": "MMTC",
                "SecurityName": "MMTC LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE123F01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE832A01018",
                "SecurityId": "MODIRUBBER",
                "SecurityName": "MODI RUBBER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE832A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE954E01012",
                "SecurityId": "MOHITIND",
                "SecurityName": "MOHIT INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE954E01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE313D01013",
                "SecurityId": "MOHOTAIND",
                "SecurityName": "MOHOTA INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE313D01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE490G01020",
                "SecurityId": "MOIL",
                "SecurityName": "MOIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE490G01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE835B01035",
                "SecurityId": "MOLDTECH",
                "SecurityName": "MOLD-TEK TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE835B01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE893J01029",
                "SecurityId": "MOLDTKPAC",
                "SecurityName": "MOLD-TEK PACKAGING LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE893J01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE950M01013",
                "SecurityId": "MONTECARLO",
                "SecurityName": "MONTE CARLO FASHIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE950M01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE161G01027",
                "SecurityId": "MORARJEE",
                "SecurityName": "MORARJEE TEXTILES LIMITED",
                "FaceValue": "7",
                "ISINNo": "INE161G01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE083A01026",
                "SecurityId": "MOREPENLAB",
                "SecurityName": "MOREPEN LABORATORIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE083A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE775A01035",
                "SecurityId": "MOTHERSUMI",
                "SecurityName": "MOTHERSON SUMI SYSTEMS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE775A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE338I01027",
                "SecurityId": "MOTILALOFS",
                "SecurityName": "MOTILAL OSWAL FINANCIAL SERVICES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE338I01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE861B01015",
                "SecurityId": "MOTOGENFIN",
                "SecurityName": "THE MOTOR & GENERAL FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE861B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE356A01018",
                "SecurityId": "MPHASIS",
                "SecurityName": "MPHASIS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE356A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE943D01017",
                "SecurityId": "MPSLTD",
                "SecurityName": "MPS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE943D01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE883A01011",
                "SecurityId": "MRF",
                "SecurityName": "MRF LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE883A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE398B01018",
                "SecurityId": "MRO-TEK",
                "SecurityName": "MRO-TEK REALTY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE398B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE103A01014",
                "SecurityId": "MRPL",
                "SecurityName": "MANGALORE REFINERY AND PETROCHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE103A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE752G01015",
                "SecurityId": "MSPL",
                "SecurityName": "MSP STEEL & POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE752G01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE255X01014",
                "SecurityId": "MSTCLTD",
                "SecurityName": "MSTC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE255X01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE472M01018",
                "SecurityId": "MTEDUCARE",
                "SecurityName": "MT EDUCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE472M01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE153A01019",
                "SecurityId": "MTNL",
                "SecurityName": "MAHANAGAR TELEPHONE NIGAM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE153A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE022B01014",
                "SecurityId": "MUKANDENGG",
                "SecurityName": "MUKAND ENGINEERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE022B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE304A01026",
                "SecurityId": "MUKANDLTD",
                "SecurityName": "MUKAND LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE304A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE374B01019",
                "SecurityId": "MUKTAARTS",
                "SecurityName": "MUKTA ARTS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE374B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE672B01032",
                "SecurityId": "MUNJALAU",
                "SecurityName": "MUNJAL AUTO INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE672B01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE577A01027",
                "SecurityId": "MUNJALSHOW",
                "SecurityName": "MUNJAL SHOWA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE577A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE692B01014",
                "SecurityId": "MURUDCERA",
                "SecurityName": "MURUDESHWAR CERAMICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE692B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE296G01013",
                "SecurityId": "MUTHOOTCAP",
                "SecurityName": "MUTHOOT CAPITAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE296G01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE414G01012",
                "SecurityId": "MUTHOOTFIN",
                "SecurityName": "MUTHOOT FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE414G01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE295D01020",
                "SecurityId": "NACLIND",
                "SecurityName": "NACL INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE295D01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE454M01024",
                "SecurityId": "NAGAFERT",
                "SecurityName": "NAGARJUNA FERTILIZERS AND CHEMICALS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE454M01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE245I01016",
                "SecurityId": "NAGREEKCAP",
                "SecurityName": "NAGREEKA CAPITAL & INFRASTRUCTURE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE245I01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE123B01028",
                "SecurityId": "NAGREEKEXP",
                "SecurityName": "NAGREEKA EXPORTS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE123B01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE049I01012",
                "SecurityId": "NAHARCAP",
                "SecurityName": "NAHAR CAPITAL AND FINANCIAL SERVICES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE049I01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE289A01011",
                "SecurityId": "NAHARINDUS",
                "SecurityName": "NAHAR INDUSTRIAL ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE289A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE308A01027",
                "SecurityId": "NAHARPOLY",
                "SecurityName": "NAHAR POLY FILMS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE308A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE290A01027",
                "SecurityId": "NAHARSPING",
                "SecurityName": "NAHAR SPINNING MILLS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE290A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE298J01013",
                "SecurityId": "NAM-INDIA",
                "SecurityName": "NIPPON LIFE INDIA ASSET MANAGEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE298J01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE987B01026",
                "SecurityId": "NATCOPHARM",
                "SecurityName": "NATCO PHARMA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE987B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE448G01010",
                "SecurityId": "NATHBIOGEN",
                "SecurityName": "NATH BIO-GENES (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE448G01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE139A01034",
                "SecurityId": "NATIONALUM",
                "SecurityName": "NATIONAL ALUMINIUM COMPANY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE139A01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE088B01015",
                "SecurityId": "NATNLSTEEL",
                "SecurityName": "NATIONAL STEEL AND AGRO INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE088B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE663F01024",
                "SecurityId": "NAUKRI",
                "SecurityName": "INFO EDGE (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE663F01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE048G01026",
                "SecurityId": "NAVINFLUOR",
                "SecurityName": "NAVIN FLUORINE INTERNATIONAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE048G01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE278M01019",
                "SecurityId": "NAVKARCORP",
                "SecurityName": "NAVKAR CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE278M01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE060A01024",
                "SecurityId": "NAVNETEDUL",
                "SecurityName": "NAVNEET EDUCATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE060A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE095N01031",
                "SecurityId": "NBCC",
                "SecurityName": "NBCC (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE095N01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE365I01020",
                "SecurityId": "NBIFIN",
                "SecurityName": "N. B. I. INDUSTRIAL FINANCE COMPANY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE365I01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE725A01022",
                "SecurityId": "NBVENTURES",
                "SecurityName": "NAVA BHARAT VENTURES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE725A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE868B01028",
                "SecurityId": "NCC",
                "SecurityName": "NCC LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE868B01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE732C01016",
                "SecurityId": "NCLIND",
                "SecurityName": "NCL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE732C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE756C01015",
                "SecurityId": "NDGL",
                "SecurityName": "NAGA DHUNSERI GROUP LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE756C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE875G01030",
                "SecurityId": "NDL",
                "SecurityName": "NANDAN DENIM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE875G01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE155G01029",
                "SecurityId": "NDTV",
                "SecurityName": "NEW DELHI TELEVISION LIMITED",
                "FaceValue": "4",
                "ISINNo": "INE155G01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE553C01016",
                "SecurityId": "NECCLTD",
                "SecurityName": "NORTH EASTERN CARRYING CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE553C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE023H01027",
                "SecurityId": "NECLIFE",
                "SecurityName": "NECTAR LIFESCIENCES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE023H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE189I01024",
                "SecurityId": "NELCAST",
                "SecurityName": "NELCAST LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE189I01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE045B01015",
                "SecurityId": "NELCO",
                "SecurityName": "NELCO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE045B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE136S01016",
                "SecurityId": "NEOGEN",
                "SecurityName": "NEOGEN CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE136S01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE317F01035",
                "SecurityId": "NESCO",
                "SecurityName": "NESCO LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE317F01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE239A01016",
                "SecurityId": "NESTLEIND",
                "SecurityName": "NESTLE INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE239A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE870H01013",
                "SecurityId": "NETWORK18",
                "SecurityName": "NETWORK18 MEDIA & INVESTMENTS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE870H01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE794A01010",
                "SecurityId": "NEULANDLAB",
                "SecurityName": "NEULAND LABORATORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE794A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE619B01017",
                "SecurityId": "NEWGEN",
                "SecurityName": "NEWGEN SOFTWARE TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE619B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE747B01016",
                "SecurityId": "NEXTMEDIA",
                "SecurityName": "NEXT MEDIAWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE747B01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE870D01012",
                "SecurityId": "NFL",
                "SecurityName": "NATIONAL FERTILIZERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE870D01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE410P01011",
                "SecurityId": "NH",
                "SecurityName": "NARAYANA HRUDAYALAYA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE410P01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE848E01016",
                "SecurityId": "NHPC",
                "SecurityName": "NHPC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE848E01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE470Y01017",
                "SecurityId": "NIACL",
                "SecurityName": "THE NEW INDIA ASSURANCE COMPANY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE470Y01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE047O01014",
                "SecurityId": "NIBL",
                "SecurityName": "NRB INDUSTRIAL BEARINGS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE047O01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE161A01038",
                "SecurityId": "NIITLTD",
                "SecurityName": "NIIT LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE161A01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE591G01017",
                "SecurityId": "NIITTECH",
                "SecurityName": "NIIT TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE591G01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE937C01029",
                "SecurityId": "NILAINFRA",
                "SecurityName": "NILA INFRASTRUCTURES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE937C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE00S901012",
                "SecurityId": "NILASPACES",
                "SecurityName": "NILA SPACES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE00S901012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE310A01015",
                "SecurityId": "NILKAMAL",
                "SecurityName": "NILKAMAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE310A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE567A01010",
                "SecurityId": "NIPPOBATRY",
                "SecurityName": "INDO-NATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE567A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE326T01011",
                "SecurityId": "NIRAJISPAT",
                "SecurityName": "NIRAJ ISPAT INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE326T01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE858F01012",
                "SecurityId": "NITCO",
                "SecurityName": "NITCO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE858F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE489H01020",
                "SecurityId": "NITINFIRE",
                "SecurityName": "NITIN FIRE PROTECTION INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE489H01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE229H01012",
                "SecurityId": "NITINSPIN",
                "SecurityName": "NITIN SPINNERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE229H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE542C01019",
                "SecurityId": "NKIND",
                "SecurityName": "NK INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE542C01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE589A01014",
                "SecurityId": "NLCINDIA",
                "SecurityName": "NLC INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE589A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE584A01023",
                "SecurityId": "NMDC",
                "SecurityName": "NMDC LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE584A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE163A01018",
                "SecurityId": "NOCIL",
                "SecurityName": "NOCIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE163A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE781B01015",
                "SecurityId": "NOIDATOLL",
                "SecurityName": "NOIDA TOLL BRIDGE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE781B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE369C01017",
                "SecurityId": "NORBTEAEXP",
                "SecurityName": "NORBEN TEA & EXPORTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE369C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE740D01017",
                "SecurityId": "NRAIL",
                "SecurityName": "N R AGARWAL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE740D01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE349A01021",
                "SecurityId": "NRBBEARING",
                "SecurityName": "NRB BEARING LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE349A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE023A01030",
                "SecurityId": "NSIL",
                "SecurityName": "NALWA SONS INVESTMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE023A01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE333I01036",
                "SecurityId": "NTL",
                "SecurityName": "NEUEON TOWERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE333I01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE733E01010",
                "SecurityId": "NTPC",
                "SecurityName": "NTPC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE733E01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE096B01018",
                "SecurityId": "NUCLEUS",
                "SecurityName": "NUCLEUS SOFTWARE EXPORTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE096B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE353A01023",
                "SecurityId": "NXTDIGITAL",
                "SecurityName": "NXTDIGITAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE353A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE959C01023",
                "SecurityId": "OAL",
                "SecurityName": "ORIENTAL AROMATICS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE959C01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE093I01010",
                "SecurityId": "OBEROIRLTY",
                "SecurityName": "OBEROI REALTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE093I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE321D01016",
                "SecurityId": "OCCL",
                "SecurityName": "ORIENTAL CARBON & CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE321D01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE881D01027",
                "SecurityId": "OFSS",
                "SecurityName": "ORACLE FINANCIAL SERVICES SOFTWARE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE881D01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE274J01014",
                "SecurityId": "OIL",
                "SecurityName": "OIL INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE274J01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE591A01010",
                "SecurityId": "OILCOUNTUB",
                "SecurityName": "OIL COUNTRY TUBULAR LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE591A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE196J01019",
                "SecurityId": "OISL",
                "SecurityName": "OCL IRON AND STEEL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE196J01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE260D01016",
                "SecurityId": "OLECTRA",
                "SecurityName": "OLECTRA GREENTECH LIMITED",
                "FaceValue": "4",
                "ISINNo": "INE260D01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE090B01011",
                "SecurityId": "OMAXAUTO",
                "SecurityName": "OMAX AUTOS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE090B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE800H01010",
                "SecurityId": "OMAXE",
                "SecurityName": "OMAXE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE800H01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE474L01016",
                "SecurityId": "OMKARCHEM",
                "SecurityName": "OMKAR SPECIALITY CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE474L01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE239D01028",
                "SecurityId": "OMMETALS",
                "SecurityName": "OM METALS INFRAPROJECTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE239D01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE912L01015",
                "SecurityId": "ONELIFECAP",
                "SecurityName": "ONELIFE CAPITAL ADVISORS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE912L01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE840Y01011",
                "SecurityId": "ONEPOINT",
                "SecurityName": "ONE POINT ONE SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE840Y01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE213A01029",
                "SecurityId": "ONGC",
                "SecurityName": "OIL & NATURAL GAS CORPORATION LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE213A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE809I01019",
                "SecurityId": "ONMOBILE",
                "SecurityName": "ONMOBILE GLOBAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE809I01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE229A01017",
                "SecurityId": "ONWARDTEC",
                "SecurityName": "ONWARD TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE229A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE350C01017",
                "SecurityId": "OPTIEMUS",
                "SecurityName": "OPTIEMUS INFRACOM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE350C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE808B01016",
                "SecurityId": "OPTOCIRCUI",
                "SecurityName": "OPTO CIRCUITS (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE808B01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE231G01010",
                "SecurityId": "ORBTEXP",
                "SecurityName": "ORBIT EXPORTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE231G01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE730A01022",
                "SecurityId": "ORICONENT",
                "SecurityName": "ORICON ENTERPRISES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE730A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE569C01020",
                "SecurityId": "ORIENTABRA",
                "SecurityName": "ORIENT ABRASIVES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE569C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE998H01012",
                "SecurityId": "ORIENTALTL",
                "SecurityName": "ORIENTAL TRIMEX LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE998H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE141A01014",
                "SecurityId": "ORIENTBANK",
                "SecurityName": "ORIENTAL BANK OF COMMERCE",
                "FaceValue": "10",
                "ISINNo": "INE141A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE607D01018",
                "SecurityId": "ORIENTBELL",
                "SecurityName": "ORIENT BELL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE607D01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE876N01018",
                "SecurityId": "ORIENTCEM",
                "SecurityName": "ORIENT CEMENT LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE876N01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE142Z01019",
                "SecurityId": "ORIENTELEC",
                "SecurityName": "ORIENT ELECTRIC LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE142Z01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE750A01020",
                "SecurityId": "ORIENTHOT",
                "SecurityName": "ORIENTAL HOTELS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE750A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE609C01024",
                "SecurityId": "ORIENTLTD",
                "SecurityName": "ORIENT PRESS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE609C01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE592A01026",
                "SecurityId": "ORIENTPPR",
                "SecurityName": "ORIENT PAPER & INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE592A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE743M01012",
                "SecurityId": "ORIENTREF",
                "SecurityName": "ORIENT REFRACTORIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE743M01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE725E01024",
                "SecurityId": "ORISSAMINE",
                "SecurityName": "THE ORISSA MINERALS DEVELOPMENT COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE725E01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE849L01019",
                "SecurityId": "ORTEL",
                "SecurityName": "ORTEL COMMUNICATIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE849L01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE749B01012",
                "SecurityId": "ORTINLABSS",
                "SecurityName": "ORTIN LABORATORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE749B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE142A01012",
                "SecurityId": "OSWALAGRO",
                "SecurityName": "OSWAL AGRO MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE142A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE766A01018",
                "SecurityId": "PAEL",
                "SecurityName": "PAE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE766A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE761H01022",
                "SecurityId": "PAGEIND",
                "SecurityName": "PAGE INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE761H01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE420C01042",
                "SecurityId": "PAISALO",
                "SecurityName": "PAISALO DIGITAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE420C01042",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE471W01019",
                "SecurityId": "PALASHSECU",
                "SecurityName": "PALASH SECURITIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE471W01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE218G01033",
                "SecurityId": "PALREDTEC",
                "SecurityName": "PALRED TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE218G01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE922B01023",
                "SecurityId": "PANACEABIO",
                "SecurityName": "PANACEA BIOTEC LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE922B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE895W01019",
                "SecurityId": "PANACHE",
                "SecurityName": "PANACHE DIGILIFE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE895W01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE305C01029",
                "SecurityId": "PANAMAPET",
                "SecurityName": "PANAMA PETROCHEM LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE305C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE275B01026",
                "SecurityId": "PAPERPROD",
                "SecurityName": "HUHTAMAKI PPL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE275B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE618H01016",
                "SecurityId": "PARABDRUGS",
                "SecurityName": "PARABOLIC DRUGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE618H01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE074B01023",
                "SecurityId": "PARACABLES",
                "SecurityName": "PARAMOUNT COMMUNICATIONS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE074B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE883N01014",
                "SecurityId": "PARAGMILK",
                "SecurityName": "PARAG MILK FOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE883N01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE561H01026",
                "SecurityId": "PARSVNATH",
                "SecurityName": "PARSVNATH DEVELOPERS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE561H01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE244B01030",
                "SecurityId": "PATELENG",
                "SecurityName": "PATEL ENGINEERING LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE244B01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE529D01014",
                "SecurityId": "PATINTLOG",
                "SecurityName": "PATEL INTEGRATED LOGISTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE529D01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE790C01014",
                "SecurityId": "PATSPINLTD",
                "SecurityName": "PATSPIN INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE790C01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE785M01013",
                "SecurityId": "PCJEWELLER",
                "SecurityName": "PC JEWELLER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE785M01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE865T01018",
                "SecurityId": "PDMJEPAPER",
                "SecurityName": "PUDUMJEE PAPER PRODUCTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE865T01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE111Q01013",
                "SecurityId": "PDSMFL",
                "SecurityName": "PDS MULTINATIONAL FASHIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE111Q01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE844A01013",
                "SecurityId": "PEARLPOLY",
                "SecurityName": "PEARL POLYMERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE844A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE140A01024",
                "SecurityId": "PEL",
                "SecurityName": "PIRAMAL ENTERPRISES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE140A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE932A01024",
                "SecurityId": "PENIND",
                "SecurityName": "PENNAR INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE932A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE138A01028",
                "SecurityId": "PENINLAND",
                "SecurityName": "PENINSULA LAND LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE138A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE262H01013",
                "SecurityId": "PERSISTENT",
                "SecurityName": "PERSISTENT SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE262H01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE742A01019",
                "SecurityId": "PETRONENGG",
                "SecurityName": "PETRON ENGINEERING CONSTRUCTION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE742A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE347G01014",
                "SecurityId": "PETRONET",
                "SecurityName": "PETRONET LNG LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE347G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE134E01011",
                "SecurityId": "PFC",
                "SecurityName": "POWER FINANCE CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE134E01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE182A01018",
                "SecurityId": "PFIZER",
                "SecurityName": "PFIZER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE182A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE367G01038",
                "SecurityId": "PFOCUS",
                "SecurityName": "PRIME FOCUS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE367G01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE560K01014",
                "SecurityId": "PFS",
                "SecurityName": "PTC INDIA FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE560K01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE457L01011",
                "SecurityId": "PGEL",
                "SecurityName": "PG ELECTROPLAST LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE457L01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE179A01014",
                "SecurityId": "PGHH",
                "SecurityName": "PROCTER & GAMBLE HYGIENE AND HEALTH CARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE179A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE199A01012",
                "SecurityId": "PGHL",
                "SecurityName": "PROCTER & GAMBLE HEALTH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE199A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE940H01014",
                "SecurityId": "PGIL",
                "SecurityName": "PEARL GLOBAL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE940H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE602A01023",
                "SecurityId": "PHILIPCARB",
                "SecurityName": "PHILLIPS CARBON BLACK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE602A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE211B01039",
                "SecurityId": "PHOENIXLTD",
                "SecurityName": "THE PHOENIX MILLS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE211B01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE318A01026",
                "SecurityId": "PIDILITIND",
                "SecurityName": "PIDILITE INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE318A01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE603J01030",
                "SecurityId": "PIIND",
                "SecurityName": "PI INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE603J01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE417C01014",
                "SecurityId": "PILANIINVS",
                "SecurityName": "PILANI INVESTMENT AND INDUSTRIES CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE417C01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE600A01035",
                "SecurityId": "PILITA",
                "SecurityName": "PIL ITALICA LIFESTYLE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE600A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE889E01010",
                "SecurityId": "PIONDIST",
                "SecurityName": "PIONEER DISTILLERIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE889E01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE156C01018",
                "SecurityId": "PIONEEREMB",
                "SecurityName": "PIONEER EMBROIDERIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE156C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE450D01021",
                "SecurityId": "PITTIENG",
                "SecurityName": "PITTI ENGINEERING LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE450D01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE431F01018",
                "SecurityId": "PKTEA",
                "SecurityName": "THE PERIA KARAMALAI TEA & PRODUCE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE431F01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE083C01022",
                "SecurityId": "PLASTIBLEN",
                "SecurityName": "PLASTIBLENDS INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE083C01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE160A01022",
                "SecurityId": "PNB",
                "SecurityName": "PUNJAB NATIONAL BANK",
                "FaceValue": "2",
                "ISINNo": "INE160A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE859A01011",
                "SecurityId": "PNBGILTS",
                "SecurityName": "PNB GILTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE859A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE572E01012",
                "SecurityId": "PNBHOUSING",
                "SecurityName": "PNB HOUSING FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE572E01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE392B01011",
                "SecurityId": "PNC",
                "SecurityName": "PRITISH NANDY COMMUNICATIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE392B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE195J01029",
                "SecurityId": "PNCINFRA",
                "SecurityName": "PNC INFRATECH LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE195J01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE888B01018",
                "SecurityId": "PODDARHOUS",
                "SecurityName": "PODDAR HOUSING AND DEVELOPMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE888B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE371C01013",
                "SecurityId": "PODDARMENT",
                "SecurityName": "PODDAR PIGMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE371C01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE637C01025",
                "SecurityId": "POKARNA",
                "SecurityName": "POKARNA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE637C01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE455K01017",
                "SecurityId": "POLYCAB",
                "SecurityName": "POLYCAB INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE455K01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE205C01021",
                "SecurityId": "POLYMED",
                "SecurityName": "POLY MEDICURE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE205C01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE633B01018",
                "SecurityId": "POLYPLEX",
                "SecurityName": "POLYPLEX CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE633B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE838E01017",
                "SecurityId": "PONNIERODE",
                "SecurityName": "PONNI SUGARS (ERODE) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE838E01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE752E01010",
                "SecurityId": "POWERGRID",
                "SecurityName": "POWER GRID CORPORATION OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE752E01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE211R01019",
                "SecurityId": "POWERMECH",
                "SecurityName": "POWER MECH PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE211R01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE095I01015",
                "SecurityId": "PPAP",
                "SecurityName": "PPAP AUTOMOTIVE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE095I01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE050001010",
                "SecurityId": "PPL",
                "SecurityName": "PRAKASH PIPES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE050001010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE302M01033",
                "SecurityId": "PRABHAT",
                "SecurityName": "PRABHAT DAIRY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE302M01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE495J01015",
                "SecurityId": "PRADIP",
                "SecurityName": "PRADIP OVERSEAS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE495J01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE505C01016",
                "SecurityId": "PRAENG",
                "SecurityName": "PRAJAY ENGINEERS SYNDICATE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE505C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE074A01025",
                "SecurityId": "PRAJIND",
                "SecurityName": "PRAJ INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE074A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE603A01013",
                "SecurityId": "PRAKASH",
                "SecurityName": "PRAKASH INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE603A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE696K01024",
                "SecurityId": "PRAKASHSTL",
                "SecurityName": "PRAKASH STEELAGE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE696K01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE546Y01022",
                "SecurityId": "PRAXIS",
                "SecurityName": "PRAXIS HOME RETAIL LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE546Y01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE484I01029",
                "SecurityId": "PRECAM",
                "SecurityName": "PRECISION CAMSHAFTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE484I01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE283A01014",
                "SecurityId": "PRECOT",
                "SecurityName": "PRECOT MERIDIAN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE283A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE372C01029",
                "SecurityId": "PRECWIRE",
                "SecurityName": "PRECISION WIRES INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE372C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE863B01011",
                "SecurityId": "PREMEXPLN",
                "SecurityName": "PREMIER EXPLOSIVES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE863B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE342A01018",
                "SecurityId": "PREMIER",
                "SecurityName": "PREMIER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE342A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE309M01012",
                "SecurityId": "PREMIERPOL",
                "SecurityName": "PREMIER POLYFILM LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE309M01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE980A01023",
                "SecurityId": "PRESSMN",
                "SecurityName": "PRESSMAN ADVERTISING LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE980A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE811K01011",
                "SecurityId": "PRESTIGE",
                "SecurityName": "PRESTIGE ESTATES PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE811K01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE726V01018",
                "SecurityId": "PRICOLLTD",
                "SecurityName": "PRICOL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE726V01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE032B01021",
                "SecurityId": "PRIMESECU",
                "SecurityName": "PRIME SECURITIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE032B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE689W01016",
                "SecurityId": "PRINCEPIPE",
                "SecurityName": "PRINCE PIPES AND FITTINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE689W01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE217G01027",
                "SecurityId": "PROSEED",
                "SecurityName": "PROSEED INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE217G01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE195N01013",
                "SecurityId": "PROZONINTU",
                "SecurityName": "PROZONE INTU PROPERTIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE195N01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE010A01011",
                "SecurityId": "PRSMJOHNSN",
                "SecurityName": "PRISM JOHNSON LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE010A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE608A01012",
                "SecurityId": "PSB",
                "SecurityName": "PUNJAB & SIND BANK",
                "FaceValue": "10",
                "ISINNo": "INE608A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE474B01017",
                "SecurityId": "PSL",
                "SecurityName": "PSL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE474B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE488V01015",
                "SecurityId": "PSPPROJECT",
                "SecurityName": "PSP PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE488V01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE877F01012",
                "SecurityId": "PTC",
                "SecurityName": "PTC INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE877F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE034D01031",
                "SecurityId": "PTL",
                "SecurityName": "PTL ENTERPRISES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE034D01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE277B01014",
                "SecurityId": "PUNJABCHEM",
                "SecurityName": "PUNJAB CHEMICALS & CROP PROTECTION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE277B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE701B01021",
                "SecurityId": "PUNJLLOYD",
                "SecurityName": "PUNJ LLOYD LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE701B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE323I01011",
                "SecurityId": "PURVA",
                "SecurityName": "PURAVANKARA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE323I01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE191H01014",
                "SecurityId": "PVR",
                "SecurityName": "PVR LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE191H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE615P01015",
                "SecurityId": "QUESS",
                "SecurityName": "QUESS CORP LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE615P01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE306L01010",
                "SecurityId": "QUICKHEAL",
                "SecurityName": "QUICK HEAL TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE306L01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE874F01027",
                "SecurityId": "RADAAN",
                "SecurityName": "RADAAN MEDIAWORKS INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE874F01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE944F01028",
                "SecurityId": "RADICO",
                "SecurityName": "RADICO KHAITAN LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE944F01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE919I01024",
                "SecurityId": "RADIOCITY",
                "SecurityName": "MUSIC BROADCAST LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE919I01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE855B01025",
                "SecurityId": "RAIN",
                "SecurityName": "RAIN INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE855B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE343B01030",
                "SecurityId": "RAJESHEXPO",
                "SecurityName": "RAJESH EXPORTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE343B01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE533D01024",
                "SecurityId": "RAJRAYON",
                "SecurityName": "RAJ RAYON INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE533D01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE562B01019",
                "SecurityId": "RAJSREESUG",
                "SecurityName": "RAJSHREE SUGARS & CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE562B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE952H01027",
                "SecurityId": "RAJTV",
                "SecurityName": "RAJ TELEVISION NETWORK LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE952H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE613A01020",
                "SecurityId": "RALLIS",
                "SecurityName": "RALLIS INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE613A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE278B01020",
                "SecurityId": "RAMANEWS",
                "SecurityName": "SHREE RAMA NEWSPRINT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE278B01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE230R01027",
                "SecurityId": "RAMASTEEL",
                "SecurityName": "RAMA STEEL TUBES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE230R01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE331A01037",
                "SecurityId": "RAMCOCEM",
                "SecurityName": "THE RAMCO CEMENTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE331A01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE614A01028",
                "SecurityId": "RAMCOIND",
                "SecurityName": "RAMCO INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE614A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE246B01019",
                "SecurityId": "RAMCOSYS",
                "SecurityName": "RAMCO SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE246B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE874I01013",
                "SecurityId": "RAMKY",
                "SecurityName": "RAMKY INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE874I01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE005D01015",
                "SecurityId": "RAMSARUP",
                "SecurityName": "RAMSARUP INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE005D01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE625B01014",
                "SecurityId": "RANASUG",
                "SecurityName": "RANA SUGARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE625B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE222J01013",
                "SecurityId": "RANEENGINE",
                "SecurityName": "RANE ENGINE VALVE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE222J01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE384A01010",
                "SecurityId": "RANEHOLDIN",
                "SecurityName": "RANE HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE384A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE703B01027",
                "SecurityId": "RATNAMANI",
                "SecurityName": "RATNAMANI METALS & TUBES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE703B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE301A01014",
                "SecurityId": "RAYMOND",
                "SecurityName": "RAYMOND LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE301A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE244J01017",
                "SecurityId": "RBL",
                "SecurityName": "RANE BRAKE LINING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE244J01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE976G01028",
                "SecurityId": "RBLBANK",
                "SecurityName": "RBL BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE976G01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE027A01015",
                "SecurityId": "RCF",
                "SecurityName": "RASHTRIYA CHEMICALS AND FERTILIZERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE027A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE330H01018",
                "SecurityId": "RCOM",
                "SecurityName": "RELIANCE COMMUNICATIONS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE330H01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE020B01018",
                "SecurityId": "RECLTD",
                "SecurityName": "REC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE020B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE891D01026",
                "SecurityId": "REDINGTON",
                "SecurityName": "REDINGTON (INDIA) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE891D01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE056I01017",
                "SecurityId": "REFEX",
                "SecurityName": "REFEX INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE056I01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE131B01039",
                "SecurityId": "RELAXO",
                "SecurityName": "RELAXO FOOTWEARS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE131B01039",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE013A01015",
                "SecurityId": "RELCAPITAL",
                "SecurityName": "RELIANCE CAPITAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE013A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE002A01018",
                "SecurityId": "RELIANCE",
                "SecurityName": "RELIANCE INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE002A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE621H01010",
                "SecurityId": "RELIGARE",
                "SecurityName": "RELIGARE ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE621H01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE036A01016",
                "SecurityId": "RELINFRA",
                "SecurityName": "RELIANCE INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE036A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE474C01015",
                "SecurityId": "REMSONSIND",
                "SecurityName": "REMSONS INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE474C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE087H01022",
                "SecurityId": "RENUKA",
                "SecurityName": "SHREE RENUKA SUGARS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE087H01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE612J01015",
                "SecurityId": "REPCOHOME",
                "SecurityName": "REPCO HOME FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE612J01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE461B01014",
                "SecurityId": "REPRO",
                "SecurityName": "REPRO INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE461B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE688D01026",
                "SecurityId": "RESPONIND",
                "SecurityName": "RESPONSIVE INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE688D01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE617A01013",
                "SecurityId": "REVATHI",
                "SecurityName": "REVATHI EQUIPMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE617A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE722H01016",
                "SecurityId": "RGL",
                "SecurityName": "RENAISSANCE GLOBAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE722H01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE217K01011",
                "SecurityId": "RHFL",
                "SecurityName": "RELIANCE HOME FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE217K01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE209B01025",
                "SecurityId": "RICOAUTO",
                "SecurityName": "RICO AUTO INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE209B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE046A01015",
                "SecurityId": "RIIL",
                "SecurityName": "RELIANCE INDUSTRIAL INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE046A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE320J01015",
                "SecurityId": "RITES",
                "SecurityName": "RITES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE320J01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE722J01012",
                "SecurityId": "RKDL",
                "SecurityName": "RAVI KUMAR DISTILLERIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE722J01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE399G01015",
                "SecurityId": "RKFORGE",
                "SecurityName": "RAMKRISHNA FORGINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE399G01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE172H01014",
                "SecurityId": "RMCL",
                "SecurityName": "RADHA MADHAV CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE172H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE050H01012",
                "SecurityId": "RML",
                "SecurityName": "RANE (MADRAS) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE050H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE542F01012",
                "SecurityId": "RNAVAL",
                "SecurityName": "RELIANCE NAVAL AND ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE542F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE248H01012",
                "SecurityId": "ROHITFERRO",
                "SecurityName": "ROHIT FERRO-TECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE248H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE283H01019",
                "SecurityId": "ROHLTD",
                "SecurityName": "ROYAL ORCHID HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE283H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE927A01040",
                "SecurityId": "ROLLT",
                "SecurityName": "ROLLATAINERS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE927A01040",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE293A01013",
                "SecurityId": "ROLTA",
                "SecurityName": "ROLTA INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE293A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE847C01020",
                "SecurityId": "ROSSELLIND",
                "SecurityName": "ROSSELL INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE847C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE105J01010",
                "SecurityId": "RPGLIFE",
                "SecurityName": "RPG LIFE SCIENCES LIMITED",
                "FaceValue": "8",
                "ISINNo": "INE105J01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE614G01033",
                "SecurityId": "RPOWER",
                "SecurityName": "RELIANCE POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE614G01033",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE324L01013",
                "SecurityId": "RPPINFRA",
                "SecurityName": "R.P.P. INFRA PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE324L01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE165B01029",
                "SecurityId": "RSSOFTWARE",
                "SecurityName": "R. S. SOFTWARE (INDIA) LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE165B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE611A01016",
                "SecurityId": "RSWM",
                "SecurityName": "RSWM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE611A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE411H01032",
                "SecurityId": "RSYSTEMS",
                "SecurityName": "R SYSTEMS INTERNATIONAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE411H01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE399K01017",
                "SecurityId": "RTNPOWER",
                "SecurityName": "RATTANINDIA POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE399K01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE301D01026",
                "SecurityId": "RUBYMILLS",
                "SecurityName": "THE RUBY MILLS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE301D01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE619A01035",
                "SecurityId": "RUCHI",
                "SecurityName": "RUCHI SOYA INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE619A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE413B01023",
                "SecurityId": "RUCHINFRA",
                "SecurityName": "RUCHI INFRASTRUCTURE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE413B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE803H01014",
                "SecurityId": "RUCHIRA",
                "SecurityName": "RUCHIRA PAPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE803H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE895B01021",
                "SecurityId": "RUPA",
                "SecurityName": "RUPA & COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE895B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE573K01017",
                "SecurityId": "RUSHIL",
                "SecurityName": "RUSHIL DECOR LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE573K01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE415G01027",
                "SecurityId": "RVNL",
                "SecurityName": "RAIL VIKAS NIGAM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE415G01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE860T01019",
                "SecurityId": "SABEVENTS",
                "SecurityName": "SAB EVENTS & GOVERNANCE NOW MEDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE860T01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE416A01036",
                "SecurityId": "SABTN",
                "SecurityName": "SRI ADHIKARI BROTHERS TELEVISION NETWORK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE416A01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE226H01026",
                "SecurityId": "SADBHAV",
                "SecurityName": "SADBHAV ENGINEERING LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE226H01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE764L01010",
                "SecurityId": "SADBHIN",
                "SecurityName": "SADBHAV INFRASTRUCTURE PROJECT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE764L01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE429E01023",
                "SecurityId": "SAFARI",
                "SecurityName": "SAFARI INDUSTRIES (INDIA) LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE429E01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE976T01013",
                "SecurityId": "SAGARDEEP",
                "SecurityName": "SAGARDEEP ALLOYS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE976T01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE229C01013",
                "SecurityId": "SAGCEM",
                "SecurityName": "SAGAR CEMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE229C01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE114A01011",
                "SecurityId": "SAIL",
                "SecurityName": "STEEL AUTHORITY OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE114A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE732S01012",
                "SecurityId": "SAKAR",
                "SecurityName": "SAKAR HEALTHCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE732S01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE623A01011",
                "SecurityId": "SAKHTISUG",
                "SecurityName": "SAKTHI SUGARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE623A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE667G01015",
                "SecurityId": "SAKSOFT",
                "SecurityName": "SAKSOFT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE667G01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE190H01024",
                "SecurityId": "SAKUMA",
                "SecurityName": "SAKUMA EXPORTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE190H01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE170V01019",
                "SecurityId": "SALASAR",
                "SecurityName": "SALASAR TECHNO ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE170V01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE498E01010",
                "SecurityId": "SALONA",
                "SecurityName": "SALONA COTSPIN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE498E01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE658G01014",
                "SecurityId": "SALSTEEL",
                "SecurityName": "S.A.L. STEEL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE658G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE457F01013",
                "SecurityId": "SALZERELEC",
                "SecurityName": "SALZER ELECTRONICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE457F01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE699B01027",
                "SecurityId": "SAMBHAAV",
                "SecurityName": "SAMBHAAV MEDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE699B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE782L01012",
                "SecurityId": "SANCO",
                "SecurityName": "SANCO INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE782L01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE583B01015",
                "SecurityId": "SANDESH",
                "SecurityName": "THE SANDESH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE583B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE278H01035",
                "SecurityId": "SANDHAR",
                "SecurityName": "SANDHAR TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE278H01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE495C01010",
                "SecurityId": "SANGAMIND",
                "SecurityName": "SANGAM (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE495C01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE999B01013",
                "SecurityId": "SANGHIIND",
                "SecurityName": "SANGHI INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE999B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE263L01013",
                "SecurityId": "SANGHVIFOR",
                "SecurityName": "SANGHVI FORGING AND ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE263L01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE989A01024",
                "SecurityId": "SANGHVIMOV",
                "SecurityName": "SANGHVI MOVERS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE989A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE753W01010",
                "SecurityId": "SANGINITA",
                "SecurityName": "SANGINITA CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE753W01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE058A01010",
                "SecurityId": "SANOFI",
                "SecurityName": "SANOFI INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE058A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE890C01046",
                "SecurityId": "SANWARIA",
                "SecurityName": "SANWARIA CONSUMER LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE890C01046",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE385C01013",
                "SecurityId": "SARDAEN",
                "SecurityName": "SARDA ENERGY & MINERALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE385C01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE979A01017",
                "SecurityId": "SAREGAMA",
                "SecurityName": "SAREGAMA INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE979A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE453D01025",
                "SecurityId": "SARLAPOLY",
                "SecurityName": "SARLA PERFORMANCE FIBERS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE453D01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE231F01020",
                "SecurityId": "SASKEN",
                "SecurityName": "SASKEN TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE231F01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE019J01013",
                "SecurityId": "SASTASUNDR",
                "SecurityName": "SASTASUNDAR VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE019J01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE176C01016",
                "SecurityId": "SATHAISPAT",
                "SecurityName": "SATHAVAHANA ISPAT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE176C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE170E01023",
                "SecurityId": "SATIA",
                "SecurityName": "SATIA INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE170E01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE836B01017",
                "SecurityId": "SATIN",
                "SecurityName": "SATIN CREDITCARE NETWORK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE836B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE018E01016",
                "SecurityId": "SBICARD",
                "SecurityName": "SBI CARDS AND PAYMENT SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE018E01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE123W01016",
                "SecurityId": "SBILIFE",
                "SecurityName": "SBI LIFE INSURANCE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE123W01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE062A01020",
                "SecurityId": "SBIN",
                "SecurityName": "STATE BANK OF INDIA",
                "FaceValue": "1",
                "ISINNo": "INE062A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE224E01036",
                "SecurityId": "SCAPDVR",
                "SecurityName": "STAMPEDE CAPITAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE224E01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE513A01014",
                "SecurityId": "SCHAEFFLER",
                "SecurityName": "SCHAEFFLER INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE513A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE807K01035",
                "SecurityId": "SCHAND",
                "SecurityName": "S CHAND AND COMPANY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE807K01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE839M01018",
                "SecurityId": "SCHNEIDER",
                "SecurityName": "SCHNEIDER ELECTRIC INFRASTRUCTURE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE839M01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE109A01011",
                "SecurityId": "SCI",
                "SecurityName": "SHIPPING CORPORATION OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE109A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE480C01012",
                "SecurityId": "SDBL",
                "SecurityName": "SOM DISTILLERIES & BREWERIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE480C01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE497B01018",
                "SecurityId": "SEAMECLTD",
                "SecurityName": "SEAMEC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE497B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE818A01017",
                "SecurityId": "SELAN",
                "SecurityName": "SELAN EXPLORATION TECHNOLOGY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE818A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE105I01012",
                "SecurityId": "SELMCL",
                "SecurityName": "SEL MANUFACTURING COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE105I01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE735M01018",
                "SecurityId": "SEPOWER",
                "SecurityName": "S.E. POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE735M01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE807F01027",
                "SecurityId": "SEQUENT",
                "SecurityName": "SEQUENT SCIENTIFIC LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE807F01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE630A01024",
                "SecurityId": "SESHAPAPER",
                "SecurityName": "SESHASAYEE PAPER AND BOARDS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE630A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE878E01021",
                "SecurityId": "SETCO",
                "SecurityName": "SETCO AUTOMOTIVE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE878E01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE023M01027",
                "SecurityId": "SETUINFRA",
                "SecurityName": "SETUBANDHAN INFRASTRUCTURE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE023M01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE573R01012",
                "SecurityId": "SEYAIND",
                "SecurityName": "SEYA INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE573R01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE955I01036",
                "SecurityId": "SEZAL",
                "SecurityName": "SEZAL GLASS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE955I01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE916U01025",
                "SecurityId": "SFL",
                "SecurityName": "SHEELA FOAM LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE916U01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE353H01010",
                "SecurityId": "SGL",
                "SecurityName": "STL GLOBAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE353H01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE640C01011",
                "SecurityId": "SHAHALLOYS",
                "SecurityName": "SHAH ALLOYS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE640C01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE908D01010",
                "SecurityId": "SHAKTIPUMP",
                "SecurityName": "SHAKTI PUMPS (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE908D01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE597J01018",
                "SecurityId": "SHALBY",
                "SecurityName": "SHALBY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE597J01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE849C01026",
                "SecurityId": "SHALPAINTS",
                "SecurityName": "SHALIMAR PAINTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE849C01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE274V01019",
                "SecurityId": "SHANKARA",
                "SecurityName": "SHANKARA BUILDING PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE274V01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE631A01022",
                "SecurityId": "SHANTIGEAR",
                "SecurityName": "SHANTHI GEARS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE631A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE221J01015",
                "SecurityId": "SHARDACROP",
                "SecurityName": "SHARDA CROPCHEM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE221J01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE597I01010",
                "SecurityId": "SHARDAMOTR",
                "SecurityName": "SHARDA MOTOR INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE597I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE363M01019",
                "SecurityId": "SHEMAROO",
                "SecurityName": "SHEMAROO ENTERTAINMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE363M01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE05AN01011",
                "SecurityId": "SHIL",
                "SecurityName": "SOMANY HOME INNOVATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE05AN01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE790G01031",
                "SecurityId": "SHILPAMED",
                "SecurityName": "SHILPA MEDICARE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE790G01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE196B01016",
                "SecurityId": "SHIRPUR-G",
                "SecurityName": "SHIRPUR GOLD REFINERY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE196B01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE637H01024",
                "SecurityId": "SHIVAMAUTO",
                "SecurityName": "SHIVAM AUTOTECH LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE637H01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE644Y01017",
                "SecurityId": "SHIVAMILLS",
                "SecurityName": "SHIVA MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE644Y01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE705C01020",
                "SecurityId": "SHIVATEX",
                "SecurityName": "SHIVA TEXYARN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE705C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE500L01026",
                "SecurityId": "SHK",
                "SecurityName": "S H KELKAR AND COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE500L01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE498B01024",
                "SecurityId": "SHOPERSTOP",
                "SecurityName": "SHOPPERS STOP LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE498B01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE232A01011",
                "SecurityId": "SHREDIGCEM",
                "SecurityName": "SHREE DIGVIJAY CEMENT CO.LTD",
                "FaceValue": "10",
                "ISINNo": "INE232A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE070A01015",
                "SecurityId": "SHREECEM",
                "SecurityName": "SHREE CEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE070A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE712K01011",
                "SecurityId": "SHREEPUSHK",
                "SecurityName": "SHREE PUSHKAR CHEMICALS & FERTILISERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE712K01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE879A01019",
                "SecurityId": "SHREERAMA",
                "SecurityName": "SHREE RAMA MULTI-TECH LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE879A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE632X01022",
                "SecurityId": "SHRENIK",
                "SecurityName": "SHRENIK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE632X01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE231C01019",
                "SecurityId": "SHREYANIND",
                "SecurityName": "SHREYANS INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE231C01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE757B01015",
                "SecurityId": "SHREYAS",
                "SecurityName": "SHREYAS SHIPPING & LOGISTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE757B01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE526E01018",
                "SecurityId": "SHRIPISTON",
                "SecurityName": "SHRIRAM PISTONS & RINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE526E01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE722A01011",
                "SecurityId": "SHRIRAMCIT",
                "SecurityName": "SHRIRAM CITY UNION FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE722A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE964H01014",
                "SecurityId": "SHRIRAMEPC",
                "SecurityName": "SHRIRAM EPC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE964H01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE979R01011",
                "SecurityId": "SHYAMCENT",
                "SecurityName": "SHYAM CENTURY FERROUS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE979R01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE635A01023",
                "SecurityId": "SHYAMTEL",
                "SecurityName": "SHYAM TELECOM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE635A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE176J01011",
                "SecurityId": "SICAGEN",
                "SecurityName": "SICAGEN INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE176J01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE075B01012",
                "SecurityId": "SICAL",
                "SecurityName": "SICAL LOGISTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE075B01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE003A01024",
                "SecurityId": "SIEMENS",
                "SecurityName": "SIEMENS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE003A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE529F01035",
                "SecurityId": "SIGIND",
                "SecurityName": "SIGNET INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE529F01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE173A01025",
                "SecurityId": "SIL",
                "SecurityName": "STANDARD INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE173A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE923A01015",
                "SecurityId": "SILINV",
                "SecurityName": "SIL INVESTMENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE923A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE748T01016",
                "SecurityId": "SIMBHALS",
                "SecurityName": "SIMBHAOLI SUGARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE748T01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE059B01024",
                "SecurityId": "SIMPLEXINF",
                "SecurityName": "SIMPLEX INFRASTRUCTURES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE059B01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE429C01035",
                "SecurityId": "SINTEX",
                "SecurityName": "SINTEX INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE429C01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE792Z01011",
                "SecurityId": "SIRCA",
                "SecurityName": "SIRCA PAINTS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE792Z01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE285J01028",
                "SecurityId": "SIS",
                "SecurityName": "SECURITY AND INTELLIGENCE SERVICES (INDIA) LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE285J01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE965H01011",
                "SecurityId": "SITINET",
                "SecurityName": "SITI NETWORKS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE965H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE076B01028",
                "SecurityId": "SIYSIL",
                "SecurityName": "SIYARAM SILK MILLS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE076B01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE002L01015",
                "SecurityId": "SJVN",
                "SecurityName": "SJVN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE002L01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE640A01023",
                "SecurityId": "SKFINDIA",
                "SecurityName": "SKF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE640A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE429F01012",
                "SecurityId": "SKIL",
                "SecurityName": "SKIL INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE429F01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE439E01022",
                "SecurityId": "SKIPPER",
                "SecurityName": "SKIPPER LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE439E01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE411D01015",
                "SecurityId": "SKMEGGPROD",
                "SecurityName": "SKM EGG PRODUCTS EXPORT (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE411D01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE178C01020",
                "SecurityId": "SMARTLINK",
                "SecurityName": "SMARTLINK HOLDINGS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE178C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE294B01019",
                "SecurityId": "SMLISUZU",
                "SecurityName": "SML ISUZU LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE294B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE215G01021",
                "SecurityId": "SMPL",
                "SecurityName": "SPLENDID METAL PRODUCTS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE215G01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE320X01016",
                "SecurityId": "SMSLIFE",
                "SecurityName": "SMS LIFESCIENCES INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE320X01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE812G01025",
                "SecurityId": "SMSPHARMA",
                "SecurityName": "SMS PHARMACEUTICALS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE812G01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE734N01019",
                "SecurityId": "SNOWMAN",
                "SecurityName": "SNOWMAN LOGISTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE734N01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE671H01015",
                "SecurityId": "SOBHA",
                "SecurityName": "SOBHA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE671H01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE624Z01016",
                "SecurityId": "SOLARA",
                "SecurityName": "SOLARA ACTIVE PHARMA SCIENCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE624Z01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE343H01029",
                "SecurityId": "SOLARINDS",
                "SecurityName": "SOLAR INDUSTRIES INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE343H01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE355A01028",
                "SecurityId": "SOMANYCERA",
                "SecurityName": "SOMANY CERAMICS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE355A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE314C01013",
                "SecurityId": "SOMATEX",
                "SecurityName": "SOMA TEXTILES & INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE314C01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE323J01019",
                "SecurityId": "SOMICONVEY",
                "SecurityName": "SOMI CONVEYOR BELTINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE323J01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE269A01021",
                "SecurityId": "SONATSOFTW",
                "SecurityName": "SONATA SOFTWARE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE269A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE034H01016",
                "SecurityId": "SORILINFRA",
                "SecurityName": "SORIL INFRA RESOURCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE034H01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE035D01012",
                "SecurityId": "SOTL",
                "SecurityName": "SAVITA OIL TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE035D01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE683A01023",
                "SecurityId": "SOUTHBANK",
                "SecurityName": "THE SOUTH INDIAN BANK LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE683A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE980Y01015",
                "SecurityId": "SOUTHWEST",
                "SecurityName": "SOUTH WEST PINNACLE EXPLORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE980Y01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE212I01016",
                "SecurityId": "SPAL",
                "SecurityName": "S. P. APPARELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE212I01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE572J01011",
                "SecurityId": "SPANDANA",
                "SecurityName": "SPANDANA SPHOORTY FINANCIAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE572J01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE232I01014",
                "SecurityId": "SPARC",
                "SecurityName": "SUN PHARMA ADVANCED RESEARCH COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE232I01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE970N01027",
                "SecurityId": "SPCENET",
                "SecurityName": "SPACENET ENTERPRISES INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE970N01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE247M01014",
                "SecurityId": "SPECIALITY",
                "SecurityName": "SPECIALITY RESTAURANTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE247M01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE020801028",
                "SecurityId": "SPENCERS",
                "SecurityName": "SPENCER'S RETAIL LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE020801028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE376C01020",
                "SecurityId": "SPENTEX",
                "SecurityName": "SPENTEX INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE376C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE147A01011",
                "SecurityId": "SPIC",
                "SecurityName": "SOUTHERN PETROCHEMICALS INDUSTRIES CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE147A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE285B01017",
                "SecurityId": "SPICEJET",
                "SecurityName": "SPICEJET LTD",
                "FaceValue": "10",
                "ISINNo": "INE285B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE978G01016",
                "SecurityId": "SPLIL",
                "SecurityName": "SPL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE978G01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE937A01023",
                "SecurityId": "SPMLINFRA",
                "SecurityName": "SPML INFRA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE937A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE501W01021",
                "SecurityId": "SPTL",
                "SecurityName": "SINTEX PLASTICS TECHNOLOGY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE501W01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE268L01020",
                "SecurityId": "SPYL",
                "SecurityName": "SHEKHAWATI POLY-YARN LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE268L01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE099F01013",
                "SecurityId": "SREEL",
                "SecurityName": "SREELEATHERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE099F01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE872A01014",
                "SecurityId": "SREINFRA",
                "SecurityName": "SREI INFRASTRUCTURE FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE872A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE647A01010",
                "SecurityId": "SRF",
                "SecurityName": "SRF LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE647A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE917H01012",
                "SecurityId": "SRHHYPOLTD",
                "SecurityName": "SREE RAYALASEEMA HI-STRENGTH HYPO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE917H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE943C01027",
                "SecurityId": "SRIPIPES",
                "SecurityName": "SRIKALAHASTHI PIPES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE943C01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE721A01013",
                "SecurityId": "SRTRANSFIN",
                "SecurityName": "SHRIRAM TRANSPORT FINANCE COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE721A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE802C01017",
                "SecurityId": "SSWL",
                "SecurityName": "STEEL STRIPS WHEELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE802C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE224E01028",
                "SecurityId": "STAMPEDE",
                "SecurityName": "STAMPEDE CAPITAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE224E01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE939A01011",
                "SecurityId": "STAR",
                "SecurityName": "STRIDES PHARMA SCIENCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE939A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE460H01021",
                "SecurityId": "STARCEMENT",
                "SecurityName": "STAR CEMENT LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE460H01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE733A01018",
                "SecurityId": "STARPAPER",
                "SecurityName": "STAR PAPER MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE733A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE655A01013",
                "SecurityId": "STCINDIA",
                "SecurityName": "THE STATE TRADING CORPORATION OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE655A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE395H01011",
                "SecurityId": "STEELCITY",
                "SecurityName": "STEEL CITY SECURITIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE395H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE503B01013",
                "SecurityId": "STEELXIND",
                "SecurityName": "STEEL EXCHANGE INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE503B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE577L01016",
                "SecurityId": "STEL",
                "SecurityName": "STEL HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE577L01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE334A01023",
                "SecurityId": "STERTOOLS",
                "SecurityName": "STERLING TOOLS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE334A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE090C01019",
                "SecurityId": "STINDIA",
                "SecurityName": "STI INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE090C01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE089C01029",
                "SecurityId": "STRTECH",
                "SecurityName": "STERLITE TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE089C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE845C01016",
                "SecurityId": "SUBCAPCITY",
                "SecurityName": "INTERNATIONAL CONSTRUCTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE845C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE754A01014",
                "SecurityId": "SUBEX",
                "SecurityName": "SUBEX LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE754A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE287B01021",
                "SecurityId": "SUBROS",
                "SecurityName": "SUBROS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE287B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE659A01023",
                "SecurityId": "SUDARSCHEM",
                "SecurityName": "SUDARSHAN CHEMICAL INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE659A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE216G01011",
                "SecurityId": "SUJANAUNI",
                "SecurityName": "SUJANA UNIVERSAL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE216G01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE235C01010",
                "SecurityId": "SUMEETINDS",
                "SecurityName": "SUMEET INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE235C01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE258G01013",
                "SecurityId": "SUMICHEM",
                "SecurityName": "SUMITOMO CHEMICAL INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE258G01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE748Z01013",
                "SecurityId": "SUMIT",
                "SecurityName": "SUMIT WOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE748Z01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE519C01017",
                "SecurityId": "SUMMITSEC",
                "SecurityName": "SUMMIT SECURITIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE519C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE105A01035",
                "SecurityId": "SUNCLAYLTD",
                "SecurityName": "SUNDARAM CLAYTON LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE105A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE108E01023",
                "SecurityId": "SUNDARAM",
                "SecurityName": "SUNDARAM MULTI PAP LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE108E01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE660A01013",
                "SecurityId": "SUNDARMFIN",
                "SecurityName": "SUNDARAM FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE660A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE202Z01029",
                "SecurityId": "SUNDARMHLD",
                "SecurityName": "SUNDARAM FINANCE HOLDINGS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE202Z01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE073D01013",
                "SecurityId": "SUNDRMBRAK",
                "SecurityName": "SUNDARAM BRAKE LININGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE073D01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE387A01021",
                "SecurityId": "SUNDRMFAST",
                "SecurityName": "SUNDRAM FASTENERS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE387A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE947A01014",
                "SecurityId": "SUNFLAG",
                "SecurityName": "SUNFLAG IRON AND STEEL COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE947A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE044A01036",
                "SecurityId": "SUNPHARMA",
                "SecurityName": "SUN PHARMACEUTICAL INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE044A01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE805D01034",
                "SecurityId": "SUNTECK",
                "SecurityName": "SUNTECK REALTY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE805D01034",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE424H01027",
                "SecurityId": "SUNTV",
                "SecurityName": "SUN TV NETWORK LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE424H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE712B01010",
                "SecurityId": "SUPERHOUSE",
                "SecurityName": "SUPERHOUSE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE712B01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE662A01027",
                "SecurityId": "SUPERSPIN",
                "SecurityName": "SUPER SPINNING MILLS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE662A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE663A01017",
                "SecurityId": "SUPPETRO",
                "SecurityName": "SUPREME PETROCHEM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE663A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE399C01030",
                "SecurityId": "SUPRAJIT",
                "SecurityName": "SUPRAJIT ENGINEERING LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE399C01030",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE195A01028",
                "SecurityId": "SUPREMEIND",
                "SecurityName": "SUPREME INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE195A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE550H01011",
                "SecurityId": "SUPREMEINF",
                "SecurityName": "SUPREME INFRASTRUCTURE INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE550H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE272L01022",
                "SecurityId": "SURANASOL",
                "SecurityName": "SURANA SOLAR LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE272L01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE130B01031",
                "SecurityId": "SURANAT&P",
                "SecurityName": "SURANA TELECOM AND POWER LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE130B01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE713B01026",
                "SecurityId": "SURYALAXMI",
                "SecurityName": "SURYALAKSHMI COTTON MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE713B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE335A01012",
                "SecurityId": "SURYAROSNI",
                "SecurityName": "SURYA ROSHNI LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE335A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE645H01027",
                "SecurityId": "SUTLEJTEX",
                "SecurityName": "SUTLEJ TEXTILES AND INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE645H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE495B01038",
                "SecurityId": "SUVEN",
                "SecurityName": "SUVEN LIFE SCIENCES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE495B01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE03QK01018",
                "SecurityId": "SUVENPHAR",
                "SecurityName": "SUVEN PHARMACEUTICALS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE03QK01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE040H01021",
                "SecurityId": "SUZLON",
                "SecurityName": "SUZLON ENERGY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE040H01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE665A01038",
                "SecurityId": "SWANENERGY",
                "SecurityName": "SWAN ENERGY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE665A01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE277A01016",
                "SecurityId": "SWARAJENG",
                "SecurityName": "SWARAJ ENGINES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE277A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE409B01013",
                "SecurityId": "SWELECTES",
                "SecurityName": "SWELECT ENERGY SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE409B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE00M201021",
                "SecurityId": "SWSOLAR",
                "SecurityName": "STERLING AND WILSON SOLAR LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE00M201021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE225D01027",
                "SecurityId": "SYMPHONY",
                "SecurityName": "SYMPHONY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE225D01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE602K01014",
                "SecurityId": "SYNCOM",
                "SecurityName": "SYNCOM HEALTHCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE602K01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE667A01018",
                "SecurityId": "SYNDIBANK",
                "SecurityName": "SYNDICATE BANK",
                "FaceValue": "10",
                "ISINNo": "INE667A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE398R01022",
                "SecurityId": "SYNGENE",
                "SecurityName": "SYNGENE INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE398R01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE123C01018",
                "SecurityId": "TAINWALCHM",
                "SecurityName": "TAINWALA CHEMICAL AND PLASTIC (I) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE123C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE586B01026",
                "SecurityId": "TAJGVK",
                "SecurityName": "TAJ GVK HOTELS & RESORTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE586B01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE142I01023",
                "SecurityId": "TAKE",
                "SecurityName": "TAKE SOLUTIONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE142I01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE187D01011",
                "SecurityId": "TALBROAUTO",
                "SecurityName": "TALBROS AUTOMOTIVE COMPONENTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE187D01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE502K01016",
                "SecurityId": "TALWALKARS",
                "SecurityName": "TALWALKARS BETTER VALUE FITNESS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE502K01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE627Z01019",
                "SecurityId": "TALWGYM",
                "SecurityName": "TALWALKARS HEALTHCLUBS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE627Z01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE483C01032",
                "SecurityId": "TANLA",
                "SecurityName": "TANLA SOLUTIONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE483C01032",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE924H01018",
                "SecurityId": "TARMAT",
                "SecurityName": "TARMAT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE924H01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE488B01017",
                "SecurityId": "TASTYBITE",
                "SecurityName": "TASTY BITE EATABLES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE488B01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE092A01019",
                "SecurityId": "TATACHEM",
                "SecurityName": "TATA CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE092A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE493A01027",
                "SecurityId": "TATACOFFEE",
                "SecurityName": "TATA COFFEE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE493A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE151A01013",
                "SecurityId": "TATACOMM",
                "SecurityName": "TATA COMMUNICATIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE151A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE192A01025",
                "SecurityId": "TATACONSUM",
                "SecurityName": "TATA CONSUMER PRODUCTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE192A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE670A01012",
                "SecurityId": "TATAELXSI",
                "SecurityName": "TATA ELXSI LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE670A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE672A01018",
                "SecurityId": "TATAINVEST",
                "SecurityName": "TATA INVESTMENT CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE672A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE056C01010",
                "SecurityId": "TATAMETALI",
                "SecurityName": "TATA METALIKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE056C01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE155A01022",
                "SecurityId": "TATAMOTORS",
                "SecurityName": "TATA MOTORS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE155A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "IN9155A01020",
                "SecurityId": "TATAMTRDVR",
                "SecurityName": "TATA MOTORS LIMITED",
                "FaceValue": "2",
                "ISINNo": "IN9155A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE245A01021",
                "SecurityId": "TATAPOWER",
                "SecurityName": "TATA POWER COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE245A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE081A01012",
                "SecurityId": "TATASTEEL",
                "SecurityName": "TATA STEEL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE081A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE824B01021",
                "SecurityId": "TATASTLBSL",
                "SecurityName": "TATA STEEL BSL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE824B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE674A01014",
                "SecurityId": "TATASTLLP",
                "SecurityName": "TATA STEEL LONG PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE674A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE760L01018",
                "SecurityId": "TBZ",
                "SecurityName": "TRIBHOVANDAS BHIMJI ZAVERI LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE760L01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE688A01022",
                "SecurityId": "TCI",
                "SecurityName": "TRANSPORT CORPORATION OF INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE688A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE662L01016",
                "SecurityId": "TCIDEVELOP",
                "SecurityName": "TCI DEVELOPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE662L01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE586V01016",
                "SecurityId": "TCIEXP",
                "SecurityName": "TCI EXPRESS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE586V01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE911B01018",
                "SecurityId": "TCIFINANCE",
                "SecurityName": "TCI FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE911B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE778U01029",
                "SecurityId": "TCNSBRANDS",
                "SecurityName": "TCNS CLOTHING CO. LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE778U01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE822C01015",
                "SecurityId": "TCPLPACK",
                "SecurityName": "TCPL PACKAGING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE822C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE467B01029",
                "SecurityId": "TCS",
                "SecurityName": "TATA CONSULTANCY SERVICES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE467B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE419M01019",
                "SecurityId": "TDPOWERSYS",
                "SecurityName": "TD POWER SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE419M01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE985S01024",
                "SecurityId": "TEAMLEASE",
                "SecurityName": "TEAMLEASE SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE985S01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE778A01021",
                "SecurityId": "TECHIN",
                "SecurityName": "TECHINDIA NIRMAN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE778A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE669C01036",
                "SecurityId": "TECHM",
                "SecurityName": "TECH MAHINDRA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE669C01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE285K01026",
                "SecurityId": "TECHNOE",
                "SecurityName": "TECHNO ELECTRIC & ENGINEERING COMPANY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE285K01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE509K01011",
                "SecurityId": "TECHNOFAB",
                "SecurityName": "TECHNOFAB ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE509K01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE010J01012",
                "SecurityId": "TEJASNET",
                "SecurityName": "TEJAS NETWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE010J01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE482B01010",
                "SecurityId": "TERASOFT",
                "SecurityName": "TERA SOFTWARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE482B01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE435C01024",
                "SecurityId": "TEXINFRA",
                "SecurityName": "TEXMACO INFRASTRUCTURE & HOLDINGS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE435C01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE141K01013",
                "SecurityId": "TEXMOPIPES",
                "SecurityName": "TEXMO PIPES AND PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE141K01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE621L01012",
                "SecurityId": "TEXRAIL",
                "SecurityName": "TEXMACO RAIL & ENGINEERING LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE621L01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE305A01015",
                "SecurityId": "TFCILTD",
                "SecurityName": "TOURISM FINANCE CORPORATION OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE305A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE804H01012",
                "SecurityId": "TFL",
                "SecurityName": "TRANSWARRANTY FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE804H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE797H01018",
                "SecurityId": "TGBHOTELS",
                "SecurityName": "TGB BANQUETS AND HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE797H01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE085J01014",
                "SecurityId": "THANGAMAYL",
                "SecurityName": "THANGAMAYIL JEWELLERY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE085J01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE924D01017",
                "SecurityId": "THEINVEST",
                "SecurityName": "THE INVESTMENT TRUST OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE924D01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE083B01016",
                "SecurityId": "THEMISMED",
                "SecurityName": "THEMIS MEDICARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE083B01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE152A01029",
                "SecurityId": "THERMAX",
                "SecurityName": "THERMAX LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE152A01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE409A01015",
                "SecurityId": "THIRUSUGAR",
                "SecurityName": "THIRU AROORAN SUGARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE409A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE332A01027",
                "SecurityId": "THOMASCOOK",
                "SecurityName": "THOMAS COOK (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE332A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE480M01011",
                "SecurityId": "THOMASCOTT",
                "SecurityName": "THOMAS SCOTT (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE480M01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE594H01019",
                "SecurityId": "THYROCARE",
                "SecurityName": "THYROCARE TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE594H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE133E01013",
                "SecurityId": "TI",
                "SecurityName": "TILAKNAGAR INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE133E01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE484C01022",
                "SecurityId": "TIDEWATER",
                "SecurityName": "TIDE WATER OIL COMPANY (INDIA) LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE484C01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE545H01011",
                "SecurityId": "TIIL",
                "SecurityName": "TECHNOCRAFT INDUSTRIES (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE545H01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE974X01010",
                "SecurityId": "TIINDIA",
                "SecurityName": "TUBE INVESTMENTS OF INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE974X01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE440L01017",
                "SecurityId": "TIJARIA",
                "SecurityName": "TIJARIA POLYPIPES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE440L01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE806C01018",
                "SecurityId": "TIL",
                "SecurityName": "TIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE806C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE289C01025",
                "SecurityId": "TIMESGTY",
                "SecurityName": "TIMES GUARANTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE289C01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE508G01029",
                "SecurityId": "TIMETECHNO",
                "SecurityName": "TIME TECHNOPLAST LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE508G01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE325A01013",
                "SecurityId": "TIMKEN",
                "SecurityName": "TIMKEN INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE325A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE422C01014",
                "SecurityId": "TINPLATE",
                "SecurityName": "THE TINPLATE COMPANY OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE422C01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE716B01011",
                "SecurityId": "TIPSINDLTD",
                "SecurityName": "TIPS INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE716B01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE338A01024",
                "SecurityId": "TIRUMALCHM",
                "SecurityName": "THIRUMALAI CHEMICALS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE338A01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE280A01028",
                "SecurityId": "TITAN",
                "SecurityName": "TITAN COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE280A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE759V01019",
                "SecurityId": "TMRVL",
                "SecurityName": "THE MANDHANA RETAIL VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE759V01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE148A01019",
                "SecurityId": "TNPETRO",
                "SecurityName": "TAMILNADU PETROPRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE148A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE107A01015",
                "SecurityId": "TNPL",
                "SecurityName": "TAMIL NADU NEWSPRINT & PAPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE107A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE141D01018",
                "SecurityId": "TNTELE",
                "SecurityName": "TAMILNADU TELECOMMUNICATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE141D01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE932C01012",
                "SecurityId": "TOKYOPLAST",
                "SecurityName": "TOKYO PLAST INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE932C01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE685A01028",
                "SecurityId": "TORNTPHARM",
                "SecurityName": "TORRENT PHARMACEUTICALS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE685A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE813H01021",
                "SecurityId": "TORNTPOWER",
                "SecurityName": "TORRENT POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE813H01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE486Y01013",
                "SecurityId": "TOUCHWOOD",
                "SecurityName": "TOUCHWOOD ENTERTAINMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE486Y01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE413G01014",
                "SecurityId": "TPLPLASTEH",
                "SecurityName": "TPL PLASTECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE413G01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE040M01013",
                "SecurityId": "TREEHOUSE",
                "SecurityName": "TREE HOUSE EDUCATION & ACCESSORIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE040M01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE00CA01015",
                "SecurityId": "TREJHARA",
                "SecurityName": "TREJHARA SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE00CA01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE849A01020",
                "SecurityId": "TRENT",
                "SecurityName": "TRENT LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE849A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE391D01019",
                "SecurityId": "TRF",
                "SecurityName": "TRF LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE391D01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE064C01022",
                "SecurityId": "TRIDENT",
                "SecurityName": "TRIDENT LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE064C01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE948A01012",
                "SecurityId": "TRIGYN",
                "SecurityName": "TRIGYN TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE948A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE763I01026",
                "SecurityId": "TRIL",
                "SecurityName": "TRANSFORMERS AND RECTIFIERS (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE763I01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE152M01016",
                "SecurityId": "TRITURBINE",
                "SecurityName": "TRIVENI TURBINE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE152M01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE256C01024",
                "SecurityId": "TRIVENI",
                "SecurityName": "TRIVENI ENGINEERING & INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE256C01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE910C01018",
                "SecurityId": "TTKHLTCARE",
                "SecurityName": "TTK HEALTHCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE910C01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE690A01010",
                "SecurityId": "TTKPRESTIG",
                "SecurityName": "TTK PRESTIGE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE690A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE592B01016",
                "SecurityId": "TTL",
                "SecurityName": "T T LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE592B01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE517B01013",
                "SecurityId": "TTML",
                "SecurityName": "TATA TELESERVICES (MAHARASHTRA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE517B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE886H01027",
                "SecurityId": "TV18BRDCST",
                "SecurityName": "TV18 BROADCAST LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE886H01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE236G01019",
                "SecurityId": "TVSELECT",
                "SecurityName": "TVS ELECTRONICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE236G01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE494B01023",
                "SecurityId": "TVSMOTOR",
                "SecurityName": "TVS MOTOR COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE494B01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE421C01016",
                "SecurityId": "TVSSRICHAK",
                "SecurityName": "TVS SRICHAKRA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE421C01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE038F01029",
                "SecurityId": "TVTODAY",
                "SecurityName": "TV TODAY NETWORK LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE038F01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE871L01013",
                "SecurityId": "TVVISION",
                "SecurityName": "TV VISION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE871L01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE615H01020",
                "SecurityId": "TWL",
                "SecurityName": "TITAGARH WAGONS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE615H01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE686F01025",
                "SecurityId": "UBL",
                "SecurityName": "UNITED BREWERIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE686F01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE139B01016",
                "SecurityId": "UCALFUEL",
                "SecurityName": "UCAL FUEL SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE139B01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE691A01018",
                "SecurityId": "UCOBANK",
                "SecurityName": "UCO BANK",
                "FaceValue": "10",
                "ISINNo": "INE691A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE516A01017",
                "SecurityId": "UFLEX",
                "SecurityName": "UFLEX LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE516A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE527H01019",
                "SecurityId": "UFO",
                "SecurityName": "UFO MOVIEZ INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE527H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE071E01023",
                "SecurityId": "UGARSUGAR",
                "SecurityName": "THE UGAR SUGAR WORKS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE071E01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE899L01022",
                "SecurityId": "UJAAS",
                "SecurityName": "UJAAS ENERGY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE899L01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE334L01012",
                "SecurityId": "UJJIVAN",
                "SecurityName": "UJJIVAN FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE334L01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE551W01018",
                "SecurityId": "UJJIVANSFB",
                "SecurityName": "UJJIVAN SMALL FINANCE BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE551W01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE481G01011",
                "SecurityId": "ULTRACEMCO",
                "SecurityName": "ULTRATECH CEMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE481G01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE864B01027",
                "SecurityId": "UMANGDAIRY",
                "SecurityName": "UMANG DAIRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE864B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE240C01028",
                "SecurityId": "UMESLTD",
                "SecurityName": "USHA MARTIN EDUCATION & SOLUTIONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE240C01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE351A01035",
                "SecurityId": "UNICHEMLAB",
                "SecurityName": "UNICHEM LABORATORIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE351A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE037A01022",
                "SecurityId": "UNIENTER",
                "SecurityName": "UNIPHOS ENTERPRISES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE037A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE692A01016",
                "SecurityId": "UNIONBANK",
                "SecurityName": "UNION BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE692A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE950G01023",
                "SecurityId": "UNIPLY",
                "SecurityName": "UNIPLY INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE950G01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE694A01020",
                "SecurityId": "UNITECH",
                "SecurityName": "UNITECH LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE694A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE695A01019",
                "SecurityId": "UNITEDBNK",
                "SecurityName": "UNITED BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE695A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE458F01011",
                "SecurityId": "UNITEDTEA",
                "SecurityName": "THE UNITED NILGIRI TEA ESTATES COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE458F01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE466H01028",
                "SecurityId": "UNITY",
                "SecurityName": "UNITY INFRAPROJECTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE466H01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE279A01012",
                "SecurityId": "UNIVCABLES",
                "SecurityName": "UNIVERSAL CABLES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE279A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE03V001013",
                "SecurityId": "UNIVPHOTO",
                "SecurityName": "UNIVERSUS PHOTO IMAGINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE03V001013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE628A01036",
                "SecurityId": "UPL",
                "SecurityName": "UPL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE628A01036",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE550C01020",
                "SecurityId": "URJA",
                "SecurityName": "URJA GLOBAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE550C01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE228A01035",
                "SecurityId": "USHAMART",
                "SecurityName": "USHA MARTIN LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE228A01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE699A01011",
                "SecurityId": "UTTAMSTL",
                "SecurityName": "UTTAM GALVA STEELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE699A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE786F01031",
                "SecurityId": "UTTAMSUGAR",
                "SecurityName": "UTTAM SUGAR MILLS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE786F01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE292A01023",
                "SecurityId": "UVSL",
                "SecurityName": "UTTAM VALUE STEELS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE292A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE945H01013",
                "SecurityId": "V2RETAIL",
                "SecurityName": "V2 RETAIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE945H01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE694D01016",
                "SecurityId": "VADILALIND",
                "SecurityName": "VADILAL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE694D01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE884A01019",
                "SecurityId": "VAIBHAVGBL",
                "SecurityName": "VAIBHAV GLOBAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE884A01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE972X01014",
                "SecurityId": "VAISHALI",
                "SecurityName": "VAISHALI PHARMA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE972X01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE051B01021",
                "SecurityId": "VAKRANGEE",
                "SecurityName": "VAKRANGEE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE051B01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE116G01013",
                "SecurityId": "VARDHACRLC",
                "SecurityName": "VARDHMAN ACRYLICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE116G01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE835A01011",
                "SecurityId": "VARDMNPOLY",
                "SecurityName": "VARDHMAN POLYTEX LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE835A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE665L01035",
                "SecurityId": "VARROC",
                "SecurityName": "VARROC ENGINEERING LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE665L01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE893I01013",
                "SecurityId": "VASCONEQ",
                "SecurityName": "VASCON ENGINEERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE893I01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE590L01019",
                "SecurityId": "VASWANI",
                "SecurityName": "VASWANI INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE590L01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE200M01013",
                "SecurityId": "VBL",
                "SecurityName": "VARUN BEVERAGES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE200M01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE205A01025",
                "SecurityId": "VEDL",
                "SecurityName": "VEDANTA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE205A01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE398A01010",
                "SecurityId": "VENKEYS",
                "SecurityName": "VENKY'S (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE398A01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE411B01019",
                "SecurityId": "VENUSREM",
                "SecurityName": "VENUS REMEDIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE411B01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE386A01015",
                "SecurityId": "VESUVIUS",
                "SecurityName": "VESUVIUS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE386A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE918N01018",
                "SecurityId": "VETO",
                "SecurityName": "VETO SWITCHGEARS AND CABLES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE918N01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE951I01027",
                "SecurityId": "VGUARD",
                "SecurityName": "V-GUARD INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE951I01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE701A01023",
                "SecurityId": "VHL",
                "SecurityName": "VARDHMAN HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE701A01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE048C01017",
                "SecurityId": "VICEROY",
                "SecurityName": "VICEROY HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE048C01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE703A01011",
                "SecurityId": "VIDEOIND",
                "SecurityName": "VIDEOCON INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE703A01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE632C01026",
                "SecurityId": "VIDHIING",
                "SecurityName": "VIDHI SPECIALTY FOOD INGREDIENTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE632C01026",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE159N01027",
                "SecurityId": "VIJIFIN",
                "SecurityName": "VIJI FINANCE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE159N01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE806A01020",
                "SecurityId": "VIKASECO",
                "SecurityName": "VIKAS ECOTECH LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE806A01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE161L01027",
                "SecurityId": "VIKASMCORP",
                "SecurityName": "VIKAS MULTICORP LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE161L01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE767B01022",
                "SecurityId": "VIKASPROP",
                "SecurityName": "VIKAS PROPPANT & GRANITE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE767B01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE706A01022",
                "SecurityId": "VIKASWSP",
                "SecurityName": "VIKAS WSP LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE706A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE579C01029",
                "SecurityId": "VIMTALABS",
                "SecurityName": "VIMTA LABS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE579C01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE410B01037",
                "SecurityId": "VINATIORGA",
                "SecurityName": "VINATI ORGANICS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE410B01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE707A01012",
                "SecurityId": "VINDHYATEL",
                "SecurityName": "VINDHYA TELELINKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE707A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE250B01029",
                "SecurityId": "VINYLINDIA",
                "SecurityName": "VINYL CHEMICALS (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE250B01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE450G01024",
                "SecurityId": "VIPCLOTHNG",
                "SecurityName": "VIP CLOTHING LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE450G01024",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE054A01027",
                "SecurityId": "VIPIND",
                "SecurityName": "VIP INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE054A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE946H01037",
                "SecurityId": "VIPULLTD",
                "SecurityName": "VIPUL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE946H01037",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE392A01013",
                "SecurityId": "VISAKAIND",
                "SecurityName": "VISAKA INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE392A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE286H01012",
                "SecurityId": "VISASTEEL",
                "SecurityName": "VISA STEEL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE286H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE755Q01025",
                "SecurityId": "VISHAL",
                "SecurityName": "VISHAL FABRICS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE755Q01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE270I01014",
                "SecurityId": "VISHNU",
                "SecurityName": "VISHNU CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE270I01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE430N01014",
                "SecurityId": "VISHWARAJ",
                "SecurityName": "VISHWARAJ SUGAR INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE430N01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE370E01029",
                "SecurityId": "VIVIDHA",
                "SecurityName": "VISAGAR POLYTEX LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE370E01029",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE526G01021",
                "SecurityId": "VIVIMEDLAB",
                "SecurityName": "VIVIMED LABS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE526G01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE709A01018",
                "SecurityId": "VLSFINANCE",
                "SecurityName": "VLS FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE709A01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE665J01013",
                "SecurityId": "VMART",
                "SecurityName": "V-MART RETAIL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE665J01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE540H01012",
                "SecurityId": "VOLTAMP",
                "SecurityName": "VOLTAMP TRANSFORMERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE540H01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE226A01021",
                "SecurityId": "VOLTAS",
                "SecurityName": "VOLTAS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE226A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE366I01010",
                "SecurityId": "VRLLOG",
                "SecurityName": "VRL LOGISTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE366I01010",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE050M01012",
                "SecurityId": "VSSL",
                "SecurityName": "VARDHMAN SPECIAL STEELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE050M01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE710A01016",
                "SecurityId": "VSTIND",
                "SecurityName": "VST INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE710A01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE764D01017",
                "SecurityId": "VSTTILLERS",
                "SecurityName": "V.S.T TILLERS TRACTORS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE764D01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE825A01012",
                "SecurityId": "VTL",
                "SecurityName": "VARDHMAN TEXTILES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE825A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE956G01038",
                "SecurityId": "WABAG",
                "SecurityName": "VA TECH WABAG LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE956G01038",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE342J01019",
                "SecurityId": "WABCOINDIA",
                "SecurityName": "WABCO INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE342J01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE711A01022",
                "SecurityId": "WALCHANNAG",
                "SecurityName": "WALCHANDNAGAR INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE711A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE107F01022",
                "SecurityId": "WANBURY",
                "SecurityName": "WANBURY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE107F01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE054C01015",
                "SecurityId": "WATERBASE",
                "SecurityName": "WATERBASE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE054C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE855C01015",
                "SecurityId": "WEBELSOLAR",
                "SecurityName": "WEBSOL ENERGY SYSTEM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE855C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE726L01019",
                "SecurityId": "WEIZFOREX",
                "SecurityName": "WEIZMANN FOREX LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE726L01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE080A01014",
                "SecurityId": "WEIZMANIND",
                "SecurityName": "WEIZMANN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE080A01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE191B01025",
                "SecurityId": "WELCORP",
                "SecurityName": "WELSPUN CORP LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE191B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE625G01013",
                "SecurityId": "WELENT",
                "SecurityName": "WELSPUN ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE625G01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE389K01018",
                "SecurityId": "WELINV",
                "SecurityName": "WELSPUN INVESTMENTS AND COMMERCIALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE389K01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE192B01031",
                "SecurityId": "WELSPUNIND",
                "SecurityName": "WELSPUN INDIA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE192B01031",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE274C01019",
                "SecurityId": "WENDT",
                "SecurityName": "WENDT (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE274C01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE274F01020",
                "SecurityId": "WESTLIFE",
                "SecurityName": "WESTLIFE DEVELOPMENT LTD",
                "FaceValue": "2",
                "ISINNo": "INE274F01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE715A01015",
                "SecurityId": "WHEELS",
                "SecurityName": "WHEELS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE715A01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE716A01013",
                "SecurityId": "WHIRLPOOL",
                "SecurityName": "WHIRLPOOL OF INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE716A01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE210A01017",
                "SecurityId": "WILLAMAGOR",
                "SecurityName": "WILLIAMSON MAGOR & COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE210A01017",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE052A01021",
                "SecurityId": "WINDMACHIN",
                "SecurityName": "WINDSOR MACHINES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE052A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE215F01023",
                "SecurityId": "WIPL",
                "SecurityName": "THE WESTERN INDIA PLYWOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE215F01023",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE075A01022",
                "SecurityId": "WIPRO",
                "SecurityName": "WIPRO LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE075A01022",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE049B01025",
                "SecurityId": "WOCKPHARMA",
                "SecurityName": "WOCKHARDT LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE049B01025",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE066O01014",
                "SecurityId": "WONDERLA",
                "SecurityName": "WONDERLA HOLIDAYS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE066O01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE100D01014",
                "SecurityId": "WSI",
                "SecurityName": "W S INDUSTRIES (I) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE100D01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE976A01021",
                "SecurityId": "WSTCSTPAPR",
                "SecurityName": "WEST COAST PAPER MILLS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE976A01021",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE692G01013",
                "SecurityId": "XCHANGING",
                "SecurityName": "XCHANGING SOLUTIONS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE692G01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE01P501012",
                "SecurityId": "XELPMOC",
                "SecurityName": "XELPMOC DESIGN AND TECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE01P501012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE445C01015",
                "SecurityId": "XPROINDIA",
                "SecurityName": "XPRO INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE445C01015",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE528G01035",
                "SecurityId": "YESBANK",
                "SecurityName": "YES BANK LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE528G01035",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE256A01028",
                "SecurityId": "ZEEL",
                "SecurityName": "ZEE ENTERTAINMENT ENTERPRISES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE256A01028",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE565L01011",
                "SecurityId": "ZEELEARN",
                "SecurityName": "ZEE LEARN LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE565L01011",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE966H01019",
                "SecurityId": "ZEEMEDIA",
                "SecurityName": "ZEE MEDIA CORPORATION LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE966H01019",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE318D01020",
                "SecurityId": "ZENITHBIR",
                "SecurityName": "ZENITH BIRLA (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE318D01020",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE058B01018",
                "SecurityId": "ZENITHEXPO",
                "SecurityName": "ZENITH EXPORTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE058B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE520A01027",
                "SecurityId": "ZENSARTECH",
                "SecurityName": "ZENSAR TECHNOLOGIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE520A01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE251B01027",
                "SecurityId": "ZENTEC",
                "SecurityName": "ZEN TECHNOLOGIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE251B01027",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE871B01014",
                "SecurityId": "ZICOM",
                "SecurityName": "ZICOM ELECTRONIC SECURITY SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE871B01014",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE206B01013",
                "SecurityId": "ZODIACLOTH",
                "SecurityName": "ZODIAC CLOTHING COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE206B01013",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE077B01018",
                "SecurityId": "ZODJRDMKJ",
                "SecurityName": "ZODIAC JRD- MKJ LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE077B01018",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE358U01012",
                "SecurityId": "ZOTA",
                "SecurityName": "ZOTA HEALTH CARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE358U01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE840M01016",
                "SecurityId": "ZUARI",
                "SecurityName": "ZUARI AGRO CHEMICALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE840M01016",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE217A01012",
                "SecurityId": "ZUARIGLOB",
                "SecurityName": "ZUARI GLOBAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE217A01012",
                "Industry": "NA",
                "Instrument": "Equity"
            },
            {
                "Exchange": "NSE",
                "SecurityCode": "INE768C01010",
                "SecurityId": "ZYDUSWELL",
                "SecurityName": "ZYDUS WELLNESS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE768C01010",
                "Industry": "NA",
                "Instrument": "Equity"
            }

            
                  ];
