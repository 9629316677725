import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from "@angular/fire/storage";
import { map, finalize } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthenticationserviceService } from '../authenticationservice.service'
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-uploadphoto',
  templateUrl: './uploadphoto.component.html',
  styleUrls: ['./uploadphoto.component.css']
})
export class UploadphotoComponent implements OnInit {

  downloadURL: Observable<string>;
  constructor(private storage: AngularFireStorage, public authservice: AuthenticationserviceService
    , private router: Router) {
    this.downloadURL = this.authservice.getimageURl();

  }

  ngOnInit(): void {
  }
  fb;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileChangeEvent(event: any): void {
    //https://stackblitz.com/edit/image-cropper?file=app%2Fimage-cropper%2Futils%2Fblob.utils.ts
    //https://developer.aliyun.com/mirror/npm/package/angular-image-cropper
    //https://github.com/Mawi137/ngx-image-cropper
    //above used after reaseaching a lot .it has crop and compress and with size also drag and drop feature

    //never tried this
    //https://www.digitalocean.com/community/tutorials/angular-resizing-images-in-browser-ng2-img-max
    //https://github.com/digitalascetic/ngx-pica
    //https://github.com/FBerthelot/angular-images-resizer#readme
    //https://stackblitz.com/edit/resizing-cropping-image?file=src%2Fapp%2Fapp.component.ts
    //above 2 not working in ios as per docs
    this.imageChangedEvent = event;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const filetoupload = this.base64ToFile(event.base64);
    this.SENDTOFIREBASESTORAGE(filetoupload);

  }
  private SENDTOFIREBASESTORAGE(filetoupload: Blob) {
    //https://dev.to/fayvik/uploading-an-image-to-firebase-cloud-storage-with-angular-2aeh

    //for delete/update in firebase
    //https://grokonez.com/frontend/angular/angular-6-firebase-tutorial-upload-display-delete-files-from-firebase-storage

    var n = this.authservice.authuser.uid;
    const file = filetoupload;//this.base64ToFile(event.base64);
    const filePath = `desifintwit/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`desifintwit/${n}`, file);
    // console.log("uploading");
    // console.log(fileRef);
    // console.log(fileRef.getDownloadURL());
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              //this.fb = url;
              this.router.navigate(['/editprofile']);//, { relativeTo: this.route });
            }
            //console.log(this.fb);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          //console.log(url);
        }
      });


  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
}
