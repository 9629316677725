import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { WatchlistInterface } from '../WatchlistInterface';
import { AuthenticationserviceService } from '../authenticationservice.service';
import { BSE_Equity } from '../BSE_Equity';
import { NSE_Equity } from '../NSE_Equity';
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WatchDtoModel } from './watchlistDto';
import { DataService } from '../../shared/data.service';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { SharedserviceService } from '../sharedservice.service';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let StockModule = require('highcharts/modules/stock');

StockModule(Highcharts); // <-- Have to be first
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  selector: 'app-watchlist',
  templateUrl: './watchlist.component.html',
  styleUrls: ['./watchlist.component.css']
})
export class WatchlistComponent implements OnInit {

  faTrashAlt = faTrashAlt;
  faPlusCircle = faPlusCircle;
  watchlist: Observable<any[]>;
  //stocknames:any[];
  
  changecharacter;
  items;
  currentDocumentID;
  public focusElement: number = -1;
  SearchType: string = "WatchListSearch";
  //stocklist:WatchlistInterface;

  prices: any = [];
  chartOption: any = [];
  stockData: Array<WatchDtoModel> = new Array<WatchDtoModel>();
  ChartData: any = [];
  demochart: any = [];
  chartsData: any = [];
  demoChartsData: any = [];
isFirstTimeCode: boolean = false;

  stocknames: string[] = [];
  //stocknames: any = [];
  // [
  //   'AMZN','NFLX','GOOG','PLTR','TWTR','FB','MSFT','DIS','NVDA','GE','V','CRM'
    
  // ];
  

  constructor(public firestore: AngularFirestore
    , private toastr: ToastrService
    ,private datalist: DataService
    , public sharedserviceService: SharedserviceService

    , public authservice: AuthenticationserviceService, private router: Router) {
    //this.watchlist = firestore.collection<Stock>('/watchlist').valueChanges();




  }
  getStickerFromWatchListName(name)
  {
    return   name.substring(0, name.lastIndexOf(' ('))+".BO";
    
  }
  successResultCount: number = -1;
  getEachStockData(stockname)
  {
    var today = new Date();
    var duration = today.setMonth(today.getMonth() - 2);
    this.datalist
    .getChartData(stockname, duration)
    
    .subscribe((data) => {
      this.ChartData = data;
      if (
        this.ChartData.chart.result[0] &&
        this.ChartData.chart.result[0].timestamp
      ) {
        this.prices = this.ChartData.chart.result[0].indicators.quote[0].open;
        let tempData: any = [];
        for (
          let i = 0;
          i < this.ChartData.chart.result[0].timestamp.length;
          i++
        ) {
          tempData.push([
            this.ChartData.chart.result[0].timestamp[i], // the date
            this.ChartData.chart.result[0].indicators.quote[0].volume[i], // the volume
          ]);
        }
        if (this.ChartData) {
          this.successResultCount++;
          this.chartsData.push(this.ChartData);
          this.demoChartsData.push(tempData);
          //console.log(this.successResultCount);
          //console.log(this.chartsData);
          let getSecurityId=this.sharedserviceService.getSecurityIdFromYFCompanyCode(this.ChartData.chart.result[0].meta.symbol)
          this.stockData.push(
            new WatchDtoModel({
            
              name: this.sharedserviceService.getSecurityIdFromYFCompanyCode(this.ChartData.chart.result[0].meta.symbol),
    YFCode:this.ChartData.chart.result[0].meta.symbol,
              price: this.ChartData.chart.result[0].meta.regularMarketPrice,
              value:
              this.ChartData.chart.result[0].meta.chartPreviousClose -
              this.ChartData.chart.result[0].meta.regularMarketPrice,
              diffValue:
                (this.ChartData.chart.result[0].meta.chartPreviousClose * 100) /
                this.ChartData.chart.result[0].meta.regularMarketPrice /
                  100 -
                  this.ChartData.chart.result[0].meta.chartPreviousClose / 100,
              companyName: this.sharedserviceService.getCompanyNamefromCompanyCode(getSecurityId)?.SecurityName,
            })
          );

          this.getstockData(this.chartsData.length-1);
        }
        
      }
    });
  }
  getWatchList(): void {
    
    
    for (let j = 0; j < this.stocknames.length; j++) {
      this.getEachStockData(this.stocknames[j])
    }

  }
//   ; trackBy:trackByEmpCode
//   trackByEmpCode(index: number, stockData: any): string {
//     return stockData.name;
// }
  ngOnInit(): void {

    this.firestore.collection<WatchlistInterface>('/watchlist', ref => ref.where("emailid", "==", this.authservice.authuser.email).limit(1))
      .valueChanges({ idField: 'documentid' })
      .subscribe(register => {
        this.stocknames = register[0].name;
        this.currentDocumentID = register[0].documentid
        this.stockData=[];
        this.chartsData=[];
        this.demoChartsData=[];
        this.getWatchList();
//console.log("test");

// if(this.isFirstTimeCode==false)
// {
//   this.getWatchList();
//   this.isFirstTimeCode=true;
// }
      });


    
  }
  getSecurityIdFromYFCompanyCode(code)
  {
    //console.log(code);
let test= this.sharedserviceService.getSecurityIdFromYFCompanyCode(code);
//console.log(test);
return test;
  }

  navigateToSymbol(m1) {


   
    //let stocknavigateToSymbol = m1.substring(0, m1.lastIndexOf(' ('));
    let stocknavigateToSymbol = this.sharedserviceService.getSecurityIdFromYFCompanyCode(m1);
    
  
    this.router.navigate(['/symbol/' + stocknavigateToSymbol]);
    //return m.substring(0,m.lastIndexOf(' ('))
  }

  onmouseoverChange(i) {
    this.focusElement = i;
  }
  onfocus() {
    //console.log(this.auth.user.subscribe());
    this.items = BSE_Equity.slice(0, 10);
    //this.focusElement = 0;
  }
  onfocusout() {
    this.items = null;
  }




  onLocationBlur() {
    this.focusElement = -1;

  }

  onArrowUp() {
    if (this.focusElement > 0) {
      this.focusElement--;

    }
  }

  onArrowDown() {
    if (this.focusElement <= this.items.length - 2) {
      this.focusElement++;
    }
  }
  onEnter($event) {
    //let stockToWatch = this.items[this.focusElement].SecurityId + " (" + this.items[this.focusElement].SecurityName + ")";
    let stockToWatchSecurityId = this.items[this.focusElement].SecurityId ;
    let stockToWatchExchange = this.items[this.focusElement].Exchange ;

    let stockToWatch= this.sharedserviceService.getYFCompanyCodeFromSecurityIdAndExchange(stockToWatchSecurityId,stockToWatchExchange);


    if (this.stocknames.includes(stockToWatch)) {
      this.toastr.info('Already in Watchlist', '');

    }
    else {
      this.stocknames.push(stockToWatch);
      this.firestore.doc('/watchlist/' + this.currentDocumentID)
        .update({
          name: this.stocknames

        });

        this.toastr.success('Added to Watchlist', '');

     // this.getEachStockData(stockToWatch)

    }


    this.items = null;
    this.changecharacter = null;
    $event.target.blur();
  }

  unwatch(m) {
console.log("unwatch" + m);
    this.stocknames = this.stocknames.filter(item => item !== m);

    this.firestore.doc('/watchlist/' + this.currentDocumentID)
      .update({
        name: this.stocknames

      });
    this.toastr.success('Deleted', '');
    this.items = null;
    this.changecharacter = null;

//     let s=this.stockData[m];
//     let index = this.stockData.findIndex(x => x.YFCode === m);
// console.log("index" + index);
// ``
//     if (index !== -1) {
//       this.chartsData.splice(index, 1);
//   }  
    this.stockData=this.stockData.filter(item=>item.YFCode!==m);
    this.chartsData=this.chartsData.filter(item=>item.chart.result[0].meta.symbol !==m);
  }


  addToWatchList(StockId, Exchange) {
    //let stockToWatch = StockId + " (" + StockName + ")";

    let stockToWatch= this.sharedserviceService.getYFCompanyCodeFromSecurityIdAndExchange(StockId,Exchange);

    //console.log("add watch "+stockToWatch);
    //console.log(stockToWatch.substring(0,stockToWatch.lastIndexOf(' (')));

    //this.router.navigate(['/symbol/'+m]);


    if (this.stocknames.includes(stockToWatch)) {
      this.toastr.info('Already in Watchlist', '');

    }
    else {
      this.stocknames.push(stockToWatch);
      this.firestore.doc('/watchlist/' + this.currentDocumentID)
        .update({
          name: this.stocknames

        });

      this.toastr.success('Added to Watchlist', '');

      
      //this.getEachStockData(stockToWatch)
    }

    this.items = null;
    this.changecharacter = null;
  }
 
  changeInSearch(event: KeyboardEvent) {


    const innerHtml = (event.target as HTMLInputElement).value;
    (event.target as HTMLInputElement).value = innerHtml.replace('$', '₹');
    let term = (event.target as HTMLInputElement).value.split('₹').pop().toUpperCase();
    if (term === '') {
    this.items = BSE_Equity.slice(0, 10);
    }
    else {
      this.items = BSE_Equity.filter(function (tag) {
        if (tag.SecurityName.indexOf(term) == 0 || tag.SecurityId.indexOf(term) == 0)
          return true;
      });
      if (this.items.length == 0) {
        this.items = NSE_Equity.filter(function (tag) {
          if (tag.SecurityName.indexOf(term) == 0 || tag.SecurityId.indexOf(term) == 0)
            return true;
        });

      }
      //
    }
  }



  getstockData(indexCounter: number) {
    if (!this.chartsData[indexCounter]) return;
    //const chartTempData = this.chartsData[indexCounter];
//     for (let i = 0; i < chartTempData.chart.result.length; i++) {
//       this.stockData.push(
//         new WatchDtoModel({
        
//           name: this.sharedserviceService.getSecurityIdFromYFCompanyCode(chartTempData.chart.result[0].meta.symbol),
// YFCode:chartTempData.chart.result[0].meta.symbol,
//           price: chartTempData.chart.result[0].meta.regularMarketPrice,
//           value:
//             chartTempData.chart.result[0].meta.chartPreviousClose -
//             chartTempData.chart.result[0].meta.regularMarketPrice,
//           diffValue:
//             (chartTempData.chart.result[0].meta.chartPreviousClose * 100) /
//               chartTempData.chart.result[0].meta.regularMarketPrice /
//               100 -
//             chartTempData.chart.result[0].meta.chartPreviousClose / 100,
//           companyName: chartTempData.chart.result[0].meta.exchangeTimezoneName,
//         })
//       );
//     }

    let chartOption: any = {
      chart: {
        height: 80,
      },
      rangeSelector: {
        selected: 1,
      },
      title: {
        text: '',
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        formatter: function () {
          return false;
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
      },
      series: [
        {
          name: '',
          data: this.demoChartsData[indexCounter],
        },
      ],
    };
    setTimeout(() => {
      let selector: any = document.getElementsByClassName('chartline');
      // for (let index = 0; index < selector.length; index++) {
      //   if (indexCounter == index) {
      //     const element = selector[index];
      //     Highcharts.chart(element.id, chartOption);
      //   }
      // }
   
          const element = selector[indexCounter];
          Highcharts.chart(element.id, chartOption);
        
      

    }, 500);
  }

  gosymbol(name) {
    //this.router.navigate(['main/symbol'], { queryParams: { q: name } });
    this.router.navigate(['/symbol/' + name.split('.')[0]]);

  }
}
