
<app-login></app-login>
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
        
      <app-stockscroll></app-stockscroll>
    
    </div>
  </div>

<!-- {{title}}
<app-post></app-post> -->

<div class="backgroundimage">

</div>

<div class="container">
    <div class="row justify-content-between">
        <div class="col-md-3 col-sm-3 col-xs-3">
            <!-- <div class="left_div"> -->
            <span class="border">
                
                <app-news></app-news> 
                <!-- <router-outlet name="forNews"></router-outlet> -->
            </span>
        <!-- </div> -->
        </div>
        <div class="col-md-6 col-sm-6 col-xs-6 pt-2">
            <span class="border">
                <router-outlet></router-outlet>
            </span>
        </div>
        <div class="col-md-3 col-sm-3 col-xs-3">
            <!-- <div class="right_div">  -->
            <span class="border">
                <!-- <app-watchlist></app-watchlist> -->
                <!-- <fa-icon [icon]="faCoffee"></fa-icon> -->
                <router-outlet name="forWatchList"></router-outlet>
               
                <!-- <app-watchlist></app-watchlist> -->
            </span>
         <!-- </div> -->
        </div>
    </div>
</div>