import { Injectable } from '@angular/core';
import { HttpClient,HttpClientJsonpModule,HttpClientModule } from '@angular/common/http';
import { url } from 'inspector';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // String quoteSite = "https://query1.finance.yahoo.com/v7/finance/chart/"
  //                  + symbolName + "?"
  //                  + "period1=" + period1
  //                  + "&period2=" + period2
  //                  + "&interval=1d&indicators=quote&includeTimestamps=true";

  //https://query1.finance.yahoo.com/v10/finance/quoteSummary/AAPL?modules=assetProfile%2CearningsHistory
  //https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=true&interval=1h&range=1d&.tsrc=finance%27
  //https://query1.finance.yahoo.com/v10/finance/quoteSummary/AAPL?modules=assetProfile%2CearningsHistory
  //private api = 'https://query1.finance.yahoo.com/v8/finance/chart/{0}?region=US&lang=en-US&includePrePost=false&interval=2m&range=1d&.tsrc=finance';
  private api = '/v8/finance/chart/{0}?region=US&lang=en-US&includePrePost=false&interval=2m&range=1d&.tsrc=finance';
  constructor(private httpClient: HttpClient) { }
  public getStockInformation(ticker){
      let api = this.api.replace('{0}', ticker);
      //this.httpClient.header('Access-Control-Allow-Origin', "*")
return this.httpClient.get(api);
      //return this.httpClient.get(api);
//return this.httpClient.jsonp(api,'callback',{responseType: 'json'});
      
  }
  private news = '/rss/search?q={0}&hl=en-US&gl=US&ceid=US:en';
  public getNewsInformation(ticker){
    const requestOptions: Object = {
      observe: 'body',
      responseType: 'text'
    };
    let api = this.news.replace('{0}', ticker);
    //this.httpClient.header('Access-Control-Allow-Origin', "*")
    //api="https://news.google.com/rss/search?q=tesla&hl=en-US&gl=US&ceid=US:en";
    console.log("googleNews: " +api);
//return this.httpClient.get(api, {responseType: 'text'});
return this.httpClient.get(api, requestOptions);
   
    
}

getChartData(symbol, period1) {
  var timestamp = Math.round(period1 / 1000);
  var today = new Date();
  var todayTimestamp = Math.round(today.getTime() / 1000);
  //return this.http.get(`https://query1.finance.yahoo.com/v8/finance/chart/` + symbol + `?region=US&lang=en-US`);
  return this.httpClient.get(`https://query1.finance.yahoo.com/v8/finance/chart/` + symbol + `?region=US&lang=en-US&period1=` + timestamp + `&period2=`+ todayTimestamp + `&interval=1d`);
  //return this.httpClient.get(`https://query1.finance.yahoo.com/v8/finance/chart/` + symbol + `?region=US&lang=en-US&includePrePost=false&interval=2m&range=1Y&.tsrc=finance%27`);

}
getCentralChartData(symbol, interval, range) {
  
  return this.httpClient.get(`https://query1.finance.yahoo.com/v8/finance/chart/` + symbol + `?region=US&lang=en-US&includePrePost=false&interval=` + interval + `&range=` + range + `&.tsrc=finance%27`);
 
 // return this.httpClient.get(`https://query1.finance.yahoo.com/v8/finance/chart/{$symbol}?range={$range}&interval={$interval}

// https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=false&interval=2m&range=1d&.tsrc=finance
// https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=false&interval=30m&range=5d&.tsrc=finance
// https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=false&interval=60m&range=1mo&.tsrc=finance
// https://query1.finance.yahoo.com/v8/finance/chart/NVDA?region=US&lang=en-US&includePrePost=false&interval=1h&range=ytd&.tsrc=finance
// https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=false&interval=1h&range=6mo&.tsrc=finance
// https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=false&interval=5m&range=1Y&.tsrc=finance
// https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=false&interval=5m&range=5Y&.tsrc=finance
// https://query1.finance.yahoo.com/v8/finance/chart/TSLA?region=US&lang=en-US&includePrePost=false&interval=5m&range=max&.tsrc=finance
}
getMultiStock(){

  //return this.httpClient.get(`https://query1.finance.yahoo.com/v7/finance/quote?symbols=MJCO.BO,NFLX,GOOG,PLTR,TWTR,FB,MSFT,DIS,NVDA,GE,V,CRM`);
  return this.httpClient.get(`https://query1.finance.yahoo.com/v7/finance/quote?symbols=MJCO.BO,RELIANCE.BO,TCS.BO,HDFCBANK.BO,HINDUNILVR.BO,INFY.BO,KOTAKBANK.BO,ICICIBANK.BO,BAJFINANCE.BO,ITC.BO`);

}
private gadgetnews = 'https://gadgets.ndtv.com/rss/feeds';

getGadgetRssFeedData(ticker)
{
  //let api = this.news.replace('{0}', "TSLA");
  let api = this.gadgetnews;
  
return this.httpClient.get(api, {responseType: 'text'});

}

}
