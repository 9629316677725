<!-- <div class="col align-self-center">
  <div id="search-component">
    <input #searchBox id="search-box" [(ngModel)]="changecharacter" (focus)="onfocus()" (focusout)="onfocusout()"
      (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp()" (keydown.ArrowDown)="onArrowDown()"
      (keydown.enter)="onEnter($event)" (input)="this.changeInSearch($event)" />
    
  </div>
</div> -->


<!-- <ul>
        <li class="text" *ngFor="let stock of stocknames">
         
         <a routerLink='/symbol/{{stock}}'>&#8377;{{stock}}</a> 
      <a (click)="unwatch(stock)">Delete</a>
     
        </li>
      </ul> -->

<!-- ➖ -->



<!-- <br>
<br>
<table class="table table-striped">
 
  <tbody>
    <tr *ngFor="let stock of stocknames; index as i" draggable="true">
      <th scope="row">{{ i + 1 }}</th>
      <td>
    
        <a (click)="navigateToSymbol(stock)">&#8377;{{getSecurityIdFromYFCompanyCode(stock)}}</a>

       

      </td>

      <td><a (click)="unwatch(stock)">
          <fa-icon class="btn-outline-primary" [icon]="faTrashAlt"></fa-icon>
        </a></td>
    </tr>
  </tbody>
</table>

<br> -->

<!-- <div echarts [options]="chartOption" class="offer-price-history__chart"></div> -->
<div class="row pt-2">
  <div class="col-md-12 col-sm-12 col-xs-12">
    <div class="card">
      <div class="card-body text-left card-box p-0">
        <div class="p-3">
          <div class="title-watch">Watchlist</div>
          <div class="input-group mb-1">
            <div class="input-group-prepend">
              <span class="input-group-text set-icon" id="basic-addon1"><i class="bi bi-search"></i></span>
            </div>
            <!-- <input type="text" pInputText class="input-searchbox" placeholder="Search..." aria-label="Search"
              aria-describedby="basic-addon1" /> -->
              <input  [(ngModel)]="changecharacter" (focus)="onfocus()" (focusout)="onfocusout()"
      (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp()" (keydown.ArrowDown)="onArrowDown()"
      (keydown.enter)="onEnter($event)" (input)="this.changeInSearch($event)" type="text" pInputText class="input-searchbox" placeholder="Search..." aria-label="Search"
      aria-describedby="basic-addon1" />
      <ul id="testid" class="search-result">

        <li #item [ngClass]="{'focus-background': focusElement == item.tabIndex}" (mouseover)="onmouseoverChange(i)"
          (mousedown)="addToWatchList(company.SecurityId,company.Exchange)"
          *ngFor="let company of items | slice:0:10; let i = index" tabIndex="{{i}}">
  
          <div class="row">
            <div class="col-md-10 col-sm-10 col-xs-10">
              <a routerLink='/symbol/{{company.SecurityId}}'>&#8377;{{company.SecurityId}}</a>
            </div>
            <div class="col-md-2 col-sm-2 col-xs-2">
              <fa-icon [icon]="faPlusCircle"></fa-icon>
            </div>
  
          </div>
  
          <div class="row">
            <div class="col" style="font-size: 10px;">
              {{company.SecurityName}}
            </div>
          </div>
  
        </li>
      </ul>
          </div>
        </div>
        <div class="scroll-box">
          <div class="stock-contain" *ngFor="let s of stockData; index as i">
            <div class="row p-3 mr-2 ml-2 " >
              <div class="col-md-4 col-sm-4 col-xs-4 p-0" (click)="gosymbol(s.name)">
                <span class="card-text card-stock-name display-5">&#8377;{{ s.name }}</span>
                <span class="text-wrap-span text-muted">{{ s.companyName }}</span>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-4 pl-0 pr-2" (click)="gosymbol(s.name)" >
                <!-- <div class="card-text card-news-title display-5"> -->
                  <div id="chartline{{i}}" class="chartline"></div>
              
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3 pl-0 pr-2">
                <span class="card-text card-stock-name ">{{
                  s.price
                  }}</span>
                <span [ngClass]="
                  s.price > 0 ? 'stock-price-text-up' : 'stock-price-text-down'
                ">
                  <i [ngClass]="
                    s.price > 0
                      ? 'bi bi-arrow-up icon-set-all '
                      : 'bi bi-arrow-down icon-set-all'
                  "></i>
                  {{ s.value | number: "2.1-2" }}
                  ({{ s.diffValue | number: "2.1-2" }} %)
                </span>
              </div>
           
              <div class="col-md-1 col-sm-1 col-xs-1 p-0">
                <span class="icon-delete">
                  <a (click)="unwatch(s.YFCode)">
                    <fa-icon class="btn-outline-primary" [icon]="faTrashAlt"></fa-icon>
                  </a>
                  <!-- <i class="bi bi-trash font-text"></i> -->
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div echarts [options]="chartOption" class="demo-chart"></div> -->