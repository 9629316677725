import { BSE_Equity_Interface } from './BSE_Equity_Interface';
//convert excel sheet to json
//http://beautifytools.com/excel-to-json-converter.php
//https://www.bseindia.com/corporates/List_Scrips.aspx
export const BSE_Equity:BSE_Equity_Interface[]=[
      
    {
        "Exchange": "BSE",
        "SecurityCode": "NIFYBANK",
        "SecurityId": "NIFYBANK",
        "SecurityName": "NIFYBANK",
        "FaceValue": "0",
        "ISINNo": "NiftyBank",
        "Industry": "Nifty Bank",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "NIFTY50",
        "SecurityId": "NIFTY50",
        "SecurityName": "NIFTY 50",
        "FaceValue": "o",
        "ISINNo": "NIFTY50",
        "Industry": "NIFTY 50",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "SENSEX",
        "SecurityId": "SENSEX",
        "SecurityName": "SENSEX",
        "FaceValue": "0",
        "ISINNo": "Sensex",
        "Industry": "Sensex",
        "Instrument": "Equity"
    },
            {
                "Exchange": "BSE",
                "SecurityCode": "500002",
                "SecurityId": "ABB",
                "SecurityName": "ABB INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE117A01022",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500003",
                "SecurityId": "AEGISLOG",
                "SecurityName": "AEGIS LOGISTICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE208C01025",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500004",
                "SecurityId": "TPAEC",
                "SecurityName": "TORRENT POWER AEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE424A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500005",
                "SecurityId": "AKARLAMIN",
                "SecurityName": "AKAR LAMINATORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE984C01013",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500006",
                "SecurityId": "ALPHADR",
                "SecurityName": "ALPHA DRUG INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE256B01026",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500008",
                "SecurityId": "AMARAJABAT",
                "SecurityName": "AMARA RAJA BATTERIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE885A01032",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500009",
                "SecurityId": "AMBALALSA",
                "SecurityName": "AMBALAL SARABHAI ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE432A01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500010",
                "SecurityId": "HDFC",
                "SecurityName": "HOUSING DEVELOPMENT FINANCE CORP.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE001A01036",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500011",
                "SecurityId": "AMRTMIL-BDM",
                "SecurityName": "AMRUT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500012",
                "SecurityId": "ANDHRAPET",
                "SecurityName": "ANDHRA PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE714B01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500013",
                "SecurityId": "ANSALAPI",
                "SecurityName": "ANSAL PROPERTIES & INFRASTRUCTURE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE436A01026",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500014",
                "SecurityId": "UTIQUE",
                "SecurityName": "UTIQUE ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE096A01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500015",
                "SecurityId": "ICICIDM",
                "SecurityName": "ICICI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE005A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500016",
                "SecurityId": "ARUNAHTEL",
                "SecurityName": "ARUNA HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE957C01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500018",
                "SecurityId": "ARPOLDM",
                "SecurityName": "ARPOLDM",
                "FaceValue": "10",
                "ISINNo": "INE035A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500019",
                "SecurityId": "BOR",
                "SecurityName": "BANK OF RAJASTHAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320A01014",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500020",
                "SecurityId": "BOMDYEING",
                "SecurityName": "BOMBAY DYEING & MFG.CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE032A01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500021",
                "SecurityId": "ASINCOF",
                "SecurityName": "ASINCOF",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500023",
                "SecurityId": "ASIANHOTNR",
                "SecurityName": "ASIAN HOTELS (NORTH) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE363A01022",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500024",
                "SecurityId": "ASSAMCO",
                "SecurityName": "ASSAM COMPANY (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE442A01024",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500025",
                "SecurityId": "ASSAMBR",
                "SecurityName": "ASSAMBROOK LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE353C01011",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500026",
                "SecurityId": "ATSHIND",
                "SecurityName": "ATASH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500027",
                "SecurityId": "ATUL",
                "SecurityName": "ATUL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE100A01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500028",
                "SecurityId": "ATVPR",
                "SecurityName": "ATV PROJECTS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE447A01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500029",
                "SecurityId": "AUTOLITIND",
                "SecurityName": "AUTOLITE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE448A01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500030",
                "SecurityId": "AUTORIDFIN",
                "SecurityName": "AUTORIDERS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE450A01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500031",
                "SecurityId": "BAJAJELEC",
                "SecurityName": "BAJAJ ELECTRICALS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE193E01025",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500032",
                "SecurityId": "BAJAJHIND",
                "SecurityName": "BAJAJ HINDUSTHAN SUGAR LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE306A01021",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500033",
                "SecurityId": "FORCEMOT",
                "SecurityName": "FORCE MOTORS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE451A01017",
                "Industry": "Cars & Utility Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500034",
                "SecurityId": "BAJFINANCE",
                "SecurityName": "BAJAJ FINANCE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE296A01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500035",
                "SecurityId": "BALAJIDI",
                "SecurityName": "BALAJI DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE453A01013",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500036",
                "SecurityId": "BALAJIIND",
                "SecurityName": "BALAJI INDUSTRIAL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE455A01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500037",
                "SecurityId": "BALMERL-B",
                "SecurityName": "BALMER LAWRIE FREIGHT CONTAINERS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500038",
                "SecurityId": "BALRAMCHIN",
                "SecurityName": "BALRAMPUR CHINI MILLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE119A01028",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500039",
                "SecurityId": "BANCOINDIA",
                "SecurityName": "BANCO PRODUCTS (INDIA) LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE213C01025",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500040",
                "SecurityId": "CENTURYTEX",
                "SecurityName": "CENTURY TEXTILES & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE055A01016",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500041",
                "SecurityId": "BANARISUG",
                "SecurityName": "BANNARI AMMAN SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE459A01010",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500042",
                "SecurityId": "BASF",
                "SecurityName": "BASF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE373A01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500043",
                "SecurityId": "BATAINDIA",
                "SecurityName": "BATA INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE176A01028",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500044",
                "SecurityId": "RAYBAN",
                "SecurityName": "RAYBAN SUN OPTICS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE854A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500045",
                "SecurityId": "BELLARYS",
                "SecurityName": "BELLARY STEELS & ALLOYS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE166C01025",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500046",
                "SecurityId": "BECREL",
                "SecurityName": "BEST & CROMPTON ENGG.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE287A01015",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500047",
                "SecurityId": "BETANAP",
                "SecurityName": "BETA NAPHTHOL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500048",
                "SecurityId": "BEML",
                "SecurityName": "BEML LTD.",
                "FaceValue": "10",
                "ISINNo": "INE258A01016",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500049",
                "SecurityId": "BEL",
                "SecurityName": "BHARAT ELECTRONICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE263A01024",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500050",
                "SecurityId": "BHARATRA",
                "SecurityName": "BHARAT RASAYAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE838B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500051",
                "SecurityId": "BHAGGAS",
                "SecurityName": "BHAGAWATI GAS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE099C01010",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500052",
                "SecurityId": "BEPL",
                "SecurityName": "BHANSALI ENGINEERING POLYMERS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE922A01025",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500053",
                "SecurityId": "BHAZINC",
                "SecurityName": "BHARAT ZINC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500054",
                "SecurityId": "BHRTITE-B",
                "SecurityName": "BHRTITE-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500055",
                "SecurityId": "TATASTLBSL",
                "SecurityName": "TATA STEEL BSL LTD",
                "FaceValue": "2",
                "ISINNo": "INE824B01021",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500056",
                "SecurityId": "UTIMRDM",
                "SecurityName": "MASTERGROWTH-93",
                "FaceValue": "10",
                "ISINNo": "INF189A01046",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500057",
                "SecurityId": "ABCIL",
                "SecurityName": "ADITYA BIRLA CHEMICALS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE605B01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500058",
                "SecurityId": "BIHSPONG",
                "SecurityName": "BIHAR SPONGE IRON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE819C01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500059",
                "SecurityId": "BINANIIND",
                "SecurityName": "BINANI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE071A01013",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500060",
                "SecurityId": "BIRLACABLE",
                "SecurityName": "BIRLA CABLE LTD",
                "FaceValue": "10",
                "ISINNo": "INE800A01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500061",
                "SecurityId": "ANVALDM",
                "SecurityName": "ANDHRA VALLEY POWER SUPPLY COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE247A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500062",
                "SecurityId": "ASHOKLEYFIN",
                "SecurityName": "ASHOK LEYLAND FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE251A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500063",
                "SecurityId": "OSWALCHEM",
                "SecurityName": "OSWAL CHEMICALS & FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE143A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500064",
                "SecurityId": "BGFL",
                "SecurityName": "BIRLA GLOBAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE097A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500067",
                "SecurityId": "BLUESTARCO",
                "SecurityName": "BLUE STAR LTD.",
                "FaceValue": "2",
                "ISINNo": "INE472A01039",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500068",
                "SecurityId": "DISAQ",
                "SecurityName": "DISA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE131C01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500069",
                "SecurityId": "BNKCAP",
                "SecurityName": "BNK CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE418C01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500070",
                "SecurityId": "BOP",
                "SecurityName": "BANK OF PUNJAB LTD.",
                "FaceValue": "10",
                "ISINNo": "INE458A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500072",
                "SecurityId": "BONGAIREFN",
                "SecurityName": "BONGAIGAON REFINERY & PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE241A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500073",
                "SecurityId": "BPLENG",
                "SecurityName": "BPL ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE108A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500074",
                "SecurityId": "BPL",
                "SecurityName": "BPL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE110A01019",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500075",
                "SecurityId": "NAGARFER",
                "SecurityName": "NAGARJUNA FERTILIZERS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE580A01013",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500076",
                "SecurityId": "BURROWEL",
                "SecurityName": "BURROUGHS WELLCOME (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE157A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500077",
                "SecurityId": "CCI",
                "SecurityName": "CABLE CORPORATION OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE475A01016",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500078",
                "SecurityId": "OAL",
                "SecurityName": "ORIENTAL AROMATICS LTD-$",
                "FaceValue": "5",
                "ISINNo": "INE959C01023",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500079",
                "SecurityId": "CARIR",
                "SecurityName": "CARRIER AIRCON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE480A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500080",
                "SecurityId": "CAUVERSOFT",
                "SecurityName": "CAUVERY SOFTWARE ENGINEERING SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE466B01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500081",
                "SecurityId": "CELEINT",
                "SecurityName": "CELESTE INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500082",
                "SecurityId": "CENCHDM",
                "SecurityName": "CENTAK CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE942A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500083",
                "SecurityId": "CENTEXT",
                "SecurityName": "CENTURY EXTRUSIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE281A01026",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500084",
                "SecurityId": "CESC",
                "SecurityName": "CESC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE486A01013",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500085",
                "SecurityId": "CHAMBLFERT",
                "SecurityName": "CHAMBAL FERTILISERS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE085A01013",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500086",
                "SecurityId": "EXIDEIND",
                "SecurityName": "EXIDE INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE302A01020",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500087",
                "SecurityId": "CIPLA",
                "SecurityName": "CIPLA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE059A01026",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500089",
                "SecurityId": "DICIND",
                "SecurityName": "DIC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE303A01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500090",
                "SecurityId": "MADUCOA",
                "SecurityName": "MADURA COATS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500091",
                "SecurityId": "COREPARENT",
                "SecurityName": "CORE HEALTHCARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE494A01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500092",
                "SecurityId": "CRISIL",
                "SecurityName": "CRISIL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE007A01025",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500093",
                "SecurityId": "CGPOWER",
                "SecurityName": "CG POWER AND INDUSTRIAL SOLUTIONS LTD",
                "FaceValue": "2",
                "ISINNo": "INE067A01029",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500094",
                "SecurityId": "CTCOTTON",
                "SecurityName": "CT COTTON YARN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE107D01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500095",
                "SecurityId": "WYETH",
                "SecurityName": "WYETH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE378A01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500096",
                "SecurityId": "DABUR",
                "SecurityName": "DABUR INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE016A01026",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500097",
                "SecurityId": "DALMIASUG",
                "SecurityName": "DALMIA BHARAT SUGAR AND INDUSTRIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE495A01022",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500098",
                "SecurityId": "SKYLNEPC",
                "SecurityName": "SKYLINE NEPC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE575B01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500099",
                "SecurityId": "CENTINPO",
                "SecurityName": "CENTRAL INDIA POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE496A01020",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500100",
                "SecurityId": "DAEWOO",
                "SecurityName": "DAEWOO MOTORS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE497A01010",
                "Industry": "Cars & Utility Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500101",
                "SecurityId": "ARVIND",
                "SecurityName": "ARVIND LTD.",
                "FaceValue": "10",
                "ISINNo": "INE034A01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500102",
                "SecurityId": "BALLARPUR",
                "SecurityName": "BALLARPUR INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE294A01037",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500103",
                "SecurityId": "BHEL",
                "SecurityName": "BHARAT HEAVY ELECTRICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE257A01026",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500104",
                "SecurityId": "HINDPETRO*",
                "SecurityName": "HINDUSTAN PETROLEUM CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE094A01015",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500105",
                "SecurityId": "IPCL",
                "SecurityName": "INDIAN PETROCHEMICALS CORP.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE006A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500106",
                "SecurityId": "IFCI",
                "SecurityName": "IFCI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE039A01010",
                "Industry": "Financial Institutions",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500107",
                "SecurityId": "JINDALIRON",
                "SecurityName": "JINDAL IRON & STEEL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE020A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500108",
                "SecurityId": "MTNL",
                "SecurityName": "MAHANAGAR TELEPHONE NIGAM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE153A01019",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500109",
                "SecurityId": "MRPL",
                "SecurityName": "MANGALORE REFINERY & PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE103A01014",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500110",
                "SecurityId": "CHENNPETRO",
                "SecurityName": "CHENNAI PETROLEUM CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE178A01016",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500111",
                "SecurityId": "RELCAPITAL",
                "SecurityName": "RELIANCE CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE013A01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500112",
                "SecurityId": "SBIN",
                "SecurityName": "STATE BANK OF INDIA",
                "FaceValue": "1",
                "ISINNo": "INE062A01020",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500113",
                "SecurityId": "SAIL",
                "SecurityName": "STEEL AUTHORITY OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE114A01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500114",
                "SecurityId": "TITAN",
                "SecurityName": "TITAN COMPANY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE280A01028",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500115",
                "SecurityId": "MSTRP",
                "SecurityName": "MSTRP",
                "FaceValue": "10",
                "ISINNo": "INF189A01061",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500116",
                "SecurityId": "IDBI",
                "SecurityName": "IDBI BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE008A01015",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500117",
                "SecurityId": "DCW",
                "SecurityName": "DCW LTD.",
                "FaceValue": "2",
                "ISINNo": "INE500A01029",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500119",
                "SecurityId": "DHAMPURSUG",
                "SecurityName": "DHAMPUR SUGAR MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE041A01016",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500120",
                "SecurityId": "DIAMINESQ",
                "SecurityName": "DIAMINES & CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE591D01014",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500121",
                "SecurityId": "DIGITAL",
                "SecurityName": "DIGITAL GLOBALSOFT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE124A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500122",
                "SecurityId": "AMBUJACEMR",
                "SecurityName": "AMBUJA CEMENT RAJASTHAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE125A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500123",
                "SecurityId": "ELANTAS",
                "SecurityName": "ELANTAS BECK INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE280B01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500124",
                "SecurityId": "DRREDDY",
                "SecurityName": "DR.REDDY&#39;S LABORATORIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE089A01023",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500125",
                "SecurityId": "EIDPARRY",
                "SecurityName": "E.I.D.-PARRY (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE126A01031",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500126",
                "SecurityId": "PGHL",
                "SecurityName": "PROCTER & GAMBLE HEALTH LTD",
                "FaceValue": "10",
                "ISINNo": "INE199A01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500127",
                "SecurityId": "ESTMNAL",
                "SecurityName": "EASTERN MINING & ALLIED LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500128",
                "SecurityId": "ELECTCAST",
                "SecurityName": "ELECTROSTEEL CASTINGS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE086A01029",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500129",
                "SecurityId": "ELAUPAR-BDM",
                "SecurityName": "ELGI AUTO PARTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500130",
                "SecurityId": "ELGIF",
                "SecurityName": "ELGI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE424D01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500131",
                "SecurityId": "ELGITR",
                "SecurityName": "ELGITREAD (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE257B01024",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500132",
                "SecurityId": "EMPEESUG",
                "SecurityName": "EMPEE SUGARS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE928B01012",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500133",
                "SecurityId": "ESABINDIA",
                "SecurityName": "ESAB INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE284A01012",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500134",
                "SecurityId": "ESSAROIL",
                "SecurityName": "ESSAR OIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE011A01019",
                "Industry": "Integrated Oil & Gas",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500135",
                "SecurityId": "EPL",
                "SecurityName": "EPL LTD",
                "FaceValue": "2",
                "ISINNo": "INE255A01020",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500136",
                "SecurityId": "ESTER",
                "SecurityName": "ESTER INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE778B01029",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500138",
                "SecurityId": "UNIWORTHT",
                "SecurityName": "UNIWORTH TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE486C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500139",
                "SecurityId": "FEDDERELEC",
                "SecurityName": "FEDDERS ELECTRIC AND ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE249C01011",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500140",
                "SecurityId": "FEMNORM-B",
                "SecurityName": "FEMNOR MINERAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500141",
                "SecurityId": "FERROALL",
                "SecurityName": "FERRO ALLOYS CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE912A01026",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500142",
                "SecurityId": "FGP",
                "SecurityName": "FGP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE512A01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500143",
                "SecurityId": "PHCAP",
                "SecurityName": "PH CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE160F01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500144",
                "SecurityId": "FINCABLES",
                "SecurityName": "FINOLEX CABLES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE235A01022",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500145",
                "SecurityId": "FIRSTLEASE",
                "SecurityName": "FIRST LEASING CO.OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE492B01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500147",
                "SecurityId": "COCKERILL",
                "SecurityName": "JOHN COCKERILL INDIA LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE515A01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500148",
                "SecurityId": "UFLEX",
                "SecurityName": "UFLEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE516A01017",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500149",
                "SecurityId": "FLOAI",
                "SecurityName": "FLOATGLASS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE517A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500150",
                "SecurityId": "FOSECOIND",
                "SecurityName": "FOSECO INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE519A01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500151",
                "SecurityId": "GOLDENTOBC",
                "SecurityName": "GOLDEN TOBACCO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE973A01010",
                "Industry": "Cigarettes-Tobacco Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500152",
                "SecurityId": "GRMAG",
                "SecurityName": "G.R.MAGNETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE523A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500153",
                "SecurityId": "GANESHBE",
                "SecurityName": "GANESH BENZOPLAST LTD.",
                "FaceValue": "1",
                "ISINNo": "INE388A01029",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500154",
                "SecurityId": "FOSCL",
                "SecurityName": "FUTURISTIC OFFSHORE SERVICES & CHEMICAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE418A01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500155",
                "SecurityId": "GARDENSILK",
                "SecurityName": "GARDEN SILK MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE526A01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500156",
                "SecurityId": "GRMNR",
                "SecurityName": "GERMAN REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE382A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500157",
                "SecurityId": "GICBALN-B",
                "SecurityName": "GICBALN-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500158",
                "SecurityId": "GICBGVL-B",
                "SecurityName": "GICBGVL-B",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500159",
                "SecurityId": "METROGLOBL",
                "SecurityName": "METROGLOBAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE085D01033",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500160",
                "SecurityId": "GTL",
                "SecurityName": "GTL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE043A01012",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500161",
                "SecurityId": "GTB",
                "SecurityName": "GLOBAL TRUST BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE130A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500162",
                "SecurityId": "PARRYSUGAR",
                "SecurityName": "PARRYS SUGAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE353B01021",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500163",
                "SecurityId": "GODFRYPHLP",
                "SecurityName": "GODFREY PHILLIPS INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE260B01028",
                "Industry": "Cigarettes-Tobacco Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500164",
                "SecurityId": "GODREJIND",
                "SecurityName": "GODREJ INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE233A01035",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500165",
                "SecurityId": "KANSAINER",
                "SecurityName": "KANSAI NEROLAC PAINTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE531A01024",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500166",
                "SecurityId": "GOODRICKE",
                "SecurityName": "GOODRICKE GROUP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE300A01016",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500167",
                "SecurityId": "GOVMC",
                "SecurityName": "GOOD VALUE MARKETING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE532A01014",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500168",
                "SecurityId": "GOODYEAR",
                "SecurityName": "GOODYEAR INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE533A01012",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500169",
                "SecurityId": "GRPCOMI",
                "SecurityName": "GRAPCO MINING & CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500170",
                "SecurityId": "GTNINDS",
                "SecurityName": "GTN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE537A01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500171",
                "SecurityId": "GHCL",
                "SecurityName": "GHCL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE539A01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500173",
                "SecurityId": "GFLLIMITED",
                "SecurityName": "GFL LTD",
                "FaceValue": "1",
                "ISINNo": "INE538A01037",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500174",
                "SecurityId": "GLFL",
                "SecurityName": "GUJARAT LEASE FINANCING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE540A01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500175",
                "SecurityId": "GTCL",
                "SecurityName": "GUJARAT TELEPHONE CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE261B01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500176",
                "SecurityId": "HAMCMIN-B",
                "SecurityName": "HAMCO MINING & SMELTING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500177",
                "SecurityId": "HANILERA",
                "SecurityName": "HANIL ERA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE021D01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500178",
                "SecurityId": "HARCR",
                "SecurityName": "HARIG CRANKSHAFTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE905C01026",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500179",
                "SecurityId": "HCL-INSYS",
                "SecurityName": "HCL INFOSYSTEMS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE236A01020",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500180",
                "SecurityId": "HDFCBANK",
                "SecurityName": "HDFC BANK LTD",
                "FaceValue": "1",
                "ISINNo": "INE040A01034",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500181",
                "SecurityId": "HRBTSON-B",
                "SecurityName": "HERBERTSONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE546A1014 ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500182",
                "SecurityId": "HEROMOTOCO",
                "SecurityName": "HERO MOTOCORP LTD.",
                "FaceValue": "2",
                "ISINNo": "INE158A01026",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500183",
                "SecurityId": "HFCL",
                "SecurityName": "HFCL LTD",
                "FaceValue": "1",
                "ISINNo": "INE548A01028",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500184",
                "SecurityId": "HSCL",
                "SecurityName": "HIMADRI SPECIALITY CHEMICAL LTD",
                "FaceValue": "1",
                "ISINNo": "INE019C01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500185",
                "SecurityId": "HCC",
                "SecurityName": "HINDUSTAN CONSTRUCTION CO.LTD.",
                "FaceValue": "1",
                "ISINNo": "INE549A01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500186",
                "SecurityId": "HINDOILEXP",
                "SecurityName": "HINDUSTAN OIL EXPLORATION CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE345A01011",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500187",
                "SecurityId": "HSIL",
                "SecurityName": "HSIL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE415A01038",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500188",
                "SecurityId": "HINDZINC",
                "SecurityName": "HINDUSTAN ZINC LTD.",
                "FaceValue": "2",
                "ISINNo": "INE267A01025",
                "Industry": "Zinc",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500189",
                "SecurityId": "NXTDIGITAL",
                "SecurityName": "NXTDIGITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE353A01023",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500190",
                "SecurityId": "HITDRDM",
                "SecurityName": "HITECH DRILLING SERVICES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE555A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500191",
                "SecurityId": "HMT",
                "SecurityName": "HMT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE262A01018",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500192",
                "SecurityId": "PRAGBOS",
                "SecurityName": "PRAG BOSIMI SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE962B01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500193",
                "SecurityId": "HLVLTD",
                "SecurityName": "HLV LTD",
                "FaceValue": "2",
                "ISINNo": "INE102A01024",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500195",
                "SecurityId": "HOTLINGLAS",
                "SecurityName": "HOTLINE GLASS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE676B01017",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500197",
                "SecurityId": "HTSUMAG",
                "SecurityName": "HYTAISUN MAGNETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500198",
                "SecurityId": "IBP",
                "SecurityName": "IBP CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE261A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500199",
                "SecurityId": "IGPL",
                "SecurityName": "I G PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE204A01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500201",
                "SecurityId": "INDIAGLYCO",
                "SecurityName": "INDIA GLYCOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE560A01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500202",
                "SecurityId": "INDLEASE",
                "SecurityName": "INDIA LEASE DEVELOPMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE333C01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500203",
                "SecurityId": "INDPOLY",
                "SecurityName": "INDIA POLYFIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE860C01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500204",
                "SecurityId": "INDSEC",
                "SecurityName": "INDIA SECURITIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE134A01035",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500206",
                "SecurityId": "MARGOFIN",
                "SecurityName": "MARGO FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE680B01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500207",
                "SecurityId": "INDORAMA",
                "SecurityName": "INDO RAMA SYNTHETICS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE156A01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500208",
                "SecurityId": "INDUNISS",
                "SecurityName": "INDU NISSAN OXO-CHEMICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE599C01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500209",
                "SecurityId": "INFY",
                "SecurityName": "INFOSYS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE009A01021",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500210",
                "SecurityId": "INGERRAND",
                "SecurityName": "INGERSOLL-RAND (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE177A01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500211",
                "SecurityId": "INSILCO",
                "SecurityName": "INSILCO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE901A01011",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500212",
                "SecurityId": "IFSL",
                "SecurityName": "INTEGRATED FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE898B01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500213",
                "SecurityId": "ITHL",
                "SecurityName": "INTERNATIONAL TRAVEL HOUSE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE262B01016",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500214",
                "SecurityId": "IONEXCHANG",
                "SecurityName": "ION EXCHANGE (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE570A01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500215",
                "SecurityId": "ATFL",
                "SecurityName": "AGRO TECH FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE209A01019",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500216",
                "SecurityId": "ITCHOTEL",
                "SecurityName": "ITC HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE379A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500217",
                "SecurityId": "ITWSGDM",
                "SecurityName": "ITW SIGNODE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE240A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500218",
                "SecurityId": "JKPHARMA",
                "SecurityName": "J.K.PHARMACHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE335C01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500219",
                "SecurityId": "JISLJALEQS",
                "SecurityName": "JAIN IRRIGATION SYSTEMS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE175A01038",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500220",
                "SecurityId": "JASCH",
                "SecurityName": "JASCH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE711C01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500221",
                "SecurityId": "JPHOTEL",
                "SecurityName": "JAYPEE HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE851A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500222",
                "SecurityId": "JCTEL",
                "SecurityName": "JCT ELECTRONICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE264B01020",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500223",
                "SecurityId": "JCTLTD",
                "SecurityName": "JCT LTD.",
                "FaceValue": "2.5",
                "ISINNo": "INE945A01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500224",
                "SecurityId": "JNDST",
                "SecurityName": "JNDST",
                "FaceValue": "10",
                "ISINNo": "INE023A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500226",
                "SecurityId": "CONSOFIN",
                "SecurityName": "CONSOLIDATED FINVEST & HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE025A01027",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500227",
                "SecurityId": "JINDALPOLY",
                "SecurityName": "JINDAL POLY FILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE197D01010",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500228",
                "SecurityId": "JSWSTEEL",
                "SecurityName": "JSW STEEL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE019A01038",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500229",
                "SecurityId": "JINDVJY",
                "SecurityName": "JINDVJY",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500230",
                "SecurityId": "JINDALV",
                "SecurityName": "JINDALV",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500231",
                "SecurityId": "UMANGDAIR",
                "SecurityName": "UMANG DAIRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE864B01027",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500232",
                "SecurityId": "JORDENG-B",
                "SecurityName": "JORD ENGINEERS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE666F01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500233",
                "SecurityId": "KAJARIACER",
                "SecurityName": "KAJARIA CERAMICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE217B01036",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500234",
                "SecurityId": "KAKATCEM",
                "SecurityName": "KAKATIYA CEMENT SUGAR & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE437B01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500235",
                "SecurityId": "KSL",
                "SecurityName": "KALYANI STEELS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE907A01026",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500236",
                "SecurityId": "KANELIND",
                "SecurityName": "KANEL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE252C01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500237",
                "SecurityId": "KEDIADI-B",
                "SecurityName": "KEDIA DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500238",
                "SecurityId": "WHIRLPOOL",
                "SecurityName": "WHIRLPOOL OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE716A01013",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500239",
                "SecurityId": "KGDENIM",
                "SecurityName": "KG DENIM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE104A01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500240",
                "SecurityId": "KINETICENG",
                "SecurityName": "KINETIC ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE266B01017",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500241",
                "SecurityId": "KIRLOSBROS",
                "SecurityName": "KIRLOSKAR BROTHERS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE732A01036",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500242",
                "SecurityId": "KIRPNDM",
                "SecurityName": "KIRLOSKAR PNEUMATIC CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE328A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500243",
                "SecurityId": "KIRLOSIND",
                "SecurityName": "KIRLOSKAR INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE250A01039",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500245",
                "SecurityId": "KIRLFER",
                "SecurityName": "KIRLOSKAR FERROUS INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE884B01025",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500246",
                "SecurityId": "ENVAIREL",
                "SecurityName": "ENVAIR ELECTRODYNE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE601C01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500247",
                "SecurityId": "KOTAKBANK",
                "SecurityName": "KOTAK MAHINDRA BANK LTD.",
                "FaceValue": "5",
                "ISINNo": "INE237A01028",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500248",
                "SecurityId": "KRIFILIND",
                "SecurityName": "KRISHNA FILAMENT INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE073A01019",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500249",
                "SecurityId": "KSB",
                "SecurityName": "KSB LTD",
                "FaceValue": "10",
                "ISINNo": "INE999A01015",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500250",
                "SecurityId": "LGBBROSLTD",
                "SecurityName": "L.G.BALAKRISHNAN & BROS.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE337A01034",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500251",
                "SecurityId": "TRENT",
                "SecurityName": "TRENT LTD.",
                "FaceValue": "1",
                "ISINNo": "INE849A01020",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500252",
                "SecurityId": "LAXMIMACH",
                "SecurityName": "LAKSHMI MACHINE WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE269B01029",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500253",
                "SecurityId": "LICHSGFIN",
                "SecurityName": "LIC HOUSING FINANCE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE115A01026",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500254",
                "SecurityId": "UTTAMVALUE",
                "SecurityName": "UTTAM VALUE STEELS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE292A01023",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500255",
                "SecurityId": "LML",
                "SecurityName": "LML LTD.",
                "FaceValue": "10",
                "ISINNo": "INE862A01015",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500256",
                "SecurityId": "LOKHSG",
                "SecurityName": "LOK HOUSING & CONSTRUCTIONS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE367C01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500257",
                "SecurityId": "LUPIN",
                "SecurityName": "LUPIN LTD.",
                "FaceValue": "2",
                "ISINNo": "INE326A01037",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500258",
                "SecurityId": "LUPINDM",
                "SecurityName": "LUPIN LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE327A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500259",
                "SecurityId": "LYKALABS",
                "SecurityName": "LYKA LABS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE933A01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500260",
                "SecurityId": "RAMCOCEM",
                "SecurityName": "THE RAMCO CEMENTS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE331A01037",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500261",
                "SecurityId": "MDRPTRO",
                "SecurityName": "MADRAS PETRO-CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500262",
                "SecurityId": "MAFATLAFIN",
                "SecurityName": "MAFATLAL FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE965B01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500264",
                "SecurityId": "MAFATIND",
                "SecurityName": "MAFATLAL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE270B01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500265",
                "SecurityId": "MAHSEAMLES",
                "SecurityName": "MAHARASHTRA SEAMLESS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE271B01025",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500266",
                "SecurityId": "MAHSCOOTER",
                "SecurityName": "MAHARASHTRA SCOOTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE288A01013",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500267",
                "SecurityId": "MAJESAUT",
                "SecurityName": "MAJESTIC AUTO LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE201B01022",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500268",
                "SecurityId": "MANALIPETC",
                "SecurityName": "MANALI PETROCHEMICAL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE201A01024",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500269",
                "SecurityId": "MARDIACH",
                "SecurityName": "MARDIA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE273B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500270",
                "SecurityId": "BARODARY",
                "SecurityName": "BARODA RAYON CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE461A01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500271",
                "SecurityId": "MFSL",
                "SecurityName": "MAX FINANCIAL SERVICES LTD",
                "FaceValue": "2",
                "ISINNo": "INE180A01020",
                "Industry": "Life Insurance",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500272",
                "SecurityId": "MAXWORC-B",
                "SecurityName": "MAXWORTH ORCHARDS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500273",
                "SecurityId": "MCDWLDM",
                "SecurityName": "MCDWLDM",
                "FaceValue": "10",
                "ISINNo": "INE887A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500274",
                "SecurityId": "MESPHAR-B",
                "SecurityName": "MESCO PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE839X01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500275",
                "SecurityId": "MTZIND",
                "SecurityName": "MTZ INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE865A01018",
                "Industry": "Other Non-Ferrous Metals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500276",
                "SecurityId": "MICROPL-B",
                "SecurityName": "MICRO PLANTAE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500277",
                "SecurityId": "MIDINDIA",
                "SecurityName": "MID INDIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE401C01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500278",
                "SecurityId": "MIDESTI-B",
                "SecurityName": "MIDEAST (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE844X01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500279",
                "SecurityId": "MIRCELECTR",
                "SecurityName": "MIRC ELECTRONICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE831A01028",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500280",
                "SecurityId": "CENTENKA",
                "SecurityName": "CENTURY ENKA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE485A01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500282",
                "SecurityId": "MDRNTHR-B",
                "SecurityName": "MODERN THREADS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE794W01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500283",
                "SecurityId": "MDTERYT-B",
                "SecurityName": "MODERN TERRY TOWELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500284",
                "SecurityId": "LORDSCHLO",
                "SecurityName": "LORDS CHLORO ALKALI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE846D01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500285",
                "SecurityId": "SPICEJET",
                "SecurityName": "SPICEJET LTD.",
                "FaceValue": "10",
                "ISINNo": "INE285B01017",
                "Industry": "Airlines",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500286",
                "SecurityId": "MONTA",
                "SecurityName": "MONTARI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE407C01015",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500288",
                "SecurityId": "MOREPENLAB",
                "SecurityName": "MOREPEN LABORATORIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE083A01026",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500289",
                "SecurityId": "MSGF",
                "SecurityName": "MORGAN STANLEY GROWTH FUND",
                "FaceValue": "10",
                "ISINNo": "INF063A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500290",
                "SecurityId": "MRF",
                "SecurityName": "MRF LTD.",
                "FaceValue": "10",
                "ISINNo": "INE883A01011",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500292",
                "SecurityId": "HEIDELBERG",
                "SecurityName": "HEIDELBERGCEMENT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE578A01017",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500293",
                "SecurityId": "MYSRKIR-B",
                "SecurityName": "MYSORE KIRLOSKAR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500294",
                "SecurityId": "NCC",
                "SecurityName": "NCC LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE868B01028",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500295",
                "SecurityId": "VEDL",
                "SecurityName": "VEDANTA LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE205A01025",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500296",
                "SecurityId": "NAHARSPING",
                "SecurityName": "NAHAR SPINNING MILLS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE290A01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500297",
                "SecurityId": "NATAL",
                "SecurityName": "NATAL",
                "FaceValue": "10",
                "ISINNo": "INE139A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500298",
                "SecurityId": "NATPEROX",
                "SecurityName": "NATIONAL PEROXIDE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE585A01020",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500300",
                "SecurityId": "GRASIM",
                "SecurityName": "GRASIM INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE047A01021",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500301",
                "SecurityId": "NEPCMICON",
                "SecurityName": "NEPC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE588A01016",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500302",
                "SecurityId": "PEL",
                "SecurityName": "PIRAMAL ENTERPRISES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE140A01024",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500303",
                "SecurityId": "ABIRLANUVO",
                "SecurityName": "ADITYA BIRLA NUVO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE069A01017",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500304",
                "SecurityId": "NIITLTD",
                "SecurityName": "NIIT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE161A01038",
                "Industry": "IT Training Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500305",
                "SecurityId": "JSWISPAT",
                "SecurityName": "JSW ISPAT STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE136A01022",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500306",
                "SecurityId": "JAYKAY",
                "SecurityName": "JAYKAY ENTERPRISES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE903A01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500307",
                "SecurityId": "NIRLON",
                "SecurityName": "NIRLON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE910A01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500308",
                "SecurityId": "NIRMA",
                "SecurityName": "NIRMA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE091A01029",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500309",
                "SecurityId": "NOVAEL-",
                "SecurityName": "NOVA ELECTRO MAGNETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500310",
                "SecurityId": "NOVOPANIND",
                "SecurityName": "NOVOPAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE460B01016",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500311",
                "SecurityId": "NUCHEM",
                "SecurityName": "NUCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE946A01016",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500312",
                "SecurityId": "ONGC",
                "SecurityName": "OIL AND NATURAL GAS CORPORATION LTD",
                "FaceValue": "5",
                "ISINNo": "INE213A01029",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500313",
                "SecurityId": "OILCOUNTUB",
                "SecurityName": "OIL COUNTRY TUBULAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE591A01010",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500314",
                "SecurityId": "ORIENTHOT",
                "SecurityName": "ORIENTAL HOTELS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE750A01020",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500315",
                "SecurityId": "ORIENTBANK",
                "SecurityName": "ORIENTAL BANK OF COMMERCE",
                "FaceValue": "10",
                "ISINNo": "INE141A01014",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500317",
                "SecurityId": "OSWALAGRO",
                "SecurityName": "OSWAL AGRO MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE142A01012",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500318",
                "SecurityId": "OSSWM",
                "SecurityName": "OSWAL SPINNING & WEAVING MILLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE716C01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500319",
                "SecurityId": "INDSUCR",
                "SecurityName": "INDIAN SUCROSE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE557C01017",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500321",
                "SecurityId": "PADMINIT",
                "SecurityName": "PADMINI TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE114B01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500322",
                "SecurityId": "PANCM",
                "SecurityName": "PANYAM CEMENTS & MINERAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE167E01029",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500323",
                "SecurityId": "PAREKHPLAT",
                "SecurityName": "PAREKH PLATINUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE598A01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500325",
                "SecurityId": "RELIANCE",
                "SecurityName": "RELIANCE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE002A01018",
                "Industry": "Integrated Oil & Gas",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500326",
                "SecurityId": "PATHEJA-B",
                "SecurityName": "PATHEJA FORGINGS & AUTO PARTS MANUFACTURERS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500327",
                "SecurityId": "PILITA",
                "SecurityName": "PIL ITALICA LIFESTYLE LTD",
                "FaceValue": "1",
                "ISINNo": "INE600A01035",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500328",
                "SecurityId": "PENTFOR-B",
                "SecurityName": "PENTAFOUR PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500329",
                "SecurityId": "PENTAGRAPH",
                "SecurityName": "PENTAMEDIA GRAPHICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE202A01022",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500330",
                "SecurityId": "RAYMOND",
                "SecurityName": "RAYMOND LTD.",
                "FaceValue": "10",
                "ISINNo": "INE301A01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500331",
                "SecurityId": "PIDILITIND",
                "SecurityName": "PIDILITE INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE318A01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500332",
                "SecurityId": "PITICEM-B",
                "SecurityName": "PITTIE CEMENTS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500333",
                "SecurityId": "PIXTRANS",
                "SecurityName": "PIX TRANSMISSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE751B01018",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500334",
                "SecurityId": "PRMRVNY-B",
                "SecurityName": "PREMIER VINYL FLOORING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500335",
                "SecurityId": "BIRLACORPN",
                "SecurityName": "BIRLA CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE340A01012",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500336",
                "SecurityId": "SURYAROSNI",
                "SecurityName": "SURYA ROSHNI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE335A01012",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500337",
                "SecurityId": "PRIMESECU",
                "SecurityName": "PRIME SECURITIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE032B01021",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500338",
                "SecurityId": "PRSMJOHNSN",
                "SecurityName": "PRISM JOHNSON LTD",
                "FaceValue": "10",
                "ISINNo": "INE010A01011",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500339",
                "SecurityId": "RAIN",
                "SecurityName": "RAIN INDUSTRIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE855B01025",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500340",
                "SecurityId": "ITBHPDM",
                "SecurityName": "ITC BHADRACHALAM PAPERBOARDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE076A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500341",
                "SecurityId": "PRUDCAP-BDM",
                "SecurityName": "PRUDENTIAL CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500342",
                "SecurityId": "PRUDMOULI",
                "SecurityName": "PRUDENTIAL SUGAR CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE024D01016",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500343",
                "SecurityId": "AMJLAND",
                "SecurityName": "AMJ LAND HOLDINGS LTD",
                "FaceValue": "2",
                "ISINNo": "INE606A01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500344",
                "SecurityId": "PUNJABTRAC",
                "SecurityName": "PUNJAB TRACTORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE170A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500345",
                "SecurityId": "PPOWER-",
                "SecurityName": "PPOWER-",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500346",
                "SecurityId": "PUNJCOMMU",
                "SecurityName": "PUNJAB COMMUNICATIONS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE609A01010",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500347",
                "SecurityId": "PUNWRDM",
                "SecurityName": "PUNJAB WIRELESS SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE181A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500348",
                "SecurityId": "QLTYSTL",
                "SecurityName": "QUALITY STEEL & FORGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500349",
                "SecurityId": "RASIC",
                "SecurityName": "RASIC",
                "FaceValue": "10",
                "ISINNo": "INE231A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500350",
                "SecurityId": "RSWM",
                "SecurityName": "RSWM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE611A01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500351",
                "SecurityId": "RAJPOTU",
                "SecurityName": "RAJASTHAN POLYVIN TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500352",
                "SecurityId": "RAJTOOL",
                "SecurityName": "RAJASTHAN TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500353",
                "SecurityId": "RAJNSTL-B",
                "SecurityName": "RAJINDER STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500354",
                "SecurityId": "RAJSREESUG",
                "SecurityName": "RAJSHREE SUGARS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE562B01019",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500355",
                "SecurityId": "RALLIS",
                "SecurityName": "RALLIS INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE613A01020",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500356",
                "SecurityId": "RAMANEWS",
                "SecurityName": "SHREE RAMA NEWSPRINT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE278B01020",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500357",
                "SecurityId": "RAMAPPR-B",
                "SecurityName": "RAMA PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE425E01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500358",
                "SecurityId": "RAMAPETRO",
                "SecurityName": "RAMA PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE783A01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500359",
                "SecurityId": "RANBAXY",
                "SecurityName": "RANBAXY LABORATORIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE015A01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500360",
                "SecurityId": "RAPICUT",
                "SecurityName": "RAPICUT CARBIDES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE350D01015",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500361",
                "SecurityId": "RASPRO",
                "SecurityName": "RAS PROPACK LAMIPACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE279B01028",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500362",
                "SecurityId": "RECRON",
                "SecurityName": "RECRON SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE616A01023",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500363",
                "SecurityId": "REVAP",
                "SecurityName": "REAL VALUE APPLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE980C01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500364",
                "SecurityId": "REPETDM",
                "SecurityName": "RELIANCE PETROLEUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE014A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500365",
                "SecurityId": "WELSPLSOL",
                "SecurityName": "WELSPUN SPECIALTY SOLUTIONS LTD",
                "FaceValue": "6",
                "ISINNo": "INE731F01037",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500366",
                "SecurityId": "ROLTA",
                "SecurityName": "ROLTA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE293A01013",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500367",
                "SecurityId": "RUBFILA",
                "SecurityName": "RUBFILA INTERNATIONAL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE642C01025",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500368",
                "SecurityId": "RUCHISOYA",
                "SecurityName": "RUCHI SOYA INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE619A01035",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500369",
                "SecurityId": "RPGTRANS",
                "SecurityName": "RPG TRANSMISSION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE621A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500370",
                "SecurityId": "SALORAINTL",
                "SecurityName": "SALORA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE924A01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500371",
                "SecurityId": "SAMTELIN",
                "SecurityName": "SAMTEL INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE538C01017",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500372",
                "SecurityId": "SAMTEL",
                "SecurityName": "SAMTEL COLOR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE381A01016",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500373",
                "SecurityId": "CLARIANT",
                "SecurityName": "CLARIANT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE221A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500374",
                "SecurityId": "SANAS",
                "SecurityName": "SANDVIK ASIA LTD.",
                "FaceValue": "100",
                "ISINNo": "INE624A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500375",
                "SecurityId": "SANGHIPOLY",
                "SecurityName": "SANGHI POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE146A01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500376",
                "SecurityId": "SATYAM",
                "SecurityName": "SATYAM COMPUTER SERVICES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE275A01028",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500377",
                "SecurityId": "SHREEJIPHOS",
                "SecurityName": "SHREEJI PHOSPHATE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE157C01016",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500378",
                "SecurityId": "JINDALSAW",
                "SecurityName": "JINDAL SAW LTD.",
                "FaceValue": "2",
                "ISINNo": "INE324A01024",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500379",
                "SecurityId": "SBIHOMEFIN",
                "SecurityName": "SBI HOME FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE627A01012",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500380",
                "SecurityId": "JKLAKSHMI",
                "SecurityName": "JK LAKSHMI CEMENT LTD.",
                "FaceValue": "5",
                "ISINNo": "INE786A01032",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500382",
                "SecurityId": "SBIMGNE-B",
                "SecurityName": "SBIMGNE-B",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500384",
                "SecurityId": "BRABOURNE",
                "SecurityName": "BRABOURNE ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE629A01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500386",
                "SecurityId": "SHAMKNSPIN",
                "SecurityName": "SHAMKEN SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE626B01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500387",
                "SecurityId": "SHREECEM",
                "SecurityName": "SHREE CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE070A01015",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500388",
                "SecurityId": "SKPMIL",
                "SecurityName": "SHREE KRISHNA PAPER MILLS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE970C01012",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500389",
                "SecurityId": "SILVERLINE",
                "SecurityName": "SILVERLINE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE368A01021",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500390",
                "SecurityId": "RELINFRA",
                "SecurityName": "RELIANCE INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE036A01016",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500391",
                "SecurityId": "SMDYECH",
                "SecurityName": "SM DYECHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE620A01025",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500393",
                "SecurityId": "SOLPHR--Z",
                "SecurityName": "SOL PHARMACEUTICAL LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500394",
                "SecurityId": "SOLCT",
                "SecurityName": "SOLID CARBIDE TOOLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE161C01026",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500395",
                "SecurityId": "SOUTHNHE",
                "SecurityName": "SOUTHERN HERBALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE001C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500396",
                "SecurityId": "TASSLDM",
                "SecurityName": "TATA SSL LTD.",
                "FaceValue": "100",
                "ISINNo": "INE675A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500397",
                "SecurityId": "HENKELSPIC",
                "SecurityName": "HENKEL SPIC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE902A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500398",
                "SecurityId": "GECAP",
                "SecurityName": "GE CAPITAL TRANSPORTATION FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE698D01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500399",
                "SecurityId": "STEELCO",
                "SecurityName": "STEELCO GUJARAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE629B01024",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500400",
                "SecurityId": "TATAPOWER",
                "SecurityName": "TATA POWER CO.LTD.",
                "FaceValue": "1",
                "ISINNo": "INE245A01021",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500402",
                "SecurityId": "SPMLINFRA",
                "SecurityName": "SPML INFRA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE937A01023",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500403",
                "SecurityId": "SUNDRMFAST",
                "SecurityName": "SUNDRAM FASTENERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE387A01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500404",
                "SecurityId": "SUNFLAG",
                "SecurityName": "SUNFLAG IRON & STEEL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE947A01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500405",
                "SecurityId": "SUPPETRO",
                "SecurityName": "SUPREME PETROCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE663A01017",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500406",
                "SecurityId": "SURYAGRO",
                "SecurityName": "SURYA AGROILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE780B01017",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500407",
                "SecurityId": "SWARAJENG",
                "SecurityName": "SWARAJ ENGINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE277A01016",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500408",
                "SecurityId": "TATAELXSI",
                "SecurityName": "TATA ELXSI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE670A01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500409",
                "SecurityId": "TAHYDDM",
                "SecurityName": "TATA HYDRO ELECTRIC POWER SUPPLY CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE246A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500410",
                "SecurityId": "ACC",
                "SecurityName": "ACC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE012A01025",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500411",
                "SecurityId": "THERMAX",
                "SecurityName": "THERMAX LTD.",
                "FaceValue": "2",
                "ISINNo": "INE152A01029",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500412",
                "SecurityId": "TIRUMALCHM",
                "SecurityName": "THIRUMALAI CHEMICALS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE338A01024",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500413",
                "SecurityId": "THOMASCOOK",
                "SecurityName": "THOMAS COOK (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE332A01027",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500414",
                "SecurityId": "TIMEX",
                "SecurityName": "TIMEX GROUP INDIA LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE064A01026",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500415",
                "SecurityId": "TISCSPN-B",
                "SecurityName": "TISCSPN-B",
                "FaceValue": "300",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500416",
                "SecurityId": "TITAGRST",
                "SecurityName": "TITAGARH STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE824A01023",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500417",
                "SecurityId": "TITANAL",
                "SecurityName": "TITAN ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500418",
                "SecurityId": "TOKYOPLAST",
                "SecurityName": "TOKYO PLAST INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE932C01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500419",
                "SecurityId": "TOLANDM",
                "SecurityName": "TOLANI BULK CARRIERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE051C01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500420",
                "SecurityId": "TORNTPHARM",
                "SecurityName": "TORRENT PHARMACEUTICALS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE685A01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500421",
                "SecurityId": "TPINDIA",
                "SecurityName": "TPI INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE578C01021",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500422",
                "SecurityId": "TRANSCHEM",
                "SecurityName": "TRANSCHEM LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE019B01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500423",
                "SecurityId": "TVSEL",
                "SecurityName": "TVS ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE380A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500424",
                "SecurityId": "TVSSZDM",
                "SecurityName": "TVSSZDM",
                "FaceValue": "10",
                "ISINNo": "INE106A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500425",
                "SecurityId": "AMBUJACEM",
                "SecurityName": "AMBUJA CEMENTS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE079A01024",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500426",
                "SecurityId": "UTLINDS",
                "SecurityName": "UTL INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE184E01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500427",
                "SecurityId": "UNIFLEX",
                "SecurityName": "UNIFLEX CABLES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE146B01011",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500428",
                "SecurityId": "UNIPLAS",
                "SecurityName": "UNIPLAS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500429",
                "SecurityId": "UNIPHOS",
                "SecurityName": "UNIPHOS ENTERPRISES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE037A01022",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500430",
                "SecurityId": "UNITEDWESTB",
                "SecurityName": "UNITED WESTERN BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE165A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500431",
                "SecurityId": "USHAINDIA",
                "SecurityName": "USHA (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE068A01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500432",
                "SecurityId": "USHAISPAT",
                "SecurityName": "USHA ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE150A01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500433",
                "SecurityId": "UTIGN",
                "SecurityName": "UTIGN",
                "FaceValue": "10",
                "ISINNo": "INF189A01079",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500434",
                "SecurityId": "MSTGN",
                "SecurityName": "MSTGN",
                "FaceValue": "10",
                "ISINNo": "INF189A01053",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500435",
                "SecurityId": "UMISPST",
                "SecurityName": "UMI SPECIAL STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500436",
                "SecurityId": "UTI2000-B",
                "SecurityName": "UGS- 2000-OPEN ENDED",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500437",
                "SecurityId": "UTI5000-B",
                "SecurityName": "UGS-5000-OPEN ENDED",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500438",
                "SecurityId": "VAJRABE",
                "SecurityName": "VAJRA BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE978B01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500439",
                "SecurityId": "VHL",
                "SecurityName": "VARDHMAN HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE701A01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500440",
                "SecurityId": "HINDALCO",
                "SecurityName": "HINDALCO INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE038A01020",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500442",
                "SecurityId": "VIRALFL-BDM",
                "SecurityName": "VIRAL FILAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500443",
                "SecurityId": "WARTSILA",
                "SecurityName": "WARTSILA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE057A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500444",
                "SecurityId": "WSTCSTPAPR",
                "SecurityName": "WEST COAST PAPER MILLS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE976A01021",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500445",
                "SecurityId": "INDAL",
                "SecurityName": "INDIAN ALUMINIUM CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE249A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500446",
                "SecurityId": "CAROL",
                "SecurityName": "CAROL INFO SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE198A01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500447",
                "SecurityId": "YOGIPHA",
                "SecurityName": "YOGI PHARMACY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500448",
                "SecurityId": "HINDC",
                "SecurityName": "HINDUSTAN DEVELOPMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE550A01016",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500449",
                "SecurityId": "HOCL",
                "SecurityName": "HINDUSTAN ORGANIC CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE048A01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500450",
                "SecurityId": "MPILCORPL",
                "SecurityName": "MPIL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE844C01027",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500451",
                "SecurityId": "MDRNSUT-B",
                "SecurityName": "MODERN DENIM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE01N301019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500452",
                "SecurityId": "NEPCAGRO",
                "SecurityName": "NEPC AGRO FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE587A01018",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500455",
                "SecurityId": "PAAMPHR-B",
                "SecurityName": "PAAM PHARMACEUTICALS (DELHI) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500456",
                "SecurityId": "PASUPTAC",
                "SecurityName": "PASUPATI ACRYLON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE818B01023",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500457",
                "SecurityId": "PNTASOL-B",
                "SecurityName": "PENTAFOUR SOLEC TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500458",
                "SecurityId": "KORE",
                "SecurityName": "KORE FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE601A01017",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500459",
                "SecurityId": "PGHH",
                "SecurityName": "PROCTER & GAMBLE HYGIENE & HEALTH CARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE179A01014",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500460",
                "SecurityId": "MUKANDLTD",
                "SecurityName": "MUKAND LTD.",
                "FaceValue": "10",
                "ISINNo": "INE304A01026",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500462",
                "SecurityId": "SIV",
                "SecurityName": "SIV INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE639A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500463",
                "SecurityId": "AGCNET",
                "SecurityName": "AGC NETWORKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE676A01019",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500464",
                "SecurityId": "UCALFUEL",
                "SecurityName": "UCAL FUEL SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE139B01016",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500465",
                "SecurityId": "VARUNSHIP",
                "SecurityName": "VARUN SHIPPING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE702A01013",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500466",
                "SecurityId": "STERHFN",
                "SecurityName": "STERLING HOLIDAY FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500467",
                "SecurityId": "HARRMALAYA",
                "SecurityName": "HARRISONS MALAYALAM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE544A01019",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500468",
                "SecurityId": "WESTPAQ-B",
                "SecurityName": "WESTERN PAQUES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500469",
                "SecurityId": "FEDERALBNK",
                "SecurityName": "FEDERAL BANK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE171A01029",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500470",
                "SecurityId": "TATASTEEL",
                "SecurityName": "TATA STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE081A01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500472",
                "SecurityId": "SKFINDIA",
                "SecurityName": "SKF INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE640A01023",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500477",
                "SecurityId": "ASHOKLEY",
                "SecurityName": "ASHOK LEYLAND LTD.",
                "FaceValue": "1",
                "ISINNo": "INE208A01029",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500480",
                "SecurityId": "CUMMINSIND",
                "SecurityName": "CUMMINS INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE298A01020",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500483",
                "SecurityId": "TATACOMM",
                "SecurityName": "TATA COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE151A01013",
                "Industry": "Telecom - Alternate Carriers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500488",
                "SecurityId": "ABBOTINDIA",
                "SecurityName": "ABBOTT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE358A01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500490",
                "SecurityId": "BAJAJHLDNG",
                "SecurityName": "BAJAJ HOLDINGS & INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE118A01012",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500493",
                "SecurityId": "BHARATFORG",
                "SecurityName": "BHARAT FORGE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE465A01025",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500495",
                "SecurityId": "ESCORTS",
                "SecurityName": "ESCORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE042A01014",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500500",
                "SecurityId": "HINDMOTORS",
                "SecurityName": "HINDUSTAN MOTORS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE253A01025",
                "Industry": "Cars & Utility Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500510",
                "SecurityId": "LT",
                "SecurityName": "LARSEN & TOUBRO LTD.",
                "FaceValue": "2",
                "ISINNo": "INE018A01030",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500520",
                "SecurityId": "M&M",
                "SecurityName": "MAHINDRA & MAHINDRA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE101A01026",
                "Industry": "Cars & Utility Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500530",
                "SecurityId": "BOSCHLTD",
                "SecurityName": "BOSCH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE323A01026",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500540",
                "SecurityId": "PREMIER",
                "SecurityName": "PREMIER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE342A01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500547",
                "SecurityId": "BPCL",
                "SecurityName": "BHARAT PETROLEUM CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE029A01011",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500550",
                "SecurityId": "SIEMENS",
                "SecurityName": "SIEMENS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE003A01024",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500560",
                "SecurityId": "PEICO",
                "SecurityName": "PHILIPS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE319A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500570",
                "SecurityId": "TATAMOTORS",
                "SecurityName": "TATA MOTORS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE155A01022",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500575",
                "SecurityId": "VOLTAS",
                "SecurityName": "VOLTAS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE226A01021",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500620",
                "SecurityId": "GESHIP",
                "SecurityName": "GREAT EASTERN SHIPPING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE017A01032",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500627",
                "SecurityId": "ESSARSTEEL",
                "SecurityName": "ESSAR STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE127A01021",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500630",
                "SecurityId": "ESSARPORTS",
                "SecurityName": "ESSAR PORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE282A01024",
                "Industry": "Marine Port & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500645",
                "SecurityId": "DEEPAKFERT",
                "SecurityName": "DEEPAK FERTILISERS & PETROCHEMICALS CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE501A01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500647",
                "SecurityId": "SMIPHDM",
                "SecurityName": "SMITHKLINE BEECHAM PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE266A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500650",
                "SecurityId": "EXCELINDUS",
                "SecurityName": "EXCEL INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE369A01029",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500655",
                "SecurityId": "GARWARPOLY",
                "SecurityName": "GARWARE POLYESTER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE291A01017",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500660",
                "SecurityId": "GLAXO",
                "SecurityName": "GLAXOSMITHKLINE PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE159A01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500670",
                "SecurityId": "GNFC",
                "SecurityName": "GUJARAT NARMADA VALLEY FERTILIZERS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE113A01013",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500672",
                "SecurityId": "NOVARTIND",
                "SecurityName": "NOVARTIS INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE234A01025",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500674",
                "SecurityId": "SANOFI",
                "SecurityName": "SANOFI INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE058A01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500676",
                "SecurityId": "GSKCONS",
                "SecurityName": "GLAXOSMITHKLINE CONSUMER HEALTHCARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE264A01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500677",
                "SecurityId": "PARKD",
                "SecurityName": "PARKE DAVIS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE254A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500680",
                "SecurityId": "PFIZER",
                "SecurityName": "PFIZER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE182A01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500690",
                "SecurityId": "GSFC",
                "SecurityName": "GUJARAT STATE FERTILIZERS & CHEMICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE026A01025",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500696",
                "SecurityId": "HINDUNILVR",
                "SecurityName": "HINDUSTAN UNILEVER LTD.",
                "FaceValue": "1",
                "ISINNo": "INE030A01027",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500710",
                "SecurityId": "AKZOINDIA",
                "SecurityName": "AKZO NOBEL INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE133A01011",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500720",
                "SecurityId": "FUTURAPOLY",
                "SecurityName": "FUTURA POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE564A01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500723",
                "SecurityId": "IGLCRDM",
                "SecurityName": "INDO GULF CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE022A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500730",
                "SecurityId": "NOCIL",
                "SecurityName": "NOCIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE163A01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500750",
                "SecurityId": "RCKBEDM",
                "SecurityName": "RECKITT BENCKISER (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE274A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500758",
                "SecurityId": "SPIC",
                "SecurityName": "SOUTHERN PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE147A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500770",
                "SecurityId": "TATACHEM",
                "SecurityName": "TATA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE092A01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500777",
                "SecurityId": "TNPETRO",
                "SecurityName": "TAMILNADU PETROPRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE148A01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500780",
                "SecurityId": "ZUARIGLOB",
                "SecurityName": "ZUARI GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE217A01012",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500790",
                "SecurityId": "NESTLEIND",
                "SecurityName": "NESTLE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE239A01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500793",
                "SecurityId": "CADBRDM",
                "SecurityName": "CADBURY INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE184A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500800",
                "SecurityId": "TATACONSUM",
                "SecurityName": "TATA CONSUMER PRODUCTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE192A01025",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500820",
                "SecurityId": "ASIANPAINT",
                "SecurityName": "ASIAN PAINTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE021A01026",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500825",
                "SecurityId": "BRITANNIA",
                "SecurityName": "BRITANNIA INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE216A01030",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500830",
                "SecurityId": "COLPAL",
                "SecurityName": "COLGATE-PALMOLIVE (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE259A01022",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500840",
                "SecurityId": "EIHOTEL",
                "SecurityName": "EIH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE230A01023",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500850",
                "SecurityId": "INDHOTEL",
                "SecurityName": "INDIAN HOTELS CO.LTD.",
                "FaceValue": "1",
                "ISINNo": "INE053A01029",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500870",
                "SecurityId": "CASTROLIND",
                "SecurityName": "CASTROL INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE172A01027",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500873",
                "SecurityId": "KOCHIREF",
                "SecurityName": "KOCHI REFINERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE123A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500875",
                "SecurityId": "ITC",
                "SecurityName": "ITC LTD.",
                "FaceValue": "1",
                "ISINNo": "INE154A01025",
                "Industry": "Cigarettes-Tobacco Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500877",
                "SecurityId": "APOLLOTYRE",
                "SecurityName": "APOLLO TYRES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE438A01022",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500878",
                "SecurityId": "CEATLTD",
                "SecurityName": "CEAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE482A01020",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500888",
                "SecurityId": "JAIPRAKASH",
                "SecurityName": "JAI PRAKASH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE575A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500890",
                "SecurityId": "MODIRUBBER",
                "SecurityName": "MODI RUBBER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE832A01018",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500900",
                "SecurityId": "STERLITEIND",
                "SecurityName": "STERLITE INDUSTRIES (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE268A01049",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500940",
                "SecurityId": "FINOLEXIND",
                "SecurityName": "FINOLEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE183A01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500945",
                "SecurityId": "VALUEIND",
                "SecurityName": "VALUE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE352A01017",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500947",
                "SecurityId": "VIDEOCONINT",
                "SecurityName": "VIDEOCON INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE341A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500950",
                "SecurityId": "WIMCO",
                "SecurityName": "WIMCO LTD.",
                "FaceValue": "1",
                "ISINNo": "INE333A01025",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500960",
                "SecurityId": "SBIMG90-B",
                "SecurityName": "SBIMG90-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "500999",
                "SecurityId": "MSTRSDM",
                "SecurityName": "MASTERSHARES-86",
                "FaceValue": "10",
                "ISINNo": "INF189A01038",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501061",
                "SecurityId": "SBBJ",
                "SecurityName": "STATE BANK OF BIKANER & JAIPUR",
                "FaceValue": "10",
                "ISINNo": "INE648A01026",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501110",
                "SecurityId": "SUNRINV",
                "SecurityName": "SUNRISE INDUSTRIAL TRADERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE371U01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501111",
                "SecurityId": "ZGOLDINV",
                "SecurityName": "GOLD ROCK INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE598F01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501115",
                "SecurityId": "ZMIPCOIN",
                "SecurityName": "MIPCO INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501125",
                "SecurityId": "ZOCEAINV",
                "SecurityName": "OCEANIC INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501144",
                "SecurityId": "PEOPLIN",
                "SecurityName": "PEOPLES INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE644U01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501146",
                "SecurityId": "ZBHAVIIN",
                "SecurityName": "BHAVI INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501148",
                "SecurityId": "DSINVEST",
                "SecurityName": "DALAL STREET INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE422D01012",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501150",
                "SecurityId": "CENTRUM",
                "SecurityName": "CENTRUM CAPITAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE660C01027",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501151",
                "SecurityId": "KARTKIN",
                "SecurityName": "KARTIK INVESTMENTS TRUST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE524U01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501154",
                "SecurityId": "ROSEI",
                "SecurityName": "ROSE INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE383D01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501178",
                "SecurityId": "ZSURYODI",
                "SecurityName": "SURYODAYA INVESTMENT & TRADING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501179",
                "SecurityId": "OSCAR",
                "SecurityName": "OSCAR INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE221D01018",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501209",
                "SecurityId": "MAESTROM",
                "SecurityName": "MAESTROS MEDILINE SYSTEMS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE408E01027",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501233",
                "SecurityId": "BIDL",
                "SecurityName": "BHAGYODAYA INFRASTRUCTURE DEVELOPMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE876D01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501242",
                "SecurityId": "TCIFINANCE",
                "SecurityName": "TCI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE911B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501254",
                "SecurityId": "SAGARIN",
                "SecurityName": "SAGAR INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501261",
                "SecurityId": "KRATOSENER",
                "SecurityName": "KRATOS ENERGY & INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE567L01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501267",
                "SecurityId": "SHRERAN",
                "SecurityName": "SHREE RANI SATI INVESTMENT & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501270",
                "SecurityId": "ANTARIKSH",
                "SecurityName": "ANTARIKSH INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE825M01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501295",
                "SecurityId": "IITL",
                "SecurityName": "INDUSTRIAL INVESTMENT TRUST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE886A01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501298",
                "SecurityId": "INDPRUD",
                "SecurityName": "INDUSTRIAL & PRUDENTIAL INVESTMENTS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE620D01011",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501301",
                "SecurityId": "TATAINVEST",
                "SecurityName": "TATA INVESTMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE672A01018",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501311",
                "SecurityId": "JAYBHCR",
                "SecurityName": "JAYABHARAT CREDIT LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE998D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501314",
                "SecurityId": "PRISMX",
                "SecurityName": "PRISMX GLOBAL VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE286N01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501316",
                "SecurityId": "KILLICK",
                "SecurityName": "KILLICK NIXON LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE982A01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501318",
                "SecurityId": "KIRTIIN",
                "SecurityName": "KIRTI INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501328",
                "SecurityId": "HNDTRAN",
                "SecurityName": "HINDUSTAN TRANSMISSION PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501343",
                "SecurityId": "MOTOGENFIN",
                "SecurityName": "MOTOR & GENERAL FINANCE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE861B01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501351",
                "SecurityId": "RAPIDIN",
                "SecurityName": "RAPID INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE154M01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501355",
                "SecurityId": "ORINTIN",
                "SecurityName": "ORIENTAL INDUSTRIAL INVESTMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501368",
                "SecurityId": "GUJORGA",
                "SecurityName": "GUJARAT ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501370",
                "SecurityId": "WALCHPF",
                "SecurityName": "WALCHAND PEOPLEFIRST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE695D01021",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501371",
                "SecurityId": "ZURVICHM",
                "SecurityName": "URVI CHEMICALS & ALLIED INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501379",
                "SecurityId": "SHAWWALLACE",
                "SecurityName": "SHAW WALLACE & CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE402A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501386",
                "SecurityId": "ZSWASTSA",
                "SecurityName": "SWASTIK SAFE DEPOSIT & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE094R01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501391",
                "SecurityId": "WHBRADY",
                "SecurityName": "W.H.BRADY & CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE855A01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501413",
                "SecurityId": "AKASAGC",
                "SecurityName": "AKASH AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501415",
                "SecurityId": "ALERPTR",
                "SecurityName": "ALERT PETROGAS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501421",
                "SecurityId": "TECHNVISN",
                "SecurityName": "TECHNVISION VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE314H01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501423",
                "SecurityId": "SHAILY",
                "SecurityName": "SHAILY ENGINEERING PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE151G01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501425",
                "SecurityId": "BBTC",
                "SecurityName": "BOMBAY BURMAH TRADING CORP.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE050A01025",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501430",
                "SecurityId": "BOMBCYC",
                "SecurityName": "BOMBAY CYCLE & MOTOR AGENCY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE691K01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501455",
                "SecurityId": "GREAVESCOT",
                "SecurityName": "GREAVES COTTON LTD.",
                "FaceValue": "2",
                "ISINNo": "INE224A01026",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501458",
                "SecurityId": "MAFATDY",
                "SecurityName": "MAFATLAL DYES & CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE733D01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501469",
                "SecurityId": "BOSTON",
                "SecurityName": "BOSTON EDUCATION AND SOFTWARE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122C01010",
                "Industry": "IT Training Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501471",
                "SecurityId": "MACK",
                "SecurityName": "MACK TRADING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE436D01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501473",
                "SecurityId": "MALTC",
                "SecurityName": "MALABAR TRADING CO.LTD",
                "FaceValue": "10",
                "ISINNo": "INE438D01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501477",
                "SecurityId": "MULLER",
                "SecurityName": "MULLER & PHIPPS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE003F01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501482",
                "SecurityId": "PARDI",
                "SecurityName": "PAREKH DISTRIBUTORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE528D01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501500",
                "SecurityId": "UNITMOT",
                "SecurityName": "UNITED MOTORS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501578",
                "SecurityId": "SHDHOOT",
                "SecurityName": "SHREE DHOOT TRADING & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501622",
                "SecurityId": "AMALGAM",
                "SecurityName": "AMALGAMATED ELECTRICITY CO.LTD.",
                "FaceValue": "5",
                "ISINNo": "INE492N01022",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501630",
                "SecurityId": "ANANDPROJ",
                "SecurityName": "ANAND PROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE134R01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501636",
                "SecurityId": "ZANDHVRT",
                "SecurityName": "ZANDHVRT",
                "FaceValue": "100",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501700",
                "SecurityId": "INDIANVSH",
                "SecurityName": "INDIANIVESH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE131H01028",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501736",
                "SecurityId": "TPSEC",
                "SecurityName": "TORRENT POWER SEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE054B01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501756",
                "SecurityId": "THAES",
                "SecurityName": "THANA ELECTRIC SUPPLY CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE451E01019",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501815",
                "SecurityId": "BHARLIN",
                "SecurityName": "BHARAT LINE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501827",
                "SecurityId": "CENTPROV",
                "SecurityName": "CENTRAL PROVINCES RAILWAYS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE631B01038",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501831",
                "SecurityId": "COASTCORP",
                "SecurityName": "COASTAL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE377E01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501833",
                "SecurityId": "CHOWGULSTM",
                "SecurityName": "CHOWGULE STEAMSHIPS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE490A01015",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501848",
                "SecurityId": "GLOBOFFS",
                "SecurityName": "GLOBAL OFFSHORE SERVICES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE446C01013",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "501945",
                "SecurityId": "DHENUBUILD",
                "SecurityName": "DHENU BUILDCON INFRA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE758D01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502015",
                "SecurityId": "ASIIL",
                "SecurityName": "ASI INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE443A01030",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502038",
                "SecurityId": "MEWARMR",
                "SecurityName": "MEWAR MARBLES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502070",
                "SecurityId": "PANCHCE",
                "SecurityName": "PANCHAMAHAL CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502076",
                "SecurityId": "DHARCEM",
                "SecurityName": "DHAR CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502088",
                "SecurityId": "JANPRIY",
                "SecurityName": "JANPRIYA CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502090",
                "SecurityId": "SAGCEM",
                "SecurityName": "SAGAR CEMENTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE229C01013",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502133",
                "SecurityId": "HEMACEM",
                "SecurityName": "HEMADRI CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE07BK01011",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502137",
                "SecurityId": "DECCANCE",
                "SecurityName": "DECCAN CEMENTS LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE583C01021",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502150",
                "SecurityId": "KLYNCEM",
                "SecurityName": "KALYANPUR CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE991E01022",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502157",
                "SecurityId": "MANGLMCEM",
                "SecurityName": "MANGALAM CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE347A01017",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502162",
                "SecurityId": "NARMC",
                "SecurityName": "NARMADA CEMENT CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE344A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502165",
                "SecurityId": "OCL",
                "SecurityName": "OCL INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE290B01025",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502168",
                "SecurityId": "NCLIND",
                "SecurityName": "NCL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE732C01016",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502175",
                "SecurityId": "SAURASHCEM",
                "SecurityName": "SAURASHTRA CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE626A01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502180",
                "SecurityId": "SHREDIGCEM",
                "SecurityName": "SHREE DIGVIJAY CEMENT CO.LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE232A01011",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502187",
                "SecurityId": "ZSOMESCE",
                "SecurityName": "SOMESHWARA CEMENTS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502216",
                "SecurityId": "BOMBPOT",
                "SecurityName": "BOMBAY POTTERIES & TILES LTD.",
                "FaceValue": "100",
                "ISINNo": "INE06AE01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502219",
                "SecurityId": "BORORENEW",
                "SecurityName": "BOROSIL RENEWABLES LTD",
                "FaceValue": "1",
                "ISINNo": "INE666D01022",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502223",
                "SecurityId": "XCLGLASS",
                "SecurityName": "EXCEL GLASSES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE664C01029",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502241",
                "SecurityId": "IAG",
                "SecurityName": "IAG COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE831D01014",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502250",
                "SecurityId": "LERTHAI",
                "SecurityName": "LERTHAI FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE347D01011",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502255",
                "SecurityId": "NEYCERI",
                "SecurityName": "NEYCER INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE275N01013",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502260",
                "SecurityId": "ORSIND",
                "SecurityName": "ORISSA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502271",
                "SecurityId": "RASSIREF",
                "SecurityName": "RAASI REFRACTORIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE858D01017",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502273",
                "SecurityId": "SHVALGL",
                "SecurityName": "SHREE VALLABH GLASS WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502281",
                "SecurityId": "TRIVENIGQ",
                "SecurityName": "TRIVENI GLASS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE094C01011",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502294",
                "SecurityId": "NILACHAL",
                "SecurityName": "NILACHAL REFRACTORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416N01013",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502296",
                "SecurityId": "LAXMPOPDM",
                "SecurityName": "LAKSHMI PORCELEANS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502330",
                "SecurityId": "ANDHRAPAP",
                "SecurityName": "ANDHRA PAPER LTD",
                "FaceValue": "10",
                "ISINNo": "INE435A01028",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502335",
                "SecurityId": "JOLLNEW",
                "SecurityName": "JOLLY BOARD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE532G01011",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502352",
                "SecurityId": "AURANPAP",
                "SecurityName": "AURANGABAD PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE525C01014",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502355",
                "SecurityId": "BALKRISIND",
                "SecurityName": "BALKRISHNA INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE787D01026",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502369",
                "SecurityId": "COAPADM",
                "SecurityName": "COASTAL PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE531C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502374",
                "SecurityId": "ELLOPAP",
                "SecurityName": "ELLORA PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE384H01023",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502390",
                "SecurityId": "JYNTPPR-B",
                "SecurityName": "JAYANT PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502405",
                "SecurityId": "MYSPAPE",
                "SecurityName": "MYSORE PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE924F01012",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502407",
                "SecurityId": "NATHPULP",
                "SecurityName": "NATH PULP & PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE776A01025",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502420",
                "SecurityId": "ORIENTPPR",
                "SecurityName": "ORIENT PAPER & INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE592A01026",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502445",
                "SecurityId": "CITADEL",
                "SecurityName": "CITADEL REALTY AND DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE906D01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502448",
                "SecurityId": "ROLLT",
                "SecurityName": "ROLLATAINERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE927A01040",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502450",
                "SecurityId": "SESHAPAPER",
                "SecurityName": "SESHASAYEE PAPER & BOARDS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE630A01024",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502452",
                "SecurityId": "SHRVINDPPR",
                "SecurityName": "SHREE VINDHYA PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE210D01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502455",
                "SecurityId": "SIRPAPER",
                "SecurityName": "SIRPUR PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE202C01010",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502460",
                "SecurityId": "SOLIDCO",
                "SecurityName": "SOLID CONTAINERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE134U01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502465",
                "SecurityId": "SPECIAPP",
                "SecurityName": "SPECIALITY PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE260F01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502512",
                "SecurityId": "DHAMSUGR",
                "SecurityName": "DHAMPUR SUGAR (KASHIPUR) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE735A01013",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502520",
                "SecurityId": "VIDHAPA",
                "SecurityName": "VIDARBHA PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502550",
                "SecurityId": "SHIVAPPR",
                "SecurityName": "SHIVA PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE186D01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502563",
                "SecurityId": "SHBHAWPA",
                "SecurityName": "SHREE BHAWANI PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE688C01010",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502577",
                "SecurityId": "PAMWPAP",
                "SecurityName": "PAMVI TISSUES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502587",
                "SecurityId": "NATHIND",
                "SecurityName": "NATH INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE777A01023",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502589",
                "SecurityId": "VAPIPPR",
                "SecurityName": "VAPI PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE464D01014",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502595",
                "SecurityId": "KITPLY",
                "SecurityName": "KITPLY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE147B01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502700",
                "SecurityId": "ARUNODAY",
                "SecurityName": "ARUNODAY MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE469E01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502722",
                "SecurityId": "BANSTEX",
                "SecurityName": "BANSWARA TEXTILE MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502728",
                "SecurityId": "BELWSPG",
                "SecurityName": "BELWAL SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502742",
                "SecurityId": "SINTEX",
                "SecurityName": "SINTEX INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE429C01035",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502761",
                "SecurityId": "BLUBLND-B",
                "SecurityName": "BLUE BLENDS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE113O01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502813",
                "SecurityId": "ZCUDDSPG",
                "SecurityName": "CUDDAPAH SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502817",
                "SecurityId": "DAWNMILL",
                "SecurityName": "DAWN MILLS CO.LTD.",
                "FaceValue": "50",
                "ISINNo": "INE179E01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502820",
                "SecurityId": "DCM",
                "SecurityName": "DCM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE498A01018",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502823",
                "SecurityId": "DRBYTEX-B",
                "SecurityName": "DERBY TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502850",
                "SecurityId": "ZGAEKWAR",
                "SecurityName": "GAEKWAR MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE837X01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502856",
                "SecurityId": "GNDHISP",
                "SecurityName": "GANDHIDHAM SPINNING & MANUFACTURING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502865",
                "SecurityId": "FORBESCO",
                "SecurityName": "FORBES & COMPANY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE518A01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502873",
                "SecurityId": "HPCOTTON",
                "SecurityName": "H.P.COTTON TEXTILE MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE950C01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502880",
                "SecurityId": "HINDSPG",
                "SecurityName": "HINDOOSTAN SPINNING & WEAVING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE066D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502893",
                "SecurityId": "UNITEDINT",
                "SecurityName": "UNITED INTERACTIVE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE706D01018",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502901",
                "SecurityId": "JAMSHRI",
                "SecurityName": "JAMSHRI REALTY LTD-$",
                "FaceValue": "1000",
                "ISINNo": "INE462D01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502920",
                "SecurityId": "KCTXL-B1",
                "SecurityName": "K.C.TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502933",
                "SecurityId": "KATRSPG",
                "SecurityName": "KATARE SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE498G01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502937",
                "SecurityId": "KESORAMIND",
                "SecurityName": "KESORAM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE087A01019",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502943",
                "SecurityId": "KHATAU",
                "SecurityName": "KHATAU MAKANJI SPG.& WVG.CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502954",
                "SecurityId": "LDTXL",
                "SecurityName": "L.D.TEXTILE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502958",
                "SecurityId": "LAKSHMIMIL",
                "SecurityName": "LAKSHMI MILLS COMPANY LTD.-$",
                "FaceValue": "100",
                "ISINNo": "INE938C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502986",
                "SecurityId": "VTL",
                "SecurityName": "VARDHMAN TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE825A01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502991",
                "SecurityId": "MAHWRML",
                "SecurityName": "MAHESHWARI MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "502995",
                "SecurityId": "MALWACOTT",
                "SecurityName": "MALWA COTTON SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE272B01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503001",
                "SecurityId": "MATUSTX",
                "SecurityName": "MATUSHREE TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503015",
                "SecurityId": "MODERN",
                "SecurityName": "MODERN INDIA LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE251D01023",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503031",
                "SecurityId": "PENINLAND",
                "SecurityName": "PENINSULA LAND LTD.",
                "FaceValue": "2",
                "ISINNo": "INE138A01028",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503046",
                "SecurityId": "NAVSARI",
                "SecurityName": "NAVSARI COTTON & SILK MILLS LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503092",
                "SecurityId": "PASUSPG",
                "SecurityName": "PASUPATI SPG.& WVG.MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE909B01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503100",
                "SecurityId": "PHOENIXLTD",
                "SecurityName": "THE PHOENIX MILLS LTD",
                "FaceValue": "2",
                "ISINNo": "INE211B01039",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503101",
                "SecurityId": "MARATHON",
                "SecurityName": "MARATHON NEXTGEN REALTY LTD.",
                "FaceValue": "5",
                "ISINNo": "INE182D01020",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503127",
                "SecurityId": "RAJABAH",
                "SecurityName": "RAJA BAHADUR INTERNATIONAL LTD.",
                "FaceValue": "100",
                "ISINNo": "INE491N01016",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503162",
                "SecurityId": "RELCHEMQ",
                "SecurityName": "RELIANCE CHEMOTEX INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE750D01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503169",
                "SecurityId": "RUBYMILLS",
                "SecurityName": "RUBY MILLS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE301D01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503205",
                "SecurityId": "SHREERAM",
                "SecurityName": "SHREE RAM URBAN INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE164H01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503229",
                "SecurityId": "SIMPLXREA",
                "SecurityName": "SIMPLEX REALTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE167H01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503243",
                "SecurityId": "SAMSPIN",
                "SecurityName": "SAMRAT SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503297",
                "SecurityId": "SUPERSYN",
                "SecurityName": "SUPER SYNCOTEX (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE542D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503301",
                "SecurityId": "SVDSIMI",
                "SecurityName": "SVADESHI MILLS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503310",
                "SecurityId": "SWANENERGY",
                "SecurityName": "SWAN ENERGY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE665A01038",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503349",
                "SecurityId": "VICTMILL",
                "SecurityName": "VICTORIA MILLS LTD.",
                "FaceValue": "100",
                "ISINNo": "INE203D01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503622",
                "SecurityId": "ZSVTRADI",
                "SecurityName": "S.V.TRADING & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE404N01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503624",
                "SecurityId": "ZSVARAJT",
                "SecurityName": "SVARAJ TRADING & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE406N01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503628",
                "SecurityId": "ZDOLPINV",
                "SecurityName": "DOLPHIN INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503630",
                "SecurityId": "SHELLIN",
                "SecurityName": "SHELL INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503635",
                "SecurityId": "SALSAIN",
                "SecurityName": "SHREE SALASAR INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE315N01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503637",
                "SecurityId": "DHANLEELA",
                "SecurityName": "DHANLEELA INVESTMENTS & TRADING COMPANY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE683D01027",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503639",
                "SecurityId": "INDSOYA",
                "SecurityName": "INDSOYA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE314N01010",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503641",
                "SecurityId": "ZODIACVEN",
                "SecurityName": "ZODIAC VENTURES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE945J01027",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503655",
                "SecurityId": "AEONIA",
                "SecurityName": "AEONIAN INVESTMENTS CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE421F01027",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503657",
                "SecurityId": "VEERENRGY",
                "SecurityName": "VEER ENERGY & INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE255E01030",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503659",
                "SecurityId": "SW1",
                "SecurityName": "SW INVESTMENTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE948K01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503660",
                "SecurityId": "SHETYLSDM",
                "SecurityName": "SHETTY LEASING (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503663",
                "SecurityId": "TILAK",
                "SecurityName": "TILAK VENTURES LTD",
                "FaceValue": "1",
                "ISINNo": "INE026L01022",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503665",
                "SecurityId": "NEWCCRD",
                "SecurityName": "NEW CITY CREDIT & INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503666",
                "SecurityId": "ZMANOWIN",
                "SecurityName": "MANOWAY INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503669",
                "SecurityId": "KKFIN",
                "SecurityName": "K K FINCORP LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE509J01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503671",
                "SecurityId": "UNIJOLL",
                "SecurityName": "UNIJOLLY INVESTMENTS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE130N01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503673",
                "SecurityId": "CMMQ",
                "SecurityName": "CMM BROADCASTING NETWORK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE199B01028",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503675",
                "SecurityId": "WAGEND",
                "SecurityName": "WAGEND INFRA VENTURE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE786K01023",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503677",
                "SecurityId": "ZLIGHTHS",
                "SecurityName": "LIGHT HOUSE INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503679",
                "SecurityId": "GLMINEF",
                "SecurityName": "GOLDMINE FINTRADE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503681",
                "SecurityId": "ELCIDIN",
                "SecurityName": "ELCID INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE927X01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503683",
                "SecurityId": "ZRAIINVE",
                "SecurityName": "RAI INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503685",
                "SecurityId": "MEDIAONE",
                "SecurityName": "MEDIAONE GLOBAL ENTERTAINMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE828I01019",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503689",
                "SecurityId": "HEALINV",
                "SecurityName": "HEALTHY INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE160N01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503691",
                "SecurityId": "SAHARA",
                "SecurityName": "SAHARA ONE MEDIA & ENTERTAINMENT LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE479B01016",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503693",
                "SecurityId": "SOLITIN",
                "SecurityName": "SOLITAIRE INVESTMENTS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416G01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503695",
                "SecurityId": "COBALIN",
                "SecurityName": "COBAL INVESTMENTS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503696",
                "SecurityId": "SHRENTI",
                "SecurityName": "SHREENATH INVESTMENTS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE475V01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503699",
                "SecurityId": "GEODESIC",
                "SecurityName": "GEODESIC LTD.",
                "FaceValue": "2",
                "ISINNo": "INE371D01029",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503721",
                "SecurityId": "ZHARYCONDM",
                "SecurityName": "ZHARYCONDM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503722",
                "SecurityId": "BANSWRAS",
                "SecurityName": "BANSWARA SYNTEX LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE629D01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503723",
                "SecurityId": "ZAMBISLK",
                "SecurityName": "AMBIKA SILK MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503728",
                "SecurityId": "BOMSILKDM",
                "SecurityName": "BOMBAY SILK MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503738",
                "SecurityId": "GSLINDL",
                "SecurityName": "GSL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503744",
                "SecurityId": "GRWRENY",
                "SecurityName": "GARWARE NYLONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503749",
                "SecurityId": "APR",
                "SecurityName": "APR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE193A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503772",
                "SecurityId": "MODWOOL",
                "SecurityName": "MODELLA WOOLLENS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE380D01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503776",
                "SecurityId": "MODIPON",
                "SecurityName": "MODIPON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE170C01019",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503780",
                "SecurityId": "NRC",
                "SecurityName": "NRC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE953C01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503788",
                "SecurityId": "SHRUSYN",
                "SecurityName": "SHRUTI SYNTHETIC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503796",
                "SecurityId": "DIGJAM",
                "SecurityName": "DIGJAM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE471A01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503800",
                "SecurityId": "SHREESYN",
                "SecurityName": "SHREE SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE872B01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503804",
                "SecurityId": "SHRIDINE",
                "SecurityName": "SHRI DINESH MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE204C01024",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503806",
                "SecurityId": "SRF",
                "SecurityName": "SRF LTD.",
                "FaceValue": "10",
                "ISINNo": "INE647A01010",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503811",
                "SecurityId": "SIYSIL",
                "SecurityName": "SIYARAM SILK MILLS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE076B01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503816",
                "SecurityId": "SWADPOL",
                "SecurityName": "SWADESHI POLYTEX LTD.",
                "FaceValue": "1",
                "ISINNo": "INE243N01029",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503823",
                "SecurityId": "BIRLATR",
                "SecurityName": "BIRLA TRANSASIA CARPETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE646O01013",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503830",
                "SecurityId": "KAREEMS",
                "SecurityName": "KAREEMS SILK INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503831",
                "SecurityId": "FOMEHOT",
                "SecurityName": "FOMENTO RESORTS & HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE241E01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503837",
                "SecurityId": "SHRAJSYNQ",
                "SecurityName": "SHREE RAJASTHAN SYNTEX LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE796C01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503839",
                "SecurityId": "HAMSTAL",
                "SecurityName": "HAMCO STEELS & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503847",
                "SecurityId": "JAINSPN",
                "SecurityName": "JAIN SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503857",
                "SecurityId": "SUPERSP",
                "SecurityName": "SIDDHARTHA SUPER SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503861",
                "SecurityId": "JAIPURPO",
                "SecurityName": "JAIPUR POLYSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE916B01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503863",
                "SecurityId": "SHRMFGC",
                "SecurityName": "SHREE MANUFACTURING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE632A01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503873",
                "SecurityId": "PRIYADSP",
                "SecurityName": "PRIYADARSHINI SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE165C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503876",
                "SecurityId": "TRPATFB",
                "SecurityName": "TIRUPATI FIBRES & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503878",
                "SecurityId": "ORNTSYN",
                "SecurityName": "ORIENT SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503881",
                "SecurityId": "HINDSYNTEX",
                "SecurityName": "HIND SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE155B01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503893",
                "SecurityId": "ZSATYASL",
                "SecurityName": "SATYAM SILK MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE07MC01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503897",
                "SecurityId": "ARIHANTIND",
                "SecurityName": "ARIHANT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE332C01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503899",
                "SecurityId": "OXEM BAP",
                "SecurityName": "OXEMBERG APPARELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE583O01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503940",
                "SecurityId": "ASIANELEC",
                "SecurityName": "ASIAN ELECTRONICS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE441A01026",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503956",
                "SecurityId": "BAROELE",
                "SecurityName": "BARODA ELECTRIC METERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503960",
                "SecurityId": "BBL",
                "SecurityName": "BHARAT BIJLEE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE464A01028",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503968",
                "SecurityId": "CTRMFG",
                "SecurityName": "CTR MANUFACTURING INDUSTRIES LTD.",
                "FaceValue": "100",
                "ISINNo": "INE372G01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503976",
                "SecurityId": "DEVINDL",
                "SecurityName": "DEVIDAYAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503978",
                "SecurityId": "ZDIGIELE",
                "SecurityName": "DIGITAL ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE053N01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "503982",
                "SecurityId": "EDDYCUR",
                "SecurityName": "EDDY CURRENT CONTROLS (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504000",
                "SecurityId": "ELPROINTL",
                "SecurityName": "ELPRO INTERNATIONAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE579B01039",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504008",
                "SecurityId": "EMCO",
                "SecurityName": "EMCO LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE078A01026",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504028",
                "SecurityId": "GEE",
                "SecurityName": "GEE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE064H01021",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504033",
                "SecurityId": "GLOBACT",
                "SecurityName": "GLOBE ACTIVE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504036",
                "SecurityId": "HIRECT",
                "SecurityName": "HIND RECTIFIERS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE835D01023",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504058",
                "SecurityId": "NIPPOBATRY",
                "SecurityName": "INDO-NATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE567A01010",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504060",
                "SecurityId": "INDSCBL",
                "SecurityName": "INDUSTRIAL CABLES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE510J01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504067",
                "SecurityId": "ZENSARTECH",
                "SecurityName": "ZENSAR TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE520A01027",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504076",
                "SecurityId": "JYOTI",
                "SecurityName": "JYOTI LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE511D01012",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504080",
                "SecurityId": "JSLINDL",
                "SecurityName": "JSL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE581L01018",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504084",
                "SecurityId": "KAYCEEI",
                "SecurityName": "KAYCEE INDUSTRIES LTD.",
                "FaceValue": "100",
                "ISINNo": "INE813G01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504087",
                "SecurityId": "KHNDHER",
                "SecurityName": "KHANDELWAL HERMANN ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504092",
                "SecurityId": "INDOKEM",
                "SecurityName": "INDOKEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE716F01012",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504093",
                "SecurityId": "PANAENERG",
                "SecurityName": "PANASONIC ENERGY INDIA COMPANY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE795A01017",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504107",
                "SecurityId": "MLTRNSM",
                "SecurityName": "MELTRON SEMICONDUCTORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504112",
                "SecurityId": "NELCO",
                "SecurityName": "NELCO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE045B01015",
                "Industry": "IT Networking Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504130",
                "SecurityId": "OTISEDM",
                "SecurityName": "OTIS ELEVATOR CO. (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE099A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504132",
                "SecurityId": "PERMAGN",
                "SecurityName": "PERMANENT MAGNETS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE418E01018",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504176",
                "SecurityId": "HIGHENE",
                "SecurityName": "HIGH ENERGY BATTERIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE783E01015",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504180",
                "SecurityId": "STDBAT",
                "SecurityName": "STANDARD BATTERIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE502C01039",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504184",
                "SecurityId": "SYLVAEL",
                "SecurityName": "SYLVANIA & LAXMAN LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504202",
                "SecurityId": "TRANELE",
                "SecurityName": "TRANSFORMERS & ELECTRICALS KERALA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504212",
                "SecurityId": "UNIVCABLES",
                "SecurityName": "UNIVERSAL CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE279A01012",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504220",
                "SecurityId": "WSIND",
                "SecurityName": "W.S.INDUSTRIES (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE100D01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504227",
                "SecurityId": "WILLARD",
                "SecurityName": "WILLARD INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE481D01018",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504240",
                "SecurityId": "DLTNCBL",
                "SecurityName": "DELTON CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE872E01016",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504243",
                "SecurityId": "GENELEC",
                "SecurityName": "GENELEC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504246",
                "SecurityId": "ELCTRA",
                "SecurityName": "ELECTRA (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504250",
                "SecurityId": "FCIOEN",
                "SecurityName": "FCI OEN CONNECTORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE669B01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504252",
                "SecurityId": "SAHAKEI",
                "SecurityName": "SAHA KEIL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504256",
                "SecurityId": "DELTRON",
                "SecurityName": "DELTRON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE272R01011",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504258",
                "SecurityId": "LAKSELEC",
                "SecurityName": "LAKSHMI ELECTRICAL CONTROL SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE284C01018",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504269",
                "SecurityId": "KHAITANELE",
                "SecurityName": "KHAITAN ELECTRICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE761A01019",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504273",
                "SecurityId": "MODULEX",
                "SecurityName": "MODULEX CONSTRUCTION TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE064R01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504274",
                "SecurityId": "WESTELE",
                "SecurityName": "WESTON ELECTRONIKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504278",
                "SecurityId": "PUALIDM",
                "SecurityName": "PUNJAB ANAND LAMP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE276B01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504286",
                "SecurityId": "DELTAMAGNT",
                "SecurityName": "DELTA MANUFACTURING LTD",
                "FaceValue": "10",
                "ISINNo": "INE393A01011",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504288",
                "SecurityId": "POLARIND",
                "SecurityName": "POLAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE057B01010",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504290",
                "SecurityId": "TATAINFOTE",
                "SecurityName": "TATA INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE194A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504295",
                "SecurityId": "KARWELD",
                "SecurityName": "KARNATAKA WELDING PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504335",
                "SecurityId": "PAZEL",
                "SecurityName": "PAZEL INTERNATIONAL LTD",
                "FaceValue": "1",
                "ISINNo": "INE040N01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504336",
                "SecurityId": "INTRA",
                "SecurityName": "INDTRADECO LTD.",
                "FaceValue": "1",
                "ISINNo": "INE853B01020",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504338",
                "SecurityId": "ZBALSHYG",
                "SecurityName": "BALSARA HYGIENE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504340",
                "SecurityId": "CONFINT",
                "SecurityName": "CONFIDENCE FINANCE AND TRADING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE180M01033",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504341",
                "SecurityId": "RELTD",
                "SecurityName": "RAVINDRA ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE206N01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504343",
                "SecurityId": "KANUMUN",
                "SecurityName": "KANUMANEK TRADING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504345",
                "SecurityId": "SAMLEPU",
                "SecurityName": "SAM LEASECO LTD.",
                "FaceValue": "1",
                "ISINNo": "INE368N01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504346",
                "SecurityId": "GDTRAGN",
                "SecurityName": "G.D.TRADING & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE713N01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504351",
                "SecurityId": "EMPOWER",
                "SecurityName": "EMPOWER INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE507F01023",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504353",
                "SecurityId": "ZTITANTR",
                "SecurityName": "TITAN TRADING & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE353F01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504356",
                "SecurityId": "ZMULTIPU",
                "SecurityName": "MULTIPURPOSE TRADING & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE017P01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504358",
                "SecurityId": "TURBO",
                "SecurityName": "TURBOTECH ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE764M01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504360",
                "SecurityId": "REMISIN",
                "SecurityName": "REMI SALES & ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE130I01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504362",
                "SecurityId": "ZSIYARPO",
                "SecurityName": "SIYARAM PODDAR FINANCE & TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE232P01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504365",
                "SecurityId": "RIDHISYN",
                "SecurityName": "RIDHI SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE07LK01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504367",
                "SecurityId": "CHAMPFIN",
                "SecurityName": "CHAMPION FINSEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE815H01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504369",
                "SecurityId": "GRANDMA",
                "SecurityName": "GRANDMA TRADING & AGENCIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE927M01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504373",
                "SecurityId": "ZOPALTRD",
                "SecurityName": "OPAL TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504375",
                "SecurityId": "SOFTBPO",
                "SecurityName": "SOFTBPO GLOBAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE459E01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504377",
                "SecurityId": "ATETRMGDM",
                "SecurityName": "A.T.E.TRADING & MANUFACTURING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504378",
                "SecurityId": "NYSSACORP",
                "SecurityName": "NYSSA CORPORATION LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE812K01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504380",
                "SecurityId": "FLOMIC",
                "SecurityName": "FLOMIC GLOBAL LOGISTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE952M01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504382",
                "SecurityId": "SMPLXTR",
                "SecurityName": "SIMPLEX TRADING & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE518H01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504387",
                "SecurityId": "ELFTRDG",
                "SecurityName": "ELF TRADING & CHEMICALS MANUFACTURING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504389",
                "SecurityId": "ZBALGHOL",
                "SecurityName": "BALGOPAL HOLDING & TRADERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE243P01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504390",
                "SecurityId": "AVTIL",
                "SecurityName": "AVTIL ENTERPRISE LTD",
                "FaceValue": "10",
                "ISINNo": "INE292N01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504392",
                "SecurityId": "KRISHNA",
                "SecurityName": "KRISHNA VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE537L01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504395",
                "SecurityId": "UPTOWTR",
                "SecurityName": "UP TOWN TRADING & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504397",
                "SecurityId": "GANHOLD",
                "SecurityName": "GANESH HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE932M01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504398",
                "SecurityId": "SJCORP",
                "SecurityName": "SJ CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE312B01027",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504580",
                "SecurityId": "MALCO",
                "SecurityName": "MADRAS ALUMINIUM CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE223B01026",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504601",
                "SecurityId": "HARSTEEL",
                "SecurityName": "HARYANA STEEL & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE567C01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504605",
                "SecurityId": "UNIABEXAL",
                "SecurityName": "UNI ABEX ALLOY PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE361D01012",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504614",
                "SecurityId": "SARDAEN",
                "SecurityName": "SARDA ENERGY & MINERALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE385C01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504615",
                "SecurityId": "SOMANI",
                "SecurityName": "SOMANI IRON & STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504621",
                "SecurityId": "BIHRFOC",
                "SecurityName": "BIHAR FOUNDRY & CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504629",
                "SecurityId": "ANILSPL",
                "SecurityName": "ANIL SPECIAL STEEL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE904B01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504646",
                "SecurityId": "BGWTATO",
                "SecurityName": "BHAGWATI AUTOCAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE106G01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504648",
                "SecurityId": "BOMBWIR",
                "SecurityName": "BOMBAY WIRE ROPES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE089T01023",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504668",
                "SecurityId": "CANRSTL",
                "SecurityName": "CANARA STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504673",
                "SecurityId": "UNIVPRIM",
                "SecurityName": "UNIVERSAL PRIME ALUMINIUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE621D01019",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504697",
                "SecurityId": "GALADA",
                "SecurityName": "GALADA POWER & TELECOMMUNICATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE255C01018",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504701",
                "SecurityId": "GONTER",
                "SecurityName": "GONTERMANN-PEIPERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE530A01026",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504704",
                "SecurityId": "GKW",
                "SecurityName": "GKW LTD.",
                "FaceValue": "10",
                "ISINNo": "INE528A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504707",
                "SecurityId": "GRHMFRT-B",
                "SecurityName": "GRAHAM FIRTH STEEL PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504710",
                "SecurityId": "GUJSTUB",
                "SecurityName": "GUJARAT STEEL TUBES LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504713",
                "SecurityId": "HINDWRS",
                "SecurityName": "HINDUSTAN WIRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE075C01010",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504716",
                "SecurityId": "HWIREMT",
                "SecurityName": "HIND WIRE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504717",
                "SecurityId": "STRIPMT",
                "SecurityName": "STEEL STRIPS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE01RN01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504731",
                "SecurityId": "IBRIGST",
                "SecurityName": "INDIAN BRIGHT STEEL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE566M01017",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504736",
                "SecurityId": "INFORDR",
                "SecurityName": "INDIA FORGE & DROP STAMPINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504741",
                "SecurityId": "INDIANHUME",
                "SecurityName": "INDIAN HUME PIPE CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE323C01030",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504743",
                "SecurityId": "ZDERCOIL",
                "SecurityName": "DERCO COOLING COILS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504746",
                "SecurityId": "INLCM",
                "SecurityName": "INDIAN LINK CHAIN MANUFACTURES LTD.",
                "FaceValue": "100",
                "ISINNo": "INE359D01016",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504751",
                "SecurityId": "INDMETA-B",
                "SecurityName": "INDIAN METALS & FERRO ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504754",
                "SecurityId": "INSMT",
                "SecurityName": "INSMT",
                "FaceValue": "10",
                "ISINNo": "INE501B01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504784",
                "SecurityId": "INDWIRE-B",
                "SecurityName": "INDORE WIRE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504786",
                "SecurityId": "INVPRECQ",
                "SecurityName": "INVESTMENT & PRECISION CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE155E01016",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504807",
                "SecurityId": "SUMMITSEC",
                "SecurityName": "SUMMIT SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE852A01016",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504810",
                "SecurityId": "INFORTEC",
                "SecurityName": "INFORMED TECHNOLOGIES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE123E01014",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504823",
                "SecurityId": "MAHINDUGIN",
                "SecurityName": "MAHINDRA UGINE STEEL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE850A01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504824",
                "SecurityId": "MAHELEK",
                "SecurityName": "MAHARASHTRA ELEKTROSMELT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE400E01016",
                "Industry": "Other Non-Ferrous Metals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504840",
                "SecurityId": "KAIRA",
                "SecurityName": "KAIRA CAN CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE375D01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504844",
                "SecurityId": "MHECKMT",
                "SecurityName": "MOHATTA & HECKEL LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504855",
                "SecurityId": "ZMUSSSTL",
                "SecurityName": "MUZAFFARNAGAR STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504860",
                "SecurityId": "PRATRAJ",
                "SecurityName": "PARTAP RAJASTHAN SPECIAL STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504864",
                "SecurityId": "ORISSASP",
                "SecurityName": "ORISSA SPONGE IRON & STEEL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE228D01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504879",
                "SecurityId": "ORIENTABRA",
                "SecurityName": "ORIENT ABRASIVES LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE569C01020",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504882",
                "SecurityId": "NATIONSTD",
                "SecurityName": "NATIONAL STANDARD (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE166R01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504888",
                "SecurityId": "NAHARINT",
                "SecurityName": "NAHAR INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE360A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504895",
                "SecurityId": "RATHIAL",
                "SecurityName": "RATHI ALLOYS & STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504896",
                "SecurityId": "RATHISPA",
                "SecurityName": "RATHI ISPAT LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE235D01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504903",
                "SecurityId": "RATHIST",
                "SecurityName": "RATHI STEEL & POWER LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE336C01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504908",
                "SecurityId": "DUNCANENG",
                "SecurityName": "DUNCAN ENGINEERING LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE340F01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504918",
                "SecurityId": "SANDUMA",
                "SecurityName": "SANDUR MANGANESE & IRON ORES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE149K01016",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504920",
                "SecurityId": "JHAGCOP",
                "SecurityName": "JHAGADIA COPPER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE666A01028",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504925",
                "SecurityId": "ZSMAHISP",
                "SecurityName": "SHREE MAHAVIR ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504953",
                "SecurityId": "STELCOM",
                "SecurityName": "STEEL COMPLEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504959",
                "SecurityId": "STOVACQ",
                "SecurityName": "STOVEC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE755D01015",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504960",
                "SecurityId": "STEWARTQ",
                "SecurityName": "STEWARTS & LLOYDS OF INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE356D01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504961",
                "SecurityId": "TATAYODOGA",
                "SecurityName": "TAYO ROLLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE895C01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504966",
                "SecurityId": "TINPLATE",
                "SecurityName": "TINPLATE COMPANY OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE422C01014",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504973",
                "SecurityId": "CHOLAHLDNG",
                "SecurityName": "CHOLAMANDALAM FINANCIAL HOLDINGS LTD",
                "FaceValue": "1",
                "ISINNo": "INE149A01033",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504988",
                "SecurityId": "ZWELCAST",
                "SecurityName": "WELCAST STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE380G01015",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504991",
                "SecurityId": "VIDIRMT",
                "SecurityName": "VIDARBHA IRON & STEEL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE392D01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504997",
                "SecurityId": "BHRKSTSER",
                "SecurityName": "BHORUKA STEEL & SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE152I01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "504998",
                "SecurityId": "WMINIMT",
                "SecurityName": "WESTERN MINISTIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE187U01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505010",
                "SecurityId": "AUTOAXLES",
                "SecurityName": "AUTOMOTIVE AXLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE449A01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505029",
                "SecurityId": "ATLASCYCLE",
                "SecurityName": "ATLAS CYCLES (HARYANA) LTD.",
                "FaceValue": "5",
                "ISINNo": "INE446A01025",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505036",
                "SecurityId": "ACGL",
                "SecurityName": "AUTOMOBILE CORPORATION OF GOA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE451C01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505052",
                "SecurityId": "CLUTCHAUTO",
                "SecurityName": "CLUTCH AUTO LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE779B01019",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505075",
                "SecurityId": "SETCO",
                "SecurityName": "SETCO AUTOMOTIVE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE878E01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505100",
                "SecurityId": "INRADIA",
                "SecurityName": "INDIA RADIATORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE461Y01016",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505141",
                "SecurityId": "SCOOTER",
                "SecurityName": "SCOOTERS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE959E01011",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505155",
                "SecurityId": "SANENG",
                "SecurityName": "SAN ENGINEERING & LOCOMOTIVE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE031H01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505160",
                "SecurityId": "TALBROAUTO",
                "SecurityName": "TALBROS AUTOMOTIVE COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE187D01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505163",
                "SecurityId": "ZFSTEERING",
                "SecurityName": "Z.F.STEERING GEAR (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE116C01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505185",
                "SecurityId": "BOSCHCHAS",
                "SecurityName": "BOSCH CHASSIS SYSTEMS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE053B01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505190",
                "SecurityId": "KINETIC",
                "SecurityName": "KINETIC MOTOR CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE267B01015",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505192",
                "SecurityId": "SMLISUZU",
                "SecurityName": "SML ISUZU LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE294B01019",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505196",
                "SecurityId": "TIL",
                "SecurityName": "TIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE806C01018",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505200",
                "SecurityId": "EICHERMOT",
                "SecurityName": "EICHER MOTORS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE066A01021",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505212",
                "SecurityId": "JAINEX",
                "SecurityName": "JAINEX AAMCOL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE280F01019",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505216",
                "SecurityId": "ALFREDHE",
                "SecurityName": "ALFRED HERBERT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE782D01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505230",
                "SecurityId": "CIMMCO",
                "SecurityName": "CIMMCO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE184C01028",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505232",
                "SecurityId": "VELJAN",
                "SecurityName": "VELJAN DENISON LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE232E01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505242",
                "SecurityId": "DYNAMATECH",
                "SecurityName": "DYNAMATIC TECHNOLOGIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE221B01012",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505250",
                "SecurityId": "GGDANDE",
                "SecurityName": "G.G.DANDEKAR MACHINE WORKS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE631D01026",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505252",
                "SecurityId": "TATCP",
                "SecurityName": "TATA CONSTRUCTION & PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE312D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505255",
                "SecurityId": "GMM",
                "SecurityName": "GMM PFAUDLER LTD.",
                "FaceValue": "2",
                "ISINNo": "INE541A01023",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505260",
                "SecurityId": "INDABRT",
                "SecurityName": "INDABRATOR LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505277",
                "SecurityId": "WEBELSE",
                "SecurityName": "WEBEL SEN CAPACITORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505280",
                "SecurityId": "JOHNFWL-B",
                "SecurityName": "JOHN FOWLER (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505283",
                "SecurityId": "KIRLPNU",
                "SecurityName": "KIRLOSKAR PNEUMATIC CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE811A01020",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505285",
                "SecurityId": "TULIVE",
                "SecurityName": "TULIVE DEVELOPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE637D01015",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505299",
                "SecurityId": "KPT",
                "SecurityName": "KPT INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE731D01024",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505300",
                "SecurityId": "KUNLENG",
                "SecurityName": "KUNAL ENGINEERING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505302",
                "SecurityId": "LXMIATO",
                "SecurityName": "LAKSHMI AUTOMATIC LOOM WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE718M01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505320",
                "SecurityId": "LYNMC",
                "SecurityName": "LYNX MACHINERY & COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE732D01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505324",
                "SecurityId": "MANUGRAPH",
                "SecurityName": "MANUGRAPH INDIA LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE867A01022",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505330",
                "SecurityId": "ZLOYAENG",
                "SecurityName": "LOYAL ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505336",
                "SecurityId": "MIRCH",
                "SecurityName": "MIRCH TECHNOLOGIES (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE098E01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505343",
                "SecurityId": "MONOT",
                "SecurityName": "MONOTYPE INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE811D01024",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505355",
                "SecurityId": "NESCO",
                "SecurityName": "NESCO LTD.",
                "FaceValue": "2",
                "ISINNo": "INE317F01035",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505358",
                "SecurityId": "INTEGRAEN",
                "SecurityName": "INTEGRA ENGINEERING INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE984B01023",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505366",
                "SecurityId": "BOSCHDM",
                "SecurityName": "BOSCH REXROTH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE294D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505368",
                "SecurityId": "REVATHI",
                "SecurityName": "REVATHI EQUIPMENT LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE617A01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505380",
                "SecurityId": "SIMTOOL",
                "SecurityName": "SIMTOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505382",
                "SecurityId": "STANENG",
                "SecurityName": "STANFORD ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505385",
                "SecurityId": "SLMMNKL",
                "SecurityName": "SLMMNKL",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505397",
                "SecurityId": "TECHELE",
                "SecurityName": "TECHNO ELECTRIC & ENGINEERING COMPANY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE470B01023",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505400",
                "SecurityId": "TEXINFRA",
                "SecurityName": "TEXMACO INFRASTRUCTURE & HOLDINGS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE435C01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505412",
                "SecurityId": "WENDT",
                "SecurityName": "WENDT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE274C01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505421",
                "SecurityId": "STARMCH",
                "SecurityName": "STAR MACHINERY MANUFACTURING CO.LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505426",
                "SecurityId": "BIRLMCTOL",
                "SecurityName": "BIRLA MACHINING & TOOLINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE989B01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505502",
                "SecurityId": "PSITINFRA",
                "SecurityName": "PS IT INFRASTRUCTURE & SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE953M01033",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505504",
                "SecurityId": "SINDUVA",
                "SecurityName": "SINDU VALLEY TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505506",
                "SecurityId": "AXONVL",
                "SecurityName": "AXON VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE663D01011",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505509",
                "SecurityId": "RESPONIND",
                "SecurityName": "RESPONSIVE INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE688D01026",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505511",
                "SecurityId": "ZSAMTULI",
                "SecurityName": "SAM-TUL INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505513",
                "SecurityId": "SHRSHATEX",
                "SecurityName": "SHREE SHALEEN TEXTILES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE703L01026",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505515",
                "SecurityId": "SHYMINV",
                "SecurityName": "SHYAMKAMAL INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE203N01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505518",
                "SecurityId": "ZARIHPET",
                "SecurityName": "ARIHANT PETRO CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505523",
                "SecurityId": "MAHACORP",
                "SecurityName": "MAHARASHTRA CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE272E01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505525",
                "SecurityId": "ZPARICIN",
                "SecurityName": "PARICHAY INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE701F01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505526",
                "SecurityId": "DOLAT",
                "SecurityName": "DOLAT INVESTMENTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE966A01022",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505529",
                "SecurityId": "SHYAMHO",
                "SecurityName": "SHYAMAL HOLDINGS & TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE481F01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505530",
                "SecurityId": "PRISMINFO",
                "SecurityName": "PRISM INFORMATICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE389J01028",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505533",
                "SecurityId": "WESTLIFE",
                "SecurityName": "WESTLIFE DEVELOPMENT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE274F01020",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505535",
                "SecurityId": "ZVVNMFG",
                "SecurityName": "VVN MFG.& INVESTA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505537",
                "SecurityId": "ZEEL",
                "SecurityName": "ZEE ENTERTAINMENT ENTERPRISES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE256A01028",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505539",
                "SecurityId": "IMCFINA",
                "SecurityName": "IMC FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE170F01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505541",
                "SecurityId": "SUNFI",
                "SecurityName": "SUNFLEX FINANCE & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE458D01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505543",
                "SecurityId": "BALAHDG",
                "SecurityName": "BALAJI BONDS & HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505574",
                "SecurityId": "ZAQULNDI",
                "SecurityName": "AQUALAND (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505576",
                "SecurityId": "GOLDCORP",
                "SecurityName": "GOLDCREST CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE505D01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505583",
                "SecurityId": "WWTECHHOL",
                "SecurityName": "W W TECHNOLOGY HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE972M01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505585",
                "SecurityId": "ZKOVALIN",
                "SecurityName": "KOVALAM INVESTMENT & TRADING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE735U01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505590",
                "SecurityId": "SVPGLOB",
                "SecurityName": "SVP GLOBAL VENTURES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE308E01029",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505592",
                "SecurityId": "SHKRISHNAB",
                "SecurityName": "SHREEKRISHNA BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE571N01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505594",
                "SecurityId": "MULTIIN",
                "SecurityName": "MULTIPLUS HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE886E01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505650",
                "SecurityId": "SKYLMILAR",
                "SecurityName": "SKYLINE MILLARS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE178E01026",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505658",
                "SecurityId": "REMIPRO",
                "SecurityName": "REMI PROCESS PLANT & MACHINERY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE513H01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505665",
                "SecurityId": "ABCBEARS",
                "SecurityName": "ABC BEARINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE779A01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505672",
                "SecurityId": "ASIANBRG",
                "SecurityName": "ASIAN BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE046C01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505678",
                "SecurityId": "CPECLTD",
                "SecurityName": "CPEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE029P01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505681",
                "SecurityId": "BIMETAL",
                "SecurityName": "BIMETAL BEARINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE469A01019",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505684",
                "SecurityId": "CHIPIDM",
                "SecurityName": "CHICAGO PNEUMATIC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE489A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505685",
                "SecurityId": "TAPARIA",
                "SecurityName": "TAPARIA TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE614R01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505688",
                "SecurityId": "BHARATGEAR",
                "SecurityName": "BHARAT GEARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE561C01019",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505690",
                "SecurityId": "BRADYM",
                "SecurityName": "BRADY & MORRIS ENGINEERING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE856A01017",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505693",
                "SecurityId": "LATIMMETAL",
                "SecurityName": "LA TIM METAL & INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE501N01012",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505700",
                "SecurityId": "ELECON",
                "SecurityName": "ELECON ENGINEERING CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE205B01023",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505703",
                "SecurityId": "DECANBRG",
                "SecurityName": "DECCAN BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE498D01012",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505710",
                "SecurityId": "GRAUWEIL",
                "SecurityName": "GRAUER & WEIL (INDIA) LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE266D01021",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505711",
                "SecurityId": "GAJRA",
                "SecurityName": "GAJRA BEVEL GEARS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE282D01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505712",
                "SecurityId": "HIMTEK",
                "SecurityName": "HIM TEKNOFORGE LTD",
                "FaceValue": "2",
                "ISINNo": "INE705G01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505714",
                "SecurityId": "GABRIEL",
                "SecurityName": "GABRIEL INDIA LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE524A01029",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505720",
                "SecurityId": "HERCULES",
                "SecurityName": "HERCULES HOISTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE688E01024",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505725",
                "SecurityId": "HINDEVER",
                "SecurityName": "HINDUSTAN EVEREST TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE598D01019",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505726",
                "SecurityId": "IFBIND",
                "SecurityName": "IFB INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE559A01017",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505729",
                "SecurityId": "SINGER",
                "SecurityName": "SINGER INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE638A01035",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505737",
                "SecurityId": "INTLCOMBQ",
                "SecurityName": "INTERNATIONAL COMBUSTION (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE403C01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505744",
                "SecurityId": "FMGOETZE",
                "SecurityName": "FEDERAL-MOGUL GOETZE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE529A01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505750",
                "SecurityId": "JOSTS",
                "SecurityName": "JOST&#39;S ENGINEERING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE636D01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505783",
                "SecurityId": "OTOKLIN",
                "SecurityName": "OTOKLIN PLANTS & EQUIPMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE304C01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505789",
                "SecurityId": "PERCI",
                "SecurityName": "PERFECT CIRCLE INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE222D01032",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505790",
                "SecurityId": "SCHAEFFLER",
                "SecurityName": "SCHAEFFLER INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE513A01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505797",
                "SecurityId": "MPCOSEMB",
                "SecurityName": "MIPCO SEAMLESS RINGS (GUJARAT) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE860N01012",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505800",
                "SecurityId": "RANEHOLDIN",
                "SecurityName": "RANE HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE384A01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505807",
                "SecurityId": "ROLCOEN",
                "SecurityName": "ROLCON ENGINEERING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE775R01013",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505817",
                "SecurityId": "REILELEC",
                "SecurityName": "REIL ELECTRICALS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE814K01015",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505823",
                "SecurityId": "SECALS",
                "SecurityName": "SECALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505827",
                "SecurityId": "SNL",
                "SecurityName": "SNL BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE568F01017",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505835",
                "SecurityId": "SHBEREN",
                "SecurityName": "SHRIRAM BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505840",
                "SecurityId": "JAIPAN",
                "SecurityName": "JAIPAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE058D01030",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505845",
                "SecurityId": "APSSTAR-B",
                "SecurityName": "APS-STAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505850",
                "SecurityId": "MANCREDIT",
                "SecurityName": "MANGAL CREDIT AND FINCORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545L01039",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505854",
                "SecurityId": "TRF",
                "SecurityName": "TRF LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE391D01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505872",
                "SecurityId": "WPIL",
                "SecurityName": "WPIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE765D01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505885",
                "SecurityId": "ALFALAVAL",
                "SecurityName": "ALFA LAVAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE427A01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505890",
                "SecurityId": "KENNAMET",
                "SecurityName": "KENNAMETAL INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE717A01029",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505893",
                "SecurityId": "HINDHARD",
                "SecurityName": "HINDUSTAN HARDY SPICER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE724D01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505923",
                "SecurityId": "EXEDYIND",
                "SecurityName": "EXEDY INDIA LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE773A01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505930",
                "SecurityId": "VISHMEL",
                "SecurityName": "VISHAL MALLEABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661G01018",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505976",
                "SecurityId": "CENTTUBDM",
                "SecurityName": "CENTURY TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505978",
                "SecurityId": "TRITONV",
                "SecurityName": "TRITON VALVES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE440G01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505982",
                "SecurityId": "HINDUJAFO",
                "SecurityName": "HINDUJA FOUNDRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE291F01016",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "505988",
                "SecurityId": "AJNTTUB",
                "SecurityName": "AJANTA TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506003",
                "SecurityId": "SUDAI",
                "SecurityName": "SUDAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE618D01015",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506016",
                "SecurityId": "JRIIIL",
                "SecurityName": "JRI INDUSTRIES & INFRASTRUCTURE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE022M01029",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506022",
                "SecurityId": "PRAKASH",
                "SecurityName": "PRAKASH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE603A01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506024",
                "SecurityId": "HARME",
                "SecurityName": "HARIYANA METALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE219D01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506027",
                "SecurityId": "BHRKALM",
                "SecurityName": "BHORUKA ALUMINIUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE866G01013",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506041",
                "SecurityId": "MAISF",
                "SecurityName": "MAHESH AGRICULTURE IMPLEMENTS & STEEL FORGE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE119D01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506042",
                "SecurityId": "ZPRBHSTE",
                "SecurityName": "PRABHU STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE821R01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506044",
                "SecurityId": "ARIHAST",
                "SecurityName": "ARIHANT STEEL & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506059",
                "SecurityId": "STEELTUBES",
                "SecurityName": "STEEL TUBES OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE926A01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506074",
                "SecurityId": "ARSHIYA",
                "SecurityName": "ARSHIYA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE968D01022",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506076",
                "SecurityId": "GRINDWELL",
                "SecurityName": "GRINDWELL NORTON LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE536A01023",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506079",
                "SecurityId": "LAKPRE",
                "SecurityName": "LAKSHMI PRECISION SCREWS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE651C01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506087",
                "SecurityId": "ANUPMAL",
                "SecurityName": "ANUP MALLEABLE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE188O01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506093",
                "SecurityId": "BCLFRG***",
                "SecurityName": "BCL FORGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE528H01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506095",
                "SecurityId": "CASTRON",
                "SecurityName": "CASTRON TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE492D01015",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506102",
                "SecurityId": "QPRO",
                "SecurityName": "QPRO INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE824F01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506103",
                "SecurityId": "VICTENT",
                "SecurityName": "VICTORIA ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE082E01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506105",
                "SecurityId": "STANROS",
                "SecurityName": "STANROSE MAFATLAL INVESTMENTS AND FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE441L01015",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506107",
                "SecurityId": "PRECTRA",
                "SecurityName": "PRECIOUS TRADING & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE629R01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506109",
                "SecurityId": "GENESYS",
                "SecurityName": "GENESYS INTERNATIONAL CORPORATION LTD.",
                "FaceValue": "5",
                "ISINNo": "INE727B01026",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506112",
                "SecurityId": "ZJALAVEG",
                "SecurityName": "JALAVEG INVESTMENTS & TRADING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506113",
                "SecurityId": "LIFELINE",
                "SecurityName": "LIFELINE DRUGS & PHARMA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE776N01028",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506117",
                "SecurityId": "VIVEKCO",
                "SecurityName": "VIVEK COMMERCIAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506120",
                "SecurityId": "ALNATRD",
                "SecurityName": "ALNA TRADING & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE07I701011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506122",
                "SecurityId": "PANKAJPIYUS",
                "SecurityName": "PANKAJ PIYUSH TRADE & INVESTMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE820M01018",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506123",
                "SecurityId": "NIDHIPL",
                "SecurityName": "NIDHI POLYESTER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506128",
                "SecurityId": "PARNAXLAB",
                "SecurityName": "PARNAX LAB LTD.",
                "FaceValue": "10",
                "ISINNo": "INE383L01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506129",
                "SecurityId": "NGRJFIN-B",
                "SecurityName": "NAGARJUNA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506131",
                "SecurityId": "INDIACO",
                "SecurityName": "INDIACO VENTURES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE064E01028",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506134",
                "SecurityId": "INTELLCAP",
                "SecurityName": "INTELLIVATE CAPITAL VENTURES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE512D01028",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506140",
                "SecurityId": "HIRAMFN",
                "SecurityName": "HIRAMOTI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506142",
                "SecurityId": "VYAPAR",
                "SecurityName": "VYAPAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE070G01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506146",
                "SecurityId": "VIVIDHA",
                "SecurityName": "VISAGAR POLYTEX LTD.",
                "FaceValue": "1",
                "ISINNo": "INE370E01029",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506152",
                "SecurityId": "SARAFSO",
                "SecurityName": "SARAF SONS (TRADERS) LTD.",
                "FaceValue": "5",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506154",
                "SecurityId": "GATEHLD",
                "SecurityName": "GATEWAY HOLDING & TRADES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506156",
                "SecurityId": "ETCNETWORK",
                "SecurityName": "ETC NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE421B01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506159",
                "SecurityId": "ASISL",
                "SecurityName": "ASIS LOGISTICS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE888E01020",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506161",
                "SecurityId": "ISHWATR",
                "SecurityName": "ISHWARSHAKTI HOLDINGS & TRADERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE073I01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506162",
                "SecurityId": "TERRAFORM",
                "SecurityName": "TERRAFORM MAGNUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122V01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506164",
                "SecurityId": "BRINDHL",
                "SecurityName": "BRINDABAN HOLDINGS & TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506166",
                "SecurityId": "APIS",
                "SecurityName": "APIS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE070K01014",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506169",
                "SecurityId": "NASHINV",
                "SecurityName": "NASHVILLE INVESTMENT & TRADING CO.",
                "FaceValue": "10",
                "ISINNo": "INE747F01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506170",
                "SecurityId": "HIRAN",
                "SecurityName": "HIRAN ORGOCHEM LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE546E01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506172",
                "SecurityId": "ZSAMPACH",
                "SecurityName": "SAMPADA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE040K01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506174",
                "SecurityId": "ZJTYUTXL",
                "SecurityName": "JATAYU TEXTILES & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506176",
                "SecurityId": "SUSMITR",
                "SecurityName": "SUSMIT TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506178",
                "SecurityId": "ZKHATAUE",
                "SecurityName": "KHATAU EXIM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE092P01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506180",
                "SecurityId": "EMERGENT",
                "SecurityName": "EMERGENT INDUSTRIAL SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE668L01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506184",
                "SecurityId": "KANANIIND",
                "SecurityName": "KANANI INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE879E01037",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506186",
                "SecurityId": "GCKL",
                "SecurityName": "GALAXY CLOUD KITCHENS LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE403B01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506190",
                "SecurityId": "ZSARVAMA",
                "SecurityName": "SARVAMANGAL MERCANTILE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE978L01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506192",
                "SecurityId": "ZSUSHRTR",
                "SecurityName": "SUSHREE TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506194",
                "SecurityId": "ASL",
                "SecurityName": "ARIHANT SUPERSTRUCTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE643K01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506196",
                "SecurityId": "VIKSHEN",
                "SecurityName": "VIKSIT ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE965V01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506197",
                "SecurityId": "BLISSGVS",
                "SecurityName": "BLISS GVS PHARMA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE416D01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506198",
                "SecurityId": "MRTENTI",
                "SecurityName": "MERTINEZ ENTEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506222",
                "SecurityId": "INEOSSTYRO",
                "SecurityName": "INEOS STYROLUTION INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE189B01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506225",
                "SecurityId": "ADARCHM",
                "SecurityName": "ADARSH CHEMICALS & FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506230",
                "SecurityId": "RHODIA",
                "SecurityName": "RHODIA SPECIALTY CHEMICALS INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE255B01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506235",
                "SecurityId": "ALEMBICLTD",
                "SecurityName": "ALEMBIC LTD.",
                "FaceValue": "2",
                "ISINNo": "INE426A01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506245",
                "SecurityId": "AMRDYCMDM",
                "SecurityName": "AMAR DYE-CHEM LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506248",
                "SecurityId": "AMNPLST",
                "SecurityName": "AMINES & PLASTICIZERS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE275D01022",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506260",
                "SecurityId": "ANUHPHR",
                "SecurityName": "ANUH PHARMA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE489G01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506261",
                "SecurityId": "MODISNME",
                "SecurityName": "MODISON METALS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE737D01021",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506265",
                "SecurityId": "ARLABS",
                "SecurityName": "ARLABS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506267",
                "SecurityId": "ASSAPET",
                "SecurityName": "ASSAM PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE277D01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506275",
                "SecurityId": "BARIUMC",
                "SecurityName": "BARIUM CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506285",
                "SecurityId": "BAYERCROP",
                "SecurityName": "BAYER CROPSCIENCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE462A01022",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506313",
                "SecurityId": "SASHWAT",
                "SecurityName": "SASHWAT TECHNOCRATS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE789D01014",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506315",
                "SecurityId": "BORAX",
                "SecurityName": "BORAX MORARJI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE658B01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506345",
                "SecurityId": "CELUPRO",
                "SecurityName": "CELLULOSE PRODUCTS OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506353",
                "SecurityId": "CHMEQUP",
                "SecurityName": "CHEMIEQUIP LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506355",
                "SecurityId": "CHEMPLAST",
                "SecurityName": "CHEMPLAST SANMAR LTD.",
                "FaceValue": "1",
                "ISINNo": "INE488A01027",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506365",
                "SecurityId": "CHEMOPH",
                "SecurityName": "CHEMO PHARMA LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320M01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506370",
                "SecurityId": "CITRIND",
                "SecurityName": "CITRIC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506373",
                "SecurityId": "CITURGIA",
                "SecurityName": "CITURGIA BIOCHEMICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE795B01031",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506390",
                "SecurityId": "CLNINDIA",
                "SecurityName": "CLARIANT CHEMICALS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE492A01029",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506395",
                "SecurityId": "COROMANDEL",
                "SecurityName": "COROMANDEL INTERNATIONAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE169A01031",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506401",
                "SecurityId": "DEEPAKNI",
                "SecurityName": "DEEPAK NITRITE LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE288B01029",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506405",
                "SecurityId": "DHARAMSI",
                "SecurityName": "DHARAMSI MORARJI CHEMICAL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE505A01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506414",
                "SecurityId": "FERMENTA",
                "SecurityName": "FERMENTA BIOTECH LTD",
                "FaceValue": "5",
                "ISINNo": "INE225B01021",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506443",
                "SecurityId": "FICOMOR",
                "SecurityName": "FICOM ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE514A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506446",
                "SecurityId": "ZGATEWCH",
                "SecurityName": "GATEWAY CHEMISTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506457",
                "SecurityId": "GUJCARB",
                "SecurityName": "GUJARAT CARBON & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE462C01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506460",
                "SecurityId": "SIGROUP",
                "SecurityName": "SI GROUP-INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE547A01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506461",
                "SecurityId": "HICOPRO",
                "SecurityName": "HICO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506480",
                "SecurityId": "GOCLCORP",
                "SecurityName": "GOCL CORPORATION LTD-$",
                "FaceValue": "2",
                "ISINNo": "INE077F01035",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506485",
                "SecurityId": "IDI",
                "SecurityName": "IDI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE888A01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506490",
                "SecurityId": "INDELTC",
                "SecurityName": "INDIAN ELECTRO CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506515",
                "SecurityId": "JKINVESTR",
                "SecurityName": "J.K.INVESTO TRADE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE361G01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506518",
                "SecurityId": "JYNTVTM",
                "SecurityName": "JAYANT VITAMINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506520",
                "SecurityId": "JAYCH",
                "SecurityName": "JAYSHREE CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE693E01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506522",
                "SecurityId": "JLMORI",
                "SecurityName": "J.L.MORISON (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE430D01015",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506525",
                "SecurityId": "KANORICHEM",
                "SecurityName": "KANORIA CHEMICALS & INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE138C01024",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506528",
                "SecurityId": "KELENRG",
                "SecurityName": "KELTECH ENERGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE881E01017",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506530",
                "SecurityId": "KEMP",
                "SecurityName": "KEMP & COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE060E01018",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506532",
                "SecurityId": "NITTAGELA",
                "SecurityName": "NITTA GELATIN INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE265B01019",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506535",
                "SecurityId": "ZKEYTUCH",
                "SecurityName": "KEYTUO CHEMICALS LTD.",
                "FaceValue": "100",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506540",
                "SecurityId": "KUTCSAL",
                "SecurityName": "KUTCH SALT & ALLIED INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506543",
                "SecurityId": "MPAGI",
                "SecurityName": "M.P.AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE468C01017",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506559",
                "SecurityId": "SIEHEALTH",
                "SecurityName": "SIEMENS HEALTHCARE DIAGNOSTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE195D01014",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506579",
                "SecurityId": "ORIENTCQ",
                "SecurityName": "ORIENTAL CARBON & CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE321D01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506580",
                "SecurityId": "PBGLOBAL",
                "SecurityName": "PB GLOBAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE615W01011",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506590",
                "SecurityId": "PHILIPCARB",
                "SecurityName": "PHILLIPS CARBON BLACK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE602A01023",
                "Industry": "Carbon Black",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506597",
                "SecurityId": "AMAL",
                "SecurityName": "AMAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE841D01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506605",
                "SecurityId": "POLYCHEM",
                "SecurityName": "POLYCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE752B01024",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506614",
                "SecurityId": "RAJPCHM",
                "SecurityName": "RAJ PRAKASH CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506615",
                "SecurityId": "SUNASIAN",
                "SecurityName": "SUNRISE ASIAN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE917D01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506618",
                "SecurityId": "PUNJABCHEM",
                "SecurityName": "PUNJAB CHEMICALS AND CROP PROTECTION LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE277B01014",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506640",
                "SecurityId": "GUJCMDS",
                "SecurityName": "GUJCHEM DISTILLERS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE218N01013",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506642",
                "SecurityId": "SADHNANIQ",
                "SecurityName": "SADHANA NITROCHEM LTD.",
                "FaceValue": "1",
                "ISINNo": "INE888C01040",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506644",
                "SecurityId": "SEARSOLDM",
                "SecurityName": "SEARSOLE CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506650",
                "SecurityId": "ZSOMAIYO",
                "SecurityName": "SOMAIYA ORGANICS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506655",
                "SecurityId": "SUDARSCHEM",
                "SecurityName": "SUDARSHAN CHEMICAL INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE659A01023",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506660",
                "SecurityId": "SYNTHCHEM",
                "SecurityName": "SYNTHETICS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE024A01012",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506680",
                "SecurityId": "TRELCHE-B",
                "SecurityName": "TECIL CHEMICALS & HYDRO POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE014B01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506685",
                "SecurityId": "ULTRAMAR",
                "SecurityName": "ULTRAMARINE & PIGMENTS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE405A01021",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506687",
                "SecurityId": "TRANSPEK",
                "SecurityName": "TRANSPEK INDUSTRY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE687A01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506690",
                "SecurityId": "UNICHEMLAB",
                "SecurityName": "UNICHEM LABORATORIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE351A01035",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506700",
                "SecurityId": "CABINDM",
                "SecurityName": "CABOT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE144B01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506718",
                "SecurityId": "SWASURF",
                "SecurityName": "SWASTIK SURFACTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506720",
                "SecurityId": "ZANDUREALT",
                "SecurityName": "ZANDU REALTY LIMITED",
                "FaceValue": "100",
                "ISINNo": "INE719A01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506734",
                "SecurityId": "MYSORPETRO",
                "SecurityName": "MYSORE PETRO CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE741A01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506737",
                "SecurityId": "HINDLEVCHM",
                "SecurityName": "HIND LEVER CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE336A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506747",
                "SecurityId": "RHONPDM",
                "SecurityName": "RHONE-POULENCE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE223A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506758",
                "SecurityId": "PAUSHAK",
                "SecurityName": "PAUSHAK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE449D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506767",
                "SecurityId": "ALKYLAMINE",
                "SecurityName": "ALKYL AMINES CHEMICALS LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE150B01021",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506803",
                "SecurityId": "FULFORD",
                "SecurityName": "FULFORD (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE521A01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506808",
                "SecurityId": "TUTIALKA",
                "SecurityName": "TUTICORIN ALKALI CHEMICALS & FERTILISERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE400A01014",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506810",
                "SecurityId": "DIVYCHM",
                "SecurityName": "DIVIYA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506817",
                "SecurityId": "PHARMACIAH",
                "SecurityName": "PHARMACIA HEALTHCARE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE252B01025",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506820",
                "SecurityId": "ASTRAZEN",
                "SecurityName": "ASTRAZENECA PHARMA INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE203A01020",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506822",
                "SecurityId": "AIPCL",
                "SecurityName": "AIKYAM INTELLECTUAL PROPERTY CONSULTANCY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE739N01026",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506840",
                "SecurityId": "UDAYPCH",
                "SecurityName": "UDAYPCH",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506850",
                "SecurityId": "PUNNFERDM",
                "SecurityName": "PUNJAB NATIONAL FERTILIZERS & CHEMICALS LTD.",
                "FaceValue": "5",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506852",
                "SecurityId": "PACL",
                "SecurityName": "PUNJAB ALKALIES & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE607A01014",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506854",
                "SecurityId": "TANFACIND",
                "SecurityName": "TANFAC INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE639B01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506858",
                "SecurityId": "GUJPETR",
                "SecurityName": "GUJARAT PETROSYNTHESE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE636P01011",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506863",
                "SecurityId": "SWADEIN",
                "SecurityName": "SWADESHI INDUSTRIES LEASING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE716M01034",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506867",
                "SecurityId": "MASCH",
                "SecurityName": "MASTER CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE523D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506871",
                "SecurityId": "MAHAXPO",
                "SecurityName": "MAHARASHTRA EXPLOSIVES LTD.",
                "FaceValue": "5",
                "ISINNo": "NA          ",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506873",
                "SecurityId": "MAHEXCH",
                "SecurityName": "MAHEXCH",
                "FaceValue": "0.1",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506874",
                "SecurityId": "SHREEJAL",
                "SecurityName": "SHREEJAL INFO HUBS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE765C01024",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506879",
                "SecurityId": "GUJTHEM",
                "SecurityName": "GUJARAT THEMIS BIOSYN LTD.",
                "FaceValue": "5",
                "ISINNo": "INE942C01029",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506883",
                "SecurityId": "PIRHL",
                "SecurityName": "PIRHL",
                "FaceValue": "10",
                "ISINNo": "INE144A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506894",
                "SecurityId": "CHEMFALKAL",
                "SecurityName": "CHEMFAB ALKALIS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE479E01028",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506895",
                "SecurityId": "MERIN",
                "SecurityName": "MERIN",
                "FaceValue": "10",
                "ISINNo": "INE215A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506899",
                "SecurityId": "VARACDM",
                "SecurityName": "VARINDER AGRO CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE200E01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506900",
                "SecurityId": "SHACIDS",
                "SecurityName": "SHREE ACIDS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE142C01018",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506906",
                "SecurityId": "SCBL",
                "SecurityName": "SAPTAK CHEM AND BUSINESS LTD",
                "FaceValue": "10",
                "ISINNo": "INE467X01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506910",
                "SecurityId": "JAYSYN",
                "SecurityName": "JAYSYNTH DYESTUFF (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE703C01025",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506919",
                "SecurityId": "MAKERSL",
                "SecurityName": "MAKERS LABORATORIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE987A01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506923",
                "SecurityId": "ZGUJBNIL",
                "SecurityName": "GUJARAT BINIL CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506935",
                "SecurityId": "CONTCHM",
                "SecurityName": "CONTINENTAL CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE423K01015",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506938",
                "SecurityId": "KAPPH",
                "SecurityName": "KAPPAC PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE601D01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506940",
                "SecurityId": "HARPCDM",
                "SecurityName": "HARYANA PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE753C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506943",
                "SecurityId": "JBCHEPHARM",
                "SecurityName": "J.B.CHEMICALS & PHARMACEUTICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE572A01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506945",
                "SecurityId": "IGLFXPL-B",
                "SecurityName": "INDO GULF INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE684U01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506947",
                "SecurityId": "ADVPETR-B",
                "SecurityName": "ADVANCE PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE334N01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506949",
                "SecurityId": "NIRUPSYDM",
                "SecurityName": "NIRUP SYNCHROME LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506952",
                "SecurityId": "INDGPRO",
                "SecurityName": "INDAG PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506954",
                "SecurityId": "MOTOROLSP",
                "SecurityName": "MOTOROL SPECIALITY OILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE642B01019",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506959",
                "SecurityId": "ZRATHIND",
                "SecurityName": "RATHI INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506965",
                "SecurityId": "AGROCHM",
                "SecurityName": "AGRO CHEM PUNJAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506971",
                "SecurityId": "BBREALTY",
                "SecurityName": "B&B REALTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE314E01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506975",
                "SecurityId": "RAJSPTR",
                "SecurityName": "RAJASTHAN PETRO SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE374C01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506979",
                "SecurityId": "APTPACK",
                "SecurityName": "APT PACKAGING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE046E01017",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506981",
                "SecurityId": "BLUECHIPT",
                "SecurityName": "BLUE CHIP TEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE472D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506985",
                "SecurityId": "TWILITAKA",
                "SecurityName": "TWILIGHT LITAKA PHARMA LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE783B01029",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506987",
                "SecurityId": "GWPLPIP",
                "SecurityName": "GWALIOR POLYPIPES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506989",
                "SecurityId": "RGNTCHM",
                "SecurityName": "REGENT CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506991",
                "SecurityId": "NOBLEXP",
                "SecurityName": "NOBLE EXPLOCHEM LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE875D01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "506998",
                "SecurityId": "HINDACH",
                "SecurityName": "HINDUSTAN AGRO CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507000",
                "SecurityId": "SHRISHM",
                "SecurityName": "SHRISHMA FINE CHEMICALS & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507050",
                "SecurityId": "BELAIND",
                "SecurityName": "BELAPUR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE490D01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507070",
                "SecurityId": "BSILTD",
                "SecurityName": "BSI LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507135",
                "SecurityId": "GWALSUG",
                "SecurityName": "GWALIOR SUGAR CO.LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507138",
                "SecurityId": "HARBRDM",
                "SecurityName": "HARYANA BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE175D01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507140",
                "SecurityId": "ZHINDBRE",
                "SecurityName": "HINDUSTAN BREWERIES & BOTTLING LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507150",
                "SecurityId": "INDSUGA",
                "SecurityName": "INDIA SUGARS & REFINERIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507155",
                "SecurityId": "JAGAJITIND",
                "SecurityName": "JAGATJIT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE574A01016",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507180",
                "SecurityId": "KESARENT",
                "SecurityName": "KESAR ENTERPRISES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE133B01019",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507190",
                "SecurityId": "KOTHARIS",
                "SecurityName": "KOTHARI SUGARS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE419A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507205",
                "SecurityId": "TI",
                "SecurityName": "TILAKNAGAR INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE133E01013",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507260",
                "SecurityId": "OUDHSUG",
                "SecurityName": "OUDH SUGAR MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE594A01014",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507265",
                "SecurityId": "EMERALL",
                "SecurityName": "EMERALD LEISURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE044N01013",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507270",
                "SecurityId": "ARLMBRWDM",
                "SecurityName": "ARLEM BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507300",
                "SecurityId": "RAVALSUGAR",
                "SecurityName": "RAVALGAON SUGAR FARM LTD.",
                "FaceValue": "50",
                "ISINNo": "INE615A01017",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507315",
                "SecurityId": "SAKHTISUG",
                "SecurityName": "SAKTHI SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE623A01011",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507348",
                "SecurityId": "SICBRDM",
                "SecurityName": "SICA BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE262D01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507352",
                "SecurityId": "SKOLBDM",
                "SecurityName": "SKOL BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE231D01025",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507405",
                "SecurityId": "VISHNSG",
                "SecurityName": "VISHNU SUGAR MILLS LTD.",
                "FaceValue": "100",
                "ISINNo": "INE211K01014",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507410",
                "SecurityId": "WALCHANNAG",
                "SecurityName": "WALCHANDNAGAR INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE711A01022",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507427",
                "SecurityId": "PONNI",
                "SecurityName": "PONNI SUGARS (ORISSA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE096D01022",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507430",
                "SecurityId": "CHAMPGN",
                "SecurityName": "CHAMPGN",
                "FaceValue": "10",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507435",
                "SecurityId": "KHODAY",
                "SecurityName": "KHODAY INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE687B01014",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507438",
                "SecurityId": "IFBAGRO",
                "SecurityName": "IFB AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE076C01018",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507442",
                "SecurityId": "DHARSUGAR",
                "SecurityName": "DHARANI SUGARS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE988C01014",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507446",
                "SecurityId": "SIMBHSUGAR",
                "SecurityName": "SIMBHAOLI SUGARS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE270C01017",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507448",
                "SecurityId": "MACAGRO",
                "SecurityName": "MACAGRO",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507450",
                "SecurityId": "THIRUSUGAR",
                "SecurityName": "THIRU AROORAN SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE409A01015",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507456",
                "SecurityId": "RAJBREW",
                "SecurityName": "RAJASTHAN BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507458",
                "SecurityId": "UBHOLDINGS",
                "SecurityName": "UNITED BREWERIES (HOLDINGS) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE696A01025",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507466",
                "SecurityId": "WCOSTSG",
                "SecurityName": "WEST COAST BREWERIES & DISTILLERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507468",
                "SecurityId": "SHVANI",
                "SecurityName": "SHREE VAANI SUGARS AND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE106E01019",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507472",
                "SecurityId": "VENUSUG",
                "SecurityName": "VENUS SUGAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE972B01010",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507474",
                "SecurityId": "KFBL",
                "SecurityName": "KOTHARI FERMENTATION & BIOCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE991B01010",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507476",
                "SecurityId": "BLOSIND",
                "SecurityName": "BLOSSOM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE264D01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507480",
                "SecurityId": "ISHMS",
                "SecurityName": "ISHWAR MEDICAL SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE058C01024",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507482",
                "SecurityId": "DUJOHN",
                "SecurityName": "DUJOHN LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE990A01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507484",
                "SecurityId": "SRCHRDM",
                "SecurityName": "SRI CHAKRA REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE840B01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507486",
                "SecurityId": "CAPRO",
                "SecurityName": "CAPROLACTAM CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE470N01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507488",
                "SecurityId": "GMBREW",
                "SecurityName": "G.M.BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE075D01018",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507490",
                "SecurityId": "RANASUG",
                "SecurityName": "RANA SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE625B01014",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507494",
                "SecurityId": "CHABRDM",
                "SecurityName": "CHARMINAR BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE173D01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507498",
                "SecurityId": "PICCASUG",
                "SecurityName": "PICCADILY SUGAR & ALLIED INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE544C01023",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507506",
                "SecurityId": "GIRDSGA",
                "SecurityName": "GIRDHARILAL SUGAR & ALLIED INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE310M01010",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507508",
                "SecurityId": "RIGASUG",
                "SecurityName": "RIGA SUGAR COMPANY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE909C01010",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507514",
                "SecurityId": "SDBL",
                "SecurityName": "SOM DISTILLERIES & BREWERIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE480C01020",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507515",
                "SecurityId": "CJGEL",
                "SecurityName": "C.J.GELATINE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE557D01015",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507517",
                "SecurityId": "SULINDI",
                "SecurityName": "SUL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507518",
                "SecurityId": "SUPDF",
                "SecurityName": "SUPERSTAR DISTILLERIES & FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE543D01015",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507520",
                "SecurityId": "RAINBRE",
                "SecurityName": "RAINBOW BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507522",
                "SecurityId": "MOUNTSHIQ",
                "SecurityName": "MOUNT SHIVALIK INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE410C01019",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507525",
                "SecurityId": "AMRITCORP",
                "SecurityName": "AMRIT CORP.LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE866E01026",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507526",
                "SecurityId": "ASALCBR",
                "SecurityName": "ASSOCIATED ALCOHOLS & BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE073G01016",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507528",
                "SecurityId": "EASTSUGIND",
                "SecurityName": "EASTERN SUGAR & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE889B01016",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507530",
                "SecurityId": "ASIFOOD",
                "SecurityName": "ASIAN FOOD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE04VT01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507539",
                "SecurityId": "INBEFDM",
                "SecurityName": "INTERNATIONAL BESTFOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE792A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507543",
                "SecurityId": "CORAGRO",
                "SecurityName": "COROMANDEL AGRO PRODUCTS & OILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE495D01018",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507552",
                "SecurityId": "FOODSIN",
                "SecurityName": "FOODS & INNS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE976E01023",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507580",
                "SecurityId": "IVP",
                "SecurityName": "IVP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE043C01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507598",
                "SecurityId": "KLRFM",
                "SecurityName": "KOVILPATTI LAKSHMI ROLLER FLOUR MILLS LTD",
                "FaceValue": "10",
                "ISINNo": "INE014E01015",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507600",
                "SecurityId": "ZLAXMSTADM",
                "SecurityName": "LAXMI STARCH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507609",
                "SecurityId": "OLYOI",
                "SecurityName": "OLYMPIC OIL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE286E01019",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507621",
                "SecurityId": "MLKFOOD",
                "SecurityName": "MILKFOOD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE588G01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507645",
                "SecurityId": "POLSON",
                "SecurityName": "POLSON LTD.",
                "FaceValue": "50",
                "ISINNo": "INE339F01021",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507649",
                "SecurityId": "RASOI",
                "SecurityName": "RASOI LTD.",
                "FaceValue": "200",
                "ISINNo": "INE349E01023",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507650",
                "SecurityId": "ZSATHEBI",
                "SecurityName": "SATHE BISCUIT & CHOCOLATE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507652",
                "SecurityId": "RATNAMAGRO",
                "SecurityName": "RATNAMANI AGRO INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE679N01016",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507663",
                "SecurityId": "SAGRSOY-B",
                "SecurityName": "SAGAR SOYA PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE131O01024",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507668",
                "SecurityId": "TRISTR",
                "SecurityName": "TRI-STAR SOYA PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507682",
                "SecurityId": "MHSHPRT",
                "SecurityName": "MAHESHWARI PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507685",
                "SecurityId": "WIPRO",
                "SecurityName": "WIPRO LTD.",
                "FaceValue": "2",
                "ISINNo": "INE075A01022",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507690",
                "SecurityId": "ORIBEVER",
                "SecurityName": "ORIENT BEVERAGES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE247F01018",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507707",
                "SecurityId": "PRESHMP-B",
                "SecurityName": "PRESTIGE HM POLYCONTAINERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507709",
                "SecurityId": "PHARLAB-B",
                "SecurityName": "PHAR EAST LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507711",
                "SecurityId": "GILTPKG",
                "SecurityName": "GILT PACK LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507715",
                "SecurityId": "EBEPH",
                "SecurityName": "EBERS PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE669D01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507717",
                "SecurityId": "DHANUKA",
                "SecurityName": "DHANUKA AGRITECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE435G01025",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507722",
                "SecurityId": "DEEPPHR-B",
                "SecurityName": "DEE-PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507725",
                "SecurityId": "SHVFERT",
                "SecurityName": "SFL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507728",
                "SecurityId": "SANDRSN",
                "SecurityName": "SANDERSON INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507732",
                "SecurityId": "GODAVRFERT",
                "SecurityName": "GODAVARI FERTILISERS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE507C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507743",
                "SecurityId": "DRSABHP",
                "SecurityName": "DR. SABHARWAL&#39;S MFG. LABS. LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Healthcare Supplies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507745",
                "SecurityId": "HARSCHM",
                "SecurityName": "HARSHVARDHAN CHEMICALS & MINERALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507747",
                "SecurityId": "TTKHEALTH",
                "SecurityName": "TTK HEALTHCARE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE910C01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507751",
                "SecurityId": "MJPHADM",
                "SecurityName": "M.J.PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE434D01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507753",
                "SecurityId": "TGVSL",
                "SecurityName": "TGV SRAAC LTD",
                "FaceValue": "10",
                "ISINNo": "INE284B01028",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507756",
                "SecurityId": "CEPHORG",
                "SecurityName": "CEPHAM ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507759",
                "SecurityId": "LIMECHM",
                "SecurityName": "LIME CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE891G01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507761",
                "SecurityId": "TEEM",
                "SecurityName": "TEEM LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE202D01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507764",
                "SecurityId": "GUJPLYW",
                "SecurityName": "GUJARAT POLYWEAVE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507777",
                "SecurityId": "SILTP",
                "SecurityName": "SILTAP CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE637A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507779",
                "SecurityId": "KANPRPLA",
                "SecurityName": "KANPUR PLASTIPACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE694E01014",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507785",
                "SecurityId": "TAINWALCHM",
                "SecurityName": "TAINWALA CHEMICALS & PLASTICS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE123C01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507789",
                "SecurityId": "JAGSNPHARM",
                "SecurityName": "JAGSONPAL PHARMACEUTICALS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE048B01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507790",
                "SecurityId": "GUJPRDM",
                "SecurityName": "GUJARAT PROPACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE956D01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507794",
                "SecurityId": "KHAICHEM",
                "SecurityName": "KHAITAN CHEMICALS & FERTILIZERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE745B01028",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507796",
                "SecurityId": "JHAVFLEXO",
                "SecurityName": "JHAVERI FLEXO INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE114C01025",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507808",
                "SecurityId": "UNTTEMI",
                "SecurityName": "UNITED LEASING & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE357P01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507813",
                "SecurityId": "NOL",
                "SecurityName": "NATIONAL OXYGEN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE296D01010",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507815",
                "SecurityId": "GILLETTE",
                "SecurityName": "GILLETTE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE322A01010",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507817",
                "SecurityId": "WIREFABR",
                "SecurityName": "WIRES & FABRIKS (SA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE469D01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507819",
                "SecurityId": "VOLEX",
                "SecurityName": "VOLEX FINANCE & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE227D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507821",
                "SecurityId": "TURNKEYDM",
                "SecurityName": "TURNKEY INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507828",
                "SecurityId": "ANSALHSG",
                "SecurityName": "ANSAL HOUSING LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE880B01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507831",
                "SecurityId": "PREMRIN",
                "SecurityName": "PREMIER INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507833",
                "SecurityId": "COMPUPN",
                "SecurityName": "COMPUTER POINT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE607B01012",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507836",
                "SecurityId": "MCCHRLS-B",
                "SecurityName": "MAC CHARLES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE435D01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507842",
                "SecurityId": "KOTRIFNDM",
                "SecurityName": "KOTHARI ORIENT FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507852",
                "SecurityId": "ADDIND",
                "SecurityName": "ADDI INDUSTRIES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE757C01021",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507864",
                "SecurityId": "PIONRINV",
                "SecurityName": "PIONEER INVESTCORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE746D01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507866",
                "SecurityId": "GUJOILD",
                "SecurityName": "GUJARAT OIL AND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507869",
                "SecurityId": "VIJAYCO",
                "SecurityName": "VIJAYA COMMERCIAL CREDIT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507870",
                "SecurityId": "LLOYDFIN",
                "SecurityName": "LLOYDS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE174A01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507872",
                "SecurityId": "ASHNOOR",
                "SecurityName": "ASHNOOR TEXTILE MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE372I01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507874",
                "SecurityId": "MALABARB",
                "SecurityName": "MALABAR BUILDING PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE093E01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507878",
                "SecurityId": "UNITECH",
                "SecurityName": "UNITECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE694A01020",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507880",
                "SecurityId": "VIPIND",
                "SecurityName": "V.I.P.INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE054A01027",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507884",
                "SecurityId": "NUMEM",
                "SecurityName": "NUMECH EMBALLAGE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE679D01017",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507886",
                "SecurityId": "DHRUVES",
                "SecurityName": "DHRUV ESTATES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE780E01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507892",
                "SecurityId": "WINSOMEDJ",
                "SecurityName": "WINSOME DIAMONDS AND JEWELLERY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE664A01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507894",
                "SecurityId": "SCHABLON",
                "SecurityName": "SCHABLONA INDIA LTD.",
                "FaceValue": "4",
                "ISINNo": "INE024C01026",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507896",
                "SecurityId": "DYNASFN",
                "SecurityName": "DYNASTY FINANCIAL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507898",
                "SecurityId": "JAIYATA",
                "SecurityName": "JAI YATAYAT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507906",
                "SecurityId": "HOYSBLO",
                "SecurityName": "HOYSALA BLOW MOULDERS (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507910",
                "SecurityId": "FIBERWEB",
                "SecurityName": "FIBERWEB (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE296C01020",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507912",
                "SecurityId": "LKPFIN",
                "SecurityName": "LKP FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE724A01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507914",
                "SecurityId": "SAMRTHS",
                "SecurityName": "SAMRAT HOUSING & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507917",
                "SecurityId": "EASTBUILD",
                "SecurityName": "EAST BUILDTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE706N01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507918",
                "SecurityId": "ARUMUGA",
                "SecurityName": "SRI ARUMUGA ENTERPRISE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE240L01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507920",
                "SecurityId": "ZGOLDHOL",
                "SecurityName": "SRI CHAKRA FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507922",
                "SecurityId": "BLCHPLE",
                "SecurityName": "BLUE CHIP LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507924",
                "SecurityId": "JAYBFAB",
                "SecurityName": "JAYBHARAT FABRICS MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507926",
                "SecurityId": "WRLDLNK",
                "SecurityName": "WORLDLINK FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507928",
                "SecurityId": "KUDRINV",
                "SecurityName": "KUDRAT INVESTMENTS & LEASING (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507933",
                "SecurityId": "ALEXCON",
                "SecurityName": "ALEXCON FOAMCAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE225A01015",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507935",
                "SecurityId": "INOVTV",
                "SecurityName": "INNOVATIVE PRINT FORMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507938",
                "SecurityId": "MNPLFIN",
                "SecurityName": "MANIPAL FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE798E01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507940",
                "SecurityId": "HIGHL",
                "SecurityName": "HIGHLAND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE672D01012",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507942",
                "SecurityId": "JOGENG",
                "SecurityName": "JOG ENGINEERING LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE941A01017",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507944",
                "SecurityId": "BAJAJST",
                "SecurityName": "BAJAJ STEEL INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE704G01024",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507946",
                "SecurityId": "KIDUJA",
                "SecurityName": "KIDUJA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE845A01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507948",
                "SecurityId": "KEYCORP",
                "SecurityName": "KEY CORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE130F01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507952",
                "SecurityId": "SHIKHARLETR",
                "SecurityName": "SHIKHAR LEASING & TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE02BV01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507954",
                "SecurityId": "VITAMZD",
                "SecurityName": "VITTA MAZDA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507956",
                "SecurityId": "CONCON",
                "SecurityName": "CONTINENTAL CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE970B01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507960",
                "SecurityId": "GUJHOTE",
                "SecurityName": "GUJARAT HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE621C01011",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507962",
                "SecurityId": "RAJATH",
                "SecurityName": "RAJATH FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE455H01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507964",
                "SecurityId": "ALIANCP",
                "SecurityName": "ALLIANCE CAPITAL & FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507966",
                "SecurityId": "RASRESOR",
                "SecurityName": "RAS RESORTS & APART HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE651D01016",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507968",
                "SecurityId": "JOLYPLS",
                "SecurityName": "JOLLY PLASTIC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE289M01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507970",
                "SecurityId": "PARMCOS-B",
                "SecurityName": "PARAMOUNT COSMETICS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE143I01013",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507981",
                "SecurityId": "JINDHOT",
                "SecurityName": "JINDAL HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE726D01016",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507984",
                "SecurityId": "SERIND",
                "SecurityName": "SER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE358F01013",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507987",
                "SecurityId": "JPTRLES",
                "SecurityName": "JUPITER INDUSTRIES & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE990E01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507997",
                "SecurityId": "OPGMTFN",
                "SecurityName": "OPG METAL & FINSEC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "507998",
                "SecurityId": "SIMMOND",
                "SecurityName": "SIMMONDS MARSHALL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE657D01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508136",
                "SecurityId": "BNALTD",
                "SecurityName": "B & A LTD.",
                "FaceValue": "10",
                "ISINNo": "INE489D01011",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508197",
                "SecurityId": "DPIL",
                "SecurityName": "DPIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE137D01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508238",
                "SecurityId": "WTAL",
                "SecurityName": "WILLIAMSON TEA ASSAM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE222A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508274",
                "SecurityId": "ROSINDM",
                "SecurityName": "ROSSELL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE166D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508306",
                "SecurityId": "LEDOTEA",
                "SecurityName": "LEDO TEA CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE643B01017",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508486",
                "SecurityId": "HAWKINCOOK",
                "SecurityName": "HAWKINS COOKERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE979B01015",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508494",
                "SecurityId": "WARRENTEA",
                "SecurityName": "WARREN TEA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE712A01012",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508571",
                "SecurityId": "COCHMAL",
                "SecurityName": "COCHIN MALABAR ESTATES & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE788M01017",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508664",
                "SecurityId": "BESTEAST",
                "SecurityName": "BEST EASTERN HOTELS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE553F01035",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508670",
                "SecurityId": "NEAGI",
                "SecurityName": "NEELAMALAI AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE605D01012",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508671",
                "SecurityId": "SATELINFO",
                "SecurityName": "SATELLITE INFOCONCEPTS LTD",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508800",
                "SecurityId": "UNIQEST",
                "SecurityName": "UNIQUE ESTATES DEVELOPMENTS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508807",
                "SecurityId": "ISTLTD",
                "SecurityName": "IST LTD.",
                "FaceValue": "5",
                "ISINNo": "INE684B01029",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508809",
                "SecurityId": "GORDONW",
                "SecurityName": "GORDON WOODROFFE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE595C01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508814",
                "SecurityId": "COSMOFILMS",
                "SecurityName": "COSMO FILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE757A01017",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508815",
                "SecurityId": "FICSCON",
                "SecurityName": "FICS CONSULTANCY SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE651J01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508833",
                "SecurityId": "ZASIATPR",
                "SecurityName": "ASIATIC PROPERTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508853",
                "SecurityId": "ZKAMPACO",
                "SecurityName": "KAMPANI CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE590J01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508857",
                "SecurityId": "LUSTRTI",
                "SecurityName": "LUSTRE TILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508860",
                "SecurityId": "DIAMANT",
                "SecurityName": "DIAMANT INFRASTRUCTURE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE206I01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508867",
                "SecurityId": "NEWMKTADV",
                "SecurityName": "NEW MARKETS ADVISORY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE800K01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508869",
                "SecurityId": "APOLLOHOSP",
                "SecurityName": "APOLLO HOSPITALS ENTERPRISE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE437A01024",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508875",
                "SecurityId": "NITINCAST",
                "SecurityName": "NITIN CASTINGS LTD",
                "FaceValue": "5",
                "ISINNo": "INE861H01020",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508900",
                "SecurityId": "ZENCAP",
                "SecurityName": "ZENITH CAPITALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE132N01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508905",
                "SecurityId": "SMIFS",
                "SecurityName": "SMIFS CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE641A01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508906",
                "SecurityId": "EVERESTIND",
                "SecurityName": "EVEREST INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE295A01018",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508908",
                "SecurityId": "BELLREMDM",
                "SecurityName": "BELL REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508910",
                "SecurityId": "ZPROGLEA",
                "SecurityName": "PROGRESSIVE METAL FORMING & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508912",
                "SecurityId": "ZSNEHCON",
                "SecurityName": "SNEH CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508914",
                "SecurityId": "WINDSCP",
                "SecurityName": "WINDSOR CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508916",
                "SecurityId": "MIDINDIDM",
                "SecurityName": "MIDWEST INDIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508918",
                "SecurityId": "GREYCELLS",
                "SecurityName": "GREYCELLS EDUCATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE791H01011",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508922",
                "SecurityId": "MSRINDIA",
                "SecurityName": "MSR INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE331L01026",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508924",
                "SecurityId": "NORTHPR",
                "SecurityName": "NORTHERN PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE01CZ01010",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508929",
                "SecurityId": "JOYREALTY",
                "SecurityName": "JOY REALTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE433O01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508933",
                "SecurityId": "AYMSYNTEX",
                "SecurityName": "AYM SYNTEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE193B01039",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508937",
                "SecurityId": "KIRTIMO",
                "SecurityName": "KIRTIMOYA CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508939",
                "SecurityId": "BLCISER",
                "SecurityName": "BLUE CIRCLE SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE526K01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508941",
                "SecurityId": "PANCARBON",
                "SecurityName": "PANASONIC CARBON INDIA CO.LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE013E01017",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508954",
                "SecurityId": "FINKURVE",
                "SecurityName": "FINKURVE FINANCIAL SERVICES LTD",
                "FaceValue": "1",
                "ISINNo": "INE734I01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508956",
                "SecurityId": "HBLEAS",
                "SecurityName": "HB LEASING & FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE549B01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508961",
                "SecurityId": "SHRICON",
                "SecurityName": "SHRICON INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE753D01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508963",
                "SecurityId": "STRLGUA",
                "SecurityName": "STERLING GUARANTY & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE668Y01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508965",
                "SecurityId": "MOTOROL",
                "SecurityName": "MOTOROL",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508969",
                "SecurityId": "SULABEN",
                "SecurityName": "SULABH ENGINEERS & SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE673M01029",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508976",
                "SecurityId": "SPANCO",
                "SecurityName": "SPANCO LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE360B01026",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508978",
                "SecurityId": "SPLTECHNO",
                "SecurityName": "SPL TECHNOCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE481C01010",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508980",
                "SecurityId": "FRONTCAP",
                "SecurityName": "FRONTIER CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE977E01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508982",
                "SecurityId": "LINKSONI",
                "SecurityName": "LINKSON INTERNATIONAL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE996E01021",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508984",
                "SecurityId": "BHAHODM",
                "SecurityName": "BHARAT HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE466A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508987",
                "SecurityId": "ABACUS",
                "SecurityName": "ABACUS COMPUTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE192D01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508989",
                "SecurityId": "NAVNETEDUL",
                "SecurityName": "NAVNEET EDUCATION LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE060A01024",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508993",
                "SecurityId": "KEDIACN",
                "SecurityName": "KEDIA CONSTRUCTION CO.LTD.",
                "FaceValue": "5",
                "ISINNo": "INE511J01027",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508996",
                "SecurityId": "SATRAPROP",
                "SecurityName": "SATRA PROPERTIES (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE086E01021",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "508998",
                "SecurityId": "STEERINTER",
                "SecurityName": "STERLING INTERNATIONAL ENTERPRISES LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE696C01021",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509000",
                "SecurityId": "DAGALEA",
                "SecurityName": "DAGA LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509001",
                "SecurityId": "EMPHOTR",
                "SecurityName": "EMPIRE HOTELS & RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509003",
                "SecurityId": "TRANOCE",
                "SecurityName": "TRANSOCEANIC PROPERTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE074J01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509007",
                "SecurityId": "PAGITLS",
                "SecurityName": "PAGITA LEASING & FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509009",
                "SecurityId": "AUSOMENT",
                "SecurityName": "AUSOM ENTERPRISE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE218C01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509011",
                "SecurityId": "CHISEL",
                "SecurityName": "CHISEL & HAMMER (MOBEL) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE426G01016",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509015",
                "SecurityId": "THAKRAL",
                "SecurityName": "THAKRAL SERVICES (INDIA) LTD.",
                "FaceValue": "3",
                "ISINNo": "INE190F01028",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509020",
                "SecurityId": "RUCHINFRA",
                "SecurityName": "RUCHI INFRASTRUCTURE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE413B01023",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509022",
                "SecurityId": "DOWELEA",
                "SecurityName": "DOWELL LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509024",
                "SecurityId": "GOLDLEG",
                "SecurityName": "GOLDEN LEGAND LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE088E01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509026",
                "SecurityId": "VJTFEDU",
                "SecurityName": "VJTF EDUSERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE117F01013",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509028",
                "SecurityId": "ESERVE",
                "SecurityName": "E-SERVE INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE784A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509030",
                "SecurityId": "JAFLEAS",
                "SecurityName": "JAFFERSON LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509034",
                "SecurityId": "KALPVIND",
                "SecurityName": "KALPAVRIKSHA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE815D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509038",
                "SecurityId": "VOLLF",
                "SecurityName": "VOLTAIRE LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE763D01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509040",
                "SecurityId": "NETLINK",
                "SecurityName": "NETLINK SOLUTIONS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE040F01033",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509046",
                "SecurityId": "ZLEENCON",
                "SecurityName": "LEENA CONSULTANCY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE778N01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509048",
                "SecurityId": "LANCORHOL",
                "SecurityName": "LANCOR HOLDINGS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE572G01025",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509051",
                "SecurityId": "INDINFO",
                "SecurityName": "INDIAN INFOTECH & SOFTWARE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE300B01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509053",
                "SecurityId": "BANASFN",
                "SecurityName": "BANAS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE521L01030",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509055",
                "SecurityId": "VISAKAIND",
                "SecurityName": "VISAKA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE392A01013",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509057",
                "SecurityId": "GHARFIL",
                "SecurityName": "GHAR FINANCE & LEASING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509063",
                "SecurityId": "ZHIMATMO",
                "SecurityName": "HIMATSINGKA MOTOR WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509069",
                "SecurityId": "INFOMEDIA",
                "SecurityName": "INFOMEDIA PRESS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE669A01022",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509073",
                "SecurityId": "HATHWAYB",
                "SecurityName": "HATHWAY BHAWANI CABLETEL & DATACOM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE525B01016",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509077",
                "SecurityId": "PRESSMN",
                "SecurityName": "PRESSMAN ADVERTISING LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE980A01023",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509079",
                "SecurityId": "GUFICBIO",
                "SecurityName": "GUFIC BIOSCIENCES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE742B01025",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509083",
                "SecurityId": "MIDLIND",
                "SecurityName": "MIDLAND INDUSTRIES AND LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509084",
                "SecurityId": "PHOTON",
                "SecurityName": "PHOTON CAPITAL ADVISORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE107J01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509087",
                "SecurityId": "GROVERLE",
                "SecurityName": "GROVER LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE930B01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509094",
                "SecurityId": "HAJIMAN",
                "SecurityName": "HAJI MANZOOR ALAM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509099",
                "SecurityId": "OSWALEA",
                "SecurityName": "OSWAL LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE811Q01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509110",
                "SecurityId": "ARICONS",
                "SecurityName": "ARI CONSOLIDATED INVESTMENTS LTD.",
                "FaceValue": "100",
                "ISINNo": "INE233I01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509117",
                "SecurityId": "MODISTO",
                "SecurityName": "MODISTONE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509130",
                "SecurityId": "DUNLOP-B1",
                "SecurityName": "DUNLOP INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE509A01012",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509148",
                "SecurityId": "GOVINDRU",
                "SecurityName": "GOVIND RUBBER LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE011C01015",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509152",
                "SecurityId": "GRPLTD",
                "SecurityName": "GRP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE137I01015",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509162",
                "SecurityId": "INDAG",
                "SecurityName": "INDAG RUBBER LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE802D01023",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509180",
                "SecurityId": "ZKERARUB",
                "SecurityName": "KERALA RUBBER & RECLAIMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509196",
                "SecurityId": "MMRUBBR-B",
                "SecurityName": "MM RUBBER COMPANY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE159E01026",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509220",
                "SecurityId": "PTL",
                "SecurityName": "PTL ENTERPRISES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE034D01031",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509243",
                "SecurityId": "TVSSRICHAK",
                "SecurityName": "TVS SRICHAKRA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE421C01016",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509260",
                "SecurityId": "SWASRUB",
                "SecurityName": "SWASTIK RUBBER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509301",
                "SecurityId": "VIKTY",
                "SecurityName": "VIKRANT TYRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE234D01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509356",
                "SecurityId": "AMZLAUT",
                "SecurityName": "AMZEL AUTOMOTIVE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE644G01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509358",
                "SecurityId": "ECGIL",
                "SecurityName": "ELECTRIC CONTROL GEAR (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE458C01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509367",
                "SecurityId": "APTANN",
                "SecurityName": "ANDHRA PRADESH TANNERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE628Y01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509419",
                "SecurityId": "WOPOLIN",
                "SecurityName": "WOPOLIN PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE986C01018",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509423",
                "SecurityId": "SEL",
                "SecurityName": "SANATHNAGAR ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE367E01033",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509438",
                "SecurityId": "BENARAS",
                "SecurityName": "BENARES HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE664D01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509440",
                "SecurityId": "BELLGRP",
                "SecurityName": "GRAPHIC CHARTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509449",
                "SecurityId": "BHAGWOX",
                "SecurityName": "BHAGWATI OXYGEN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE026I01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509463",
                "SecurityId": "ZBIHAAIR",
                "SecurityName": "BIHAR AIR PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509470",
                "SecurityId": "BOMOXY-B1",
                "SecurityName": "BOMBAY OXYGEN INVESTMENTS LTD",
                "FaceValue": "100",
                "ISINNo": "INE01TL01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509472",
                "SecurityId": "CRAVATEX",
                "SecurityName": "CRAVATEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE145E01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509475",
                "SecurityId": "BOMPAINT",
                "SecurityName": "BOMBAY PAINTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE712D01016",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509479",
                "SecurityId": "BRITCAR",
                "SecurityName": "BRITELITE CARBON LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509480",
                "SecurityId": "BERGEPAINT",
                "SecurityName": "BERGER PAINTS INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE463A01038",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509486",
                "SecurityId": "CAPRIHANS",
                "SecurityName": "CAPRIHANS INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE479A01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509488",
                "SecurityId": "GRAPHITE",
                "SecurityName": "GRAPHITE INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE371A01025",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509495",
                "SecurityId": "CARONA",
                "SecurityName": "CARONA LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509496",
                "SecurityId": "ITDCEM",
                "SecurityName": "ITD CEMENTATION INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE686A01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509499",
                "SecurityId": "CTRNIND",
                "SecurityName": "CENTRON INDUSTRIAL ALLIANCE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE450L01024",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509513",
                "SecurityId": "DOLPHOT",
                "SecurityName": "DOLPHIN HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509516",
                "SecurityId": "DSPML",
                "SecurityName": "DSP MERRILL LYNCH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE072C01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509525",
                "SecurityId": "EMPIND",
                "SecurityName": "EMPIRE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE515H01014",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509527",
                "SecurityId": "FALCONTQ",
                "SecurityName": "FALCON TYRES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE511B01024",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509531",
                "SecurityId": "EASTHOT",
                "SecurityName": "EASTERN INTERNATIONAL HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509545",
                "SecurityId": "GCLARIDDM",
                "SecurityName": "G.CLARIDGE & CO.LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509546",
                "SecurityId": "GRAVISSHO",
                "SecurityName": "GRAVISS HOSPITALITY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE214F01026",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509550",
                "SecurityId": "GAMMONIND",
                "SecurityName": "GAMMON INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE259B01020",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509557",
                "SecurityId": "GARFIBRES",
                "SecurityName": "GARWARE TECHNICAL FIBRES LTD",
                "FaceValue": "10",
                "ISINNo": "INE276A01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509563",
                "SecurityId": "GARWAMAR",
                "SecurityName": "GARWARE MARINE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE925D01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509567",
                "SecurityId": "GOACARBON",
                "SecurityName": "GOA CARBON LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE426D01013",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509586",
                "SecurityId": "ZGOVPOOX",
                "SecurityName": "GOVIND POY OXYGEN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE00ZH01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509590",
                "SecurityId": "GRPHIDM",
                "SecurityName": "GRAPHITE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE535A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509597",
                "SecurityId": "HARDCAS",
                "SecurityName": "HARDCASTLE & WAUD MFG.CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE722D01015",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509613",
                "SecurityId": "HLTNRUB",
                "SecurityName": "HILTON RUBBERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509627",
                "SecurityId": "HINDDORROL",
                "SecurityName": "HINDUSTAN DORR-OLIVER LTD.",
                "FaceValue": "2",
                "ISINNo": "INE551A01022",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509631",
                "SecurityId": "HEG",
                "SecurityName": "HEG LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545A01016",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509635",
                "SecurityId": "HINDCOMPOS",
                "SecurityName": "HINDUSTAN COMPOSITES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE310C01029",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509650",
                "SecurityId": "ZHINDHSG",
                "SecurityName": "HINDUSTAN HOUSING CO.LTD.",
                "FaceValue": "25",
                "ISINNo": "INE083O01019",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509660",
                "SecurityId": "HINDOXG",
                "SecurityName": "HINDUSTAN OXYGEN GAS CO.LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509667",
                "SecurityId": "HOTBENJ",
                "SecurityName": "HOTEL BANJARA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509675",
                "SecurityId": "HIL",
                "SecurityName": "HIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE557A01011",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509684",
                "SecurityId": "INDIAFOI",
                "SecurityName": "INDIA FOILS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE260A01020",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509692",
                "SecurityId": "INDIANCARD",
                "SecurityName": "INDIAN CARD CLOTHING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE061A01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509695",
                "SecurityId": "DGPSEC",
                "SecurityName": "DGP SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE965D01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509698",
                "SecurityId": "GESTETQ",
                "SecurityName": "GESTETNER (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE223C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509704",
                "SecurityId": "KODAKDM",
                "SecurityName": "KODAK INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE377A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509709",
                "SecurityId": "INTLCONV",
                "SecurityName": "INTERNATIONAL CONVEYORS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE575C01027",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509711",
                "SecurityId": "VDOINDIA",
                "SecurityName": "SIEMENS VDO AUTOMOTIVE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE327F01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509715",
                "SecurityId": "JAYSHREETEA",
                "SecurityName": "JAY SHREE TEA & INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE364A01020",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509728",
                "SecurityId": "BHURUKA",
                "SecurityName": "BHURUKA GASES LTD.",
                "FaceValue": "2.5",
                "ISINNo": "INE880D01029",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509732",
                "SecurityId": "KOTIC",
                "SecurityName": "KOTHARI INDUSTRIAL CORPORATION LTD.",
                "FaceValue": "5",
                "ISINNo": "INE972A01020",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509749",
                "SecurityId": "MNDVPEL",
                "SecurityName": "MANDOVI PELLETS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509760",
                "SecurityId": "MODRNSH",
                "SecurityName": "MODERN SHARES & STOCKBROKERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE370A01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509762",
                "SecurityId": "MAPROIN",
                "SecurityName": "MAPRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE848M01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509782",
                "SecurityId": "OSEASPR",
                "SecurityName": "OSEASPRE CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE880P01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509805",
                "SecurityId": "NEWINDI",
                "SecurityName": "NEW INDIA INDUSTRIES LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509820",
                "SecurityId": "HUHTAMAKI",
                "SecurityName": "HUHTAMAKI INDIA LTD",
                "FaceValue": "2",
                "ISINNo": "INE275B01026",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509830",
                "SecurityId": "POONHOTDM",
                "SecurityName": "POONA INDUSTRIAL HOTEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509835",
                "SecurityId": "PREMSYN",
                "SecurityName": "PREMIER SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE940N01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509839",
                "SecurityId": "PUNWOOLC",
                "SecurityName": "PUNJAB WOOLCOMBERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE556C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509845",
                "SecurityId": "RJSHAH",
                "SecurityName": "R.J.SHAH & CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE712Z01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509854",
                "SecurityId": "ROPLAS",
                "SecurityName": "ROPLAS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509870",
                "SecurityId": "SHAHCON",
                "SecurityName": "SHAH CONSTRUCTION CO.LTD.",
                "FaceValue": "100",
                "ISINNo": "INE02C301011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509874",
                "SecurityId": "SHALPAINTS",
                "SecurityName": "SHALIMAR PAINTS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE849C01026",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509887",
                "SecurityId": "SINNAR",
                "SecurityName": "SINNAR BIDI UDYOG LTD.",
                "FaceValue": "5",
                "ISINNo": "INE896E01023",
                "Industry": "Cigarettes-Tobacco Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509890",
                "SecurityId": "SIPORDM",
                "SecurityName": "SIPOREX INDIA LTD.",
                "FaceValue": "100",
                "ISINNo": "INE689D01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509895",
                "SecurityId": "HINDMILL",
                "SecurityName": "HINDOOSTAN MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE832D01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509910",
                "SecurityId": "ZSOUTGAS",
                "SecurityName": "SOUTHERN GAS LTD.",
                "FaceValue": "100",
                "ISINNo": "INE532U01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509917",
                "SecurityId": "TECHCON",
                "SecurityName": "TECHNOJET CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE881P01013",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509924",
                "SecurityId": "ZSHVIJAY",
                "SecurityName": "SHRI VIJAYVALLABH HOLDING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509930",
                "SecurityId": "SUPREMEIND",
                "SecurityName": "SUPREME INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE195A01028",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509945",
                "SecurityId": "THACKER",
                "SecurityName": "THACKER & CO.LTD.",
                "FaceValue": "1",
                "ISINNo": "INE077P01034",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509953",
                "SecurityId": "TRADWIN",
                "SecurityName": "TRADE WINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE961E01017",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509958",
                "SecurityId": "TCI-B1",
                "SecurityName": "TCI-B1",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509960",
                "SecurityId": "UPHOT",
                "SecurityName": "U.P.HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE726E01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509966",
                "SecurityId": "VSTIND",
                "SecurityName": "VST INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE710A01016",
                "Industry": "Cigarettes-Tobacco Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "509992",
                "SecurityId": "UBENGG",
                "SecurityName": "UB ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE328C01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "510245",
                "SecurityId": "SWASTIVI",
                "SecurityName": "SWASTI VINAYAKA SYNTHETICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE804A01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511000",
                "SecurityId": "MADHUSE",
                "SecurityName": "MADHUSUDAN SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE856D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511012",
                "SecurityId": "YAMNINV",
                "SecurityName": "YAMINI INVESTMENTS COMPANY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE457N01025",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511016",
                "SecurityId": "PREMCAP",
                "SecurityName": "PREMIER CAPITAL SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE946K01023",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511018",
                "SecurityId": "ZMILGFIN",
                "SecurityName": "MILGREY FINANCE & INVESTMENTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE679T01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511024",
                "SecurityId": "ZSUBWAYF",
                "SecurityName": "SUBWAY FINANCE & INVESTMENT CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE033F01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511026",
                "SecurityId": "GULFODM",
                "SecurityName": "GULF OIL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE812A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511028",
                "SecurityId": "NAGPINV-B",
                "SecurityName": "ASIA PACIFIC INVESTMENT TRUST LTD.",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511031",
                "SecurityId": "INTEGFIN",
                "SecurityName": "INTEGRATED FINANCE COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE787E01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511034",
                "SecurityId": "JINDRILL",
                "SecurityName": "JINDAL DRILLING & INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE742C01031",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511038",
                "SecurityId": "ZARCOLEA",
                "SecurityName": "ARCO LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE955S01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511041",
                "SecurityId": "DCLFIDM",
                "SecurityName": "DCL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE712C01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511042",
                "SecurityId": "STDMDCH",
                "SecurityName": "STANDARD MEDICAL & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511048",
                "SecurityId": "KUSUMEL",
                "SecurityName": "KUSAM ELECTRICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE175Q01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511052",
                "SecurityId": "RANAKEX",
                "SecurityName": "RANAKPURJI EXPORTS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511056",
                "SecurityId": "FLFININ",
                "SecurityName": "PL FINANCE & INVESTMENT LTD.",
                "FaceValue": "5",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511058",
                "SecurityId": "VIJAYLE",
                "SecurityName": "VIJAYA LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511060",
                "SecurityId": "JUMBFNL",
                "SecurityName": "JUMBO FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122N01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511064",
                "SecurityId": "APLAYA",
                "SecurityName": "APLAYA CREATIONS LTD",
                "FaceValue": "1",
                "ISINNo": "INE099M01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511066",
                "SecurityId": "SAKTHIFIN",
                "SecurityName": "SAKTHI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE302E01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511072",
                "SecurityId": "DHFL",
                "SecurityName": "DEWAN HOUSING FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE202B01012",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511074",
                "SecurityId": "WEIZFIN",
                "SecurityName": "WEIZMANN FINCORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE407F01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511076",
                "SecurityId": "SATINDLTD",
                "SecurityName": "SAT INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE065D01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511080",
                "SecurityId": "ZTELETEK",
                "SecurityName": "TELETEK INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511082",
                "SecurityId": "MAAJTL",
                "SecurityName": "MAA JAGDAMBE TRADELINKS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE403N01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511086",
                "SecurityId": "CIFCO",
                "SecurityName": "CIFCO FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511092",
                "SecurityId": "JMDVL",
                "SecurityName": "JMD VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE047E01031",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511096",
                "SecurityId": "TVOLCON",
                "SecurityName": "TIVOLI CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE747V01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511106",
                "SecurityId": "NIVEF",
                "SecurityName": "NIVENA ESTATE AND FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE678D01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511108",
                "SecurityId": "SHIVTEX",
                "SecurityName": "SHIVA TEXYARN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE705C01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511110",
                "SecurityId": "VBDESAI",
                "SecurityName": "V.B.DESAI FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE848D01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511114",
                "SecurityId": "VALUEMAR",
                "SecurityName": "VALUEMART RETAIL SOLUTIONS LTD ",
                "FaceValue": "10",
                "ISINNo": "INE800F01014",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511116",
                "SecurityId": "QUADRANT",
                "SecurityName": "QUADRANT TELEVENTURES LIMITED-$",
                "FaceValue": "1",
                "ISINNo": "INE527B01020",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511122",
                "SecurityId": "1STCUS",
                "SecurityName": "FIRST CUSTODIAN FUND (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE609B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511126",
                "SecurityId": "DUGARFNDM",
                "SecurityName": "DUGAR FINANCE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511128",
                "SecurityId": "GALAXCP",
                "SecurityName": "GALAXY CONSOLIDATED FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511131",
                "SecurityId": "KAMANWALA",
                "SecurityName": "KAMANWALA HOUSING CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE344D01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511138",
                "SecurityId": "KOTHARIFIN",
                "SecurityName": "KOTHARI WORLD FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE988F01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511139",
                "SecurityId": "BAJRFIN",
                "SecurityName": "BAJRANG FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE507J01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511141",
                "SecurityId": "SANEX",
                "SecurityName": "SANTOGEN EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE452D01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511144",
                "SecurityId": "ASYAINFO",
                "SecurityName": "ASYA INFOSOFT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE520G01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511147",
                "SecurityId": "WSFIN",
                "SecurityName": "WALL STREET FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE549D01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511149",
                "SecurityId": "REMITR",
                "SecurityName": "REMI SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE592J01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511153",
                "SecurityId": "ANJANIFOODS",
                "SecurityName": "ANJANI FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE096I01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511158",
                "SecurityId": "SAFIN",
                "SecurityName": "SOUTH ASIAN FINANCIAL EXCHANGE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511164",
                "SecurityId": "SHRIRAMI",
                "SecurityName": "SHRIRAM INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE401A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511167",
                "SecurityId": "ELCONFN",
                "SecurityName": "ELCON FINLEASE & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511169",
                "SecurityId": "RKDAGRRTL",
                "SecurityName": "RKD AGRI & RETAIL LTD",
                "FaceValue": "10",
                "ISINNo": "INE552N01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511176",
                "SecurityId": "PARSHWANA",
                "SecurityName": "PARSHWANATH CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE635I01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511180",
                "SecurityId": "SHOPINV-B",
                "SecurityName": "SHOPPERS INVESTMENT & FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511185",
                "SecurityId": "SURYAKR",
                "SecurityName": "SURYAKRIPA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE381N01019",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511187",
                "SecurityId": "MILLENNIUM",
                "SecurityName": "MILLENNIUM ONLINE SOLUTIONS ( INDIA ) LTD",
                "FaceValue": "1",
                "ISINNo": "INE570N01025",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511190",
                "SecurityId": "ZCANSHR",
                "SecurityName": "CANSHARE",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511194",
                "SecurityId": "ICDSLTD",
                "SecurityName": "ICDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE613B01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511196",
                "SecurityId": "CANFINHOME",
                "SecurityName": "CAN FIN HOMES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE477A01020",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511200",
                "SecurityId": "MUNCAPM",
                "SecurityName": "MUNOTH CAPITAL MARKET LTD.",
                "FaceValue": "5",
                "ISINNo": "INE910G01027",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511208",
                "SecurityId": "IVC",
                "SecurityName": "IL&FS INVESTMENT MANAGERS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE050B01023",
                "Industry": "Asset Management Cos.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511210",
                "SecurityId": "BASILINF",
                "SecurityName": "BASIL INFRASTRUCTURE PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE465D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511214",
                "SecurityId": "LEAFIN",
                "SecurityName": "LEAFIN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511218",
                "SecurityId": "SRTRANSFIN",
                "SecurityName": "SHRIRAM TRANSPORT FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE721A01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511224",
                "SecurityId": "CANDBLE",
                "SecurityName": "CANDOUBLE",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511231",
                "SecurityId": "TATAFIN",
                "SecurityName": "TATA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE265A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511243",
                "SecurityId": "CHOLAFIN",
                "SecurityName": "CHOLAMANDALAM INVESTMENT AND FINANCE COMPANY LTD",
                "FaceValue": "2",
                "ISINNo": "INE121A01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511246",
                "SecurityId": "WHITELIO",
                "SecurityName": "WHITE LION ASIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE910D01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511254",
                "SecurityId": "SAGARSYST",
                "SecurityName": "SAGAR SYSTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE771Z01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511260",
                "SecurityId": "IDINFO",
                "SecurityName": "ID INFO BUSINESS SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE088P01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511262",
                "SecurityId": "GICRISE",
                "SecurityName": "GICRISE",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511263",
                "SecurityId": "GICRIBO",
                "SecurityName": "GICRIBO",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511264",
                "SecurityId": "NEDBKDM",
                "SecurityName": "NEDUNGADI BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE586A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511270",
                "SecurityId": "KKUMTFN",
                "SecurityName": "KMF LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511272",
                "SecurityId": "CEATFIN",
                "SecurityName": "CFL CAPITAL FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE481A01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511274",
                "SecurityId": "KIRIF",
                "SecurityName": "KIRLOSKAR INVESTMENTS & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE195B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511276",
                "SecurityId": "MEFCOM",
                "SecurityName": "MEFCOM AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE188C01011",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511286",
                "SecurityId": "CRBCAP-B1",
                "SecurityName": "CRB CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511288",
                "SecurityId": "GRUH",
                "SecurityName": "GRUH FINANCE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE580B01029",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511296",
                "SecurityId": "GLOBFIN",
                "SecurityName": "GFC SECURITIES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511306",
                "SecurityId": "PALCRED",
                "SecurityName": "PAL CREDIT & CAPITAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE983B01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511310",
                "SecurityId": "PREMINT",
                "SecurityName": "PREMIUM INTERNATIONAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511314",
                "SecurityId": "GICCUBN",
                "SecurityName": "GICCUBN",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511320",
                "SecurityId": "RADKHFI",
                "SecurityName": "RADICO KHAITAN FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE146D01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511324",
                "SecurityId": "INDMOTI",
                "SecurityName": "INDMOTI",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511325",
                "SecurityId": "ALPICFIN",
                "SecurityName": "ALPIC FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE429A01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511333",
                "SecurityId": "VLSFINANCE",
                "SecurityName": "VLS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE709A01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511335",
                "SecurityId": "SANMACDM",
                "SecurityName": "SANMAC MOTOR FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511339",
                "SecurityId": "APPLECREDT",
                "SecurityName": "APPLE CREDIT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE212A01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511343",
                "SecurityId": "UCILLEA",
                "SecurityName": "UCIL LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511347",
                "SecurityId": "SYNRGFN",
                "SecurityName": "SYNERGY FINANCIAL EXCHANGE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511355",
                "SecurityId": "INDCEMCAP",
                "SecurityName": "INDIA CEMENTS CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE429D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511357",
                "SecurityId": "KAILASH",
                "SecurityName": "KAILASH AUTO FINANCE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE410O01022",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511359",
                "SecurityId": "ADMANUM",
                "SecurityName": "AD-MANUM FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE556D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511361",
                "SecurityId": "VISHWAFIN",
                "SecurityName": "VISHWAMITRA FINANCIAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE002I01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511367",
                "SecurityId": "MEGLON",
                "SecurityName": "MEGLON INFRA-REAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE218B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511369",
                "SecurityId": "FIRFIN",
                "SecurityName": "FIRST FINANCIAL SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE141N01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511371",
                "SecurityId": "VATCO",
                "SecurityName": "VATSA CORPORATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE522B01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511373",
                "SecurityId": "JNFSL",
                "SecurityName": "JENSON & NICHOLSON FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE004D01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511375",
                "SecurityId": "CONCCAP",
                "SecurityName": "CONCERT CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511377",
                "SecurityId": "MEHIF",
                "SecurityName": "MEHTA INTEGRATED FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE240B01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511379",
                "SecurityId": "FIDLFIN",
                "SecurityName": "FIDELITY FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511381",
                "SecurityId": "HARVFINDM",
                "SecurityName": "HARVEST FINANCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511383",
                "SecurityId": "BURBR",
                "SecurityName": "BURR BROWN (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE474A01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511385",
                "SecurityId": "IFBFINADM",
                "SecurityName": "IFB FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511389",
                "SecurityId": "VIDEOIND",
                "SecurityName": "VIDEOCON INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE703A01011",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511391",
                "SecurityId": "INTRGLB",
                "SecurityName": "INTER GLOBE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661M01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511393",
                "SecurityId": "DFLINFRA",
                "SecurityName": "DFL INFRASTRUCTURE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE071C01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511395",
                "SecurityId": "BALATECGL",
                "SecurityName": "BALA TECHNO GLOBAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE652B01026",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511401",
                "SecurityId": "MCLTD",
                "SecurityName": "MUNOTH COMMUNICATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE410E01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511409",
                "SecurityId": "MERCARD",
                "SecurityName": "MERCARD LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511411",
                "SecurityId": "SHRISTI",
                "SecurityName": "SHRISTI INFRASTRUCTURE DEVELOPMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE472C01027",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511413",
                "SecurityId": "CREST",
                "SecurityName": "CREST VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE559D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511415",
                "SecurityId": "WEBSITY",
                "SecurityName": "WEBSITY INFOSYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE190B01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511417",
                "SecurityId": "LEASIFN",
                "SecurityName": "LEASING FINANCE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE095F01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511421",
                "SecurityId": "PINEANIM",
                "SecurityName": "PINE ANIMATION LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE452N01026",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511425",
                "SecurityId": "SCHEMFN",
                "SecurityName": "SCHEMATIC FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511427",
                "SecurityId": "ATNINTER",
                "SecurityName": "ATN INTERNATIONAL LTD.",
                "FaceValue": "4",
                "ISINNo": "INE803A01027",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511429",
                "SecurityId": "INTEGENT",
                "SecurityName": "INTEGRATED ENTERPRISES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE227A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511431",
                "SecurityId": "VAKRANGEE",
                "SecurityName": "VAKRANGEE LIMITED-$",
                "FaceValue": "1",
                "ISINNo": "INE051B01021",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511433",
                "SecurityId": "INSTAF",
                "SecurityName": "INSTA FINANCE LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE592M01021",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511435",
                "SecurityId": "COIMLAK-B",
                "SecurityName": "COIMBATORE LAKSHMI INVESTMENT & FINANCE COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511441",
                "SecurityId": "GUJCRED",
                "SecurityName": "GUJARAT CREDIT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE034B01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511443",
                "SecurityId": "ICICIPR",
                "SecurityName": "ICICI PREMIER",
                "FaceValue": "10",
                "ISINNo": "INF346A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511445",
                "SecurityId": "VGPFINC",
                "SecurityName": "VGP FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511447",
                "SecurityId": "SYLPH",
                "SecurityName": "SYLPH TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE706F01013",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511451",
                "SecurityId": "DHARFIN",
                "SecurityName": "DHARANI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE899D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511463",
                "SecurityId": "ALEXANDER",
                "SecurityName": "ALEXANDER STAMPS AND COIN LTD",
                "FaceValue": "10",
                "ISINNo": "INE191N01012",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511469",
                "SecurityId": "RUSHBCP",
                "SecurityName": "RUSHABH CAPITAL & FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511471",
                "SecurityId": "SHUKDTA",
                "SecurityName": "SHUKLA DATA TECHNICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511473",
                "SecurityId": "INDBANK",
                "SecurityName": "INDBANK MERCHANT BANKING SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE841B01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511479",
                "SecurityId": "BIRCFDM",
                "SecurityName": "BIRLA CENTURY FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE164C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511485",
                "SecurityId": "CHEMXSC",
                "SecurityName": "CHEMOX SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511487",
                "SecurityId": "THAPRFN",
                "SecurityName": "THAPAR FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511489",
                "SecurityId": "GANDLEA",
                "SecurityName": "GANDHINAGAR LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE202J01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511493",
                "SecurityId": "VCKCAP",
                "SecurityName": "VCK CAPITAL MARKET SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE488C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511495",
                "SecurityId": "YULEFIN",
                "SecurityName": "YULE FINANCING & LEASING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE490C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511497",
                "SecurityId": "ARIHCOR",
                "SecurityName": "ARIHANT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511501",
                "SecurityId": "BHARAT",
                "SecurityName": "BHARAT BHUSHAN SHARE & COMMODITY BROKERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE900A01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511503",
                "SecurityId": "SECEARTH",
                "SecurityName": "SECURE EARTH TECHNOLIGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE160B01038",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511505",
                "SecurityId": "CAPITALT",
                "SecurityName": "CAPITAL TRUST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE707C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511507",
                "SecurityId": "USHAKIRA",
                "SecurityName": "USHAKIRAN FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE697C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511509",
                "SecurityId": "VIVOBIOT",
                "SecurityName": "VIVO BIO TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE380K01017",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511511",
                "SecurityId": "MONEYCF",
                "SecurityName": "MONEYCARE FINANZ LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511513",
                "SecurityId": "SARLCRD",
                "SecurityName": "SARLA CREDIT & SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511515",
                "SecurityId": "SSLINDI",
                "SecurityName": "SSL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511517",
                "SecurityId": "SURAJSC",
                "SecurityName": "SURAJ SECURITIES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511521",
                "SecurityId": "TULIPFIN",
                "SecurityName": "TULIP FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE955C01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511523",
                "SecurityId": "VEERHEALTH",
                "SecurityName": "VEERHEALTH CARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE882C01035",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511525",
                "SecurityId": "PANINDIAC",
                "SecurityName": "PAN INDIA CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE376A01032",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511529",
                "SecurityId": "LALBHFN",
                "SecurityName": "LALBHAI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511531",
                "SecurityId": "APACFIN",
                "SecurityName": "ASIA PACIFIC FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511533",
                "SecurityId": "SAHARAHOUS",
                "SecurityName": "SAHARA HOUSINGFINA CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE135C01012",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511535",
                "SecurityId": "NDASEC",
                "SecurityName": "NDA SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE026C01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511539",
                "SecurityId": "SUPRATRE",
                "SecurityName": "SUPRA TRENDS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE533B01028",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511541",
                "SecurityId": "PLATIFN",
                "SecurityName": "PLATINUM FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511543",
                "SecurityId": "GSBFIN",
                "SecurityName": "GSB FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE777C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511547",
                "SecurityId": "KIEVFIN",
                "SecurityName": "KIEV FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511549",
                "SecurityId": "MORARKFI",
                "SecurityName": "MORARKA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE367A01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511551",
                "SecurityId": "MONARCH",
                "SecurityName": "MONARCH NETWORTH CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE903D01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511555",
                "SecurityId": "ONIDA",
                "SecurityName": "ONIDA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE328B01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511557",
                "SecurityId": "PROFINC",
                "SecurityName": "PRO FIN CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE732K01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511558",
                "SecurityId": "PUNSUFO",
                "SecurityName": "PUNSUMI FOILS & COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511559",
                "SecurityId": "TIMESGTY",
                "SecurityName": "TIMES GUARANTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE289C01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511561",
                "SecurityId": "INDNAVR",
                "SecurityName": "INDNAVR",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511563",
                "SecurityId": "SANCF",
                "SecurityName": "SANCHAY FINVEST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE654D01010",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511569",
                "SecurityId": "SOURFIN",
                "SecurityName": "SOURCE FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511571",
                "SecurityId": "SODFC",
                "SecurityName": "SOM DATT FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE754C01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511573",
                "SecurityId": "CRBARIH",
                "SecurityName": "ARIHANT MANGAL GROWTH SCHEME-CRB",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Asset Management Cos.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511575",
                "SecurityId": "AMRUTCR",
                "SecurityName": "AMRUT CREDIT CORPORATION (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511577",
                "SecurityId": "SAVFI",
                "SecurityName": "SAVANI FINANCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE304E01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511583",
                "SecurityId": "KINALFN",
                "SecurityName": "KINJAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511585",
                "SecurityId": "REGTRUS",
                "SecurityName": "REGENCY TRUST LTD.",
                "FaceValue": "1",
                "ISINNo": "INE425F01028",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511587",
                "SecurityId": "AMBSECU",
                "SecurityName": "AMBSECU",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511589",
                "SecurityId": "AVONMORE",
                "SecurityName": "AVONMORE CAPITAL & MANAGEMENT SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE323B01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511591",
                "SecurityId": "SHRACHI",
                "SecurityName": "SHRACHI INFRASTRUCTURE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE510C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511593",
                "SecurityId": "LIBORDFIN",
                "SecurityName": "LIBORD FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE212B01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511597",
                "SecurityId": "PALSOFT",
                "SecurityName": "PALSOFT INFOSYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE969B01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511599",
                "SecurityId": "RAUNAFI",
                "SecurityName": "RAUNAQ FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511601",
                "SecurityId": "YASHMGM",
                "SecurityName": "YASH MANAGEMENT & SATELLITE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE216B01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511605",
                "SecurityId": "ARIHCAPM",
                "SecurityName": "ARIHANT CAPITAL MARKETS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE420B01028",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511607",
                "SecurityId": "BIRSHLEDU",
                "SecurityName": "BIRLA SHLOKA EDUTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE814E01018",
                "Industry": "IT Training Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511609",
                "SecurityId": "ISLCONSUL",
                "SecurityName": "ISL CONSULTING LTD.",
                "FaceValue": "5",
                "ISINNo": "INE569B01022",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511611",
                "SecurityId": "DCMFINSERV",
                "SecurityName": "DCM FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE891B01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511613",
                "SecurityId": "HARVIC",
                "SecurityName": "HARVIC MANAGEMENT SERVICES (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE156D01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511617",
                "SecurityId": "ICIPOWRDM",
                "SecurityName": "ICICI POWER",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511618",
                "SecurityId": "JIKIND",
                "SecurityName": "JIK INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE026B01049",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511620",
                "SecurityId": "GANITAK",
                "SecurityName": "GANIPITAK YAKSHRAJ CAPLEASE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511622",
                "SecurityId": "TAURUSN",
                "SecurityName": "TAURUSN",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511624",
                "SecurityId": "BCLFSER-B",
                "SecurityName": "BCL FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511626",
                "SecurityId": "RRFIN",
                "SecurityName": "RR FINANCIAL CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE229D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511628",
                "SecurityId": "IMCAP",
                "SecurityName": "IM+ CAPITALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE417D01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511630",
                "SecurityId": "SAMBHAAV",
                "SecurityName": "SAMBHAAV MEDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE699B01027",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511632",
                "SecurityId": "OLYMTFI",
                "SecurityName": "OLYMPIC MANAGEMENT & FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE091N01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511634",
                "SecurityId": "DUGARHOU",
                "SecurityName": "DUGAR HOUSING DEVELOPMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE919M01018",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511636",
                "SecurityId": "DJSSS",
                "SecurityName": "DJS STOCK & SHARES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE234E01027",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511638",
                "SecurityId": "MADHURC",
                "SecurityName": "MADHUR CAPITAL & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE107C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511640",
                "SecurityId": "SANGHCO",
                "SecurityName": "SANGHI CORPORATE SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE998M01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511642",
                "SecurityId": "WISEC",
                "SecurityName": "WISEC GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE638C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511644",
                "SecurityId": "OMEGAIN",
                "SecurityName": "OMEGA INTERACTIVE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE113B01029",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511646",
                "SecurityId": "VIJACAP",
                "SecurityName": "VIJAYSHREE CAPITAL & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511648",
                "SecurityId": "ATASECU",
                "SecurityName": "ATASH SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511650",
                "SecurityId": "CVILINFRA",
                "SecurityName": "CVIL INFRA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511652",
                "SecurityId": "GEMOIL",
                "SecurityName": "GEMMIA OILTECH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE736I01014",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511654",
                "SecurityId": "SUGALDAM",
                "SecurityName": "SUGAL & DAMANI SHARE BROKERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE309D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511656",
                "SecurityId": "CYBERSPACE",
                "SecurityName": "CYBERSPACE INFOSYS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE063B01026",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511658",
                "SecurityId": "NETTLINX",
                "SecurityName": "NETTLINX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE027D01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511660",
                "SecurityId": "PREMCAPM",
                "SecurityName": "PREMIUM CAPITAL MARKET & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE555D01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511662",
                "SecurityId": "AJBRO",
                "SecurityName": "A.J.BROTHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE939C01017",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511664",
                "SecurityId": "BGIL",
                "SecurityName": "BGIL FILMS & TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE443D01018",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511666",
                "SecurityId": "MANGFIN",
                "SecurityName": "MANGAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511668",
                "SecurityId": "FACTENT",
                "SecurityName": "FACT ENTERPRISE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE923D01019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511670",
                "SecurityId": "ADCONCP",
                "SecurityName": "ADCON CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511672",
                "SecurityId": "SCANSTL",
                "SecurityName": "SCAN STEELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE099G01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511674",
                "SecurityId": "OLYCAP",
                "SecurityName": "OLYMPIA CAPITALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE697B01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511676",
                "SecurityId": "GICHSGFIN",
                "SecurityName": "GIC HOUSING FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE289B01019",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511678",
                "SecurityId": "DAMANFN",
                "SecurityName": "DAMANIA CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511680",
                "SecurityId": "MRRYSFN",
                "SecurityName": "MERRY SHAREFIN LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511682",
                "SecurityId": "IFLPROMOT",
                "SecurityName": "IFL PROMOTERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE326D01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511684",
                "SecurityId": "RELIAFN",
                "SecurityName": "RELIABLE FINSTOCK SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511688",
                "SecurityId": "MATHEWE",
                "SecurityName": "MATHEW EASOW RESEARCH SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE963B01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511690",
                "SecurityId": "WARNER",
                "SecurityName": "WARNER MULTIMEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE407B01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511692",
                "SecurityId": "AJCON",
                "SecurityName": "AJCON GLOBAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE759C01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511694",
                "SecurityId": "ALPINFN",
                "SecurityName": "ALPINE CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE118F01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511696",
                "SecurityId": "CHRTEDCA",
                "SecurityName": "CHARTERED CAPITAL & INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE953B01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511698",
                "SecurityId": "BHAGYFN",
                "SecurityName": "BHAGYASHREE LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE655F01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511700",
                "SecurityId": "STANCAP",
                "SecurityName": "STANDARD CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE625D01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511702",
                "SecurityId": "PARSHINV",
                "SecurityName": "PARSHARTI INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE290E01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511704",
                "SecurityId": "SHRIBFN",
                "SecurityName": "SHREE BHIKSHU FOUNDATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511706",
                "SecurityId": "ACTIONFI",
                "SecurityName": "ACTION FINANCIAL SERVICES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE357A01032",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511710",
                "SecurityId": "CUBIFIN",
                "SecurityName": "CUBICAL FINANCIAL SERVICES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE717D01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511712",
                "SecurityId": "RELICTEC",
                "SecurityName": "RELIC TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE452B01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511714",
                "SecurityId": "NIMBSPROJ",
                "SecurityName": "NIMBUS PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE875B01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511716",
                "SecurityId": "ESCORTSFIN",
                "SecurityName": "ESCORTS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE359A01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511718",
                "SecurityId": "ATHENA",
                "SecurityName": "ATHENA FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE139C01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511720",
                "SecurityId": "CAPMANFI",
                "SecurityName": "CAPMAN FINANCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE862D01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511722",
                "SecurityId": "SOMANFN",
                "SecurityName": "SOMANI SWISS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511724",
                "SecurityId": "BALFC",
                "SecurityName": "BAID LEASING AND FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE020D01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511726",
                "SecurityId": "VIPUL",
                "SecurityName": "VIPUL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE946H01037",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511728",
                "SecurityId": "KZLFIN",
                "SecurityName": "K.Z.LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE006C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511730",
                "SecurityId": "TRCFIN",
                "SecurityName": "TRC FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE759D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511734",
                "SecurityId": "PASUFIN",
                "SecurityName": "PASUPATI FINCAP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE527C01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511736",
                "SecurityId": "USHDI",
                "SecurityName": "USHDEV INTERNATIONAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE981D01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511738",
                "SecurityId": "MEHSECU",
                "SecurityName": "MEHTA SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE241B01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511740",
                "SecurityId": "MEHTAHG",
                "SecurityName": "MEHTA HOUSING FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE239B01014",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511742",
                "SecurityId": "UGROCAP",
                "SecurityName": "UGRO CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE583D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511744",
                "SecurityId": "FCGL",
                "SecurityName": "FCGL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE281D01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511748",
                "SecurityId": "KSTRIFN",
                "SecurityName": "KASTURI FINLEASE & INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511750",
                "SecurityId": "ASCENTEX",
                "SecurityName": "ASCENT EXIM (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE138E01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511752",
                "SecurityId": "ORTHGFN",
                "SecurityName": "ORIENTAL HOUSING DEVELOPMENT FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511754",
                "SecurityId": "SAHLIBHFI",
                "SecurityName": "SHALIBHADRA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE861D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511756",
                "SecurityId": "ABIRAFN",
                "SecurityName": "ABIRAMI FINANCIAL SERVICES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE195I01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511758",
                "SecurityId": "MANSIFIN",
                "SecurityName": "MANSI FINANCE (CHENNAI) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE094E01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511760",
                "SecurityId": "SEVENHILL",
                "SecurityName": "SEVEN HILL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE518D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511762",
                "SecurityId": "KUBERFN",
                "SecurityName": "KUBER AUTO GENERAL FINANCE & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511764",
                "SecurityId": "UPASAFN",
                "SecurityName": "UPASANA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE819K01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511766",
                "SecurityId": "MUTHTFN",
                "SecurityName": "MUTHOOT CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE296G01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511768",
                "SecurityId": "MASTERTR",
                "SecurityName": "MASTER TRUST LTD.",
                "FaceValue": "5",
                "ISINNo": "INE677D01029",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511820",
                "SecurityId": "ZTRANS23",
                "SecurityName": "ZTRANS23",
                "FaceValue": "80",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "511832",
                "SecurityId": "HAREKIN",
                "SecurityName": "HARE KRISHNA INVESTMENT & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512000",
                "SecurityId": "ZJIWAAPP",
                "SecurityName": "JIWA APPLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512002",
                "SecurityId": "VISNUPR",
                "SecurityName": "VISHNUPRIYA AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512004",
                "SecurityId": "ZNILKENG",
                "SecurityName": "NILKANTH ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE582V01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512008",
                "SecurityId": "AMANITRA",
                "SecurityName": "AMANI TRADING & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE886D01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512010",
                "SecurityId": "ANGFNDM",
                "SecurityName": "ANGFNDM",
                "FaceValue": "10",
                "ISINNo": "INE033A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512011",
                "SecurityId": "TEATIME",
                "SecurityName": "TEA TIME LTD.",
                "FaceValue": "10",
                "ISINNo": "INE237U01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512014",
                "SecurityId": "SOBME",
                "SecurityName": "SOBHAGYA MERCHANTILE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE754D01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512017",
                "SecurityId": "ZEXDONTR",
                "SecurityName": "EXDON TRADING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE204I01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512018",
                "SecurityId": "CNIRESLTD",
                "SecurityName": "CNI RESEARCH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE135H01029",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512020",
                "SecurityId": "ZSARACOM",
                "SecurityName": "SARASWATI COMMERCIAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE967G01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512022",
                "SecurityId": "WINROC",
                "SecurityName": "WINRO COMMERCIAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE837E01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512024",
                "SecurityId": "NDMETAL",
                "SecurityName": "N.D.METAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE643D01013",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512025",
                "SecurityId": "INERTIAST",
                "SecurityName": "INERTIA STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE767M01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512026",
                "SecurityId": "PHTRADING",
                "SecurityName": "PH TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE603D01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512028",
                "SecurityId": "SHOTI",
                "SecurityName": "SHONKH TECHNOLOGIES INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE735B01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512032",
                "SecurityId": "PRISMER",
                "SecurityName": "PRISM MERCANTILE COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512036",
                "SecurityId": "KAPILCO",
                "SecurityName": "KAPIL COTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE393H01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512038",
                "SecurityId": "ASWTR",
                "SecurityName": "AASWA TRADING & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE887D01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512042",
                "SecurityId": "CHEMXCHDM",
                "SecurityName": "CHEMOX CHEMICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512047",
                "SecurityId": "ROYALIND",
                "SecurityName": "ROYAL INDIA CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE510H01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512048",
                "SecurityId": "LUHARUKA",
                "SecurityName": "LUHARUKA MEDIA & INFRA LTD",
                "FaceValue": "1",
                "ISINNo": "INE195E01020",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512050",
                "SecurityId": "SAGRSLK",
                "SecurityName": "SAGAR SILK INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512058",
                "SecurityId": "DRAVIND",
                "SecurityName": "DRAVYA INDUSTRIAL CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512060",
                "SecurityId": "SHYAM",
                "SecurityName": "VENTURA GUARANTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE139J01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512062",
                "SecurityId": "ZSANMCOM",
                "SecurityName": "SANMITRA COMMERCIAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE896J01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512063",
                "SecurityId": "AYOME",
                "SecurityName": "AYOKI MERCANTILE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE048E01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512064",
                "SecurityId": "VISVEN",
                "SecurityName": "VISHVPRABHA VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE762D01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512065",
                "SecurityId": "MRUTR",
                "SecurityName": "MRUGESH TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE738D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512067",
                "SecurityId": "VISJYTR",
                "SecurityName": "VISHVJYOTI TRADING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE025N01020",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512068",
                "SecurityId": "DECNGOLD",
                "SecurityName": "DECCAN GOLD MINES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE945F01025",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512069",
                "SecurityId": "ZBIOSTCODM",
                "SecurityName": "BOISTUR COMMERCIAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512070",
                "SecurityId": "UPL",
                "SecurityName": "UPL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE628A01036",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512075",
                "SecurityId": "SUCHTRD",
                "SecurityName": "SUCHAK TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE035G01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512079",
                "SecurityId": "DOCTORBI",
                "SecurityName": "DOCTORS BIOTECH INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE739D01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512087",
                "SecurityId": "PARIJAT",
                "SecurityName": "PARIJAT TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512091",
                "SecurityId": "ANSHNCO",
                "SecurityName": "ANSHUNI COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE425H01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512093",
                "SecurityId": "CRANESSOFT",
                "SecurityName": "CRANES SOFTWARE INTERNATIONAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE234B01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512095",
                "SecurityId": "ZFOURDIMDM",
                "SecurityName": "FOUR DIMENSIONS SECURITIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512097",
                "SecurityId": "SAICOM",
                "SecurityName": "SAIANAND COMMERCIAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE092G01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512099",
                "SecurityId": "PUNITCO",
                "SecurityName": "PUNIT COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE750G01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512101",
                "SecurityId": "TRIPR",
                "SecurityName": "TRIOCHEM PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE331E01013",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512103",
                "SecurityId": "NIDHGRN",
                "SecurityName": "NIDHI GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE276H01013",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512105",
                "SecurityId": "PROAIMENT",
                "SecurityName": "PROAIM ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE490J01032",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512107",
                "SecurityId": "ZGOLKOND",
                "SecurityName": "GOLKONDA ENGINEERING ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512109",
                "SecurityId": "AVIVA",
                "SecurityName": "AVIVA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE461H01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512111",
                "SecurityId": "JAHATRADM",
                "SecurityName": "JAHANRI TRADES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512113",
                "SecurityId": "KELVINFIN",
                "SecurityName": "KELVIN FINCAP LTD",
                "FaceValue": "5",
                "ISINNo": "INE232N01022",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512115",
                "SecurityId": "ROSEMER",
                "SecurityName": "ROSE MERC.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE649C01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512117",
                "SecurityId": "TWIROST",
                "SecurityName": "TWIN ROSES TRADES & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE436U01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512119",
                "SecurityId": "ZATULTRD",
                "SecurityName": "ATULYA TRADES & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512125",
                "SecurityId": "ZSANCHTR",
                "SecurityName": "SANCHANA TRADING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE872O01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512127",
                "SecurityId": "ETPCORP",
                "SecurityName": "ETP CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE581C01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512129",
                "SecurityId": "JAYAMEL",
                "SecurityName": "JAYANT MERCANTILE CO.LTD.",
                "FaceValue": "1",
                "ISINNo": "INE789G01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512131",
                "SecurityId": "SIGNETIND",
                "SecurityName": "SIGNET INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE529F01035",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512135",
                "SecurityId": "ENSSI",
                "SecurityName": "ENSA STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE322C01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512141",
                "SecurityId": "ZACUMTRD",
                "SecurityName": "ACUMEN TRADING & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512143",
                "SecurityId": "ZWALCONS",
                "SecurityName": "WALL STREET CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512145",
                "SecurityId": "CREDNFN",
                "SecurityName": "CREDENTIAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512147",
                "SecurityId": "KAJALSY",
                "SecurityName": "KAJAL SYNTHETICS & SILK MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE956V01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512149",
                "SecurityId": "AVANCE",
                "SecurityName": "AVANCE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE758A01056",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512153",
                "SecurityId": "SPECMKT",
                "SecurityName": "SPECULAR MARKETING & FINANCING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE808W01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512157",
                "SecurityId": "TERRAREAL",
                "SecurityName": "TERRAFORM REALSTATE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE123V01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512161",
                "SecurityId": "SECURKLOUD",
                "SecurityName": "SECUREKLOUD TECHNOLOGIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE650K01021",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512163",
                "SecurityId": "EXTCO",
                "SecurityName": "EXTOL COMMERCIAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE670D01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512165",
                "SecurityId": "ABANSENT",
                "SecurityName": "ABANS ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE365O01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512167",
                "SecurityId": "MATRAREAL",
                "SecurityName": "MATRA REALTY LTD.",
                "FaceValue": "5",
                "ISINNo": "INE190E01021",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512169",
                "SecurityId": "CAPRICORN",
                "SecurityName": "CAPRICORN SYSTEMS GLOBAL SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE968E01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512173",
                "SecurityId": "PARKAVN",
                "SecurityName": "PARK AVENUE ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512175",
                "SecurityId": "VAMA",
                "SecurityName": "VAMA INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE685D01022",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512177",
                "SecurityId": "CHMERCN",
                "SecurityName": "CHERRY MERCANTILE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512179",
                "SecurityId": "SUNTECK",
                "SecurityName": "SUNTECK REALTY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE805D01034",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512183",
                "SecurityId": "HARTF",
                "SecurityName": "HARISIDDHA TRADING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE596C01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512185",
                "SecurityId": "IOLN",
                "SecurityName": "IOL NETCOM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE517C01011",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512187",
                "SecurityId": "GRFINDL",
                "SecurityName": "GREENFIELD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512189",
                "SecurityId": "PREMKUT",
                "SecurityName": "PREM KUTIR ESTATES & PROPERTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512191",
                "SecurityId": "MISHKAFIN",
                "SecurityName": "MISHKA FINANCE AND TRADING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE231N01024",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512193",
                "SecurityId": "ZDCLSECU",
                "SecurityName": "DCL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512195",
                "SecurityId": "BENTCOM",
                "SecurityName": "BENTLEY COMMERCIAL ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE496M01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512197",
                "SecurityId": "SILVERO",
                "SecurityName": "SILVER OAK COMMERCIAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE798C01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512199",
                "SecurityId": "COREEDUTEC",
                "SecurityName": "CORE EDUCATION & TECHNOLOGIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE247G01024",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512205",
                "SecurityId": "VATSAEDU",
                "SecurityName": "VATSA EDUCATIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE815A01021",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512207",
                "SecurityId": "EFFTXT",
                "SecurityName": "EFFINGO TEXTILE & TRADING LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE907N01029",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512211",
                "SecurityId": "SANGEXP",
                "SecurityName": "SANGAM EXPORTS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512213",
                "SecurityId": "CLASELE",
                "SecurityName": "CLASSIC ELECTRICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE02BR01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512215",
                "SecurityId": "STEPHANOTIS",
                "SecurityName": "STEPHANOTIS FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE902L01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512217",
                "SecurityId": "PRISMMEDI",
                "SecurityName": "PRISM MEDICO AND PHARMACY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE730E01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512219",
                "SecurityId": "FINAVENT",
                "SecurityName": "FINAVENTURE CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE128B01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512221",
                "SecurityId": "SPVGLOBAL",
                "SecurityName": "SPV GLOBAL TRADING LTD",
                "FaceValue": "10",
                "ISINNo": "INE177E01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512223",
                "SecurityId": "ZSIMCOTR",
                "SecurityName": "SIMCO TRADING & FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512225",
                "SecurityId": "ANGLOBA",
                "SecurityName": "AN GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512229",
                "SecurityId": "VERITAS",
                "SecurityName": "VERITAS (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE379J01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512233",
                "SecurityId": "JAYTEX",
                "SecurityName": "JAYBHARAT TEXTILES & REAL ESTATE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE091E01039",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512237",
                "SecurityId": "JAICORPLTD",
                "SecurityName": "JAI CORP LTD.",
                "FaceValue": "1",
                "ISINNo": "INE070D01027",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512239",
                "SecurityId": "ZJQARTCH",
                "SecurityName": "JACQART CHEMICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE966G01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512243",
                "SecurityId": "SURASEC",
                "SecurityName": "SURANA SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512245",
                "SecurityId": "ZNIVITRD",
                "SecurityName": "NIVI TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE552F01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512247",
                "SecurityId": "ASHCAP",
                "SecurityName": "ASHIRWAD CAPITAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE894A01026",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512253",
                "SecurityId": "MEUSEKARA",
                "SecurityName": "MEUSE KARA & SUNGRACE MAFATLAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE756D01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512255",
                "SecurityId": "ZWINMOTR",
                "SecurityName": "WINMORE SILK MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE869O01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512257",
                "SecurityId": "SVARTCORP",
                "SecurityName": "SWASTI VINAYAKA ART AND HERITAGE CORPORATION LTD",
                "FaceValue": "1",
                "ISINNo": "INE895A01023",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512261",
                "SecurityId": "BAJGLOB",
                "SecurityName": "BAJAJ GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE553H01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512263",
                "SecurityId": "ZPURVENGDM",
                "SecurityName": "PURVA ENGINEERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512265",
                "SecurityId": "AVONMERC",
                "SecurityName": "AVON MERCANTILE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE471D01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512267",
                "SecurityId": "MMWL",
                "SecurityName": "MEDIA MATRIX WORLDWIDE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE200D01020",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512269",
                "SecurityId": "ZWARDENC",
                "SecurityName": "WARDEN CONSTRUCTION & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512271",
                "SecurityId": "TASHIND",
                "SecurityName": "TASHI INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE552H01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512273",
                "SecurityId": "ARONICOMM",
                "SecurityName": "ARONI COMMERCIALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE484B01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512277",
                "SecurityId": "AUTOINT",
                "SecurityName": "AUTORIDERS INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE340U01010",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512279",
                "SecurityId": "NNTL",
                "SecurityName": "N2N TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE043F01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512281",
                "SecurityId": "EUROPLS",
                "SecurityName": "EUROPLAST INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512285",
                "SecurityId": "YUVINTL",
                "SecurityName": "YUVRAJ INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512289",
                "SecurityId": "SHIRPUR-G",
                "SecurityName": "SHIRPUR GOLD REFINERY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE196B01016",
                "Industry": "Other Non-Ferrous Metals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512291",
                "SecurityId": "ZSPEEDCO",
                "SecurityName": "SPEEDAGE COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE497M01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512294",
                "SecurityId": "ZPARAPLY",
                "SecurityName": "PARASRAMPURIA POLYAMIDES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512296",
                "SecurityId": "BHAGYNAGAR",
                "SecurityName": "BHAGYANAGAR INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE458B01036",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512297",
                "SecurityId": "SHANTAI",
                "SecurityName": "SHANTAI INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE408F01016",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512299",
                "SecurityId": "STERLINBIO",
                "SecurityName": "STERLING BIOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE324C01038",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512301",
                "SecurityId": "CHMBBRW",
                "SecurityName": "CHAMBAL BREWERIES & DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE417N01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512303",
                "SecurityId": "ZMANSOON",
                "SecurityName": "MANSOON TRADING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE776V01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512305",
                "SecurityId": "ZKASLOWTDM",
                "SecurityName": "ZKASLOWTDM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512309",
                "SecurityId": "ROSETEX",
                "SecurityName": "ROSEKAMAL TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE392F01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512311",
                "SecurityId": "SURCHIN",
                "SecurityName": "SURABHI CHEMICALS & INVESTMENTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE401F01037",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512313",
                "SecurityId": "BIJLTEX",
                "SecurityName": "BIJLEE TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE368F01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512317",
                "SecurityId": "NTWSTFN",
                "SecurityName": "NETWEST FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512319",
                "SecurityId": "RAJLXIN",
                "SecurityName": "RAJLAXMI INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE400O01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512327",
                "SecurityId": "TRLTRAG",
                "SecurityName": "TRILOCHAN TRADERS & AGENCIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512329",
                "SecurityId": "KRL",
                "SecurityName": "KINTECH RENEWABLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE385F01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512332",
                "SecurityId": "BIRLACAP",
                "SecurityName": "BIRLA CAPITAL & FINANCIAL SERVICES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE776E01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512335",
                "SecurityId": "AVSWLTR",
                "SecurityName": "AVS WORLD TRADE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512337",
                "SecurityId": "MAHSHRE",
                "SecurityName": "MAHASHREE TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE924T01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512341",
                "SecurityId": "CESL",
                "SecurityName": "CES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE396F01013",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512344",
                "SecurityId": "ARAVALIS",
                "SecurityName": "ARAVALI SECURITIES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE068C01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512345",
                "SecurityId": "YASTF",
                "SecurityName": "YASH TRADING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE745A01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512347",
                "SecurityId": "LEADPKG",
                "SecurityName": "LEADER PACKAGING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512349",
                "SecurityId": "LINKH",
                "SecurityName": "LINKHOUSE INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE080C01028",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512351",
                "SecurityId": "ZMAGICOE",
                "SecurityName": "MAGICO EXPORTS & CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512353",
                "SecurityId": "RUSODAY",
                "SecurityName": "RUSODAY & CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512355",
                "SecurityId": "ZANUKCOM",
                "SecurityName": "ANUKARAN COMMERCIAL ENTERPRISES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE090G01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512357",
                "SecurityId": "ZSUNITCO",
                "SecurityName": "SUNITI COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512359",
                "SecurityId": "SWORDEDGE",
                "SecurityName": "SWORD-EDGE COMMERCIALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE093G01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512361",
                "SecurityId": "CUPIDTR",
                "SecurityName": "CUPID TRADES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE108G01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512365",
                "SecurityId": "COSYMER",
                "SecurityName": "COSY MERCANTILE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512367",
                "SecurityId": "ZSHERAPR",
                "SecurityName": "SHERATON PROPERTIES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE495M01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512369",
                "SecurityId": "ENNORE",
                "SecurityName": "ENNORE COKE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE755H01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512371",
                "SecurityId": "ZESQENGP",
                "SecurityName": "ESQUIRE ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512375",
                "SecurityId": "INNOVENT",
                "SecurityName": "INNOVENTIVE VENTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE322N01013",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512377",
                "SecurityId": "MAGANTR",
                "SecurityName": "MAGNANIMOUS TRADE & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE664N01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512379",
                "SecurityId": "CRESSAN",
                "SecurityName": "CRESSANDA SOLUTIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE716D01033",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512381",
                "SecurityId": "STARTECK",
                "SecurityName": "STARTECK FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE992I01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512383",
                "SecurityId": "ZDELSTCO",
                "SecurityName": "DELSTAR COMMERCIAL & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512387",
                "SecurityId": "WIINDDM",
                "SecurityName": "WESTERN INDIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512389",
                "SecurityId": "WASTHOU",
                "SecurityName": "WALL STREET HOUSING & FINANCE CORP.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512393",
                "SecurityId": "SHARDUL",
                "SecurityName": "SHARDUL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE037B01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512397",
                "SecurityId": "RATANGLI",
                "SecurityName": "RATAN GLITTER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE348E01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512399",
                "SecurityId": "KAPASHI",
                "SecurityName": "KAPASHI COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE017I01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512401",
                "SecurityId": "ZKAYTCOT",
                "SecurityName": "KAYTEE COTSYNTH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512404",
                "SecurityId": "BENGALS",
                "SecurityName": "BENGAL STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE523W01017",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512405",
                "SecurityId": "IPOWER",
                "SecurityName": "I-POWER SOLUTIONS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE468F01010",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512408",
                "SecurityId": "UNIWSEC",
                "SecurityName": "UNIWORTH SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE728J01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512409",
                "SecurityId": "RAJSAN",
                "SecurityName": "RAJSANKET REALTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE314F01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512411",
                "SecurityId": "AUROBRT",
                "SecurityName": "MADALSA AGROTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512413",
                "SecurityId": "SPECTACLE",
                "SecurityName": "SPECTACLE VENTURES LTD",
                "FaceValue": "1",
                "ISINNo": "INE409H01028",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512415",
                "SecurityId": "MERCTRD",
                "SecurityName": "MERCURY TRADE LINKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE319T01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512417",
                "SecurityId": "TRITRADE",
                "SecurityName": "TRINITY TRADELINK LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE567D01022",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512419",
                "SecurityId": "ZSAMPKTR",
                "SecurityName": "SAMPARK TRADING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512424",
                "SecurityId": "MTZPOLY",
                "SecurityName": "MTZ POLYFILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE864A01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512425",
                "SecurityId": "NIRAVCOM",
                "SecurityName": "NIRAV COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE242B01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512427",
                "SecurityId": "DEORAPL",
                "SecurityName": "DEORA POLYTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512429",
                "SecurityId": "NITININ",
                "SecurityName": "NITIN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512431",
                "SecurityId": "WHITHAL",
                "SecurityName": "WHITE HALL COMMERCIAL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416X01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512433",
                "SecurityId": "ASUTENT",
                "SecurityName": "ASUTOSH ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE508W01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512437",
                "SecurityId": "APOLLOFI",
                "SecurityName": "APOLLO FINVEST (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE412D01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512441",
                "SecurityId": "ENBETRD",
                "SecurityName": "ENBEE TRADE & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE993I01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512443",
                "SecurityId": "GANONPRO",
                "SecurityName": "GANON PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE162L01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512445",
                "SecurityId": "DEVITRD",
                "SecurityName": "DEVINSU TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE07LH01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512447",
                "SecurityId": "INNOVIS",
                "SecurityName": "INNOVISION E-COMMERCE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE880C01021",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512449",
                "SecurityId": "SVCRES",
                "SecurityName": "SVC RESOURCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE254H01044",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512453",
                "SecurityId": "SHRJAGP",
                "SecurityName": "SHRI JAGDAMBA POLYMERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE564J01026",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512455",
                "SecurityId": "LLOYDSME",
                "SecurityName": "LLOYDS METALS AND ENERGY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE281B01032",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512461",
                "SecurityId": "PUNCTRD",
                "SecurityName": "PUNCTUAL TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE07NX01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512463",
                "SecurityId": "SHRGLTR",
                "SecurityName": "SHREE GLOBAL TRADEFIN LTD.",
                "FaceValue": "1",
                "ISINNo": "INE080I01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512465",
                "SecurityId": "SANTOWIN",
                "SecurityName": "SANTOWIN CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE386L01038",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512477",
                "SecurityId": "BETXIND",
                "SecurityName": "BETEX INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE765L01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512479",
                "SecurityId": "GYTRIPA",
                "SecurityName": "GAYATRI TISSUE & PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661K01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512481",
                "SecurityId": "POLYTEX",
                "SecurityName": "POLYTEX INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE012F01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512485",
                "SecurityId": "DHANCOT",
                "SecurityName": "DHANLAXMI COTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE977F01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512487",
                "SecurityId": "REMIELEK",
                "SecurityName": "REMI ELEKTROTECHNIK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE512H01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512489",
                "SecurityId": "OASISEC",
                "SecurityName": "OASIS SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE876A01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512491",
                "SecurityId": "SONUSYN",
                "SecurityName": "SONU SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512493",
                "SecurityId": "GARNETINT",
                "SecurityName": "GARNET INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE590B01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512499",
                "SecurityId": "SHALPRO",
                "SecurityName": "SHALIMAR PRODUCTIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE435E01020",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512503",
                "SecurityId": "AMARVAN",
                "SecurityName": "AMAR VANIJYA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE499F01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512511",
                "SecurityId": "VARUNME",
                "SecurityName": "VARUN MERCANTILE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE442U01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512517",
                "SecurityId": "VINVANI",
                "SecurityName": "VINAYAK VANIJYA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE132V01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512519",
                "SecurityId": "DONEAR",
                "SecurityName": "DONEAR INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE668D01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512520",
                "SecurityId": "PRIYANK",
                "SecurityName": "PRIYANKA UDYOG LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512522",
                "SecurityId": "NEPTEXP",
                "SecurityName": "NEPTUNE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE066X01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512527",
                "SecurityId": "SUPER",
                "SecurityName": "SUPER SALES INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE091C01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512529",
                "SecurityId": "SEQUENT",
                "SecurityName": "SEQUENT SCIENTIFIC LTD.",
                "FaceValue": "2",
                "ISINNo": "INE807F01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512531",
                "SecurityId": "STCINDIA",
                "SecurityName": "STATE TRADING CORPORATION OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE655A01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512533",
                "SecurityId": "BHRATCOM",
                "SecurityName": "BHARAT COMMERCE & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE032C01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512535",
                "SecurityId": "ASAHINFRA",
                "SecurityName": "ASAHI INFRASTRUCTURE & PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE933C01036",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512537",
                "SecurityId": "LKSMITR",
                "SecurityName": "LAKSHMI TRADE CREDITS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512539",
                "SecurityId": "MAHDECO",
                "SecurityName": "MAHADEV CORPORATION (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512543",
                "SecurityId": "KIRANTRDM",
                "SecurityName": "KIRAN OVERSEAS EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512547",
                "SecurityId": "WORLDTR",
                "SecurityName": "ZILLION MEDICARE (EXPORTS) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512549",
                "SecurityId": "DRUCGRF",
                "SecurityName": "DRUCKGRAFEN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512551",
                "SecurityId": "PITFI",
                "SecurityName": "PITTIE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE331B01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512553",
                "SecurityId": "ZENITHEXPO",
                "SecurityName": "ZENITH EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE058B01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512555",
                "SecurityId": "SAVOV",
                "SecurityName": "SAVITRI OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE828C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512557",
                "SecurityId": "GIRSHTR",
                "SecurityName": "GIRISH TRAVELS & COURIERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512559",
                "SecurityId": "KOHINOOR",
                "SecurityName": "KOHINOOR FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE080B01012",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512561",
                "SecurityId": "CHINEXP",
                "SecurityName": "CHINAR EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512563",
                "SecurityId": "OKARLES",
                "SecurityName": "OKARA LEASING & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512565",
                "SecurityId": "RTEXPO",
                "SecurityName": "R.T.EXPORTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE581D01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512567",
                "SecurityId": "BHARPAR",
                "SecurityName": "BHARAT PARENTERALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512569",
                "SecurityId": "ARONIEP",
                "SecurityName": "AUROKNIT EXPORTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512571",
                "SecurityId": "ADITLIM",
                "SecurityName": "ADITYA LIME INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512573",
                "SecurityId": "AVANTI",
                "SecurityName": "AVANTI FEEDS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE871C01038",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512575",
                "SecurityId": "RITESEX",
                "SecurityName": "RITESH EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512579",
                "SecurityId": "GUJNRECOKE",
                "SecurityName": "GUJARAT NRE COKE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE110D01013",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512581",
                "SecurityId": "LYNXINDDM",
                "SecurityName": "LYNX INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512583",
                "SecurityId": "ENVCLEN",
                "SecurityName": "ENVIRO-CLEAN SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512585",
                "SecurityId": "KARMA",
                "SecurityName": "KARMA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416F01019",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512587",
                "SecurityId": "ZODJRDMKJ",
                "SecurityName": "ZODIAC-JRD-MKJ LTD.",
                "FaceValue": "10",
                "ISINNo": "INE077B01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512589",
                "SecurityId": "SITAENT",
                "SecurityName": "SITA ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE579D01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512591",
                "SecurityId": "PULSRIN",
                "SecurityName": "PULSAR INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE183U01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512593",
                "SecurityId": "PREMIND",
                "SecurityName": "PREMIUM INDUSTRIES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512595",
                "SecurityId": "UNIMOVR",
                "SecurityName": "UNIMODE OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE348N01034",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512597",
                "SecurityId": "KEYFINSER",
                "SecurityName": "KEYNOTE FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE681C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512599",
                "SecurityId": "ADANIENT",
                "SecurityName": "ADANI ENTERPRISES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE423A01024",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512600",
                "SecurityId": "MACINTR",
                "SecurityName": "MACRO (INTERNATIONAL) EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE307N01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512602",
                "SecurityId": "ASHPROP",
                "SecurityName": "ASHARI PROPERTIES & FINANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512604",
                "SecurityId": "HARIAEXPO",
                "SecurityName": "HARIA EXPORTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE772B01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512606",
                "SecurityId": "RUPIM",
                "SecurityName": "RUPANGI IMPEX LTD.",
                "FaceValue": "2",
                "ISINNo": "INE766B01024",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512608",
                "SecurityId": "BHANDHOS",
                "SecurityName": "BHANDARI HOSIERY EXPORTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE474E01029",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512610",
                "SecurityId": "SHRIVAR",
                "SecurityName": "SHRI VARDHMAN OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512614",
                "SecurityId": "GRANHPH",
                "SecurityName": "GRAN HEAL PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512616",
                "SecurityId": "ORINEXP",
                "SecurityName": "ORIND EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE165E01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512618",
                "SecurityId": "RLF",
                "SecurityName": "RLF LTD.",
                "FaceValue": "10",
                "ISINNo": "INE629C01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512620",
                "SecurityId": "DUCTA",
                "SecurityName": "DUCK TARPAULINS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE966D01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512622",
                "SecurityId": "INDOSWI",
                "SecurityName": "INDO SWISS EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512624",
                "SecurityId": "REGENTRP",
                "SecurityName": "REGENT ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE769D01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512626",
                "SecurityId": "ORBTEXP",
                "SecurityName": "ORBIT EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE231G01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512628",
                "SecurityId": "ACQUATR",
                "SecurityName": "ACQUAINT EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512634",
                "SecurityId": "SAVERA",
                "SecurityName": "SAVERA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE104E01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512636",
                "SecurityId": "PREYATRDM",
                "SecurityName": "PREYANSHU EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512638",
                "SecurityId": "AMIGOEXDM",
                "SecurityName": "AMIGO EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512640",
                "SecurityId": "STOCKNET",
                "SecurityName": "STOCKNET INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE201D01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512642",
                "SecurityId": "ARTIBIOIN",
                "SecurityName": "ARTILLEGENCE BIO-INNOVATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE576F01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "512644",
                "SecurityId": "ARIHENT",
                "SecurityName": "ARIHANT ENTERPRISE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE154D01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513002",
                "SecurityId": "ANDHRSI",
                "SecurityName": "ANDHRA SINTER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513005",
                "SecurityId": "VBCFERROQ",
                "SecurityName": "VBC FERRO ALLOYS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE114E01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513010",
                "SecurityId": "TATASTLLP",
                "SecurityName": "TATA STEEL LONG PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE674A01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513012",
                "SecurityId": "ZJEETMAC",
                "SecurityName": "JEET MACHINE TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE987E01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513017",
                "SecurityId": "HIMISPTDM",
                "SecurityName": "HIM ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513018",
                "SecurityId": "ZHIMTUBL",
                "SecurityName": "HIMTUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513023",
                "SecurityId": "NBVENTURES",
                "SecurityName": "NAVA BHARAT VENTURES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE725A01022",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513039",
                "SecurityId": "ZHINUDYP",
                "SecurityName": "HINDUSTHAN UDYOG LTD.",
                "FaceValue": "10",
                "ISINNo": "INE582K01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513041",
                "SecurityId": "ISPLIND",
                "SecurityName": "ISPL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513043",
                "SecurityId": "REMIEDEL",
                "SecurityName": "REMI EDELSTAHL TUBULARS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE158G01015",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513059",
                "SecurityId": "GSAUTO",
                "SecurityName": "G.S.AUTO INTERNATIONAL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE736H01024",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513063",
                "SecurityId": "TRANSFRE",
                "SecurityName": "TRANS-FREIGHT CONTAINERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE360D01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513077",
                "SecurityId": "SANGHVI",
                "SecurityName": "SANGHVI STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513081",
                "SecurityId": "FEROCON",
                "SecurityName": "FERRO CONCRETE CO.(INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513097",
                "SecurityId": "SHBCLQ",
                "SecurityName": "SHIVALIK BIMETAL CONTROLS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE386D01027",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513108",
                "SecurityId": "GANDHITUBE",
                "SecurityName": "GANDHI SPECIAL TUBES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE524B01027",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513117",
                "SecurityId": "AMFORG",
                "SecurityName": "AMFORGE INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE991A01020",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513119",
                "SecurityId": "ABCGAS",
                "SecurityName": "ABC GAS (INTERNATIONAL) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE173M01012",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513121",
                "SecurityId": "ORICON",
                "SecurityName": "ORICON ENTERPRISES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE730A01022",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513129",
                "SecurityId": "GUJWDGE",
                "SecurityName": "GUJARAT WEDGE WIRE SCREENS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE090J01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513131",
                "SecurityId": "METAI",
                "SecurityName": "METALMAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE689B01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513134",
                "SecurityId": "PIOALOY",
                "SecurityName": "PIONEER ALLOY CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513142",
                "SecurityId": "BALASORE",
                "SecurityName": "BALASORE ALLOYS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE135A01024",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513149",
                "SecurityId": "ACROW",
                "SecurityName": "ACROW INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE950D01012",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513151",
                "SecurityId": "STINDIA",
                "SecurityName": "STI INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE090C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513153",
                "SecurityId": "TECHTREK",
                "SecurityName": "TECHTREK INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE892N01015",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513158",
                "SecurityId": "SRAMSTLDM",
                "SecurityName": "SRI RAMKRISHNA STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513173",
                "SecurityId": "STLSTRINF",
                "SecurityName": "STEEL STRIPS INFRASTRUCTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE205F01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513175",
                "SecurityId": "STELAGE-B",
                "SecurityName": "STEELAGE INDUSTRIES LTD.",
                "FaceValue": "12",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513178",
                "SecurityId": "ZRGISPAT",
                "SecurityName": "R.G.ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513179",
                "SecurityId": "NATNLSTEEL",
                "SecurityName": "NATIONAL STEEL & AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE088B01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513181",
                "SecurityId": "KRSTLDM",
                "SecurityName": "K.R.STEEL UNION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513188",
                "SecurityId": "ANNFODM",
                "SecurityName": "ANNAPURNA FOILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE566C01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513198",
                "SecurityId": "MEKSOLT",
                "SecurityName": "MEK SLOTTED ANGLES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513199",
                "SecurityId": "GMMITAL",
                "SecurityName": "G.M.MITTAL STAINLESS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513203",
                "SecurityId": "ANUPAST",
                "SecurityName": "ANUPAMA STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513207",
                "SecurityId": "HOGANDM",
                "SecurityName": "HOGANAS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE132A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513212",
                "SecurityId": "RMISTEL",
                "SecurityName": "RMI STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513216",
                "SecurityId": "UTTAMSTL",
                "SecurityName": "UTTAM GALVA STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE699A01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513223",
                "SecurityId": "SRIISHR",
                "SecurityName": "SHRI ISHAR ALLOY STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513228",
                "SecurityId": "PENIND",
                "SecurityName": "PENNAR INDUSTRIES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE932A01024",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513235",
                "SecurityId": "INDCHR",
                "SecurityName": "INDIAN METALS & FERRO ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE801B01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513240",
                "SecurityId": "CHOKSITUQ",
                "SecurityName": "CHOKSI TUBE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661C01017",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513242",
                "SecurityId": "MSLIND",
                "SecurityName": "MSL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE626C01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513244",
                "SecurityId": "KANTHDM",
                "SecurityName": "KANTHAL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE513D01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513250",
                "SecurityId": "JYOTISTRUC",
                "SecurityName": "JYOTI STRUCTURES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE197A01024",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513252",
                "SecurityId": "JAYUSH",
                "SecurityName": "JAY USHIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE289D01015",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513256",
                "SecurityId": "OREXTR",
                "SecurityName": "ORISSA EXTRUSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513262",
                "SecurityId": "SSWL",
                "SecurityName": "STEEL STRIPS WHEELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE802C01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513265",
                "SecurityId": "MUKESTL",
                "SecurityName": "MUKESH STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE953G01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513269",
                "SecurityId": "MANINDS",
                "SecurityName": "MAN INDUSTRIES (INDIA) LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE993A01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513272",
                "SecurityId": "HINFIND",
                "SecurityName": "HINDUSTAN FERRO AND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513277",
                "SecurityId": "SUPERFORGE",
                "SecurityName": "SUPER FORGINGS & STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661A01011",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513285",
                "SecurityId": "THPRISP-B",
                "SecurityName": "THAPAR ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513287",
                "SecurityId": "GKSTLALDM",
                "SecurityName": "G.K.STEEL & ALLIED INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513291",
                "SecurityId": "PREMPIPES",
                "SecurityName": "PREMIER PIPES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE448N01024",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513295",
                "SecurityId": "IMEC",
                "SecurityName": "IMEC SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE611C01012",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513297",
                "SecurityId": "SNGMALM",
                "SecurityName": "SANGAM ALUMINIUM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513301",
                "SecurityId": "JALNISP",
                "SecurityName": "JALAN ISPAT CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513303",
                "SecurityId": "MDRNSTL",
                "SecurityName": "MODERN STEELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE001F01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513305",
                "SecurityId": "TERRASCOPE",
                "SecurityName": "TERRASCOPE VENTURES LTD",
                "FaceValue": "5",
                "ISINNo": "INE346M01022",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513307",
                "SecurityId": "SYNTHFO",
                "SecurityName": "SYNTHIKO FOILS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE363L01029",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513309",
                "SecurityId": "GOLKONDA",
                "SecurityName": "GOLKONDA ALUMINIUM EXTRUSIONS LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE327C01031",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513313",
                "SecurityId": "ATMASTL",
                "SecurityName": "ATMA STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513315",
                "SecurityId": "KUSMINV",
                "SecurityName": "KUSUM INGOTS & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513317",
                "SecurityId": "AMBJELE",
                "SecurityName": "AMBUJA ELECTROCASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513319",
                "SecurityId": "TAMSPON",
                "SecurityName": "TAMILNADU SPONGE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513321",
                "SecurityId": "GSALIND",
                "SecurityName": "GSAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE653F01017",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513322",
                "SecurityId": "DHTUSNK",
                "SecurityName": "DHATU SANSKAR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513333",
                "SecurityId": "BHUWALST",
                "SecurityName": "BHUWALKA STEEL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE069C01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513335",
                "SecurityId": "METALFORGE",
                "SecurityName": "METALYST FORGINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE425A01011",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513337",
                "SecurityId": "GUJTLRM",
                "SecurityName": "GUJARAT TOOLROOM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE145J01024",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513343",
                "SecurityId": "GRANDFONRY",
                "SecurityName": "GRAND FOUNDRY LTD.",
                "FaceValue": "4",
                "ISINNo": "INE534A01028",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513347",
                "SecurityId": "JALNFRG",
                "SecurityName": "JALAN FORGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513349",
                "SecurityId": "AJMERA",
                "SecurityName": "AJMERA REALTY & INFRA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE298G01027",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513351",
                "SecurityId": "SRKRSTR",
                "SecurityName": "SKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513353",
                "SecurityId": "COCHINM",
                "SecurityName": "COCHIN MINERALS & RUTILE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE105D01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513355",
                "SecurityId": "MRMGAST",
                "SecurityName": "MARMAGOA STEELS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE698E01023",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513357",
                "SecurityId": "AMBJZNC",
                "SecurityName": "AMBUJA ZINC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Zinc",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513359",
                "SecurityId": "PARINFRA",
                "SecurityName": "PARAB INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE713M01023",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513361",
                "SecurityId": "ISWL",
                "SecurityName": "INDIA STEEL WORKS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE072A01029",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513363",
                "SecurityId": "VARAHI",
                "SecurityName": "VARAHI DIAMONDS & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513369",
                "SecurityId": "RJKMRFR",
                "SecurityName": "RAJKUMAR FORGE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE013J01016",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513375",
                "SecurityId": "CARBORUNIV",
                "SecurityName": "CARBORUNDUM UNIVERSAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE120A01034",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513377",
                "SecurityId": "MMTC",
                "SecurityName": "MMTC LTD.",
                "FaceValue": "1",
                "ISINNo": "INE123F01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513383",
                "SecurityId": "DHAPOWR",
                "SecurityName": "DHARMADEEP POWERDRIVE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513385",
                "SecurityId": "INSSADM",
                "SecurityName": "INDIAN SEAMLESS STEELS & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE879C01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513387",
                "SecurityId": "DHTUFRG",
                "SecurityName": "DHATU FORGE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513391",
                "SecurityId": "SNGHLSW-B",
                "SecurityName": "SINGHAL SWAROOP ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513395",
                "SecurityId": "NOVST",
                "SecurityName": "NOVA STEEL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE587E01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513397",
                "SecurityId": "VALLABHSQ",
                "SecurityName": "VALLABH STEELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE457E01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513399",
                "SecurityId": "ORDEIND",
                "SecurityName": "ORDE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513401",
                "SecurityId": "ASHIS",
                "SecurityName": "ASHIANA ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE587D01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513403",
                "SecurityId": "PMTELELIN",
                "SecurityName": "P.M.TELELINNKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE092C01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513405",
                "SecurityId": "PENNARALUM",
                "SecurityName": "PENNAR ALUMINIUM CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE057C01018",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513406",
                "SecurityId": "RAIPF",
                "SecurityName": "RAJ IRRIGATION PIPES & FITTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE649D01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513408",
                "SecurityId": "RAJINAL",
                "SecurityName": "RAJINDER ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513412",
                "SecurityId": "ALCOBMT-B",
                "SecurityName": "ALCOBEX METALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513414",
                "SecurityId": "SMPL",
                "SecurityName": "SPLENDID METAL PRODUCTS LTD",
                "FaceValue": "5",
                "ISINNo": "INE215G01021",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513418",
                "SecurityId": "SMFIL",
                "SecurityName": "SMITHS & FOUNDERS (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE728B01032",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513420",
                "SecurityId": "LADAMST",
                "SecurityName": "LADAM STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513422",
                "SecurityId": "BLOIN",
                "SecurityName": "BLOOM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE373E01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513424",
                "SecurityId": "ELLOSTL",
                "SecurityName": "ELLORA STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513428",
                "SecurityId": "TRINETRA",
                "SecurityName": "TRINETRA CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE031L01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513430",
                "SecurityId": "MAITRI",
                "SecurityName": "MAITRI ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE501L01024",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513434",
                "SecurityId": "TATAMETALI",
                "SecurityName": "TATA METALIKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE056C01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513436",
                "SecurityId": "SHAHALLOYS",
                "SecurityName": "SHAH ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE640C01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513438",
                "SecurityId": "RAJAMBI",
                "SecurityName": "RAJASTHAN AMBUJA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513440",
                "SecurityId": "ANIKSTL",
                "SecurityName": "ANIK STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513442",
                "SecurityId": "SWETAST",
                "SecurityName": "SWEATAMBER STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513446",
                "SecurityId": "JSWISPL",
                "SecurityName": "JSW ISPAT SPECIAL PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE743C01021",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513448",
                "SecurityId": "GANFNDR",
                "SecurityName": "GANESH FOUNDRY & CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE449U01019",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513450",
                "SecurityId": "MRDIACO",
                "SecurityName": "MARDIA COPPER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513452",
                "SecurityId": "ELANGO",
                "SecurityName": "ELANGO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE594D01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513456",
                "SecurityId": "KANSHST",
                "SecurityName": "KANISHK STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE791E01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513458",
                "SecurityId": "ASIANAL-B",
                "SecurityName": "ASIAN ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513460",
                "SecurityId": "MAHALXSE",
                "SecurityName": "MAHALAXMI SEAMLESS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE257F01017",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513462",
                "SecurityId": "SAMNAST",
                "SecurityName": "SAMANA STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513464",
                "SecurityId": "USHAIRN",
                "SecurityName": "USHA IRON & FERRO METALS CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513470",
                "SecurityId": "DENHSTLDM",
                "SecurityName": "DENHOLM STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513472",
                "SecurityId": "SIMPLEXCAS",
                "SecurityName": "SIMPLEX CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE658D01011",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513476",
                "SecurityId": "STRNJAY",
                "SecurityName": "SHATRUNJAY EXTRUSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513478",
                "SecurityId": "SAURALL",
                "SecurityName": "SAURABH ALLOY CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513480",
                "SecurityId": "RAKSTEL",
                "SecurityName": "RAKAN STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513482",
                "SecurityId": "UNIMETA",
                "SecurityName": "UNI-METAL ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513486",
                "SecurityId": "TRIDSTLDM",
                "SecurityName": "TRIDENT STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513488",
                "SecurityId": "SSWRL",
                "SecurityName": "SHREE STEEL WIRE ROPES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE387D01025",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513490",
                "SecurityId": "GUJCONC",
                "SecurityName": "GUJARAT CONCAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513492",
                "SecurityId": "ASLOSTL",
                "SecurityName": "ASLO STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513496",
                "SecurityId": "MEWATZI",
                "SecurityName": "MEWAT ZINC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE235U01012",
                "Industry": "Zinc",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513498",
                "SecurityId": "SOUTHMG",
                "SecurityName": "SOUTHERN MAGNESIUM & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE308N01012",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513500",
                "SecurityId": "CHARMST",
                "SecurityName": "CHARMINAR STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513502",
                "SecurityId": "BAROEXT",
                "SecurityName": "BARODA EXTRUSION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE927K01023",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513504",
                "SecurityId": "JAYAGRO",
                "SecurityName": "JAY AGROCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513505",
                "SecurityId": "STARELE",
                "SecurityName": "STAR PRECISION ELECTRONICS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513507",
                "SecurityId": "GUJCONT",
                "SecurityName": "GUJARAT CONTAINERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE276I01011",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513509",
                "SecurityId": "KALYANIFRG",
                "SecurityName": "KALYANI FORGE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE314G01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513511",
                "SecurityId": "PANCHMAHQ",
                "SecurityName": "PANCHMAHAL STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE798F01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513513",
                "SecurityId": "ADITYA",
                "SecurityName": "ADITYA ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE570B01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513515",
                "SecurityId": "SRIND",
                "SecurityName": "S.R.INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE329C01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513517",
                "SecurityId": "STEELCAS",
                "SecurityName": "STEELCAST LTD.",
                "FaceValue": "5",
                "ISINNo": "INE124E01020",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513519",
                "SecurityId": "PITTIENG",
                "SecurityName": "PITTI ENGINEERING LTD-$",
                "FaceValue": "5",
                "ISINNo": "INE450D01021",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513523",
                "SecurityId": "PUNIRST",
                "SecurityName": "PUNJAB IRON & STEEL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513526",
                "SecurityId": "SHREGRN",
                "SecurityName": "SHREEJI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513528",
                "SecurityId": "GLITTEKG",
                "SecurityName": "GLITTEK GRANITES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE741B01027",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513530",
                "SecurityId": "STELCOST",
                "SecurityName": "STELCO STRIPS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE308D01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513532",
                "SecurityId": "PRADPME",
                "SecurityName": "PRADEEP METALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE770A01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513534",
                "SecurityId": "VARDHINDQ",
                "SecurityName": "VARDHMAN INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE458E01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513536",
                "SecurityId": "GNRL",
                "SecurityName": "GUJARAT NATURAL RESOURCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE207H01018",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513540",
                "SecurityId": "TNSTLTU",
                "SecurityName": "TAMILNADU STEEL TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE176E01012",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513542",
                "SecurityId": "JAYIRST",
                "SecurityName": "JAY IRON & STEELS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE986E01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513544",
                "SecurityId": "MSCTC",
                "SecurityName": "MARDIA SAMYOUNG CAPILLARY TUBES COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE277E01026",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513548",
                "SecurityId": "SHRDAIS",
                "SecurityName": "SHARDA ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE385M01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513554",
                "SecurityId": "MAHASTEEL",
                "SecurityName": "MAHAMAYA STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE451L01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513556",
                "SecurityId": "SERHDMT",
                "SecurityName": "SPEARHEAD METALS & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513558",
                "SecurityId": "REALSTR",
                "SecurityName": "REAL STRIPS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE183B01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513562",
                "SecurityId": "VIRAJAL",
                "SecurityName": "VIRAJ ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513564",
                "SecurityId": "MITHIMJ",
                "SecurityName": "MITHILA STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513566",
                "SecurityId": "NOVIS",
                "SecurityName": "NOVA IRON & STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE608C01026",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513568",
                "SecurityId": "RATHIMJ",
                "SecurityName": "RATHI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513573",
                "SecurityId": "VISHWAST",
                "SecurityName": "VISHWAS STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE014C01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513575",
                "SecurityId": "STERPOW",
                "SecurityName": "STERLING POWERGENSYS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE067E01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513577",
                "SecurityId": "MIDWEIR",
                "SecurityName": "MID WEST IRON AND STEEL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513579",
                "SecurityId": "FFPL",
                "SecurityName": "FOUNDRY FUEL PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE617C01027",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513583",
                "SecurityId": "SB&TINTL",
                "SecurityName": "SB & T INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE465B01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513593",
                "SecurityId": "STACH",
                "SecurityName": "STANDARD CHROME LTD.",
                "FaceValue": "10",
                "ISINNo": "INE715E01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513595",
                "SecurityId": "GLEITLAI",
                "SecurityName": "GLEITLAGER (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE190C01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513597",
                "SecurityId": "SURANAIND",
                "SecurityName": "SURANA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE659D01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513599",
                "SecurityId": "HINDCOPPER",
                "SecurityName": "HINDUSTAN COPPER LTD.",
                "FaceValue": "5",
                "ISINNo": "INE531E01026",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513601",
                "SecurityId": "MALHOST",
                "SecurityName": "MALHOTRA STEEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513603",
                "SecurityId": "MORWIRE",
                "SecurityName": "MOIRA WIRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513605",
                "SecurityId": "SRIPIPES",
                "SecurityName": "SRIKALAHASTHI PIPES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE943C01027",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513609",
                "SecurityId": "SRIVASAV",
                "SecurityName": "SRI VASAVI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE634E01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513611",
                "SecurityId": "PITHMST",
                "SecurityName": "PITHAMPUR STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE077H01015",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513613",
                "SecurityId": "PRESHAMET",
                "SecurityName": "PRESHA METALLURGICAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE908L01013",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513615",
                "SecurityId": "SHIVLOH",
                "SecurityName": "SHIVALIK LOHA MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513617",
                "SecurityId": "INLACGR",
                "SecurityName": "INLAC GRANSTON LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513621",
                "SecurityId": "GLOARYA",
                "SecurityName": "GLOBAL ARYA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513623",
                "SecurityId": "SMELTER",
                "SecurityName": "SMELTERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513625",
                "SecurityId": "KUSIS",
                "SecurityName": "KUSUM IRON & STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE397E01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513627",
                "SecurityId": "KRISFEP",
                "SecurityName": "KRISHNA FERRO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE218L01017",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513629",
                "SecurityId": "TULSYAN",
                "SecurityName": "TULSYAN NEC LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE463D01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513630",
                "SecurityId": "RAJMALL",
                "SecurityName": "RAJESH MALLEABLES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513632",
                "SecurityId": "DHARIND",
                "SecurityName": "DHAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513634",
                "SecurityId": "PANAUTO",
                "SecurityName": "PAN AUTO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513636",
                "SecurityId": "TALVROC",
                "SecurityName": "TALAVADI ROCK & MINERAL PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513638",
                "SecurityId": "NATUSTO",
                "SecurityName": "NATURAL STONE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE893G01017",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513640",
                "SecurityId": "HJSSTON",
                "SecurityName": "H.J.S.STONES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513642",
                "SecurityId": "AXELPOLY",
                "SecurityName": "AXEL POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE197C01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513644",
                "SecurityId": "BALAJST",
                "SecurityName": "BALAJI STEEL TUBES AND PIPES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513646",
                "SecurityId": "DEWNSTL",
                "SecurityName": "DEWAN STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513648",
                "SecurityId": "MARGPROIN",
                "SecurityName": "MARG PROJECTS AND INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE942E01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513650",
                "SecurityId": "CENTSHT",
                "SecurityName": "CENTURY SHEET METALS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513652",
                "SecurityId": "MIDEINT",
                "SecurityName": "MIDEAST INTEGRATED STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513683",
                "SecurityId": "NLCINDIA",
                "SecurityName": "NLC INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE589A01014",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513685",
                "SecurityId": "MULTIARC",
                "SecurityName": "MULTI-ARC INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE399B01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513687",
                "SecurityId": "SPECTRA",
                "SecurityName": "SPECTRA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE848B01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513691",
                "SecurityId": "JMTAUTOLTD",
                "SecurityName": "JMT AUTO LTD.",
                "FaceValue": "1",
                "ISINNo": "INE988E01036",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513693",
                "SecurityId": "KAJARIR",
                "SecurityName": "KIC METALIKS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE434C01027",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513695",
                "SecurityId": "SIDVIME",
                "SecurityName": "SIDDHI VINAYAK METAL COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513697",
                "SecurityId": "SINGALLDM",
                "SecurityName": "SINGH ALLOYS & STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513699",
                "SecurityId": "SOLIDSTON",
                "SecurityName": "SOLID STONE COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE584G01012",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513701",
                "SecurityId": "AACHALL",
                "SecurityName": "AACHAL ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513703",
                "SecurityId": "KUMARWI",
                "SecurityName": "KUMAR WIRE CLOTH MANUFACTURING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE840A01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513705",
                "SecurityId": "VARDWIR",
                "SecurityName": "VARDHAMAN WIRES & POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE157G01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513707",
                "SecurityId": "MARDIAST",
                "SecurityName": "MARDIA STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE439D01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513709",
                "SecurityId": "SHILGRAVQ",
                "SecurityName": "SHILP GRAVURES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE960A01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513711",
                "SecurityId": "KITTIST",
                "SecurityName": "KITTI STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513713",
                "SecurityId": "WHITEORG",
                "SecurityName": "WHITE ORGANIC AGRO LTD",
                "FaceValue": "10",
                "ISINNo": "INE146C01019",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513715",
                "SecurityId": "VALLYAB",
                "SecurityName": "VALLEY ABRASIVES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE904E01017",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513717",
                "SecurityId": "SAROMET",
                "SecurityName": "SAROVAR METALLWERKE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513721",
                "SecurityId": "MFSINTRCRP",
                "SecurityName": "MFS INTERCORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE614F01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513723",
                "SecurityId": "HIMGRANI",
                "SecurityName": "HIMALAYA GRANITES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE464C01024",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513725",
                "SecurityId": "CVSTEEL",
                "SecurityName": "C.V.STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513727",
                "SecurityId": "RANJEEV",
                "SecurityName": "RANJEEV ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE478F01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513729",
                "SecurityId": "AROGRANITE",
                "SecurityName": "ARO GRANITE INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE210C01013",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513731",
                "SecurityId": "JGFOUMT",
                "SecurityName": "J.G.FOUNDRY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "513733",
                "SecurityId": "SHPOMMT",
                "SecurityName": "SHREE POMANI METALS & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514010",
                "SecurityId": "HIMFIBP",
                "SecurityName": "HIMACHAL FIBRES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE723D01021",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514012",
                "SecurityId": "GUJFILA",
                "SecurityName": "GUJARAT FILAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514017",
                "SecurityId": "PRSRSYN-B",
                "SecurityName": "PARASRAMPURIA SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514026",
                "SecurityId": "BINDLTX",
                "SecurityName": "BINDAL TEXTILE MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514028",
                "SecurityId": "RAJKSYN",
                "SecurityName": "RAJKAMAL SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE376L01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514030",
                "SecurityId": "DEEPAKSP",
                "SecurityName": "DEEPAK SPINNERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE272C01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514032",
                "SecurityId": "SHRDATX",
                "SecurityName": "SHARDA TEXTILE MILLS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514034",
                "SecurityId": "JBFIND",
                "SecurityName": "JBF INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE187A01017",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514036",
                "SecurityId": "LOYALTEX",
                "SecurityName": "LOYAL TEXTILE MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE970D01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514039",
                "SecurityId": "AKCSYNT",
                "SecurityName": "AKC SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514040",
                "SecurityId": "PRSRIND",
                "SecurityName": "PARASRAMPURIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514043",
                "SecurityId": "HIMATSEIDE",
                "SecurityName": "HIMATSINGKA SEIDE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE049A01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514045",
                "SecurityId": "BSL",
                "SecurityName": "BSL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE594B01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514055",
                "SecurityId": "ADHUSYN",
                "SecurityName": "ADHUNIK SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514060",
                "SecurityId": "EVERTEX",
                "SecurityName": "EVERGREEN TEXTILES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE229N01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514063",
                "SecurityId": "TRIMSYN",
                "SecurityName": "TRIMURTI SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514073",
                "SecurityId": "RAJPOLY",
                "SecurityName": "RAJASTHAN POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE702B01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514078",
                "SecurityId": "KRIMPEX",
                "SecurityName": "KRIMPEX SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514087",
                "SecurityId": "PBMPOLY",
                "SecurityName": "PBM POLYTEX LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE501F01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514108",
                "SecurityId": "FUSPOLY",
                "SecurityName": "FUSION POLYMER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514113",
                "SecurityId": "ADINATH",
                "SecurityName": "ADINATH TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE207C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514114",
                "SecurityId": "OASISTEX",
                "SecurityName": "OASIS TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE181W01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514116",
                "SecurityId": "GUPTSYN",
                "SecurityName": "GUPTA SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE957D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514118",
                "SecurityId": "ESKAY",
                "SecurityName": "ESKAY K&#39;N&#39;IT (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE220A01032",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514122",
                "SecurityId": "ARIHANTCOT",
                "SecurityName": "ARIHANT COTSYN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE925B01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514128",
                "SecurityId": "KONARKSY",
                "SecurityName": "KONARK SYNTHETIC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE517D01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514134",
                "SecurityId": "ARYNFFB-B",
                "SecurityName": "ARYAN FINEFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514138",
                "SecurityId": "SURYALA",
                "SecurityName": "SURYALATA SPINNING MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE132C01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514140",
                "SecurityId": "SURYVANSP",
                "SecurityName": "SURYAVANSHI SPINNING MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE431C01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514142",
                "SecurityId": "TTL",
                "SecurityName": "T T LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE592B01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514144",
                "SecurityId": "UNIWORTH",
                "SecurityName": "UNIWORTH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE207A01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514146",
                "SecurityId": "LKSHSYN-B",
                "SecurityName": "LAKSHMI SYNTHETIC MACHINERY MFRS.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514152",
                "SecurityId": "SHAMKMUL",
                "SecurityName": "SHAMKEN MULTIFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "INE565B01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514156",
                "SecurityId": "VKAYFIBDM",
                "SecurityName": "VEE KAY FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514160",
                "SecurityId": "MAHENPET",
                "SecurityName": "MAHENDRA PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE452H01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514162",
                "SecurityId": "WELSPUNIND",
                "SecurityName": "WELSPUN INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE192B01031",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514165",
                "SecurityId": "INDIANACRY",
                "SecurityName": "INDIAN ACRYLICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE862B01013",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514167",
                "SecurityId": "GANECOS",
                "SecurityName": "GANESHA ECOSPHERE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE845D01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514169",
                "SecurityId": "VIKASAS",
                "SecurityName": "VISAKA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514171",
                "SecurityId": "CEETAIN",
                "SecurityName": "CEETA INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE760J01012",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514175",
                "SecurityId": "VARDMNPOLY",
                "SecurityName": "VARDHMAN POLYTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE835A01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514179",
                "SecurityId": "PUNFIBR",
                "SecurityName": "PUNJAB FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514183",
                "SecurityId": "BLACKROSE",
                "SecurityName": "BLACK ROSE INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE761G01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514185",
                "SecurityId": "SHATEX",
                "SecurityName": "SHATEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514189",
                "SecurityId": "KESWASY",
                "SecurityName": "KESWANI SYNTHETICS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE396E01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514191",
                "SecurityId": "BHIWDEN",
                "SecurityName": "BHIWANI DENIM & APPARELS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514197",
                "SecurityId": "STCORP",
                "SecurityName": "S&T CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE110Q01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514199",
                "SecurityId": "BALATECIN",
                "SecurityName": "BALA TECHNO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE653B01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514201",
                "SecurityId": "MARUTISDM",
                "SecurityName": "MARUTI SYNTEX (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514203",
                "SecurityId": "LOHIAPL",
                "SecurityName": "LOHIA POLYESTER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514205",
                "SecurityId": "LIBRAFL",
                "SecurityName": "LIBRA FILAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514207",
                "SecurityId": "NUFABID",
                "SecurityName": "NUFAB INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514209",
                "SecurityId": "SHAMKENC",
                "SecurityName": "SHAMKEN COTSYN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE352D01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514211",
                "SecurityId": "SUMEETINDS",
                "SecurityName": "SUMEET INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE235C01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514213",
                "SecurityId": "MKDOTEX",
                "SecurityName": "MIKADO TEXTILE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514215",
                "SecurityId": "BINNY",
                "SecurityName": "BINNY LTD.",
                "FaceValue": "5",
                "ISINNo": "INE118K01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514217",
                "SecurityId": "ZSRMEENDM",
                "SecurityName": "SREE MEENAKSHI MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514219",
                "SecurityId": "NHRFBDM",
                "SecurityName": "NHRFBDM",
                "FaceValue": "10",
                "ISINNo": "INE309A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514221",
                "SecurityId": "KLIFESTYL",
                "SecurityName": "K-LIFESTYLE & INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE218A01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514223",
                "SecurityId": "RAMGOPOLY",
                "SecurityName": "RAMGOPAL POLYTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE410D01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514228",
                "SecurityId": "KAYELSY",
                "SecurityName": "KAYEL SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514230",
                "SecurityId": "MADHUMI-B",
                "SecurityName": "MADHUMILAN SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514232",
                "SecurityId": "CHANDSY",
                "SecurityName": "CHANDRA SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514234",
                "SecurityId": "SANGAMIND",
                "SecurityName": "SANGAM (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE495C01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514236",
                "SecurityId": "TTLEL",
                "SecurityName": "TTL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE664X01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514238",
                "SecurityId": "IKAB",
                "SecurityName": "IKAB SECURITIES & INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE874A01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514240",
                "SecurityId": "KUSHIND",
                "SecurityName": "KUSH INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE979D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514242",
                "SecurityId": "MHMLIND",
                "SecurityName": "MH MILLS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE698F01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514244",
                "SecurityId": "SILKONS",
                "SecurityName": "SILKON SILK MILLS (EXPORT) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514246",
                "SecurityId": "EASTRNSI",
                "SecurityName": "EASTERN SILK INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE962C01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514248",
                "SecurityId": "SRECR",
                "SecurityName": "SREECHEM RESINS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE377C01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514250",
                "SecurityId": "MAYASPN",
                "SecurityName": "MAYA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514252",
                "SecurityId": "GIRNFIB",
                "SecurityName": "GIRNAR FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514254",
                "SecurityId": "ENKTEXFOOD",
                "SecurityName": "ENKAY TEXFOODS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE108D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514258",
                "SecurityId": "HYTONE",
                "SecurityName": "HYTONE TEXSTYLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE926D01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514260",
                "SecurityId": "SURBHIN",
                "SecurityName": "SURBHI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE899E01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514262",
                "SecurityId": "ANKITYN",
                "SecurityName": "ANKIT YARNS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514264",
                "SecurityId": "SEASONST",
                "SecurityName": "SEASONS TEXTILES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE707B01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514266",
                "SecurityId": "ZENIFIB",
                "SecurityName": "ZENITH FIBRES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE106C01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514268",
                "SecurityId": "SUBHTXT",
                "SecurityName": "SUBHAKTI TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514270",
                "SecurityId": "AMARPLY",
                "SecurityName": "AMAR POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514272",
                "SecurityId": "BHILSPIN",
                "SecurityName": "BHILWARA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE436C01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514274",
                "SecurityId": "AARVEEDEN",
                "SecurityName": "AARVEE DENIMS & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE273D01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514278",
                "SecurityId": "GJKNIT",
                "SecurityName": "GUJARAT NARMADA KNIT WEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514280",
                "SecurityId": "SANTETX",
                "SecurityName": "SANRHEA TECHNICAL TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE589J01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514282",
                "SecurityId": "UNIIN",
                "SecurityName": "UNIWORTH INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE760D01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514284",
                "SecurityId": "GKAYTEX",
                "SecurityName": "GEE KAY TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514286",
                "SecurityId": "ASHIMASYN",
                "SecurityName": "ASHIMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE440A01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514292",
                "SecurityId": "INDPLYF",
                "SecurityName": "INDIAN POLYFINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514294",
                "SecurityId": "KREMSPN",
                "SecurityName": "KAREEMS SPUN SILK LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514296",
                "SecurityId": "HARYANATEX",
                "SecurityName": "HARYANA TEXPRINTS (OVERSEAS) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE206G01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514298",
                "SecurityId": "INTEGRAL",
                "SecurityName": "INTEGRAL KNIT CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514300",
                "SecurityId": "PIONEEREMB",
                "SecurityName": "PIONEER EMBROIDERIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE156C01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514302",
                "SecurityId": "VIPPYSP",
                "SecurityName": "VIPPY SPINPRO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE660D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514304",
                "SecurityId": "SKUMARN",
                "SecurityName": "S.KUMARS NATIONWIDE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE772A01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514306",
                "SecurityId": "GARLNIN",
                "SecurityName": "GARLON POLYFAB INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE875E01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514308",
                "SecurityId": "HANJFIB",
                "SecurityName": "HANJER FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE053G01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514310",
                "SecurityId": "PATEXTI",
                "SecurityName": "PATODIA TEXTILE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514312",
                "SecurityId": "JAIHINDS",
                "SecurityName": "JAIHIND SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE156E01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514314",
                "SecurityId": "YNGIRSY",
                "SecurityName": "YANGIR SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514316",
                "SecurityId": "RAGHUSYN",
                "SecurityName": "RAGHUVIR SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE969C01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514318",
                "SecurityId": "JATTAINDUS",
                "SecurityName": "JATTASHANKAR INDUSTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE722N01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514322",
                "SecurityId": "KAMADGIRI",
                "SecurityName": "KAMADGIRI FASHION LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE535C01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514324",
                "SecurityId": "OMNITEX",
                "SecurityName": "OMNITEX INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE814D01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514326",
                "SecurityId": "PATSPINLTD",
                "SecurityName": "PATSPIN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE790C01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514328",
                "SecurityId": "NACHKNIT",
                "SecurityName": "NACHMO KNITEX LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE228C01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514330",
                "SecurityId": "ONEGLOBAL",
                "SecurityName": "ONE GLOBAL SERVICE PROVIDER LTD",
                "FaceValue": "10",
                "ISINNo": "INE670O01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514332",
                "SecurityId": "NEOINFRA",
                "SecurityName": "NEO INFRACON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE216I01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514334",
                "SecurityId": "DIVYENT",
                "SecurityName": "DIVYA ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514336",
                "SecurityId": "GENESIS",
                "SecurityName": "GENESIS IBRC INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE194N01016",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514338",
                "SecurityId": "ADITYPL",
                "SecurityName": "ADITYA POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514340",
                "SecurityId": "ARNMNTX",
                "SecurityName": "ARUN MANTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514344",
                "SecurityId": "BINASYN",
                "SecurityName": "BINACA SYNTHETIC RESINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514346",
                "SecurityId": "BAROPOL",
                "SecurityName": "BARODA POLYPLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514348",
                "SecurityId": "WINSOME",
                "SecurityName": "WINSOME YARNS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE784B01035",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514350",
                "SecurityId": "SSTSILKS",
                "SecurityName": "SSTELLA SILKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE133F01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514352",
                "SecurityId": "PAROSYN",
                "SecurityName": "PARO SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514354",
                "SecurityId": "PREMIERPOL",
                "SecurityName": "PREMIER POLYFILM LTD.",
                "FaceValue": "5",
                "ISINNo": "INE309M01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514358",
                "SecurityId": "EVERLON",
                "SecurityName": "EVERLON SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE339D01034",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514360",
                "SecurityId": "KRRAIL",
                "SecurityName": "K&R RAIL ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE078T01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514362",
                "SecurityId": "ORIEFAB",
                "SecurityName": "ORIENT FABRITEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514364",
                "SecurityId": "MUKSYNT",
                "SecurityName": "MUKUND SYNTEX LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514366",
                "SecurityId": "CITIZYN",
                "SecurityName": "CITIZEN YARNS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE940P01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514370",
                "SecurityId": "HARPOLY",
                "SecurityName": "HARSH POLYMERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514372",
                "SecurityId": "USHMAPL",
                "SecurityName": "USHMA POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514376",
                "SecurityId": "JAIPSYN",
                "SecurityName": "JAIPUR SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514378",
                "SecurityId": "YARNSYN",
                "SecurityName": "YARN SYNDICATE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE564C01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514380",
                "SecurityId": "MAIKALFI",
                "SecurityName": "MAIKAAL FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE341C01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514384",
                "SecurityId": "UNIRLEA",
                "SecurityName": "UNIROLL LEATHER INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514386",
                "SecurityId": "GUJCOTEX",
                "SecurityName": "GUJARAT COTEX LTD.",
                "FaceValue": "5",
                "ISINNo": "INE004C01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514390",
                "SecurityId": "KARNIND",
                "SecurityName": "KARAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514392",
                "SecurityId": "JAIMI",
                "SecurityName": "JAI MATA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE562D01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514394",
                "SecurityId": "AJIL",
                "SecurityName": "ATLAS JEWELLERY INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE022N01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514396",
                "SecurityId": "ARMPOLY",
                "SecurityName": "ARM POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514398",
                "SecurityId": "SHEETFI",
                "SecurityName": "SHEETAL FILAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514400",
                "SecurityId": "GARWSYN",
                "SecurityName": "GARWARE SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE340D01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514402",
                "SecurityId": "SHARDFI",
                "SecurityName": "SHARAD FIBRES & YARN PROCESSORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE638N01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514404",
                "SecurityId": "KRISSYN",
                "SecurityName": "KRISHNA SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514406",
                "SecurityId": "NEINTEX",
                "SecurityName": "NEO INTEX MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514408",
                "SecurityId": "ORTOSYN",
                "SecurityName": "ORTON SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514410",
                "SecurityId": "TOORPSY",
                "SecurityName": "TOOR SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514412",
                "SecurityId": "SARUPINDUS",
                "SecurityName": "SARUP INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE305D01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514414",
                "SecurityId": "OXFORDIN",
                "SecurityName": "OXFORD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE114D01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514416",
                "SecurityId": "KAMALDS",
                "SecurityName": "KAMALDEEP SYNTHETIC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514418",
                "SecurityId": "MANORG",
                "SecurityName": "MANGALAM ORGANICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE370D01013",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514422",
                "SecurityId": "SDHATER",
                "SecurityName": "SHARADHA TERRY PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE167G01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514428",
                "SecurityId": "HINDADH",
                "SecurityName": "HINDUSTAN ADHESIVES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE074C01013",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514430",
                "SecurityId": "RAJRS",
                "SecurityName": "RAJRATAN SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE522C01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514432",
                "SecurityId": "NTEXINDDM",
                "SecurityName": "NOVOTEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514434",
                "SecurityId": "RSLINDU",
                "SecurityName": "RSL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514436",
                "SecurityId": "KONGINT",
                "SecurityName": "KONGARAR INTEGRATED FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514438",
                "SecurityId": "SUPSOXL",
                "SecurityName": "SUPERIOR SOX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514440",
                "SecurityId": "BPTEX",
                "SecurityName": "BLUE PEARL TEXSPIN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE439N01023",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514442",
                "SecurityId": "SRIKPRIND",
                "SecurityName": "SRI KPR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE009C01019",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514446",
                "SecurityId": "LSIND",
                "SecurityName": "LS INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE345D01031",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514448",
                "SecurityId": "JYOTIRES",
                "SecurityName": "JYOTI RESINS & ADHESIVES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE577D01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514450",
                "SecurityId": "MHLXMIRU",
                "SecurityName": "MAHALAXMI RUBTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE112D01035",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514452",
                "SecurityId": "ADHUYRN",
                "SecurityName": "ADHUNIK YARNS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514454",
                "SecurityId": "SOUTLAT",
                "SecurityName": "SOUTHERN LATEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE410M01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514458",
                "SecurityId": "SHANINT",
                "SecurityName": "SHAAN INTERWELL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514460",
                "SecurityId": "OSWAYRN",
                "SecurityName": "OSWAL YARNS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE670H01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514464",
                "SecurityId": "TAICHONG",
                "SecurityName": "TAI CHONBANG TEXTILE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE913B01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514466",
                "SecurityId": "ESSJSYN",
                "SecurityName": "ESSJAY SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514470",
                "SecurityId": "WINSOMTX",
                "SecurityName": "WINSOME TEXTILE INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE837B01031",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514472",
                "SecurityId": "FILAMENT",
                "SecurityName": "FILAMENTS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE179C01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514474",
                "SecurityId": "FAIRDSY",
                "SecurityName": "FAIR DEAL FILAMENTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE719D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514476",
                "SecurityId": "AKAIMPX",
                "SecurityName": "AKAI IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514478",
                "SecurityId": "TERRYGOL",
                "SecurityName": "TERRYGOLD (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE995B01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514480",
                "SecurityId": "ARIHANTRED",
                "SecurityName": "ARIHANT THREADS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE747A01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514482",
                "SecurityId": "ASAHIIND",
                "SecurityName": "ASAHI INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE745I01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514484",
                "SecurityId": "THAMBBI",
                "SecurityName": "THAMBBI MODERN SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE830D01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514486",
                "SecurityId": "POLTC",
                "SecurityName": "POLYGENTA TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE441D01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514488",
                "SecurityId": "SARDAINF",
                "SecurityName": "SARDA INFORMATION TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE342C01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514490",
                "SecurityId": "INDPOLYS",
                "SecurityName": "INDIA POLYSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE725D01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514492",
                "SecurityId": "MANGALVEN",
                "SecurityName": "MANGALAM VENTURES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE198E01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "514494",
                "SecurityId": "SHSHYAM",
                "SecurityName": "SHREE SHYAM FABRICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515003",
                "SecurityId": "SPACI",
                "SecurityName": "SPARTEK CERAMICS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE645A01014",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515008",
                "SecurityId": "MODINSU",
                "SecurityName": "MODERN INSULATORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE219W01012",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515017",
                "SecurityId": "UNIFRAXINDM",
                "SecurityName": "UNIFRAX INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515018",
                "SecurityId": "REGENCERAM",
                "SecurityName": "REGENCY CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE277C01012",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515026",
                "SecurityId": "VICGLSS",
                "SecurityName": "VICTORY GLASS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE512E01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515028",
                "SecurityId": "WILDONDM",
                "SecurityName": "WILDON INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515030",
                "SecurityId": "ASAHIINDIA",
                "SecurityName": "ASAHI INDIA GLASS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE439A01020",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515032",
                "SecurityId": "ROCKCOP",
                "SecurityName": "ROCK COPCO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515035",
                "SecurityId": "BELLCERA",
                "SecurityName": "BELL CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE403A01026",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515037",
                "SecurityId": "MURUDCERA",
                "SecurityName": "MURUDESHWAR CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE692B01014",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515039",
                "SecurityId": "MTLXCER",
                "SecurityName": "METLEX CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515043",
                "SecurityId": "SAINTGOBAIN",
                "SecurityName": "SAINT-GOBAIN SEKURIT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE068B01017",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515047",
                "SecurityId": "ATULGLS",
                "SecurityName": "ATUL GLASS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515051",
                "SecurityId": "ZGMBCERA",
                "SecurityName": "GMB CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515055",
                "SecurityId": "ANANTRAJ",
                "SecurityName": "ANANT RAJ LIMITED-$",
                "FaceValue": "2",
                "ISINNo": "INE242C01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515059",
                "SecurityId": "MADHUDIN",
                "SecurityName": "MADHUSUDAN INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE469C01023",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515063",
                "SecurityId": "GRPOGRN",
                "SecurityName": "GRAPCO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515065",
                "SecurityId": "SANDPLS",
                "SecurityName": "SAND PLAST (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515081",
                "SecurityId": "SRIVAJRA",
                "SecurityName": "SRI VAJRA GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE047H01018",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515085",
                "SecurityId": "RESTILE",
                "SecurityName": "RESTILE CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE298E01022",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515087",
                "SecurityId": "DCCNGRN",
                "SecurityName": "DECCAN GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515093",
                "SecurityId": "MADHAV",
                "SecurityName": "MADHAV MARBLES & GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE925C01016",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515097",
                "SecurityId": "GGGRAN",
                "SecurityName": "GEE GEE GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE164F01015",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515099",
                "SecurityId": "VERTICLIND",
                "SecurityName": "VERTICAL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE247Q01015",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515101",
                "SecurityId": "YNPYMN",
                "SecurityName": "YENEPOYA MINERALS & GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515105",
                "SecurityId": "CHARMGR",
                "SecurityName": "CHARMINAR GRANITES EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515107",
                "SecurityId": "ASSMRMO",
                "SecurityName": "ASSOCIATED MARMO & GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515113",
                "SecurityId": "CRBCORP",
                "SecurityName": "CRB CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515115",
                "SecurityId": "STIGRAN",
                "SecurityName": "STI GRANITE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE057G01019",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515119",
                "SecurityId": "UPMINRL",
                "SecurityName": "U.P.MINERAL PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515123",
                "SecurityId": "COMTGLS",
                "SecurityName": "COMET GLASS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515127",
                "SecurityId": "RAMASIGNS",
                "SecurityName": "RAMASIGNS INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE650D01026",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515135",
                "SecurityId": "DUCKFIN",
                "SecurityName": "DUCKFIN INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515137",
                "SecurityId": "CANAGLS",
                "SecurityName": "CANA GLASS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE155F01013",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515139",
                "SecurityId": "PHILIPSGLDM",
                "SecurityName": "PHILIPS GLASS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515143",
                "SecurityId": "HARYANSHET",
                "SecurityName": "HARYANA SHEET GLASS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE038D01016",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515145",
                "SecurityId": "HINDNATGLS",
                "SecurityName": "HINDUSTHAN NATIONAL GLASS & INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE952A01022",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515147",
                "SecurityId": "HALDYNGL",
                "SecurityName": "HALDYN GLASS LTD-$",
                "FaceValue": "1",
                "ISINNo": "INE506D01020",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515149",
                "SecurityId": "HINDCER",
                "SecurityName": "HINDUSTAN CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "515435",
                "SecurityId": "HIMATAUT",
                "SecurityName": "HIMATSINGKA AUTO ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE642E01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516001",
                "SecurityId": "TUNGPUL",
                "SecurityName": "TUNGABHADRA PULP & BOARD MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516003",
                "SecurityId": "DUROPLY",
                "SecurityName": "DUROPLY INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE932D01010",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516007",
                "SecurityId": "MANGTIMBER",
                "SecurityName": "MANGALAM TIMBER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE805B01012",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516013",
                "SecurityId": "BESTBRD",
                "SecurityName": "BEST BOARD LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516016",
                "SecurityId": "SHREYANIND",
                "SecurityName": "SHREYANS INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE231C01019",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516020",
                "SecurityId": "AGIOPAPER",
                "SecurityName": "AGIO PAPER & INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE112C01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516022",
                "SecurityId": "STARPAPER",
                "SecurityName": "STAR PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE733A01018",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516028",
                "SecurityId": "SHREAMBP",
                "SecurityName": "SHREE AMBESHWAR PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE616D01019",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516030",
                "SecurityId": "YASHPAKKA",
                "SecurityName": "YASH PAKKA LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE551D01018",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516032",
                "SecurityId": "SARDAPPR",
                "SecurityName": "SARDA PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE385D01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516034",
                "SecurityId": "SUSHLPU",
                "SecurityName": "SUSHILA PULP & PAPER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516036",
                "SecurityId": "RNPAPER",
                "SecurityName": "R.N.PAPER & BOARDS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516038",
                "SecurityId": "SOMAPPR",
                "SecurityName": "SOMA PAPERS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE737E01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516040",
                "SecurityId": "SHREEIN",
                "SecurityName": "SHREE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516042",
                "SecurityId": "DADRAWP",
                "SecurityName": "DADRAWALA PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516044",
                "SecurityId": "NAYAPAP",
                "SecurityName": "NAYAGARA PAPER PRODUCTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516046",
                "SecurityId": "SHEFAPP",
                "SecurityName": "SHEFALI PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516048",
                "SecurityId": "NISHPAP",
                "SecurityName": "NISHANT PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516050",
                "SecurityId": "BUTAPRNDM",
                "SecurityName": "BHUTTA PRINTING & ALLIED INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516052",
                "SecurityId": "RUPALAM",
                "SecurityName": "RUPAL LAMINATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516054",
                "SecurityId": "FLORAWA",
                "SecurityName": "FLORA WALL COVERINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516060",
                "SecurityId": "JACKPRD",
                "SecurityName": "JACKARD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516062",
                "SecurityId": "NATPLY",
                "SecurityName": "NATIONAL PLYWOOD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE497C01016",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516064",
                "SecurityId": "ARROWGREEN",
                "SecurityName": "ARROW GREENTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE570D01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516068",
                "SecurityId": "MUKPA",
                "SecurityName": "MUKERIAN PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE348C01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516070",
                "SecurityId": "CRESCOL",
                "SecurityName": "CRESCENT COLORCOAT SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516072",
                "SecurityId": "VISHNU",
                "SecurityName": "VISHNU CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE270I01014",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516074",
                "SecurityId": "RANAMOH",
                "SecurityName": "RANA MOHENDRA PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516076",
                "SecurityId": "ADORTECH",
                "SecurityName": "ADOR TECHNOPAK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE708D01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516078",
                "SecurityId": "JUMBO",
                "SecurityName": "JUMBO BAG LTD.",
                "FaceValue": "10",
                "ISINNo": "INE699D01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516080",
                "SecurityId": "SUPEPOL",
                "SecurityName": "SUPER POLYFABRIKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516082",
                "SecurityId": "NRAGRINDQ",
                "SecurityName": "N.R.AGARWAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE740D01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516084",
                "SecurityId": "VIDHIIN",
                "SecurityName": "VIDHI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516086",
                "SecurityId": "SRPML",
                "SecurityName": "SHREE RAJESHWARANAND PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE617D01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516088",
                "SecurityId": "SAURAPB-B",
                "SecurityName": "SAURASHTRA PAPER & BOARD MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516090",
                "SecurityId": "WATSSOFT",
                "SecurityName": "WATSON SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE950B01016",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516092",
                "SecurityId": "3PLAND",
                "SecurityName": "3P LAND HOLDINGS LTD",
                "FaceValue": "2",
                "ISINNo": "INE105C01023",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516096",
                "SecurityId": "SANPA",
                "SecurityName": "SANGAL PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE384D01022",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516098",
                "SecurityId": "VENTURA",
                "SecurityName": "VENTURA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE810C01044",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516100",
                "SecurityId": "CONWAYP-BDM",
                "SecurityName": "CONWAY PRINTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516104",
                "SecurityId": "CRESPAP",
                "SecurityName": "CREST PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516106",
                "SecurityId": "SHKARTP",
                "SecurityName": "SHREE KARTHIK PAPERS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE538D01015",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516108",
                "SecurityId": "STHINPA",
                "SecurityName": "SOUTH INDIA PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE088G01014",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516110",
                "SecurityId": "SCANDENT",
                "SecurityName": "SCANDENT IMAGING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE146N01016",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "516112",
                "SecurityId": "BKDUPLEX",
                "SecurityName": "B.K.DUPLEX BOARD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE212C01019",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517001",
                "SecurityId": "BIRLAPOWER",
                "SecurityName": "BIRLA POWER SOLUTIONS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE224B01024",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517007",
                "SecurityId": "PIONAUT",
                "SecurityName": "PIONEER AUTO LAMPS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517015",
                "SecurityId": "VINDHYATEL",
                "SecurityName": "VINDHYA TELELINKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE707A01012",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517026",
                "SecurityId": "POWERFL",
                "SecurityName": "POWERFLOW LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517035",
                "SecurityId": "RIR",
                "SecurityName": "RUTTONSHA INTERNATIONAL RECTIFIER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE302D01016",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517041",
                "SecurityId": "ADORWELD",
                "SecurityName": "ADOR WELDING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE045A01017",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517044",
                "SecurityId": "IDM",
                "SecurityName": "INTERNATIONAL DATA MANAGEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE649R01010",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517047",
                "SecurityId": "FORWSEC",
                "SecurityName": "FORWARD SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517049",
                "SecurityId": "PUNSUMI",
                "SecurityName": "PUNSUMI INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE045C01013",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517056",
                "SecurityId": "RPGCAB",
                "SecurityName": "RPG CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE145A01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517059",
                "SecurityId": "SALZER",
                "SecurityName": "SALZER ELECTRONICS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE457F01013",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517063",
                "SecurityId": "JETKINGQ",
                "SecurityName": "JETKING INFOTRAIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE919C01019",
                "Industry": "IT Training Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517077",
                "SecurityId": "INDAGIV",
                "SecurityName": "IND-AGIV COMMERCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE115E01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517080",
                "SecurityId": "HIGHGROUND",
                "SecurityName": "HIGH GROUND ENTERPRISE LTD",
                "FaceValue": "1",
                "ISINNo": "INE361M01021",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517092",
                "SecurityId": "WSTNCOM",
                "SecurityName": "WESTON COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517096",
                "SecurityId": "APLAB",
                "SecurityName": "APLAB LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE273A01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517119",
                "SecurityId": "PCS",
                "SecurityName": "PCS TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE834B01012",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517133",
                "SecurityId": "VHELIND-B",
                "SecurityName": "VHEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517140",
                "SecurityId": "MOSERBAER",
                "SecurityName": "MOSER BAER INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE739A01015",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517146",
                "SecurityId": "USHAMART",
                "SecurityName": "USHA MARTIN LTD.",
                "FaceValue": "1",
                "ISINNo": "INE228A01035",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517157",
                "SecurityId": "SPARKPL",
                "SecurityName": "SPARK PLUGS COMPANY INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517159",
                "SecurityId": "TELECABL",
                "SecurityName": "TELEPHONE CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE745C01018",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517164",
                "SecurityId": "ZENITHCOMP",
                "SecurityName": "ZENITH COMPUTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE598B01013",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517166",
                "SecurityId": "SPELS",
                "SecurityName": "SPEL SEMICONDUCTOR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE252A01019",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517168",
                "SecurityId": "SUBROS",
                "SecurityName": "SUBROS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE287B01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517170",
                "SecurityId": "KLKELEC",
                "SecurityName": "KLK ELECTRICAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE125G01014",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517172",
                "SecurityId": "PERVASIVE",
                "SecurityName": "PERVASIVE COMMODITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE443P01020",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517174",
                "SecurityId": "HONAUT",
                "SecurityName": "HONEYWELL AUTOMATION INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE671A01010",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517177",
                "SecurityId": "TEKTRON",
                "SecurityName": "TEKTRON",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517189",
                "SecurityId": "SLDAIRE",
                "SecurityName": "SOLIDAIRE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517195",
                "SecurityId": "ORGINFO",
                "SecurityName": "ORG INFORMATICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE686D01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517199",
                "SecurityId": "NATNSWI",
                "SecurityName": "NATIONAL SWITCHGEARS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517201",
                "SecurityId": "SWITCHTE",
                "SecurityName": "SWITCHING TECHNOLOGIES GUNTHER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE311D01017",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517203",
                "SecurityId": "BEELE",
                "SecurityName": "BEE ELECTRONIC MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE038E01014",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517204",
                "SecurityId": "ELNETLDM",
                "SecurityName": "ELNET LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517206",
                "SecurityId": "LUMAXIND",
                "SecurityName": "LUMAX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE162B01018",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517207",
                "SecurityId": "TOSHA",
                "SecurityName": "TOSHA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517208",
                "SecurityId": "HOTLINET",
                "SecurityName": "HOTLINE TELETUBE & COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE677B01015",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517210",
                "SecurityId": "ONIDASAV",
                "SecurityName": "ONIDA SAVAK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE303C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517212",
                "SecurityId": "AKGACCU",
                "SecurityName": "AKG ACOUSTICS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517214",
                "SecurityId": "DIGISPICE",
                "SecurityName": "DIGISPICE TECHNOLOGIES LTD-$",
                "FaceValue": "3",
                "ISINNo": "INE927C01020",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517216",
                "SecurityId": "YOKOGAWA",
                "SecurityName": "YOKOGAWA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE718A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517220",
                "SecurityId": "INCAB",
                "SecurityName": "INCAB INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517222",
                "SecurityId": "BPLREF",
                "SecurityName": "B.S.REFRIGERATORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE313A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517224",
                "SecurityId": "SUJANAUNI",
                "SecurityName": "SUJANA UNIVERSAL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE216G01011",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517228",
                "SecurityId": "TRENDELEC",
                "SecurityName": "TREND ELECTRONICS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE219F01017",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517230",
                "SecurityId": "PAEL",
                "SecurityName": "PAE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE766A01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517236",
                "SecurityId": "CALCOM",
                "SecurityName": "CALCOM VISION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE216C01010",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517238",
                "SecurityId": "DYNAVSN",
                "SecurityName": "DYNAVISION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE083E01010",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517244",
                "SecurityId": "HINDPOW",
                "SecurityName": "HINDUSTAN POWERPLUS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE554A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517246",
                "SecurityId": "BCCFUBA",
                "SecurityName": "BCC FUBA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE788D01016",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517248",
                "SecurityId": "BST",
                "SecurityName": "BST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE389A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517250",
                "SecurityId": "INTEGRA",
                "SecurityName": "INTEGRA INDIA GROUP COMPANY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE288D01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517254",
                "SecurityId": "PROFCIR",
                "SecurityName": "PROFESSIONAL CIRCUIT BOARDS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517258",
                "SecurityId": "PRECISIO",
                "SecurityName": "PRECISION ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE143C01024",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517264",
                "SecurityId": "FINELINE",
                "SecurityName": "FINE-LINE CIRCUITS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE087E01011",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517266",
                "SecurityId": "PRTHELE",
                "SecurityName": "PROTECH ELECTROMECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517269",
                "SecurityId": "NAMTECHELE",
                "SecurityName": "NAMTECH ELECTRONIC DEVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE607C01010",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517271",
                "SecurityId": "HBLPOWER",
                "SecurityName": "HBL POWER SYSTEMS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE292B01021",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517273",
                "SecurityId": "S&SPOWER",
                "SecurityName": "S&S POWER SWITCHGEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE902B01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517275",
                "SecurityId": "SRYMRPH",
                "SecurityName": "SURYA MURPHY RICHARDS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517277",
                "SecurityId": "INTRODM",
                "SecurityName": "INTRON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE151C01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517280",
                "SecurityId": "VIJAYIND",
                "SecurityName": "VIJAY INDUSTRIES & PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE226D01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517282",
                "SecurityId": "TRNSPEN",
                "SecurityName": "TRANSPOWER ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517284",
                "SecurityId": "VPPOLYC",
                "SecurityName": "V.P.TELECOM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517286",
                "SecurityId": "AUTOPAL",
                "SecurityName": "AUTOPAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE335Q01018",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517288",
                "SecurityId": "GUJARATPOLY",
                "SecurityName": "GUJARAT POLY ELECTRONICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE541F01022",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517296",
                "SecurityId": "PHOENIXLL",
                "SecurityName": "PHOENIX LAMPS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE455B01016",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517300",
                "SecurityId": "GIPCL",
                "SecurityName": "GUJARAT INDUSTRIES POWER CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE162A01010",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517304",
                "SecurityId": "MDHITCH",
                "SecurityName": "MADRAS HI-TECH CIRCUITS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517312",
                "SecurityId": "ELCAPCP",
                "SecurityName": "ELCAPS CAPACITORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517314",
                "SecurityId": "SALZCNTDM",
                "SecurityName": "SALZER CONTROLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517318",
                "SecurityId": "INDOASFU",
                "SecurityName": "INDO ASIAN FUSEGEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE814A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517320",
                "SecurityId": "SAVINFOCO",
                "SecurityName": "SAVANT INFOCOMM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE898E01011",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517326",
                "SecurityId": "CMC",
                "SecurityName": "CMC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE314A01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517328",
                "SecurityId": "SPRMCON",
                "SecurityName": "SUPREME CONDUCTORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517330",
                "SecurityId": "CMI",
                "SecurityName": "CMI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE981B01011",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517332",
                "SecurityId": "MUKATITDM",
                "SecurityName": "MUKATI TRANSFORMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517334",
                "SecurityId": "MOTHERSUMI",
                "SecurityName": "MOTHERSON SUMI SYSTEMS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE775A01035",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517336",
                "SecurityId": "MODMA",
                "SecurityName": "MODERN MALLEABLES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE834C01028",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517340",
                "SecurityId": "MINTAGE",
                "SecurityName": "MINTAGE ELECTRO EQUIPMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE726C01018",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517344",
                "SecurityId": "MINDTECK",
                "SecurityName": "MINDTECK (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE110B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517346",
                "SecurityId": "PURVIEL",
                "SecurityName": "PURVI ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517348",
                "SecurityId": "DIGIWRDDM",
                "SecurityName": "DIGITAL WORLD INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517352",
                "SecurityId": "EIDRELE",
                "SecurityName": "EIDER ELECTRONICS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE053C01017",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517354",
                "SecurityId": "HAVELLS",
                "SecurityName": "HAVELLS INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE176B01034",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517356",
                "SecurityId": "ACIIN",
                "SecurityName": "ACI INFOCOM LTD.",
                "FaceValue": "1",
                "ISINNo": "INE167B01025",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517358",
                "SecurityId": "ELITAPL",
                "SecurityName": "ELITE APPLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517360",
                "SecurityId": "SBECSYS",
                "SecurityName": "SBEC SYSTEMS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE689V01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517362",
                "SecurityId": "ALACRIEL",
                "SecurityName": "ALACRITY ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE023C01010",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517366",
                "SecurityId": "SRSTENT-B",
                "SecurityName": "SRSTENT-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517370",
                "SecurityId": "INCAP",
                "SecurityName": "INCAP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE437C01012",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517372",
                "SecurityId": "GUJINTRX",
                "SecurityName": "GUJARAT INTRUX LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE877E01015",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517374",
                "SecurityId": "MUKSTRI",
                "SecurityName": "MUKESH STRIPS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE946G01013",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517376",
                "SecurityId": "OPAL",
                "SecurityName": "OPAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE413E01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517378",
                "SecurityId": "ELEXT",
                "SecurityName": "ELECTREX (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE167A01019",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517380",
                "SecurityId": "IGARASHI",
                "SecurityName": "IGARASHI MOTORS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE188B01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517383",
                "SecurityId": "KRISOEL",
                "SecurityName": "KRISONS ELECTRONIC SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517385",
                "SecurityId": "SYMPHONY",
                "SecurityName": "SYMPHONY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE225D01027",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517387",
                "SecurityId": "USHAUDG",
                "SecurityName": "USHAUDG",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517391",
                "SecurityId": "GAMIE",
                "SecurityName": "GAMMA INFOWAY EXALT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE970A01016",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517393",
                "SecurityId": "VINTRON",
                "SecurityName": "VINTRON INFORMATICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE043B01028",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517395",
                "SecurityId": "UCALPWR",
                "SecurityName": "UCAL POWER SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517397",
                "SecurityId": "PANELEC",
                "SecurityName": "PAN ELECTRONICS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE648E01010",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517399",
                "SecurityId": "VXLINSTR",
                "SecurityName": "VXL INSTRUMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE756A01019",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517403",
                "SecurityId": "SUNSOUI",
                "SecurityName": "SUN SOURCE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320F01013",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517405",
                "SecurityId": "ADITYEL",
                "SecurityName": "ADITYA ELECTRO COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517407",
                "SecurityId": "TOYAMAQ",
                "SecurityName": "TOYAMA ELECTRIC LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE081D01016",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517411",
                "SecurityId": "SHYAMTEL",
                "SecurityName": "SHYAM TELECOM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE635A01023",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517415",
                "SecurityId": "LEENEE",
                "SecurityName": "LEE & NEE SOFTWARES (EXPORTS) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE791B01014",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517417",
                "SecurityId": "PATELSAI",
                "SecurityName": "PATELS AIRTEMP (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE082C01024",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517419",
                "SecurityId": "HARTNCO",
                "SecurityName": "HARTRON COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517421",
                "SecurityId": "BUTTERFLY",
                "SecurityName": "BUTTERFLY GANDHIMATHI APPLIANCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE295F01017",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517427",
                "SecurityId": "HARTNET",
                "SecurityName": "HARTRON NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517429",
                "SecurityId": "ATHENAGLO",
                "SecurityName": "ATHENA GLOBAL TECHNOLOGIES LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE576B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517431",
                "SecurityId": "NARMP",
                "SecurityName": "NARMADA MACPLAST DRIP IRRIGATION SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE060D01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517433",
                "SecurityId": "INSOE",
                "SecurityName": "INNOVATION SOFTWARE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE568B01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517435",
                "SecurityId": "EQUPCON",
                "SecurityName": "EQUIPMENT CONDUCTORS & CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517437",
                "SecurityId": "DUTRON",
                "SecurityName": "DUTRON POLYMERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE940C01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517443",
                "SecurityId": "TRNINGL",
                "SecurityName": "TRANS INDIA GLASS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517447",
                "SecurityId": "RSSOFTWARE",
                "SecurityName": "R.S.SOFTWARE INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE165B01029",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517449",
                "SecurityId": "MAGNAELQ",
                "SecurityName": "MAGNA ELECTRO CASTINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE437D01010",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517453",
                "SecurityId": "ARUNPIPDM",
                "SecurityName": "ARUN PIPES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517455",
                "SecurityId": "ANCO",
                "SecurityName": "ANCO COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE541B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517463",
                "SecurityId": "LINAKS",
                "SecurityName": "LINAKS MICROELECTRONICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE028C01027",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517465",
                "SecurityId": "ODVDC",
                "SecurityName": "ODYSSEY VIDEO COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE342B01016",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517467",
                "SecurityId": "MARSONS",
                "SecurityName": "MARSONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE415B01044",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517469",
                "SecurityId": "WORLDGT",
                "SecurityName": "WORLD DIGITAL SOUND LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517471",
                "SecurityId": "KOATOOLIN",
                "SecurityName": "KOA TOOLS INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE316B01036",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517473",
                "SecurityId": "DATAPRO",
                "SecurityName": "DATAPRO INFORMATION TECHNOLOGY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE873A01020",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517477",
                "SecurityId": "ELNET",
                "SecurityName": "ELNET TECHNOLOGIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE033C01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517481",
                "SecurityId": "APTL",
                "SecurityName": "ARTECH POWER & TRADING LTD",
                "FaceValue": "10",
                "ISINNo": "INE421N01021",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517483",
                "SecurityId": "MICROEN",
                "SecurityName": "MICROENERGY (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517485",
                "SecurityId": "INDOPWR",
                "SecurityName": "INDO POWER CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517488",
                "SecurityId": "SATKAR",
                "SecurityName": "SATKAR ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE824D01019",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517490",
                "SecurityId": "SUPSTAW",
                "SecurityName": "SUPER STAR WELDING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517492",
                "SecurityId": "STIPROD",
                "SecurityName": "STI PRODUCTS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE205G01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517494",
                "SecurityId": "ACCEL",
                "SecurityName": "ACCEL LTD",
                "FaceValue": "2",
                "ISINNo": "INE258C01038",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517496",
                "SecurityId": "RICOHQ",
                "SecurityName": "RICOH INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE291B01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517498",
                "SecurityId": "WEBELSOLAR",
                "SecurityName": "WEBSOL ENERGY SYSTEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE855C01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517500",
                "SecurityId": "ROTO",
                "SecurityName": "ROTO PUMPS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE535D01029",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517502",
                "SecurityId": "ICESOFT",
                "SecurityName": "ICES SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE171B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517504",
                "SecurityId": "GEMCABLE",
                "SecurityName": "GEM CABLES & CONDUCTORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE582B01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517506",
                "SecurityId": "TTKPRESTIG",
                "SecurityName": "TTK PRESTIGE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE690A01010",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517508",
                "SecurityId": "DYNIDUS-B",
                "SecurityName": "DYNAVOX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517510",
                "SecurityId": "DIGHELE",
                "SecurityName": "DIGHE ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517512",
                "SecurityId": "MOTIELE",
                "SecurityName": "MOTI ELECTRIC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517514",
                "SecurityId": "DHINDIA",
                "SecurityName": "D&H INDIA LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE589D01018",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517518",
                "SecurityId": "LEEL",
                "SecurityName": "LEEL ELECTRICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE245C01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517522",
                "SecurityId": "RAJGLOWIR",
                "SecurityName": "RAJRATAN GLOBAL WIRE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE451D01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517526",
                "SecurityId": "INDITALIA",
                "SecurityName": "INDITALIA REFCON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE149C01013",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517528",
                "SecurityId": "SJBTUBE",
                "SecurityName": "SJB TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517530",
                "SecurityId": "SURANAT&P",
                "SecurityName": "SURANA TELECOM AND POWER LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE130B01031",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517532",
                "SecurityId": "RAJVELE",
                "SecurityName": "RAJVEBH ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517534",
                "SecurityId": "NIVINFRA",
                "SecurityName": "NIVYAH INFRASTRUCTURE & TELECOM SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE303D01014",
                "Industry": "Internet & Catalogue Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517536",
                "SecurityId": "ONWARDTEC",
                "SecurityName": "ONWARD TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE229A01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517538",
                "SecurityId": "DIGIMULT",
                "SecurityName": "DIGITAL MULTIFORMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE927B01014",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517540",
                "SecurityId": "NEXUSSOF",
                "SecurityName": "NEXUS SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE901B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517544",
                "SecurityId": "CENTUM",
                "SecurityName": "CENTUM ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320B01020",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517546",
                "SecurityId": "ALFATRAN",
                "SecurityName": "ALFA TRANSFORMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE209C01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517548",
                "SecurityId": "STARLITE",
                "SecurityName": "STARLITE COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE035C01022",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517552",
                "SecurityId": "ADVNCMIC",
                "SecurityName": "ADVANCED MICRONIC DEVICES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE903C01013",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517554",
                "SecurityId": "NHCFOODS",
                "SecurityName": "NHC FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE141C01028",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517556",
                "SecurityId": "PVP",
                "SecurityName": "PVP VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE362A01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517560",
                "SecurityId": "TINAELE",
                "SecurityName": "TINA ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517562",
                "SecurityId": "TRIGYN",
                "SecurityName": "TRIGYN TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE948A01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517564",
                "SecurityId": "GRCABLE",
                "SecurityName": "GR CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE769B01010",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517565",
                "SecurityId": "ASHCONIUL",
                "SecurityName": "ASHCO NIULAB INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE714F01033",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517567",
                "SecurityId": "RAMSTEL",
                "SecurityName": "RAMS TRANSFORMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517569",
                "SecurityId": "KEI",
                "SecurityName": "KEI INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE878B01027",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517571",
                "SecurityId": "INDLMETER",
                "SecurityName": "IMP POWERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE065B01013",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "517973",
                "SecurityId": "DMCEDU",
                "SecurityName": "DMC EDUCATION LTD",
                "FaceValue": "5",
                "ISINNo": "INE585D01024",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518011",
                "SecurityId": "KEERTHI",
                "SecurityName": "KEERTHI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE145L01012",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518017",
                "SecurityId": "BHEEMACEM",
                "SecurityName": "BHEEMA CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE333H01012",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518018",
                "SecurityId": "SRVIC",
                "SecurityName": "SRI VISHNU CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE286B01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518026",
                "SecurityId": "SUDRCEM",
                "SecurityName": "SUDARSHAN CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518029",
                "SecurityId": "GSCLCEMENT",
                "SecurityName": "GUJARAT SIDHEE CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE542A01039",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518034",
                "SecurityId": "BALACEM",
                "SecurityName": "BALARAM CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518042",
                "SecurityId": "PRCEM",
                "SecurityName": "P.R.CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE447D01019",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518049",
                "SecurityId": "JPTRCEM",
                "SecurityName": "JUPITER CEMENT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518051",
                "SecurityId": "VINAYCEM",
                "SecurityName": "VINAY CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE534C01016",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518053",
                "SecurityId": "SRICC",
                "SecurityName": "SRI CHAKRA CEMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE827D01020",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518059",
                "SecurityId": "PRCEMENDM",
                "SecurityName": "PRUDENTIAL CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518061",
                "SecurityId": "PANASIN",
                "SecurityName": "PAN ASIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518067",
                "SecurityId": "BANJCEM",
                "SecurityName": "BANJARA CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518071",
                "SecurityId": "SOMANCM",
                "SecurityName": "SOMANI CEMENT COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE536F01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518073",
                "SecurityId": "LKSMCEM",
                "SecurityName": "LAKSHMI CEMENT & CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518075",
                "SecurityId": "SURAJ",
                "SecurityName": "SURAJ PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE069E01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518077",
                "SecurityId": "RAGHCEM",
                "SecurityName": "RAGHOJI CEMENT MANUFACTURING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518079",
                "SecurityId": "MAHNDCM",
                "SecurityName": "MAHENDRA CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518081",
                "SecurityId": "MODECEM",
                "SecurityName": "MODERN CEMENT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518083",
                "SecurityId": "SSIMHCM",
                "SecurityName": "SRI SIMHADRI CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518085",
                "SecurityId": "SINGLCM",
                "SecurityName": "SINGHAL CEMENT & ALLIED INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518087",
                "SecurityId": "SHUBI",
                "SecurityName": "SHUBHAM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE097D01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518091",
                "SecurityId": "APCL",
                "SecurityName": "ANJANI PORTLAND CEMENT LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE071F01012",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518093",
                "SecurityId": "GANGCEM",
                "SecurityName": "GANGOTRI CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE831P01018",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518097",
                "SecurityId": "VSHWCEM",
                "SecurityName": "VISHWAKARMA CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518099",
                "SecurityId": "INDACEM",
                "SecurityName": "INDO AMERICAN CEMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518107",
                "SecurityId": "RANISCM",
                "SecurityName": "RANISAGAR CEMENT CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518109",
                "SecurityId": "VARUCEM-B",
                "SecurityName": "VARUN CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "518113",
                "SecurityId": "SIGMACE",
                "SecurityName": "SIGMA CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519003",
                "SecurityId": "MODINATUR",
                "SecurityName": "MODI NATURALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE537F01012",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519012",
                "SecurityId": "ALPININ-B",
                "SecurityName": "ALPINE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519014",
                "SecurityId": "PRSNTIN",
                "SecurityName": "PRASHANT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE100E01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519022",
                "SecurityId": "ASHOKCT",
                "SecurityName": "ASHOKA COTSEEDS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519023",
                "SecurityId": "KCHROIL",
                "SecurityName": "KOCHER OIL MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519027",
                "SecurityId": "PRESTIG",
                "SecurityName": "PRESTIGE FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519031",
                "SecurityId": "SHAHFOOD",
                "SecurityName": "SHAH FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE455D01012",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519035",
                "SecurityId": "WADALACOM",
                "SecurityName": "WADALA COMMODITIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE868A01020",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519039",
                "SecurityId": "VIPPY",
                "SecurityName": "VIPPY INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE187E01027",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519045",
                "SecurityId": "KEDIAVA",
                "SecurityName": "KEDIA VANASPATI LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519064",
                "SecurityId": "ZKHANDEN",
                "SecurityName": "KHANDELWAL EXTRACTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE687W01010",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519067",
                "SecurityId": "ATLSOYA",
                "SecurityName": "ATLAS SOYA PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519075",
                "SecurityId": "SALSTAR",
                "SecurityName": "SALSTAR FOODS & BEVERAGES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519081",
                "SecurityId": "NPCHWIN",
                "SecurityName": "N.P.CHEWING GUMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519086",
                "SecurityId": "CHHATIN",
                "SecurityName": "CHHATAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519091",
                "SecurityId": "TASTYBIT",
                "SecurityName": "TASTY BITE EATABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE488B01017",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519097",
                "SecurityId": "RITESHIN",
                "SecurityName": "RITESH INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE534D01014",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519105",
                "SecurityId": "AVTNPL",
                "SecurityName": "AVT NATURAL PRODUCTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE488D01021",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519126",
                "SecurityId": "HNDFDS",
                "SecurityName": "HINDUSTAN FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE254N01018",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519132",
                "SecurityId": "AMRTPRTDM",
                "SecurityName": "AMRIT PROTEIN FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519134",
                "SecurityId": "DHARI",
                "SecurityName": "DHARNENDRA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE624D01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519136",
                "SecurityId": "NAHARINDUS",
                "SecurityName": "NAHAR INDUSTRIAL ENTERPRISES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE289A01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519138",
                "SecurityId": "MRVJIOL",
                "SecurityName": "M.RAVJI OIL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519140",
                "SecurityId": "VGPRFOO",
                "SecurityName": "VEGEPRO FOODS & FEEDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE155O01015",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519142",
                "SecurityId": "AMBJFLR",
                "SecurityName": "AMBUJA FLOUR MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519144",
                "SecurityId": "VRUNAGR",
                "SecurityName": "VARUNA AGROPROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519152",
                "SecurityId": "VADILENT",
                "SecurityName": "VADILAL ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE693D01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519156",
                "SecurityId": "VADILALIND",
                "SecurityName": "VADILAL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE694D01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519160",
                "SecurityId": "KANVA",
                "SecurityName": "KANHA VANASPATI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE281C01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519164",
                "SecurityId": "AMBAGIN",
                "SecurityName": "AMBUJA AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519168",
                "SecurityId": "NIJJER",
                "SecurityName": "NIJJER AGRO FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE847B01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519172",
                "SecurityId": "BRARVNS",
                "SecurityName": "BARAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519174",
                "SecurityId": "ASHAI",
                "SecurityName": "ASHIANA AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE709D01012",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519176",
                "SecurityId": "KOTHSOY",
                "SecurityName": "KOTHARI GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519178",
                "SecurityId": "PRMRPRT",
                "SecurityName": "PREMIER PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519183",
                "SecurityId": "ADFFOODS",
                "SecurityName": "ADF FOODS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE982B01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519187",
                "SecurityId": "FORFOOD",
                "SecurityName": "FORTUNE FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE712V01018",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519189",
                "SecurityId": "MNSGOIL",
                "SecurityName": "MANSINGHKA OIL PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519191",
                "SecurityId": "RGRL",
                "SecurityName": "RETRO GREEN REVOLUTION LTD",
                "FaceValue": "10",
                "ISINNo": "INE601N01010",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519192",
                "SecurityId": "SATGAGR",
                "SecurityName": "SATGURU AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519194",
                "SecurityId": "PANKAGR",
                "SecurityName": "PANKAJ AGRO PROTINEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519196",
                "SecurityId": "RICOAGR",
                "SecurityName": "RICOAGR",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519200",
                "SecurityId": "NVCMIND",
                "SecurityName": "NAVCOM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519210",
                "SecurityId": "INOVAFOOD",
                "SecurityName": "INNOVATIVE FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE827C01022",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519214",
                "SecurityId": "WILLIMFI",
                "SecurityName": "WILLIAMSON FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE188E01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519216",
                "SecurityId": "AJANTSOY",
                "SecurityName": "AJANTA SOYA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE601B01015",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519218",
                "SecurityId": "RANKAQU",
                "SecurityName": "RANK AQUA ESTATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519222",
                "SecurityId": "INDBF",
                "SecurityName": "INDO BIOTECH FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE183C01012",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519224",
                "SecurityId": "WILLAMAGOR",
                "SecurityName": "WILLIAMSON MAGOR & COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE210A01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519226",
                "SecurityId": "ZBISHDM",
                "SecurityName": "BISHNAUTH TEA CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE211A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519228",
                "SecurityId": "TEMPTFD",
                "SecurityName": "TEMPTATION FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE244I01019",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519230",
                "SecurityId": "RICHIRICH",
                "SecurityName": "RICHIRICH INVENTURES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE102C01020",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519232",
                "SecurityId": "LIBOMDM",
                "SecurityName": "LIBERTY OIL MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE158E01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519234",
                "SecurityId": "SIEL",
                "SecurityName": "SUPERIOR INDUSTRIAL ENTERPRISES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE843L01012",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519236",
                "SecurityId": "SUPLFOD",
                "SecurityName": "SUPPLEMENTARY FOODS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519238",
                "SecurityId": "SPTRSHI",
                "SecurityName": "SAPTARISHI AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE233P01017",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519242",
                "SecurityId": "SRDAPRT",
                "SecurityName": "SARDA PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE995U01011",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519244",
                "SecurityId": "HMGRFOD",
                "SecurityName": "HIMGIRI FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519248",
                "SecurityId": "JVLAGRO",
                "SecurityName": "JVL AGRO INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE430G01026",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519252",
                "SecurityId": "VSHLLTK",
                "SecurityName": "VISHAL LAKTO (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519254",
                "SecurityId": "SWADIND",
                "SecurityName": "SWAD INDUSTRIES & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519260",
                "SecurityId": "SANWARIA",
                "SecurityName": "SANWARIA CONSUMER LTD-$",
                "FaceValue": "1",
                "ISINNo": "INE890C01046",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519262",
                "SecurityId": "PRIMAGR",
                "SecurityName": "PRIMA AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE297D01018",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519264",
                "SecurityId": "NKUMBDP",
                "SecurityName": "NIKUMBH DAIRY PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519266",
                "SecurityId": "BENGTEA",
                "SecurityName": "BENGTEA",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519271",
                "SecurityId": "JSMNFOD",
                "SecurityName": "JASMINA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519273",
                "SecurityId": "UNOINDL",
                "SecurityName": "UNNO INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE142N01023",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519275",
                "SecurityId": "AMISNFD",
                "SecurityName": "AMISON FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519277",
                "SecurityId": "RSHBAGR",
                "SecurityName": "RISHABH AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519279",
                "SecurityId": "MADHURIND",
                "SecurityName": "MADHUR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE110C01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519281",
                "SecurityId": "AGRODUTCH",
                "SecurityName": "AGRO DUTCH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE135B01014",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519283",
                "SecurityId": "KINGIAQ",
                "SecurityName": "KINGS INTERNATIONAL AQUA -MARINE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519285",
                "SecurityId": "TARAI",
                "SecurityName": "TARAI FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE906C01016",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519287",
                "SecurityId": "MODAIRY",
                "SecurityName": "MODERN DAIRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE617B01011",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519289",
                "SecurityId": "PRUSRJG",
                "SecurityName": "PRUDENTIAL SRI JAGANNATH AGRO-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519293",
                "SecurityId": "CORBIOT",
                "SecurityName": "COROMONDAL BIOTECH INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519295",
                "SecurityId": "BAMBINO",
                "SecurityName": "BAMBINO AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE921D01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519299",
                "SecurityId": "PRIMIND",
                "SecurityName": "PRIME INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE543F01028",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519303",
                "SecurityId": "TROMBOEXT",
                "SecurityName": "TROMBO EXTRACTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE850O01011",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519305",
                "SecurityId": "SVRNAQU",
                "SecurityName": "SUVARNA AQUA FARM & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519307",
                "SecurityId": "VIKASWSP",
                "SecurityName": "VIKAS WSP LTD.",
                "FaceValue": "1",
                "ISINNo": "INE706A01022",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519309",
                "SecurityId": "PATELFD",
                "SecurityName": "PATEL FOOD PRODUCT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519313",
                "SecurityId": "SYPAGFD",
                "SecurityName": "SYP AGRO FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519315",
                "SecurityId": "SARVIND",
                "SecurityName": "SARVOTTAM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519317",
                "SecurityId": "BHAGVEG",
                "SecurityName": "BHAAGYALAKSHMI VEGETABLE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519319",
                "SecurityId": "JATALIA",
                "SecurityName": "JATALIA GLOBAL VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE847M01011",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519321",
                "SecurityId": "WESTFRT",
                "SecurityName": "WESTERN FRUITS & VEGETABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519323",
                "SecurityId": "MURLIIND",
                "SecurityName": "MURLI INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE806B01028",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519325",
                "SecurityId": "PANASAG",
                "SecurityName": "PAN ASIA GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519327",
                "SecurityId": "DHARNAG",
                "SecurityName": "DHARNENDRA AGRO FOOD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519329",
                "SecurityId": "INDDAIRDM",
                "SecurityName": "INDIANA DAIRY SPECIALITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519331",
                "SecurityId": "VSFPROJ",
                "SecurityName": "VSF PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE923K01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519333",
                "SecurityId": "RLAGRO",
                "SecurityName": "R.L.AGROTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519335",
                "SecurityId": "MLKPIL",
                "SecurityName": "MILK PARTNERS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE301N01017",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519337",
                "SecurityId": "NUTCHAG",
                "SecurityName": "NUTECH AGROS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519339",
                "SecurityId": "KLMARAG",
                "SecurityName": "KALYAN MARINE & AGRO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519341",
                "SecurityId": "MRTPLST",
                "SecurityName": "MARUTI PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519343",
                "SecurityId": "SWAIVAN",
                "SecurityName": "SWAIKA VANASPATI PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE677F01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519345",
                "SecurityId": "AMPRPRO",
                "SecurityName": "AMPRO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519347",
                "SecurityId": "GJTAQFD",
                "SecurityName": "GUJARAT AQUA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE705F01015",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519349",
                "SecurityId": "BHAKIND",
                "SecurityName": "BHAKRA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519351",
                "SecurityId": "GPINDUS",
                "SecurityName": "G.P.INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519353",
                "SecurityId": "BANSTEA",
                "SecurityName": "BANSISONS TEA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE856E01019",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519355",
                "SecurityId": "SHISRAG",
                "SecurityName": "SHRI ISHAR AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519357",
                "SecurityId": "TRFJTFD",
                "SecurityName": "TIRUMALA-FUJITECH AQUAFARMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519359",
                "SecurityId": "POONADAL",
                "SecurityName": "POONA DAL & OIL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE809E01018",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519365",
                "SecurityId": "IDANFDF",
                "SecurityName": "INDIAN FOOD FERMENTATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519367",
                "SecurityId": "TRANSFD",
                "SecurityName": "TRANSGLOBE FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE213P01027",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519369",
                "SecurityId": "SOMKAFD",
                "SecurityName": "SOMKAN MARINE FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519373",
                "SecurityId": "VIMALOIL",
                "SecurityName": "VIMAL OIL & FOODS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE067D01015",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519375",
                "SecurityId": "RMIFOOD",
                "SecurityName": "RMI FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519379",
                "SecurityId": "MEHARDT",
                "SecurityName": "MEHAR DAIRY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519381",
                "SecurityId": "WINFAGR",
                "SecurityName": "WINFARM AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519383",
                "SecurityId": "ANIKINDS",
                "SecurityName": "ANIK INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE087B01017",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519387",
                "SecurityId": "SAWNTFD",
                "SecurityName": "SAWANT FOOD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519389",
                "SecurityId": "IDUSFOD",
                "SecurityName": "INDUS FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519391",
                "SecurityId": "EASTOVR",
                "SecurityName": "EASTERN OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519393",
                "SecurityId": "DALMIAIN",
                "SecurityName": "DALMIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE839C01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519395",
                "SecurityId": "HARSM",
                "SecurityName": "HARYANA SURAJ MALTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE154E01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519397",
                "SecurityId": "SHINDL",
                "SecurityName": "SHARAT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE220Z01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519399",
                "SecurityId": "CENTPRTDM",
                "SecurityName": "CENTURY PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519401",
                "SecurityId": "INDOAQA",
                "SecurityName": "INDO AQUATICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519405",
                "SecurityId": "SANJAG",
                "SecurityName": "SANJIVANI AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519407",
                "SecurityId": "UNILIFD",
                "SecurityName": "UNILIV FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519411",
                "SecurityId": "SURSF",
                "SecurityName": "SURYACHAKRA SEAFOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE633C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519413",
                "SecurityId": "DATIWARE",
                "SecurityName": "DATIWARE MARITIME INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE673U01014",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519415",
                "SecurityId": "KMGMILK",
                "SecurityName": "KMG MILK FOOD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE873N01015",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519417",
                "SecurityId": "MONESHIA",
                "SecurityName": "MONESHI AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE604C01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519419",
                "SecurityId": "MAYAGRP",
                "SecurityName": "MAYA AGRO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519421",
                "SecurityId": "KSE",
                "SecurityName": "KSE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE953E01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519428",
                "SecurityId": "HERMMLK",
                "SecurityName": "HERMAN MILKFOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519433",
                "SecurityId": "MAHVIFD",
                "SecurityName": "MAHAVIRA FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519435",
                "SecurityId": "VAFAGRO",
                "SecurityName": "VAFA AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519439",
                "SecurityId": "PIONAGR",
                "SecurityName": "PIONEER AGRO EXTRACTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE062E01014",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519441",
                "SecurityId": "JAIDIND",
                "SecurityName": "JAIDKA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE733M01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519443",
                "SecurityId": "THAPOIL",
                "SecurityName": "THAPAR OILS & FATS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519445",
                "SecurityId": "PAWANPR",
                "SecurityName": "PAWAN PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519447",
                "SecurityId": "PRIMSOL",
                "SecurityName": "PRIME SOLVENT EXTRACTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519449",
                "SecurityId": "SHRSHAK",
                "SecurityName": "SHREE SHAKTI AGRO OILS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519451",
                "SecurityId": "VADIDAI",
                "SecurityName": "VADILAL DAIRY INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE159T01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519455",
                "SecurityId": "NARBADA",
                "SecurityName": "NARBADA GEMS AND JEWELLERY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE540C01021",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519457",
                "SecurityId": "VIRATCRA",
                "SecurityName": "VIRAT CRANE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE295C01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519459",
                "SecurityId": "CHAKVEG",
                "SecurityName": "CHAKAN VEGOILS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519463",
                "SecurityId": "IBINFO",
                "SecurityName": "IB INFOTECH ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE678B01021",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519467",
                "SecurityId": "AVIINDSDM",
                "SecurityName": "AVI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519469",
                "SecurityId": "RATVA",
                "SecurityName": "RATTAN VANASPATI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE330C01019",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519471",
                "SecurityId": "AMBARPIL",
                "SecurityName": "AMBAR PROTEIN INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE072V01017",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519473",
                "SecurityId": "TRILLENT",
                "SecurityName": "TRILLENIUM TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE187B01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519475",
                "SecurityId": "CHORDIA",
                "SecurityName": "CHORDIA FOOD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE975C01011",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519477",
                "SecurityId": "CIANAGRO",
                "SecurityName": "CIAN AGRO INDUSTRIES & INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE052V01019",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519479",
                "SecurityId": "OMEAG",
                "SecurityName": "OMEGA AG-SEEDS (PUNJAB) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE112B01013",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519481",
                "SecurityId": "MIHIJAM",
                "SecurityName": "MIHIJAM VANASPATI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE521C01013",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519483",
                "SecurityId": "TAIIND",
                "SecurityName": "TAI INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE358D01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519485",
                "SecurityId": "KOHINOORT",
                "SecurityName": "KOHINOOR TECHNO ENGINEERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE237D01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519489",
                "SecurityId": "SH.ANJY",
                "SecurityName": "SHRI ANJANEY AGRO FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519491",
                "SecurityId": "OCEAGRO",
                "SecurityName": "OCEAN AGRO (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE049J01010",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519494",
                "SecurityId": "NKIND",
                "SecurityName": "N.K.INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE542C01019",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519496",
                "SecurityId": "TRATF",
                "SecurityName": "TRANS TECHNO FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE405B01011",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519498",
                "SecurityId": "GLBLFDS",
                "SecurityName": "GLOBAL FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519500",
                "SecurityId": "BKV",
                "SecurityName": "BKV INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE356C01022",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519504",
                "SecurityId": "MAHINDL",
                "SecurityName": "MAHADEV INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519506",
                "SecurityId": "NCCBLUE",
                "SecurityName": "NCC BLUE WATER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE630N01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519508",
                "SecurityId": "GENUINC",
                "SecurityName": "GENUINE COMMODITIES DEVELOPMENT COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519510",
                "SecurityId": "ANMOLDY",
                "SecurityName": "ANMOL DAIRY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519512",
                "SecurityId": "DRMSY",
                "SecurityName": "DR.M.SOY & GENERAL FOOD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE733B01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519514",
                "SecurityId": "RAJSOLV",
                "SecurityName": "RAJESH SOLVEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE893E01012",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519518",
                "SecurityId": "ROYCMAR",
                "SecurityName": "ROYCE MARINE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519520",
                "SecurityId": "INTWATR",
                "SecurityName": "INTERNATIONAL WATER BASE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519526",
                "SecurityId": "SAHASAG",
                "SecurityName": "SAHAS AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519528",
                "SecurityId": "NORBTEAEXP",
                "SecurityName": "NORBEN TEA & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE369C01017",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519532",
                "SecurityId": "ASIANTNE",
                "SecurityName": "ASIAN TEA & EXPORTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE822B01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519534",
                "SecurityId": "VISAKAQ",
                "SecurityName": "VISAKHA AQUA FARMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519536",
                "SecurityId": "AGRMARI",
                "SecurityName": "AGRI-MARINE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519538",
                "SecurityId": "INDOFREB",
                "SecurityName": "INDO-FRENCH BIOTECH ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE867B01012",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519540",
                "SecurityId": "KANAFOD",
                "SecurityName": "KANAIYA FOODS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519542",
                "SecurityId": "VRUNDAG",
                "SecurityName": "VRUNDAVAN AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519546",
                "SecurityId": "KENGI",
                "SecurityName": "KENGOLD (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE680C01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519548",
                "SecurityId": "KJINTFD",
                "SecurityName": "K.J.INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519550",
                "SecurityId": "BUBNMAJ",
                "SecurityName": "BUBNA MAJOR BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519552",
                "SecurityId": "HERITGFOOD",
                "SecurityName": "HERITAGE FOODS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE978A01027",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519554",
                "SecurityId": "CONFAGR",
                "SecurityName": "CONFRO AGROS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519558",
                "SecurityId": "WESTEFD",
                "SecurityName": "WESTERN FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519560",
                "SecurityId": "NEHAINT",
                "SecurityName": "NEHA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE874D01022",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519562",
                "SecurityId": "PASEAFD",
                "SecurityName": "P.A.SEA FOOD EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519564",
                "SecurityId": "ORIEVEG",
                "SecurityName": "ORIENT VEGETEXPO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519566",
                "SecurityId": "SIMRAN",
                "SecurityName": "SIMRAN FARMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE354D01017",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519570",
                "SecurityId": "LAKSHMIO",
                "SecurityName": "LAKSHMI OVERSEAS INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE992B01026",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519572",
                "SecurityId": "UNITYAG",
                "SecurityName": "UNITY AGROTECH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519574",
                "SecurityId": "HINDUST",
                "SecurityName": "HINDUSTAN AGRIGENETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE092301014",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519576",
                "SecurityId": "INDODFD",
                "SecurityName": "INDO-DUTCH PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519578",
                "SecurityId": "JAGDAMD",
                "SecurityName": "JAGDAMBA FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519580",
                "SecurityId": "HANUMAN",
                "SecurityName": "HANUMAN TEA CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE363C01010",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519582",
                "SecurityId": "UNTEDFD-B",
                "SecurityName": "UNIFIED AGRO INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519584",
                "SecurityId": "GOLDEFD",
                "SecurityName": "GOLDEN AGRO-TECH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519586",
                "SecurityId": "SMILAX",
                "SecurityName": "SMILAX INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE393M01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519588",
                "SecurityId": "DFM",
                "SecurityName": "DFM FOODS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE456C01020",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519600",
                "SecurityId": "CCL",
                "SecurityName": "CCL PRODUCTS (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE421D01022",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519602",
                "SecurityId": "KELLTONTEC",
                "SecurityName": "KELLTON TECH SOLUTIONS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE164B01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519604",
                "SecurityId": "SURFI",
                "SecurityName": "SURYO FOODS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE565E01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519606",
                "SecurityId": "INTEGFD",
                "SecurityName": "INTEGRATED PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE177M01013",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519608",
                "SecurityId": "LILACFD",
                "SecurityName": "LILAC EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519610",
                "SecurityId": "SUNCITY",
                "SecurityName": "SUNCITY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "519612",
                "SecurityId": "MAHAANF",
                "SecurityName": "MAHAAN FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE734D01010",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520003",
                "SecurityId": "VYVRAUT",
                "SecurityName": "VYBRA AUTOMET LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE251F01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520008",
                "SecurityId": "RICOAUTO",
                "SecurityName": "RICO AUTO INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE209B01025",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520021",
                "SecurityId": "OMAXAUTO",
                "SecurityName": "OMAX AUTOS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE090B01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520022",
                "SecurityId": "DENSO",
                "SecurityName": "DENSO (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE502A01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520024",
                "SecurityId": "MANSAROVARP",
                "SecurityName": "MANSAROVARP",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520026",
                "SecurityId": "HELLAIND",
                "SecurityName": "HELLA INDIA LIGHTING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE431D01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520043",
                "SecurityId": "MUNJALSHOW",
                "SecurityName": "MUNJAL SHOWA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE577A01027",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520051",
                "SecurityId": "JAMNAAUTO",
                "SecurityName": "JAMNA AUTO INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE039C01032",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520056",
                "SecurityId": "SUNCLAYLTD",
                "SecurityName": "SUNDARAM-CLAYTON LTD.",
                "FaceValue": "5",
                "ISINNo": "INE105A01035",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520057",
                "SecurityId": "JTEKTINDIA",
                "SecurityName": "JTEKT INDIA LTD",
                "FaceValue": "1",
                "ISINNo": "INE643A01035",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520059",
                "SecurityId": "MUNJALAU",
                "SecurityName": "MUNJAL AUTO INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE672B01032",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520061",
                "SecurityId": "LAKSHMIAUTO",
                "SecurityName": "LAKSHMI AUTO COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE871A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520064",
                "SecurityId": "GKNDRDM",
                "SecurityName": "GKN DRIVESHAFTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE527A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520066",
                "SecurityId": "JAYBARMARU",
                "SecurityName": "JAY BHARAT MARUTI LTD.",
                "FaceValue": "5",
                "ISINNo": "INE571B01028",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520067",
                "SecurityId": "RENZTLB-B",
                "SecurityName": "REINZ-TALBROS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520069",
                "SecurityId": "NATAUTO",
                "SecurityName": "NATIONAL AUTO ACCESSORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520071",
                "SecurityId": "JAPSP",
                "SecurityName": "JAI PARABOLIC SPRINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE686B01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520073",
                "SecurityId": "RACLGEAR",
                "SecurityName": "RACL GEARTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE704B01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520075",
                "SecurityId": "SAMKRG",
                "SecurityName": "SAMKRG PISTONS & RINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE706B01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520077",
                "SecurityId": "AMTEKAUTO",
                "SecurityName": "AMTEK AUTO LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE130C01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520079",
                "SecurityId": "INDIASTE",
                "SecurityName": "INDIA STEAMSHIP CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE288C01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520080",
                "SecurityId": "SICA",
                "SecurityName": "SOU IND CO A",
                "FaceValue": " ",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520081",
                "SecurityId": "ECSTSTL",
                "SecurityName": "EAST COAST STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE315F01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520086",
                "SecurityId": "SICAL",
                "SecurityName": "SICAL LOGISTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE075B01012",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520090",
                "SecurityId": "SIPNAUT",
                "SecurityName": "SIPANI AUTOMOBILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520096",
                "SecurityId": "ATOTNSN",
                "SecurityName": "AUTO TENSION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520101",
                "SecurityId": "TVSAUTOL",
                "SecurityName": "TVS AUTOLEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE134C01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520109",
                "SecurityId": "ZBAJAJTR",
                "SecurityName": "ZBAJAJTR",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520111",
                "SecurityId": "RATNAMANI",
                "SecurityName": "RATNAMANI METALS & TUBES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE703B01027",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520113",
                "SecurityId": "VESUVIUS",
                "SecurityName": "VESUVIUS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE386A01015",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520115",
                "SecurityId": "BRAKAUT",
                "SecurityName": "BRAKES AUTO (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE222G01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520117",
                "SecurityId": "SUNKU",
                "SecurityName": "SUNKU AUTO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE723B01017",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520119",
                "SecurityId": "ASAL",
                "SecurityName": "AUTOMOTIVE STAMPINGS & ASSEMBLIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE900C01027",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520121",
                "SecurityId": "ARCEEIN",
                "SecurityName": "ARCEE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE276D01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520123",
                "SecurityId": "ABCINDQ",
                "SecurityName": "ABC INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE125D01011",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520125",
                "SecurityId": "DESIGNAU",
                "SecurityName": "DESIGN AUTO SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE993B01016",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520127",
                "SecurityId": "BALTE",
                "SecurityName": "BALURGHAT TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE654B01014",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520131",
                "SecurityId": "COARO",
                "SecurityName": "COASTAL ROADWAYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE229E01019",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520139",
                "SecurityId": "JAGSONAI",
                "SecurityName": "JAGSON AIRLINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE685B01018",
                "Industry": "Airlines",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520141",
                "SecurityId": "SIBARAUT",
                "SecurityName": "SIBAR AUTO PARTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE441C01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520145",
                "SecurityId": "FAIRFIEL",
                "SecurityName": "FAIRFIELD ATLAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE922C01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520147",
                "SecurityId": "CRCARRIDM",
                "SecurityName": "CRC CARRIER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520149",
                "SecurityId": "MAFLU",
                "SecurityName": "MAFATLAL LUBRICANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE521D01011",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520151",
                "SecurityId": "SHREYAS",
                "SecurityName": "SHREYAS SHIPPING & LOGISTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE757B01015",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520153",
                "SecurityId": "INDCASTDM",
                "SecurityName": "INDIA CASTOR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "520155",
                "SecurityId": "STARLOG",
                "SecurityName": "STARLOG ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE580C01019",
                "Industry": "Marine Port & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521003",
                "SecurityId": "SHVSUIT",
                "SecurityName": "SHIVA SUITINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE02Z901011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521005",
                "SecurityId": "INDOVATION",
                "SecurityName": "INDOVATION TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE807M01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521012",
                "SecurityId": "ANANROT",
                "SecurityName": "ANANT ROTOSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521014",
                "SecurityId": "EUROTEXIND",
                "SecurityName": "EUROTEX INDUSTRIES & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE022C01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521016",
                "SecurityId": "ICIL",
                "SecurityName": "INDO COUNT INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE483B01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521018",
                "SecurityId": "MARALOVER",
                "SecurityName": "MARAL OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE882A01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521022",
                "SecurityId": "SURYAJYOTI",
                "SecurityName": "SURYAJYOTI SPINNING MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE411C01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521024",
                "SecurityId": "AMTEXTL",
                "SecurityName": "AMETHI TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521026",
                "SecurityId": "SHEENA",
                "SecurityName": "SHEENA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521028",
                "SecurityId": "VEENATX",
                "SecurityName": "VEENA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE728E01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521030",
                "SecurityId": "NAKODA",
                "SecurityName": "NAKODA LIMITED-$",
                "FaceValue": "5",
                "ISINNo": "INE559B01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521034",
                "SecurityId": "SOMATEX",
                "SecurityName": "SOMA TEXTILES & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE314C01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521036",
                "SecurityId": "SOURCEIND",
                "SecurityName": "SOURCE INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE695C01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521038",
                "SecurityId": "TAMJAIM",
                "SecurityName": "TAMILNADU JAIBHARAT MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE868H01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521046",
                "SecurityId": "VANASTEX",
                "SecurityName": "VANASTHALI TEXTILE INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE698C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521048",
                "SecurityId": "ADVLIFE",
                "SecurityName": "ADVANCE LIFESTYLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE900E01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521052",
                "SecurityId": "SALEMTX",
                "SecurityName": "SALEM TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521054",
                "SecurityId": "KAKTEX",
                "SecurityName": "KAKATIYA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE092E01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521056",
                "SecurityId": "CHESLINTEX",
                "SecurityName": "CHESLIND TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE217C01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521058",
                "SecurityId": "DENIKNI",
                "SecurityName": "DENISH-KNIT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521062",
                "SecurityId": "OCTAVE",
                "SecurityName": "PERFECT-OCTAVE MEDIA PROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE814L01013",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521064",
                "SecurityId": "TRIDENT",
                "SecurityName": "TRIDENT LTD.",
                "FaceValue": "1",
                "ISINNo": "INE064C01022",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521068",
                "SecurityId": "HISARSP",
                "SecurityName": "HISAR SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE689E01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521070",
                "SecurityId": "ALOKTEXT",
                "SecurityName": "ALOK INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE270A01029",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521072",
                "SecurityId": "INTRCRF",
                "SecurityName": "INTERCRAFT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521074",
                "SecurityId": "KORATEX",
                "SecurityName": "KORATLA TEXTILES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521076",
                "SecurityId": "ASIL",
                "SecurityName": "AMIT SPINNING INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE988A01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521080",
                "SecurityId": "PASARI",
                "SecurityName": "PASARI SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE604F01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521082",
                "SecurityId": "SPENTEX",
                "SecurityName": "SPENTEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE376C01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521086",
                "SecurityId": "SAGRAPR",
                "SecurityName": "SAGAR APPARELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521088",
                "SecurityId": "VNYGCLO",
                "SecurityName": "VINIYOGA CLOTHEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521090",
                "SecurityId": "KARNFBR",
                "SecurityName": "KARAN FIBRES AND FABRICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521092",
                "SecurityId": "RAGHUIN",
                "SecurityName": "RAGHU INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521093",
                "SecurityId": "HINCOTX",
                "SecurityName": "HINDUSTAN COTEX EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521095",
                "SecurityId": "HLNHOSR",
                "SecurityName": "H-LON HOSIERY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521097",
                "SecurityId": "AMARJOTHI",
                "SecurityName": "AMARJOTHI SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE484D01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521099",
                "SecurityId": "EMTEXIND",
                "SecurityName": "EMTEX INDUSTRIES (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE907B01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521103",
                "SecurityId": "AMRAPALI",
                "SecurityName": "AMRAPALI DEVELOPERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE761C01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521105",
                "SecurityId": "OLYMPTX",
                "SecurityName": "OLYMPIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE482O01021",
                "Industry": "Internet & Catalogue Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521107",
                "SecurityId": "SAJJNT",
                "SecurityName": "SAJJAN TEXTILES MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521109",
                "SecurityId": "NAGREEKEXP",
                "SecurityName": "NAGREEKA EXPORTS LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE123B01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521111",
                "SecurityId": "SALZETX",
                "SecurityName": "SALZER TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521113",
                "SecurityId": "SUDTIND-B",
                "SecurityName": "SUDITI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE691D01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521115",
                "SecurityId": "PRSMMIL",
                "SecurityName": "PRISM MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521117",
                "SecurityId": "KKRSONS",
                "SecurityName": "KUKAR SONS (INDO-FRENCH) EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521119",
                "SecurityId": "SRIMALI",
                "SecurityName": "SRI MALINI SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521123",
                "SecurityId": "PEARLGLO",
                "SecurityName": "PEARL GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE787B01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521125",
                "SecurityId": "DRIND",
                "SecurityName": "D.R.INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE197B01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521127",
                "SecurityId": "KHTRFIB",
                "SecurityName": "KHATOR FIBRE & FABRICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE964G01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521129",
                "SecurityId": "GUJTEXSP",
                "SecurityName": "GUJARAT TEXSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE677C01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521131",
                "SecurityId": "SBFL",
                "SecurityName": "SHREE BHAVYA FABRICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE363D01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521133",
                "SecurityId": "GEMSPIN",
                "SecurityName": "GEM SPINNERS INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE165F01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521135",
                "SecurityId": "VJYKMCT",
                "SecurityName": "VIJAYKUMAR MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521137",
                "SecurityId": "EUREKAI",
                "SecurityName": "EUREKA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE958A01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521139",
                "SecurityId": "MHGTCXT",
                "SecurityName": "MAHAGANESH TEXPRO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521141",
                "SecurityId": "ADITYASP",
                "SecurityName": "ADITYA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122D01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521145",
                "SecurityId": "JERSETXDM",
                "SecurityName": "JERSEY INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521147",
                "SecurityId": "MILLENNIUM",
                "SecurityName": "MILLENNIUM BEER INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE258D01028",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521149",
                "SecurityId": "PRIMEURB",
                "SecurityName": "PRIME URBAN DEVELOPMENT INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE419E01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521151",
                "SecurityId": "DHANFAB",
                "SecurityName": "DHANLAXMI FABRICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE953D01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521155",
                "SecurityId": "PEGAPPA",
                "SecurityName": "PEGASUS APPARELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521159",
                "SecurityId": "PERSCAR",
                "SecurityName": "PERSIAN CARPET & TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521161",
                "SecurityId": "SLSTLQ",
                "SecurityName": "SRI LAKSHMI SARASWATHI TEXTILES (ARNI) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE456D01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521163",
                "SecurityId": "ZODIACLOTH",
                "SecurityName": "ZODIAC CLOTHING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE206B01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521165",
                "SecurityId": "ANACOTS",
                "SecurityName": "ANAND COTSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521167",
                "SecurityId": "FRONTBUSS",
                "SecurityName": "FRONTLINE BUSINESS SOLUTIONS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE485D01035",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521169",
                "SecurityId": "DENIMFS",
                "SecurityName": "DENIM FASHIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521172",
                "SecurityId": "SSMILLS",
                "SecurityName": "SSM MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521174",
                "SecurityId": "ARORAFIB",
                "SecurityName": "ARORA FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE487D01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521176",
                "SecurityId": "GANGOTRI",
                "SecurityName": "GANGOTRI TEXTILES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE670B01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521178",
                "SecurityId": "SRMCL",
                "SecurityName": "SRI RAMAKRISHNA MILLS (COIMBATORE) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE306D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521180",
                "SecurityId": "SUPERSPIN",
                "SecurityName": "SUPER SPINNING MILLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE662A01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521182",
                "SecurityId": "SEASONF",
                "SecurityName": "SEASONS FURNISHINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE454D01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521184",
                "SecurityId": "PRECM",
                "SecurityName": "PRECOT MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE283A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521188",
                "SecurityId": "UNITEDTE",
                "SecurityName": "UNITED TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE727E01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521190",
                "SecurityId": "PANCLTH",
                "SecurityName": "PAN CLOTHING & CONSOLIDATED CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521192",
                "SecurityId": "NEPCTEX",
                "SecurityName": "NEPC TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE574B01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521194",
                "SecurityId": "SILINV",
                "SecurityName": "SIL INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE923A01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521196",
                "SecurityId": "RAVISPN",
                "SecurityName": "RAVI SPINNING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521200",
                "SecurityId": "SURYALAXMI",
                "SecurityName": "SURYALAKSHMI COTTON MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE713B01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521204",
                "SecurityId": "SIDHART",
                "SecurityName": "SIDDHARTHA SPINFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521206",
                "SecurityId": "SAMTEX",
                "SecurityName": "SAMTEX FASHIONS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE931D01020",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521208",
                "SecurityId": "ATLANTSP",
                "SecurityName": "ATLANTIC SPINNING & WEAVING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE504C01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521210",
                "SecurityId": "CITYMAN",
                "SecurityName": "CITYMAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE117C01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521212",
                "SecurityId": "BALWTEX",
                "SecurityName": "BALWANT TEXTILES MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521214",
                "SecurityId": "KESARTX",
                "SecurityName": "KESHARIYA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521216",
                "SecurityId": "DHANROTO",
                "SecurityName": "DHANALAXMI ROTO SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE220C01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521218",
                "SecurityId": "DELIHND",
                "SecurityName": "DELIGHT HANDICRAFTS PALACE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE226F01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521220",
                "SecurityId": "DAMOINDUS",
                "SecurityName": "DAMODAR INDUSTRIES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE497D01022",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521222",
                "SecurityId": "SANBLUE",
                "SecurityName": "SANBLUE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE602D01027",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521224",
                "SecurityId": "KONGATX",
                "SecurityName": "KONGARAR TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521226",
                "SecurityId": "UNIROYAL",
                "SecurityName": "UNIROYAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE980D01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521228",
                "SecurityId": "TATIAGLOB",
                "SecurityName": "TATIA GLOBAL VENNTURE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE083G01031",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521230",
                "SecurityId": "GOLDTXT",
                "SecurityName": "GOLDWON TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521232",
                "SecurityId": "SUNILTX",
                "SecurityName": "SUNIL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE124M01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521234",
                "SecurityId": "SRINACHA",
                "SecurityName": "SRI NACHAMMAI COTTON MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE443E01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521236",
                "SecurityId": "SROGNGM",
                "SecurityName": "SRI GANAPATHY MILLS CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE488F01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521238",
                "SecurityId": "KDL",
                "SecurityName": "KKRRAFTON DEVELOPERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE893C01032",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521240",
                "SecurityId": "SAMBANDAM",
                "SecurityName": "SAMBANDAM SPINNING MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE304D01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521242",
                "SecurityId": "KANDAGIRI",
                "SecurityName": "KANDAGIRI SPINNING MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE292D01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521244",
                "SecurityId": "CHITRTX",
                "SecurityName": "CHITRADURGA SPINTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE676G01024",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521246",
                "SecurityId": "PARASPETRO",
                "SecurityName": "PARAS PETROFILS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE162C01024",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521248",
                "SecurityId": "KITEX",
                "SecurityName": "KITEX GARMENTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE602G01020",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521250",
                "SecurityId": "SRUMATX",
                "SecurityName": "SREE UMA PARAMESWARI MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "521252",
                "SecurityId": "RADHIKAS",
                "SecurityName": "RADHIKA SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE938B01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522001",
                "SecurityId": "CRANEX",
                "SecurityName": "CRANEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE608B01010",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522004",
                "SecurityId": "BATLIBOI",
                "SecurityName": "BATLIBOI LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE177C01022",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522005",
                "SecurityId": "AUSTENG",
                "SecurityName": "AUSTIN ENGINEERING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE759F01012",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522014",
                "SecurityId": "UNIDT",
                "SecurityName": "UNITED DRILLING TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE961D01019",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522015",
                "SecurityId": "VCCLLTD",
                "SecurityName": "VCCL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE460E01010",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522017",
                "SecurityId": "FLUIDOM",
                "SecurityName": "FLUIDOMAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE459C01016",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522027",
                "SecurityId": "EMAINDIA",
                "SecurityName": "EMA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE279D01016",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522029",
                "SecurityId": "WINDMACHIN",
                "SecurityName": "WINDSOR MACHINES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE052A01021",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522034",
                "SecurityId": "SHANTIGEAR",
                "SecurityName": "SHANTHI GEARS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE631A01022",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522036",
                "SecurityId": "MIVENMACH",
                "SecurityName": "MIVEN MACHINE TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE338P01014",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522041",
                "SecurityId": "CNTLVAL",
                "SecurityName": "CONTINENTAL VALVE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE465F01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522042",
                "SecurityId": "SMENER",
                "SecurityName": "S.M.ENERGY TEKNIK & ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE540D01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522045",
                "SecurityId": "ATURIAC",
                "SecurityName": "ATURIA CONTINENTAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522055",
                "SecurityId": "BEMCOJK",
                "SecurityName": "BEMCO JACKS & ALLIED PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522056",
                "SecurityId": "SACHI",
                "SecurityName": "SACHS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE337D01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522059",
                "SecurityId": "INDAGEVIN",
                "SecurityName": "INDAGE VINTNERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE194C01019",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522062",
                "SecurityId": "KAVIENGDM",
                "SecurityName": "KAVERI ENGINEERING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522064",
                "SecurityId": "HONDAPOWER",
                "SecurityName": "HONDA INDIA POWER PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE634A01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522065",
                "SecurityId": "SHRIHON",
                "SecurityName": "SHRIHON",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522073",
                "SecurityId": "HITECHGEAR",
                "SecurityName": "THE HI-TECH GEARS LTD",
                "FaceValue": "10",
                "ISINNo": "INE127B01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522074",
                "SecurityId": "ELGIEQUIP",
                "SecurityName": "ELGI EQUIPMENTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE285A01027",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522078",
                "SecurityId": "ELBSCHF",
                "SecurityName": "ELB SCHLIFF (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522080",
                "SecurityId": "TERRUZZI",
                "SecurityName": "TERRUZZI FERCALX INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE699C01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522085",
                "SecurityId": "STONEIN",
                "SecurityName": "STONE INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE290C01015",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522091",
                "SecurityId": "UVDRHOR",
                "SecurityName": "UNITED VAN DER HORST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE890G01013",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522093",
                "SecurityId": "WELII",
                "SecurityName": "WELLMAN INCANDESCENT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE143D01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522095",
                "SecurityId": "INCEN",
                "SecurityName": "INCORPORATED ENGINEERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE801D01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522099",
                "SecurityId": "ZINDUCIN",
                "SecurityName": "INDUCON INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522101",
                "SecurityId": "KLBRENG-B",
                "SecurityName": "KILBURN ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE338F01015",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522103",
                "SecurityId": "GRMHCNCDM",
                "SecurityName": "GREMACH CNC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522105",
                "SecurityId": "BIRLAPREC",
                "SecurityName": "BIRLA PRECISION TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE372E01025",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522106",
                "SecurityId": "VIPCORP",
                "SecurityName": "VIPRAS CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522108",
                "SecurityId": "YUKEN",
                "SecurityName": "YUKEN INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE384C01016",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522113",
                "SecurityId": "TIMKEN",
                "SecurityName": "TIMKEN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE325A01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522114",
                "SecurityId": "ALXNXTR",
                "SecurityName": "ALEXCON EXTRUSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522116",
                "SecurityId": "TEXTOOL",
                "SecurityName": "TEXTOOL COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE677A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522118",
                "SecurityId": "ZMEEKTRA",
                "SecurityName": "MEEKAN TRANSMISSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522122",
                "SecurityId": "VOITHPAPR",
                "SecurityName": "VOITH PAPER FABRICS INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE285C01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522130",
                "SecurityId": "HEATSHRINKT",
                "SecurityName": "HEATSHRINK TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE966B01012",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522132",
                "SecurityId": "VIDIANI",
                "SecurityName": "VIDIANI ENGINEERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522134",
                "SecurityId": "ARTSONEN",
                "SecurityName": "ARTSON ENGINEERING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE133D01023",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522136",
                "SecurityId": "BHAGEENG",
                "SecurityName": "BHAGHEERATHA ENGINEERING LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE869E01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522140",
                "SecurityId": "PATNIEN",
                "SecurityName": "PATNI ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522142",
                "SecurityId": "TECHFOR",
                "SecurityName": "TECHNO FORGE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE305I01018",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522148",
                "SecurityId": "GORDONHE",
                "SecurityName": "GORDON HERBERT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE755C01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522150",
                "SecurityId": "ADTYFRG",
                "SecurityName": "ADITYA FORGE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE281H01013",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522152",
                "SecurityId": "SOLIMAC",
                "SecurityName": "SOLITAIRE MACHINE TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE410A01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522153",
                "SecurityId": "SOLRSNI",
                "SecurityName": "SOLARSON INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522157",
                "SecurityId": "BANKA",
                "SecurityName": "BANKA (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE148C01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522163",
                "SecurityId": "DIAPOWER",
                "SecurityName": "DIAMOND POWER INFRASTRUCTURE LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE989C01012",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522165",
                "SecurityId": "INDSILHYD",
                "SecurityName": "INDSIL HYDRO POWER AND MANGANESE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE867D01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522167",
                "SecurityId": "FLEXENG",
                "SecurityName": "FLEX ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE929B01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522169",
                "SecurityId": "SNEHDHR",
                "SecurityName": "SNHEHADHARA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522171",
                "SecurityId": "TMTIND-B1",
                "SecurityName": "TMT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE182E01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522173",
                "SecurityId": "KRISHNAENG",
                "SecurityName": "KRISHNA ENGINEERING WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE409C01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522175",
                "SecurityId": "SVOGL",
                "SecurityName": "SVOGL OIL GAS AND ENERGY LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE756B01017",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522181",
                "SecurityId": "PRFASDM",
                "SecurityName": "PRECISION FASTENERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE604A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522183",
                "SecurityId": "ITL",
                "SecurityName": "ITL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE478D01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522185",
                "SecurityId": "TTGINDM",
                "SecurityName": "TTG INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE111E01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522189",
                "SecurityId": "KNDENGT",
                "SecurityName": "KND ENGINEERING TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522193",
                "SecurityId": "BILINDU",
                "SecurityName": "BIL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522195",
                "SecurityId": "FRONTSP",
                "SecurityName": "FRONTIER SPRINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE572D01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522199",
                "SecurityId": "MONOZYM",
                "SecurityName": "MONOZYME INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE772D01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522201",
                "SecurityId": "AAKARENG",
                "SecurityName": "AAKAR ENGINEERING & MANUFACTURING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE814B01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522203",
                "SecurityId": "CHAININ",
                "SecurityName": "CHAIN IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522205",
                "SecurityId": "PRAJIND",
                "SecurityName": "PRAJ INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE074A01025",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522207",
                "SecurityId": "RASANDIK",
                "SecurityName": "RASANDIK ENGINEERING INDUSTRIES INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE682D01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522209",
                "SecurityId": "YOGISUNG",
                "SecurityName": "YOGI SUNG-WON (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE429B01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522211",
                "SecurityId": "RAJBALL",
                "SecurityName": "RAJASTHAN BALLS & BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522215",
                "SecurityId": "HLEGLAS",
                "SecurityName": "HLE GLASCOAT LTD",
                "FaceValue": "10",
                "ISINNo": "INE461D01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522217",
                "SecurityId": "GUJAPOLLO",
                "SecurityName": "GUJARAT APOLLO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE826C01016",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522219",
                "SecurityId": "HYDFLEXI",
                "SecurityName": "HYDERABAD FLEXTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE113C01019",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522221",
                "SecurityId": "SRILAEG",
                "SecurityName": "SRI LAKSHMI ENGINEERING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522223",
                "SecurityId": "MARTELI",
                "SecurityName": "MARUTI TELSTAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522229",
                "SecurityId": "TANAA",
                "SecurityName": "TANEJA AEROSPACE & AVIATION LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE692C01020",
                "Industry": "Aerospace",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522231",
                "SecurityId": "CONART",
                "SecurityName": "CONART ENGINEERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE714D01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522233",
                "SecurityId": "VHCLINDUS",
                "SecurityName": "VHCL INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE674D01026",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522235",
                "SecurityId": "MINALIND",
                "SecurityName": "MINAL INDUSTRIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE097E01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522237",
                "SecurityId": "SHIVAGR",
                "SecurityName": "SHIVAGRICO IMPLEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE092H01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522241",
                "SecurityId": "MMFL",
                "SecurityName": "M.M.FORGINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE227C01017",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522243",
                "SecurityId": "LAKSRAT",
                "SecurityName": "LAXMIRATAN ENGINEERING WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522245",
                "SecurityId": "IYKOTHITE",
                "SecurityName": "IYKOT HITECH TOOLROOM LTD.",
                "FaceValue": "5",
                "ISINNo": "INE079L01013",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522249",
                "SecurityId": "MAYURUNIQ",
                "SecurityName": "MAYUR UNIQUOTERS LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE040D01038",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522251",
                "SecurityId": "CENLUB",
                "SecurityName": "CENLUB INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE627F01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522255",
                "SecurityId": "FORGBLO",
                "SecurityName": "FORGE & BLOWER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522257",
                "SecurityId": "RAJOOENG",
                "SecurityName": "RAJOO ENGINEERS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE535F01024",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522259",
                "SecurityId": "KALINDEE",
                "SecurityName": "KALINDEE RAIL NIRMAN (ENGINEERS) LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE178D01010",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522261",
                "SecurityId": "DOLPHINOFF",
                "SecurityName": "DOLPHIN OFFSHORE ENTERPRISES (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE920A01011",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522263",
                "SecurityId": "JMCPROJECT",
                "SecurityName": "JMC PROJECTS (INDIA) LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE890A01024",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522267",
                "SecurityId": "VJLAXMIE",
                "SecurityName": "VEEJAY LAKSHMI ENGINEERING WORKS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE466D01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522269",
                "SecurityId": "COVENSP-B",
                "SecurityName": "COVENTRY SPRING & ENGINEERING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522271",
                "SecurityId": "UNIONBE",
                "SecurityName": "UNION BEARINGS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE670F01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522273",
                "SecurityId": "AHMDSTE",
                "SecurityName": "AHMEDABAD STEELCRAFT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE868C01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522275",
                "SecurityId": "GET&D",
                "SecurityName": "GE T&D INDIA LTD",
                "FaceValue": "2",
                "ISINNo": "INE200A01026",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522277",
                "SecurityId": "SRPIND",
                "SecurityName": "SRP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522279",
                "SecurityId": "SATELENG",
                "SecurityName": "SATELLITE ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE087C01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522281",
                "SecurityId": "RAMRAT",
                "SecurityName": "RAM RATNA WIRES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE207E01023",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522285",
                "SecurityId": "JAYNECOIND",
                "SecurityName": "JAYASWAL NECO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE854B01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522287",
                "SecurityId": "KALPATPOWR",
                "SecurityName": "KALPATARU POWER TRANSMISSION LTD.",
                "FaceValue": "2",
                "ISINNo": "INE220B01022",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522289",
                "SecurityId": "NMSRESRC",
                "SecurityName": "NMS RESOURCES GLOBAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE169F01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522292",
                "SecurityId": "CHANDNI",
                "SecurityName": "CHANDNI TEXTILES ENGINEERING IND. LTD",
                "FaceValue": "10",
                "ISINNo": "INE713D01055",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522294",
                "SecurityId": "TIGLOB",
                "SecurityName": "T & I GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE811B01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522295",
                "SecurityId": "CONTROLPR",
                "SecurityName": "CONTROL PRINT LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE663B01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522296",
                "SecurityId": "SSFORMT",
                "SecurityName": "S.S.FORGINGS & ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE233G01016",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522298",
                "SecurityId": "MICROMT",
                "SecurityName": "MICRO FORGE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE550E01018",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "522650",
                "SecurityId": "BEMHY",
                "SecurityName": "BEMCO HYDRAULICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE142E01014",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523007",
                "SecurityId": "ANSALBU",
                "SecurityName": "ANSAL BUILDWELL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE030C01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523011",
                "SecurityId": "WEIZMANIND",
                "SecurityName": "WEIZMANN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE080A01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523019",
                "SecurityId": "BNRSEC",
                "SecurityName": "B.N.RATHI SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE710D01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523021",
                "SecurityId": "RISHITECH",
                "SecurityName": "RISHI TECHTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE989D01010",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523023",
                "SecurityId": "SINCLAIR",
                "SecurityName": "SINCLAIRS HOTELS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE985A01022",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523025",
                "SecurityId": "SAFARIND",
                "SecurityName": "SAFARI INDUSTRIES (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE429E01023",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523028",
                "SecurityId": "NUWAREI",
                "SecurityName": "NUWARE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523030",
                "SecurityId": "RAJDHNIL",
                "SecurityName": "RAJDHANI LEASING & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE608D01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523033",
                "SecurityId": "SOUNDCRAFT",
                "SecurityName": "SOUNDCRAFT INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE271A01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523035",
                "SecurityId": "SARVDIM",
                "SecurityName": "SARVESHWARI DIAMONDS & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523044",
                "SecurityId": "HFML",
                "SecurityName": "HINDUSTAN FINANCIAL MANAGEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE815C01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523049",
                "SecurityId": "RINKIPET",
                "SecurityName": "RINKI PETROCHEMICALS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE726B01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523051",
                "SecurityId": "DEWRUBB-B",
                "SecurityName": "DEWAN RUBBER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523054",
                "SecurityId": "ZBINTXPP",
                "SecurityName": "BINAYAK TEX PROCESSORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE626H01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523062",
                "SecurityId": "JJFINCOR",
                "SecurityName": "J.J.FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE584C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523066",
                "SecurityId": "INDIAGYP",
                "SecurityName": "INDIA GYPSUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE561A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523069",
                "SecurityId": "PRALE",
                "SecurityName": "PRAKASH LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE816D01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523072",
                "SecurityId": "ASROTOPDM",
                "SecurityName": "A.S.ROTOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523075",
                "SecurityId": "KHTNREF",
                "SecurityName": "KHTNREF",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523080",
                "SecurityId": "AEC",
                "SecurityName": "AEC (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523083",
                "SecurityId": "SOMPLAST",
                "SecurityName": "SOMPLAST LEATHER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE021E01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523090",
                "SecurityId": "SAFPACKDM",
                "SecurityName": "SAFEPACK POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523095",
                "SecurityId": "HOPELES",
                "SecurityName": "HOPE LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523100",
                "SecurityId": "COSMOFE",
                "SecurityName": "COSMO FERRITES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE124B01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523102",
                "SecurityId": "CONSFNDDM",
                "SecurityName": "CONSOLIDATED FOUNDATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523105",
                "SecurityId": "ZPPOLYSA",
                "SecurityName": "PLANTER POLYSACKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE293E01023",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523106",
                "SecurityId": "ZMAGNETI",
                "SecurityName": "MAGNETIX (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523113",
                "SecurityId": "FUTURSEC",
                "SecurityName": "FUTURISTIC SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE425D01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523116",
                "SecurityId": "SANCTRN",
                "SecurityName": "SANCO TRANS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE391G01012",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523120",
                "SecurityId": "ADORMUL",
                "SecurityName": "ADOR MULTIPRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE628D01014",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523125",
                "SecurityId": "ELARFSH",
                "SecurityName": "ELAR FASHIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523127",
                "SecurityId": "EIHAHOTELS",
                "SecurityName": "EIH ASSOCIATED HOTELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE276C01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523131",
                "SecurityId": "KHAOVER",
                "SecurityName": "KHAITAN OVERSEAS & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523133",
                "SecurityId": "BONINDL",
                "SecurityName": "BONANZA INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE143N01021",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523141",
                "SecurityId": "SOLBUSDM",
                "SecurityName": "SOLAR BUSIFORMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523144",
                "SecurityId": "MEDICAPQ",
                "SecurityName": "MEDI-CAPS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE442D01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523149",
                "SecurityId": "ARPLC",
                "SecurityName": "ARPAN LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE080E01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523151",
                "SecurityId": "OTCO",
                "SecurityName": "OTCO INTERNATIONAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE910B01028",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523153",
                "SecurityId": "GKAYEXM-B",
                "SecurityName": "GEEKAY EXIM (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523156",
                "SecurityId": "HEMKUNT",
                "SecurityName": "HEMKUNT TIMBERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523160",
                "SecurityId": "MORGANITE",
                "SecurityName": "MORGANITE CRUCIBLE (INDIA) LTD.",
                "FaceValue": "5",
                "ISINNo": "INE599F01020",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523164",
                "SecurityId": "SIPIND",
                "SecurityName": "SIP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE186B01017",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523167",
                "SecurityId": "INDSMFNDM",
                "SecurityName": "INDIAN SEAMLESS FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523168",
                "SecurityId": "AYPLAMI",
                "SecurityName": "AYEPEE LAMITUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE257X01010",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523179",
                "SecurityId": "ROCKLES-B",
                "SecurityName": "ROCKLAND LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523186",
                "SecurityId": "BAPACK",
                "SecurityName": "B&A PACKAGING INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE00FM01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523197",
                "SecurityId": "MAZDAPR",
                "SecurityName": "MAZDA PROPERTIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE245R01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523200",
                "SecurityId": "CLASSIC",
                "SecurityName": "CLASSIC DIAMONDS (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE987C01024",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523204",
                "SecurityId": "ABAN",
                "SecurityName": "ABAN OFFSHORE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE421A01028",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523207",
                "SecurityId": "KOKUYOCMLN",
                "SecurityName": "KOKUYO CAMLIN LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE760A01029",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523209",
                "SecurityId": "NICCOUCO",
                "SecurityName": "NICCO UCO ALLIANCE CREDIT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE917B01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523218",
                "SecurityId": "KILBURN",
                "SecurityName": "KILBURN OFFICE AUTOMATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE793E01014",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523221",
                "SecurityId": "MCSLTD",
                "SecurityName": "MCS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE737A01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523222",
                "SecurityId": "SRMENERGY",
                "SecurityName": "SRM ENERGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE173J01018",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523229",
                "SecurityId": "BHARATSE",
                "SecurityName": "BHARAT SEATS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE415D01024",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523230",
                "SecurityId": "JALPAC",
                "SecurityName": "JALPAC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE976B01011",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523232",
                "SecurityId": "CONTPTR",
                "SecurityName": "CONTINENTAL PETROLEUMS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE369D01023",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523236",
                "SecurityId": "SHRENUJ",
                "SecurityName": "SHRENUJ & CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE633A01028",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523240",
                "SecurityId": "GUJINST",
                "SecurityName": "GUJINST",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523242",
                "SecurityId": "NBFOOT",
                "SecurityName": "NB FOOTWEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE006F01018",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523244",
                "SecurityId": "MOHANFI",
                "SecurityName": "MOHAN FIBRE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523246",
                "SecurityId": "CHOKINT",
                "SecurityName": "CHOKHANI INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE772F01015",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523248",
                "SecurityId": "MACPLASQ",
                "SecurityName": "MACHINO PLASTICS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE082B01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523252",
                "SecurityId": "DEWNTYR-B",
                "SecurityName": "DEWAN TYRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523260",
                "SecurityId": "PEARLPOLY",
                "SecurityName": "PEARL POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE844A01013",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523261",
                "SecurityId": "VENKYS",
                "SecurityName": "VENKY&#39;S (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE398A01010",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523263",
                "SecurityId": "GANGAPP",
                "SecurityName": "GANGADHARAM APPLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523267",
                "SecurityId": "ESSTE",
                "SecurityName": "ESSAR TELEHOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE317C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523269",
                "SecurityId": "ADVANIHOTR",
                "SecurityName": "ADVANI HOTELS & RESORTS (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE199C01026",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523273",
                "SecurityId": "SUPOFDM",
                "SecurityName": "SUPREME ORIENTED FILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE175E01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523277",
                "SecurityId": "GVFILM",
                "SecurityName": "GV FILMS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE395B01048",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523283",
                "SecurityId": "SUPERHOUSE",
                "SecurityName": "SUPERHOUSE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE712B01010",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523285",
                "SecurityId": "PRMRHS-B1DM",
                "SecurityName": "PREMIER HOUSING & INDUSTRIAL ENTERPRISE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523289",
                "SecurityId": "RAMAVISION",
                "SecurityName": "RAMA VISION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE763B01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523295",
                "SecurityId": "SUMANMOTEL",
                "SecurityName": "SUMAN MOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE723A01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523299",
                "SecurityId": "ROCKWOOL",
                "SecurityName": "ROCKWOOL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE301E01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523301",
                "SecurityId": "TCPLPACK",
                "SecurityName": "TCPL PACKAGING LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE822C01015",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523303",
                "SecurityId": "BESTE",
                "SecurityName": "BESTAVISION ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE423C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523307",
                "SecurityId": "PANASONIC",
                "SecurityName": "PANASONIC APPLIANCES INDIA COMPANY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE841C01015",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523309",
                "SecurityId": "SHRIGANG",
                "SecurityName": "SHRI GANG INDUSTRIES AND ALLIED PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE241V01018",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523315",
                "SecurityId": "PURITY",
                "SecurityName": "PURITY FLEX PACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE898O01010",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523317",
                "SecurityId": "NARANGIN",
                "SecurityName": "NARANG INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE172C01015",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523319",
                "SecurityId": "BALMLAWRIE",
                "SecurityName": "BALMER LAWRIE & CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE164A01016",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523321",
                "SecurityId": "KASHPAN",
                "SecurityName": "KASHIRAM PANNALAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523323",
                "SecurityId": "KOVAI",
                "SecurityName": "KOVAI MEDICAL CENTER & HOSPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE177F01017",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523325",
                "SecurityId": "GINNIFIL",
                "SecurityName": "GINNI FILAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE424C01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523327",
                "SecurityId": "RENEWPW",
                "SecurityName": "RENEWABLE POWER PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523329",
                "SecurityId": "ELDEHSG",
                "SecurityName": "ELDECO HOUSING & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE668G01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523331",
                "SecurityId": "DHARNOV",
                "SecurityName": "DHARNENDRA OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523333",
                "SecurityId": "POLARPH",
                "SecurityName": "POLAR PHARMA INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE145D01019",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523337",
                "SecurityId": "KUSHDIA",
                "SecurityName": "KUSHAL DIAMONDS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523341",
                "SecurityId": "RAJNPIP-B",
                "SecurityName": "RAJINDER PIPES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523343",
                "SecurityId": "MICROSE",
                "SecurityName": "MICROSE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE809F01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523351",
                "SecurityId": "STDSHOE",
                "SecurityName": "STANDARD SHOE SOLE AND MOULD (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE888N01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523353",
                "SecurityId": "CDRHLTH",
                "SecurityName": "CDR HEALTH CARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE369F01010",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523357",
                "SecurityId": "GUJJHM",
                "SecurityName": "GUJARAT JHM HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE596J01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523359",
                "SecurityId": "SHARPIND",
                "SecurityName": "SHARP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE013B01021",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523361",
                "SecurityId": "AGRITHA",
                "SecurityName": "AGRITECH HATCHERIES & FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523363",
                "SecurityId": "STERLINH",
                "SecurityName": "STERLING HOLIDAY RESORTS (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE657A01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523365",
                "SecurityId": "RKVNFLO",
                "SecurityName": "RIKVIN FLOORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523367",
                "SecurityId": "DCMSHRIRAM",
                "SecurityName": "DCM SHRIRAM LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE499A01024",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523369",
                "SecurityId": "DCMSRMIND",
                "SecurityName": "DCM SHRIRAM INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE843D01019",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523371",
                "SecurityId": "MAWANASUG",
                "SecurityName": "MAWANA SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE636A01039",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523373",
                "SecurityId": "MINID",
                "SecurityName": "MINI DIAMONDS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE281E01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523375",
                "SecurityId": "MODITHR",
                "SecurityName": "MODI TELE FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523377",
                "SecurityId": "GUJAP",
                "SecurityName": "APAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE372A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523382",
                "SecurityId": "MINISOFT",
                "SecurityName": "MINI SOFT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE178B01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523384",
                "SecurityId": "MAHAPEXLTD",
                "SecurityName": "MAHA RASHTRA APEX CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE843B01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523385",
                "SecurityId": "NILKAMAL",
                "SecurityName": "NILKAMAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE310A01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523387",
                "SecurityId": "TRITON",
                "SecurityName": "TRITON CORP.LTD.",
                "FaceValue": "1",
                "ISINNo": "INE982C01033",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523389",
                "SecurityId": "OXIDE",
                "SecurityName": "OXIDES & SPECIALITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE597D01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523391",
                "SecurityId": "NAHARPOLY",
                "SecurityName": "NAHAR POLYFILMS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE308A01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523395",
                "SecurityId": "3MINDIA",
                "SecurityName": "3M INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE470A01017",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523398",
                "SecurityId": "JCHAC",
                "SecurityName": "JOHNSON CONTROLS-HITACHI AIR CONDITIONING INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE782A01015",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523405",
                "SecurityId": "JMFINANCIL",
                "SecurityName": "JM FINANCIAL LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE780C01023",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523407",
                "SecurityId": "LANESEDA",
                "SecurityName": "LAN ESEDA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE520B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523409",
                "SecurityId": "HNDMGTDM",
                "SecurityName": "HINDUSTAN MAGNETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523411",
                "SecurityId": "ADCINDIA",
                "SecurityName": "ADC INDIA COMMUNICATIONS LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE833A01016",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523413",
                "SecurityId": "HMG",
                "SecurityName": "HMG INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523415",
                "SecurityId": "COVENTRY",
                "SecurityName": "COVENTRY COIL-O-MATIC (HARYANA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE964D01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523419",
                "SecurityId": "TNTELE",
                "SecurityName": "TAMILNADU TELECOMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE141D01018",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523421",
                "SecurityId": "AIRCOMI",
                "SecurityName": "AIRCOMMAND INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523423",
                "SecurityId": "RCSVANP",
                "SecurityName": "RCS VANASPATI INDUSTRY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523425",
                "SecurityId": "SUNRAJDI",
                "SecurityName": "SUNRAJ DIAMOND EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE459D01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523427",
                "SecurityId": "TITANFO",
                "SecurityName": "TITAN FOODS & FASHIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523429",
                "SecurityId": "BSAPPLI",
                "SecurityName": "B S APPLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE312A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523431",
                "SecurityId": "NWERURB",
                "SecurityName": "NEW ERA URBAN AMENITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523433",
                "SecurityId": "MGWLMAC",
                "SecurityName": "MIG-WELD & MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523435",
                "SecurityId": "POLMRMO",
                "SecurityName": "POLAR MARMO AGGLOMERATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523439",
                "SecurityId": "SHWALEL",
                "SecurityName": "SHAW WALLACE ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523441",
                "SecurityId": "VKRMPRJDM",
                "SecurityName": "VIKRAM PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523443",
                "SecurityId": "TMLNDHS",
                "SecurityName": "TAMILNAD HOSPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523445",
                "SecurityId": "RIIL",
                "SecurityName": "RELIANCE INDUSTRIAL INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE046A01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523447",
                "SecurityId": "ALSAMRN-B",
                "SecurityName": "ALSA MARINE & HARVESTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523449",
                "SecurityId": "SHARP",
                "SecurityName": "SHARP INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE207B01011",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523451",
                "SecurityId": "RADMULT",
                "SecurityName": "RADAN MULTIMEDIA LTD.",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523455",
                "SecurityId": "TECPO",
                "SecurityName": "TECHTRAN POLYLENSES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE020C01016",
                "Industry": "Healthcare Supplies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523457",
                "SecurityId": "LINDEINDIA",
                "SecurityName": "LINDE INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE473A01011",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523461",
                "SecurityId": "ELETROLUXK",
                "SecurityName": "ELECTROLUX KELVINATOR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE820A01021",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523465",
                "SecurityId": "INDBNK",
                "SecurityName": "IND BANK HOUSING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE969D01012",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523467",
                "SecurityId": "JAIMATAG",
                "SecurityName": "JAI MATA GLASS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE250C01027",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523469",
                "SecurityId": "RAVIHTC",
                "SecurityName": "RAVI HI-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523471",
                "SecurityId": "GUJSSPN",
                "SecurityName": "GUJARAT SUSPENSION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523475",
                "SecurityId": "LOTUSCHO",
                "SecurityName": "LOTUS CHOCOLATE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE026D01011",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523477",
                "SecurityId": "GUJRATGAS",
                "SecurityName": "GUJARAT GAS CO.LTD.",
                "FaceValue": "2",
                "ISINNo": "INE374A01029",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523483",
                "SecurityId": "PACIFICI",
                "SecurityName": "PACIFIC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE883C01025",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523489",
                "SecurityId": "CMMHOSP",
                "SecurityName": "CHENNAI MEENAKSHI MULTISPECIALITY HOSPITAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE889F01017",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523491",
                "SecurityId": "LUNRDMD",
                "SecurityName": "LUNAR DIAMONDS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523497",
                "SecurityId": "PAMDIDM",
                "SecurityName": "PAMPASAR DISTILLERY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE263D01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523501",
                "SecurityId": "VITLFOD",
                "SecurityName": "VITAL FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523505",
                "SecurityId": "GUJBXIT",
                "SecurityName": "GUJARAT BAUXITE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523511",
                "SecurityId": "AANKITG",
                "SecurityName": "AANKIT GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523513",
                "SecurityId": "VISHVEL",
                "SecurityName": "VISHVA ELECTRONICS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523515",
                "SecurityId": "SRLXMED",
                "SecurityName": "SURLUX MEDIQUIP LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523519",
                "SecurityId": "UNIOFFICE",
                "SecurityName": "UNIVERSAL OFFICE AUTOMATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE951C01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523523",
                "SecurityId": "RAINBOWPAP",
                "SecurityName": "RAINBOW PAPERS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE028D01025",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523525",
                "SecurityId": "SPPTROL",
                "SecurityName": "SPECIALITY PETROLUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523531",
                "SecurityId": "AVNCPRN",
                "SecurityName": "AVON INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523537",
                "SecurityId": "APMIN",
                "SecurityName": "APM INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE170D01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523539",
                "SecurityId": "PRECWIRE",
                "SecurityName": "PRECISION WIRES INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE372C01029",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523542",
                "SecurityId": "DYNALMP",
                "SecurityName": "DYNA LAMPS & GLASS WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523544",
                "SecurityId": "MONEL",
                "SecurityName": "MONICA ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE568C01014",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523548",
                "SecurityId": "HYDCONN",
                "SecurityName": "HYDERABAD CONNECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523550",
                "SecurityId": "KRYPTONQ",
                "SecurityName": "KRYPTON INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE951B01014",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523554",
                "SecurityId": "SSIND-B1",
                "SecurityName": "S&S INDUSTRIES & ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523558",
                "SecurityId": "SWISSMLTRY",
                "SecurityName": "SWISS MILITARY CONSUMER GOODS LTD",
                "FaceValue": "2",
                "ISINNo": "INE010C01025",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523564",
                "SecurityId": "MRGNIND",
                "SecurityName": "MORGAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE701E01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523566",
                "SecurityId": "MARBU",
                "SecurityName": "MARTIN BURN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE199D01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523568",
                "SecurityId": "CNRTSPC",
                "SecurityName": "CONCERT SPICES & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523572",
                "SecurityId": "INTRUBI",
                "SecurityName": "INTEGRATED RUBIAN EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE509D01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523574",
                "SecurityId": "FEL",
                "SecurityName": "FUTURE ENTERPRISES LTD",
                "FaceValue": "2",
                "ISINNo": "INE623B01027",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523576",
                "SecurityId": "FLAWLESD",
                "SecurityName": "FLAWLESS DIAMOND (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE345E01021",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523578",
                "SecurityId": "MURABLK",
                "SecurityName": "MURABLACK INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Carbon Black",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523580",
                "SecurityId": "SIPRD",
                "SecurityName": "S.I.PROPERTY DEVELOPMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE503E01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523586",
                "SecurityId": "INDTONER",
                "SecurityName": "INDIAN TONERS & DEVELOPERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE826B01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523592",
                "SecurityId": "JENSONICOL",
                "SecurityName": "JENSON & NICHOLSON (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE819B01021",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523594",
                "SecurityId": "KUNSTOFF",
                "SecurityName": "KUNSTSTOFFE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE638D01021",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523596",
                "SecurityId": "IRCONIN",
                "SecurityName": "IRCON INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523598",
                "SecurityId": "SCI",
                "SecurityName": "SHIPPING CORPORATION OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE109A01011",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523600",
                "SecurityId": "NORTECHDM",
                "SecurityName": "NORTECH INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523604",
                "SecurityId": "UNICORP-B",
                "SecurityName": "UNICORP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523606",
                "SecurityId": "SIKA",
                "SecurityName": "SIKA INTERPLANT SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE438E01016",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523610",
                "SecurityId": "ITI",
                "SecurityName": "ITI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE248A01017",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523612",
                "SecurityId": "DKCHEMO",
                "SecurityName": "D.K.CHEMO-PLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523616",
                "SecurityId": "RISHABH",
                "SecurityName": "RISHABH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523618",
                "SecurityId": "DREDGECORP",
                "SecurityName": "DREDGING CORPORATION OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE506A01018",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523620",
                "SecurityId": "PHRMASI",
                "SecurityName": "PHAARMASIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE486I01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523626",
                "SecurityId": "BELLCONT",
                "SecurityName": "BELLS CONTROLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE025C01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523628",
                "SecurityId": "PODDAR",
                "SecurityName": "PODDAR HOUSING AND DEVELOPMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE888B01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523630",
                "SecurityId": "NFL",
                "SecurityName": "NATIONAL FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE870D01012",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523632",
                "SecurityId": "UNIPORT",
                "SecurityName": "UNIPORT COMPUTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE491B01011",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523634",
                "SecurityId": "VASHISTIDET",
                "SecurityName": "VASHISTI DETERGENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE330A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523638",
                "SecurityId": "IPRINGLTD",
                "SecurityName": "IP RINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE558A01019",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523640",
                "SecurityId": "VENKYSI",
                "SecurityName": "VENKYS(IND)",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523642",
                "SecurityId": "PIIND",
                "SecurityName": "PI INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE603J01030",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523646",
                "SecurityId": "LIFELINDM",
                "SecurityName": "HALLMARK HEALTHCARE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523648",
                "SecurityId": "PLASTIBLEN",
                "SecurityName": "PLASTIBLENDS INDIA LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE083C01022",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523650",
                "SecurityId": "REDEXPR",
                "SecurityName": "REDEX PROTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE823D01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523652",
                "SecurityId": "KKPLASTICK",
                "SecurityName": "KKALPANA PLASTICK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE465K01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523654",
                "SecurityId": "UNILITE",
                "SecurityName": "UNILITE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523658",
                "SecurityId": "RISHIRUB",
                "SecurityName": "RISHIROOP RUBBER (INTERNATIONAL) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE887C01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523660",
                "SecurityId": "WATERBASE",
                "SecurityName": "WATERBASE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE054C01015",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523664",
                "SecurityId": "SITAPLY",
                "SecurityName": "SITAPUR PLYWOOD MANUFACTURERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523666",
                "SecurityId": "DEVFA",
                "SecurityName": "DEV FASTENERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE150E01017",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523670",
                "SecurityId": "NOIMC",
                "SecurityName": "NOIDA MEDICARE CENTRE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE740C01019",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523672",
                "SecurityId": "FLEXFO",
                "SecurityName": "FLEX FOODS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE954B01018",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523676",
                "SecurityId": "GOLKUNDIA",
                "SecurityName": "GOLKUNDA DIAMONDS & JEWELLERY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE798D01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523678",
                "SecurityId": "MALANLT",
                "SecurityName": "MALANPUR LEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523682",
                "SecurityId": "BTWIN",
                "SecurityName": "BTW INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE345C01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523688",
                "SecurityId": "KRYSTPL",
                "SecurityName": "KRYSTAL POLYFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523692",
                "SecurityId": "MSSHOES",
                "SecurityName": "MS SHOES EAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523694",
                "SecurityId": "APCOTEXIND",
                "SecurityName": "APCOTEX INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE116A01032",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523696",
                "SecurityId": "FORTISMLR",
                "SecurityName": "FORTIS MALAR HOSPITALS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE842B01015",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523698",
                "SecurityId": "MIDLANDP",
                "SecurityName": "MIDLAND PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE423F01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523702",
                "SecurityId": "PACKTEC",
                "SecurityName": "PACKTECH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523704",
                "SecurityId": "MASTEK",
                "SecurityName": "MASTEK LTD.",
                "FaceValue": "5",
                "ISINNo": "INE759A01021",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523708",
                "SecurityId": "EIMCOELECO",
                "SecurityName": "EIMCO ELECON (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE158B01016",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523710",
                "SecurityId": "SAYAJIHOTL",
                "SecurityName": "SAYAJI HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE318C01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523712",
                "SecurityId": "JMGCORP",
                "SecurityName": "JMG CORPORATION LTD.",
                "FaceValue": "2.5",
                "ISINNo": "INE745F01011",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523716",
                "SecurityId": "ASHIANA",
                "SecurityName": "ASHIANA HOUSING LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE365D01021",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523718",
                "SecurityId": "ASHOKFSDM",
                "SecurityName": "ASHOK FASHIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523722",
                "SecurityId": "SVAMSOF",
                "SecurityName": "SVAM SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE119B01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523724",
                "SecurityId": "VIJSHAN",
                "SecurityName": "VIJAY SHANTHI BUILDERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE806F01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523726",
                "SecurityId": "KHPAPER",
                "SecurityName": "KHAITAN PAPER MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523728",
                "SecurityId": "SIDDHATUBE",
                "SecurityName": "SIDDHARTHA TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE708B01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523732",
                "SecurityId": "ECOBOAR",
                "SecurityName": "ECOBOARD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE866A01016",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523736",
                "SecurityId": "DVL",
                "SecurityName": "DHUNSERI VENTURES LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE477B01010",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523738",
                "SecurityId": "ALACRIHS",
                "SecurityName": "ALACRITY HOUSING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE781D01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523752",
                "SecurityId": "IOSYSTEM",
                "SecurityName": "IO SYSTEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE502D01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523754",
                "SecurityId": "MAHEPC",
                "SecurityName": "MAHINDRA EPC IRRIGATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE215D01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523756",
                "SecurityId": "SREINFRA",
                "SecurityName": "SREI INFRASTRUCTURE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE872A01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523758",
                "SecurityId": "ITIL",
                "SecurityName": "INFORMATION TECHNOLOGIES (INDIA) LTD.",
                "FaceValue": "5",
                "ISINNo": "INE569A01024",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523760",
                "SecurityId": "SATNEXP",
                "SecurityName": "SATNAM EXPORTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523764",
                "SecurityId": "SAKURAS-B",
                "SecurityName": "SAKURA SEIMITSU INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523766",
                "SecurityId": "ICCON",
                "SecurityName": "ICCON OIL & SPECIALITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE929A01012",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523768",
                "SecurityId": "GUJBOROS",
                "SecurityName": "GUJARAT BOROSIL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE059C01022",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523770",
                "SecurityId": "IDISL",
                "SecurityName": "INTERGRATED DIGITAL INFO SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE762G01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523774",
                "SecurityId": "SSHELTR",
                "SecurityName": "SOUTHERN SHELTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE271F01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523778",
                "SecurityId": "YSPORCL",
                "SecurityName": "Y.S.PORCELAIN TUBES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523780",
                "SecurityId": "RASEXTR",
                "SecurityName": "RAS EXTRUSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE014F01020",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523782",
                "SecurityId": "MITSHI",
                "SecurityName": "MITSHI INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE844D01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523784",
                "SecurityId": "AVIKMRS",
                "SecurityName": "AVIKEM RESINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523788",
                "SecurityId": "INDRUBR",
                "SecurityName": "INDIA RUBBER LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523790",
                "SecurityId": "SHUKJEW",
                "SecurityName": "SHUKRA JEWELLERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE344E01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523792",
                "SecurityId": "MAZDALTD",
                "SecurityName": "MAZDA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE885E01034",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523794",
                "SecurityId": "GLOBT",
                "SecurityName": "GLOBAL INFRASTRUCTURE & TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE943A01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523796",
                "SecurityId": "VICEROY",
                "SecurityName": "VICEROY HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE048C01017",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523800",
                "SecurityId": "HANIIND",
                "SecurityName": "HANI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523802",
                "SecurityId": "GOMIND",
                "SecurityName": "GOM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE596D01013",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523804",
                "SecurityId": "JFLABS",
                "SecurityName": "J.F.LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE226C01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523806",
                "SecurityId": "GWALTRN",
                "SecurityName": "GWALIOR TRANSMISSION SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523808",
                "SecurityId": "BHORIND",
                "SecurityName": "BHOR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE160C01010",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523810",
                "SecurityId": "DIVINE",
                "SecurityName": "DIVINE MULTIMEDIA (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE611B01022",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523812",
                "SecurityId": "ARHTIND",
                "SecurityName": "ARHAT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523814",
                "SecurityId": "SRINSME",
                "SecurityName": "SRINIVASA SMELTERS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523820",
                "SecurityId": "NEOCORP",
                "SecurityName": "NEO CORP INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE851C01014",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523822",
                "SecurityId": "THPAPEX",
                "SecurityName": "THAPAR EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523824",
                "SecurityId": "DUPONTS",
                "SecurityName": "DUPONT SPORTSWEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523826",
                "SecurityId": "SOVERDIA",
                "SecurityName": "SOVEREIGN DIAMONDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE959D01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523828",
                "SecurityId": "MENONBE",
                "SecurityName": "MENON BEARINGS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE071D01033",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523830",
                "SecurityId": "FLEETWL",
                "SecurityName": "FLEETWELD (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523832",
                "SecurityId": "MUKATPIP",
                "SecurityName": "MUKAT PIPES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE862C01029",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523836",
                "SecurityId": "GUJRAFIA",
                "SecurityName": "GUJARAT RAFFIA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE610B01024",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523838",
                "SecurityId": "SIMPLEXINF",
                "SecurityName": "SIMPLEX INFRASTRUCTURES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE059B01024",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523840",
                "SecurityId": "INNOVTEC",
                "SecurityName": "INNOVATIVE TECH PACK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE965C01038",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523842",
                "SecurityId": "SUPTANERY",
                "SecurityName": "SUPER TANNERY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE460D01038",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523844",
                "SecurityId": "CONSTRONIC",
                "SecurityName": "CONSTRONICS INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE537B01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523846",
                "SecurityId": "SKYSS",
                "SecurityName": "SKYPAK SERVICE SPECIALIST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE539D01013",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523848",
                "SecurityId": "SRSHTSHDM",
                "SecurityName": "SRUSHTI SHOES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523850",
                "SecurityId": "AXTEL",
                "SecurityName": "AXTEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE767C01012",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523852",
                "SecurityId": "NUCORWR",
                "SecurityName": "NUCOR WIRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523854",
                "SecurityId": "ZSEEMEINDM",
                "SecurityName": "SEEMA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523856",
                "SecurityId": "TORRCABS",
                "SecurityName": "TORRENT CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE859B01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523858",
                "SecurityId": "ESSMCAT",
                "SecurityName": "ESSEM CATALYST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523860",
                "SecurityId": "GYANLTH",
                "SecurityName": "GYAN LEATHERBOARD LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523862",
                "SecurityId": "PIFL",
                "SecurityName": "PACHELI INDUSTRIAL FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE926B01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523864",
                "SecurityId": "DSQSOFT",
                "SecurityName": "DSQ SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE286A01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523872",
                "SecurityId": "MAGNA",
                "SecurityName": "MAGNA INDUSTRIES & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE113F01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523874",
                "SecurityId": "PRECISION",
                "SecurityName": "PRECISION CONTAINEURS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE191C01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523876",
                "SecurityId": "JYOTIOVR",
                "SecurityName": "JYOTI OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE432D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523878",
                "SecurityId": "TOTEX",
                "SecurityName": "TOTAL EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE109E01013",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523880",
                "SecurityId": "GUJSPLB",
                "SecurityName": "GUJARAT SPECIALITY LUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523882",
                "SecurityId": "MAGNSRB",
                "SecurityName": "MAGNUS RUBBER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523886",
                "SecurityId": "MICROINK",
                "SecurityName": "MICRO INKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE056A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523888",
                "SecurityId": "VRWODAR",
                "SecurityName": "V R WOODART LTD.",
                "FaceValue": "10",
                "ISINNo": "INE317D01014",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523890",
                "SecurityId": "DSKULKARNI",
                "SecurityName": "D.S.KULKARNI DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE891A01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523892",
                "SecurityId": "GEMEYDM",
                "SecurityName": "GEM EYEADORNS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE419B01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523894",
                "SecurityId": "CROWNTV",
                "SecurityName": "CROWN TELEVISION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523896",
                "SecurityId": "APIL",
                "SecurityName": "AVI PRODUCTS INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE316O01021",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523990",
                "SecurityId": "BHTERMI",
                "SecurityName": "BHARAT THERMITE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523994",
                "SecurityId": "INTRLNK",
                "SecurityName": "INTERLINK EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "523998",
                "SecurityId": "DATALIN",
                "SecurityName": "DATALINE & RESEARCH TECHNOLOGIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524000",
                "SecurityId": "MAGMA",
                "SecurityName": "MAGMA FINCORP LTD.",
                "FaceValue": "2",
                "ISINNo": "INE511C01022",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524003",
                "SecurityId": "GUJHITC",
                "SecurityName": "GUJARAT HIGH TECH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524011",
                "SecurityId": "GENOMICS",
                "SecurityName": "GENOMICS BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE974A01018",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524013",
                "SecurityId": "HINFLUR",
                "SecurityName": "HINDUSTAN FLUOROCARBONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE806J01013",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524019",
                "SecurityId": "KINGFA",
                "SecurityName": "KINGFA SCIENCE & TECHNOLOGY (INDIA) LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE473D01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524031",
                "SecurityId": "PATIDAR",
                "SecurityName": "PATIDAR BUILDCON LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE637N01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524037",
                "SecurityId": "RAMAPHO",
                "SecurityName": "RAMA PHOSPHATES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE809A01024",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524038",
                "SecurityId": "VENLONENT",
                "SecurityName": "VENLON ENTERPRISES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE204D01022",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524044",
                "SecurityId": "ZUNIALK",
                "SecurityName": "UNIALKEM FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524046",
                "SecurityId": "PETPLST",
                "SecurityName": "PET PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE704F01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524051",
                "SecurityId": "POLYPLEX",
                "SecurityName": "POLYPLEX CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE633B01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524055",
                "SecurityId": "PANIDPR",
                "SecurityName": "PANTHER INDUSTRIAL PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE181D01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524059",
                "SecurityId": "VARNPLY",
                "SecurityName": "VARUN POLYMOL ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524065",
                "SecurityId": "CHEMECHDM",
                "SecurityName": "CHEMECH LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524067",
                "SecurityId": "SRNEDYE",
                "SecurityName": "SERENE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524070",
                "SecurityId": "AMRACHEDM",
                "SecurityName": "AMRACHEDM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524075",
                "SecurityId": "ALBERTDA",
                "SecurityName": "ALBERT DAVID LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE155C01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524080",
                "SecurityId": "HARLETH",
                "SecurityName": "HARYANA LEATHER CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE681F01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524082",
                "SecurityId": "HARLPHR",
                "SecurityName": "HARLEYSTREET PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE344K01013",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524084",
                "SecurityId": "MONSANTO",
                "SecurityName": "MONSANTO INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE274B01011",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524087",
                "SecurityId": "UNICORN",
                "SecurityName": "UNICORN ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524091",
                "SecurityId": "ACRYSIL",
                "SecurityName": "ACRYSIL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE482D01024",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524099",
                "SecurityId": "JUPIBIOT",
                "SecurityName": "JUPITER BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE790E01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524103",
                "SecurityId": "LINEARIND",
                "SecurityName": "LINEAR INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE808D01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524105",
                "SecurityId": "SUMEXCH",
                "SecurityName": "SUMEX CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524107",
                "SecurityId": "ORGCHEM",
                "SecurityName": "ORGANIC CHEMOILS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524109",
                "SecurityId": "KABRAEXTRU",
                "SecurityName": "KABRA EXTRUSIONTECHNIK LTD.",
                "FaceValue": "5",
                "ISINNo": "INE900B01029",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524113",
                "SecurityId": "PHARMA-B1",
                "SecurityName": "PHARMACEUTICALS PRODUCTS OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524117",
                "SecurityId": "PROTCHEM",
                "SecurityName": "PROTCHEM INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE819D01019",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524119",
                "SecurityId": "KPLINT",
                "SecurityName": "KPL INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE174B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524121",
                "SecurityId": "INDMAIZ",
                "SecurityName": "INDIAN MAIZE & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524123",
                "SecurityId": "CEPHAMP",
                "SecurityName": "CEPHAM PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524125",
                "SecurityId": "THIRNCH",
                "SecurityName": "THIRNCH",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524129",
                "SecurityId": "VINYLINDIA",
                "SecurityName": "VINYL CHEMICALS (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE250B01029",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524136",
                "SecurityId": "PCCOSMA",
                "SecurityName": "PEE CEE COSMA SOPE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE417E01010",
                "Industry": "Household Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524138",
                "SecurityId": "MAHINCOMP",
                "SecurityName": "MAHINDRA COMPOSITES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE219G01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524140",
                "SecurityId": "CHDRGDM",
                "SecurityName": "CHEMINOR DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE088A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524144",
                "SecurityId": "BONANZA",
                "SecurityName": "BONANZA PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524152",
                "SecurityId": "BLUBPTR",
                "SecurityName": "BLUE BLENDS PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524156",
                "SecurityId": "TCMLMTD",
                "SecurityName": "TCM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE034F01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524160",
                "SecurityId": "SMZS",
                "SecurityName": "SMZS CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE894C01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524162",
                "SecurityId": "PFIMEXP",
                "SecurityName": "PFIMEX PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524163",
                "SecurityId": "ZSHRAYPE",
                "SecurityName": "ZSHRAYPE",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524164",
                "SecurityId": "IOLCP",
                "SecurityName": "IOL CHEMICALS & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE485C01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524166",
                "SecurityId": "WYTHLAB-B",
                "SecurityName": "WYTHLAB-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524168",
                "SecurityId": "TITACOM",
                "SecurityName": "TITANOR COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE244A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524170",
                "SecurityId": "DSQBIO",
                "SecurityName": "DSQ BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE964A01019",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524174",
                "SecurityId": "KESARPE",
                "SecurityName": "KESAR PETROPRODUCTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE133C01033",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524178",
                "SecurityId": "PNTXCHM",
                "SecurityName": "PAINTEX CHEMICALS (BOMBAY) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524194",
                "SecurityId": "ROCKHARD",
                "SecurityName": "ROCK HARD PETROCHEMICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE300D01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524196",
                "SecurityId": "BNZOPTR",
                "SecurityName": "BENZO PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524200",
                "SecurityId": "VINATIORGA",
                "SecurityName": "VINATI ORGANICS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE410B01037",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524202",
                "SecurityId": "LACTOSE",
                "SecurityName": "LACTOSE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE058I01013",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524204",
                "SecurityId": "TEEAI",
                "SecurityName": "TEESTA AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE757D01011",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524206",
                "SecurityId": "ASTDRUG",
                "SecurityName": "ASTRON DRUGS AND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524208",
                "SecurityId": "AARTIIND",
                "SecurityName": "AARTI INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE769A01020",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524210",
                "SecurityId": "PNTKYOR",
                "SecurityName": "PENTOKEY ORGANY (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE702E01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524212",
                "SecurityId": "WANBURY",
                "SecurityName": "WANBURY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE107F01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524214",
                "SecurityId": "KINGCHD",
                "SecurityName": "KINGS CHEMICALS & DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524218",
                "SecurityId": "RESONANCE",
                "SecurityName": "RESONANCE SPECIALTIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE486D01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524224",
                "SecurityId": "NPNTCHM",
                "SecurityName": "NARIMAN POINT CHEMICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524226",
                "SecurityId": "GAEL",
                "SecurityName": "GUJARAT AMBUJA EXPORTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE036B01030",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524230",
                "SecurityId": "RCF",
                "SecurityName": "RASHTRIYA CHEMICALS & FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE027A01015",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524232",
                "SecurityId": "MAHAPOL",
                "SecurityName": "MAHARASHTRA POLYBUTENES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE488E01037",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524237",
                "SecurityId": "ZMRPL(5)",
                "SecurityName": "ZMRPL(5)",
                "FaceValue": "5",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524238",
                "SecurityId": "GUJINJEC",
                "SecurityName": "GUJARAT INJECT (KERALA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE659F01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524240",
                "SecurityId": "SKGSOLV",
                "SecurityName": "SKG SOLVEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524244",
                "SecurityId": "FACT",
                "SecurityName": "FERTILIZERS & CHEMICALS TRAVANCORE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE188A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524246",
                "SecurityId": "PRDEPDRDM",
                "SecurityName": "PRADEEP DRUG CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524252",
                "SecurityId": "PRIMEPT",
                "SecurityName": "PRIME PETRO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE189F01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524254",
                "SecurityId": "KRSHPLS",
                "SecurityName": "KRISHNA PLASTO CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524256",
                "SecurityId": "DAURALA",
                "SecurityName": "DAURALA ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE775D01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524260",
                "SecurityId": "CONSOLFI",
                "SecurityName": "CONSOLIDATED FIBRES AND CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE311A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524264",
                "SecurityId": "UNIMERQ",
                "SecurityName": "UNIMERS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE980B01039",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524270",
                "SecurityId": "MAGNACOL",
                "SecurityName": "MAGNA COLORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE283N01017",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524272",
                "SecurityId": "CBONIND",
                "SecurityName": "CEBON INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524276",
                "SecurityId": "DUJODPPR",
                "SecurityName": "DUJODWALA PAPER CHEMICAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE990C01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524280",
                "SecurityId": "KOPRAN",
                "SecurityName": "KOPRAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE082A01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524288",
                "SecurityId": "AIMCOPEST",
                "SecurityName": "AIMCO PESTICIDES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE008B01013",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524290",
                "SecurityId": "VYSLIPH",
                "SecurityName": "VYSALI PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE153G01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524306",
                "SecurityId": "HILTINT",
                "SecurityName": "HILTON INTERMEDIATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524310",
                "SecurityId": "VBCIND",
                "SecurityName": "VBC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE809C01012",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524312",
                "SecurityId": "VIJAYCH",
                "SecurityName": "VIJAYSHREE CHEMICALS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524314",
                "SecurityId": "GUJTERC",
                "SecurityName": "GUJARAT TERCE LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE499G01013",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524318",
                "SecurityId": "PREYANS",
                "SecurityName": "PREYANSHU INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524322",
                "SecurityId": "KABRADG",
                "SecurityName": "KABRA DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE323K01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524324",
                "SecurityId": "SEYAIND",
                "SecurityName": "SEYA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE573R01012",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524330",
                "SecurityId": "JAYAGROGN",
                "SecurityName": "JAYANT AGRO-ORGANICS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE785A01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524332",
                "SecurityId": "BCLIL",
                "SecurityName": "BCL INDUSTRIES LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE412G01016",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524336",
                "SecurityId": "SHHARICH",
                "SecurityName": "SHREE HARI CHEMICALS EXPORT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE065E01017",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524338",
                "SecurityId": "MADHURE",
                "SecurityName": "MADHU REFOILS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524342",
                "SecurityId": "INDOBORAX",
                "SecurityName": "INDO BORAX & CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE803D01013",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524344",
                "SecurityId": "ELEGNPH",
                "SecurityName": "ELEGANT PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524346",
                "SecurityId": "ICATLYS",
                "SecurityName": "INTERNATIONAL CATALYSTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524348",
                "SecurityId": "AARTIDRUGS",
                "SecurityName": "AARTI DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE767A01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524350",
                "SecurityId": "NACROCH",
                "SecurityName": "NACRO CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524360",
                "SecurityId": "SOLARFM",
                "SecurityName": "SOLAR FARMACHEM LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524364",
                "SecurityId": "SPLPOLY",
                "SecurityName": "SPL POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE615D01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524368",
                "SecurityId": "MILEORG",
                "SecurityName": "MILESTONE ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524370",
                "SecurityId": "BODALCHEM",
                "SecurityName": "BODAL CHEMICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE338D01028",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524372",
                "SecurityId": "ORCHIDPHAR",
                "SecurityName": "ORCHID PHARMA LTD",
                "FaceValue": "10",
                "ISINNo": "INE191A01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524374",
                "SecurityId": "EPICENZY",
                "SecurityName": "EPIC ENZYMES PHARMACEUTICALS & INDUSTRIAL CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE278A01014",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524378",
                "SecurityId": "JMDEPACKR",
                "SecurityName": "JMDE PACKAGING & REALTIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE807C01024",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524380",
                "SecurityId": "JNAKINT",
                "SecurityName": "JNAKINT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524382",
                "SecurityId": "RINADRG",
                "SecurityName": "RATNA DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524384",
                "SecurityId": "OMNDE",
                "SecurityName": "OMNI DYE-CHEM EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE529C01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524386",
                "SecurityId": "AMINXCHDM",
                "SecurityName": "AMINXCHDM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524388",
                "SecurityId": "CRAZYINF",
                "SecurityName": "CRAZY INFOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE664B01021",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524390",
                "SecurityId": "MHNAVPH",
                "SecurityName": "MANAV PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524394",
                "SecurityId": "VIMTALABS",
                "SecurityName": "VIMTA LABS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE579C01029",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524396",
                "SecurityId": "BIOFILCHEM",
                "SecurityName": "BIOFIL CHEMICALS & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE829A01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524398",
                "SecurityId": "DMNIAPH",
                "SecurityName": "DAMANIA PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524400",
                "SecurityId": "ISHITADR",
                "SecurityName": "ISHITA DRUGS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE806D01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524402",
                "SecurityId": "MRUTIOR**",
                "SecurityName": "MARUTI ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524404",
                "SecurityId": "MARKSANS",
                "SecurityName": "MARKSANS PHARMA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE750C01026",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524406",
                "SecurityId": "OMEGALAB",
                "SecurityName": "OMEGA LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE006E01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524408",
                "SecurityId": "UNIVSTAR",
                "SecurityName": "UNIVERSAL STARCH-CHEM ALLIED LTD.",
                "FaceValue": "10",
                "ISINNo": "INE113E01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524410",
                "SecurityId": "SRHHL",
                "SecurityName": "SRHHL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE724B01023",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524412",
                "SecurityId": "AREYDRG",
                "SecurityName": "AAREY DRUGS & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE198H01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524414",
                "SecurityId": "NORRIS",
                "SecurityName": "NORRIS MEDICINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE744C01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524418",
                "SecurityId": "CDRMEDI",
                "SecurityName": "CDR MEDICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524424",
                "SecurityId": "HITCHDR",
                "SecurityName": "HI-TECH DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524426",
                "SecurityId": "SILCAL",
                "SecurityName": "SILCAL METALLURGICAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545D01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524432",
                "SecurityId": "KARLECH",
                "SecurityName": "KARNAV LEATHER CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524434",
                "SecurityId": "ASINPET",
                "SecurityName": "ASIAN PETROPRODUCTS & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE810M01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524438",
                "SecurityId": "ZORAPHA",
                "SecurityName": "ZORA PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524440",
                "SecurityId": "CAMEXLTD",
                "SecurityName": "CAMEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE198C01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524442",
                "SecurityId": "RAHICEM",
                "SecurityName": "RAHI CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524444",
                "SecurityId": "EVEXIA",
                "SecurityName": "EVEXIA LIFECARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE313M01014",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524446",
                "SecurityId": "SABERORGAN",
                "SecurityName": "SABERO ORGANICS GUJARAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE243A01018",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524448",
                "SecurityId": "AHLCONQ",
                "SecurityName": "AHLCON PARENTERALS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE027C01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524450",
                "SecurityId": "ORIACID",
                "SecurityName": "ORION ACIDS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524452",
                "SecurityId": "SUNSL",
                "SecurityName": "SUNSTAR LUBRICANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE120C01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524456",
                "SecurityId": "CUREREM",
                "SecurityName": "CUREFAST REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524458",
                "SecurityId": "INDOEURO",
                "SecurityName": "INDO EURO INDCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE319N01019",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524460",
                "SecurityId": "RICHLNP",
                "SecurityName": "RICHLINE PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524462",
                "SecurityId": "PAMDRGS",
                "SecurityName": "PAAM DRUGS & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524464",
                "SecurityId": "CHEMLAB-B",
                "SecurityName": "CHEMOX LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524466",
                "SecurityId": "COREORG",
                "SecurityName": "CORE ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524468",
                "SecurityId": "WHITEPR",
                "SecurityName": "WHITE WAY PRODUCTS (PHARMA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524470",
                "SecurityId": "SYNCOMF",
                "SecurityName": "SYNCOM FORMULATIONS (INDIA) LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE312C01025",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524474",
                "SecurityId": "HITECOR",
                "SecurityName": "HITECH ORGOCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524476",
                "SecurityId": "ZILLOPH",
                "SecurityName": "ZILLION PHARMACHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE845F01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524478",
                "SecurityId": "ESSPHRM",
                "SecurityName": "ESSKAY PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524480",
                "SecurityId": "RIDDHI",
                "SecurityName": "RIDDHI SIDDHI GLUCO BIOLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE249D01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524482",
                "SecurityId": "SHILPLA",
                "SecurityName": "SHILPAX LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524484",
                "SecurityId": "TRAMEDI",
                "SecurityName": "TRANS MEDICARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE358P01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524486",
                "SecurityId": "STLBNCH",
                "SecurityName": "STILBENE CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524488",
                "SecurityId": "SVCIND",
                "SecurityName": "SVC INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE038B01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524494",
                "SecurityId": "IPCALAB",
                "SecurityName": "IPCA LABORATORIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE571A01020",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524496",
                "SecurityId": "NEWRLDM",
                "SecurityName": "NEW WORLD MEDICAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524498",
                "SecurityId": "MRCRYPH",
                "SecurityName": "MERCURY PHYTOCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524500",
                "SecurityId": "KILITCH",
                "SecurityName": "KILITCH DRUGS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE729D01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524502",
                "SecurityId": "RAAJMEDI",
                "SecurityName": "RAAJ MEDISAFE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE548H01015",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524504",
                "SecurityId": "REVAORG",
                "SecurityName": "REVATI ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE270D01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524506",
                "SecurityId": "CORALAB",
                "SecurityName": "CORAL LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE683E01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524508",
                "SecurityId": "VORLADM",
                "SecurityName": "VORIN LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE331D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524510",
                "SecurityId": "RENCHEM",
                "SecurityName": "RENCAL CHEMICALS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524512",
                "SecurityId": "BOMDRDM",
                "SecurityName": "BOMBAY DRUGS & PHARMAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE002C01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524514",
                "SecurityId": "TULASEEBIOE",
                "SecurityName": "TULASEE BIO-ETHANOL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE276N01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524516",
                "SecurityId": "BACPHAR",
                "SecurityName": "BACIL PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE711D01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524518",
                "SecurityId": "KREBSBIO",
                "SecurityName": "KREBS BIOCHEMICALS & INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE268B01013",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524520",
                "SecurityId": "KMCSHIL",
                "SecurityName": "KMC SPECIALITY HOSPITALS (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE879K01018",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524522",
                "SecurityId": "LAFFANSQ",
                "SecurityName": "LAFFANS PETROCHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE919B01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524528",
                "SecurityId": "VELVINT",
                "SecurityName": "VELVETTE INTERNATIONAL PHARMA PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE042F01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524532",
                "SecurityId": "EARNHEA-B",
                "SecurityName": "EARNEST HEALTHCARE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524534",
                "SecurityId": "BHASKAGR",
                "SecurityName": "BHASKAR AGROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE972C01018",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524536",
                "SecurityId": "SUNSTAR",
                "SecurityName": "SUN STAR CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524538",
                "SecurityId": "ALLRESN",
                "SecurityName": "ALLIED RESINS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524540",
                "SecurityId": "SECHE",
                "SecurityName": "SECUNDERABAD HEALTHCARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE930C01016",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524542",
                "SecurityId": "SUKHJITS",
                "SecurityName": "SUKHJIT STARCH & CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE450E01011",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524546",
                "SecurityId": "SHABCHM",
                "SecurityName": "SHABA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE06DM01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524548",
                "SecurityId": "SHARMEH",
                "SecurityName": "SHARMA EAST INDIA HOSPITALS & MEDICAL RESEARCH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE465H01012",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524552",
                "SecurityId": "SHASUNPHAR",
                "SecurityName": "SHASUN PHARMACEUTICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE317A01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524554",
                "SecurityId": "ANILALM",
                "SecurityName": "MSD INDUSTRIAL ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524556",
                "SecurityId": "MAHACHMDM",
                "SecurityName": "MAHA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524558",
                "SecurityId": "NEULANDLAB",
                "SecurityName": "NEULAND LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE794A01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524564",
                "SecurityId": "GAYATRIBI",
                "SecurityName": "GAYATRI BIOORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE052E01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524566",
                "SecurityId": "SHAPCHMDM",
                "SecurityName": "SHAPER CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524568",
                "SecurityId": "ECLATCH",
                "SecurityName": "ECLAT CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524570",
                "SecurityId": "PODARPIGQ",
                "SecurityName": "PODDAR PIGMENTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE371C01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524572",
                "SecurityId": "PHARMAID",
                "SecurityName": "PHARMAIDS PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE117D01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524574",
                "SecurityId": "RAVIINF",
                "SecurityName": "RAVISH INFUSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524576",
                "SecurityId": "VIVIDIND",
                "SecurityName": "VIVID GLOBAL INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE737C01023",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524578",
                "SecurityId": "DOLPLAB",
                "SecurityName": "DOLPHIN LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE840C01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524580",
                "SecurityId": "PRIYALT",
                "SecurityName": "PRIYA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE686C01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524582",
                "SecurityId": "TIRUSTA",
                "SecurityName": "TIRUPATI STARCH & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE314D01011",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524584",
                "SecurityId": "KANODYE",
                "SecurityName": "KANORIA DYCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524586",
                "SecurityId": "QUAPHAMDM",
                "SecurityName": "QUALITY PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524588",
                "SecurityId": "EMEDTECH",
                "SecurityName": "EMED.COM TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE379F01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524590",
                "SecurityId": "HEMORGANIC",
                "SecurityName": "HEMO ORGANIC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE422G01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524592",
                "SecurityId": "JDORGOCHEM",
                "SecurityName": "JD ORGOCHEM LTD.",
                "FaceValue": "1",
                "ISINNo": "INE263B01022",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524594",
                "SecurityId": "ASHOKALC",
                "SecurityName": "ASHOK ALCO-CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE994D01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524596",
                "SecurityId": "ALCORDM",
                "SecurityName": "ALCHEMIE ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE768A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524598",
                "SecurityId": "AKSCHEM",
                "SecurityName": "AKSHARCHEM (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE542B01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524600",
                "SecurityId": "FCLTECH",
                "SecurityName": "FCL TECHNOLOGIES & PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE145B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524602",
                "SecurityId": "SHIVMED",
                "SecurityName": "SHIVA MEDICARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE875N01028",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524604",
                "SecurityId": "KAMRLAB",
                "SecurityName": "KAMRON LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE276T01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524606",
                "SecurityId": "BERLDRG",
                "SecurityName": "BERYL DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE415H01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524610",
                "SecurityId": "RATHIGRA",
                "SecurityName": "RATHI GRAPHIC TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE886C01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524612",
                "SecurityId": "STELADG",
                "SecurityName": "STELLAR DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524614",
                "SecurityId": "INDXTRA",
                "SecurityName": "IEL LTD",
                "FaceValue": "10",
                "ISINNo": "INE056E01016",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524616",
                "SecurityId": "PASUMIR",
                "SecurityName": "PASUMAI IRRIGATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524622",
                "SecurityId": "ISTRNETWK",
                "SecurityName": "ISTREET NETWORK LIMITED",
                "FaceValue": "4",
                "ISINNo": "INE532B01020",
                "Industry": "Internet & Catalogue Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524624",
                "SecurityId": "GAGAN",
                "SecurityName": "GAGAN GASES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE076D01016",
                "Industry": "Integrated Oil & Gas",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524626",
                "SecurityId": "SRIMANP",
                "SecurityName": "SRIMAN PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524628",
                "SecurityId": "PARKERAC",
                "SecurityName": "PARKER AGROCHEM EXPORTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE750B01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524630",
                "SecurityId": "IOTACH",
                "SecurityName": "IOTA CHEMICULTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524632",
                "SecurityId": "SHUKRAPHAR",
                "SecurityName": "SHUKRA PHARMACEUTICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE551C01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524634",
                "SecurityId": "ALUFLUOR",
                "SecurityName": "ALUFLUORIDE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE058F01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524636",
                "SecurityId": "SSORGS",
                "SecurityName": "SS ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE102E01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524640",
                "SecurityId": "ARCHITORG",
                "SecurityName": "ARCHIT ORGANOSYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE078I01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524642",
                "SecurityId": "SIKOZY",
                "SecurityName": "SIKOZY REALTORS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE528E01022",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524644",
                "SecurityId": "STERLCH",
                "SecurityName": "STERLING BASIC ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524646",
                "SecurityId": "VITACHM-B",
                "SecurityName": "VITARA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524648",
                "SecurityId": "INDOAMIN",
                "SecurityName": "INDO AMINES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE760F01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524650",
                "SecurityId": "NARMADACHEM",
                "SecurityName": "NARMADA CHEMATUR PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE693B01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524652",
                "SecurityId": "INDSWFTLTD",
                "SecurityName": "IND-SWIFT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE788B01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524654",
                "SecurityId": "NATCAPSUQ",
                "SecurityName": "NATURAL CAPSULES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE936B01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524661",
                "SecurityId": "WELCURE",
                "SecurityName": "WELCURE DRUGS & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE331C01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524663",
                "SecurityId": "BIBCL",
                "SecurityName": "BHARAT IMMUNOLOGICALS & BIOLOGICALS CORPORATION LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE994B01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524665",
                "SecurityId": "ADDLP",
                "SecurityName": "ADD-LIFE PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE124C01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524667",
                "SecurityId": "SOTL",
                "SecurityName": "SAVITA OIL TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE035D01012",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524669",
                "SecurityId": "HESTERBIO",
                "SecurityName": "HESTER BIOSCIENCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE782E01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524675",
                "SecurityId": "KABSON",
                "SecurityName": "KABSONS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE645C01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524677",
                "SecurityId": "UPLIMEC",
                "SecurityName": "U.P.LIME-CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524679",
                "SecurityId": "HINCHEM",
                "SecurityName": "HINDUSTAN INDUSTRIAL CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524683",
                "SecurityId": "METROCHE",
                "SecurityName": "METROCHEM INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE732B01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524685",
                "SecurityId": "KANVHYD",
                "SecurityName": "KANVA HYDRO-CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524687",
                "SecurityId": "BASANTGL",
                "SecurityName": "BASANT AGRO TECH (INDIA) LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE473E01021",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524689",
                "SecurityId": "PARENTLD",
                "SecurityName": "PARENTERAL DRUGS (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE904D01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524691",
                "SecurityId": "RSPETRO",
                "SecurityName": "R.S.PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524695",
                "SecurityId": "ASIANFR",
                "SecurityName": "ASIAN FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE01OY01018",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524697",
                "SecurityId": "PIONEPD",
                "SecurityName": "PIONEER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524699",
                "SecurityId": "KILBURNC",
                "SecurityName": "KILBURN CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE655C01027",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524701",
                "SecurityId": "KEDIACHE",
                "SecurityName": "KEDIA CHEMICAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE514D01016",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524703",
                "SecurityId": "SANDUPHQ",
                "SecurityName": "SANDU PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE751D01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524709",
                "SecurityId": "NACLIND",
                "SecurityName": "NACL INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE295D01020",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524711",
                "SecurityId": "VISTAPH",
                "SecurityName": "VISTA PHARMACEUTICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE427C01021",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524715",
                "SecurityId": "SUNPHARMA",
                "SecurityName": "SUN PHARMACEUTICAL INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE044A01036",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524717",
                "SecurityId": "TITANBIO",
                "SecurityName": "TITAN BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE150C01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524719",
                "SecurityId": "SOCRUSBIO",
                "SecurityName": "SOCRUS BIO SCIENCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE858A01039",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524721",
                "SecurityId": "TRIAFIN",
                "SecurityName": "TRIA FINE-CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524723",
                "SecurityId": "BIJHANS",
                "SecurityName": "BIJOY HANS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE491D01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524725",
                "SecurityId": "BHUTI",
                "SecurityName": "BHUVAN TRIPURA INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE604B01027",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524727",
                "SecurityId": "SDL",
                "SecurityName": "SPAN DIVERGENT LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE004E01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524729",
                "SecurityId": "MERVEND",
                "SecurityName": "MERVEN DRUG PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524731",
                "SecurityId": "JENBURPH",
                "SecurityName": "JENBURKT PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE354A01013",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524735",
                "SecurityId": "HIKAL",
                "SecurityName": "HIKAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE475B01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524737",
                "SecurityId": "BENZOCH",
                "SecurityName": "BENZO PETRO INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE981M01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524741",
                "SecurityId": "KPGELATDM",
                "SecurityName": "KPGELATDM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524742",
                "SecurityId": "CAPPL",
                "SecurityName": "CAPLIN POINT LABORATORIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE475E01026",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524743",
                "SecurityId": "FISCHER",
                "SecurityName": "FISCHER CHEMIC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE771F01025",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524746",
                "SecurityId": "SIRISLT-B",
                "SecurityName": "SIRIS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524748",
                "SecurityId": "LINKPH",
                "SecurityName": "LINK PHARMA CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE302F01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524750",
                "SecurityId": "BHASIDM",
                "SecurityName": "BHARAT STARCH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE030D01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524752",
                "SecurityId": "DECIPHER",
                "SecurityName": "DECIPHER LABS LTD",
                "FaceValue": "10",
                "ISINNo": "INE643N01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524754",
                "SecurityId": "GUJMEDI",
                "SecurityName": "GUJARAT MEDITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE010M01016",
                "Industry": "Healthcare Supplies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524756",
                "SecurityId": "EMAAIDG",
                "SecurityName": "EMAAI PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524758",
                "SecurityId": "WINTAC",
                "SecurityName": "WINTAC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE812C01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524760",
                "SecurityId": "ARVINDIN",
                "SecurityName": "ARVIND INTERNATIONAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE512C01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524762",
                "SecurityId": "NOVASUR",
                "SecurityName": "NOVA SURGIKOS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524764",
                "SecurityId": "NUTRA",
                "SecurityName": "NUTRAPLUS INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE230G01020",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524768",
                "SecurityId": "EMMESSA",
                "SecurityName": "EMMESSAR BIOTECH & NUTRITION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE634B01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524770",
                "SecurityId": "MAYARAS",
                "SecurityName": "MAYA RASAYAN LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524772",
                "SecurityId": "LAMEDICDM",
                "SecurityName": "LA MEDICAL DEVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524774",
                "SecurityId": "NGLFINE",
                "SecurityName": "NGL FINE-CHEM LTD.",
                "FaceValue": "5",
                "ISINNo": "INE887E01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524778",
                "SecurityId": "ANMOLDG",
                "SecurityName": "ANMOL DRUGS & PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524780",
                "SecurityId": "BAGRICH",
                "SecurityName": "BAGRI MINERALS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524782",
                "SecurityId": "FINEDRUG",
                "SecurityName": "FINE DRUGS AND CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE407D01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524784",
                "SecurityId": "SUPHA",
                "SecurityName": "SUPRIYA PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE647C01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524786",
                "SecurityId": "TIPHARM",
                "SecurityName": "T.I.PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524788",
                "SecurityId": "ELDERPG",
                "SecurityName": "ELDER PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE975E01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524790",
                "SecurityId": "EVERESTO",
                "SecurityName": "EVEREST ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE334C01029",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524792",
                "SecurityId": "PCICHEM",
                "SecurityName": "P.C.I.CHEMICALS AND PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524794",
                "SecurityId": "MATRIXLAB",
                "SecurityName": "MATRIX LABORATORIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE604D01023",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524796",
                "SecurityId": "VARDHCH",
                "SecurityName": "VARDHAMAN LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE503F01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524798",
                "SecurityId": "PALMACH",
                "SecurityName": "PALMACH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524800",
                "SecurityId": "HEMPECH",
                "SecurityName": "HEMI PETRO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524802",
                "SecurityId": "VEROLAB",
                "SecurityName": "VERONICA LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE994A01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524804",
                "SecurityId": "AUROPHARMA",
                "SecurityName": "AUROBINDO PHARMA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE406A01037",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524806",
                "SecurityId": "CLARICH",
                "SecurityName": "CLARISIS ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE654F01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524808",
                "SecurityId": "PHYTO",
                "SecurityName": "PHYTO CHEM (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE037C01010",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524810",
                "SecurityId": "CEPMS",
                "SecurityName": "CEPHAM MILK SPECIALITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE359C01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524812",
                "SecurityId": "EMERGY",
                "SecurityName": "EMERGY PHAARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE586C01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524814",
                "SecurityId": "MARTICH",
                "SecurityName": "MARUTI INDUSTRIAL CARBOHYDRATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524816",
                "SecurityId": "NATCOPHARM",
                "SecurityName": "NATCO PHARMA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE987B01026",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524818",
                "SecurityId": "DYNAMIND",
                "SecurityName": "DYNAMIC INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE457C01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524820",
                "SecurityId": "PANAMAPET",
                "SecurityName": "PANAMA PETROCHEM LTD.",
                "FaceValue": "2",
                "ISINNo": "INE305C01029",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524822",
                "SecurityId": "UMEDXPH",
                "SecurityName": "UMEDIX INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524824",
                "SecurityId": "BALPHARMA",
                "SecurityName": "BAL PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE083D01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524826",
                "SecurityId": "JUPITER",
                "SecurityName": "JUPITER BIOSCIENCE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE918B01013",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524828",
                "SecurityId": "BDH",
                "SecurityName": "BDH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE278D01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "524830",
                "SecurityId": "ELDERHCL",
                "SecurityName": "ELDER HEALTH CARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE950E01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526001",
                "SecurityId": "JAUSPOL",
                "SecurityName": "JAUSS POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE593O01017",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526003",
                "SecurityId": "ALPSLAB",
                "SecurityName": "ALPS LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526007",
                "SecurityId": "TUBTECS",
                "SecurityName": "TUBETEC SEAMLESS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526009",
                "SecurityId": "PROCAL",
                "SecurityName": "PROCAL ELECTRONICS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE700B01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526015",
                "SecurityId": "KEMROCK",
                "SecurityName": "KEMROCK INDUSTRIES & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE990B01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526019",
                "SecurityId": "CHERCON",
                "SecurityName": "CHERAN CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526021",
                "SecurityId": "INDPROG",
                "SecurityName": "INDUSTRIAL PROGRESSIVE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526023",
                "SecurityId": "PRUDPOL",
                "SecurityName": "PRUDENTIAL POLYWEBS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526025",
                "SecurityId": "GLOBUSCON",
                "SecurityName": "GLOBUS CONSTRUCTORS & DEVELOPERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE064L01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526027",
                "SecurityId": "CUBEXTUB",
                "SecurityName": "CUBEX TUBINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE144D01012",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526031",
                "SecurityId": "ASAGALI",
                "SecurityName": "ASIAN AGALITE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526033",
                "SecurityId": "CRYSS",
                "SecurityName": "CRYSTAL SOFTWARE SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE665B01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526035",
                "SecurityId": "JILICHL",
                "SecurityName": "JILICHEM LABORATORIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526039",
                "SecurityId": "SARIPNT",
                "SecurityName": "SARIKA PAINTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526041",
                "SecurityId": "RAMLEAT",
                "SecurityName": "RAM LEATHER EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526043",
                "SecurityId": "POLYCHMP",
                "SecurityName": "POLYMECHPLAST MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE809B01014",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526045",
                "SecurityId": "LUMITECH",
                "SecurityName": "LUMINAIRE TECHNOLOGIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE682C01021",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526049",
                "SecurityId": "SHLAKSHMI",
                "SecurityName": "SHRI LAKSHMI COTSYN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE851B01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526051",
                "SecurityId": "DECCNPL",
                "SecurityName": "DECCNPL",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526053",
                "SecurityId": "POINTPL",
                "SecurityName": "POINT PLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE494E01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526055",
                "SecurityId": "KRMINT.",
                "SecurityName": "KRM INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526059",
                "SecurityId": "NAMASTEXP",
                "SecurityName": "NAMASTE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE583A01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526061",
                "SecurityId": "NOVAINT",
                "SecurityName": "NOVA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526063",
                "SecurityId": "MAYOH",
                "SecurityName": "MAYO HOSPITALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE263C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526065",
                "SecurityId": "TKNOMIN",
                "SecurityName": "TEKNOMIN AQUA EXPORTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526067",
                "SecurityId": "KCCLPLASTC",
                "SecurityName": "KCCL PLASTIC LTD.",
                "FaceValue": "2",
                "ISINNo": "INE100L01025",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526069",
                "SecurityId": "ICICONE",
                "SecurityName": "ICICON ELECTRONICS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526071",
                "SecurityId": "STELLANT",
                "SecurityName": "STELLANT SECURITIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE395F01023",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526073",
                "SecurityId": "GALXBRG",
                "SecurityName": "GALAXY BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE020S01012",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526075",
                "SecurityId": "VINRKLB",
                "SecurityName": "REKVINA LABORATORIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE092O01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526077",
                "SecurityId": "ACELABO-B",
                "SecurityName": "ACE LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526079",
                "SecurityId": "HANSFLN",
                "SecurityName": "HANSAFLON PLASTO CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526081",
                "SecurityId": "SCAGRO",
                "SecurityName": "SC AGROTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE895E01017",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526083",
                "SecurityId": "SUTHTWNDM",
                "SecurityName": "SOUTHERN TOWNSHIP PROMOTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526085",
                "SecurityId": "SGARRES",
                "SecurityName": "SAGAR TOURIST RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE01M801019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526087",
                "SecurityId": "SHEZLEADM",
                "SecurityName": "SHEZ LEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526089",
                "SecurityId": "ROOFITIND",
                "SecurityName": "ROOFIT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE743A01017",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526091",
                "SecurityId": "RANCC",
                "SecurityName": "RANE COMPUTERS CONSULTANCY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE749C01010",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526093",
                "SecurityId": "SATHAISPAT",
                "SecurityName": "SATHAVAHANA ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE176C01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526095",
                "SecurityId": "RALEGRA",
                "SecurityName": "RAVILEELA GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE427E01027",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526099",
                "SecurityId": "SIMCOIN",
                "SecurityName": "SIMCO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526109",
                "SecurityId": "PRICOL",
                "SecurityName": "PRICOL   LTD",
                "FaceValue": "1",
                "ISINNo": "INE605A01026",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526111",
                "SecurityId": "DGIFLEX",
                "SecurityName": "DIGIFLEX INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526113",
                "SecurityId": "UNRYLMA",
                "SecurityName": "UNIROYAL MARINE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE602H01010",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526115",
                "SecurityId": "KARANWO",
                "SecurityName": "KARAN WOO-SIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE327D01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526117",
                "SecurityId": "SHERVANI",
                "SecurityName": "SHERVANI INDUSTRIAL SYNDICATE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE011D01013",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526119",
                "SecurityId": "INDAMOP",
                "SecurityName": "INDO AMERICAN OPTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526123",
                "SecurityId": "LYNXOPT",
                "SecurityName": "LYNX OPTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526125",
                "SecurityId": "ARHNTTO",
                "SecurityName": "ARIHANT TOURNESOL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE00HZ01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526133",
                "SecurityId": "SUPERTEX",
                "SecurityName": "SUPERTEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE881B01054",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526137",
                "SecurityId": "SHETR",
                "SecurityName": "SHETRON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE278C01010",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526139",
                "SecurityId": "TRABI",
                "SecurityName": "TRANSGENE BIOTEK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE773D01018",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526141",
                "SecurityId": "COMDI",
                "SecurityName": "COMPACT DISC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE821B01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526143",
                "SecurityId": "MPL",
                "SecurityName": "MPL PLASTICS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE343A01016",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526145",
                "SecurityId": "INTCHIT",
                "SecurityName": "INTECH INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526147",
                "SecurityId": "CONSCON",
                "SecurityName": "CONSOLIDATED CONTAINERS (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526155",
                "SecurityId": "APEXINT",
                "SecurityName": "APEX INTERTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526159",
                "SecurityId": "NIKHILAD",
                "SecurityName": "NIKHIL ADHESIVES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE926C01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526161",
                "SecurityId": "SPENTA",
                "SecurityName": "SPENTA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE175C01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526163",
                "SecurityId": "MRNCRGO",
                "SecurityName": "MARINE CARGO COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526165",
                "SecurityId": "IGGIRES-B",
                "SecurityName": "IGGI RESORTS INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526167",
                "SecurityId": "INDBGEM",
                "SecurityName": "INDO BOSCH GEMS AND JEWELLERY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526169",
                "SecurityId": "MULTIBASE",
                "SecurityName": "MULTIBASE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE678F01014",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526171",
                "SecurityId": "VIRALSYN",
                "SecurityName": "VIRAL SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE415C01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526173",
                "SecurityId": "ANDREWYU",
                "SecurityName": "ANDREW YULE & COMPANY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE449C01025",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526177",
                "SecurityId": "ADAMCO",
                "SecurityName": "ADAM COMSOF LTD.",
                "FaceValue": "10",
                "ISINNo": "INE272A01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526179",
                "SecurityId": "LUDLOWJUT",
                "SecurityName": "LUDLOW JUTE & SPECIALITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE983C01015",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526185",
                "SecurityId": "INTLHOME",
                "SecurityName": "INTERNATIONAL HOMETEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE315D01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526187",
                "SecurityId": "ASHRAM",
                "SecurityName": "ASHRAM ONLINE.COM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE293C01019",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526189",
                "SecurityId": "AIRCTEC",
                "SecurityName": "AIRCOMMAND AIRTECHNICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526191",
                "SecurityId": "COSMLEA",
                "SecurityName": "COSMOS LEATHER EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526193",
                "SecurityId": "ROYALCU",
                "SecurityName": "ROYAL CUSHION VINYL PRODUCTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE618A01011",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526195",
                "SecurityId": "NOGMIND",
                "SecurityName": "NEOGEM INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE552E01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526197",
                "SecurityId": "CITYLIF",
                "SecurityName": "CITY LIFTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526205",
                "SecurityId": "SCURIND",
                "SecurityName": "SECUR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526207",
                "SecurityId": "DCLMRTC",
                "SecurityName": "DCL MARITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526209",
                "SecurityId": "KSOILS",
                "SecurityName": "K.S.OILS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE727D01022",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526211",
                "SecurityId": "SURJIND",
                "SecurityName": "SURAJ INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE170U01011",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526215",
                "SecurityId": "CARBINDDM",
                "SecurityName": "CARBON COMPOSITES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526217",
                "SecurityId": "HITECHCORP",
                "SecurityName": "HITECH CORPORATION LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE120D01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526225",
                "SecurityId": "BLOOM",
                "SecurityName": "BLOOM DEKOR LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE253C01013",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526227",
                "SecurityId": "FILATEX",
                "SecurityName": "FILATEX INDIA LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE816B01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526229",
                "SecurityId": "JAYABUSDM",
                "SecurityName": "JAYANTI BUSINESS MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526231",
                "SecurityId": "STDSFAC",
                "SecurityName": "STANDARD SURFACTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE307D01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526235",
                "SecurityId": "MERCATOR",
                "SecurityName": "MERCATOR LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE934B01028",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526237",
                "SecurityId": "MORGAN",
                "SecurityName": "MORGAN VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE902C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526239",
                "SecurityId": "ARNPROC",
                "SecurityName": "ARUN PROCESSORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526241",
                "SecurityId": "AMRAPLIN",
                "SecurityName": "AMRAPALI INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE762C01021",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526243",
                "SecurityId": "BHAGWGL",
                "SecurityName": "BHAGWATI GLASS CONTAINERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526247",
                "SecurityId": "PREMEXPLQ",
                "SecurityName": "PREMIER EXPLOSIVES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE863B01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526249",
                "SecurityId": "DARSHAN",
                "SecurityName": "DARSHAN OILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE667D01012",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526251",
                "SecurityId": "MIDEASTP",
                "SecurityName": "MID EAST PORTFOLIO MANAGEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE033E01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526257",
                "SecurityId": "KLJPLST",
                "SecurityName": "KLJ PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526259",
                "SecurityId": "IDEALCAR",
                "SecurityName": "IDEAL CARPETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE710C01012",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526261",
                "SecurityId": "KLTHRAE",
                "SecurityName": "KOLUTHARA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526263",
                "SecurityId": "MOLDTEK",
                "SecurityName": "MOLD-TEK TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE835B01035",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526267",
                "SecurityId": "ZENITHSTL",
                "SecurityName": "ZENITH STEEL TUBES AND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526269",
                "SecurityId": "CRSTCHM",
                "SecurityName": "CRESTCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE293N01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526271",
                "SecurityId": "FLOWRTI",
                "SecurityName": "FLOWER & TISSUE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526275",
                "SecurityId": "SHALIHT",
                "SecurityName": "SHALIN HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526277",
                "SecurityId": "RHNISTR",
                "SecurityName": "ROHINI STRIPS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526279",
                "SecurityId": "NGARJNG",
                "SecurityName": "NAGARJUNA GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526283",
                "SecurityId": "ESTNGRA",
                "SecurityName": "EASTERN GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526285",
                "SecurityId": "DIVYAJYQ",
                "SecurityName": "DIVYA JYOTI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE666B01018",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526287",
                "SecurityId": "MDHYVEX",
                "SecurityName": "MADHYAVART EXXOIL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526289",
                "SecurityId": "VLVOTER",
                "SecurityName": "VOLVO TERRY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526297",
                "SecurityId": "VRUNSEA",
                "SecurityName": "VARUN SEACON LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526299",
                "SecurityId": "MPHASIS",
                "SecurityName": "MPHASIS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE356A01018",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526301",
                "SecurityId": "MEDINOV",
                "SecurityName": "MEDINOVA DIAGNOSTIC SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE047C01019",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526303",
                "SecurityId": "NOVAPUM",
                "SecurityName": "NOVA PUMECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526305",
                "SecurityId": "PHELAPP",
                "SecurityName": "PHELIX APPLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526307",
                "SecurityId": "HINDIND",
                "SecurityName": "HIND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE675B01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526309",
                "SecurityId": "ASHAGRO",
                "SecurityName": "ASHA AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526313",
                "SecurityId": "NUTECH",
                "SecurityName": "NU-TECH CORPORATE SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE041C01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526315",
                "SecurityId": "DIVSHKT",
                "SecurityName": "DIVYASHAKTI GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE410G01010",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526317",
                "SecurityId": "RSHIOILDM",
                "SecurityName": "RISHI OIL AND FATS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526325",
                "SecurityId": "ORIENTLTD",
                "SecurityName": "ORIENT PRESS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE609C01024",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526327",
                "SecurityId": "HANSPOL",
                "SecurityName": "HANS POLYURETHANES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526331",
                "SecurityId": "ADVMULT",
                "SecurityName": "ADVANCE MULTITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE875S01019",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526333",
                "SecurityId": "NCLMARB",
                "SecurityName": "NCL MARBLES & GRANITES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526335",
                "SecurityId": "SHREYASI",
                "SecurityName": "SHREYAS INTERMEDIATES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE115F01017",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526337",
                "SecurityId": "MONTRIL",
                "SecurityName": "MONTARI LEATHER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526343",
                "SecurityId": "DECOTUB",
                "SecurityName": "DECORA TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526345",
                "SecurityId": "PANJON",
                "SecurityName": "PANJON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE744D01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526347",
                "SecurityId": "ACCLAIM",
                "SecurityName": "ACCLAIM INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE051E01017",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526349",
                "SecurityId": "PARTIND",
                "SecurityName": "PARTH INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE218T01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526353",
                "SecurityId": "PASHUSEO",
                "SecurityName": "PASHUPATI SEOHUNG LTD.",
                "FaceValue": "10",
                "ISINNo": "INE322D01014",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526355",
                "SecurityId": "DUROPACK",
                "SecurityName": "DUROPACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE138B01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526357",
                "SecurityId": "STRLKAL",
                "SecurityName": "STERLING KALKSAND BRICKS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526365",
                "SecurityId": "SWARNSAR",
                "SecurityName": "SWARNSARITA GEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE967A01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526367",
                "SecurityId": "GANESHHOUC",
                "SecurityName": "GANESH HOUSING CORPORATION LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE460C01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526371",
                "SecurityId": "NMDC",
                "SecurityName": "NMDC LTD.",
                "FaceValue": "1",
                "ISINNo": "INE584A01023",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526373",
                "SecurityId": "CINDHO",
                "SecurityName": "CINDRELLA HOTELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE908C01012",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526377",
                "SecurityId": "ANJNSOL",
                "SecurityName": "ANJANI SOLVENTS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526379",
                "SecurityId": "KONTY",
                "SecurityName": "KONKAN TYRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE730D01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526381",
                "SecurityId": "PATINTLOG",
                "SecurityName": "PATEL INTEGRATED LOGISTICS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE529D01014",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526383",
                "SecurityId": "HAMLSHO",
                "SecurityName": "HAMILTON SHOES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526385",
                "SecurityId": "GUJPE",
                "SecurityName": "GUJARAT PERSTORP ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE878D01015",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526387",
                "SecurityId": "MULCOFF",
                "SecurityName": "MULTICOLOUR OFFSET LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526389",
                "SecurityId": "SKYLEATDM",
                "SecurityName": "SKYLINE LEATHER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526391",
                "SecurityId": "NEELKNT",
                "SecurityName": "NEELKANTH MOTELS & HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE882F01012",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526395",
                "SecurityId": "RAJSFORDM",
                "SecurityName": "RAJSHRI FOREX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526397",
                "SecurityId": "ALPHAGEO",
                "SecurityName": "ALPHAGEO (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE137C01018",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526399",
                "SecurityId": "VISHFOT",
                "SecurityName": "VISHAL FOOTWEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526401",
                "SecurityId": "GURULET",
                "SecurityName": "GURDARSHAN LEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526403",
                "SecurityId": "RGNYMIS",
                "SecurityName": "REGENCY HOSPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE581K01010",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526407",
                "SecurityId": "RPIL",
                "SecurityName": "RITESH PROPERTIES & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE299D01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526409",
                "SecurityId": "KKALPANAIND",
                "SecurityName": "KKALPANA INDUSTRIES (INDIA) LTD",
                "FaceValue": "2",
                "ISINNo": "INE301C01028",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526411",
                "SecurityId": "MOVILEX",
                "SecurityName": "MOVILEX IRRIGATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE857A01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526413",
                "SecurityId": "PANDGRP-B",
                "SecurityName": "PANDIAN GRAPHITES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526415",
                "SecurityId": "OKPLA",
                "SecurityName": "OK PLAY INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE870B01016",
                "Industry": "Gift Articles-Toys & Cards ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526417",
                "SecurityId": "SPLINDL",
                "SecurityName": "SPL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526421",
                "SecurityId": "LOKMIND",
                "SecurityName": "LOKMANYA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526423",
                "SecurityId": "KRITIIND",
                "SecurityName": "KRITI INDUSTRIES (INDIA) LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE479D01038",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526425",
                "SecurityId": "FDTYMIS-BDM",
                "SecurityName": "FIDELITY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526427",
                "SecurityId": "OJAST",
                "SecurityName": "OJAS TECHNOCHEM PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE696B01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526431",
                "SecurityId": "WELTI",
                "SecurityName": "WELTERMAN INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE662D01013",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526433",
                "SecurityId": "ASMTEC",
                "SecurityName": "ASM TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE867C01010",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526435",
                "SecurityId": "PERFEPA",
                "SecurityName": "PERFECTPAC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE759I01016",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526437",
                "SecurityId": "SRINICO",
                "SecurityName": "SHRI NICOSECT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE674F01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526439",
                "SecurityId": "AMBIT",
                "SecurityName": "AMBITIOUS PLASTOMAC COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE267C01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526441",
                "SecurityId": "VISIONCINE",
                "SecurityName": "VISION CINEMAS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE515B01025",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526443",
                "SecurityId": "DATASOFT",
                "SecurityName": "DATASOFT APPLICATION SOFTWARE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE072B01027",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526445",
                "SecurityId": "INDRANIB",
                "SecurityName": "INDRAYANI BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE007C01021",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526449",
                "SecurityId": "JAYRAPID",
                "SecurityName": "JAY RAPID ROLLER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE872D01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526451",
                "SecurityId": "KARNAALF",
                "SecurityName": "KARNAVATI ALFA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE901D01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526453",
                "SecurityId": "VIDIMIS",
                "SecurityName": "VIDIANI AGROTECH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526457",
                "SecurityId": "SOFTRAKT",
                "SecurityName": "SOFTRAK TECHNOLOGY EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE697D01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526461",
                "SecurityId": "TRANMED",
                "SecurityName": "TRANSMEDICA (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526463",
                "SecurityId": "UGHOR",
                "SecurityName": "U.G.HOTELS AND RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE692D01010",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526465",
                "SecurityId": "RAGHAVIN",
                "SecurityName": "RAGHAV INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE492C01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526467",
                "SecurityId": "EURLEADDM",
                "SecurityName": "EURLEADDM",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526468",
                "SecurityId": "EUROLED",
                "SecurityName": "EURO LEDER FASHION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE940E01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526469",
                "SecurityId": "SUPERIA",
                "SecurityName": "SUPERIOR AIR PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526471",
                "SecurityId": "WINSOMBR",
                "SecurityName": "WINSOME BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE391C01011",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526473",
                "SecurityId": "ELEFLOR",
                "SecurityName": "ELEGANT FLORICULTURE & AGROTECH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE152E01013",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526475",
                "SecurityId": "HOTKRIS",
                "SecurityName": "HOTEL KRISHNA INTERNATIONAL (I)LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526477",
                "SecurityId": "SAENTER",
                "SecurityName": "SOUTH ASIAN ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE118B01010",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526479",
                "SecurityId": "SKYIND",
                "SecurityName": "SKY INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE765B01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526481",
                "SecurityId": "PHOENXINTL",
                "SecurityName": "PHOENIX INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE245B01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526483",
                "SecurityId": "EDUEXEL",
                "SecurityName": "EDUEXEL INFOTAINMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE611F01015",
                "Industry": "Photographic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526486",
                "SecurityId": "HITECHIJEW",
                "SecurityName": "HITECHI JEWELLERY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE143B01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526488",
                "SecurityId": "AARVINFRA",
                "SecurityName": "AARV INFRATEL LTD",
                "FaceValue": "10",
                "ISINNo": "INE432N01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526490",
                "SecurityId": "PRATIK",
                "SecurityName": "PRATIK PANELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE206C01011",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526492",
                "SecurityId": "RISHIROOP",
                "SecurityName": "RISHIROOP LTD",
                "FaceValue": "10",
                "ISINNo": "INE582D01013",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526494",
                "SecurityId": "PROMACT",
                "SecurityName": "PROMACT IMPEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE818D01011",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526496",
                "SecurityId": "RUBBERPR",
                "SecurityName": "RUBBER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE430C01017",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526498",
                "SecurityId": "ZENER",
                "SecurityName": "ZENERGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE860B01017",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526500",
                "SecurityId": "STRGRENWO",
                "SecurityName": "STERLING GREEN WOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE398F01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526502",
                "SecurityId": "VISHCHR",
                "SecurityName": "VISHAL CHAIRS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526504",
                "SecurityId": "DOLPHMED",
                "SecurityName": "DOLPHIN MEDICAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE796B01013",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526506",
                "SecurityId": "SYSTMTXC",
                "SecurityName": "SYSTEMATIX CORPORATE SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE356B01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526508",
                "SecurityId": "SHAHISHIP",
                "SecurityName": "SHAHI SHIPPING LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE825D01016",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526510",
                "SecurityId": "SHAKMET",
                "SecurityName": "SHAKTI MET-DOR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE614D01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526512",
                "SecurityId": "INTLNKP",
                "SecurityName": "INTERLINK PETROLEUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE959G01016",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526514",
                "SecurityId": "ACMEIND",
                "SecurityName": "ACME INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526517",
                "SecurityId": "APPOSTO",
                "SecurityName": "APPOLLO STONES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526519",
                "SecurityId": "ALPINEHOU",
                "SecurityName": "ALPINE HOUSING DEVELOPMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE840D01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526521",
                "SecurityId": "SANGHIIND",
                "SecurityName": "SANGHI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE999B01013",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526523",
                "SecurityId": "JAYENGY",
                "SecurityName": "JAY ENERGY AND S.ENERGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE315L01029",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526525",
                "SecurityId": "WWALUM",
                "SecurityName": "WORLDWIDE ALUMINIUM LTD",
                "FaceValue": "10",
                "ISINNo": "INE550D01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526527",
                "SecurityId": "SAKETEX",
                "SecurityName": "SAKET EXTRUSION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526528",
                "SecurityId": "PARHOSG",
                "SecurityName": "PARTH HOUSING AND ESTATE DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526530",
                "SecurityId": "SUDIN",
                "SecurityName": "SUDEV INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE670C01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526532",
                "SecurityId": "SFPIL",
                "SecurityName": "SQUARE FOUR PROJECTS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE716K01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526534",
                "SecurityId": "INDOX",
                "SecurityName": "INDOX",
                "FaceValue": "10",
                "ISINNo": "INE321A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526536",
                "SecurityId": "MEHTARB",
                "SecurityName": "MEHTA RUBBER CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526538",
                "SecurityId": "MAXIMAA",
                "SecurityName": "MAXIMAA SYSTEMS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE161B01036",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526544",
                "SecurityId": "SCANPGEOM",
                "SecurityName": "SCANPOINT GEOMATICS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE967B01028",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526546",
                "SecurityId": "CHOKSILA",
                "SecurityName": "CHOKSI LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE493D01013",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526548",
                "SecurityId": "HARIPAM",
                "SecurityName": "HARIPARVAT MERRYLAND AND RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526550",
                "SecurityId": "CCHHL",
                "SecurityName": "COUNTRY CLUB HOSPITALITY & HOLIDAYS LTD",
                "FaceValue": "2",
                "ISINNo": "INE652F01027",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526552",
                "SecurityId": "RASIKPL",
                "SecurityName": "RASIK PLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526554",
                "SecurityId": "SALGUTI",
                "SecurityName": "SALGUTI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE159C01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526558",
                "SecurityId": "JAMEHOT",
                "SecurityName": "JAMES HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE510D01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526560",
                "SecurityId": "EICL LTD",
                "SecurityName": "EICL LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE267F01024",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526562",
                "SecurityId": "PEPL",
                "SecurityName": "PEARL ENGINEERING POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE843A01023",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526564",
                "SecurityId": "ARIITHR",
                "SecurityName": "ARIHANT THERMOWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526568",
                "SecurityId": "LONTE",
                "SecurityName": "LONGVIEW TEA COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE696E01019",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526570",
                "SecurityId": "MIDWEST",
                "SecurityName": "MIDWEST GOLD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE519N01014",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526574",
                "SecurityId": "ENTRINT",
                "SecurityName": "ENTERPRISE INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE439G01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526576",
                "SecurityId": "TECHIN",
                "SecurityName": "TECHINDIA NIRMAN LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE778A01021",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526580",
                "SecurityId": "CHAMPDIN",
                "SecurityName": "CHAMPDANY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE487A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526582",
                "SecurityId": "TPLPLAST",
                "SecurityName": "TPL PLASTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE413G01014",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526584",
                "SecurityId": "BAGWUDG",
                "SecurityName": "BAGWE UDYOG LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526586",
                "SecurityId": "WIMPLAST",
                "SecurityName": "WIM PLAST LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE015B01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526588",
                "SecurityId": "PHOTOQUP",
                "SecurityName": "PHOTOQUIP INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE813B01016",
                "Industry": "Photographic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526594",
                "SecurityId": "BNANJEN",
                "SecurityName": "B.NANJI ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE735G01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526596",
                "SecurityId": "LIBERTSHOE",
                "SecurityName": "LIBERTY SHOES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE557B01019",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526598",
                "SecurityId": "GUJNFLY",
                "SecurityName": "GUJARAT NARMADA FLYASH CO.LTD.",
                "FaceValue": "5",
                "ISINNo": "INE955G01022",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526602",
                "SecurityId": "PATELON",
                "SecurityName": "PATEL ON-BOARD COURIERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE128C01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526604",
                "SecurityId": "LIPPISYS",
                "SecurityName": "LIPPI SYSTEMS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE845B01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526606",
                "SecurityId": "PERTCOM-B",
                "SecurityName": "PERTECH COMPUTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526608",
                "SecurityId": "ELECTHERM",
                "SecurityName": "ELECTROTHERM (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE822G01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526610",
                "SecurityId": "INHOUPROD",
                "SecurityName": "IN HOUSE PRODUCTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE120B01024",
                "Industry": "Data Processing Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526612",
                "SecurityId": "BLUEDART",
                "SecurityName": "BLUE DART EXPRESS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE233B01017",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526614",
                "SecurityId": "EXPOGAS",
                "SecurityName": "EXPO GAS CONTAINERS LTD.",
                "FaceValue": "4",
                "ISINNo": "INE561D01025",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526616",
                "SecurityId": "NATPLAS",
                "SecurityName": "NATIONAL PLASTIC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE233D01013",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526620",
                "SecurityId": "SKYHIPR",
                "SecurityName": "SKYHIPR",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526622",
                "SecurityId": "MFLINDIA",
                "SecurityName": "MFL INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE244C01020",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526624",
                "SecurityId": "ELBEE",
                "SecurityName": "ELBEE SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE030B01017",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526626",
                "SecurityId": "TRIDE",
                "SecurityName": "TRIDENT PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE988B01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526628",
                "SecurityId": "AJWAFUN",
                "SecurityName": "AJWA FUN WORLD & RESORT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE863E01015",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526630",
                "SecurityId": "GEOLOGI",
                "SecurityName": "GEOLOGGING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526632",
                "SecurityId": "MPLCORP",
                "SecurityName": "MPL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526634",
                "SecurityId": "VALPLUS",
                "SecurityName": "VALPLUS BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526638",
                "SecurityId": "TEXELIN",
                "SecurityName": "TEXEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE594V01028",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526640",
                "SecurityId": "RAYALEMA",
                "SecurityName": "ROYALE MANOR HOTELS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE008C01011",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526642",
                "SecurityId": "MIRZAINT",
                "SecurityName": "MIRZA INTERNATIONAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE771A01026",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526646",
                "SecurityId": "TATAV",
                "SecurityName": "TATA ADVANCED MATERIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE097C01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526648",
                "SecurityId": "INDFL",
                "SecurityName": "INDO FLOGATES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE027B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526650",
                "SecurityId": "TFCILTD",
                "SecurityName": "TOURISM FINANCE CORPORATION OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE305A01015",
                "Industry": "Financial Institutions",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526652",
                "SecurityId": "CALSREF",
                "SecurityName": "CALS REFINERIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE040C01022",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526654",
                "SecurityId": "THAKDEV",
                "SecurityName": "THAKKERS DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE403F01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526656",
                "SecurityId": "NAVABHA-B",
                "SecurityName": "NAVA BHARAT ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526658",
                "SecurityId": "CANBAYPDM",
                "SecurityName": "CANBAY POLYFILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526660",
                "SecurityId": "PRESISH",
                "SecurityName": "PRESIDENCY SHOE INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526662",
                "SecurityId": "ARENTERP",
                "SecurityName": "RAJDARSHAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE610C01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526664",
                "SecurityId": "ANUGWOO",
                "SecurityName": "ANUGRAHA WOOD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526666",
                "SecurityId": "BIL",
                "SecurityName": "BHARTIYA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE828A01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526668",
                "SecurityId": "KAMATHOTEL",
                "SecurityName": "KAMAT HOTELS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE967C01018",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526671",
                "SecurityId": "MKEL",
                "SecurityName": "MATRA KAUSHAL ENTERPRISE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE265N01030",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526675",
                "SecurityId": "TIRTPLS",
                "SecurityName": "TIRTH PLASTIC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE008N01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526677",
                "SecurityId": "DALALSTCOM",
                "SecurityName": "DSJ COMMUNICATIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE055C01020",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526679",
                "SecurityId": "GALORE",
                "SecurityName": "GALORE PRINTS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE648C01014",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526681",
                "SecurityId": "SAISERVQ",
                "SecurityName": "SAI SERVICE STATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE622A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526683",
                "SecurityId": "HOTELRUGBY",
                "SecurityName": "HOTEL RUGBY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE275F01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526685",
                "SecurityId": "CROCH",
                "SecurityName": "CROSS COUNTRY HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE119C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526687",
                "SecurityId": "POLOHOT",
                "SecurityName": "POLO HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE084C01012",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526689",
                "SecurityId": "FENOPLAS",
                "SecurityName": "FENOPLAST LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE138D01014",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526691",
                "SecurityId": "PRETLEA",
                "SecurityName": "PRETTO LEATHER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE384M01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526693",
                "SecurityId": "MAYHO",
                "SecurityName": "MAYANK HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE571C01018",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526695",
                "SecurityId": "MICALEADM",
                "SecurityName": "MICAM LEATHER EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526697",
                "SecurityId": "KASIHSG",
                "SecurityName": "KASI HOUSING & DEVELOPMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526699",
                "SecurityId": "ABCOPLS",
                "SecurityName": "ABCO PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526701",
                "SecurityId": "SAMASOKDM",
                "SecurityName": "SAMRAT ASHOKA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526703",
                "SecurityId": "ECOPLAST",
                "SecurityName": "ECOPLAST LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE423D01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526705",
                "SecurityId": "ELEMARB",
                "SecurityName": "ELEGANT MARBLES & GRANI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE095B01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526706",
                "SecurityId": "TURBOIN",
                "SecurityName": "ALCHEMIST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526707",
                "SecurityId": "ALCHEM",
                "SecurityName": "ALCHEMIST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE964B01033",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526709",
                "SecurityId": "BITS",
                "SecurityName": "BITS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE839A01021",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526711",
                "SecurityId": "ADARSHPL",
                "SecurityName": "ADARSH PLANT PROTECT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE627D01016",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526713",
                "SecurityId": "STIEFELDM",
                "SecurityName": "STIEFEL UND SCHUH INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526715",
                "SecurityId": "ANTARTICL",
                "SecurityName": "ANTARCTICA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE414B01021",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526717",
                "SecurityId": "GOPALA",
                "SecurityName": "GOPALA POLYPLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE136C01036",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526719",
                "SecurityId": "MISOFPP",
                "SecurityName": "MISOFPP",
                "FaceValue": "0.5",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526721",
                "SecurityId": "NICCOPAR",
                "SecurityName": "NICCO PARKS & RESORTS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE653C01022",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526723",
                "SecurityId": "NTCIND",
                "SecurityName": "NTC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE920C01017",
                "Industry": "Cigarettes-Tobacco Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526725",
                "SecurityId": "SANDESH",
                "SecurityName": "SANDESH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE583B01015",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526727",
                "SecurityId": "GARNET",
                "SecurityName": "GARNET CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE797D01017",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526729",
                "SecurityId": "GOLDIAM",
                "SecurityName": "GOLDIAM INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE025B01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526731",
                "SecurityId": "BRIGHTBR",
                "SecurityName": "BRIGHT BROTHERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE630D01010",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526733",
                "SecurityId": "SUASHDIM",
                "SecurityName": "SUASHISH DIAMONDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE658A01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526735",
                "SecurityId": "EXCAST",
                "SecurityName": "EXCEL CASTRONICS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE501O01036",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526737",
                "SecurityId": "CORPOCO",
                "SecurityName": "CORPORATE COURIER AND CARGO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE871E01018",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526739",
                "SecurityId": "SHAWGELTIN",
                "SecurityName": "NARMADA GELATINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE869A01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526741",
                "SecurityId": "CREDENCE",
                "SecurityName": "CREDENCE SOUND & VISION LTD.",
                "FaceValue": "2",
                "ISINNo": "INE406C01025",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526743",
                "SecurityId": "NATCOMS",
                "SecurityName": "NATCO POLYPLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526745",
                "SecurityId": "ZOCTNMMS",
                "SecurityName": "OCTANORM INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526747",
                "SecurityId": "PGFOILQ",
                "SecurityName": "PG FOILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE078D01012",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526749",
                "SecurityId": "SUNDERADM",
                "SecurityName": "SUNDERBAN AQUATIC FARMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526751",
                "SecurityId": "GRATEXI",
                "SecurityName": "GRATEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE915E01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526753",
                "SecurityId": "KRIPIND",
                "SecurityName": "KRIPTOL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE477C01034",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526755",
                "SecurityId": "VELHO",
                "SecurityName": "VELAN HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE548D01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526757",
                "SecurityId": "GANPTMSDM",
                "SecurityName": "GANAPATI EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526759",
                "SecurityId": "VIAMEDI",
                "SecurityName": "VIA MEDIA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526761",
                "SecurityId": "HOWARHO",
                "SecurityName": "HOWARD HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE931B01016",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526763",
                "SecurityId": "MULTILA",
                "SecurityName": "MULTILAC SURFACE COATING (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526767",
                "SecurityId": "SOUTHFUEL",
                "SecurityName": "SOUTHERN FUEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526773",
                "SecurityId": "PRESSURS",
                "SecurityName": "PRESSURE SENSITIVE SYSTEMS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE891E01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526775",
                "SecurityId": "VALIANT",
                "SecurityName": "VALIANT COMMUNICATIONS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE760B01019",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526777",
                "SecurityId": "NWPLAMS",
                "SecurityName": "NEW PLASTOMERS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526779",
                "SecurityId": "HINAFIL",
                "SecurityName": "HINAFIL INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE120F01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526783",
                "SecurityId": "DRAGARWQ",
                "SecurityName": "DR.AGARWALS EYE HOSPITAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE934C01018",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526785",
                "SecurityId": "CRESTANI",
                "SecurityName": "CREST ANIMATION STUDIOS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE774A01012",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526787",
                "SecurityId": "EUROMMS",
                "SecurityName": "EURO MARINE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526789",
                "SecurityId": "NOELA",
                "SecurityName": "NOEL AGRITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE154C01013",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526791",
                "SecurityId": "INGERMS",
                "SecurityName": "INDO-GERMA PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526793",
                "SecurityId": "ARVRAMS",
                "SecurityName": "ARVIND RASHAYANA UDYOG LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526795",
                "SecurityId": "MHSGRMS",
                "SecurityName": "MAHASAGAR TRAVELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE007G01014",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526797",
                "SecurityId": "GREENPLY",
                "SecurityName": "GREENPLY INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE461C01038",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526799",
                "SecurityId": "UNQTYMI",
                "SecurityName": "UNION QUALITY PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE338N01019",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526801",
                "SecurityId": "PSL",
                "SecurityName": "PSL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE474B01017",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526803",
                "SecurityId": "OBROIMSDM",
                "SecurityName": "OBEROI PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526805",
                "SecurityId": "AMICOMP",
                "SecurityName": "AMI COMPUTERS (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE079B01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526807",
                "SecurityId": "SEAMECLTD",
                "SecurityName": "SEAMEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE497B01018",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526811",
                "SecurityId": "SATALMS",
                "SecurityName": "SAATAL KATTHA & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526813",
                "SecurityId": "RAGHUNAT",
                "SecurityName": "RAGHUNATH INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE753B01014",
                "Industry": "Cigarettes-Tobacco Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526815",
                "SecurityId": "RJPNTMS-B",
                "SecurityName": "RJPNTMS-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526817",
                "SecurityId": "CHEVIOT",
                "SecurityName": "CHEVIOT CO.LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE974B01016",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526819",
                "SecurityId": "TRNLMMS",
                "SecurityName": "TRANSLAM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526821",
                "SecurityId": "DAICHI",
                "SecurityName": "DAI-ICHI KARKARIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE928C01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526823",
                "SecurityId": "RAJINFRA",
                "SecurityName": "RAJESWARI INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE016C01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526827",
                "SecurityId": "SPICEISL",
                "SecurityName": "SPICE ISLANDS APPARELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE882D01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526829",
                "SecurityId": "CONFIPET",
                "SecurityName": "CONFIDENCE PETROLEUM INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE552D01024",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526831",
                "SecurityId": "NAINAMS",
                "SecurityName": "NAINA SEMICONDUCTOR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526833",
                "SecurityId": "RACCL",
                "SecurityName": "RAJENDRA CATERERS & CONFECTIONERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE151O01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526835",
                "SecurityId": "SILKTEX",
                "SecurityName": "SILKTEX LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE056B01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526837",
                "SecurityId": "ANUSHMS",
                "SecurityName": "ANUSHA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526839",
                "SecurityId": "SIPL",
                "SecurityName": "SHELTER INFRA PROJECTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE413C01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526841",
                "SecurityId": "SHAKTIPR",
                "SecurityName": "SHAKTI PRESS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE794C01016",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526843",
                "SecurityId": "ATLANTADEV",
                "SecurityName": "ATLANTA DEVCON LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE590F01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526847",
                "SecurityId": "ASHSI",
                "SecurityName": "ASHIRWAD STEELS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE338C01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526849",
                "SecurityId": "BANARBEADS",
                "SecurityName": "BANARAS BEADS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE655B01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526851",
                "SecurityId": "AREXMIS",
                "SecurityName": "AREX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE480H01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526853",
                "SecurityId": "BI",
                "SecurityName": "BILCARE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE986A01012",
                "Industry": "Healthcare Supplies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526855",
                "SecurityId": "MADHUFN",
                "SecurityName": "MADHUMILAN FINCORP LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526857",
                "SecurityId": "MAJESIN",
                "SecurityName": "MAJESTIC INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526859",
                "SecurityId": "ISFL",
                "SecurityName": "ISF LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE973B01026",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526861",
                "SecurityId": "RISHILASE",
                "SecurityName": "RISHI LASER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE988D01012",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526863",
                "SecurityId": "MBINDUS",
                "SecurityName": "M.B INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526865",
                "SecurityId": "JAINCO",
                "SecurityName": "JAINCO PROJECTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE966C01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526869",
                "SecurityId": "KUMPFIN",
                "SecurityName": "KUMBHAT FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE795E01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526871",
                "SecurityId": "INTECCAP",
                "SecurityName": "INTEC CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE017E01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526873",
                "SecurityId": "RAJGASES",
                "SecurityName": "RAJASTHAN GASES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE184D01018",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526875",
                "SecurityId": "MGGLOMF",
                "SecurityName": "MGGLOMF",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526877",
                "SecurityId": "SIDDHEGA",
                "SecurityName": "SIDDHESWARI GARMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE797C01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526879",
                "SecurityId": "UTLTD",
                "SecurityName": "UT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE487C01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526881",
                "SecurityId": "63MOONS",
                "SecurityName": "63 MOONS TECHNOLOGIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE111B01023",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526883",
                "SecurityId": "SHIKHAR",
                "SecurityName": "SHIKHAR CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE641B01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526885",
                "SecurityId": "SARLAPOLY",
                "SecurityName": "SARLA PERFORMANCE FIBERS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE453D01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526887",
                "SecurityId": "INDOCRED",
                "SecurityName": "INDO CREDIT CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE147D01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526891",
                "SecurityId": "MKTCREAT",
                "SecurityName": "MARKET CREATORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE944C01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526893",
                "SecurityId": "SRINIHAT",
                "SecurityName": "SRINIVASA HATCHERIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE312E01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526895",
                "SecurityId": "ODISEDM",
                "SecurityName": "ODISSI SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE203C01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526897",
                "SecurityId": "HEXPHAR",
                "SecurityName": "HEXONE PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526899",
                "SecurityId": "HFIL",
                "SecurityName": "HIMALAYA FOOD INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE552B01010",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526901",
                "SecurityId": "SONALAD",
                "SecurityName": "SONAL ADHESIVES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE344I01017",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526903",
                "SecurityId": "ROTOFIX",
                "SecurityName": "ROTOFLEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526905",
                "SecurityId": "PADMAIND",
                "SecurityName": "PADMANABH INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE743D01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526913",
                "SecurityId": "YOGIPLY",
                "SecurityName": "YOGI POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526915",
                "SecurityId": "UNQAGRO",
                "SecurityName": "UNIQUE AGRO PROCESSORS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526917",
                "SecurityId": "CHDDLTD",
                "SecurityName": "CHD DEVELOPERS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE659B01021",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526919",
                "SecurityId": "ALPEXPS",
                "SecurityName": "ALP EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526921",
                "SecurityId": "21STCENMGM",
                "SecurityName": "TWENTYFIRST CENTURY MANAGEMENT SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE253B01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526925",
                "SecurityId": "BOBSHELL",
                "SecurityName": "BOBSHELL ELECTRODES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE896B01011",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526927",
                "SecurityId": "DION",
                "SecurityName": "DION GLOBAL SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE991C01034",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526931",
                "SecurityId": "HRYNSHP",
                "SecurityName": "HARIYANA SHIP BREAKERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE400G01011",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526933",
                "SecurityId": "KONAROR",
                "SecurityName": "KONAR ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526935",
                "SecurityId": "ARUNIS",
                "SecurityName": "ARUNIS ABODE LTD",
                "FaceValue": "10",
                "ISINNo": "INE377D01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526937",
                "SecurityId": "MNGLPRODM",
                "SecurityName": "MANGAL PROCESSING MILL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526941",
                "SecurityId": "VAICC",
                "SecurityName": "VAISHNO CEMENT CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE116E01018",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526943",
                "SecurityId": "SHARAVE",
                "SecurityName": "SHREE ARAVELI FINLEASE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE818E01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526945",
                "SecurityId": "TYROON",
                "SecurityName": "TYROON TEA CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE945B01016",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526947",
                "SecurityId": "LAOPALA",
                "SecurityName": "LA OPALA RG LTD.",
                "FaceValue": "2",
                "ISINNo": "INE059D01020",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526949",
                "SecurityId": "GUJOC",
                "SecurityName": "GUJARAT OPTICAL COMMUNICATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE069D01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526951",
                "SecurityId": "STYLAMIND",
                "SecurityName": "STYLAM INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE239C01012",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526953",
                "SecurityId": "VENUSREM",
                "SecurityName": "VENUS REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE411B01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526955",
                "SecurityId": "ABLBIO",
                "SecurityName": "ABL BIOTECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE707D01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526957",
                "SecurityId": "UDL",
                "SecurityName": "UNIPLY DECOR LTD",
                "FaceValue": "2",
                "ISINNo": "INE493E01029",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526959",
                "SecurityId": "WOODSVILA",
                "SecurityName": "WOODSVILLA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE374J01020",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526961",
                "SecurityId": "TRANSFIN",
                "SecurityName": "TRANS FINANCIAL RESOURCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE404B01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526963",
                "SecurityId": "GLBFN",
                "SecurityName": "GLOBAL FINANCE AND SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE044C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526965",
                "SecurityId": "GUJCRAFT",
                "SecurityName": "GUJARAT CRAFT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE372D01019",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526967",
                "SecurityId": "HEERAISP",
                "SecurityName": "HEERA ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE025D01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526971",
                "SecurityId": "DHOOTIN",
                "SecurityName": "DHOOT INDUSTRIAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE313G01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526973",
                "SecurityId": "CLASPRS",
                "SecurityName": "CLASSIC PRESS (INTERNATIONAL) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526975",
                "SecurityId": "AWASAYO",
                "SecurityName": "AWAS AYOGEN VITTNIGAM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526977",
                "SecurityId": "CRIMSON",
                "SecurityName": "CRIMSON METAL ENGINEERING COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE318P01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526979",
                "SecurityId": "SIDIN",
                "SecurityName": "SIDH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE823C01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526981",
                "SecurityId": "SHBAJRG",
                "SecurityName": "SHRI BAJRANG ALLIANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE402H01015",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526983",
                "SecurityId": "ASHOKRE",
                "SecurityName": "ASHOKA REFINERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE760M01016",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526985",
                "SecurityId": "AKSCRE",
                "SecurityName": "AKS CREDITS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE971C01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526987",
                "SecurityId": "URJAGLOBA",
                "SecurityName": "URJA GLOBAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE550C01020",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526989",
                "SecurityId": "ORIREME",
                "SecurityName": "ORIENTAL REMEDIES & HERBALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "526991",
                "SecurityId": "ATLASCOPCO",
                "SecurityName": "ATLAS COPCO (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE445A01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "527001",
                "SecurityId": "ASHAPURMIN",
                "SecurityName": "ASHAPURA MINECHEM LTD.",
                "FaceValue": "2",
                "ISINNo": "INE348A01023",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "527003",
                "SecurityId": "SVOYHERDM",
                "SecurityName": "SAVOY HERBALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "527005",
                "SecurityId": "SHREEPAC",
                "SecurityName": "SHREE PACETRONIX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE847D01010",
                "Industry": "Healthcare Supplies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "527007",
                "SecurityId": "ATCOM",
                "SecurityName": "ATCOM TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE834A01014",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530001",
                "SecurityId": "GUJALKALI",
                "SecurityName": "GUJARAT ALKALIES & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE186A01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530005",
                "SecurityId": "INDIACEM",
                "SecurityName": "INDIA CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE383A01012",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530007",
                "SecurityId": "JKTYRE",
                "SecurityName": "JK TYRE & INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE573A01042",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530011",
                "SecurityId": "MANGCHEFER",
                "SecurityName": "MANGALORE CHEMICALS & FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE558B01017",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530017",
                "SecurityId": "SIL",
                "SecurityName": "STANDARD INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE173A01025",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530019",
                "SecurityId": "JUBILANT",
                "SecurityName": "JUBILANT LIFE SCIENCES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE700A01033",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530021",
                "SecurityId": "ASIAREF",
                "SecurityName": "ASIA REFINERY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Refineries/ Petro-Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530023",
                "SecurityId": "THEINVEST",
                "SecurityName": "THE INVESTMENT TRUST OF INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE924D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530025",
                "SecurityId": "SAMYAKINT",
                "SecurityName": "SAMYAK INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE607G01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530027",
                "SecurityId": "AADIIND",
                "SecurityName": "AADI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE563D01013",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530029",
                "SecurityId": "MHTSULF-B",
                "SecurityName": "MEHTA SULFITES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530031",
                "SecurityId": "ORIQUAL",
                "SecurityName": "ORIQUA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530033",
                "SecurityId": "MOONB",
                "SecurityName": "MOONBEAM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE526D01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530035",
                "SecurityId": "SANTOSHF",
                "SecurityName": "SANTOSH FINE-FAB LTD.",
                "FaceValue": "10",
                "ISINNo": "INE612D01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530037",
                "SecurityId": "SREEJAYA",
                "SecurityName": "SREE JAYALAKSHMI AUTOSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE618F01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530039",
                "SecurityId": "LORDSCH",
                "SecurityName": "LORDS CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE554C01014",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530041",
                "SecurityId": "YAMAPLY",
                "SecurityName": "YAMA POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530043",
                "SecurityId": "ACKNIT",
                "SecurityName": "ACKNIT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE326C01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530045",
                "SecurityId": "TITANSEC",
                "SecurityName": "TITAN SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE090D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530047",
                "SecurityId": "MOHOTAIND",
                "SecurityName": "MOHOTA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE313D01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530049",
                "SecurityId": "JJEXPO",
                "SecurityName": "J.J.EXPORTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE408B01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530051",
                "SecurityId": "MAMTAST",
                "SecurityName": "MAMTA STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530053",
                "SecurityId": "REALECO",
                "SecurityName": "REAL ECO-ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE055E01026",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530055",
                "SecurityId": "HRMNYCP",
                "SecurityName": "HARMONY CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE264N01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530057",
                "SecurityId": "VIVANZA",
                "SecurityName": "VIVANZA BIOSCIENCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE984E01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530059",
                "SecurityId": "MAHASHR",
                "SecurityName": "MAHARAJA SHREE UMAID MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE087D01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530061",
                "SecurityId": "ROCKTHR",
                "SecurityName": "ROCKLAND THERMIONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE777M01010",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530063",
                "SecurityId": "YASHRAJC",
                "SecurityName": "YASHRAJ CONTAINEURS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE095C01018",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530064",
                "SecurityId": "NAGDRUG",
                "SecurityName": "NAGARJUNA DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE254C01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530065",
                "SecurityId": "LORDSHOTL",
                "SecurityName": "LORDS ISHWAR HOTELS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE689J01013",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530067",
                "SecurityId": "CSLFINANCE",
                "SecurityName": "CSL FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE718F01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530069",
                "SecurityId": "PROTODEV",
                "SecurityName": "PROTO DEVELOPERS & TECHNOLOGIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE749D01026",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530071",
                "SecurityId": "PARSOLI",
                "SecurityName": "PARSOLI CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE905D01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530073",
                "SecurityId": "SANGHVIMOV",
                "SecurityName": "SANGHVI MOVERS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE989A01024",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530075",
                "SecurityId": "SELAN",
                "SecurityName": "SELAN EXPLORATION TECHNOLOGY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE818A01017",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530077",
                "SecurityId": "FRSHTRP",
                "SecurityName": "FRESHTROP FRUITS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE795D01011",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530079",
                "SecurityId": "FAZE3Q",
                "SecurityName": "FAZE THREE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE963C01033",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530083",
                "SecurityId": "MOTMIND",
                "SecurityName": "MOTHER MIRA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530085",
                "SecurityId": "ITALJEW",
                "SecurityName": "ITALIAN JEWELLERY MANUFACTURING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530087",
                "SecurityId": "EUROIND",
                "SecurityName": "EURO INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530089",
                "SecurityId": "GODRICH",
                "SecurityName": "GODRICH FINANCE AND SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530091",
                "SecurityId": "ZYDEN",
                "SecurityName": "ZYDEN GENTEC LTD.",
                "FaceValue": "2",
                "ISINNo": "INE622B01045",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530093",
                "SecurityId": "ACEEDU",
                "SecurityName": "ACE EDUTREND LTD",
                "FaceValue": "10",
                "ISINNo": "INE715F01014",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530095",
                "SecurityId": "PRADHIN",
                "SecurityName": "PRADHIN LTD",
                "FaceValue": "10",
                "ISINNo": "INE656B01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530097",
                "SecurityId": "XYLONLQ",
                "SecurityName": "XYLON LOQUITUR DISTILLERS & VINTNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530099",
                "SecurityId": "TRENDES",
                "SecurityName": "TRENDEASTWEST LPG BOTTLING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530101",
                "SecurityId": "LADAMFN",
                "SecurityName": "LADAM FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530105",
                "SecurityId": "KRNTCHE",
                "SecurityName": "KARNATAKA CHEMICAL INDUSTRIES CORP.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530109",
                "SecurityId": "ANUPAM",
                "SecurityName": "ANUPAM FINSERV LTD",
                "FaceValue": "10",
                "ISINNo": "INE069B01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530111",
                "SecurityId": "RAJPACK",
                "SecurityName": "RAJ PACKAGING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE639C01013",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530113",
                "SecurityId": "MAREXDM",
                "SecurityName": "MARDIA EXTRUSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE276E01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530115",
                "SecurityId": "IBINDDM",
                "SecurityName": "IB INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE374D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530117",
                "SecurityId": "PRIVISCL",
                "SecurityName": "PRIVI SPECIALITY CHEMICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE959A01019",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530119",
                "SecurityId": "NATRAJPR",
                "SecurityName": "NATRAJ PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE444D01016",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530123",
                "SecurityId": "SUSHANTDM",
                "SecurityName": "SUSHANT CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530125",
                "SecurityId": "SAMRATPH",
                "SecurityName": "SAMRAT PHARMACHEM LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE103E01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530127",
                "SecurityId": "NPRFIN",
                "SecurityName": "NPR FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE446D01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530129",
                "SecurityId": "NILE",
                "SecurityName": "NILE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE445D01013",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530131",
                "SecurityId": "UDAICEMENT",
                "SecurityName": "UDAIPUR CEMENT WORKS LTD.",
                "FaceValue": "4",
                "ISINNo": "INE225C01029",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530133",
                "SecurityId": "AMCOIND",
                "SecurityName": "AMCO INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE924B01011",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530135",
                "SecurityId": "OPTIEMUS",
                "SecurityName": "OPTIEMUS INFRACOM LTD",
                "FaceValue": "10",
                "ISINNo": "INE350C01017",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530139",
                "SecurityId": "KREONFIN",
                "SecurityName": "KREON FINNANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE302C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530141",
                "SecurityId": "GYANDEV",
                "SecurityName": "GYAN DEVELOPERS & BUILDERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE487G01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530143",
                "SecurityId": "PENNA",
                "SecurityName": "PENNAR AQUA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE810B01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530145",
                "SecurityId": "KISAN",
                "SecurityName": "KISAN MOULDINGS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE017C01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530147",
                "SecurityId": "KAPRIPH",
                "SecurityName": "KAPRINAS PHARMACEUTICALS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530149",
                "SecurityId": "KSLIND",
                "SecurityName": "KSL AND INDUSTRIES LTD.-$",
                "FaceValue": "4",
                "ISINNo": "INE219A01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530151",
                "SecurityId": "VIJAYTX",
                "SecurityName": "VIJAY TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE256G01033",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530153",
                "SecurityId": "JOLLYRID",
                "SecurityName": "JOLLY RIDES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE996B01019",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530155",
                "SecurityId": "TONIRPHQ",
                "SecurityName": "TONIRA PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE844F01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530157",
                "SecurityId": "RIVERDAL",
                "SecurityName": "RIVERDALE FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE300E01018",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530159",
                "SecurityId": "GLDCRSC",
                "SecurityName": "GOLDCREST SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530161",
                "SecurityId": "GARODCH",
                "SecurityName": "GARODIA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE236P01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530163",
                "SecurityId": "KERALAYUR",
                "SecurityName": "KERALA AYURVEDA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE817B01025",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530165",
                "SecurityId": "KANCHAN",
                "SecurityName": "KANCHAN INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE924C01019",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530167",
                "SecurityId": "MONGIPA",
                "SecurityName": "MOONGIPA CAPITAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE153K01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530169",
                "SecurityId": "MOHITPPR",
                "SecurityName": "MOHIT PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE388C01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530171",
                "SecurityId": "DAULAT",
                "SecurityName": "DAULAT SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE108C01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530173",
                "SecurityId": "OSCARGLO",
                "SecurityName": "OSCAR GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE473F01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530175",
                "SecurityId": "ODYSSEY",
                "SecurityName": "ODYSSEY TECHNOLOGIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE213B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530177",
                "SecurityId": "SPSINT",
                "SecurityName": "SPS INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE758B01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530179",
                "SecurityId": "RSCINT",
                "SecurityName": "RSC INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE015F01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530181",
                "SecurityId": "SARTHAK",
                "SecurityName": "SARTHAK SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530183",
                "SecurityId": "SCOTTEX",
                "SecurityName": "SCOTTEX INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530185",
                "SecurityId": "SURATEX",
                "SecurityName": "SURAT TEXTILE MILLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE936A01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530187",
                "SecurityId": "ATHARVENT",
                "SecurityName": "ATHARV ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE354E01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530189",
                "SecurityId": "PALAYPU",
                "SecurityName": "PALAYPU FINANCIAL & INVESTMENT SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530191",
                "SecurityId": "CHROMATIC",
                "SecurityName": "CHROMATIC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE662C01015",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530193",
                "SecurityId": "INTERDIA",
                "SecurityName": "INTERNATIONAL DIAMOND SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE248F01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530195",
                "SecurityId": "GAZIFIN",
                "SecurityName": "GAZI FINANCIAL SERVICES & INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE111L01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530197",
                "SecurityId": "FUNDVISER",
                "SecurityName": "FUNDVISER CAPITAL (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE365H01014",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530199",
                "SecurityId": "THEMISMED",
                "SecurityName": "THEMIS MEDICARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE083B01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530201",
                "SecurityId": "KALLAM",
                "SecurityName": "KALLAM TEXTILES LTD-$",
                "FaceValue": "2",
                "ISINNo": "INE629F01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530203",
                "SecurityId": "SANGHAS",
                "SecurityName": "SANGHVI ASBESTOS CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530205",
                "SecurityId": "BLJIGAL",
                "SecurityName": "BALAJI GALVANISING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE892G01019",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530207",
                "SecurityId": "BRAWN",
                "SecurityName": "BRAWN BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE899B01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530211",
                "SecurityId": "AECENTP",
                "SecurityName": "AEC ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530213",
                "SecurityId": "FORINTL",
                "SecurityName": "FORTUNE INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE501D01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530215",
                "SecurityId": "KINGSINFR",
                "SecurityName": "KINGS INFRA VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE050N01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530217",
                "SecurityId": "SWOEF",
                "SecurityName": "SWOJAS ENERGY FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE295B01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530219",
                "SecurityId": "NUTRICIRCLE",
                "SecurityName": "NUTRICIRCLE LTD",
                "FaceValue": "10",
                "ISINNo": "INE536C01029",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530221",
                "SecurityId": "SARITASO",
                "SecurityName": "SARITA SOFTWARE & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE941B01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530223",
                "SecurityId": "SPANDYE",
                "SecurityName": "SPAN DYESTUFF INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530225",
                "SecurityId": "RAJMINC",
                "SecurityName": "RAJENDRA MINING SPARES CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530227",
                "SecurityId": "SURAJHLD",
                "SecurityName": "SURAJ HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE388D01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530229",
                "SecurityId": "SHARODW",
                "SecurityName": "SHAKTI RODS & WIRES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530231",
                "SecurityId": "SUBSM",
                "SecurityName": "SUBHASH SILK MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE690D01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530233",
                "SecurityId": "AUROLAB",
                "SecurityName": "AURO LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE292C01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530235",
                "SecurityId": "KJMCFIN",
                "SecurityName": "KJMC FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE533C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530237",
                "SecurityId": "GANDHHO",
                "SecurityName": "GANDHINAGAR HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE853H01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530239",
                "SecurityId": "SUVEN",
                "SecurityName": "SUVEN LIFE SCIENCES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE495B01038",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530241",
                "SecurityId": "MINHASS",
                "SecurityName": "MINHAS STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530243",
                "SecurityId": "MANGASOF",
                "SecurityName": "MANGALYA SOFT-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE820B01011",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530245",
                "SecurityId": "ARYAMAN",
                "SecurityName": "ARYAMAN FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE032E01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530249",
                "SecurityId": "BRIDGESE",
                "SecurityName": "BRIDGE SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE958C01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530251",
                "SecurityId": "RISAINTL",
                "SecurityName": "RISA INTERNATIONAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE001O01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530253",
                "SecurityId": "RAJTUBE",
                "SecurityName": "RAJASTHAN TUBE MANUFACTURING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE497E01012",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530255",
                "SecurityId": "KAYPOWR",
                "SecurityName": "KAY POWER AND PAPER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE961B01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530257",
                "SecurityId": "MIDAS",
                "SecurityName": "MIDAS PHARMASEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE113D01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530259",
                "SecurityId": "INTSTOIL",
                "SecurityName": "INTER STATE OIL CARRIER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE003B01014",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530261",
                "SecurityId": "ARCUTTIP",
                "SecurityName": "ARCUTTIPORE TEA CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE674C01010",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530263",
                "SecurityId": "GLOBALCA",
                "SecurityName": "GLOBAL CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE062C01034",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530265",
                "SecurityId": "SAINIK",
                "SecurityName": "SAINIK FINANCE & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE584B01013",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530267",
                "SecurityId": "SABOOBR",
                "SecurityName": "SABOO BROTHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE021N01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530269",
                "SecurityId": "SCILVENTU",
                "SecurityName": "SCIL VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE613D01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530271",
                "SecurityId": "RICHUNV",
                "SecurityName": "RICH UNIVERSE NETWORK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE652D01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530273",
                "SecurityId": "LIBERTYP",
                "SecurityName": "LIBERTY PHOSPHATE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE639D01011",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530275",
                "SecurityId": "BASECEM",
                "SecurityName": "BASERA CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530277",
                "SecurityId": "KARAFIN",
                "SecurityName": "KARAN FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530279",
                "SecurityId": "KUNALOVE",
                "SecurityName": "KUNAL OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE513B01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530281",
                "SecurityId": "QUANTDIA",
                "SecurityName": "QUANTUM DIGITAL VISION (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE373C01019",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530283",
                "SecurityId": "ZUARIFOR",
                "SecurityName": "ZUARI FOREX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE579H01010",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530285",
                "SecurityId": "VOLVOST",
                "SecurityName": "VOLVO STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530287",
                "SecurityId": "TRIGZIN",
                "SecurityName": "TRIGON ZINCO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530289",
                "SecurityId": "SPCAPIT",
                "SecurityName": "SP CAPITAL FINANCING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE102F01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530291",
                "SecurityId": "PAOS",
                "SecurityName": "PAOS INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE791C01012",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530293",
                "SecurityId": "WILFI",
                "SecurityName": "WILWAYFORT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE189E01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530295",
                "SecurityId": "SHRAJOI",
                "SecurityName": "SHRI RAJIVLOCHAN OIL EXTRACTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE418K01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530297",
                "SecurityId": "IDEA",
                "SecurityName": "IDEA SPACE SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE311B01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530299",
                "SecurityId": "KOTHARIPRO",
                "SecurityName": "KOTHARI PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE823A01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530303",
                "SecurityId": "NWERAAL",
                "SecurityName": "NEW ERA ALKOLIDES & EXPORT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530305",
                "SecurityId": "PICCADIL",
                "SecurityName": "PICCADILY AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE546C01010",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530307",
                "SecurityId": "CHAMANSEQ",
                "SecurityName": "CHAMAN LAL SETIA EXPORTS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE419D01026",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530309",
                "SecurityId": "CHANDRAP",
                "SecurityName": "CHANDRA PRABHU INTERNATIONAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE368D01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530311",
                "SecurityId": "DELTAPO",
                "SecurityName": "DELTA POLYSTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530313",
                "SecurityId": "KIMIABL",
                "SecurityName": "KIMIA BIOSCIENCES LTD",
                "FaceValue": "1",
                "ISINNo": "INE285U01025",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530315",
                "SecurityId": "HINDTIN",
                "SecurityName": "HINDUSTAN TIN WORKS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE428D01019",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530317",
                "SecurityId": "GODAVARI",
                "SecurityName": "GODAVARI DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE362C01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530319",
                "SecurityId": "VRGOLAI",
                "SecurityName": "VERGOLA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530321",
                "SecurityId": "UNIMININ",
                "SecurityName": "UNIMIN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE672C01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530323",
                "SecurityId": "ERAINFRA",
                "SecurityName": "ERA INFRA ENGINEERING LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE039E01020",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530325",
                "SecurityId": "KUMARCO",
                "SecurityName": "KUMAR&#39;S COTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530327",
                "SecurityId": "KMCAPIT",
                "SecurityName": "KM CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE339T01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530329",
                "SecurityId": "MUKUIND",
                "SecurityName": "MUKUNDA INDUSTRIAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE618B01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530331",
                "SecurityId": "PREMCO",
                "SecurityName": "PREMCO GLOBAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE001E01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530333",
                "SecurityId": "EMGEECA",
                "SecurityName": "EMGEE CABLES & COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE128F01010",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530337",
                "SecurityId": "EXELON",
                "SecurityName": "EXELON INFRASTRUCTURE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE500I01022",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530339",
                "SecurityId": "LABHCON",
                "SecurityName": "LABH CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE962A01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530341",
                "SecurityId": "MUKESHB",
                "SecurityName": "MUKESH BABU FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE596B01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530343",
                "SecurityId": "GENUSPOWER",
                "SecurityName": "GENUS POWER INFRASTRUCTURES LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE955D01029",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530345",
                "SecurityId": "CREATIVW",
                "SecurityName": "CREATIVE WORLD TELEFILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE336B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530347",
                "SecurityId": "MAGNUML",
                "SecurityName": "MAGNUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE591B01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530349",
                "SecurityId": "STONOMA",
                "SecurityName": "STONO MACHINES & CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530353",
                "SecurityId": "JOYHRDM",
                "SecurityName": "JOYMAT HOTEL RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE409D01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530355",
                "SecurityId": "ASIANENE",
                "SecurityName": "ASIAN ENERGY SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE276G01015",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530357",
                "SecurityId": "KBSINDIA",
                "SecurityName": "KBS INDIA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE883D01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530361",
                "SecurityId": "SEAGOLD",
                "SecurityName": "SEA GOLD AQUA FARMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE428P01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530363",
                "SecurityId": "UGARSUGAR",
                "SecurityName": "UGAR SUGAR WORKS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE071E01023",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530365",
                "SecurityId": "ORIENTBELL",
                "SecurityName": "ORIENT BELL LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE607D01018",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530367",
                "SecurityId": "NRBBEARING",
                "SecurityName": "NRB BEARINGS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE349A01021",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530369",
                "SecurityId": "VAMSHIRU",
                "SecurityName": "VAMSHI RUBBER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE380C01014",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530371",
                "SecurityId": "SKRCHEM",
                "SecurityName": "SKR CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530373",
                "SecurityId": "TRACKPA",
                "SecurityName": "TRACKPARTS OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530375",
                "SecurityId": "NAKSHTRINF",
                "SecurityName": "NAKSHATRA INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE226B01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530377",
                "SecurityId": "NILA",
                "SecurityName": "NILA INFRASTRUCTURES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE937C01029",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530379",
                "SecurityId": "JAYFLAS",
                "SecurityName": "JAY FLASH CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530381",
                "SecurityId": "PETRONENGG",
                "SecurityName": "PETRON ENGINEERING CONSTRUCTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE742A01019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530385",
                "SecurityId": "SOUTHPO",
                "SecurityName": "SOUTH POLE SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530387",
                "SecurityId": "EUROSOFTAL",
                "SecurityName": "EUROPEAN SOFTWARE ALLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE511A01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530389",
                "SecurityId": "GEEFC",
                "SecurityName": "GEEFCEE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE632D01016",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530391",
                "SecurityId": "DOTCOM",
                "SecurityName": "DOT COM GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE440B01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530393",
                "SecurityId": "DBSTOCKBRO",
                "SecurityName": "DB (INTERNATIONAL) STOCK BROKERS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE921B01025",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530397",
                "SecurityId": "HIMADRI",
                "SecurityName": "HIMADRI CREDIT & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE018C01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530399",
                "SecurityId": "KAMAKSH",
                "SecurityName": "KAMAKSHI HOUSING FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530401",
                "SecurityId": "VINYOFL",
                "SecurityName": "VINYOFLEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE526H01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530403",
                "SecurityId": "VALLABH",
                "SecurityName": "VALLABH POLY-PLAST INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE862K01014",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530405",
                "SecurityId": "JINDCAP",
                "SecurityName": "JINDAL CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE356F01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530407",
                "SecurityId": "EPIC",
                "SecurityName": "EPIC ENERGY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE932F01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530409",
                "SecurityId": "EUPHARMLAB",
                "SecurityName": "EUPHARMA LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE909A01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530411",
                "SecurityId": "MAGIN",
                "SecurityName": "MAGAN INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE402B01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530413",
                "SecurityId": "ASIANVE",
                "SecurityName": "ASIAN VEGPRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE01OU01016",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530415",
                "SecurityId": "BAYERCROP",
                "SecurityName": "BAYER CROPSCIENCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE556A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530417",
                "SecurityId": "CHIPLUN",
                "SecurityName": "CHIPLUN FINE CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE393G01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530419",
                "SecurityId": "SUMEDHA",
                "SecurityName": "SUMEDHA FISCAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE886B01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530421",
                "SecurityId": "KUWERIN",
                "SecurityName": "KUWER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE430F01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530425",
                "SecurityId": "SUNDYEX",
                "SecurityName": "SUNDAY EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE427H01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530427",
                "SecurityId": "CHOKSI",
                "SecurityName": "CHOKSI IMAGING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE865B01016",
                "Industry": "Photographic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530429",
                "SecurityId": "ASHISHPO",
                "SecurityName": "ASHISH POLYPLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE831C01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530431",
                "SecurityId": "ADORFO",
                "SecurityName": "ADOR FONTECH LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE853A01022",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530433",
                "SecurityId": "SHIVAAGRO",
                "SecurityName": "SHIVA GLOBAL AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE960E01019",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530435",
                "SecurityId": "NOESISIND",
                "SecurityName": "NOESIS INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE141B01020",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530437",
                "SecurityId": "MAYALLO",
                "SecurityName": "MAYURA ALLOY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530439",
                "SecurityId": "SIDDHA",
                "SecurityName": "SIDDHA VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE140C01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530441",
                "SecurityId": "MAHAN",
                "SecurityName": "MAHANIVESH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE641D01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530443",
                "SecurityId": "KIRANSY-B",
                "SecurityName": "KIRAN SYNTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE293D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530445",
                "SecurityId": "SUMERUIND",
                "SecurityName": "SUMERU INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE764B01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530447",
                "SecurityId": "BESCOLT",
                "SecurityName": "BESCO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530449",
                "SecurityId": "RUNGTAIR",
                "SecurityName": "RUNGTA IRRIGATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE347C01013",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530451",
                "SecurityId": "ZNARMDCE",
                "SecurityName": "ZNARMDCE",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530453",
                "SecurityId": "THAPARC",
                "SecurityName": "THAPAR CONCAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530455",
                "SecurityId": "ELVISIN",
                "SecurityName": "ELVISIN",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530457",
                "SecurityId": "CINERAD",
                "SecurityName": "CINERAD COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE959B01017",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530459",
                "SecurityId": "VALSONQ",
                "SecurityName": "VALSON INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE808A01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530461",
                "SecurityId": "SABOOSOD",
                "SecurityName": "SABOO SODIUM CHLORO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE440C01016",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530463",
                "SecurityId": "UMAMAHM",
                "SecurityName": "UMA MAHESWARI MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530465",
                "SecurityId": "CRYSTALC",
                "SecurityName": "CRYSTAL CREDITS CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE036C01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530467",
                "SecurityId": "GUJCYPROM",
                "SecurityName": "GUJARAT CYPROMET LTD.",
                "FaceValue": "10",
                "ISINNo": "INE673B01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530469",
                "SecurityId": "GSLSEC",
                "SecurityName": "GSL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE721D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530471",
                "SecurityId": "BALAJHOTEL",
                "SecurityName": "BALAJI HOTELS & ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE454A01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530473",
                "SecurityId": "ARAVIND",
                "SecurityName": "ARAVALI (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530475",
                "SecurityId": "TINNARUBR",
                "SecurityName": "TINNA RUBBER AND INFRASTRUCTURE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE015C01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530477",
                "SecurityId": "VIKRAMTH",
                "SecurityName": "VIKRAM THERMO (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE337E01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530479",
                "SecurityId": "ATLINFRA",
                "SecurityName": "ATLANTA INFRASTRUCTURE AND FINANCE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE433D01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530481",
                "SecurityId": "KARNATFI",
                "SecurityName": "KARNATAKA FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE873B01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530483",
                "SecurityId": "GRESOIL",
                "SecurityName": "GRESOIL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530485",
                "SecurityId": "SAMARTHI",
                "SecurityName": "SAMARTH INTERNATIONAL FINLEASE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE503C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530487",
                "SecurityId": "VIBROSO",
                "SecurityName": "VIBROS ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE162Q01016",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530491",
                "SecurityId": "SOUTHIR",
                "SecurityName": "SOUTHERN IRON & STEEL COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE710B01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530493",
                "SecurityId": "COIMBAT",
                "SecurityName": "COIMBATORE POPULAR SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530495",
                "SecurityId": "STRATMONT",
                "SecurityName": "STRATMONT INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE473C01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530497",
                "SecurityId": "MARVEL",
                "SecurityName": "MARVEL CAPITAL & FINANCE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE571D01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530499",
                "SecurityId": "AKCAPIT",
                "SecurityName": "A.K.CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE701G01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530501",
                "SecurityId": "SARVODYA",
                "SecurityName": "SARVODAYA LABS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE153B01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530503",
                "SecurityId": "GUJARAN",
                "SecurityName": "GUJARAT NITRATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530505",
                "SecurityId": "UPERGANGES",
                "SecurityName": "UPPER GANGES SUGAR & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE018B01012",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530507",
                "SecurityId": "RAJMI",
                "SecurityName": "RAAJRATNA METAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE182B01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530509",
                "SecurityId": "VANTECH-B",
                "SecurityName": "VANTECH INDUSTRY LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530511",
                "SecurityId": "ARMAANE",
                "SecurityName": "ARMAAN ELECTRIC LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530513",
                "SecurityId": "ACCURATE",
                "SecurityName": "ACCURATE TRANSFORMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE243C01014",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530515",
                "SecurityId": "SHRIRAM",
                "SecurityName": "SHRIRAM OVERSEAS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE917G01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530517",
                "SecurityId": "RELAXO",
                "SecurityName": "RELAXO FOOTWEARS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE131B01039",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530519",
                "SecurityId": "INTERFAC",
                "SecurityName": "INTERFACE FINANCIAL SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE778C01027",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530521",
                "SecurityId": "VIRAT",
                "SecurityName": "VIRAT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE467D01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530523",
                "SecurityId": "KARISHM",
                "SecurityName": "KARISHMA FLORICULTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530525",
                "SecurityId": "SHEETAL",
                "SecurityName": "SHEETAL DIAMONDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE786J01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530527",
                "SecurityId": "TRANSAG",
                "SecurityName": "TRANS AGRO TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530529",
                "SecurityId": "KAMALAT",
                "SecurityName": "KAMALA TEA CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530531",
                "SecurityId": "TRIENTP",
                "SecurityName": "TRIENTP",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530533",
                "SecurityId": "TERAI",
                "SecurityName": "TERAI TEA CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE390D01011",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530535",
                "SecurityId": "HINFINS",
                "SecurityName": "HINDUSTAN FINSTOCK LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530537",
                "SecurityId": "MANRAJH",
                "SecurityName": "MANRAJ HOUSING FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE948I01015",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530539",
                "SecurityId": "SUPRAPT",
                "SecurityName": "SUPRAPTI PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530543",
                "SecurityId": "MARG",
                "SecurityName": "MARG LTD.",
                "FaceValue": "10",
                "ISINNo": "INE941E01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530545",
                "SecurityId": "COSCO",
                "SecurityName": "COSCO (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE949B01018",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530547",
                "SecurityId": "KENFIN",
                "SecurityName": "KEN FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE395E01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530549",
                "SecurityId": "SHILPAMED",
                "SecurityName": "SHILPA MEDICARE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE790G01031",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530551",
                "SecurityId": "MONINFO",
                "SecurityName": "MONALISA INFOTECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE021B01024",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530553",
                "SecurityId": "GLOBALI",
                "SecurityName": "GLOBAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530555",
                "SecurityId": "PARACABLES",
                "SecurityName": "PARAMOUNT COMMUNICATIONS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE074B01023",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530557",
                "SecurityId": "NCLRESE",
                "SecurityName": "NCL RESEARCH & FINANCIAL SERVICES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE132F01020",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530559",
                "SecurityId": "PIYUSHT",
                "SecurityName": "PIYUSH STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530561",
                "SecurityId": "RADGLOBAL",
                "SecurityName": "RADFORD GLOBAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE783M01026",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530563",
                "SecurityId": "STRONTI",
                "SecurityName": "STRONTIUM FERRITEN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530565",
                "SecurityId": "SSLFINANCE",
                "SecurityName": "ARCHANA SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE149B01015",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530567",
                "SecurityId": "ASBUSIN",
                "SecurityName": "ASSOCIATED BUSINESS CREDITS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530569",
                "SecurityId": "SKNIND",
                "SecurityName": "SKN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE931C01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530571",
                "SecurityId": "EXPLICITFIN",
                "SecurityName": "EXPLICIT FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE335G01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530573",
                "SecurityId": "TRINITY",
                "SecurityName": "TRINITY BIO-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE785B01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530575",
                "SecurityId": "SUNSS",
                "SecurityName": "SUNSTAR SOFTWARE SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE296B01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530577",
                "SecurityId": "LADDERUP",
                "SecurityName": "LADDERUP FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE519D01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530579",
                "SecurityId": "UYFINCORP",
                "SecurityName": "U. Y. FINCORP LTD",
                "FaceValue": "5",
                "ISINNo": "INE152C01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530581",
                "SecurityId": "EKAMLEA",
                "SecurityName": "EKAM LEASING & FINANCE CO.LTD.",
                "FaceValue": "5",
                "ISINNo": "INE906L01025",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530583",
                "SecurityId": "VOGUETEX",
                "SecurityName": "VOGUE TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE047F01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530585",
                "SecurityId": "SWASTIKA",
                "SecurityName": "SWASTIKA INVESTMART LTD.",
                "FaceValue": "10",
                "ISINNo": "INE691C01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530587",
                "SecurityId": "BARKHAI",
                "SecurityName": "BARKHA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530589",
                "SecurityId": "PRIMAPLA",
                "SecurityName": "PRIMA PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE573B01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530591",
                "SecurityId": "NOBLEBR",
                "SecurityName": "NOBLE BROTHERS IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530593",
                "SecurityId": "SIRHIND",
                "SecurityName": "SIRHIND STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530595",
                "SecurityId": "TELECANOR",
                "SecurityName": "TELECANOR GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE381G01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530597",
                "SecurityId": "VIJAYSH",
                "SecurityName": "VIJAY SHANTHI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530599",
                "SecurityId": "GMSCOM",
                "SecurityName": "GMSCOM",
                "FaceValue": "10",
                "ISINNo": "INE106B01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530601",
                "SecurityId": "JAGSONFI",
                "SecurityName": "JAGSONPAL FINANCE & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE582C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530603",
                "SecurityId": "KANDHAR",
                "SecurityName": "KANDHARI RUBBERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530605",
                "SecurityId": "GSLNOVA",
                "SecurityName": "GSL NOVA PETROCHEMICALS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE787A01022",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530607",
                "SecurityId": "JAYFINL",
                "SecurityName": "JAY FINLEASE AND MANAGEMENT SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530609",
                "SecurityId": "CARNATIN",
                "SecurityName": "CARNATION INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE081B01010",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530611",
                "SecurityId": "STURDY",
                "SecurityName": "STURDY INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE134F01026",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530615",
                "SecurityId": "GARGFUR",
                "SecurityName": "GARG FURNACE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE194E01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530617",
                "SecurityId": "SAMPRE",
                "SecurityName": "SAMPRE NUTRITIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE375C01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530619",
                "SecurityId": "RELIGLOBE",
                "SecurityName": "RELIGARE TECHNOVA GLOBAL SOLUTIONS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE603B01029",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530621",
                "SecurityId": "AAIL",
                "SecurityName": "AKAR AUTO INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE864E01021",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530623",
                "SecurityId": "JAINPUR",
                "SecurityName": "JAINPUR STRAW BOARDS & PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530625",
                "SecurityId": "SOLIMTD-B",
                "SecurityName": "SOL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530627",
                "SecurityId": "VIPULORG",
                "SecurityName": "VIPUL ORGANICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE834D01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530629",
                "SecurityId": "MONARCH",
                "SecurityName": "MONARCH DYESTUFFS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530631",
                "SecurityId": "ROSEZIN",
                "SecurityName": "ROSE ZINC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE018F01013",
                "Industry": "Zinc",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530633",
                "SecurityId": "HITKARIIN",
                "SecurityName": "HITKARI INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE553B01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530635",
                "SecurityId": "TEXPI",
                "SecurityName": "TEXPLAST INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE544D01013",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530637",
                "SecurityId": "BLINDUS",
                "SecurityName": "B.L.INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530641",
                "SecurityId": "SHRINIR",
                "SecurityName": "SHRI NIRANJAN AYURVED BHAVAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE942B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530643",
                "SecurityId": "ECORECO",
                "SecurityName": "ECO RECYCLING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE316A01038",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530645",
                "SecurityId": "MALVI",
                "SecurityName": "MALVIKA STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE190A01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530647",
                "SecurityId": "KANVAFI",
                "SecurityName": "KANVA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530651",
                "SecurityId": "SOFTECH",
                "SecurityName": "SOFTECH INFINIUM SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE181K01019",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530653",
                "SecurityId": "SHGANES",
                "SecurityName": "SHREE GANESH KNIT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530655",
                "SecurityId": "GOODLUCK",
                "SecurityName": "GOODLUCK INDIA LTD",
                "FaceValue": "2",
                "ISINNo": "INE127I01024",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530657",
                "SecurityId": "GLOBSEC",
                "SecurityName": "GLOBAL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE742K01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530661",
                "SecurityId": "REGBLUI",
                "SecurityName": "REGAUL BLUE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530663",
                "SecurityId": "GOYALASS",
                "SecurityName": "GOYAL ASSOCIATES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE912B01032",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530665",
                "SecurityId": "ZENITHHE",
                "SecurityName": "ZENITH HEALTH CARE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE812B01026",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530667",
                "SecurityId": "SRINANDAA",
                "SecurityName": "SRI NANDAA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE836E01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530669",
                "SecurityId": "PRESOFI",
                "SecurityName": "PREM SOMANI FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE035401012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530673",
                "SecurityId": "PCRKPHA",
                "SecurityName": "PERK PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530675",
                "SecurityId": "YORKEXP",
                "SecurityName": "YORK EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE057Q01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530677",
                "SecurityId": "SUPREME",
                "SecurityName": "SUPREME HOLDINGS & HOSPITALITY (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE822E01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530679",
                "SecurityId": "SHARDAS",
                "SecurityName": "SHARDA SOLVENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530681",
                "SecurityId": "DIARUZA",
                "SecurityName": "DIARUZA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530683",
                "SecurityId": "PITHP",
                "SecurityName": "PITHAMPUR POLY PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE747D01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530685",
                "SecurityId": "GULABDA",
                "SecurityName": "GULABDAS FLEXIPACK INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530689",
                "SecurityId": "LYKISLTD",
                "SecurityName": "LYKIS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE624M01014",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530691",
                "SecurityId": "AKASAGR",
                "SecurityName": "AKASH AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530693",
                "SecurityId": "ADITYAINF",
                "SecurityName": "ADITYA INFO-SOFT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE524F01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530695",
                "SecurityId": "PRIMEPRO",
                "SecurityName": "PRIME PROPERTY DEVELOPMENT CORPORATION LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE233C01023",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530697",
                "SecurityId": "ZENLABS",
                "SecurityName": "ZENLABS ETHICA LTD",
                "FaceValue": "10",
                "ISINNo": "INE546F01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530699",
                "SecurityId": "RAJRAYON",
                "SecurityName": "RAJ RAYON INDUSTRIES LIMITED-$",
                "FaceValue": "1",
                "ISINNo": "INE533D01024",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530701",
                "SecurityId": "KDJHRL",
                "SecurityName": "KDJ HOLIDAYSCAPES AND RESORTS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE089E01025",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530703",
                "SecurityId": "INFODRIVE",
                "SecurityName": "INFO-DRIVE SOFTWARE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE804D01029",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530705",
                "SecurityId": "FLORATX",
                "SecurityName": "FLORA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE161F01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530707",
                "SecurityId": "AFTEK",
                "SecurityName": "AFTEK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE796A01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530709",
                "SecurityId": "GOWRALE",
                "SecurityName": "GOWRA LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE225G01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530711",
                "SecurityId": "JAGANLAM",
                "SecurityName": "JAGAN LAMPS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE144C01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530713",
                "SecurityId": "AJEL",
                "SecurityName": "AJEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE229B01015",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530715",
                "SecurityId": "ALPSINDUS",
                "SecurityName": "ALPS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE093B01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530717",
                "SecurityId": "EGGROPA",
                "SecurityName": "EGGRO PAPER MOULDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE093F01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530719",
                "SecurityId": "IKFIN",
                "SecurityName": "IKF FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE859C01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530721",
                "SecurityId": "ANGIND",
                "SecurityName": "ANG INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE017D01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530723",
                "SecurityId": "ASITCFIN",
                "SecurityName": "ASIT C.MEHTA FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE041B01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530725",
                "SecurityId": "NUBALIN",
                "SecurityName": "NUBAL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE007F01016",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530727",
                "SecurityId": "TERRYFB",
                "SecurityName": "TERRYFAB (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530729",
                "SecurityId": "SCINTSOFT",
                "SecurityName": "SCINTILLA SOFTWARE TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE355B01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530733",
                "SecurityId": "NOVAPUB",
                "SecurityName": "NOVA PUBLICATIONS INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE900O01014",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530735",
                "SecurityId": "SUPERBAK",
                "SecurityName": "SUPER BAKERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE897A01011",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530737",
                "SecurityId": "BAJAI",
                "SecurityName": "BAJWA AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE337C01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530741",
                "SecurityId": "OVOBELE",
                "SecurityName": "OVOBEL FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE812S01012",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530743",
                "SecurityId": "GEINDSYS",
                "SecurityName": "GEI INDUSTRIAL SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE599B01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530745",
                "SecurityId": "LNIND",
                "SecurityName": "LN INDUSTRIES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE366C01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530747",
                "SecurityId": "INDOASIAF",
                "SecurityName": "INDO ASIAN FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE807A01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530749",
                "SecurityId": "AHURAWE",
                "SecurityName": "AHURA WELDING ELECTRODE MANUFACTURERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530751",
                "SecurityId": "BULLPOWDM",
                "SecurityName": "BULL POWER SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530753",
                "SecurityId": "SURYOPLA",
                "SecurityName": "SURYODAYA PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE998B01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530755",
                "SecurityId": "CORNE",
                "SecurityName": "CORAL NEWSPRINTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE715D01019",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530759",
                "SecurityId": "STERTOOLS",
                "SecurityName": "STERLING TOOLS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE334A01023",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530761",
                "SecurityId": "SATHYAK",
                "SecurityName": "SATHYA KAMAL AGROS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530763",
                "SecurityId": "SUNBRIGHT",
                "SecurityName": "SUNBRIGHT STOCK BROKING LTD.",
                "FaceValue": "5",
                "ISINNo": "INE476D01026",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530765",
                "SecurityId": "DEVKI",
                "SecurityName": "DEVKI LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE510B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530767",
                "SecurityId": "FLEXICI",
                "SecurityName": "FLEXI CIRCUITS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530769",
                "SecurityId": "VENUSUNI",
                "SecurityName": "VENUS UNIVERSAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE381C01020",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530771",
                "SecurityId": "KLGCAP",
                "SecurityName": "KLG CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE929C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530773",
                "SecurityId": "IVRCLINFRA",
                "SecurityName": "IVRCL LTD",
                "FaceValue": "2",
                "ISINNo": "INE875A01025",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530775",
                "SecurityId": "WALKERIDM",
                "SecurityName": "WALKER INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530777",
                "SecurityId": "INFRAIND",
                "SecurityName": "INFRA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE287D01019",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530779",
                "SecurityId": "DYNAMICP",
                "SecurityName": "DYNAMIC PORTFOLIO MANAGEMENT & SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE118C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530781",
                "SecurityId": "INTERHG",
                "SecurityName": "INTERNATIONAL HOUSING FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE903G01014",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530783",
                "SecurityId": "TRANSASIA",
                "SecurityName": "TRANS ASIA CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE321C01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530785",
                "SecurityId": "PITAMBER",
                "SecurityName": "PITAMBAR COATED PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE189C01019",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530787",
                "SecurityId": "INLANPR",
                "SecurityName": "INLAND PRINTERS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE055O01025",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530789",
                "SecurityId": "CEEJAY",
                "SecurityName": "CEEJAY FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE358C01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530791",
                "SecurityId": "PARAKTE",
                "SecurityName": "PARAKARAM TECHNOFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530793",
                "SecurityId": "PENTAPH",
                "SecurityName": "PENTA PHARMADYES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE195C01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530795",
                "SecurityId": "SUNCITYSY",
                "SecurityName": "SUNCITY SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE584D01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530797",
                "SecurityId": "SHGANEL",
                "SecurityName": "SHREE GANESH ELASTOPLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE400N01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530799",
                "SecurityId": "ANNAINFRA",
                "SecurityName": "ANNA INFRASTRUCTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE336D01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530801",
                "SecurityId": "DIAMOND",
                "SecurityName": "DIAMOND INFOSYSTEMS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE280D01022",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530803",
                "SecurityId": "BHAGIL",
                "SecurityName": "BHAGERIA INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE354C01027",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530805",
                "SecurityId": "OIVL",
                "SecurityName": "ONESOURCE IDEAS VENTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE125F01024",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530807",
                "SecurityId": "REGALIAA",
                "SecurityName": "REGALIAA REALTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE098H01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530809",
                "SecurityId": "BNRUDY",
                "SecurityName": "BNR UDYOG LTD.",
                "FaceValue": "10",
                "ISINNo": "INE355C01016",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530811",
                "SecurityId": "NETVISTAIT",
                "SecurityName": "NETVISTA INFORMATION TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE738A01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530813",
                "SecurityId": "KRBL",
                "SecurityName": "KRBL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE001B01026",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530815",
                "SecurityId": "REFNOL",
                "SecurityName": "REFNOL RESINS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE428C01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530817",
                "SecurityId": "TEATXEP",
                "SecurityName": "TEAKTEX PROCESSING COMPLEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530821",
                "SecurityId": "SSPDL",
                "SecurityName": "SSPDL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE838C01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530823",
                "SecurityId": "SOMATR",
                "SecurityName": "SOMANI STRIPS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530825",
                "SecurityId": "DAIKAFFI",
                "SecurityName": "DAIKAFFIL CHEMICALS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE789B01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530827",
                "SecurityId": "KEMWINT-B",
                "SecurityName": "KEMWINT-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530829",
                "SecurityId": "CILSEC",
                "SecurityName": "CIL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE830A01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530833",
                "SecurityId": "ABEEINF",
                "SecurityName": "ABEE INFO-CONSUMABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE097B01016",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530835",
                "SecurityId": "ELTROL",
                "SecurityName": "ELTROL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE793D01024",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530839",
                "SecurityId": "CLIOINFO",
                "SecurityName": "CLIO INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE011B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530841",
                "SecurityId": "SHRIBCL",
                "SecurityName": "SHRI BHOLANATH CARPETS LTD",
                "FaceValue": "10",
                "ISINNo": "INE151F01012",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530843",
                "SecurityId": "CUPID",
                "SecurityName": "CUPID LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE509F01011",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530845",
                "SecurityId": "SUNSHIEL",
                "SecurityName": "SUNSHIELD CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE199E01014",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530847",
                "SecurityId": "KANOPLA",
                "SecurityName": "KANORIA PLASCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530849",
                "SecurityId": "CODUREXDM",
                "SecurityName": "CODURAS EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530853",
                "SecurityId": "HIPOLIN",
                "SecurityName": "HIPOLIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE963A01011",
                "Industry": "Household Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530855",
                "SecurityId": "GDLLEAS",
                "SecurityName": "GDL LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545E01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530857",
                "SecurityId": "GARGFOR",
                "SecurityName": "GARG FORGINGS & CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530859",
                "SecurityId": "COSBOARD",
                "SecurityName": "COSBOARD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE496D01016",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530861",
                "SecurityId": "ASINFRA",
                "SecurityName": "ASIATIC INFRASTRUCTURE & SHELTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530863",
                "SecurityId": "FEINDIALTD",
                "SecurityName": "FE (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE708C01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530865",
                "SecurityId": "DHANELE",
                "SecurityName": "DHANASHREE ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE413F01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530867",
                "SecurityId": "SFLINTER",
                "SecurityName": "SFL INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE320C01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530869",
                "SecurityId": "PRAMANC",
                "SecurityName": "PRAMAN CAPITAL MARKET SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530871",
                "SecurityId": "CHEMBOND",
                "SecurityName": "CHEMBOND CHEMICALS LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE995D01025",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530875",
                "SecurityId": "GIRHOTE",
                "SecurityName": "GIRISH HOTELRESORTS & HEALTH FARM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530877",
                "SecurityId": "MODFC",
                "SecurityName": "MODEL FINANCIAL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE166B01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530879",
                "SecurityId": "CIFL",
                "SecurityName": "CAPITAL INDIA FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE345H01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530881",
                "SecurityId": "ABVL",
                "SecurityName": "ARUNJYOTI BIO VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE485K01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530883",
                "SecurityId": "SUCROSA",
                "SecurityName": "SUPER CROP SAFE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE366G01022",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530885",
                "SecurityId": "FIVECORE",
                "SecurityName": "FIVE CORE EXIM LTD",
                "FaceValue": "2",
                "ISINNo": "INE600D01021",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530887",
                "SecurityId": "INCFS",
                "SecurityName": "INCAP FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE800D01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530889",
                "SecurityId": "ALKA",
                "SecurityName": "ALKA INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE061B01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530891",
                "SecurityId": "RKB",
                "SecurityName": "RKB AGRO INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE740A01013",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530893",
                "SecurityId": "MOULIKF",
                "SecurityName": "MOULIK FINANCE & RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530897",
                "SecurityId": "NGIND",
                "SecurityName": "N.G.INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE825C01018",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530899",
                "SecurityId": "ASIAPAK",
                "SecurityName": "ASIA PACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE784M01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530901",
                "SecurityId": "ACIL",
                "SecurityName": "ACIL COTTON INDUSTRIES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE066C01035",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530905",
                "SecurityId": "SAINDUS",
                "SecurityName": "SAI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE213S01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530907",
                "SecurityId": "SICAPIT",
                "SecurityName": "SI CAPITAL & FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE417F01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530909",
                "SecurityId": "ERPSOFT",
                "SecurityName": "ERP SOFT SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE308B01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530911",
                "SecurityId": "INDOBRIT",
                "SecurityName": "INDO BRITAIN AGRO FARMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE145C01011",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530913",
                "SecurityId": "SHBHAGV",
                "SecurityName": "SHRI BHAGAVATI BRIGHT BARS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530915",
                "SecurityId": "JRFOODS",
                "SecurityName": "J.R.FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE989E01018",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530917",
                "SecurityId": "RRSECUR",
                "SecurityName": "R.R.SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE474H01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530919",
                "SecurityId": "REMSONSIND",
                "SecurityName": "REMSONS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE474C01015",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530921",
                "SecurityId": "INTETHR",
                "SecurityName": "INTEGRATED THERMOPLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE038N01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530923",
                "SecurityId": "RCLFOODS",
                "SecurityName": "RCL FOODS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE008F01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530925",
                "SecurityId": "RAMSONS",
                "SecurityName": "RAMSONS PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE609D01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530929",
                "SecurityId": "EUROASIA",
                "SecurityName": "EURO ASIA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE535P01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530931",
                "SecurityId": "STANPACK",
                "SecurityName": "STANPACKS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE457D01018",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530933",
                "SecurityId": "CILSOFN",
                "SecurityName": "CILSON FINANCE & INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530935",
                "SecurityId": "SUNOLEO",
                "SecurityName": "SUNRISE OLEOCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530937",
                "SecurityId": "UMSTECHNO",
                "SecurityName": "UMS TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530943",
                "SecurityId": "SABTN",
                "SecurityName": "SRI ADHIKARI BROTHERS TELEVISION NETWORK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416A01036",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530945",
                "SecurityId": "GISL",
                "SecurityName": "GANGOTRI IRON & STEEL COMPANY LTD.",
                "FaceValue": "5",
                "ISINNo": "INE437F01023",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530949",
                "SecurityId": "DHARTEX",
                "SecurityName": "DHAR TEXTILE MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE044B01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530951",
                "SecurityId": "RAMINFO",
                "SecurityName": "RAMINFO LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE357B01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530953",
                "SecurityId": "SUNILAGR",
                "SecurityName": "SUNIL AGRO FOODS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE224D01012",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530955",
                "SecurityId": "MINDVISCAP",
                "SecurityName": "MINDVISION CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE259E01032",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530957",
                "SecurityId": "JAINGRA",
                "SecurityName": "JAIN GRANITES & PROJECTS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530959",
                "SecurityId": "DIANATEA",
                "SecurityName": "DIANA TEA CO.LTD.",
                "FaceValue": "5",
                "ISINNo": "INE012E01035",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530961",
                "SecurityId": "VIKASECO",
                "SecurityName": "VIKAS ECOTECH LTD",
                "FaceValue": "1",
                "ISINNo": "INE806A01020",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530963",
                "SecurityId": "JGFINAN",
                "SecurityName": "J.G.FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530965",
                "SecurityId": "IOC",
                "SecurityName": "INDIAN OIL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE242A01010",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530967",
                "SecurityId": "KYRALANDS",
                "SecurityName": "KYRA LANDSCAPES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE094M01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530971",
                "SecurityId": "NIMBUSI",
                "SecurityName": "NIMBUS INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE470M01020",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530973",
                "SecurityId": "ALFAICA",
                "SecurityName": "ALFA ICA (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE042C01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530977",
                "SecurityId": "SKCIL",
                "SecurityName": "SHRI KESHAV CEMENTS AND INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE260E01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530979",
                "SecurityId": "INDIAHOME",
                "SecurityName": "INDIA HOME LOAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE274E01015",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530981",
                "SecurityId": "SRHSYNT",
                "SecurityName": "SRH SYNTHETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416C01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530983",
                "SecurityId": "GARVEEGDM",
                "SecurityName": "GARVEE GRANITE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530985",
                "SecurityId": "JPTSEC",
                "SecurityName": "JPT SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE630C01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530987",
                "SecurityId": "LSKFINA",
                "SecurityName": "LSK FINANCE AND ALLIED SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530989",
                "SecurityId": "SHANTIVI",
                "SecurityName": "SHANTIVIJAY JEWELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE656D01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530991",
                "SecurityId": "ROOPAIND",
                "SecurityName": "ROOPA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE443C01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530993",
                "SecurityId": "SARTHAKGL",
                "SecurityName": "SARTHAK GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE075H01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530995",
                "SecurityId": "SHIVEGGDM",
                "SecurityName": "SHIVA EGG PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530997",
                "SecurityId": "UNIQUEO",
                "SecurityName": "UNIQUE ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE333E01019",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "530999",
                "SecurityId": "BALAMINES",
                "SecurityName": "BALAJI AMINES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE050E01027",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531001",
                "SecurityId": "KAMAR",
                "SecurityName": "KAMAR CHEMICALS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE728D01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531003",
                "SecurityId": "SWRNASE",
                "SecurityName": "SWARNA SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE595G01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531005",
                "SecurityId": "PASCHIM",
                "SecurityName": "PASCHIM PETROCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE066B01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531007",
                "SecurityId": "INTSE",
                "SecurityName": "INTERNATIONAL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE086D01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531009",
                "SecurityId": "BETACOR",
                "SecurityName": "BETA CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531011",
                "SecurityId": "EMKAR",
                "SecurityName": "EMKAY AROMATICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE180D01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531013",
                "SecurityId": "SUNGRAN",
                "SecurityName": "SUN GRANITE EXPORT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE032M01010",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531015",
                "SecurityId": "VENMAX",
                "SecurityName": "VENMAX DRUGS AND PHARMACEUTICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE154G01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531017",
                "SecurityId": "ARISE",
                "SecurityName": "ARIHANT&#39;S SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE190D01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531019",
                "SecurityId": "SHJAGDM",
                "SecurityName": "SHREE JAGDAMBE PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE198F01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531021",
                "SecurityId": "KUBERFL",
                "SecurityName": "KUBER FLORITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531023",
                "SecurityId": "TAURGEN",
                "SecurityName": "TAURGEN",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531025",
                "SecurityId": "VISAGAR",
                "SecurityName": "VISAGAR FINANCIAL SERVICES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE309H01020",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531027",
                "SecurityId": "LIBORD",
                "SecurityName": "LIBORD SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE267E01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531029",
                "SecurityId": "BHATEXT",
                "SecurityName": "BHARAT TEXTILES & PROOFING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE201N01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531031",
                "SecurityId": "WELWORT",
                "SecurityName": "WELWORTH ELECTRIC CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531032",
                "SecurityId": "ZTAURGEN",
                "SecurityName": "ZTAURGEN",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531033",
                "SecurityId": "REGAL",
                "SecurityName": "REGAL ENTERTAINMENT & CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE101E01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531035",
                "SecurityId": "JRELTD",
                "SecurityName": "JUSTRIDE ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE432F01024",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531037",
                "SecurityId": "ZBPLREPP",
                "SecurityName": "BPL REFRIGER",
                "FaceValue": " ",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531041",
                "SecurityId": "COMPEAU",
                "SecurityName": "COMPETENT AUTOMOBILES CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE823B01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531043",
                "SecurityId": "ZDHJERK",
                "SecurityName": "DHANVANTRI JEEVAN REKHA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE239F01015",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531045",
                "SecurityId": "PRLAMIN",
                "SecurityName": "PREMIER LAMINATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531047",
                "SecurityId": "ADVPOWER",
                "SecurityName": "ADVANCE POWERINFRA TECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE009D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531049",
                "SecurityId": "NEELKAN",
                "SecurityName": "NEELKANTH ROCKMINERALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE741D01015",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531051",
                "SecurityId": "VINTAGES",
                "SecurityName": "VINTAGE SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE153C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531053",
                "SecurityId": "INDIM",
                "SecurityName": "INDIA INTERNATIONAL MARKETING CENTRE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE506C01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531055",
                "SecurityId": "GFLFIN",
                "SecurityName": "GFL FINANCIALS INDIA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE764K01038",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531057",
                "SecurityId": "RITESPL",
                "SecurityName": "RITESH POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531059",
                "SecurityId": "HATIGOR",
                "SecurityName": "HATIGOR TEA ESTATES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE205E01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531061",
                "SecurityId": "INDECOM",
                "SecurityName": "INDIA E-COMMERCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416B01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531063",
                "SecurityId": "PRSTEEL",
                "SecurityName": "PROGRESSIVE STEELS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531065",
                "SecurityId": "OSWALOR",
                "SecurityName": "OSWAL OVERSEAS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE906K01027",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531067",
                "SecurityId": "CONTILI",
                "SecurityName": "CONTIL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE080G01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531069",
                "SecurityId": "VIJSOLX",
                "SecurityName": "VIJAY SOLVEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE362D01010",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531073",
                "SecurityId": "BLUEDIA",
                "SecurityName": "BLUE DIAMOND SECURITIES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE454C01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531075",
                "SecurityId": "NUTEORG",
                "SecurityName": "NU TECH ORGANIC CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531077",
                "SecurityId": "NEPCPAPER",
                "SecurityName": "NEPC PAPER & BOARD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE471B01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531079",
                "SecurityId": "RAVRAJI",
                "SecurityName": "RAVRAJ IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531080",
                "SecurityId": "SHRIKRISH",
                "SecurityName": "SHRI KRISHNA DEVCON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE997I01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531082",
                "SecurityId": "ALANKIT",
                "SecurityName": "ALANKIT LTD",
                "FaceValue": "1",
                "ISINNo": "INE914E01040",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531083",
                "SecurityId": "NIHARINF",
                "SecurityName": "NIHAR INFO GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE876E01033",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531084",
                "SecurityId": "INDOBONIT",
                "SecurityName": "INDO BONITO MULTINATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE457B01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531085",
                "SecurityId": "LAKHANI",
                "SecurityName": "LAKHANI INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE935C01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531086",
                "SecurityId": "NAPAPER",
                "SecurityName": "NAPA PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531087",
                "SecurityId": "KASHYAP",
                "SecurityName": "KASHYAP FISCAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531088",
                "SecurityId": "TULIPSTA",
                "SecurityName": "TULIP STAR HOTELS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE379C01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531089",
                "SecurityId": "SWISSEN",
                "SecurityName": "SWISSEN INTERSPACE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531091",
                "SecurityId": "UNITDCR",
                "SecurityName": "UNITED CREDIT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE858C01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531092",
                "SecurityId": "OMMETALS",
                "SecurityName": "OM METALS INFRAPROJECTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE239D01028",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531093",
                "SecurityId": "OPTEL",
                "SecurityName": "OPTEL TELECOMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE958B01019",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531094",
                "SecurityId": "HARBORNE",
                "SecurityName": "HARBOR NETWORK SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE021C01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531095",
                "SecurityId": "PRIMETA",
                "SecurityName": "PRIME METALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531096",
                "SecurityId": "MOUNTEVE",
                "SecurityName": "MOUNT EVEREST MINERAL WATER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE690B01018",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531098",
                "SecurityId": "JIWANFL",
                "SecurityName": "JIWAN FLORA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531099",
                "SecurityId": "RUBRAME",
                "SecurityName": "RUBRA MEDICAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE396H01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531102",
                "SecurityId": "SURANACORP",
                "SecurityName": "SURANA CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE357D01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531103",
                "SecurityId": "LIBERAL",
                "SecurityName": "LIBERAL FINLEASE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531107",
                "SecurityId": "ZAUTPALI",
                "SecurityName": "ZAUTPALI",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531108",
                "SecurityId": "GJPROFN",
                "SecurityName": "GJPROFN",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531109",
                "SecurityId": "ISHANCH",
                "SecurityName": "ISHAN DYES & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE561M01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531110",
                "SecurityId": "RLPETRODM",
                "SecurityName": "RLPETRODM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531111",
                "SecurityId": "GOTHIPL",
                "SecurityName": "GOTHI PLASCON (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE538G01018",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531112",
                "SecurityId": "BALUFORGE",
                "SecurityName": "BALU FORGE INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE011E01029",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531113",
                "SecurityId": "KARSPIN",
                "SecurityName": "KARNAVATI SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531114",
                "SecurityId": "GDRMEDIA",
                "SecurityName": "GDR MEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE946B01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531115",
                "SecurityId": "SYNERGY",
                "SecurityName": "SYNERGY COSMETICS (EXIM) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE198D01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531116",
                "SecurityId": "KHEMGLB",
                "SecurityName": "KHEMSONS GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531118",
                "SecurityId": "SILSPL",
                "SecurityName": "SILVERTON SPINNERS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE034C01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531119",
                "SecurityId": "CEENIK",
                "SecurityName": "CEENIK EXPORTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE418D01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531120",
                "SecurityId": "PATELENG",
                "SecurityName": "PATEL ENGINEERING LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE244B01030",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531121",
                "SecurityId": "SIEOP",
                "SecurityName": "SIERRA OPTIMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE046B01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531122",
                "SecurityId": "GLDSTRW",
                "SecurityName": "GOLD STAR STRAW PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531123",
                "SecurityId": "STALGAR",
                "SecurityName": "STALLION GARMENTS EXPORT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531124",
                "SecurityId": "SAHACEM",
                "SecurityName": "SAHAS CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531125",
                "SecurityId": "BLUMARI",
                "SecurityName": "BLUEGOLD MARITECH (INTERNATIONAL) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531126",
                "SecurityId": "VIRTUALS",
                "SecurityName": "VIRTUALSOFT SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE237C01016",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531127",
                "SecurityId": "MENAMANI",
                "SecurityName": "MENA MANI INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE148B01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531128",
                "SecurityId": "INDFO",
                "SecurityName": "INDUSMIN FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE485E01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531129",
                "SecurityId": "INANI",
                "SecurityName": "INANI MARBLES & INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE635D01027",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531130",
                "SecurityId": "MODIHOOV",
                "SecurityName": "MODI HOOVER INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE259D01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531131",
                "SecurityId": "MASCONGLO",
                "SecurityName": "MASCON GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE896A01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531132",
                "SecurityId": "CONTAIN",
                "SecurityName": "CONTAINERWAY INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531133",
                "SecurityId": "ASIFE",
                "SecurityName": "ASIATIC FOOD (EXPORTS) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE764C01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531134",
                "SecurityId": "LEWATERIN",
                "SecurityName": "LE WATERINA RESORTS & HOTELS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE252K01026",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531135",
                "SecurityId": "PITTSIRON",
                "SecurityName": "PITTSBURGH IRON & STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE765E01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531136",
                "SecurityId": "SPARKSH",
                "SecurityName": "SPARKLING (INDIA) FINSHARES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531137",
                "SecurityId": "GEMSI",
                "SecurityName": "GEMSTONE INVESTMENTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE503D01027",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531138",
                "SecurityId": "COGRANI",
                "SecurityName": "COROMANDEL GRANITE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531139",
                "SecurityId": "ZRNKEXPO",
                "SecurityName": "ZRNKEXPO",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531141",
                "SecurityId": "SRJTC",
                "SecurityName": "SRI JAYALAKSHMI SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE355D01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531142",
                "SecurityId": "CHIRAUF",
                "SecurityName": "CHIRAU FINANCE INVESTMENT AND LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531143",
                "SecurityId": "ESENESF",
                "SecurityName": "ESENES FORGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531144",
                "SecurityId": "ELFORGE",
                "SecurityName": "EL FORGE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE158F01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531146",
                "SecurityId": "MEDICAMEQ",
                "SecurityName": "MEDICAMEN BIOTECH LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE646B01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531147",
                "SecurityId": "ALICON",
                "SecurityName": "ALICON CASTALLOY LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE062D01024",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531148",
                "SecurityId": "ENCHANTE",
                "SecurityName": "ENCHANTE JEWELLERY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE772C01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531149",
                "SecurityId": "SRANGMARK",
                "SecurityName": "SHREE RANG MARK TRAVELS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE765K01027",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531150",
                "SecurityId": "ASCORPS",
                "SecurityName": "ASIA CORP SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531151",
                "SecurityId": "LYMPHALDM",
                "SecurityName": "LYMPHA LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531152",
                "SecurityId": "STILESI",
                "SecurityName": "STILES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531153",
                "SecurityId": "DILIGENT",
                "SecurityName": "DILIGENT INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE650C01028",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531154",
                "SecurityId": "BPLIMTD",
                "SecurityName": "BPLIMTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531155",
                "SecurityId": "EPSOMPRO",
                "SecurityName": "EPSOM PROPERTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE601F01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531156",
                "SecurityId": "ALFAVIO",
                "SecurityName": "ALFAVISION OVERSEAS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE883B01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531157",
                "SecurityId": "ORGCOAT",
                "SecurityName": "ORGANIC COATINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE412E01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531158",
                "SecurityId": "CATVISION",
                "SecurityName": "CATVISION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE660B01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531159",
                "SecurityId": "JMPCAST",
                "SecurityName": "JMP CASTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531160",
                "SecurityId": "SAUMYACAP",
                "SecurityName": "SAUMYA CAPITAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE365L01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531161",
                "SecurityId": "ABMKNO",
                "SecurityName": "ABM KNOWLEDGEWARE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE850B01026",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531162",
                "SecurityId": "EMAMILTD",
                "SecurityName": "EMAMI LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE548C01032",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531163",
                "SecurityId": "KEMISTAR",
                "SecurityName": "KEMISTAR CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE971L01029",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531164",
                "SecurityId": "LADIAMO",
                "SecurityName": "LASER DIAMONDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE995E01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531165",
                "SecurityId": "KALINGA",
                "SecurityName": "KALINGA CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531166",
                "SecurityId": "MFLSTBR",
                "SecurityName": "MFL STOCK BROKING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531167",
                "SecurityId": "ORIENTINFO",
                "SecurityName": "ORIENT INFORMATION TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE067B01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531168",
                "SecurityId": "ASSOCER",
                "SecurityName": "ASSOCIATED CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE771E01010",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531169",
                "SecurityId": "SKPSEC",
                "SecurityName": "SKP SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE709B01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531170",
                "SecurityId": "TREZERO",
                "SecurityName": "TREZER OIL AGRO TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531171",
                "SecurityId": "DEVIKA",
                "SecurityName": "DEVIKA PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE248C01013",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531172",
                "SecurityId": "PRANAVSP",
                "SecurityName": "PRANAVADITYA SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE569D01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531173",
                "SecurityId": "SYSCHEM",
                "SecurityName": "SYSCHEM (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE121D01036",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531174",
                "SecurityId": "SRPLLTD",
                "SecurityName": "SRPL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531175",
                "SecurityId": "BLSINFOTE",
                "SecurityName": "BLS INFOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE606B01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531176",
                "SecurityId": "MEFCOMCAP",
                "SecurityName": "MEFCOM CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE186C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531178",
                "SecurityId": "HEMANG",
                "SecurityName": "HEMANG RESOURCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE930A01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531179",
                "SecurityId": "ARMANFIN",
                "SecurityName": "ARMAN FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE109C01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531180",
                "SecurityId": "APAMUSE",
                "SecurityName": "APPLE AMUSEMENT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE212D01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531181",
                "SecurityId": "ALFAEXPDM",
                "SecurityName": "ALFA EXPORT TECHNOLOGIES (INDIA) LT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531183",
                "SecurityId": "MANKNIT",
                "SecurityName": "MANGAL KNITS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531184",
                "SecurityId": "SSKFISC",
                "SecurityName": "SSK FISCAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531185",
                "SecurityId": "CHISO",
                "SecurityName": "CHICAGO SOFTWARE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE101B01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531186",
                "SecurityId": "SWETCHM",
                "SecurityName": "SWET-CHEM ANTIBIOTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531187",
                "SecurityId": "CLAFS",
                "SecurityName": "CLARITY FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE508C01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531188",
                "SecurityId": "GUJBP",
                "SecurityName": "GUJARAT BULK PACKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE709C01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531190",
                "SecurityId": "TAVERNIER",
                "SecurityName": "TAVERNIER RESOURCES LIMI",
                "FaceValue": "10",
                "ISINNo": "INE355H01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531191",
                "SecurityId": "FILTRON",
                "SecurityName": "FILTRON ENGINEERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE589N01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531192",
                "SecurityId": "MIDINFRA",
                "SecurityName": "MIDAS INFRA TRADE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE290M01022",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531193",
                "SecurityId": "RAJHANS-B",
                "SecurityName": "RAJHANS FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531194",
                "SecurityId": "BRAHMAINF",
                "SecurityName": "BRAHMAPUTRA INFRAPROJECT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE968C01016",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531195",
                "SecurityId": "VEDVYAS",
                "SecurityName": "VEDVYAS CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531196",
                "SecurityId": "GAGANPO",
                "SecurityName": "GAGAN POLYCOT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE297L01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531197",
                "SecurityId": "GOEAFIN",
                "SecurityName": "GOODEARTH FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531198",
                "SecurityId": "DHANADACO",
                "SecurityName": "DHANADA CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE041F01015",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531199",
                "SecurityId": "GLANCE",
                "SecurityName": "GLANCE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE580D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531200",
                "SecurityId": "MAPLLEINF",
                "SecurityName": "MAPLLE INFRAPROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE809D01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531201",
                "SecurityId": "SHILCTECH",
                "SecurityName": "SHILCHAR TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE024F01011",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531202",
                "SecurityId": "AMBEHOT",
                "SecurityName": "AMBE HOTELS AND RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531203",
                "SecurityId": "BRANDREAL",
                "SecurityName": "BRAND REALTY SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE819G01012",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531204",
                "SecurityId": "RIZVIEX",
                "SecurityName": "RIZVI EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531205",
                "SecurityId": "KANSAFB",
                "SecurityName": "KANSAL FIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE179O01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531206",
                "SecurityId": "KWALITYCL",
                "SecurityName": "KWALITY CREDIT & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE577C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531207",
                "SecurityId": "RAYLA",
                "SecurityName": "RAYMED LABS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE741C01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531208",
                "SecurityId": "PRIMUSC",
                "SecurityName": "PRIMUS CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531209",
                "SecurityId": "NUCLEUS",
                "SecurityName": "NUCLEUS SOFTWARE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE096B01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531210",
                "SecurityId": "COLINZ",
                "SecurityName": "COLINZ LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE923C01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531211",
                "SecurityId": "WELLNESS",
                "SecurityName": "WELLNESS NONI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE571H01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531212",
                "SecurityId": "NALINLEA",
                "SecurityName": "NALIN LEASE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE606C01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531213",
                "SecurityId": "MANAPPURAM",
                "SecurityName": "MANAPPURAM FINANCE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE522D01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531214",
                "SecurityId": "KANELEC",
                "SecurityName": "KANOHAR ELECTRICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531215",
                "SecurityId": "RTSPOWR",
                "SecurityName": "RTS POWER CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE005C01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531216",
                "SecurityId": "COMFINTE",
                "SecurityName": "COMFORT INTECH LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE819A01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531217",
                "SecurityId": "WESTE",
                "SecurityName": "WESTERN INDIA SHIPYARD LTD.",
                "FaceValue": "2",
                "ISINNo": "INE382C01028",
                "Industry": "Marine Port & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531218",
                "SecurityId": "RISHABFIN",
                "SecurityName": "RISHAB FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE346D01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531219",
                "SecurityId": "SHYAMAINFO",
                "SecurityName": "SHYAMA INFOSYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE861E01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531220",
                "SecurityId": "HICSCEM",
                "SecurityName": "HICS CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531221",
                "SecurityId": "MAYURFL",
                "SecurityName": "MAYUR FLOORINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE262W01012",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531222",
                "SecurityId": "VIBINVEDM",
                "SecurityName": "VIBRANT INVESTMENT & PROPERTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531223",
                "SecurityId": "ANJANI",
                "SecurityName": "ANJANI SYNTHETICS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE364D01032",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531224",
                "SecurityId": "COMPUTECH",
                "SecurityName": "COMPUTECH INTERNATIONAL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE751A01028",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531225",
                "SecurityId": "XTGLOBAL",
                "SecurityName": "XTGLOBAL INFOTECH LTD",
                "FaceValue": "1",
                "ISINNo": "INE547B01028",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531226",
                "SecurityId": "DOONVAL",
                "SecurityName": "DOON VALLEY RICE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531227",
                "SecurityId": "DECOMIC",
                "SecurityName": "DECO-MICA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE907E01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531228",
                "SecurityId": "RANDER",
                "SecurityName": "RANDER CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE821D01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531229",
                "SecurityId": "ZSBIMAGB",
                "SecurityName": "SBI MAGNUM BOND FUND 1994",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531231",
                "SecurityId": "SIDVINF",
                "SecurityName": "SIDDHI VINAYAK DAIRY & FOOD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531232",
                "SecurityId": "JINDRUG",
                "SecurityName": "JINDRUG",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531233",
                "SecurityId": "RASIELEC",
                "SecurityName": "RASI ELECTRODES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE822D01021",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531234",
                "SecurityId": "VICTORYPP",
                "SecurityName": "VICTORY PAPER & BOARDS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE962E01015",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531235",
                "SecurityId": "CITIPOR",
                "SecurityName": "CITIPORT FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE494D01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531236",
                "SecurityId": "MEWARPOL",
                "SecurityName": "MEWAR POLYTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE404E01018",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531237",
                "SecurityId": "DHRUVCA",
                "SecurityName": "DHRUVA CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE972E01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531238",
                "SecurityId": "BETALAB",
                "SecurityName": "BETALACT LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531240",
                "SecurityId": "SHAMROIN",
                "SecurityName": "SHAMROCK INDUSTRIAL CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE022F01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531241",
                "SecurityId": "LINCPENQ",
                "SecurityName": "LINC PEN & PLASTICS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE802B01019",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531243",
                "SecurityId": "GUMINDL",
                "SecurityName": "GUMMADI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531244",
                "SecurityId": "KORATLS",
                "SecurityName": "KORATLA LEASEFIN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531245",
                "SecurityId": "JANZNMT",
                "SecurityName": "JANZEN CASTMETALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531246",
                "SecurityId": "PRIMAIN",
                "SecurityName": "PRIMA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE723N01012",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531247",
                "SecurityId": "ALPHA",
                "SecurityName": "ALPHA HI-TECH FUEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE506B01016",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531248",
                "SecurityId": "ZASELECT",
                "SecurityName": "ZASELECT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531249",
                "SecurityId": "WLPACKP",
                "SecurityName": "WELL PACK PAPERS & CONTAINERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE063H01031",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531250",
                "SecurityId": "RNBIND",
                "SecurityName": "RNB INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE307H01016",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531251",
                "SecurityId": "PEBBLEI",
                "SecurityName": "PEBBLE INVESTMENT AND FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531252",
                "SecurityId": "ANERI",
                "SecurityName": "ANERI FINCAP LTD",
                "FaceValue": "10",
                "ISINNo": "INE720D01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531253",
                "SecurityId": "INDGELA",
                "SecurityName": "INDIA GELATINE & CHEMICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE342D01012",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531254",
                "SecurityId": "OPTIFIN",
                "SecurityName": "OPTIMUS FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE031G01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531255",
                "SecurityId": "PARAGONF",
                "SecurityName": "PARAGON FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE015E01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531256",
                "SecurityId": "PRAVEEN",
                "SecurityName": "PRAVEEN PROPERTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531257",
                "SecurityId": "PRATIKSH",
                "SecurityName": "PRATIKSHA CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE530D01012",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531258",
                "SecurityId": "KPRTLPR",
                "SecurityName": "KPR TELEPRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531259",
                "SecurityId": "ESHAMEDIA",
                "SecurityName": "ESHA MEDIA RESEARCH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE328F01016",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531260",
                "SecurityId": "SUNEDISON",
                "SecurityName": "SUNEDISON INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE332F01018",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531261",
                "SecurityId": "CONCURINF",
                "SecurityName": "CONCURRENT (INDIA) INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE012B01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531262",
                "SecurityId": "SURINDL",
                "SecurityName": "SURYA INDUSTRIAL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE060N01019",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531263",
                "SecurityId": "ANSUSPNDM",
                "SecurityName": "ANASUYA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531264",
                "SecurityId": "PIOCASH",
                "SecurityName": "PIONEER CASHEW INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531265",
                "SecurityId": "PROGREXV",
                "SecurityName": "PROGREX VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE421E01012",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531266",
                "SecurityId": "VSTTILLERS",
                "SecurityName": "V.S.T.TILLERS TRACTORS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE764D01017",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531267",
                "SecurityId": "INDVANI",
                "SecurityName": "INDO VANILLON CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531268",
                "SecurityId": "B2BSOFT",
                "SecurityName": "B2B SOFTWARE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE151B01011",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531269",
                "SecurityId": "KLGSYSTEL",
                "SecurityName": "KLG SYSTEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE799A01019",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531270",
                "SecurityId": "DAZZEL",
                "SecurityName": "DAZZEL CONFINDIVE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE149E01027",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531272",
                "SecurityId": "NIKKIGL",
                "SecurityName": "NIKKI GLOBAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE526C01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531273",
                "SecurityId": "RADHEDE",
                "SecurityName": "RADHE DEVELOPERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE986B01036",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531274",
                "SecurityId": "KINETRU",
                "SecurityName": "KINETIC TRUST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE674M01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531276",
                "SecurityId": "BOMSS",
                "SecurityName": "BOMBAY SWADESHI STORES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE595B01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531277",
                "SecurityId": "RETIFNL",
                "SecurityName": "REALTIME FINLEASE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531278",
                "SecurityId": "ELIXIR",
                "SecurityName": "ELIXIR CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE785D01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531279",
                "SecurityId": "TRISHAKT",
                "SecurityName": "TRISHAKTI ELECTRONICS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE238C01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531280",
                "SecurityId": "PANKAJPO",
                "SecurityName": "PANKAJ POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE698B01011",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531281",
                "SecurityId": "PGINDST",
                "SecurityName": "PG INDUSTRY LTD.",
                "FaceValue": "5",
                "ISINNo": "INE807H01023",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531282",
                "SecurityId": "VIRPOLY",
                "SecurityName": "VIRGO POLYMERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE464H01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531283",
                "SecurityId": "CINDRELL",
                "SecurityName": "CINDRELLA FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE897D01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531284",
                "SecurityId": "HITESTX",
                "SecurityName": "HITESH TEXTILE MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531285",
                "SecurityId": "ASLAKTO",
                "SecurityName": "ASIAN LAKTO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531286",
                "SecurityId": "MVCOTSP",
                "SecurityName": "MV COTSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE586D01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531287",
                "SecurityId": "NATPLASTI",
                "SecurityName": "NATIONAL PLASTIC TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE896D01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531288",
                "SecurityId": "LEADFIN",
                "SecurityName": "LEAD FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE531D01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531289",
                "SecurityId": "NATFIT",
                "SecurityName": "NATIONAL FITTINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE643C01015",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531290",
                "SecurityId": "SHDYECH",
                "SecurityName": "SHREEJI DYE-CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531291",
                "SecurityId": "REACTOP",
                "SecurityName": "REACTO PAPERS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531292",
                "SecurityId": "NALINCH",
                "SecurityName": "NALIN CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531293",
                "SecurityId": "PRAMFIN",
                "SecurityName": "PRAMADA FINVEST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE475F01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531294",
                "SecurityId": "VSMFNEX",
                "SecurityName": "VSM FINANCE & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531295",
                "SecurityId": "SUNLAKE",
                "SecurityName": "SUNLAKE RESORTS & HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE847L01013",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531296",
                "SecurityId": "MXORCHADM",
                "SecurityName": "MAXWORTH ORCHARDS INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531297",
                "SecurityId": "ARTEFACT",
                "SecurityName": "ARTEFACT PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE885B01014",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531298",
                "SecurityId": "KINJAMT",
                "SecurityName": "KINJAL METALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531299",
                "SecurityId": "CENLC",
                "SecurityName": "CENTURY LAMINATING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE662B01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531300",
                "SecurityId": "AMITINT",
                "SecurityName": "AMIT INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE053D01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531301",
                "SecurityId": "HIGHSTREE",
                "SecurityName": "HIGH STREET FILATEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE319M01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531303",
                "SecurityId": "RICHSTODM",
                "SecurityName": "RICH STONES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531304",
                "SecurityId": "NUTECGLOB",
                "SecurityName": "NUTECH GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE960H01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531305",
                "SecurityId": "GLOBALX",
                "SecurityName": "GLOBAL EXHIBITIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531306",
                "SecurityId": "DHPIND",
                "SecurityName": "DHP INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE590D01016",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531307",
                "SecurityId": "RRIL",
                "SecurityName": "RRIL LTD",
                "FaceValue": "5",
                "ISINNo": "INE951M01037",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531310",
                "SecurityId": "AVAILFC",
                "SecurityName": "AVAILABLE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE325G01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531311",
                "SecurityId": "RAHILIN",
                "SecurityName": "RAHIL INVESTMENT & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531312",
                "SecurityId": "SANRAA",
                "SecurityName": "SANRAA MEDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE889C01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531313",
                "SecurityId": "SPARKFD",
                "SecurityName": "SPARKLE FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531314",
                "SecurityId": "INTCAPM",
                "SecurityName": "INTEGRA CAPITAL MANAGEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE366H01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531315",
                "SecurityId": "ICHALSO",
                "SecurityName": "ICHALKARANJI SOYA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531316",
                "SecurityId": "SANDURL",
                "SecurityName": "SANDUR LAMINATES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531317",
                "SecurityId": "GERMANGA",
                "SecurityName": "GERMAN GARDENS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE218D01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531318",
                "SecurityId": "TRANSCON",
                "SecurityName": "TRANSCON RESEARCH & INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE273C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531319",
                "SecurityId": "MARUTISE",
                "SecurityName": "MARUTI SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE368C01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531320",
                "SecurityId": "EXOTICCOAL",
                "SecurityName": "EXOTIC COAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE898K01018",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531321",
                "SecurityId": "AMEXCHE",
                "SecurityName": "AMEX CARBONATES & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531322",
                "SecurityId": "HAVISHA",
                "SecurityName": "SRI HAVISHA HOSPITALITY AND INFRASTRUCTURE LTD",
                "FaceValue": "2",
                "ISINNo": "INE293B01029",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531323",
                "SecurityId": "JAYIND",
                "SecurityName": "JAYATMA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE250D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531324",
                "SecurityId": "ROSELABS",
                "SecurityName": "ROSELABS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE475C01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531325",
                "SecurityId": "VINIMETDM",
                "SecurityName": "VINI METASPIN STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531326",
                "SecurityId": "MPTELE",
                "SecurityName": "M.P.TELELINKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE003C01012",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531327",
                "SecurityId": "CHARMS",
                "SecurityName": "CHARMS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE442C01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531328",
                "SecurityId": "KRETTOSYS",
                "SecurityName": "KRETTO SYSCON LTD",
                "FaceValue": "10",
                "ISINNo": "INE128R01015",
                "Industry": "Healthcare Supplies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531329",
                "SecurityId": "COSAPAR",
                "SecurityName": "COSMOS APPARELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531330",
                "SecurityId": "DYNMICR",
                "SecurityName": "DYNAMIC MICROSTEPPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE443N01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531331",
                "SecurityId": "TIMESHI",
                "SecurityName": "TIME SHIPPING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531332",
                "SecurityId": "STRLIND",
                "SecurityName": "STRLIND",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531333",
                "SecurityId": "GARWALL",
                "SecurityName": "GARWALL",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531334",
                "SecurityId": "VIKALPS",
                "SecurityName": "VIKALP SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE186E01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531335",
                "SecurityId": "ZYDUSWELL",
                "SecurityName": "ZYDUS WELLNESS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE768C01010",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531336",
                "SecurityId": "AUROCOK",
                "SecurityName": "AUROMA COKE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE662I01012",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531337",
                "SecurityId": "JUMPNET",
                "SecurityName": "JUMP NETWORKS LTD",
                "FaceValue": "5",
                "ISINNo": "INE974C01022",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531338",
                "SecurityId": "MILESTONE",
                "SecurityName": "MILESTONE GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE151H01018",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531339",
                "SecurityId": "JAIHINDPRO",
                "SecurityName": "JAIHIND PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE343D01010",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531340",
                "SecurityId": "BERVINL",
                "SecurityName": "BERVIN INVESTMENT & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE348I01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531341",
                "SecurityId": "GUJINV",
                "SecurityName": "GUJARAT INVESTA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE373D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531342",
                "SecurityId": "ORBITPL",
                "SecurityName": "ORBIT POLYESTER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531343",
                "SecurityId": "INDINFRA",
                "SecurityName": "INDIA INFRASPACE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE954M01031",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531344",
                "SecurityId": "CONCOR",
                "SecurityName": "CONTAINER CORPORATION OF INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE111A01025",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531345",
                "SecurityId": "SUNZI",
                "SecurityName": "SUNRISE ZINC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE541D01019",
                "Industry": "Zinc",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531346",
                "SecurityId": "EASTRED",
                "SecurityName": "EASTERN TREADS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE500D01015",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531347",
                "SecurityId": "HARSHIT",
                "SecurityName": "HARSHITA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531348",
                "SecurityId": "MOONDRUG",
                "SecurityName": "MOON DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE444C01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531349",
                "SecurityId": "PANACEABIO",
                "SecurityName": "PANACEA BIOTEC LTD.",
                "FaceValue": "1",
                "ISINNo": "INE922B01023",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531350",
                "SecurityId": "VOLGAAIR",
                "SecurityName": "VOLGA AIR TECHNICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE719B01015",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531351",
                "SecurityId": "SHRAJTX",
                "SecurityName": "SHREE RAJASTHAN TEXCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE578F01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531352",
                "SecurityId": "PEETISEC",
                "SecurityName": "PEETI SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE328D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531353",
                "SecurityId": "MKSECUR",
                "SecurityName": "M.K.SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531354",
                "SecurityId": "PNCCAP",
                "SecurityName": "PNC CAPITAL TRUST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE346C01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531355",
                "SecurityId": "DHANLEA",
                "SecurityName": "DHANLAXMI LEASE FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531356",
                "SecurityId": "CURESPEC",
                "SecurityName": "CURE SPECTS LASERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE730B01012",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531357",
                "SecurityId": "MERMETL",
                "SecurityName": "MERCURY METALS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE763M01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531358",
                "SecurityId": "CHOICEIN",
                "SecurityName": "CHOICE INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE102B01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531359",
                "SecurityId": "SRAMSET",
                "SecurityName": "SHRIRAM ASSET MANAGEMENT CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE777G01012",
                "Industry": "Asset Management Cos.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531360",
                "SecurityId": "GOLECHA",
                "SecurityName": "GOLECHHA GLOBAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE427D01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531361",
                "SecurityId": "EMETALSI",
                "SecurityName": "E-METALS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531362",
                "SecurityId": "ISMTL",
                "SecurityName": "INDIAN SEAMLESS METAL TUBES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE501B01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531363",
                "SecurityId": "KARURKCP",
                "SecurityName": "KARUR K.C.P.PACKKAGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE727F01019",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531364",
                "SecurityId": "PARAMONE",
                "SecurityName": "PARAMONE CONCEPTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE005E01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531365",
                "SecurityId": "NAISARG",
                "SecurityName": "NAISARGIK AGRITECH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE360M01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531366",
                "SecurityId": "KOHINOORBRO",
                "SecurityName": "KOHINOOR BROADCASTING CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE414E01017",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531367",
                "SecurityId": "DOLLEX",
                "SecurityName": "DOLLEX INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE892A01020",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531368",
                "SecurityId": "CRYCABL",
                "SecurityName": "CRYSTAL CABLE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531369",
                "SecurityId": "WELLWININD",
                "SecurityName": "WELLWIN INDUSTRY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE752A01018",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531370",
                "SecurityId": "SPARCSYS",
                "SecurityName": "SPARC SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE960B01015",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531371",
                "SecurityId": "RUPEBAR",
                "SecurityName": "RUSHABH PRECISION BEARINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531372",
                "SecurityId": "BIOWIN",
                "SecurityName": "BIOWIN PHARMA (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE244D01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531373",
                "SecurityId": "THEBYKE",
                "SecurityName": "THE BYKE HOSPITALITY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE319B01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531374",
                "SecurityId": "SAAGRR",
                "SecurityName": "SAAG RR INFRA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE396G01011",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531375",
                "SecurityId": "GCCLINP",
                "SecurityName": "GCCL INFRASTRUCTURE & PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE438F01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531376",
                "SecurityId": "CHERYFS",
                "SecurityName": "CHERRY FASHIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531378",
                "SecurityId": "THANAEL",
                "SecurityName": "THANAEL",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531379",
                "SecurityId": "AVIEXPODM",
                "SecurityName": "AVI EXPORT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531380",
                "SecurityId": "CSURGSU",
                "SecurityName": "CENTENIAL SURGICAL SUTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE405H01018",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531381",
                "SecurityId": "ARIHANT",
                "SecurityName": "ARIHANT FOUNDATIONS & HOUSING LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE413D01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531382",
                "SecurityId": "JAVNTPR",
                "SecurityName": "JAYAVANT PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE364H01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531384",
                "SecurityId": "NORSTGM",
                "SecurityName": "NORTH STAR GEMS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531385",
                "SecurityId": "SUBRP",
                "SecurityName": "SUBRP",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531386",
                "SecurityId": "FLCLENT",
                "SecurityName": "FLUIDCLEAN INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531387",
                "SecurityId": "HASTIFIN",
                "SecurityName": "HASTI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE671D01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531389",
                "SecurityId": "SHBENZO",
                "SecurityName": "SHREE BENZOPHEN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531390",
                "SecurityId": "UPSURGE",
                "SecurityName": "UPSURGE INVESTMENT & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE890B01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531391",
                "SecurityId": "RATNABAL",
                "SecurityName": "RATNABALI CAPITAL MARKETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE050D01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531393",
                "SecurityId": "KRYKNIT",
                "SecurityName": "KRYSTAL KNITWEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531394",
                "SecurityId": "MARVINY",
                "SecurityName": "MARVEL VINYLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE345L01018",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531395",
                "SecurityId": "PADAMCO",
                "SecurityName": "PADAM COTTON YARNS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE448D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531396",
                "SecurityId": "WOMENNET",
                "SecurityName": "WOMEN NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE905B01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531397",
                "SecurityId": "POLYCON",
                "SecurityName": "POLYCON INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE262C01014",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531398",
                "SecurityId": "SOURCENTRL",
                "SecurityName": "SOURCE NATURAL FOODS & HERBAL SUPPL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE679C01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531399",
                "SecurityId": "GGAUTO",
                "SecurityName": "G.G.AUTOMOTIVE GEARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE493B01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531400",
                "SecurityId": "ALMONDZ",
                "SecurityName": "ALMONDZ GLOBAL SECURITIES LTD.",
                "FaceValue": "6",
                "ISINNo": "INE326B01027",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531401",
                "SecurityId": "KHODIYAR",
                "SecurityName": "KHODIYAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE843C01011",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531402",
                "SecurityId": "LWSKNIT",
                "SecurityName": "LWS KNITWEAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE281M01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531403",
                "SecurityId": "LWSNITW",
                "SecurityName": "LWSNITW",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531404",
                "SecurityId": "ZICOM",
                "SecurityName": "ZICOM ELECTRONIC SECURITY SYSTEMS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE871B01014",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531405",
                "SecurityId": "SUGAT",
                "SecurityName": "SUGAM AGRO-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE068E01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531406",
                "SecurityId": "ANSINDUS",
                "SecurityName": "ANS INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE234J01018",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531407",
                "SecurityId": "HAMARDG",
                "SecurityName": "HALLMARK DRUGS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531408",
                "SecurityId": "PARMXPH",
                "SecurityName": "PARMAX PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531409",
                "SecurityId": "ALCHCORP",
                "SecurityName": "ALCHEMIST CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE057D01016",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531410",
                "SecurityId": "GUJFOIL",
                "SecurityName": "GUJARAT FOILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE587F01017",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531411",
                "SecurityId": "TUNITEX",
                "SecurityName": "TUNI TEXTILE MILLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE560D01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531412",
                "SecurityId": "RADIXIND",
                "SecurityName": "RADIX INDUSTRIES (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE576N01014",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531413",
                "SecurityId": "KIRANPR",
                "SecurityName": "KIRAN PRINT-PACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE516D01011",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531414",
                "SecurityId": "MSINVES",
                "SecurityName": "M.S.INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531415",
                "SecurityId": "NRSTEXT",
                "SecurityName": "NRS TEXTILE MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531416",
                "SecurityId": "NARPROP",
                "SecurityName": "NARENDRA PROPERTIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE603F01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531417",
                "SecurityId": "MEGACOR",
                "SecurityName": "MEGA CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE804B01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531418",
                "SecurityId": "SYSTELIN",
                "SecurityName": "SYSTEL INFOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE919E01023",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531419",
                "SecurityId": "GOPLEEIN",
                "SecurityName": "GOPLEE INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE968B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531420",
                "SecurityId": "BMBMUMG",
                "SecurityName": "BMB MUSIC & MAGNETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE644K01016",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531421",
                "SecurityId": "IGHIGHM",
                "SecurityName": "IGGI HIGHWAY MOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531422",
                "SecurityId": "KESPRINT",
                "SecurityName": "KESARI PRINTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531423",
                "SecurityId": "NATUBIO",
                "SecurityName": "NATURAL BIOCON (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531424",
                "SecurityId": "IDHOTIN",
                "SecurityName": "IDEAL HOTELS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531425",
                "SecurityId": "MRCOTEX",
                "SecurityName": "MARUTI COTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531426",
                "SecurityId": "TNPL",
                "SecurityName": "TAMIL NADU NEWSPRINT & PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE107A01015",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531428",
                "SecurityId": "BIRMT",
                "SecurityName": "BIRMINGHAM THERMOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE782B01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531429",
                "SecurityId": "ADVENT",
                "SecurityName": "ADVENT COMPUTER SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE101C01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531430",
                "SecurityId": "STGRFLD",
                "SecurityName": "STGRFLD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531431",
                "SecurityId": "SHAKTIPUMP",
                "SecurityName": "SHAKTI PUMPS (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE908D01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531432",
                "SecurityId": "SYTIXSE",
                "SecurityName": "SYSTEMATIX SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE07P301011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531433",
                "SecurityId": "SUNGOLD",
                "SecurityName": "SUNGOLD CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE271D01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531434",
                "SecurityId": "INDOXOC",
                "SecurityName": "INDOXOC",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531435",
                "SecurityId": "LIMCHEM",
                "SecurityName": "LIMCHEM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531436",
                "SecurityId": "SAFFRON",
                "SecurityName": "SAFFRON INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE474D01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531437",
                "SecurityId": "PWASML",
                "SecurityName": "PRAKASH WOOLLEN & SYNTHETIC MILLS LTD",
                "FaceValue": "10",
                "ISINNo": "INE523I01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531438",
                "SecurityId": "CRYAGRI",
                "SecurityName": "CRYSTAL AGRITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531439",
                "SecurityId": "GOLDTECH",
                "SecurityName": "GOLDSTONE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE805A01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531440",
                "SecurityId": "PANDRUG",
                "SecurityName": "PAN DRUGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531441",
                "SecurityId": "LYONSCO",
                "SecurityName": "LYONS CORPORATE MARKET LTD.",
                "FaceValue": "10",
                "ISINNo": "INE100F01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531442",
                "SecurityId": "AMRALCO",
                "SecurityName": "AMAR ALCOHOLI LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531443",
                "SecurityId": "TAMRMIL",
                "SecurityName": "TAMARAI MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531444",
                "SecurityId": "VARDHMAN",
                "SecurityName": "VARDHMAN CONCRETE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE115C01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531445",
                "SecurityId": "KEDICON",
                "SecurityName": "KEDIA CONTINENTAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531447",
                "SecurityId": "ROCKONENT",
                "SecurityName": "ROCKON ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE644B01049",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531448",
                "SecurityId": "SAFALSEC",
                "SecurityName": "SAFAL SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE101L01031",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531449",
                "SecurityId": "GRMOVER",
                "SecurityName": "GRM OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE192H01012",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531450",
                "SecurityId": "KECITRL-B",
                "SecurityName": "KECITRL-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531451",
                "SecurityId": "HAPPYHMDM",
                "SecurityName": "HAPPY HOME PROFIN LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531452",
                "SecurityId": "NCCFIN",
                "SecurityName": "NCC FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE768B01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531453",
                "SecurityId": "MOHITIND",
                "SecurityName": "MOHIT INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE954E01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531454",
                "SecurityId": "POLYLINK",
                "SecurityName": "POLYLINK POLYMERS (INDIA) LTD.",
                "FaceValue": "5",
                "ISINNo": "INE323D01020",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531455",
                "SecurityId": "PRFOTRN",
                "SecurityName": "PRAKASH FOTRAN SOFTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531456",
                "SecurityId": "MINAXI",
                "SecurityName": "MINAXI TEXTILES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE952C01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531457",
                "SecurityId": "NARSM",
                "SecurityName": "NARAINGARH SUGAR MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE491E01015",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531458",
                "SecurityId": "BOSTONBIO",
                "SecurityName": "BOSTON BIO SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE109B01019",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531459",
                "SecurityId": "VENTRON",
                "SecurityName": "VENTRON POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE316C01018",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531460",
                "SecurityId": "CONTICON",
                "SecurityName": "CONTINENTAL CONTROLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545B01022",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531461",
                "SecurityId": "SUAPARL",
                "SecurityName": "SUVARNA APPAREL AND FASHION EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531462",
                "SecurityId": "BHAAF",
                "SecurityName": "BHAGWANDAS AUTO FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE134D01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531463",
                "SecurityId": "GBLINFRA",
                "SecurityName": "GLOBAL INFRATECH & FINANCE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE377M01035",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531464",
                "SecurityId": "ISPATPROF",
                "SecurityName": "ISPAT PROFILES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE798A01011",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531465",
                "SecurityId": "NOUVEAU",
                "SecurityName": "NOUVEAU GLOBAL VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE317B01034",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531466",
                "SecurityId": "FERNHIL",
                "SecurityName": "FERNHILL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531467",
                "SecurityId": "ARNAVCORP",
                "SecurityName": "ARNAV CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE647D01014",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531468",
                "SecurityId": "PRSBS",
                "SecurityName": "PRUDENTIAL STOCK BROKING SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE049D01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531469",
                "SecurityId": "VMATHUR",
                "SecurityName": "V.R.MATHUR MASS COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531470",
                "SecurityId": "EMPORIS",
                "SecurityName": "EMPORIS PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE742D01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531471",
                "SecurityId": "DUKEOFS",
                "SecurityName": "DUKE OFFSHORE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE397G01019",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531472",
                "SecurityId": "CYBELEIND",
                "SecurityName": "CYBELE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE183D01010",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531473",
                "SecurityId": "CETHARI",
                "SecurityName": "CETHAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531474",
                "SecurityId": "COMPSKIL",
                "SecurityName": "COMPUTERSKILL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE127C01019",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531475",
                "SecurityId": "KAMPECO",
                "SecurityName": "KAMPER CONCAST LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531476",
                "SecurityId": "KANSTEL",
                "SecurityName": "KANAN STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531477",
                "SecurityId": "GOAFRUT",
                "SecurityName": "GOA FRUIT SPECIALITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531478",
                "SecurityId": "ESSKAY",
                "SecurityName": "ESSKAY TELECOM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE913C01012",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531479",
                "SecurityId": "GLOBAL",
                "SecurityName": "GLOBAL LAND MASTERS CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE330B01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531480",
                "SecurityId": "DANINLT",
                "SecurityName": "DANIN LEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531481",
                "SecurityId": "BETKAPA",
                "SecurityName": "BETA-KAPPA INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE367F01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531482",
                "SecurityId": "SHYAAXPH",
                "SecurityName": "SHREE YAAX PHARMA & COSMETICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE325F01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531483",
                "SecurityId": "VIRTIND",
                "SecurityName": "VIRTUAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531485",
                "SecurityId": "HITKARICH",
                "SecurityName": "HITKARI CHINA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE839D01017",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531486",
                "SecurityId": "FILME",
                "SecurityName": "FILMCITY MEDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE600B01033",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531487",
                "SecurityId": "SNEEL",
                "SecurityName": "SHREE NEELACHAL LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE694B01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531488",
                "SecurityId": "SANIM",
                "SecurityName": "SANYO IMPEX LTD.",
                "FaceValue": "1",
                "ISINNo": "INE905A01020",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531489",
                "SecurityId": "CGVAK",
                "SecurityName": "CG-VAK SOFTWARE & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE084D01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531490",
                "SecurityId": "MANPELE",
                "SecurityName": "MANPELE",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531491",
                "SecurityId": "BGROWTHDM",
                "SecurityName": "BOB GROWTH &#39;95",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531492",
                "SecurityId": "PENTASOFTTE",
                "SecurityName": "PENTASOFT TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE408A01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531493",
                "SecurityId": "MAXCOUN",
                "SecurityName": "MAXWORTH COUNTRY (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531494",
                "SecurityId": "NAVBLDR",
                "SecurityName": "NAVKAR BUILDERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE268H01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531495",
                "SecurityId": "BLUECOAST",
                "SecurityName": "BLUE COAST HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE472B01011",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531496",
                "SecurityId": "OMKAR",
                "SecurityName": "OMKAR OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE680D01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531497",
                "SecurityId": "MADHUCON",
                "SecurityName": "MADHUCON PROJECTS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE378D01032",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531498",
                "SecurityId": "CLFILAM",
                "SecurityName": "CLASSIC FILAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531499",
                "SecurityId": "SYBLY",
                "SecurityName": "SYBLY INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE080D01042",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531500",
                "SecurityId": "RAJESHEXPO",
                "SecurityName": "RAJESH EXPORTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE343B01030",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531501",
                "SecurityId": "INDENAL",
                "SecurityName": "INDEEN ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531502",
                "SecurityId": "ESARIND",
                "SecurityName": "ESAAR (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE404L01039",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531503",
                "SecurityId": "MARIS",
                "SecurityName": "MARIS SPINNERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE866D01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531504",
                "SecurityId": "GRWESIN",
                "SecurityName": "GREAT WESTERN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531505",
                "SecurityId": "INDERGR",
                "SecurityName": "INDERGIRI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE628F01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531506",
                "SecurityId": "SKRABUL",
                "SecurityName": "SHUKRA BULLIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE561E01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531507",
                "SecurityId": "SOMPHYT",
                "SecurityName": "SOM PHYTOPHARMA (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531508",
                "SecurityId": "EVEREADY",
                "SecurityName": "EVEREADY INDUSTRIES INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE128A01029",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531509",
                "SecurityId": "STEP2COR",
                "SecurityName": "STEP TWO CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE623D01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531510",
                "SecurityId": "LINTAS",
                "SecurityName": "LINTAS MERCANTILE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE702D01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531511",
                "SecurityId": "NEVTIAS",
                "SecurityName": "NEVTIA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531512",
                "SecurityId": "ORIENTTR",
                "SecurityName": "ORIENT TRADELINK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE681D01021",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531513",
                "SecurityId": "ARIHCLAF",
                "SecurityName": "ARIHANT CLASSIC FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE763C01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531514",
                "SecurityId": "AASHEESH",
                "SecurityName": "AASHEESH SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE174C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531515",
                "SecurityId": "MAHANIN",
                "SecurityName": "MAHAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE735D01033",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531516",
                "SecurityId": "EMKAYIN",
                "SecurityName": "EMKAY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531517",
                "SecurityId": "ALANGIND",
                "SecurityName": "ALANG INDUSTRIAL GASES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE848C01010",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531518",
                "SecurityId": "VIKASPROP",
                "SecurityName": "VIKAS PROPPANT & GRANITE LTD",
                "FaceValue": "1",
                "ISINNo": "INE767B01022",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531519",
                "SecurityId": "ANKUSHFI",
                "SecurityName": "ANKUSH FINSTOCK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE784D01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531520",
                "SecurityId": "PANIND",
                "SecurityName": "PAN INDIA RESORT & LAND DEVELOPMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE381D01010",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531521",
                "SecurityId": "DESHRAK",
                "SecurityName": "DESH RAKSHAK AUSHDHALAYA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE971E01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531522",
                "SecurityId": "RASOYPR",
                "SecurityName": "RASOYA PROTEINS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE904G01038",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531523",
                "SecurityId": "PALPEUG",
                "SecurityName": "PAL-PEUGEOT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531524",
                "SecurityId": "ICSA",
                "SecurityName": "ICSA (INDIA) LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE306B01029",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531525",
                "SecurityId": "ACESOFT",
                "SecurityName": "ACE SOFTWARE EXPORTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE849B01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531526",
                "SecurityId": "RADRO",
                "SecurityName": "RADIANT ROTOGRAVURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE864C01017",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531527",
                "SecurityId": "LLORF",
                "SecurityName": "LLOYD ROCKFIBRES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE640D01019",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531528",
                "SecurityId": "MAARSOFTW",
                "SecurityName": "MAARS SOFTWARE INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE390A01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531529",
                "SecurityId": "SOFTRAKV",
                "SecurityName": "SOFTRAK VENTURE INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE562E01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531530",
                "SecurityId": "BETALA",
                "SecurityName": "BETALA GLOBAL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE658E01027",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531531",
                "SecurityId": "HATSUN",
                "SecurityName": "HATSUN AGRO PRODUCT LTD.",
                "FaceValue": "1",
                "ISINNo": "INE473B01035",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531532",
                "SecurityId": "GLORYCH",
                "SecurityName": "GLORY CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531533",
                "SecurityId": "ECOM",
                "SecurityName": "E.COM INFOTECH (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE578B01015",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531534",
                "SecurityId": "AMEREDM",
                "SecurityName": "AMERICAN REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE315A01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531535",
                "SecurityId": "CANVYCH",
                "SecurityName": "CANVAY CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531536",
                "SecurityId": "SRIMT",
                "SecurityName": "SRIVEN MULTI-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE004B01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531537",
                "SecurityId": "JYOTHI",
                "SecurityName": "JYOTHI INFRAVENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE681K01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531538",
                "SecurityId": "SHAYONA",
                "SecurityName": "SHAYONA PETROCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE627B01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531539",
                "SecurityId": "RISHDIGA",
                "SecurityName": "RISHABH DIGHA STEEL & ALLIED PRODUCTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE864D01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531540",
                "SecurityId": "MAINFRA",
                "SecurityName": "MARUTI INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE392G01010",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531541",
                "SecurityId": "KOBO",
                "SecurityName": "KOBO BIOTECH LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE881A01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531543",
                "SecurityId": "JINDWORLD",
                "SecurityName": "JINDAL WORLDWIDE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE247D01039",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531544",
                "SecurityId": "VERTEXSPG",
                "SecurityName": "VERTEX SPINNING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE863F01038",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531545",
                "SecurityId": "SURSTON",
                "SecurityName": "SURAJ STONE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE272F01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531546",
                "SecurityId": "WALFARM",
                "SecurityName": "WALVEKAR FARMS & FOOD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531547",
                "SecurityId": "TIRIN",
                "SecurityName": "TIRUPATI INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE329E01017",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531548",
                "SecurityId": "SOMANYCERA",
                "SecurityName": "SOMANY CERAMICS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE355A01028",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531549",
                "SecurityId": "AGRIMAS",
                "SecurityName": "AGRIMAS CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531550",
                "SecurityId": "JHACC",
                "SecurityName": "JHAVERI CREDITS & CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE865D01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531551",
                "SecurityId": "INCORPI",
                "SecurityName": "INTERCORP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531552",
                "SecurityId": "RAGHUTOB",
                "SecurityName": "RAGHUNATH TOBACCO CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE754B01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531553",
                "SecurityId": "DRL",
                "SecurityName": "DISHA RESOURCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE193D01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531554",
                "SecurityId": "TOPTELE",
                "SecurityName": "TOP TELEMEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE632B01036",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531555",
                "SecurityId": "KAMINIS",
                "SecurityName": "KAMINI ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531556",
                "SecurityId": "CORALFINAC",
                "SecurityName": "CORAL INDIA FINANCE & HOUSING LTD.",
                "FaceValue": "2",
                "ISINNo": "INE558D01021",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531557",
                "SecurityId": "AMITSEC",
                "SecurityName": "AMIT SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE137E01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531558",
                "SecurityId": "BHARFOR",
                "SecurityName": "BHARFOR",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531559",
                "SecurityId": "FEDERBK",
                "SecurityName": "FEDERBK",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531560",
                "SecurityId": "AROMAENT",
                "SecurityName": "AROMA ENTERPRISES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE371M01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531561",
                "SecurityId": "AKASHSG",
                "SecurityName": "AKASH HOUSING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531563",
                "SecurityId": "PAMRAFI",
                "SecurityName": "PAM RAFFIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531564",
                "SecurityId": "WELCO",
                "SecurityName": "WELCOME COIR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE818C01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531565",
                "SecurityId": "INDOPACIFIC",
                "SecurityName": "INDO PACIFIC PROJECTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE077C01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531566",
                "SecurityId": "LILACPW",
                "SecurityName": "LILAC POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531567",
                "SecurityId": "VIZARAG",
                "SecurityName": "VIZAR AGROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531568",
                "SecurityId": "ASHUTPM",
                "SecurityName": "ASHUTOSH PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE723K01018",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531569",
                "SecurityId": "SANJIVIN",
                "SecurityName": "SANJIVANI PARANTERAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE860D01013",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531570",
                "SecurityId": "BLAZONMRB",
                "SecurityName": "BLAZON MARBLES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE096H01023",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531571",
                "SecurityId": "PLUSFIN",
                "SecurityName": "PLUS FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531572",
                "SecurityId": "RANJITSE",
                "SecurityName": "RANJIT SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE863D01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531573",
                "SecurityId": "CRESTER",
                "SecurityName": "CRESTER SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531574",
                "SecurityId": "VASINFRA",
                "SecurityName": "VAS INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE192C01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531575",
                "SecurityId": "HPMINDL",
                "SecurityName": "HPM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531576",
                "SecurityId": "MTCAPLS",
                "SecurityName": "MEHTA CAPLEASE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531578",
                "SecurityId": "KMFBLDR",
                "SecurityName": "KMF BUILDERS & DEVELOPERS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE580H01026",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531579",
                "SecurityId": "KEMICARE",
                "SecurityName": "KEMICARE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE784C01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531581",
                "SecurityId": "ALKADIA",
                "SecurityName": "ALKA DIAMOND INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE963D01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531582",
                "SecurityId": "BERYLSE",
                "SecurityName": "BERYL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE508J01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531583",
                "SecurityId": "RAP",
                "SecurityName": "RAP MEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE483D01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531584",
                "SecurityId": "MARTEXT",
                "SecurityName": "MARSON&#39;S TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531585",
                "SecurityId": "DEVINE",
                "SecurityName": "DEVINE IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE455C01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531587",
                "SecurityId": "KNIWE",
                "SecurityName": "KNITWORTH EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE171C01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531588",
                "SecurityId": "ADITYAIN",
                "SecurityName": "ADITYA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE908B01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531589",
                "SecurityId": "GEDEVEL",
                "SecurityName": "GENESIS DEVELOPERS & RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531590",
                "SecurityId": "BILPOWER",
                "SecurityName": "BILPOWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE952D01018",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531591",
                "SecurityId": "BAMPSL",
                "SecurityName": "BAMPSL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE802A01037",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531592",
                "SecurityId": "GENPHARMA",
                "SecurityName": "GENERIC PHARMASEC LTD",
                "FaceValue": "1",
                "ISINNo": "INE861N01036",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531593",
                "SecurityId": "AROCSIL",
                "SecurityName": "AROCHEM SILVASSA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531594",
                "SecurityId": "INCON",
                "SecurityName": "INCON ENGINEERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE507D01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531595",
                "SecurityId": "CGCL",
                "SecurityName": "CAPRI GLOBAL CAPITAL LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE180C01026",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531596",
                "SecurityId": "VISALAG",
                "SecurityName": "VISHAL AGRITECH INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531597",
                "SecurityId": "MIDPOLY",
                "SecurityName": "MIDLAND POLYMERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE046M01028",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531598",
                "SecurityId": "NIMBUSFOO",
                "SecurityName": "NIMBUS FOODS INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE301B01020",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531599",
                "SecurityId": "FDC",
                "SecurityName": "FDC LTD.",
                "FaceValue": "1",
                "ISINNo": "INE258B01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531600",
                "SecurityId": "GOGIACAP",
                "SecurityName": "GOGIA CAPITAL SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE832C01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531601",
                "SecurityId": "GCVSERV",
                "SecurityName": "GCV SERVICES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE236B01036",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531602",
                "SecurityId": "KOFFBREAK",
                "SecurityName": "KOFFEE BREAK PICTURES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE208D01023",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531603",
                "SecurityId": "SAWACA",
                "SecurityName": "SAWACA COMMUNICATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE185B01019",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531604",
                "SecurityId": "SRIKAND",
                "SecurityName": "SRI SKANDAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531605",
                "SecurityId": "LLOYDCE",
                "SecurityName": "LLOYD CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531606",
                "SecurityId": "ICPSECL",
                "SecurityName": "ICP SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531607",
                "SecurityId": "MFLHF",
                "SecurityName": "MFL HOUSING FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE574C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531608",
                "SecurityId": "GORANIN",
                "SecurityName": "GORANI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE792J01015",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531609",
                "SecurityId": "KGPETRO",
                "SecurityName": "KG PETROCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE902G01016",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531610",
                "SecurityId": "UFMINDL",
                "SecurityName": "UFM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE832E01010",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531611",
                "SecurityId": "AADHAARVEN",
                "SecurityName": "AADHAAR VENTURES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE063D01030",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531612",
                "SecurityId": "KGNIND",
                "SecurityName": "KGN INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE196C01022",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531613",
                "SecurityId": "MAL",
                "SecurityName": "MEYER APPAREL LTD",
                "FaceValue": "3",
                "ISINNo": "INE100C01024",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531614",
                "SecurityId": "MICRONC",
                "SecurityName": "MICRON CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531615",
                "SecurityId": "ERABUILD",
                "SecurityName": "ERA BUILDSYS LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE040E01028",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531616",
                "SecurityId": "STARCOM",
                "SecurityName": "STARCOM INFORMATION TECHNOLOGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE347I01010",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531617",
                "SecurityId": "OSIAIND",
                "SecurityName": "OSIAN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE634M01013",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531618",
                "SecurityId": "MARALFN",
                "SecurityName": "MARAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531620",
                "SecurityId": "ENEPRO",
                "SecurityName": "ENERGY PRODUCTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE718D01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531621",
                "SecurityId": "CENTERAC",
                "SecurityName": "CENTERAC TECHNOLOGIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE157B01026",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531623",
                "SecurityId": "JYOTPOL",
                "SecurityName": "JYOTI POLY VINYL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531624",
                "SecurityId": "COUNCODOS",
                "SecurityName": "COUNTRY CONDO&#39;S LTD.",
                "FaceValue": "1",
                "ISINNo": "INE695B01025",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531625",
                "SecurityId": "SANHP",
                "SecurityName": "SANGAM HEALTH CARE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE431E01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531626",
                "SecurityId": "OROSMITHS",
                "SecurityName": "OROSIL SMITHS INDIA LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE628B01034",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531627",
                "SecurityId": "RAGHAVAEPL",
                "SecurityName": "RAGHAVA ESTATES AND PROPERTIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE348J01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531628",
                "SecurityId": "TEJASSVI",
                "SecurityName": "TEJASSVI AAHARAM LTD",
                "FaceValue": "10",
                "ISINNo": "INE173E01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531629",
                "SecurityId": "RICHF",
                "SecurityName": "RICH GOLD FINANCE AND SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE885D01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531630",
                "SecurityId": "CANOPYS",
                "SecurityName": "CANOPY SECURITIES & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531632",
                "SecurityId": "AVINF",
                "SecurityName": "AVINASH INFORMATION TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE452C01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531633",
                "SecurityId": "LINCOPH",
                "SecurityName": "LINCOLN PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE405C01035",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531634",
                "SecurityId": "ROYINDL",
                "SecurityName": "ROYAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531635",
                "SecurityId": "SILVOAK",
                "SecurityName": "SILVER OAK (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE870J01019",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531636",
                "SecurityId": "TUSEXPO",
                "SecurityName": "TUSCON EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531637",
                "SecurityId": "PRAVEG",
                "SecurityName": "PRAVEG COMMUNICATIONS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE722B01019",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531638",
                "SecurityId": "SURAJLTD",
                "SecurityName": "SURAJ LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE713C01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531639",
                "SecurityId": "ROCHEBRE",
                "SecurityName": "ROCHEES BREWERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE382D01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531640",
                "SecurityId": "SICL",
                "SecurityName": "SUVIDHA INFRAESTATE CORPORATION LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE936N01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531642",
                "SecurityId": "MARICO",
                "SecurityName": "MARICO LTD.",
                "FaceValue": "1",
                "ISINNo": "INE196A01026",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531643",
                "SecurityId": "ASRAGRO",
                "SecurityName": "ASR AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531644",
                "SecurityId": "TOKYOFIN",
                "SecurityName": "TOKYO FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE546D01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531645",
                "SecurityId": "SOUISPAT",
                "SecurityName": "SOUTHERN ISPAT AND ENERGY LTD-$",
                "FaceValue": "1",
                "ISINNo": "INE943B01029",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531646",
                "SecurityId": "RFLINT",
                "SecurityName": "RFL INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE701D01035",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531647",
                "SecurityId": "BJDUP",
                "SecurityName": "B.J.DUPLEX BOARDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE265C01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531648",
                "SecurityId": "MAHAVIRIND",
                "SecurityName": "MAHAVIR INDUSTRIES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE987M01023",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531649",
                "SecurityId": "WERTERNIN",
                "SecurityName": "WESTERN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE728C01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531650",
                "SecurityId": "VAXHS",
                "SecurityName": "VAX HOUSING FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE761B01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531651",
                "SecurityId": "NATGENI",
                "SecurityName": "NATIONAL GENERAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE654H01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531652",
                "SecurityId": "THIRDFIN",
                "SecurityName": "THIRDWAVE FINANCIAL INTERMEDIARIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE528C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531653",
                "SecurityId": "CHABRASP",
                "SecurityName": "CHHABRA SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE420D01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531655",
                "SecurityId": "CHETAKS",
                "SecurityName": "CHETAK SPINTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531656",
                "SecurityId": "CRYPTAG",
                "SecurityName": "CRYPTOGEN AGRO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531657",
                "SecurityId": "RANXLAB",
                "SecurityName": "RANXLAB",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531658",
                "SecurityId": "TRIJAL",
                "SecurityName": "TRIJAL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE454E01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531659",
                "SecurityId": "TSL",
                "SecurityName": "TSL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE547D01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531660",
                "SecurityId": "GLOBALFIL",
                "SecurityName": "GLOBAL FILMS & BROADCASTING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE174D01027",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531661",
                "SecurityId": "HITTCO",
                "SecurityName": "HITTCO TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE863C01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531662",
                "SecurityId": "PSCABLE",
                "SecurityName": "PASHUPATI CABLES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531663",
                "SecurityId": "YUVRAAJHPL",
                "SecurityName": "YUVRAAJ HYGIENE PRODUCTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE139D01020",
                "Industry": "Household Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531664",
                "SecurityId": "MAHAV",
                "SecurityName": "MAHAVIR IMPEX LTD.",
                "FaceValue": "5",
                "ISINNo": "INE997B01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531665",
                "SecurityId": "JAYNTPA",
                "SecurityName": "JAYNTPA",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531666",
                "SecurityId": "ARHNTIN",
                "SecurityName": "ARHNTIN",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531667",
                "SecurityId": "RRMETAL",
                "SecurityName": "RR METALMAKERS INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE117K01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531668",
                "SecurityId": "VISIONCO",
                "SecurityName": "VISION CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661D01015",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531669",
                "SecurityId": "NISUFIN",
                "SecurityName": "NISU FINCAP LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531670",
                "SecurityId": "CLASSICB",
                "SecurityName": "CLASSIC BIOTECH & EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE038C01018",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531671",
                "SecurityId": "BISIL",
                "SecurityName": "BISIL PLAST LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE214D01021",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531672",
                "SecurityId": "INANISEC",
                "SecurityName": "INANI SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE224C01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531673",
                "SecurityId": "ANKIN",
                "SecurityName": "ANKA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE067C01025",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531674",
                "SecurityId": "INDOASIAP",
                "SecurityName": "INDO-ASIAN PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE559F01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531675",
                "SecurityId": "TRICOM",
                "SecurityName": "TRICOM INDIA LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE771B01032",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531676",
                "SecurityId": "VAGHANI",
                "SecurityName": "VAGHANI TECHNO-BUILD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE554H01021",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531677",
                "SecurityId": "ARISINT",
                "SecurityName": "ARIS INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE588E01026",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531678",
                "SecurityId": "ANANDCR",
                "SecurityName": "ANAND CREDIT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE703J01012",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531680",
                "SecurityId": "MAYUR",
                "SecurityName": "MAYUR LEATHER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE799E01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531681",
                "SecurityId": "AMARDEE",
                "SecurityName": "AMRADEEP INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE673C01020",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531682",
                "SecurityId": "CATECH",
                "SecurityName": "CAT TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE170B01037",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531683",
                "SecurityId": "ANKURDRUGS",
                "SecurityName": "ANKUR DRUGS & PHARMA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE238D01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531685",
                "SecurityId": "FRONTFN",
                "SecurityName": "FRONTLINE FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE776R01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531686",
                "SecurityId": "ADVIKLA",
                "SecurityName": "ADVIK LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE537C01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531687",
                "SecurityId": "KGL",
                "SecurityName": "KARUTURI GLOBAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE299C01024",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531688",
                "SecurityId": "PRITHVIEXCH",
                "SecurityName": "PRITHVI EXCHANGE (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE621B01021",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531689",
                "SecurityId": "MAHAREM",
                "SecurityName": "MAHAVIR ADVANCED REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE716N01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531690",
                "SecurityId": "SEAGUL",
                "SecurityName": "SEAGULL LEAFIN LTD.",
                "FaceValue": "1",
                "ISINNo": "INE132D01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531691",
                "SecurityId": "RUSODSE",
                "SecurityName": "RUSODAY SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531692",
                "SecurityId": "KHYATI",
                "SecurityName": "KHYATI MULTIMEDIA-ENTERTAINMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE593B01030",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531693",
                "SecurityId": "YANTRA",
                "SecurityName": "YANTRA NATURAL RESOURCES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE903B01023",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531694",
                "SecurityId": "RAINBOWF",
                "SecurityName": "RAINBOW FOUNDATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE230F01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531695",
                "SecurityId": "VERONICAPRO",
                "SecurityName": "VERONICA PRODUCTION LTD",
                "FaceValue": "10",
                "ISINNo": "INE008M01044",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531696",
                "SecurityId": "VSDCONF",
                "SecurityName": "VSD CONFIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE489F01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531697",
                "SecurityId": "VIPSECU",
                "SecurityName": "VIPUL SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531698",
                "SecurityId": "KRFOODS",
                "SecurityName": "K.R.FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531699",
                "SecurityId": "SUPRDOM",
                "SecurityName": "SUPER DOMESTIC MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE215Q01012",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531700",
                "SecurityId": "RSCORP",
                "SecurityName": "R.S CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE246G01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531701",
                "SecurityId": "SHSAINA",
                "SecurityName": "SHRI SAINATH PROTEINS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531703",
                "SecurityId": "TRIBHSG",
                "SecurityName": "TRIBHUVAN HOUSING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE884D01039",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531704",
                "SecurityId": "ROCPROJ",
                "SecurityName": "ROCKLINE PROJECT LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531705",
                "SecurityId": "LANYARD",
                "SecurityName": "LANYARD FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE937E01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531706",
                "SecurityId": "VIJYREM",
                "SecurityName": "VIJAY REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531707",
                "SecurityId": "SUNINFO",
                "SecurityName": "SUN INFOWAYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE236C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531709",
                "SecurityId": "GOLDMUL",
                "SecurityName": "GOLD MULTIFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531710",
                "SecurityId": "PRUDSTK",
                "SecurityName": "PRUDENTIAL STOCK & SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531712",
                "SecurityId": "TRILOGIC",
                "SecurityName": "TRILOGIC DIGITAL MEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE532D01018",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531713",
                "SecurityId": "BLUCHIP",
                "SecurityName": "BLUECHIP STOCKSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE337F01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531714",
                "SecurityId": "MANMADE",
                "SecurityName": "MANMADE SPINNERS (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531715",
                "SecurityId": "SHUKUN",
                "SecurityName": "SHUKUN CONSTRUCTION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE171E01021",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531716",
                "SecurityId": "TRICOMFRU",
                "SecurityName": "TRICOM FRUIT PRODUCTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE843F01014",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531717",
                "SecurityId": "VIDHIING",
                "SecurityName": "VIDHI SPECIALTY FOOD INGREDIENTS LTD-$",
                "FaceValue": "1",
                "ISINNo": "INE632C01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531718",
                "SecurityId": "ANIKSHIP",
                "SecurityName": "ANIK SHIP BREAKING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531719",
                "SecurityId": "BHAGCHEM",
                "SecurityName": "BHAGIRADHA CHEMICALS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE414D01019",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531720",
                "SecurityId": "ALPGRAPH",
                "SecurityName": "ALPHA GRAPHIC INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE952B01020",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531721",
                "SecurityId": "ZENGLOB",
                "SecurityName": "ZEN GLOBAL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531722",
                "SecurityId": "SHIVANA",
                "SecurityName": "SHIVANI VANASPATI LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531723",
                "SecurityId": "STAMPEDE",
                "SecurityName": "STAMPEDE CAPITAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE224E01028",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531724",
                "SecurityId": "HRBFLOR",
                "SecurityName": "HRB FLORICULTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE284D01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531725",
                "SecurityId": "MCCIL",
                "SecurityName": "M.C.C.INVESTMENT & LEASING CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE786C01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531726",
                "SecurityId": "PANCHSHEEL",
                "SecurityName": "PANCHSHEEL ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE316G01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531727",
                "SecurityId": "MENNPIS",
                "SecurityName": "MENON PISTONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE650G01029",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531728",
                "SecurityId": "AMRITBANA",
                "SecurityName": "AMRIT BANASPATI COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE221G01029",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531729",
                "SecurityId": "SKYLIDTE",
                "SecurityName": "SKYLID TELECOMMUNICATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE169B01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531730",
                "SecurityId": "CAPSEINDM",
                "SecurityName": "CAPSEIN BIO-LAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531731",
                "SecurityId": "AARYAGLOBL",
                "SecurityName": "AARYA GLOBAL SHARES AND SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE233H01030",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531732",
                "SecurityId": "MERCFUND",
                "SecurityName": "MERCURY FUND MANAGEMENT CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE047D01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531733",
                "SecurityId": "BAFNASP",
                "SecurityName": "BAFNA SPINNING MILLS & EXPORTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE657F01034",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531734",
                "SecurityId": "NICCOCORQ",
                "SecurityName": "NICCO CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE241C01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531735",
                "SecurityId": "PRISMFN",
                "SecurityName": "PRISM FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE429Q01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531736",
                "SecurityId": "SSPPOLY",
                "SecurityName": "SSP POLYMER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531737",
                "SecurityId": "GREENCREST",
                "SecurityName": "GREENCREST FINANCIAL SERVICES LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE414C01029",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531738",
                "SecurityId": "SILICON",
                "SecurityName": "SILICON VALLEY INFOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE913A01024",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531739",
                "SecurityId": "GENNEX",
                "SecurityName": "GENNEX LABORATORIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE509C01026",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531740",
                "SecurityId": "PATPART",
                "SecurityName": "PAT-PARTH ENGINEERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531741",
                "SecurityId": "STGUJHS",
                "SecurityName": "STERLING (GUJARAT) HOSPITALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531742",
                "SecurityId": "SKYCHEM",
                "SecurityName": "SKYLEAD CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531743",
                "SecurityId": "HIRAUTO",
                "SecurityName": "HIRA AUTOMOBILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE258Z01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531744",
                "SecurityId": "GINISILK",
                "SecurityName": "GINI SILK MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE548B01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531745",
                "SecurityId": "SGLOBEX",
                "SecurityName": "SG GLOBAL EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531746",
                "SecurityId": "PRAENG",
                "SecurityName": "PRAJAY ENGINEERS SYNDICATE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE505C01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531747",
                "SecurityId": "MECAQUI",
                "SecurityName": "MECA QUILTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531748",
                "SecurityId": "GRANFLO",
                "SecurityName": "GRANDLEAS FLOREX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531750",
                "SecurityId": "ENCORE",
                "SecurityName": "ENCORE SOFTWARE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE103B01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531751",
                "SecurityId": "SONCLOC",
                "SecurityName": "SONELL CLOCKS & GIFTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE086P01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531752",
                "SecurityId": "SUNTECHNO",
                "SecurityName": "SUN TECHNO OVERSEAS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE703D01023",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531753",
                "SecurityId": "FLUIDARDM",
                "SecurityName": "FLUID-AIR (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531754",
                "SecurityId": "FINCOM",
                "SecurityName": "FINTECH COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE815B01011",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531755",
                "SecurityId": "DOMILEADM",
                "SecurityName": "DOMINO LEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531756",
                "SecurityId": "LIVERPO",
                "SecurityName": "LIVERPOOL FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE376D01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531757",
                "SecurityId": "RKSUITG",
                "SecurityName": "R.K.SUITINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531758",
                "SecurityId": "GKCONS",
                "SecurityName": "G.K.CONSULTANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE131D01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531759",
                "SecurityId": "EXELSOF",
                "SecurityName": "EXEL SOFTWARES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531760",
                "SecurityId": "FUFITIN",
                "SecurityName": "FUSION FITTINGS (I) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE284L01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531761",
                "SecurityId": "APOLLOPIPES",
                "SecurityName": "APOLLO PIPES LTD",
                "FaceValue": "10",
                "ISINNo": "INE126J01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531762",
                "SecurityId": "UNJHAFOR",
                "SecurityName": "UNJHA FORMULATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE576D01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531763",
                "SecurityId": "MZFABRI",
                "SecurityName": "MAZDA FABRIC PROCESSORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531764",
                "SecurityId": "SUPRTELE",
                "SecurityName": "SUPREME TELECOM & NETWORK INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE310D01019",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531765",
                "SecurityId": "JMDALOY",
                "SecurityName": "JMD ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531767",
                "SecurityId": "HNCOMPR",
                "SecurityName": "HINDUSTAN COMPRESSORS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531768",
                "SecurityId": "POLYMED",
                "SecurityName": "POLY MEDICURE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE205C01021",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531769",
                "SecurityId": "PFLINFOTC",
                "SecurityName": "PFL INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE600F01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531770",
                "SecurityId": "SHELMER",
                "SecurityName": "SHELL MERCANTILE CORP.LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531771",
                "SecurityId": "TOWASOK",
                "SecurityName": "TOWA SOKKI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE311M01018",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531772",
                "SecurityId": "CHETEXT",
                "SecurityName": "CHETEXT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531773",
                "SecurityId": "SHALIINF",
                "SecurityName": "SHALIBHADRA INFOSEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE302B01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531774",
                "SecurityId": "TRIPEXO",
                "SecurityName": "TRIPEX OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE360G01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531775",
                "SecurityId": "CISTRO",
                "SecurityName": "CISTRO TELELINK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE365C01023",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531777",
                "SecurityId": "INTELVIS",
                "SecurityName": "INTELLVISIONS SOFTWARE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE600C01015",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531778",
                "SecurityId": "KACHCHH",
                "SecurityName": "KACHCHH MINERALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE059E01010",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531779",
                "SecurityId": "PADALPO",
                "SecurityName": "PADMANABH ALLOYS & POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE630U01014",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531780",
                "SecurityId": "KACL",
                "SecurityName": "KAISER CORPORATION LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE229G01022",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531781",
                "SecurityId": "SAPANCHEM",
                "SecurityName": "SAPAN CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE823E01035",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531782",
                "SecurityId": "OASISME",
                "SecurityName": "OASIS MEDIA MATRIX LTD.",
                "FaceValue": "1",
                "ISINNo": "INE335D01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531783",
                "SecurityId": "AZUREEXIM",
                "SecurityName": "AZURE EXIM SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE837F01016",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531784",
                "SecurityId": "KCLINFRA",
                "SecurityName": "KCL INFRA PROJECTS LTD",
                "FaceValue": "2",
                "ISINNo": "INE469F01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531785",
                "SecurityId": "AMERPNT",
                "SecurityName": "AMERICAN PAINTS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531786",
                "SecurityId": "AESCURE",
                "SecurityName": "AESCULAPIUS REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE965E01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531787",
                "SecurityId": "AESCREM",
                "SecurityName": "AESCREM",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531788",
                "SecurityId": "RHUTUDY",
                "SecurityName": "RHUTU UDYOG (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531790",
                "SecurityId": "UNIMEDIA",
                "SecurityName": "UNIVERSAL MEDIA NETWORK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE100B01026",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531791",
                "SecurityId": "NOVAGOLD",
                "SecurityName": "NOVAGOLD PETRO-RESOURCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE517G01012",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531792",
                "SecurityId": "MAALFNC",
                "SecurityName": "MAA LEAFIN & CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531793",
                "SecurityId": "INFRAQUEST",
                "SecurityName": "INFRAQUEST INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE786B01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531794",
                "SecurityId": "SESHACHAL",
                "SecurityName": "SESHACHAL TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE331F01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531795",
                "SecurityId": "ATULAUTO",
                "SecurityName": "ATUL AUTO LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE951D01028",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531796",
                "SecurityId": "SRINFEA",
                "SecurityName": "SRINIVASA FERRO ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531797",
                "SecurityId": "SCANPRO",
                "SecurityName": "SCAN PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE393D01015",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531799",
                "SecurityId": "SHARPRIN",
                "SecurityName": "SHARPSCAN & PRINTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE795C01013",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531800",
                "SecurityId": "SHEETALB",
                "SecurityName": "SHEETAL BIO-AGRO TECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE612C01028",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531801",
                "SecurityId": "MANNA",
                "SecurityName": "MANNA GLASS-TECH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE552C01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531802",
                "SecurityId": "PRERINFRA",
                "SecurityName": "PRERNA INFRABUILD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE426H01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531803",
                "SecurityId": "VIRDEVE",
                "SecurityName": "VIR DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531806",
                "SecurityId": "PUNLSFN",
                "SecurityName": "PUNJAB LEASE FINANCING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531807",
                "SecurityId": "INGVYSYABK",
                "SecurityName": "ING VYSYA BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE166A01011",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531810",
                "SecurityId": "METALCO",
                "SecurityName": "METAL COATINGS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE161E01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531812",
                "SecurityId": "SGNTE",
                "SecurityName": "SGN TELECOMS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE266C01023",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531813",
                "SecurityId": "GANGAPA",
                "SecurityName": "GANGA PAPERS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE278O01015",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531814",
                "SecurityId": "TIRSARJ",
                "SecurityName": "TIRUPATI SARJAN LTD.",
                "FaceValue": "5",
                "ISINNo": "INE297J01023",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531816",
                "SecurityId": "PANORAMUNI",
                "SecurityName": "PANORAMIC UNIVERSAL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE194B01029",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531818",
                "SecurityId": "HYDEQUI",
                "SecurityName": "HYDROFLEX EQUIPMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531819",
                "SecurityId": "NUWAY",
                "SecurityName": "NUWAY ORGANIC NATURALS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE414L01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531820",
                "SecurityId": "FINCR",
                "SecurityName": "FINALYSIS CREDIT & GUARANTEE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE977C01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531821",
                "SecurityId": "MUNOTHFI",
                "SecurityName": "MUNOTH FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE348D01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531822",
                "SecurityId": "RODIUM",
                "SecurityName": "RODIUM REALTY LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE513E01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531823",
                "SecurityId": "ARVINDREM",
                "SecurityName": "ARVIND REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE211C01037",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531825",
                "SecurityId": "RCCEMEN",
                "SecurityName": "RCC CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE335N01015",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531826",
                "SecurityId": "DKARTAV",
                "SecurityName": "DEE KARTAVYA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE219N01011",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531827",
                "SecurityId": "HIMFINC",
                "SecurityName": "HIMGIRI FINCAP LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531828",
                "SecurityId": "MANDUIN",
                "SecurityName": "MANDU INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531829",
                "SecurityId": "SANVAN",
                "SecurityName": "SANVAN SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE156B01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531830",
                "SecurityId": "TODAYS",
                "SecurityName": "TODAYS WRITING INSTRUMENTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE944B01019",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531831",
                "SecurityId": "UNISH",
                "SecurityName": "UNISYS SOFTWARES & HOLDING INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE200B01016",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531832",
                "SecurityId": "NAGTECH",
                "SecurityName": "NAGARJUNA AGRI TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE793H01017",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531833",
                "SecurityId": "KRIPSPG",
                "SecurityName": "KRIPAL SPRINGS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531834",
                "SecurityId": "NATHUEC",
                "SecurityName": "NATURA HUE CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE487B01019",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531835",
                "SecurityId": "EICHERLTD",
                "SecurityName": "EICHER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE065A01023",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531836",
                "SecurityId": "NIRSA",
                "SecurityName": "NIRYAT-SAM APPARELS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE645D01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531837",
                "SecurityId": "GLOSS",
                "SecurityName": "GLOBE STOCKS & SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE060C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531838",
                "SecurityId": "SMSTECH",
                "SecurityName": "SMS TECHSOFT (INDIA) LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE648B01024",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531839",
                "SecurityId": "JJSPECT",
                "SecurityName": "J.J.SPECTRUM SILK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE893B01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531840",
                "SecurityId": "IECEDU",
                "SecurityName": "IEC EDUCATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE172B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531841",
                "SecurityId": "INDUSFINL",
                "SecurityName": "INDUS FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE935D01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531842",
                "SecurityId": "LAHOTIOV",
                "SecurityName": "LAHOTI OVERSEAS LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE515C01023",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531843",
                "SecurityId": "MYMTECH",
                "SecurityName": "MYM TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE378B01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531844",
                "SecurityId": "SU-RAJD",
                "SecurityName": "SU-RAJD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531845",
                "SecurityId": "ZENITHSTL",
                "SecurityName": "ZENITH STEEL PIPES & INDUSTRIES LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE318D01020",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531846",
                "SecurityId": "TRINITYLEA",
                "SecurityName": "TRINITY LEAGUE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE884X01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531847",
                "SecurityId": "ASTAR",
                "SecurityName": "ASIAN STAR CO.LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE194D01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531848",
                "SecurityId": "REGEAGP",
                "SecurityName": "REGENT AGRO PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531850",
                "SecurityId": "KAMAOVR",
                "SecurityName": "KAMAL OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531851",
                "SecurityId": "ROHITIS",
                "SecurityName": "ROHIT TISSUE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531852",
                "SecurityId": "APOWERTOOL",
                "SecurityName": "CONSORTEX KARL DOELITZCH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE870A01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531853",
                "SecurityId": "SHIVTEX",
                "SecurityName": "SHIVA SUTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531854",
                "SecurityId": "FUNWTRD",
                "SecurityName": "FUNWORLD & TOURISM DEVELOPMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE288H01018",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531855",
                "SecurityId": "PRABHAVIN",
                "SecurityName": "PRABHAV INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE538J01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531856",
                "SecurityId": "ARHSUED",
                "SecurityName": "ARHANT SUEDES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531859",
                "SecurityId": "ORVENPR",
                "SecurityName": "ORIENTAL VENEER PRODUCTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE457G01029",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531860",
                "SecurityId": "GLOES",
                "SecurityName": "GLOBAL E-SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE553D01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531861",
                "SecurityId": "JOINDRE",
                "SecurityName": "JOINDRE CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE024B01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531862",
                "SecurityId": "BHARATAGRI",
                "SecurityName": "BHARAT AGRI FERT & REALTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE842D01011",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531863",
                "SecurityId": "SIGRUN",
                "SecurityName": "SIGRUN HOLDINGS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE633D01022",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531864",
                "SecurityId": "PALDISTDM",
                "SecurityName": "PAL DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531865",
                "SecurityId": "VOLTM",
                "SecurityName": "VOLANT TEXTILE MILLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE962D01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531866",
                "SecurityId": "AAGAMCAP",
                "SecurityName": "AAGAM CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE817D01013",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531867",
                "SecurityId": "UNITINT",
                "SecurityName": "UNITECH INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE929K01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531868",
                "SecurityId": "VIFAIRW",
                "SecurityName": "VIF AIRWAYS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531869",
                "SecurityId": "SACHEMT",
                "SecurityName": "SACHETA METALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE433G01012",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531870",
                "SecurityId": "POPULARES",
                "SecurityName": "POPULAR ESTATE MANAGEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE370C01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531872",
                "SecurityId": "GLOBSYN",
                "SecurityName": "GLOBAL SYNTEX (BHILWARA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531873",
                "SecurityId": "PEQGRTRDM",
                "SecurityName": "PNB EQUITY GROWTH FUND-1993",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531874",
                "SecurityId": "VENUSPOWR",
                "SecurityName": "VENUS POWER VENTURES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE836G01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531875",
                "SecurityId": "IFBINDL",
                "SecurityName": "IFBINDL",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531876",
                "SecurityId": "GHANS",
                "SecurityName": "GHANSHYAM STEEL WORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE993C01014",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531877",
                "SecurityId": "DWARKFN",
                "SecurityName": "DWARKESH FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531878",
                "SecurityId": "ANJANIFIN",
                "SecurityName": "ANJANI FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE283D01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531879",
                "SecurityId": "PIONDIST",
                "SecurityName": "PIONEER DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE889E01010",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531880",
                "SecurityId": "LYOIE",
                "SecurityName": "LYONS INDUSTRIAL ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE333B01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531881",
                "SecurityId": "GMETCOAL",
                "SecurityName": "GUJARAT METALLIC COAL & COKE LTD.-$",
                "FaceValue": "100",
                "ISINNo": "INE146F01020",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531882",
                "SecurityId": "KWALITY",
                "SecurityName": "KWALITY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE775B01025",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531883",
                "SecurityId": "IDBIMUT",
                "SecurityName": "IDBIMUT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531884",
                "SecurityId": "IDBIGRO",
                "SecurityName": "IDBIGRO",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531885",
                "SecurityId": "SVAINDIA",
                "SecurityName": "SVA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE763K01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531886",
                "SecurityId": "SCOPEIND",
                "SecurityName": "SCOPE INDUSTRIES (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE747C01014",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531887",
                "SecurityId": "RAHME",
                "SecurityName": "RAHUL MERCHANDISING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE149D01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531888",
                "SecurityId": "REXNORD",
                "SecurityName": "REXNORD ELECTRONICS & CONTROLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE687C01012",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531889",
                "SecurityId": "INTECH",
                "SecurityName": "INTEGRATED TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE882B01029",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531890",
                "SecurityId": "SNOWCEMIND",
                "SecurityName": "SNOWCEM INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE734A01016",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531891",
                "SecurityId": "CONEQUE",
                "SecurityName": "CONDEQUIP ENGINEERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531892",
                "SecurityId": "KHANDSE",
                "SecurityName": "KHANDWALA SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE060B01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531893",
                "SecurityId": "SAWABUSI",
                "SecurityName": "SAWACA BUSINESS MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE248B01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531894",
                "SecurityId": "VATSAMUS",
                "SecurityName": "VATSA MUSIC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE816A01011",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531895",
                "SecurityId": "GLOKNIT",
                "SecurityName": "GLOBAL KNITFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "INE010901010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531896",
                "SecurityId": "LICDHNMDM",
                "SecurityName": "DHAN 88(1)",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531897",
                "SecurityId": "ACCENTECH",
                "SecurityName": "ACCENTIA TECHNOLOGIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE122B01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531898",
                "SecurityId": "SANGUI",
                "SecurityName": "SANGUINE MEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE617F01038",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531899",
                "SecurityId": "TRYWLIN",
                "SecurityName": "TRYWELL INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531900",
                "SecurityId": "CCLINTER",
                "SecurityName": "CCL INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE778E01031",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531901",
                "SecurityId": "STERWEB",
                "SecurityName": "STERLING WEBNET LTD.",
                "FaceValue": "1",
                "ISINNo": "INE313B01025",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531902",
                "SecurityId": "GALLOPENT",
                "SecurityName": "GALLOPS ENTERPRISE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE755J01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531903",
                "SecurityId": "NATFLASK",
                "SecurityName": "NATIONAL FLASK INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE350B01019",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531904",
                "SecurityId": "GLOBUSCOR",
                "SecurityName": "GLOBUS CORPORATION LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE774B01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531905",
                "SecurityId": "ACCUEXPO",
                "SecurityName": "ACCURATE EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE062B01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531906",
                "SecurityId": "BILATOR",
                "SecurityName": "BILATI (ORISSA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531907",
                "SecurityId": "DIVINEENT",
                "SecurityName": "DIVINE ENTERTAINMENT LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE376B01014",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531908",
                "SecurityId": "SHRUBER",
                "SecurityName": "SHREE RUBBER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531909",
                "SecurityId": "SWAGRUHA",
                "SecurityName": "SWAGRUHA INFRASTRUCTURE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE587J01027",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531910",
                "SecurityId": "MADHUVEER",
                "SecurityName": "MADHUVEER COM 18 NETWORK LTD",
                "FaceValue": "10",
                "ISINNo": "INE312M01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531911",
                "SecurityId": "GALAGEX",
                "SecurityName": "GALAXY AGRICO EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE803L01016",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531913",
                "SecurityId": "GOPAIST",
                "SecurityName": "GOPAL IRON & STEELS CO.(GUJARAT) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE641H01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531914",
                "SecurityId": "REGSPIN",
                "SecurityName": "REGENCY SPIN-TEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531915",
                "SecurityId": "GSPLIND",
                "SecurityName": "GSPL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531916",
                "SecurityId": "KUMETAL",
                "SecurityName": "KUMAR&#39;S METALLURGICAL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531917",
                "SecurityId": "TWINSTAR",
                "SecurityName": "TWINSTAR INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE070B01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531918",
                "SecurityId": "HINDAPL",
                "SecurityName": "HINDUSTAN APPLIANCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE02XD01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531919",
                "SecurityId": "MUDITFN",
                "SecurityName": "MUDIT FINLEASE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE220D01010",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531920",
                "SecurityId": "SURYODAL",
                "SecurityName": "SURYODAYA ALLO-METAL POWDERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE804C01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531921",
                "SecurityId": "AGARIND",
                "SecurityName": "AGARWAL INDUSTRIAL CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE204E01012",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531922",
                "SecurityId": "VOLPLAST",
                "SecurityName": "VOLPLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE489C01013",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531923",
                "SecurityId": "DHAMPURE",
                "SecurityName": "DHAMPURE SPECIALITY SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE956B01013",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531924",
                "SecurityId": "ALPSINFO",
                "SecurityName": "ALPS INFOSYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE098B01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531925",
                "SecurityId": "SHAQUAK",
                "SecurityName": "SHANTANU SHEOREY AQUAKULT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545N01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531926",
                "SecurityId": "PRTINDL",
                "SecurityName": "PARTAP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531927",
                "SecurityId": "NEXCEN",
                "SecurityName": "NEXCEN SOFTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE180B01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531928",
                "SecurityId": "GOLCA",
                "SecurityName": "GOLDEN CARPETS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE595D01015",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531929",
                "SecurityId": "INNOCORP",
                "SecurityName": "INNOCORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE214B01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531930",
                "SecurityId": "SARTHAKIND",
                "SecurityName": "SARTHAK INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE074H01012",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531931",
                "SecurityId": "SAICAPI",
                "SecurityName": "SAI CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE531X01018",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531932",
                "SecurityId": "CGIMPEX",
                "SecurityName": "C.G.IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE060F01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531933",
                "SecurityId": "WASHISOF",
                "SecurityName": "WASHINGTON SOFTWARES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE163B01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531934",
                "SecurityId": "SUPREMETEX",
                "SecurityName": "SUPREME TEX MART LTD.",
                "FaceValue": "5",
                "ISINNo": "INE651G01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531935",
                "SecurityId": "ASINSTR",
                "SecurityName": "ASEAN INDUSTRIAL STRUCTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531936",
                "SecurityId": "BLUECHIP",
                "SecurityName": "BLUE CHIP INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE657B01025",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531937",
                "SecurityId": "BECKONIN",
                "SecurityName": "BECKONS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE955B01015",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531938",
                "SecurityId": "MOHFAST",
                "SecurityName": "MOHINDRA FASTENERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531939",
                "SecurityId": "GENIUSCO",
                "SecurityName": "GENUS COMMU-TRADE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE577B01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531940",
                "SecurityId": "VINTAFD",
                "SecurityName": "VINTAGE FOODS & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531941",
                "SecurityId": "INESODM",
                "SecurityName": "INDUS-E-SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE203B01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531942",
                "SecurityId": "MILLENCY",
                "SecurityName": "MILLENNIUM CYBERTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE525D01038",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531943",
                "SecurityId": "CSJTEC",
                "SecurityName": "CSJ TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE734C01012",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531944",
                "SecurityId": "SERVOTEACH",
                "SecurityName": "SERVOTEACH INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE185D01015",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531945",
                "SecurityId": "SUNITEE",
                "SecurityName": "SUNITEE CHEMICALS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE714C01022",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531946",
                "SecurityId": "CHADPAP",
                "SecurityName": "CHADHA PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE669W01018",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531947",
                "SecurityId": "KGNAGRO",
                "SecurityName": "KGN AGRO INTERNATIONALS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531948",
                "SecurityId": "SOASMUS",
                "SecurityName": "SOUTH ASIAN MUSHROOMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531949",
                "SecurityId": "NEHAEXP",
                "SecurityName": "NEHA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531950",
                "SecurityId": "VERTEX",
                "SecurityName": "VERTEX SECURITIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE316D01024",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531951",
                "SecurityId": "PROTSEC",
                "SecurityName": "PROMINENT SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531952",
                "SecurityId": "RIBATEX",
                "SecurityName": "RIBA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE811H01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531953",
                "SecurityId": "GCCL",
                "SecurityName": "GCCL CONSTRUCTION & REALITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE775C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531954",
                "SecurityId": "NIRMANC",
                "SecurityName": "NIRMAN CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE162E01012",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531955",
                "SecurityId": "PROLINSO",
                "SecurityName": "PROLINE SOFTWARE & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE303B01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531956",
                "SecurityId": "INBKITA",
                "SecurityName": "IND TAX SHIELD PLAN A",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531957",
                "SecurityId": "INBKITB",
                "SecurityName": "IND TAX SHIELD PLAN B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531958",
                "SecurityId": "EIDERTELE",
                "SecurityName": "EIDER TELECOM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE210B01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531959",
                "SecurityId": "NEWINFRA",
                "SecurityName": "NEWTIME INFRASTRUCTURE LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE997D01021",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531960",
                "SecurityId": "KASHYAP",
                "SecurityName": "KASHYAP TELE-MEDICINES LTD",
                "FaceValue": "1",
                "ISINNo": "INE108B01029",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531961",
                "SecurityId": "HERUKAG",
                "SecurityName": "HERUK AGRO FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531962",
                "SecurityId": "SHREMETAL",
                "SecurityName": "SHREE METALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE914B01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531963",
                "SecurityId": "UNICRSE",
                "SecurityName": "UNIVERSAL CREDIT & SECURITIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE607F01039",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531964",
                "SecurityId": "ENJNATF",
                "SecurityName": "ENJAYES NATURAL FLAVOURS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531965",
                "SecurityId": "PONAMRS",
                "SecurityName": "POONAM RASAYAN LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531966",
                "SecurityId": "BNKMADM",
                "SecurityName": "BANK OF MADURA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE456A01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531967",
                "SecurityId": "KESGRIN",
                "SecurityName": "KESAR GREENFIELD INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531968",
                "SecurityId": "IITLPROJ",
                "SecurityName": "IITL PROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE786E01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531969",
                "SecurityId": "NARJEWL",
                "SecurityName": "NARAIN JEWELS INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531970",
                "SecurityId": "VRUNDSE",
                "SecurityName": "VRUNDAVAN SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531971",
                "SecurityId": "HARIGOV",
                "SecurityName": "HARI GOVIND INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE167F01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531972",
                "SecurityId": "TRIDETOOL",
                "SecurityName": "TRIDENT TOOLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE179D01018",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531973",
                "SecurityId": "MORINOV",
                "SecurityName": "MORINDA OVERSEAS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531974",
                "SecurityId": "INDOKOR",
                "SecurityName": "INDO KOREA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531975",
                "SecurityId": "ITLFNLS",
                "SecurityName": "ITL FINLEASE & SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531976",
                "SecurityId": "KEDIN",
                "SecurityName": "KEDIA INFOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE245D01025",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531977",
                "SecurityId": "CHLOGIST",
                "SecurityName": "CHARTERED LOGISTICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE558F01026",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531978",
                "SecurityId": "AMBIKCO",
                "SecurityName": "AMBIKA COTTON MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE540G01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531979",
                "SecurityId": "HINDALUMI",
                "SecurityName": "HIND ALUMINIUM INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE227B01019",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531980",
                "SecurityId": "SENINFO",
                "SecurityName": "SENTHIL INFOTEK LTD",
                "FaceValue": "10",
                "ISINNo": "INE564B01015",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531981",
                "SecurityId": "VISHPAP",
                "SecurityName": "VISHAL PAPERTECH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531982",
                "SecurityId": "SPECFOOD",
                "SecurityName": "SPECTRUM FOODS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE547C01018",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531983",
                "SecurityId": "NUMUP",
                "SecurityName": "NUMERO UNO PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE411A01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531984",
                "SecurityId": "GUJFISC",
                "SecurityName": "GUJARAT FISCON LTD.",
                "FaceValue": "10",
                "ISINNo": "INE554D01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531985",
                "SecurityId": "GAYLORD",
                "SecurityName": "GAYLORD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531986",
                "SecurityId": "ALAMFIN",
                "SecurityName": "AL-AMANAT FINANCE & CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531987",
                "SecurityId": "CLCGLOBL",
                "SecurityName": "CLC GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE072F01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531988",
                "SecurityId": "JANTE",
                "SecurityName": "JANICE TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE090E01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531989",
                "SecurityId": "DECPO",
                "SecurityName": "DECCAN POLYPACKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE132E01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531991",
                "SecurityId": "AMRAAGRI",
                "SecurityName": "AMRAWORLD AGRICO LTD.",
                "FaceValue": "1",
                "ISINNo": "INE735C01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531992",
                "SecurityId": "GLDTORE",
                "SecurityName": "GOLDEN TOURIST RESORTS AND DEVELOPER LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531993",
                "SecurityId": "SCRUCLT",
                "SecurityName": "SCRUPLES CLOTHING LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531994",
                "SecurityId": "AUTOPINS",
                "SecurityName": "AUTO PINS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE706C01028",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531995",
                "SecurityId": "SHIAE",
                "SecurityName": "SHIVAM APPERALS EXPORT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE687D01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531996",
                "SecurityId": "ODYCORP",
                "SecurityName": "ODYSSEY CORPORATION LTD.",
                "FaceValue": "5",
                "ISINNo": "INE839E01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531997",
                "SecurityId": "GOVAI",
                "SecurityName": "GOOD VALUE IRRIGATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE164D01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531998",
                "SecurityId": "IFSLLTD",
                "SecurityName": "IFSL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE414F01022",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "531999",
                "SecurityId": "ANANDRU",
                "SecurityName": "ANAN DRUGS & CHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532000",
                "SecurityId": "BROTFLR",
                "SecurityName": "BROTHER FLORATECH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532001",
                "SecurityId": "INDCTST",
                "SecurityName": "INDUCTO STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE146H01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532003",
                "SecurityId": "DINIRST",
                "SecurityName": "DINA IRON & STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE649G01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532005",
                "SecurityId": "SAMINDUS",
                "SecurityName": "SAM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE653D01012",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532006",
                "SecurityId": "KCSFINA",
                "SecurityName": "KCS FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532007",
                "SecurityId": "SHVFL",
                "SecurityName": "SHREEVATSAA FINANCE & LEASING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE981C01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532008",
                "SecurityId": "JVGDEST",
                "SecurityName": "JVG DEPARTMENTAL STORES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532009",
                "SecurityId": "GOWRIPL",
                "SecurityName": "GOWRI POLYMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532010",
                "SecurityId": "NETVIS",
                "SecurityName": "NETVISION WEB TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE286D01011",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532011",
                "SecurityId": "POOJAENT",
                "SecurityName": "POOJA ENTERTAINMENT AND FILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE147C01017",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532013",
                "SecurityId": "SHIVGAR",
                "SecurityName": "SHIVGARH RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE780A01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532014",
                "SecurityId": "NAVALTC",
                "SecurityName": "NAVAL TECHNOPLAST INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532015",
                "SecurityId": "GRAVITY",
                "SecurityName": "GRAVITY (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE995A01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532016",
                "SecurityId": "WELLESLEY",
                "SecurityName": "WELLESLEY CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE176O01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532017",
                "SecurityId": "JKCORLT",
                "SecurityName": "JKCORLT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532018",
                "SecurityId": "FAIRDTR",
                "SecurityName": "FAIRDEAL TRADE LINK LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532019",
                "SecurityId": "LCCINFOTEC",
                "SecurityName": "LCC INFOTECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE938A01021",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532020",
                "SecurityId": "VINSFRJ",
                "SecurityName": "VINSARI FRUITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532021",
                "SecurityId": "SENBO",
                "SecurityName": "SENBO INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE792C01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532022",
                "SecurityId": "FILATFASH",
                "SecurityName": "FILATEX FASHIONS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE185E01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532023",
                "SecurityId": "SANCOKE",
                "SecurityName": "SANKARDEV COKE PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532024",
                "SecurityId": "RBGUPTA",
                "SecurityName": "R.B.GUPTA FINANCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE056J01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532025",
                "SecurityId": "SOWBHAGYA",
                "SecurityName": "SOWBHAGYA MEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE826D01014",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532026",
                "SecurityId": "THEWEST",
                "SecurityName": "WESTERN INDIA COTTONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE078F01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532027",
                "SecurityId": "BONANZAB",
                "SecurityName": "BONANZA BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE050C01013",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532028",
                "SecurityId": "SURFUNC",
                "SecurityName": "SURYA FUN CITY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122F01013",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532029",
                "SecurityId": "SINDHUTRAD",
                "SecurityName": "SINDHU TRADE LINKS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE325D01017",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532030",
                "SecurityId": "MCSLMTD",
                "SecurityName": "MCSLMTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532031",
                "SecurityId": "SARCHEM",
                "SecurityName": "SARANG CHEMICALS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE003E01026",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532032",
                "SecurityId": "BASILCH",
                "SecurityName": "BASIL CHEMICALS & ADDITIVES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532033",
                "SecurityId": "JAINSTUDIO",
                "SecurityName": "JAIN STUDIOS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE486B01011",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532034",
                "SecurityId": "SAFALHBS",
                "SecurityName": "SAFAL HERBS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE880M01020",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532035",
                "SecurityId": "UNISTRMU",
                "SecurityName": "UNISTAR MULTIMEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE808C01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532036",
                "SecurityId": "TOPMEDIA",
                "SecurityName": "TOP MEDIA ENTERTAINMENT LTD.",
                "FaceValue": "1",
                "ISINNo": "INE049C01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532037",
                "SecurityId": "GIRIRAJ",
                "SecurityName": "GIRIRAJ PRINT PLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE855D01013",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532038",
                "SecurityId": "EMMSONS",
                "SecurityName": "EMMSONS INTERNATIONAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE073C01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532039",
                "SecurityId": "ZENOTECH",
                "SecurityName": "ZENOTECH LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE486F01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532040",
                "SecurityId": "RASAGEX",
                "SecurityName": "RASDHARA AGRO EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532041",
                "SecurityId": "HINDBIO",
                "SecurityName": "HINDUSTAN BIO SCIENCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE597C01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532042",
                "SecurityId": "FRONTCORP",
                "SecurityName": "FRONTLINE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE092D01013",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532043",
                "SecurityId": "SKIPLAS",
                "SecurityName": "SKIP PLASTICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532044",
                "SecurityId": "PASUFAB",
                "SecurityName": "PASUPATI TECHNOFAB LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532045",
                "SecurityId": "NEXXOFT",
                "SecurityName": "NEXXOFT INFOTEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE249H01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532046",
                "SecurityId": "APTYARNS",
                "SecurityName": "A.P.T.YARNS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532047",
                "SecurityId": "ASIANFILMS",
                "SecurityName": "ASIAN FILMS PRODUCTION & DISTRIBUTION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE581B01035",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532048",
                "SecurityId": "MALVICAE",
                "SecurityName": "MALVICA ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE008D01019",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532049",
                "SecurityId": "ALAMCHE",
                "SecurityName": "ALAMCHE",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532050",
                "SecurityId": "PRIYFAB",
                "SecurityName": "PRIYADARSHINI FABS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532051",
                "SecurityId": "SWELECTES",
                "SecurityName": "SWELECT ENERGY SYSTEMS LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE409B01013",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532052",
                "SecurityId": "UMAFABR",
                "SecurityName": "UMA FABRICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532053",
                "SecurityId": "WALLFORT",
                "SecurityName": "WALLFORT FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE121B01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532054",
                "SecurityId": "KDDL",
                "SecurityName": "KDDL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE291D01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532055",
                "SecurityId": "RASHELAG",
                "SecurityName": "RASHEL AGROTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE029D01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532056",
                "SecurityId": "ADIEXRE",
                "SecurityName": "ADINATH EXIM RESOURCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE398H01015",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532057",
                "SecurityId": "ABHICAP",
                "SecurityName": "ABHINAV CAPITAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE516F01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532058",
                "SecurityId": "GUJARTH",
                "SecurityName": "GUJARAT ARTH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE852B01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532060",
                "SecurityId": "ELQPO",
                "SecurityName": "ELQUE POLYESTERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE085E01015",
                "Industry": "Fibres & Plastics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532061",
                "SecurityId": "GRPWRPR",
                "SecurityName": "GREENVIEW POWER PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532063",
                "SecurityId": "GALBATR",
                "SecurityName": "GALVANI BATTERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532064",
                "SecurityId": "AUTOMO STAM",
                "SecurityName": "AUTOMO STAM",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532065",
                "SecurityId": "HBLIBRL",
                "SecurityName": "HBLIBRL",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532067",
                "SecurityId": "KILPEST",
                "SecurityName": "KILPEST INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE994E01018",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532068",
                "SecurityId": "ARAMUSK",
                "SecurityName": "ARAMUSK INFRASTRUCTURE INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE126F01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532069",
                "SecurityId": "PASUPATIF",
                "SecurityName": "PASUPATI FABRICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE095D01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532070",
                "SecurityId": "SUPRBPA",
                "SecurityName": "SUPERB PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE311N01016",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532072",
                "SecurityId": "INTERDIGI",
                "SecurityName": "INTERWORLD DIGITAL LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE177D01020",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532073",
                "SecurityId": "MARIN",
                "SecurityName": "MARVEL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE440D01014",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532074",
                "SecurityId": "JBCHPHM",
                "SecurityName": "JBCHPHM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532075",
                "SecurityId": "WOOLWAY",
                "SecurityName": "WOOLWAYS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532076",
                "SecurityId": "BEQSTPADM",
                "SecurityName": "BOINANZA EQT.LKD.SVG.ON TAX SCH.93-PLAN A",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532077",
                "SecurityId": "BOIEQSTDM",
                "SecurityName": "BOINANZA EQT.LKD.SVG.ON TAX SCH.93-PLAN B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532078",
                "SecurityId": "MONIND",
                "SecurityName": "MONIND LTD",
                "FaceValue": "10",
                "ISINNo": "INE407E01029",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532079",
                "SecurityId": "MUNISFG",
                "SecurityName": "MUNIS FORGE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532080",
                "SecurityId": "GTCLM",
                "SecurityName": "GTCL MOBILE-COM TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE037D01018",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532081",
                "SecurityId": "KSERASERA",
                "SecurityName": "KSS LIMITED-$",
                "FaceValue": "1",
                "ISINNo": "INE216D01026",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532082",
                "SecurityId": "ZODICEM",
                "SecurityName": "ZODIAC CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532083",
                "SecurityId": "SHKALYN",
                "SecurityName": "SHRI KALYAN HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE079N01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532084",
                "SecurityId": "FASTRAENT",
                "SecurityName": "FAST TRACK ENTERTAINMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE074D01037",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532085",
                "SecurityId": "MKTCRET",
                "SecurityName": "MKTCRET",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532086",
                "SecurityId": "OCENINFR",
                "SecurityName": "OCEAN INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE789C01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532087",
                "SecurityId": "ESTLAGR",
                "SecurityName": "ESTEL AGROTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532088",
                "SecurityId": "VIRAJFG",
                "SecurityName": "VIRAJ FORGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532089",
                "SecurityId": "MOH",
                "SecurityName": "MOH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE179B01020",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532090",
                "SecurityId": "VANDANA",
                "SecurityName": "VANDANA KNITWEAR LTD.",
                "FaceValue": "1",
                "ISINNo": "INE219M01021",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532091",
                "SecurityId": "ORBIT",
                "SecurityName": "ORBIT MULTIMEDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE471C01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532092",
                "SecurityId": "SAGARPROD",
                "SecurityName": "SAGAR PRODUCTIONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE807D01030",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532093",
                "SecurityId": "VENKPHR",
                "SecurityName": "VENKAT PHARMA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE346F01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532094",
                "SecurityId": "GFUNPRK",
                "SecurityName": "GUJARAT FUN&#39;N WATER PARK LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532095",
                "SecurityId": "HINDLVR",
                "SecurityName": "HINDLVR",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532096",
                "SecurityId": "KOTHARIP",
                "SecurityName": "KOTHARI PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE720A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532097",
                "SecurityId": "MUKANDENGG",
                "SecurityName": "MUKAND ENGINEERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE022B01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532099",
                "SecurityId": "DATABASE",
                "SecurityName": "DATABASE FINANCE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE847A01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532100",
                "SecurityId": "INDOCITY",
                "SecurityName": "INDO-CITY INFOTECH LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE456B01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532101",
                "SecurityId": "MINISPP",
                "SecurityName": "MINISPP",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532102",
                "SecurityId": "SBECSUG",
                "SecurityName": "SBEC SUGAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE948G01019",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532103",
                "SecurityId": "AUROMED",
                "SecurityName": "AUROMED LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532104",
                "SecurityId": "CMSINFO",
                "SecurityName": "CMS INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE915A01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532105",
                "SecurityId": "MEGFI",
                "SecurityName": "MEGA FIN (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE524D01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532106",
                "SecurityId": "REIAGROLTD",
                "SecurityName": "REI AGRO LTD.",
                "FaceValue": "1",
                "ISINNo": "INE385B01031",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532108",
                "SecurityId": "MEDTEDM",
                "SecurityName": "MEDICORP TECHNOLOGIES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE904A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532109",
                "SecurityId": "PANHLRS",
                "SecurityName": "PANCHWATI HOLIDAY RESORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532110",
                "SecurityId": "MANSUINDM",
                "SecurityName": "MANSUKH INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532111",
                "SecurityId": "ARCHOLC",
                "SecurityName": "ARCHIT HOLDING & CREDITS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532112",
                "SecurityId": "VRWOODA",
                "SecurityName": "VRWOODA",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532113",
                "SecurityId": "BRIJLEAS",
                "SecurityName": "BRIJLAXMI LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE957E01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532114",
                "SecurityId": "AREALTY",
                "SecurityName": "ALCHEMIST REALTY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE646D01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532115",
                "SecurityId": "STOTZBL",
                "SecurityName": "STOTZ-BLACKSMITHS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE485F01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532116",
                "SecurityId": "XOINFO",
                "SecurityName": "XO INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE490B01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532117",
                "SecurityId": "PLATINUM",
                "SecurityName": "PLATINUM CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE219B01024",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532118",
                "SecurityId": "SAINFO",
                "SecurityName": "SAI INFOSERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE589B01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532119",
                "SecurityId": "MUSKXMK",
                "SecurityName": "MUSKXMK",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532120",
                "SecurityId": "ORICONT",
                "SecurityName": "ORICONT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532121",
                "SecurityId": "DENABANK",
                "SecurityName": "DENA BANK",
                "FaceValue": "10",
                "ISINNo": "INE077A01010",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532122",
                "SecurityId": "SIDPHAR",
                "SecurityName": "SIDDHARTHA PHARMACHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532123",
                "SecurityId": "BSELINFRA",
                "SecurityName": "BSEL INFRASTRUCTURE REALTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE395A01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532124",
                "SecurityId": "RELIABVEN",
                "SecurityName": "RELIABLE VENTURES INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE419H01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532125",
                "SecurityId": "GEMINAGDM",
                "SecurityName": "GEMINI AGRITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532126",
                "SecurityId": "RASPHOT",
                "SecurityName": "RASPHOT",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532127",
                "SecurityId": "MOBILTEL",
                "SecurityName": "MOBILE TELECOMMUNICATIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE770B01026",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532128",
                "SecurityId": "MOBTLCM",
                "SecurityName": "MOBTLCM",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532129",
                "SecurityId": "HEXAWARE",
                "SecurityName": "HEXAWARE TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE093A01033",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532131",
                "SecurityId": "TRIUMPIN",
                "SecurityName": "TRIUMPH INTERNATIONAL FINANCE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE382B01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532133",
                "SecurityId": "IFGLREFRAC",
                "SecurityName": "IFGL REFRACTORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE023B01012",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532134",
                "SecurityId": "BANKBARODA",
                "SecurityName": "BANK OF BARODA",
                "FaceValue": "2",
                "ISINNo": "INE028A01039",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532135",
                "SecurityId": "BKBAROD",
                "SecurityName": "BKBAROD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532136",
                "SecurityId": "CLMUSRM",
                "SecurityName": "CLASSIC MUSHROOMS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532137",
                "SecurityId": "CPLARTS-B",
                "SecurityName": "CPLARTS-B",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532138",
                "SecurityId": "SMCREDT",
                "SecurityName": "SMC CREDITS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE440E01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532139",
                "SecurityId": "GTEIT",
                "SecurityName": "G-TECH INFO-TRAINING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE634D01038",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532140",
                "SecurityId": "MOHITE",
                "SecurityName": "MOHITE INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE154B01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532141",
                "SecurityId": "ANDHRACEMT",
                "SecurityName": "ANDHRA CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE666E01012",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532143",
                "SecurityId": "SKMEGGPROD",
                "SecurityName": "SKM EGG PRODUCTS EXPORT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE411D01015",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532144",
                "SecurityId": "WELCORP",
                "SecurityName": "WELSPUN CORP LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE191B01025",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532145",
                "SecurityId": "HOTLSILV",
                "SecurityName": "H.S.INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE731B01010",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532146",
                "SecurityId": "CANPENF",
                "SecurityName": "CANPEP 94",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532147",
                "SecurityId": "CILSNOR",
                "SecurityName": "CILSON ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532148",
                "SecurityId": "KOLARBIO",
                "SecurityName": "KOLAR BIOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE365A01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532149",
                "SecurityId": "BANKINDIA",
                "SecurityName": "BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE084A01016",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532150",
                "SecurityId": "INDRAMEDCO",
                "SecurityName": "INDRAPRASTHA MEDICAL CORP.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE681B01017",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532151",
                "SecurityId": "ZTAUGEN",
                "SecurityName": "ZTAUGEN",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532152",
                "SecurityId": "ZTAUNEW",
                "SecurityName": "ZTAUNEW",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532153",
                "SecurityId": "RAINCALCIN",
                "SecurityName": "RAIN CALCINING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE329A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532154",
                "SecurityId": "SUNSHINE",
                "SecurityName": "SUN AND SHINE WORLDWIDE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE560F01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532155",
                "SecurityId": "GAIL",
                "SecurityName": "GAIL (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE129A01019",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532156",
                "SecurityId": "VAIBHAVGBL",
                "SecurityName": "VAIBHAV GLOBAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE884A01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532157",
                "SecurityId": "DOWELWE",
                "SecurityName": "DOWELL&#39;S ELEKTRO WERKE LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532158",
                "SecurityId": "SBIMTXP",
                "SecurityName": "SBI MAGNUM TAX PROFIT SCHEME 1994",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532159",
                "SecurityId": "TRESCON",
                "SecurityName": "TRESCON LTD",
                "FaceValue": "10",
                "ISINNo": "INE088D01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532160",
                "SecurityId": "GUJSTATFIN",
                "SecurityName": "GUJARAT STATE FINANCIAL CORPORATION",
                "FaceValue": "10",
                "ISINNo": "INE944A01011",
                "Industry": "Financial Institutions",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532161",
                "SecurityId": "BAFEG",
                "SecurityName": "BAFFIN ENGINEERING PROJECTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE786D01036",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532162",
                "SecurityId": "JKPAPER",
                "SecurityName": "JK PAPER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE789E01012",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532163",
                "SecurityId": "SAREGAMA",
                "SecurityName": "SAREGAMA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE979A01017",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532164",
                "SecurityId": "MINOLTAF",
                "SecurityName": "MINOLTA FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE514C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532165",
                "SecurityId": "RANSISOF",
                "SecurityName": "RANSI SOFTWARE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE247B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532166",
                "SecurityId": "ALKASEC",
                "SecurityName": "ALKA SECURITIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE921C01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532167",
                "SecurityId": "OMKARPH",
                "SecurityName": "OMKAR PHARMACHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE501C01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532168",
                "SecurityId": "MADRASFERT",
                "SecurityName": "MADRAS FERTILIZERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE414A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532170",
                "SecurityId": "SUJTRFI",
                "SecurityName": "SUJAN TRADING & FINANCE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532171",
                "SecurityId": "DATT",
                "SecurityName": "DATT MEDIPRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE480E01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532172",
                "SecurityId": "ADROITINFO",
                "SecurityName": "ADROIT INFOTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE737B01033",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532173",
                "SecurityId": "CYBERTECH",
                "SecurityName": "CYBERTECH SYSTEMS AND SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE214A01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532174",
                "SecurityId": "ICICIBANK",
                "SecurityName": "ICICI BANK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE090A01021",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532175",
                "SecurityId": "CYIENT",
                "SecurityName": "CYIENT LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE136B01020",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532176",
                "SecurityId": "PAVCI",
                "SecurityName": "PANASONIC AVC NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE071B01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532177",
                "SecurityId": "ZIGMASOF",
                "SecurityName": "ZIGMA SOFTWARE LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE746C01032",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532178",
                "SecurityId": "ENGINERSIN",
                "SecurityName": "ENGINEERS INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE510A01028",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532179",
                "SecurityId": "CORPBANK",
                "SecurityName": "CORPORATION BANK",
                "FaceValue": "2",
                "ISINNo": "INE112A01023",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532180",
                "SecurityId": "DHANBANK",
                "SecurityName": "DHANLAXMI BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE680A01011",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532181",
                "SecurityId": "GMDCLTD",
                "SecurityName": "GUJARAT MINERAL DEVELOPMENT CORPORATION LTD.",
                "FaceValue": "2",
                "ISINNo": "INE131A01031",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532182",
                "SecurityId": "BLINTEC",
                "SecurityName": "BLUE INFORMATION TECHNOLOGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE084F01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532183",
                "SecurityId": "GAYATRI",
                "SecurityName": "GAYATRI SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE622E01023",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532186",
                "SecurityId": "CALDYNA",
                "SecurityName": "CALDYN AIRCON LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532187",
                "SecurityId": "INDUSINDBK",
                "SecurityName": "INDUSIND BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE095A01012",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532189",
                "SecurityId": "ITDC",
                "SecurityName": "INDIA TOURISM DEVELOPMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE353K01014",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532190",
                "SecurityId": "ARYANPE",
                "SecurityName": "ARYAN PESTICIDES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532191",
                "SecurityId": "SBT",
                "SecurityName": "STATE BANK OF TRAVANCORE",
                "FaceValue": "10",
                "ISINNo": "INE654A01024",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532192",
                "SecurityId": "TINNAFN",
                "SecurityName": "TINNA FINEX LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532193",
                "SecurityId": "BOBELSS",
                "SecurityName": "BOB ELSS 95",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532195",
                "SecurityId": "CNBKPEP",
                "SecurityName": "CANPEP 95",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532196",
                "SecurityId": "NCLSECC",
                "SecurityName": "NCL SECCOLAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE527D01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532197",
                "SecurityId": "BHAGCOT",
                "SecurityName": "BHAGWATI COTTONS LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532199",
                "SecurityId": "PSI",
                "SecurityName": "PSI DATA SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE299A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532200",
                "SecurityId": "MYSOREBANK",
                "SecurityName": "STATE BANK OF MYSORE",
                "FaceValue": "10",
                "ISINNo": "INE651A01020",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532201",
                "SecurityId": "AMBCEMES",
                "SecurityName": "AMBUJA CEMENT EASTERN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE073B01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532202",
                "SecurityId": "SBMAGNU",
                "SecurityName": "SBI MAGNUM EQUITY LINKED SCHEME 1995",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532207",
                "SecurityId": "CYAAGDM",
                "SecurityName": "CYANAMID AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE142B01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532208",
                "SecurityId": "MANPAIN",
                "SecurityName": "MANSAROVER PAPER & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532209",
                "SecurityId": "J&KBANK",
                "SecurityName": "JAMMU & KASHMIR BANK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE168A01041",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532210",
                "SecurityId": "CUB",
                "SecurityName": "CITY UNION BANK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE491A01021",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532211",
                "SecurityId": "SUNEARTH",
                "SecurityName": "SUN EARTH CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE744A01015",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532212",
                "SecurityId": "ARCHIES",
                "SecurityName": "ARCHIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE731A01020",
                "Industry": "Gift Articles-Toys & Cards ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532213",
                "SecurityId": "INOXL",
                "SecurityName": "INOX LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE608E01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532214",
                "SecurityId": "VISUALSOFT",
                "SecurityName": "VISUALSOFT TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE755A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532215",
                "SecurityId": "AXISBANK",
                "SecurityName": "AXIS BANK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE238A01034",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532216",
                "SecurityId": "HBSTOCK",
                "SecurityName": "HB STOCKHOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE550B01022",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532217",
                "SecurityId": "SIELFNS",
                "SecurityName": "SIEL FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE027F01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532218",
                "SecurityId": "SOUTHBANK",
                "SecurityName": "SOUTH INDIAN BANK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE683A01023",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532219",
                "SecurityId": "ENERGYDEV",
                "SecurityName": "ENERGY DEVELOPMENT COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE306C01019",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532220",
                "SecurityId": "UTIMVUPDM",
                "SecurityName": "MASTER VALUE UNIT PLAN 1998",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532221",
                "SecurityId": "SONATSOFTW",
                "SecurityName": "SONATA SOFTWARE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE269A01021",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532230",
                "SecurityId": "BENGALT",
                "SecurityName": "BENGAL TEA & FABRICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE665D01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532234",
                "SecurityId": "NATIONALUM",
                "SecurityName": "NATIONAL ALUMINIUM CO.LTD.",
                "FaceValue": "5",
                "ISINNo": "INE139A01034",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532235",
                "SecurityId": "IDBIBANK",
                "SecurityName": "IDBI BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE307A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532237",
                "SecurityId": "RADKHDM",
                "SecurityName": "RADICO KHAITAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE381B01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532240",
                "SecurityId": "INDNIPPON",
                "SecurityName": "INDIA NIPPON ELECTRICALS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE092B01025",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532249",
                "SecurityId": "SQL",
                "SecurityName": "SQL STAR INTERNATIONAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE399A01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532252",
                "SecurityId": "TIMEBDM",
                "SecurityName": "TIMEBDM",
                "FaceValue": "10",
                "ISINNo": "INE684A01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532254",
                "SecurityId": "POLARIS",
                "SecurityName": "POLARIS CONSULTING & SERVICES LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE763A01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532256",
                "SecurityId": "NSIL",
                "SecurityName": "NALWA SONS INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE023A01030",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532258",
                "SecurityId": "TRANSRIN",
                "SecurityName": "TRANSTREAM INDIA.COM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE981A01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532259",
                "SecurityId": "APARINDS",
                "SecurityName": "APAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE372A01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532262",
                "SecurityId": "TCIIND",
                "SecurityName": "TCI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE920B01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532264",
                "SecurityId": "INDAGERES",
                "SecurityName": "INDAGE RESTAURANTS AND LEISURE LTD.",
                "FaceValue": "3",
                "ISINNo": "INE525H01013",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532266",
                "SecurityId": "FSS",
                "SecurityName": "FLEXTRONICS SOFTWARE SYSTEMS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE801A01021",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532268",
                "SecurityId": "ACCELYA",
                "SecurityName": "ACCELYA SOLUTIONS INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE793A01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532271",
                "SecurityId": "CYBERMAT",
                "SecurityName": "CYBERMATE INFOTEK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE215B01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532273",
                "SecurityId": "CBOP",
                "SecurityName": "CENTURION BANK OF PUNJAB LTD.",
                "FaceValue": "1",
                "ISINNo": "INE484A01026",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532274",
                "SecurityId": "QUINTEGRA",
                "SecurityName": "QUINTEGRA SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE033B01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532275",
                "SecurityId": "LANDMARC",
                "SecurityName": "LANDMARC LEISURE CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE394C01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532276",
                "SecurityId": "SYNDIBANK",
                "SecurityName": "SYNDICATE BANK",
                "FaceValue": "10",
                "ISINNo": "INE667A01018",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532279",
                "SecurityId": "RSLMDDM",
                "SecurityName": "RSL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE622C01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532281",
                "SecurityId": "HCLTECH",
                "SecurityName": "HCL TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE860A01027",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532282",
                "SecurityId": "CASTEXTECH",
                "SecurityName": "CASTEX TECHNOLOGIES LTD-$",
                "FaceValue": "2",
                "ISINNo": "INE068D01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532283",
                "SecurityId": "KAASHYAP",
                "SecurityName": "KAASHYAP TECHNOLOGIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE397B01028",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532284",
                "SecurityId": "TCFCFINQ",
                "SecurityName": "TCFC FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE389D01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532285",
                "SecurityId": "GEOJITFSL",
                "SecurityName": "GEOJIT FINANCIAL SERVICES LTD",
                "FaceValue": "1",
                "ISINNo": "INE007B01023",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532286",
                "SecurityId": "JINDALSTEL",
                "SecurityName": "JINDAL STEEL & POWER LTD.",
                "FaceValue": "1",
                "ISINNo": "INE749A01030",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532287",
                "SecurityId": "ENTEGRA",
                "SecurityName": "ENTEGRA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE826A01028",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532290",
                "SecurityId": "BLBLIMITED",
                "SecurityName": "BLB LTD.",
                "FaceValue": "1",
                "ISINNo": "INE791A01024",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532291",
                "SecurityId": "KOPDRUGS",
                "SecurityName": "KDL BIOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE746A01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532293",
                "SecurityId": "SOFTTECHGR",
                "SecurityName": "SOFTWARE TECHNOLOGY GROUP INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE863A01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532296",
                "SecurityId": "GLENMARK",
                "SecurityName": "GLENMARK PHARMACEUTICALS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE935A01035",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532298",
                "SecurityId": "ZENITHINFO",
                "SecurityName": "ZENITH INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE899A01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532299",
                "SecurityId": "TV18",
                "SecurityName": "TELEVISION EIGHTEEN INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE889A01026",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532300",
                "SecurityId": "WOCKPHARMA",
                "SecurityName": "WOCKHARDT LTD.",
                "FaceValue": "5",
                "ISINNo": "INE049B01025",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532301",
                "SecurityId": "TATACOFFEE",
                "SecurityName": "TATA COFFEE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE493A01027",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532302",
                "SecurityId": "SIBARSOF",
                "SecurityName": "SIBAR SOFTWARE SERVICES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE940A01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532303",
                "SecurityId": "INTEGHIT",
                "SecurityName": "INTEGRATED HITECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE934A01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532304",
                "SecurityId": "KJMCCORP",
                "SecurityName": "KJMC CORPORATE ADVISORS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE602C01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532305",
                "SecurityId": "INDSWFTLAB",
                "SecurityName": "IND-SWIFT LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE915B01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532306",
                "SecurityId": "COMPUDYNE",
                "SecurityName": "COMPUDYNE WINFOSYSTEMS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE531B01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532307",
                "SecurityId": "MELSTAR",
                "SecurityName": "MELSTAR INFORMATION TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE817A01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532308",
                "SecurityId": "EONOUR",
                "SecurityName": "EONOUR TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE352B01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532309",
                "SecurityId": "GEPIL",
                "SecurityName": "GE POWER INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE878A01011",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532310",
                "SecurityId": "SHREERAMA",
                "SecurityName": "SHREE RAMA MULTI-TECH LTD.",
                "FaceValue": "5",
                "ISINNo": "INE879A01019",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532311",
                "SecurityId": "TUTIS",
                "SecurityName": "TUTIS TECHNOLOGIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE953A01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532312",
                "SecurityId": "GEOMETRIC",
                "SecurityName": "GEOMETRIC LTD.",
                "FaceValue": "2",
                "ISINNo": "INE797A01021",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532313",
                "SecurityId": "MAHLIFE",
                "SecurityName": "MAHINDRA LIFESPACE DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE813A01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532314",
                "SecurityId": "PENTAGLO",
                "SecurityName": "PENTAGON GLOBAL SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE968A01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532315",
                "SecurityId": "TELESYS",
                "SecurityName": "TELESYS SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE042B01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532316",
                "SecurityId": "SKUMAR",
                "SecurityName": "S KUMARS.COM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE827A01018",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532317",
                "SecurityId": "BOBEL97",
                "SecurityName": "BOB ELSS 97",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532318",
                "SecurityId": "GEMINI",
                "SecurityName": "GEMINI COMMUNICATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE878C01033",
                "Industry": "IT Networking Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532319",
                "SecurityId": "TVSFIN",
                "SecurityName": "TVS FINANCE AND SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE765A01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532320",
                "SecurityId": "VAARAD",
                "SecurityName": "VAARAD VENTURES LTD",
                "FaceValue": "1",
                "ISINNo": "INE418B01048",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532321",
                "SecurityId": "CADILAHC",
                "SecurityName": "CADILA HEALTHCARE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE010B01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532322",
                "SecurityId": "ELDERPHARM",
                "SecurityName": "ELDER PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE975A01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532323",
                "SecurityId": "SHIVACEM",
                "SecurityName": "SHIVA CEMENT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE555C01029",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532324",
                "SecurityId": "CINEVISTA",
                "SecurityName": "CINEVISTA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE039B01026",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532325",
                "SecurityId": "VITALCOMM",
                "SecurityName": "VITAL COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE377B01012",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532326",
                "SecurityId": "INTENTECH",
                "SecurityName": "INTENSE TECHNOLOGIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE781A01025",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532327",
                "SecurityId": "ALLSOFT",
                "SecurityName": "ALLSOFT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE347B01015",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532328",
                "SecurityId": "RANEBRAKE",
                "SecurityName": "RANE BRAKE LINING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE413A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532329",
                "SecurityId": "DANLAW",
                "SecurityName": "DANLAW TECHNOLOGIES INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE310B01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532330",
                "SecurityId": "BIOPAC",
                "SecurityName": "BIOPAC INDIA CORPORATION LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE998A01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532331",
                "SecurityId": "AJANTPHARM",
                "SecurityName": "AJANTA PHARMA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE031B01049",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532332",
                "SecurityId": "CURATECH",
                "SecurityName": "CURA TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE117B01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532333",
                "SecurityId": "HBPOR",
                "SecurityName": "HB PORTFOLIO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE638B01025",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532334",
                "SecurityId": "HBESD",
                "SecurityName": "HB ESTATE DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE640B01021",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532335",
                "SecurityId": "AMBICAAGAR",
                "SecurityName": "AMBICA AGARBATHIES & AROMA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE792B01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532336",
                "SecurityId": "BARONINF",
                "SecurityName": "BARON INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE228B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532337",
                "SecurityId": "IGATE",
                "SecurityName": "IGATE GLOBAL SOLUTIONS LTD.",
                "FaceValue": "4",
                "ISINNo": "INE177B01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532338",
                "SecurityId": "VALUEMART",
                "SecurityName": "VALUEMART INFO TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE996A01029",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532339",
                "SecurityId": "COMPUSOFT",
                "SecurityName": "COMPUCOM SOFTWARE LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE453B01029",
                "Industry": "IT Training Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532340",
                "SecurityId": "OMNIAX",
                "SecurityName": "OMNI AXS SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE369B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532341",
                "SecurityId": "IZMO",
                "SecurityName": "IZMO LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE848A01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532342",
                "SecurityId": "COMMEXTECH",
                "SecurityName": "COMMEX TECHNOLOGY LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE354B01029",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532343",
                "SecurityId": "TVSMOTOR",
                "SecurityName": "TVS MOTOR COMPANY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE494B01023",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532344",
                "SecurityId": "SOFTSOL",
                "SecurityName": "SOFTSOL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE002B01016",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532345",
                "SecurityId": "GATI",
                "SecurityName": "GATI LTD.",
                "FaceValue": "2",
                "ISINNo": "INE152B01027",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532346",
                "SecurityId": "BLUESTINFO",
                "SecurityName": "BLUE STAR INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE504B01011",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532347",
                "SecurityId": "HELIOSMATH",
                "SecurityName": "HELIOS & MATHESON INFORMATION TECHNOLOGY LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE674B01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532348",
                "SecurityId": "SUBEX",
                "SecurityName": "SUBEX LTD.",
                "FaceValue": "5",
                "ISINNo": "INE754A01055",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532349",
                "SecurityId": "TCI",
                "SecurityName": "TRANSPORT CORPORATION OF INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE688A01022",
                "Industry": "Surface Transportation",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532350",
                "SecurityId": "PADMALAYAT",
                "SecurityName": "PADMALAYA TELEFILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE243B01016",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532351",
                "SecurityId": "AKSHOPTFBR",
                "SecurityName": "AKSH OPTIFIBRE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE523B01011",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532352",
                "SecurityId": "KIRLMU",
                "SecurityName": "KIRLOSKAR MULTIMEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE175B01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532353",
                "SecurityId": "SIBARMED",
                "SecurityName": "SIBAR MEDIA & ENTERTAINMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE388B01019",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532354",
                "SecurityId": "VIRGOGLOB",
                "SecurityName": "VIRGO GLOBAL LTD",
                "FaceValue": "4",
                "ISINNo": "INE400B01020",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532355",
                "SecurityId": "PICTUREHS",
                "SecurityName": "PICTUREHOUSE MEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE448B01029",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532356",
                "SecurityId": "TRIVENI",
                "SecurityName": "TRIVENI ENGINEERING & INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE256C01024",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532357",
                "SecurityId": "MUKTAARTS",
                "SecurityName": "MUKTA ARTS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE374B01019",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532358",
                "SecurityId": "TELEDATAGL",
                "SecurityName": "TELEDATA INFORMATICS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE480B01022",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532359",
                "SecurityId": "HITKITGLO",
                "SecurityName": "HIT KIT GLOBAL SOLUTIONS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE309B01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532360",
                "SecurityId": "VINCARDS",
                "SecurityName": "VINTAGE CARDS & CREATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE810A01022",
                "Industry": "Gift Articles-Toys & Cards ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532361",
                "SecurityId": "SHAPRDM",
                "SecurityName": "SHAPRE GLOBAL INFO SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE129B01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532362",
                "SecurityId": "NAGPI",
                "SecurityName": "NAGPUR POWER & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE099E01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532363",
                "SecurityId": "CTIL",
                "SecurityName": "CTIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE394B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532364",
                "SecurityId": "CYBERSC",
                "SecurityName": "CYBERSCAPE MULTIMEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE519B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532365",
                "SecurityId": "DSSL",
                "SecurityName": "DYNACONS SYSTEMS & SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE417B01040",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532366",
                "SecurityId": "PNBGILTS",
                "SecurityName": "PNB GILTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE859A01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532367",
                "SecurityId": "KANIKAIN",
                "SecurityName": "KANIKA INFOTECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE173B01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532368",
                "SecurityId": "BCG",
                "SecurityName": "BRIGHTCOM GROUP LTD",
                "FaceValue": "2",
                "ISINNo": "INE425B01027",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532369",
                "SecurityId": "RAMCOIND",
                "SecurityName": "RAMCO INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE614A01028",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532370",
                "SecurityId": "RAMCOSYS",
                "SecurityName": "RAMCO SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE246B01019",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532371",
                "SecurityId": "TTML",
                "SecurityName": "TATA TELESERVICES (MAHARASHTRA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE517B01013",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532372",
                "SecurityId": "VIRINCHI",
                "SecurityName": "VIRINCHI LTD",
                "FaceValue": "10",
                "ISINNo": "INE539B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532373",
                "SecurityId": "WEPSOLN",
                "SecurityName": "WEP SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE434B01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532374",
                "SecurityId": "STLTECH",
                "SecurityName": "STERLITE TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE089C01029",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532375",
                "SecurityId": "TIPSINDLTD",
                "SecurityName": "TIPS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE716B01011",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532376",
                "SecurityId": "MRO-TEK",
                "SecurityName": "MRO-TEK REALTY LTD",
                "FaceValue": "5",
                "ISINNo": "INE398B01018",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532377",
                "SecurityId": "BATHINAT",
                "SecurityName": "BATHINA TECHNOLOGIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE453C01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532378",
                "SecurityId": "UNIVARTS",
                "SecurityName": "UNIVERSAL ARTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE464B01018",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532379",
                "SecurityId": "FIRSTOBJ",
                "SecurityName": "FIRSTOBJECT TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE683B01047",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532380",
                "SecurityId": "BABA",
                "SecurityName": "BABA ARTS LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE893A01036",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532381",
                "SecurityId": "INDUSNET",
                "SecurityName": "INDUS NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE006B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532382",
                "SecurityId": "BALAJITELE",
                "SecurityName": "BALAJI TELEFILMS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE794B01026",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532383",
                "SecurityId": "VISIONLTD",
                "SecurityName": "VISION ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE514B01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532384",
                "SecurityId": "TYCHE",
                "SecurityName": "TYCHE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE443B01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532385",
                "SecurityId": "AZTECSOFT",
                "SecurityName": "AZTECSOFT LTD.",
                "FaceValue": "3",
                "ISINNo": "INE651B01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532386",
                "SecurityId": "CALSOFT",
                "SecurityName": "CALIFORNIA SOFTWARE CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE526B01014",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532387",
                "SecurityId": "PNC",
                "SecurityName": "PRITISH NANDY COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE392B01011",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532388",
                "SecurityId": "IOB",
                "SecurityName": "INDIAN OVERSEAS BANK",
                "FaceValue": "10",
                "ISINNo": "INE565A01014",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532389",
                "SecurityId": "VALECHAENG",
                "SecurityName": "VALECHA ENGINEERING LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE624C01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532390",
                "SecurityId": "TAJGVK",
                "SecurityName": "TAJGVK HOTELS & RESORTS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE586B01026",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532391",
                "SecurityId": "OPTOCIRCUI",
                "SecurityName": "OPTO CIRCUITS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE808B01016",
                "Industry": "Medical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532392",
                "SecurityId": "CREATIVEYE",
                "SecurityName": "CREATIVE EYE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE230B01021",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532393",
                "SecurityId": "GESBBPH",
                "SecurityName": "GESBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532394",
                "SecurityId": "GEBBDM",
                "SecurityName": "GEBBDM",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532395",
                "SecurityId": "AXISCADES",
                "SecurityName": "AXISCADES TECHNOLOGIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE555B01013",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532396",
                "SecurityId": "VANTEL",
                "SecurityName": "VANTEL TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE078B01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532397",
                "SecurityId": "KONNDOR",
                "SecurityName": "KONNDOR INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE971A01014",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532398",
                "SecurityId": "UMESLTD",
                "SecurityName": "USHA MARTIN EDUCATION & SOLUTIONS LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE240C01028",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532399",
                "SecurityId": "RELMEDIA",
                "SecurityName": "RELIANCE MEDIAWORKS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE540B01015",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532400",
                "SecurityId": "BSOFT",
                "SecurityName": "BIRLASOFT LTD",
                "FaceValue": "2",
                "ISINNo": "INE836A01035",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532401",
                "SecurityId": "VIJAYABANK",
                "SecurityName": "VIJAYA BANK",
                "FaceValue": "10",
                "ISINNo": "INE705A01016",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532402",
                "SecurityId": "USGTECH",
                "SecurityName": "USG TECH SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE718B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532403",
                "SecurityId": "4THGEN",
                "SecurityName": "FOURTH GENERATION INFORMATION SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE739B01039",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532404",
                "SecurityId": "7TEC",
                "SecurityName": "SAVEN TECHNOLOGIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE856B01023",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532405",
                "SecurityId": "CCSIN",
                "SecurityName": "CCS INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661B01019",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532406",
                "SecurityId": "AVANTEL",
                "SecurityName": "AVANTEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE005B01019",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532407",
                "SecurityId": "MOSCHIP",
                "SecurityName": "MOSCHIP TECHNOLOGIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE935B01025",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532408",
                "SecurityId": "MEGASOFT",
                "SecurityName": "MEGASOFT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE933B01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532409",
                "SecurityId": "SYGENTA",
                "SecurityName": "SYNGENTA INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE402C01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532410",
                "SecurityId": "TRANSCOR",
                "SecurityName": "TRANSCORP INTERNATIONAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE330E01023",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532411",
                "SecurityId": "VISESHINFO",
                "SecurityName": "VISESH INFOTECNICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE861A01058",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532412",
                "SecurityId": "RYMBBPH",
                "SecurityName": "RYMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532413",
                "SecurityId": "CEREBRAINT",
                "SecurityName": "CEREBRA INTEGRATED TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE345B01019",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532414",
                "SecurityId": "IKFTECH",
                "SecurityName": "IKF TECHNOLOGIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE052C01027",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532415",
                "SecurityId": "SELBBPH",
                "SecurityName": "SELBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532416",
                "SecurityId": "NEXTMEDIA",
                "SecurityName": "NEXT MEDIAWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE747B01016",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532417",
                "SecurityId": "ESTAR",
                "SecurityName": "E.STAR INFOTECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE546B01020",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532418",
                "SecurityId": "ANDHRABANK",
                "SecurityName": "ANDHRA BANK",
                "FaceValue": "10",
                "ISINNo": "INE434A01013",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532419",
                "SecurityId": "SMARTLINK",
                "SecurityName": "SMARTLINK HOLDINGS LTD",
                "FaceValue": "2",
                "ISINNo": "INE178C01020",
                "Industry": "IT Networking Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532420",
                "SecurityId": "FENBBPH",
                "SecurityName": "FENBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532421",
                "SecurityId": "IQINF",
                "SecurityName": "IQ INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE679B01011",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532422",
                "SecurityId": "SEQSOFT",
                "SecurityName": "SEQUELSOFT INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE793C01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532423",
                "SecurityId": "JATBBPH",
                "SecurityName": "JATBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532424",
                "SecurityId": "GODREJCP",
                "SecurityName": "GODREJ CONSUMER PRODUCTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE102D01028",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532425",
                "SecurityId": "GENUSPRIME",
                "SecurityName": "GENUS PRIME INFRA LTD",
                "FaceValue": "2",
                "ISINNo": "INE256D01014",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532426",
                "SecurityId": "SEMBBPH",
                "SecurityName": "SEMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532427",
                "SecurityId": "RELBBPH",
                "SecurityName": "RELBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532428",
                "SecurityId": "WINBBPH",
                "SecurityName": "WINBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532429",
                "SecurityId": "STEBBPH",
                "SecurityName": "STEBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532430",
                "SecurityId": "BFUTILITIE",
                "SecurityName": "BF UTILITIES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE243D01012",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532431",
                "SecurityId": "BDMBBPH",
                "SecurityName": "BDMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532432",
                "SecurityId": "UNITDSPR",
                "SecurityName": "UNITED SPIRITS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE854D01024",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532433",
                "SecurityId": "BRTPHBB",
                "SecurityName": "BRTPHBB",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532434",
                "SecurityId": "BHMBBPH",
                "SecurityName": "BHMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532435",
                "SecurityId": "SANINFRA",
                "SecurityName": "SANMIT INFRA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE799C01023",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532436",
                "SecurityId": "KEIBBPH",
                "SecurityName": "KEIBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532437",
                "SecurityId": "OCIBBPH",
                "SecurityName": "OCIBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532438",
                "SecurityId": "JOFBBPH",
                "SecurityName": "JOFBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532439",
                "SecurityId": "OLECTRA",
                "SecurityName": "OLECTRA GREENTECH LTD",
                "FaceValue": "4",
                "ISINNo": "INE260D01016",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532440",
                "SecurityId": "MPSLTD",
                "SecurityName": "MPS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE943D01017",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532441",
                "SecurityId": "RAINBOWDQ",
                "SecurityName": "RAINBOW DENIM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE820D01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532442",
                "SecurityId": "MWTEXX",
                "SecurityName": "MW UNITEXX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE578D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532443",
                "SecurityId": "CERA",
                "SecurityName": "CERA SANITARYWARE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE739E01017",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532444",
                "SecurityId": "TSPIRITUAL",
                "SecurityName": "T.SPIRITUAL WORLD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE541C01037",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532445",
                "SecurityId": "EXDBBPH",
                "SecurityName": "EXDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532446",
                "SecurityId": "GOCBBPH",
                "SecurityName": "GOCBBPH",
                "FaceValue": "4",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532447",
                "SecurityId": "DUPHARPH",
                "SecurityName": "SOLVAY PHARMA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE593D01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532448",
                "SecurityId": "HERBBPH",
                "SecurityName": "HERBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532449",
                "SecurityId": "GGDBBPH",
                "SecurityName": "GGDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532450",
                "SecurityId": "BLCBBPH",
                "SecurityName": "BLCBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532451",
                "SecurityId": "HNDBBPH",
                "SecurityName": "HNDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532452",
                "SecurityId": "SAPETRO",
                "SecurityName": "SOUTH ASIAN PETROCHEM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE801C01019",
                "Industry": "Petrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532453",
                "SecurityId": "BLSBBPH",
                "SecurityName": "BLSBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532454",
                "SecurityId": "BHARTIARTL",
                "SecurityName": "BHARTI AIRTEL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE397D01024",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532455",
                "SecurityId": "SHALIWIR",
                "SecurityName": "SHALIMAR WIRES INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE655D01025",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532456",
                "SecurityId": "COMPUAGE",
                "SecurityName": "COMPUAGE INFOCOM LTD.",
                "FaceValue": "2",
                "ISINNo": "INE070C01037",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532457",
                "SecurityId": "GULPOLY",
                "SecurityName": "GULSHAN POLYOLS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE255D01024",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532458",
                "SecurityId": "IVPPHBB",
                "SecurityName": "IVPPHBB",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532459",
                "SecurityId": "FAZE3AUTO",
                "SecurityName": "FAZE THREE AUTOFAB LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE207D01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532460",
                "SecurityId": "PONNIERODE",
                "SecurityName": "PONNI SUGARS (ERODE) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE838E01017",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532461",
                "SecurityId": "PNB",
                "SecurityName": "PUNJAB NATIONAL BANK",
                "FaceValue": "2",
                "ISINNo": "INE160A01022",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532462",
                "SecurityId": "DEEBBPH",
                "SecurityName": "DEEBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532463",
                "SecurityId": "RSLTEXTIL",
                "SecurityName": "RSL TEXTILES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE259F01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532464",
                "SecurityId": "RAMCOSUP",
                "SecurityName": "RAMCO SUPER LEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE108F01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532465",
                "SecurityId": "TAFNCCPEQ",
                "SecurityName": "TAFNCCPEQ",
                "FaceValue": "10",
                "ISINNo": "IN9265A01035",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532466",
                "SecurityId": "OFSS",
                "SecurityName": "ORACLE FINANCIAL SERVICES SOFTWARE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE881D01027",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532467",
                "SecurityId": "HAZOOR",
                "SecurityName": "HAZOOR MULTI PROJECTS LTD.-$",
                "FaceValue": "4",
                "ISINNo": "INE550F01023",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532468",
                "SecurityId": "KAMAHOLD",
                "SecurityName": "KAMA HOLDINGS LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE411F01010",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532469",
                "SecurityId": "MPPLQ",
                "SecurityName": "MATHER & PLATT PUMPS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE096E01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532470",
                "SecurityId": "MPFSL",
                "SecurityName": "MATHER & PLATT FIRE SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE095E01022",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532471",
                "SecurityId": "SIRPURBBPH",
                "SecurityName": "SIRPURBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532472",
                "SecurityId": "GLOFAME",
                "SecurityName": "GLOFAME COTSPIN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE261D01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532473",
                "SecurityId": "SRFPOBBPH",
                "SecurityName": "SRFPOBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532474",
                "SecurityId": "SOLITARBBPH",
                "SecurityName": "SOLITARBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532475",
                "SecurityId": "APTECHT",
                "SecurityName": "APTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE266F01018",
                "Industry": "IT Training Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532476",
                "SecurityId": "VENPHBB",
                "SecurityName": "VENPHBB",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532477",
                "SecurityId": "UNIONBANK",
                "SecurityName": "UNION BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE692A01016",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532478",
                "SecurityId": "UBL",
                "SecurityName": "UNITED BREWERIES LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE686F01025",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532479",
                "SecurityId": "ISMTLTD",
                "SecurityName": "ISMT LTD.",
                "FaceValue": "5",
                "ISINNo": "INE732F01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532480",
                "SecurityId": "ALBK",
                "SecurityName": "ALLAHABAD BANK",
                "FaceValue": "10",
                "ISINNo": "INE428A01015",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532481",
                "SecurityId": "NOIDATOLL",
                "SecurityName": "NOIDA TOLL BRIDGE COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE781B01015",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532482",
                "SecurityId": "GRANULES",
                "SecurityName": "GRANULES INDIA LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE101D01020",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532483",
                "SecurityId": "CANBK",
                "SecurityName": "CANARA BANK",
                "FaceValue": "10",
                "ISINNo": "INE476A01014",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532484",
                "SecurityId": "SUNBBPH",
                "SecurityName": "SUNBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532485",
                "SecurityId": "BLIL",
                "SecurityName": "BALMER LAWRIE INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE525F01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532486",
                "SecurityId": "POKARNA",
                "SecurityName": "POKARNA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE637C01025",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532487",
                "SecurityId": "ECEINDBBPH",
                "SecurityName": "ECEINDBBPH",
                "FaceValue": "10",
                "ISINNo": "INE588B01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532488",
                "SecurityId": "DIVISLAB",
                "SecurityName": "DIVI&#39;S LABORATORIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE361B01024",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532489",
                "SecurityId": "ARVINPRO",
                "SecurityName": "ARVIND PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE764A01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532490",
                "SecurityId": "RANENGIN",
                "SecurityName": "RANE ENGINE VALVE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE385A01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532491",
                "SecurityId": "ECEIND",
                "SecurityName": "ECE INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE588B01014",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532492",
                "SecurityId": "INDOGULFFE",
                "SecurityName": "INDO GULF FERTILISERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE911F01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532493",
                "SecurityId": "ASTRAMICRO",
                "SecurityName": "ASTRA MICROWAVE PRODUCTS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE386C01029",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532494",
                "SecurityId": "MICROTECH",
                "SecurityName": "MICRO TECHNOLOGIES (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE358B01012",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532495",
                "SecurityId": "FINLNCBBPH",
                "SecurityName": "FINLNCBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532497",
                "SecurityId": "RADICO",
                "SecurityName": "RADICO KHAITAN LTD.",
                "FaceValue": "2",
                "ISINNo": "INE944F01028",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532498",
                "SecurityId": "SHRIRAMCIT",
                "SecurityName": "SHRIRAM CITY UNION FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE722A01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532500",
                "SecurityId": "MARUTI",
                "SecurityName": "MARUTI SUZUKI INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE585B01010",
                "Industry": "Cars & Utility Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532501",
                "SecurityId": "INDORAMTX",
                "SecurityName": "INDO RAMA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE059G01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532502",
                "SecurityId": "MAZDABBPH",
                "SecurityName": "MAZDABBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532503",
                "SecurityId": "RAJPALAYAM",
                "SecurityName": "RAJAPALAYAM MILLS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE296E01026",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532504",
                "SecurityId": "NAVINFLUOR",
                "SecurityName": "NAVIN FLUORINE INTERNATIONAL LIMITED-$",
                "FaceValue": "2",
                "ISINNo": "INE048G01026",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532505",
                "SecurityId": "UCOBANK",
                "SecurityName": "UCO BANK",
                "FaceValue": "10",
                "ISINNo": "INE691A01018",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532506",
                "SecurityId": "ACESFEXBBPH",
                "SecurityName": "ACESFEXBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532507",
                "SecurityId": "BAGFILMS",
                "SecurityName": "B.A.G.FILMS & MEDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE116D01028",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532508",
                "SecurityId": "JSL",
                "SecurityName": "JINDAL STAINLESS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE220G01021",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532509",
                "SecurityId": "SUPRAJIT",
                "SecurityName": "SUPRAJIT ENGINEERING LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE399C01030",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532510",
                "SecurityId": "ASLEYF",
                "SecurityName": "ASLEYF",
                "FaceValue": "10",
                "ISINNo": "IN9251A01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532511",
                "SecurityId": "EXCELCROP",
                "SecurityName": "EXCEL CROP CARE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE223G01017",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532512",
                "SecurityId": "MAWANA",
                "SecurityName": "MAWANA SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE255G01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532513",
                "SecurityId": "TVSELECT",
                "SecurityName": "TVS ELECTRONICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE236G01019",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532514",
                "SecurityId": "IGL",
                "SecurityName": "INDRAPRASTHA GAS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE203G01027",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532515",
                "SecurityId": "TVTODAY",
                "SecurityName": "TV TODAY NETWORK LTD.",
                "FaceValue": "5",
                "ISINNo": "INE038F01029",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532516",
                "SecurityId": "SURYAPHARM",
                "SecurityName": "SURYA PHARMACEUTICAL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE249G01020",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532517",
                "SecurityId": "PATNI",
                "SecurityName": "PATNI COMPUTER SYSTEMS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE660F01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532518",
                "SecurityId": "FLORENCE",
                "SecurityName": "FLORENCE INVESTECH LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE211G01020",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532519",
                "SecurityId": "JKSUGAR",
                "SecurityName": "JK SUGAR LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE210G01014",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532521",
                "SecurityId": "PALRED",
                "SecurityName": "PALRED TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE218G01033",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532522",
                "SecurityId": "PETRONET",
                "SecurityName": "PETRONET LNG LTD.",
                "FaceValue": "10",
                "ISINNo": "INE347G01014",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532523",
                "SecurityId": "BIOCON",
                "SecurityName": "BIOCON LTD.",
                "FaceValue": "5",
                "ISINNo": "INE376G01013",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532524",
                "SecurityId": "PTC",
                "SecurityName": "PTC INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE877F01012",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532525",
                "SecurityId": "MAHABANK",
                "SecurityName": "BANK OF MAHARASHTRA",
                "FaceValue": "10",
                "ISINNo": "INE457A01014",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532526",
                "SecurityId": "DISHMAN",
                "SecurityName": "DISHMAN PHARMACEUTICALS & CHEMICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE353G01020",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532527",
                "SecurityId": "RKFORGE",
                "SecurityName": "RAMKRISHNA FORGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE399G01015",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532528",
                "SecurityId": "DATAMATICS",
                "SecurityName": "DATAMATICS GLOBAL SERVICES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE365B01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532529",
                "SecurityId": "NDTV",
                "SecurityName": "NEW DELHI TELEVISION LTD.",
                "FaceValue": "4",
                "ISINNo": "INE155G01029",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532530",
                "SecurityId": "MASTEKBBPH",
                "SecurityName": "MASTEKBBPH",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532531",
                "SecurityId": "STAR",
                "SecurityName": "STRIDES PHARMA SCIENCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE939A01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532532",
                "SecurityId": "JPASSOCIAT",
                "SecurityName": "JAIPRAKASH ASSOCIATES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE455F01025",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532533",
                "SecurityId": "AVERYBBPH",
                "SecurityName": "AVERYBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532534",
                "SecurityId": "RELBBPHY",
                "SecurityName": "RELBBPHY",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532535",
                "SecurityId": "BRITANIBBPH",
                "SecurityName": "BRITANIBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532536",
                "SecurityId": "KOJAM",
                "SecurityName": "KOJAM FININVEST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE336G01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532537",
                "SecurityId": "LUMAXAUTO",
                "SecurityName": "LUMAX AUTOMOTIVE SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE547G01019",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532538",
                "SecurityId": "ULTRACEMCO",
                "SecurityName": "ULTRATECH CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE481G01011",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532539",
                "SecurityId": "MINDAIND",
                "SecurityName": "MINDA INDUSTRIES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE405E01023",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532540",
                "SecurityId": "TCS",
                "SecurityName": "TATA CONSULTANCY SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE467B01029",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532541",
                "SecurityId": "COFORGE",
                "SecurityName": "COFORGE LTD",
                "FaceValue": "10",
                "ISINNo": "INE591G01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532542",
                "SecurityId": "CREWBOS",
                "SecurityName": "CREW B.O.S.PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE514G01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532543",
                "SecurityId": "GULFPETRO",
                "SecurityName": "GP PETROLEUMS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE586G01017",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532544",
                "SecurityId": "IBULLS",
                "SecurityName": "INDIABULLS FINANCIAL SERVICES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE894F01025",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532545",
                "SecurityId": "FKONCO",
                "SecurityName": "FRESENIUS KABI ONCOLOGY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE575G01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532548",
                "SecurityId": "CENTURYPLY",
                "SecurityName": "CENTURY PLYBOARDS (I) LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE348B01021",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532553",
                "SecurityId": "WELENT",
                "SecurityName": "WELSPUN ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE625G01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532555",
                "SecurityId": "NTPC",
                "SecurityName": "NTPC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE733E01010",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532604",
                "SecurityId": "SALSTEEL",
                "SecurityName": "S.A.L.STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE658G01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532605",
                "SecurityId": "JBMA",
                "SecurityName": "JBM AUTO LTD.",
                "FaceValue": "5",
                "ISINNo": "INE927D01028",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532606",
                "SecurityId": "PARAL",
                "SecurityName": "PAREKH ALUMINEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE620C01013",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532607",
                "SecurityId": "ONTRACK",
                "SecurityName": "ONTRACK SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE426B01033",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532608",
                "SecurityId": "DCHL",
                "SecurityName": "DECCAN CHRONICLE HOLDINGS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE137G01027",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532609",
                "SecurityId": "BHARATIDIL",
                "SecurityName": "BHARATI DEFENCE AND INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE673G01013",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532610",
                "SecurityId": "DWARKESH",
                "SecurityName": "DWARIKESH SUGAR INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE366A01041",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532611",
                "SecurityId": "RILBBPH",
                "SecurityName": "RILBBPH",
                "FaceValue": "10",
                "ISINNo": "INE002A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532612",
                "SecurityId": "INDOCO",
                "SecurityName": "INDOCO REMEDIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE873D01024",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532613",
                "SecurityId": "VIPCLOTHNG",
                "SecurityName": "VIP CLOTHING LTD",
                "FaceValue": "2",
                "ISINNo": "INE450G01024",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532614",
                "SecurityId": "IMPEXFERRO",
                "SecurityName": "IMPEX FERRO TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE691G01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532615",
                "SecurityId": "ETC",
                "SecurityName": "ETC NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE695G01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532616",
                "SecurityId": "XCHANGING",
                "SecurityName": "XCHANGING SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE692G01013",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532617",
                "SecurityId": "JETAIRWAYS",
                "SecurityName": "JET AIRWAYS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE802G01018",
                "Industry": "Airlines",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532618",
                "SecurityId": "VISHALEXPO",
                "SecurityName": "VISHAL EXPORTS OVERSEAS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE167C01023",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532619",
                "SecurityId": "UTVSOF",
                "SecurityName": "UTV SOFTWARE COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE507B01022",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532620",
                "SecurityId": "AEGISLOGBB",
                "SecurityName": "AEGISLOGBB",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532621",
                "SecurityId": "MORARJEE",
                "SecurityName": "MORARJEE TEXTILES LTD.",
                "FaceValue": "7",
                "ISINNo": "INE161G01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532622",
                "SecurityId": "GDL",
                "SecurityName": "GATEWAY DISTRIPARKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE852F01015",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532623",
                "SecurityId": "NRINTER",
                "SecurityName": "N.R.INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE605F01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532624",
                "SecurityId": "JINDALPHOT",
                "SecurityName": "JINDAL PHOTO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE796G01012",
                "Industry": "Photographic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532625",
                "SecurityId": "DILBBPH",
                "SecurityName": "DILBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532626",
                "SecurityId": "PONDYOXIDE",
                "SecurityName": "PONDY OXIDES & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE063E01046",
                "Industry": "Other Non-Ferrous Metals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532627",
                "SecurityId": "JPPOWER",
                "SecurityName": "JAIPRAKASH POWER VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE351F01018",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532628",
                "SecurityId": "3IINFOTECH",
                "SecurityName": "3I INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE748C01020",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532629",
                "SecurityId": "MBECL",
                "SecurityName": "MCNALLY BHARAT ENGINEERING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE748A01016",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532630",
                "SecurityId": "GOKEX",
                "SecurityName": "GOKALDAS EXPORTS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE887G01027",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532631",
                "SecurityId": "FAMEINDIA",
                "SecurityName": "FAME INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE886G01011",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532632",
                "SecurityId": "GLAXOSMBBPH",
                "SecurityName": "GLAXOSMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532633",
                "SecurityId": "ALLSEC",
                "SecurityName": "ALLSEC TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE835G01018",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532635",
                "SecurityId": "ETCBBPH",
                "SecurityName": "ETCBBPH",
                "FaceValue": "10",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532636",
                "SecurityId": "IIFL",
                "SecurityName": "IIFL FINANCE LTD",
                "FaceValue": "2",
                "ISINNo": "INE530B01024",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532637",
                "SecurityId": "MANGALAM",
                "SecurityName": "MANGALAM DRUGS & ORGANICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE584F01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532638",
                "SecurityId": "SHOPERSTOP",
                "SecurityName": "SHOPPERS STOP LTD.",
                "FaceValue": "5",
                "ISINNo": "INE498B01024",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532639",
                "SecurityId": "POLARISBBPH",
                "SecurityName": "POLARISBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532640",
                "SecurityId": "CYBERMEDIA",
                "SecurityName": "CYBER MEDIA (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE278G01037",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532641",
                "SecurityId": "NDL",
                "SecurityName": "NANDAN DENIM LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE875G01030",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532642",
                "SecurityId": "JSWHL",
                "SecurityName": "JSW HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE824G01012",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532643",
                "SecurityId": "SGFL",
                "SecurityName": "SHREE GANESH FORGINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE883G01018",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532644",
                "SecurityId": "JKCEMENT",
                "SecurityName": "J.K.CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE823G01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532645",
                "SecurityId": "BEEYU",
                "SecurityName": "BEEYU OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE052B01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532646",
                "SecurityId": "UNIPLY",
                "SecurityName": "UNIPLY INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE950G01023",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532647",
                "SecurityId": "PROVOGE",
                "SecurityName": "PROVOGUE (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE968G01033",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532648",
                "SecurityId": "YESBANK",
                "SecurityName": "YES BANK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE528G01035",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532649",
                "SecurityId": "NECLIFE",
                "SecurityName": "NECTAR LIFESCIENCES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE023H01027",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532650",
                "SecurityId": "MSPL",
                "SecurityName": "MSP STEEL & POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE752G01015",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532651",
                "SecurityId": "SPLIL",
                "SecurityName": "SPL INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE978G01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532652",
                "SecurityId": "KTKBANK",
                "SecurityName": "KARNATAKA BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE614B01018",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532653",
                "SecurityId": "HINVDIR",
                "SecurityName": "HSBC INVESTDIRECT (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE800B01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532654",
                "SecurityId": "MCLEODRUSS",
                "SecurityName": "MCLEOD RUSSEL INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE942G01012",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532655",
                "SecurityId": "BALASTEELS",
                "SecurityName": "SHRI RAMRUPAI BALAJI STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE977G01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532656",
                "SecurityId": "FACORALL",
                "SecurityName": "FACOR ALLOYS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE828G01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532657",
                "SecurityId": "FACORSTE",
                "SecurityName": "FACOR STEELS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE829G01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532658",
                "SecurityId": "EON",
                "SecurityName": "EON ELECTRIC LTD.",
                "FaceValue": "5",
                "ISINNo": "INE076H01025",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532659",
                "SecurityId": "IDFC",
                "SecurityName": "IDFC LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE043D01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532660",
                "SecurityId": "VIVIMEDLAB",
                "SecurityName": "VIVIMED LABS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE526G01021",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532661",
                "SecurityId": "RML",
                "SecurityName": "RANE (MADRAS) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE050H01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532662",
                "SecurityId": "HTMEDIA",
                "SecurityName": "HT MEDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE501G01024",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532663",
                "SecurityId": "SASKEN",
                "SecurityName": "SASKEN TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE231F01020",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532664",
                "SecurityId": "AMAR",
                "SecurityName": "AMAR REMEDIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE787G01011",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532665",
                "SecurityId": "RAJVIR",
                "SecurityName": "RAJVIR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE011H01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532666",
                "SecurityId": "FCSSOFT",
                "SecurityName": "FCS SOFTWARE SOLUTIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE512B01022",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532667",
                "SecurityId": "SUZLON",
                "SecurityName": "SUZLON ENERGY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE040H01021",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532668",
                "SecurityId": "AURIONPRO",
                "SecurityName": "AURIONPRO SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE132H01018",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532669",
                "SecurityId": "SBTL",
                "SecurityName": "SOUTHERN ONLINE BIO TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE371B01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532670",
                "SecurityId": "RENUKA",
                "SecurityName": "SHREE RENUKA SUGARS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE087H01022",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532671",
                "SecurityId": "JYOTHYCONS",
                "SecurityName": "JYOTHY CONSUMER PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE099H01019",
                "Industry": "Household Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532672",
                "SecurityId": "GLODYNE",
                "SecurityName": "GLODYNE TECHNOSERVE LTD.",
                "FaceValue": "6",
                "ISINNo": "INE932G01021",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532673",
                "SecurityId": "KMSUGAR",
                "SecurityName": "K.M.SUGAR MILLS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE157H01023",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532674",
                "SecurityId": "BASML",
                "SecurityName": "BANNARI AMMAN SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE186H01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532675",
                "SecurityId": "PRITHVI",
                "SecurityName": "PRITHVI INFORMATION SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE700C01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532676",
                "SecurityId": "PBAINFRA",
                "SecurityName": "PBA INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE160H01019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532677",
                "SecurityId": "VIKASHMET",
                "SecurityName": "VIKASH METAL & POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE158H01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532678",
                "SecurityId": "BRFL",
                "SecurityName": "BOMBAY RAYON FASHIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE589G01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532679",
                "SecurityId": "SORILINFRA",
                "SecurityName": "SORIL INFRA RESOURCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE034H01016",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532680",
                "SecurityId": "PRIMESEBBPH",
                "SecurityName": "PRIMESEBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532681",
                "SecurityId": "IBULLSBBPH",
                "SecurityName": "IBULLSBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532682",
                "SecurityId": "ABGSHIP",
                "SecurityName": "ABG SHIPYARD LTD.",
                "FaceValue": "10",
                "ISINNo": "INE067H01016",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532683",
                "SecurityId": "AIAENG",
                "SecurityName": "AIA ENGINEERING LTD.",
                "FaceValue": "2",
                "ISINNo": "INE212H01026",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532684",
                "SecurityId": "EKC",
                "SecurityName": "EVEREST KANTO CYLINDER LTD.",
                "FaceValue": "2",
                "ISINNo": "INE184H01027",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532685",
                "SecurityId": "SRFPOLYBBPH",
                "SecurityName": "SRFPOLYBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532686",
                "SecurityId": "KERNEX",
                "SecurityName": "KERNEX MICROSYSTEMS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE202H01019",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532687",
                "SecurityId": "REPRO",
                "SecurityName": "REPRO INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE461B01014",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532688",
                "SecurityId": "COMSYS",
                "SecurityName": "COMPULINK SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE206H01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532689",
                "SecurityId": "PVR",
                "SecurityName": "PVR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE191H01014",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532690",
                "SecurityId": "RAMSARUP",
                "SecurityName": "RAMSARUP INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE005D01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532691",
                "SecurityId": "TULIP",
                "SecurityName": "TULIP TELECOM LTD.",
                "FaceValue": "2",
                "ISINNo": "INE122H01027",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532692",
                "SecurityId": "RMCL",
                "SecurityName": "RADHA MADHAV CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE172H01014",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532693",
                "SecurityId": "PUNJLLOYD",
                "SecurityName": "PUNJ LLOYD LTD.",
                "FaceValue": "2",
                "ISINNo": "INE701B01021",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532694",
                "SecurityId": "BARTRONICS",
                "SecurityName": "BARTRONICS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE855F01034",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532695",
                "SecurityId": "CELEBRITY",
                "SecurityName": "CELEBRITY FASHIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE185H01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532696",
                "SecurityId": "EDUCOMP",
                "SecurityName": "EDUCOMP SOLUTIONS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE216H01027",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532698",
                "SecurityId": "NITINSPIN",
                "SecurityName": "NITIN SPINNERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE229H01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532699",
                "SecurityId": "ROHLTD",
                "SecurityName": "ROYAL ORCHID HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE283H01019",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532700",
                "SecurityId": "ENIL",
                "SecurityName": "ENTERTAINMENT NETWORK (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE265F01028",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532701",
                "SecurityId": "CELLA",
                "SecurityName": "CELLA SPACE LTD",
                "FaceValue": "10",
                "ISINNo": "INE266H01014",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532702",
                "SecurityId": "GSPL",
                "SecurityName": "GUJARAT STATE PETRONET LTD.",
                "FaceValue": "10",
                "ISINNo": "INE246F01010",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532703",
                "SecurityId": "RCAPVL",
                "SecurityName": "RELIANCE CAPITAL VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE331H01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532704",
                "SecurityId": "RENVL",
                "SecurityName": "RELIANCE ENERGY VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE329H01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532705",
                "SecurityId": "JAGRAN",
                "SecurityName": "JAGRAN PRAKASHAN LTD.",
                "FaceValue": "2",
                "ISINNo": "INE199G01027",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532706",
                "SecurityId": "INOXLEISUR",
                "SecurityName": "INOX LEISURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE312H01016",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532707",
                "SecurityId": "DYNPRO",
                "SecurityName": "DYNEMIC PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE256H01015",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532708",
                "SecurityId": "GVKPIL",
                "SecurityName": "GVK POWER & INFRASTRUCTURE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE251H01024",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532709",
                "SecurityId": "RNRL",
                "SecurityName": "RELIANCE NATURAL RESOURCES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE328H01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532710",
                "SecurityId": "SADBHAV",
                "SecurityName": "SADBHAV ENGINEERING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE226H01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532711",
                "SecurityId": "SUNILHITEC",
                "SecurityName": "SUNIL HITECH ENGINEERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE305H01028",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532712",
                "SecurityId": "RCOM",
                "SecurityName": "RELIANCE COMMUNICATIONS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE330H01018",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532713",
                "SecurityId": "SAKUMA",
                "SecurityName": "SAKUMA EXPORTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE190H01024",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532714",
                "SecurityId": "KEC",
                "SecurityName": "KEC INTERNATIONAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE389H01022",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532715",
                "SecurityId": "GITANJALI",
                "SecurityName": "GITANJALI GEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE346H01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532716",
                "SecurityId": "GILLANDERS",
                "SecurityName": "GILLANDERS ARBUTHNOT & CO.LTD.",
                "FaceValue": "10",
                "ISINNo": "INE047B01011",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532717",
                "SecurityId": "INDOTECH",
                "SecurityName": "INDO TECH TRANSFORMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE332H01014",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532718",
                "SecurityId": "PRATIBHA",
                "SecurityName": "PRATIBHA INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE308H01022",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532719",
                "SecurityId": "BLKASHYAP",
                "SecurityName": "B.L.KASHYAP AND SONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE350H01032",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532720",
                "SecurityId": "M&MFIN",
                "SecurityName": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE774D01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532721",
                "SecurityId": "VISASTEEL",
                "SecurityName": "VISA STEEL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE286H01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532722",
                "SecurityId": "NITCO",
                "SecurityName": "NITCO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE858F01012",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532723",
                "SecurityId": "MPDL",
                "SecurityName": "MPDL LTD",
                "FaceValue": "10",
                "ISINNo": "INE493H01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532724",
                "SecurityId": "MOUNTEVTR",
                "SecurityName": "MOUNTEVEREST TRADING & INVESTMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE492H01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532725",
                "SecurityId": "SOLARINDS",
                "SecurityName": "SOLAR INDUSTRIES INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE343H01029",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532726",
                "SecurityId": "GALLANTT",
                "SecurityName": "GALLANTT METAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE297H01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532727",
                "SecurityId": "ADHUNIK",
                "SecurityName": "ADHUNIK METALIKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE400H01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532728",
                "SecurityId": "MALUPAPER",
                "SecurityName": "MALU PAPER MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE383H01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532729",
                "SecurityId": "UTTAMSUGAR",
                "SecurityName": "UTTAM SUGAR MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE786F01031",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532730",
                "SecurityId": "SGL",
                "SecurityName": "STL GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE353H01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532731",
                "SecurityId": "ROHITFERRO",
                "SecurityName": "ROHIT FERRO-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE248H01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532732",
                "SecurityId": "KKCL",
                "SecurityName": "KEWAL KIRAN CLOTHING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE401H01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532733",
                "SecurityId": "SUNTV",
                "SecurityName": "SUN TV NETWORK LTD.",
                "FaceValue": "5",
                "ISINNo": "INE424H01027",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532734",
                "SecurityId": "GPIL",
                "SecurityName": "GODAWARI POWER & ISPAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE177H01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532735",
                "SecurityId": "RSYSTEMINT",
                "SecurityName": "R SYSTEMS INTERNATIONAL LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE411H01032",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532736",
                "SecurityId": "POWERSOF",
                "SecurityName": "POWERSOFT GLOBAL SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE979C01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532737",
                "SecurityId": "EMKAY",
                "SecurityName": "EMKAY GLOBAL FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE296H01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532738",
                "SecurityId": "TANTIACONS",
                "SecurityName": "TANTIA CONSTRUCTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE388G01018",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532739",
                "SecurityId": "PLETHICO",
                "SecurityName": "PLETHICO PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE491H01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532740",
                "SecurityId": "LOKESHMACH",
                "SecurityName": "LOKESH MACHINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE397H01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532741",
                "SecurityId": "KAMDHENU",
                "SecurityName": "KAMDHENU LTD",
                "FaceValue": "10",
                "ISINNo": "INE390H01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532742",
                "SecurityId": "PAUSHAKLTD",
                "SecurityName": "PAUSHAK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE111F01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532743",
                "SecurityId": "RPL",
                "SecurityName": "RELIANCE PETROLEUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE475H01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532744",
                "SecurityId": "GTNTEX",
                "SecurityName": "GTN TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE302H01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532745",
                "SecurityId": "INDICAP",
                "SecurityName": "INDITRADE CAPITAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE347H01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532746",
                "SecurityId": "UNITY",
                "SecurityName": "UNITY INFRAPROJECTS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE466H01028",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532747",
                "SecurityId": "KFA",
                "SecurityName": "KINGFISHER AIRLINES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE438H01019",
                "Industry": "Airlines",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532748",
                "SecurityId": "PFOCUS",
                "SecurityName": "PRIME FOCUS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE367G01038",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532749",
                "SecurityId": "ALLCARGO",
                "SecurityName": "ALLCARGO LOGISTICS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE418H01029",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532750",
                "SecurityId": "SRFLTDBBPH",
                "SecurityName": "SRFLTDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532751",
                "SecurityId": "EASUNREYRL",
                "SecurityName": "EASUN REYROLLE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE268C01029",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532752",
                "SecurityId": "REVTEQBBPH",
                "SecurityName": "REVTEQBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532753",
                "SecurityId": "ASHIM",
                "SecurityName": "ASHIM INVESTMENT COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE511H01021",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532754",
                "SecurityId": "GMRINFRA",
                "SecurityName": "GMR INFRASTRUCTURE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE776C01039",
                "Industry": "Airport Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532755",
                "SecurityId": "TECHM",
                "SecurityName": "TECH MAHINDRA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE669C01036",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532756",
                "SecurityId": "MAHINDCIE",
                "SecurityName": "MAHINDRA CIE AUTOMOTIVE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE536H01010",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532757",
                "SecurityId": "VOLTAMP",
                "SecurityName": "VOLTAMP TRANSFORMERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE540H01012",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532758",
                "SecurityId": "KEWIND",
                "SecurityName": "KEW INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE700H01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532759",
                "SecurityId": "ATLANTA",
                "SecurityName": "ATLANTA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE285H01022",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532760",
                "SecurityId": "DEEPENR",
                "SecurityName": "DEEP ENERGY RESOURCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE677H01012",
                "Industry": "Integrated Oil & Gas",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532761",
                "SecurityId": "HOVS",
                "SecurityName": "HOV SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE596H01014",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532762",
                "SecurityId": "ACE",
                "SecurityName": "ACTION CONSTRUCTION EQUIPMENT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE731H01025",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532763",
                "SecurityId": "ICIBBPH",
                "SecurityName": "ICIBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532764",
                "SecurityId": "GEECEE",
                "SecurityName": "GEECEE VENTURES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE916G01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532765",
                "SecurityId": "USHERAGRO",
                "SecurityName": "USHER AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE235G01011",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532766",
                "SecurityId": "RICHAIND",
                "SecurityName": "RICHA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE516H01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532767",
                "SecurityId": "GAYAPROJ",
                "SecurityName": "GAYATRI PROJECTS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE336H01023",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532768",
                "SecurityId": "FIEMIND",
                "SecurityName": "FIEM INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE737H01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532770",
                "SecurityId": "HANUNG",
                "SecurityName": "HANUNG TOYS & TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE648H01013",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532771",
                "SecurityId": "JHS",
                "SecurityName": "JHS SVENDGAARD LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE544H01014",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532772",
                "SecurityId": "DCBBANK",
                "SecurityName": "DCB BANK LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE503A01015",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532773",
                "SecurityId": "GLOBALVECT",
                "SecurityName": "GLOBAL VECTRA HELICORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE792H01019",
                "Industry": "Airlines",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532774",
                "SecurityId": "INSPIRISYS",
                "SecurityName": "INSPIRISYS SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE020G01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532775",
                "SecurityId": "GTLINFRA",
                "SecurityName": "GTL INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE221H01019",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532776",
                "SecurityId": "SHIVAMAUTO",
                "SecurityName": "SHIVAM AUTOTECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE637H01024",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532777",
                "SecurityId": "NAUKRI",
                "SecurityName": "INFO EDGE (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE663F01024",
                "Industry": "Internet & Catalogue Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532778",
                "SecurityId": "LITL",
                "SecurityName": "LANCO INFRATECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE785C01048",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532779",
                "SecurityId": "TORNTPOWER",
                "SecurityName": "TORRENT POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE813H01021",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532780",
                "SecurityId": "PARSVNATH",
                "SecurityName": "PARSVNATH DEVELOPERS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE561H01026",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532781",
                "SecurityId": "BLUEBIRD",
                "SecurityName": "BLUE BIRD (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE697H01010",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532782",
                "SecurityId": "SUTLEJTEX",
                "SecurityName": "SUTLEJ TEXTILES & INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE645H01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532783",
                "SecurityId": "DAAWAT",
                "SecurityName": "LT FOODS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE818H01020",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532784",
                "SecurityId": "SOBHA",
                "SecurityName": "SOBHA LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE671H01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532785",
                "SecurityId": "RUCHIRA",
                "SecurityName": "RUCHIRA PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE803H01014",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532786",
                "SecurityId": "GTOFFSHORE",
                "SecurityName": "GOL OFFSHORE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE892H01017",
                "Industry": "Oil Equipment & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532787",
                "SecurityId": "ESSDEE",
                "SecurityName": "ESS DEE ALUMINIUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE825H01017",
                "Industry": "Household Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532788",
                "SecurityId": "XLENERGY",
                "SecurityName": "XL ENERGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE183H01011",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532789",
                "SecurityId": "NCOPPER",
                "SecurityName": "NISSAN COPPER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE846H01039",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532790",
                "SecurityId": "TANLA",
                "SecurityName": "TANLA PLATFORMS LTD",
                "FaceValue": "1",
                "ISINNo": "INE483C01032",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532791",
                "SecurityId": "PSTL",
                "SecurityName": "PYRAMID SAIMIRA THEATRE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE165H01018",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532792",
                "SecurityId": "CAIRN",
                "SecurityName": "CAIRN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE910H01017",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532793",
                "SecurityId": "SHREEASHTA",
                "SecurityName": "SHREE ASHTAVINAYAK CINE VISION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE538H01024",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532794",
                "SecurityId": "ZEEMEDIA",
                "SecurityName": "ZEE MEDIA CORPORATION LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE966H01019",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532795",
                "SecurityId": "SITINET",
                "SecurityName": "SITI NETWORKS LTD",
                "FaceValue": "1",
                "ISINNo": "INE965H01011",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532796",
                "SecurityId": "LUMAXTECH",
                "SecurityName": "LUMAX AUTO TECHNOLOGIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE872H01027",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532797",
                "SecurityId": "AUTOIND",
                "SecurityName": "AUTOLINE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE718H01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532798",
                "SecurityId": "NETWORK18",
                "SecurityName": "NETWORK18 MEDIA & INVESTMENTS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE870H01013",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532799",
                "SecurityId": "HUBTOWN",
                "SecurityName": "HUBTOWN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE703H01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532800",
                "SecurityId": "TV18BRDCST",
                "SecurityName": "TV18 BROADCAST LTD.",
                "FaceValue": "2",
                "ISINNo": "INE886H01027",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532801",
                "SecurityId": "CTE",
                "SecurityName": "CAMBRIDGE TECHNOLOGY ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE627H01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532802",
                "SecurityId": "NATCOBBPH",
                "SecurityName": "NATCOBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532803",
                "SecurityId": "POCHIRAJU",
                "SecurityName": "POCHIRAJU INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE332G01032",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532804",
                "SecurityId": "TIIL",
                "SecurityName": "TECHNOCRAFT INDUSTRIES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE545H01011",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532805",
                "SecurityId": "REDINGTON",
                "SecurityName": "REDINGTON (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE891D01026",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532806",
                "SecurityId": "AICHAMP",
                "SecurityName": "AI CHAMPDANY INDUSTRIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE768E01024",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532807",
                "SecurityId": "CINELINE",
                "SecurityName": "CINELINE INDIA LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE704H01022",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532808",
                "SecurityId": "PGIL",
                "SecurityName": "PEARL GLOBAL INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE940H01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532809",
                "SecurityId": "FSL",
                "SecurityName": "FIRSTSOURCE SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE684F01012",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532810",
                "SecurityId": "PFC",
                "SecurityName": "POWER FINANCE CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE134E01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532811",
                "SecurityId": "AHLUCONT",
                "SecurityName": "AHLUWALIA CONTRACTS (INDIA) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE758C01029",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532812",
                "SecurityId": "TFL",
                "SecurityName": "TRANSWARRANTY FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE804H01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532813",
                "SecurityId": "CANDC",
                "SecurityName": "C & C CONSTRUCTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE874H01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532814",
                "SecurityId": "INDIANB",
                "SecurityName": "INDIAN BANK",
                "FaceValue": "10",
                "ISINNo": "INE562A01011",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532815",
                "SecurityId": "SMSPHARMA",
                "SecurityName": "SMS PHARMACEUTICALS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE812G01025",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532816",
                "SecurityId": "BROADCAST",
                "SecurityName": "BROADCAST INITIATIVES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE698H01018",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532817",
                "SecurityId": "ORIENTALTL",
                "SecurityName": "ORIENTAL TRIMEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE998H01012",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532818",
                "SecurityId": "EVINIX",
                "SecurityName": "EVINIX ACCESSORIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE961H01028",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532819",
                "SecurityId": "MINDTREE",
                "SecurityName": "MINDTREE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE018I01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532820",
                "SecurityId": "ELAND",
                "SecurityName": "E-LAND APPAREL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE311H01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532821",
                "SecurityId": "INDUSFILA",
                "SecurityName": "INDUS FILA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE025I01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532822",
                "SecurityId": "IDEA",
                "SecurityName": "VODAFONE IDEA LTD",
                "FaceValue": "10",
                "ISINNo": "INE669E01016",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532823",
                "SecurityId": "EUROCERA",
                "SecurityName": "EURO CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE649H01011",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532824",
                "SecurityId": "VTXIND",
                "SecurityName": "VTX INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE119G01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532825",
                "SecurityId": "JAGJANANI",
                "SecurityName": "JAGJANANI TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE702H01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532826",
                "SecurityId": "RAJTV",
                "SecurityName": "RAJ TELEVISION NETWORK LTD.",
                "FaceValue": "5",
                "ISINNo": "INE952H01027",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532827",
                "SecurityId": "PAGEIND",
                "SecurityName": "PAGE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE761H01022",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532828",
                "SecurityId": "AMDIND",
                "SecurityName": "AMD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE005I01014",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532829",
                "SecurityId": "LEHAR",
                "SecurityName": "LEHAR FOOTWEARS LTD",
                "FaceValue": "10",
                "ISINNo": "INE976H01018",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532830",
                "SecurityId": "ASTRAL",
                "SecurityName": "ASTRAL POLY TECHNIK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE006I01046",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532831",
                "SecurityId": "ABHISHEK",
                "SecurityName": "ABHISHEK CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE004I01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532832",
                "SecurityId": "IBREALEST",
                "SecurityName": "INDIABULLS REAL ESTATE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE069I01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532833",
                "SecurityId": "SPARSHBPO",
                "SecurityName": "SPARSH BPO SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE847H01011",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532834",
                "SecurityId": "CAMLINFINE",
                "SecurityName": "CAMLIN FINE SCIENCES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE052I01032",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532835",
                "SecurityId": "ICRA",
                "SecurityName": "ICRA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE725G01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532836",
                "SecurityId": "SANCIA",
                "SecurityName": "SANCIA GLOBAL INFRAPROJECTS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE391H01010",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532837",
                "SecurityId": "ORBITCORP",
                "SecurityName": "ORBIT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE628H01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532838",
                "SecurityId": "GAELBBPH",
                "SecurityName": "GAELBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532839",
                "SecurityId": "DISHTV",
                "SecurityName": "DISH TV INDIA LTD.",
                "FaceValue": "1",
                "ISINNo": "INE836F01026",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532840",
                "SecurityId": "ADVANTA",
                "SecurityName": "ADVANTA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE517H01028",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532841",
                "SecurityId": "SAHYADRI",
                "SecurityName": "SAHYADRI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE280H01015",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532842",
                "SecurityId": "SRHHYPOLTD",
                "SecurityName": "SREE RAYALASEEMA HI-STRENGTH HYPO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE917H01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532843",
                "SecurityId": "FORTIS",
                "SecurityName": "FORTIS HEALTHCARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE061F01013",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532844",
                "SecurityId": "ASELBBPH",
                "SecurityName": "ASELBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532845",
                "SecurityId": "TGBHOTELS",
                "SecurityName": "TGB BANQUETS AND HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE797H01018",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532846",
                "SecurityId": "MROTEKBBPH",
                "SecurityName": "MROTEKBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532847",
                "SecurityId": "HILTON",
                "SecurityName": "HILTON METAL FORGING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE788H01017",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532848",
                "SecurityId": "DELTACORP",
                "SecurityName": "DELTA CORP LTD.",
                "FaceValue": "1",
                "ISINNo": "INE124G01033",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532849",
                "SecurityId": "BINANICEM",
                "SecurityName": "BINANI CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE042H01019",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532850",
                "SecurityId": "MIC",
                "SecurityName": "MIC ELECTRONICS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE287C01029",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532851",
                "SecurityId": "INSECTICID",
                "SecurityName": "INSECTICIDES (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE070I01018",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532852",
                "SecurityId": "MCDHOLDING",
                "SecurityName": "MCDOWELL HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE836H01014",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532853",
                "SecurityId": "ASAHISONG",
                "SecurityName": "ASAHI SONGWON COLORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE228I01012",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532854",
                "SecurityId": "NITINFIRE",
                "SecurityName": "NITIN FIRE PROTECTION INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE489H01020",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532855",
                "SecurityId": "HARYNACAP",
                "SecurityName": "HARYANA CAPFIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE928H01019",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532856",
                "SecurityId": "TIMETECHNO",
                "SecurityName": "TIME TECHNOPLAST LTD.",
                "FaceValue": "1",
                "ISINNo": "INE508G01029",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532857",
                "SecurityId": "GLORY",
                "SecurityName": "GLORY POLYFILMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE408H01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532858",
                "SecurityId": "DECOLIGHT",
                "SecurityName": "DECOLIGHT CERAMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE172I01012",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532859",
                "SecurityId": "HGS",
                "SecurityName": "HINDUJA GLOBAL SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE170I01016",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532860",
                "SecurityId": "NETFLIER",
                "SecurityName": "NETFLIER FINCO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE068I01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532861",
                "SecurityId": "IPRUFMP36AG",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND",
                "FaceValue": "10",
                "ISINNo": "INF346A01042",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532862",
                "SecurityId": "IPRUFMP36AD",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND",
                "FaceValue": "10",
                "ISINNo": "INF346A01059",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532863",
                "SecurityId": "SPICETELE",
                "SecurityName": "SPICE COMMUNICATIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE684H01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532864",
                "SecurityId": "NELCAST",
                "SecurityName": "NELCAST LTD.",
                "FaceValue": "2",
                "ISINNo": "INE189I01024",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532865",
                "SecurityId": "MEGH",
                "SecurityName": "MEGHMANI ORGANICS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE974H01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532866",
                "SecurityId": "QUINTEGRA",
                "SecurityName": "QUINTEGRA SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE033B01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532867",
                "SecurityId": "V2RETAIL",
                "SecurityName": "V2 RETAIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE945H01013",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532868",
                "SecurityId": "DLF",
                "SecurityName": "DLF LTD.",
                "FaceValue": "2",
                "ISINNo": "INE271C01023",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532869",
                "SecurityId": "TARMAT",
                "SecurityName": "TARMAT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE924H01018",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532870",
                "SecurityId": "ANKITMETAL",
                "SecurityName": "ANKIT METAL & POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE106I01010",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532871",
                "SecurityId": "CELESTIAL",
                "SecurityName": "CELESTIAL BIOLABS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE221I01017",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532872",
                "SecurityId": "SPARC",
                "SecurityName": "SUN PHARMA ADVANCED RESEARCH COMPANY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE232I01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532873",
                "SecurityId": "HDIL",
                "SecurityName": "HOUSING DEVELOPMENT & INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE191I01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532874",
                "SecurityId": "SURYACHAKRA",
                "SecurityName": "SURYACHAKRA POWER CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE274I01016",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532875",
                "SecurityId": "ADSL",
                "SecurityName": "ALLIED DIGITAL SERVICES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE102I01027",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532876",
                "SecurityId": "EVERONN",
                "SecurityName": "EVERONN EDUCATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE678H01010",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532877",
                "SecurityId": "SIMPLEX",
                "SecurityName": "SIMPLEX PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE898F01018",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532878",
                "SecurityId": "ALPA",
                "SecurityName": "ALPA LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE385I01010",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532879",
                "SecurityId": "SSLEL",
                "SecurityName": "SIR SHADI LAL ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE117H01019",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532880",
                "SecurityId": "OMAXE",
                "SecurityName": "OMAXE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE800H01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532881",
                "SecurityId": "IVRCLAH",
                "SecurityName": "IVRCL ASSETS & HOLDINGS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE414I01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532882",
                "SecurityId": "OMNITECH",
                "SecurityName": "OMNITECH INFOSOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE810H01019",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532883",
                "SecurityId": "ZYLOG",
                "SecurityName": "ZYLOG SYSTEMS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE225I01026",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532884",
                "SecurityId": "REFEX",
                "SecurityName": "REFEX INDUSTRIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE056I01017",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532885",
                "SecurityId": "CENTRALBK",
                "SecurityName": "CENTRAL BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE483A01010",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532886",
                "SecurityId": "SELMCL",
                "SecurityName": "SEL MANUFACTURING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE105I01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532887",
                "SecurityId": "NTL",
                "SecurityName": "NEUEON TOWERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE333I01036",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532888",
                "SecurityId": "ASIANTILES",
                "SecurityName": "ASIAN GRANITO INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE022I01019",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532889",
                "SecurityId": "KPRMILL",
                "SecurityName": "K.P.R. MILL LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE930H01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532890",
                "SecurityId": "TAKE",
                "SecurityName": "TAKE SOLUTIONS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE142I01023",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532891",
                "SecurityId": "PURVA",
                "SecurityName": "PURAVANKARA LTD",
                "FaceValue": "5",
                "ISINNo": "INE323I01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532892",
                "SecurityId": "MOTILALOFS",
                "SecurityName": "MOTILAL OSWAL FINANCIAL SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE338I01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532893",
                "SecurityId": "VTMLTD",
                "SecurityName": "VTM LTD.",
                "FaceValue": "1",
                "ISINNo": "INE222F01029",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532894",
                "SecurityId": "INDOWIND",
                "SecurityName": "INDOWIND ENERGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE227G01018",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532895",
                "SecurityId": "NAGREEKCAP",
                "SecurityName": "NAGREEKA CAPITAL & INFRASTRUCTURE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE245I01016",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532896",
                "SecurityId": "MAGNUM",
                "SecurityName": "MAGNUM VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE387I01016",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532897",
                "SecurityId": "HULBBPH",
                "SecurityName": "HULBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532898",
                "SecurityId": "POWERGRID",
                "SecurityName": "POWER GRID CORPORATION OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE752E01010",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532899",
                "SecurityId": "KSCL",
                "SecurityName": "KAVERI SEED COMPANY LTD.",
                "FaceValue": "2",
                "ISINNo": "INE455I01029",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532900",
                "SecurityId": "PAISALO",
                "SecurityName": "PAISALO DIGITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE420C01042",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532901",
                "SecurityId": "KOUTONS",
                "SecurityName": "KOUTONS RETAIL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE406I01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532902",
                "SecurityId": "CCCL",
                "SecurityName": "CONSOLIDATED CONSTRUCTION CONSORTIUM LTD.",
                "FaceValue": "2",
                "ISINNo": "INE429I01024",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532903",
                "SecurityId": "DHANUS",
                "SecurityName": "DHANUS TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE406H01016",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532904",
                "SecurityId": "SUPREMEINF",
                "SecurityName": "SUPREME INFRASTRUCTURE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE550H01011",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532905",
                "SecurityId": "SAAMYABIO",
                "SecurityName": "SAAMYA BIOTECH (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320H01019",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532906",
                "SecurityId": "MAANALU",
                "SecurityName": "MAAN ALUMINIUM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE215I01019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532907",
                "SecurityId": "IL&FSENGG",
                "SecurityName": "IL&FS ENGINEERING AND CONSTRUCTION COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE369I01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532908",
                "SecurityId": "SHARONBIO",
                "SecurityName": "SHARON BIO-MEDICINE LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE028B01029",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532909",
                "SecurityId": "GRABALALK",
                "SecurityName": "GRABAL ALOK IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE012D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532910",
                "SecurityId": "ANILLTD",
                "SecurityName": "ANIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE125E01019",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532911",
                "SecurityId": "PARLEIND",
                "SecurityName": "PARLE INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE272G01014",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532912",
                "SecurityId": "NET4",
                "SecurityName": "NET 4 INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE553E01012",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532913",
                "SecurityId": "CSIL",
                "SecurityName": "CIRCUIT SYSTEMS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE720H01010",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532914",
                "SecurityId": "ARCOTECH",
                "SecurityName": "ARCOTECH LTD.",
                "FaceValue": "2",
                "ISINNo": "INE574I01035",
                "Industry": "Copper",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532915",
                "SecurityId": "RELIGARE",
                "SecurityName": "RELIGARE ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE621H01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532916",
                "SecurityId": "BVCL",
                "SecurityName": "BARAK VALLEY CEMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE139I01011",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532917",
                "SecurityId": "VARUN",
                "SecurityName": "VARUN INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE032G01012",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532918",
                "SecurityId": "RATHIBAR",
                "SecurityName": "RATHI BARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE575I01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532919",
                "SecurityId": "ACIASIA",
                "SecurityName": "ALLIED COMPUTERS INTERNATIONAL (ASIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE335I01056",
                "Industry": "Computer Hardware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532920",
                "SecurityId": "EDL",
                "SecurityName": "EMPEE DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE180G01019",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532921",
                "SecurityId": "ADANIPORTS",
                "SecurityName": "ADANI PORTS AND SPECIAL ECONOMIC ZONE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE742F01042",
                "Industry": "Marine Port & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532922",
                "SecurityId": "EDELWEISS",
                "SecurityName": "EDELWEISS FINANCIAL SERVICES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE532F01054",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532923",
                "SecurityId": "RGL",
                "SecurityName": "RENAISSANCE GLOBAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE722H01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532924",
                "SecurityId": "KOLTEPATIL",
                "SecurityName": "KOLTE-PATIL DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE094I01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532925",
                "SecurityId": "KAUSHALYA",
                "SecurityName": "KAUSHALYA INFRASTRUCTURE DEVELOPMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE234I01010",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532926",
                "SecurityId": "JYOTHYLAB",
                "SecurityName": "JYOTHY LABS LTD",
                "FaceValue": "1",
                "ISINNo": "INE668F01031",
                "Industry": "Household Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532927",
                "SecurityId": "ECLERX",
                "SecurityName": "ECLERX SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE738I01010",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532928",
                "SecurityId": "TRIL",
                "SecurityName": "TRANSFORMERS AND RECTIFIERS (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE763I01026",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532929",
                "SecurityId": "BRIGADE",
                "SecurityName": "BRIGADE ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE791I01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532930",
                "SecurityId": "BGRENERGY",
                "SecurityName": "BGR ENERGY SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE661I01014",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532931",
                "SecurityId": "BURNPUR",
                "SecurityName": "BURNPUR CEMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE817H01014",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532932",
                "SecurityId": "MANAKSIA",
                "SecurityName": "MANAKSIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE015D01022",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532933",
                "SecurityId": "PORWAL",
                "SecurityName": "PORWAL AUTO COMPONENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE386I01018",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532934",
                "SecurityId": "PPAP",
                "SecurityName": "PPAP AUTOMOTIVE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE095I01015",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532935",
                "SecurityId": "ARIES",
                "SecurityName": "ARIES AGRO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE298I01015",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532937",
                "SecurityId": "KUANTUM",
                "SecurityName": "KUANTUM PAPERS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE529I01021",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532938",
                "SecurityId": "CAPF",
                "SecurityName": "CAPITAL FIRST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE688I01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532939",
                "SecurityId": "RPOWER",
                "SecurityName": "RELIANCE POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE614G01033",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532940",
                "SecurityId": "JKIL",
                "SecurityName": "J.KUMAR INFRAPROJECTS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE576I01022",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532941",
                "SecurityId": "CORDSCABLE",
                "SecurityName": "CORDS CABLE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE792I01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532942",
                "SecurityId": "KNRCON",
                "SecurityName": "KNR CONSTRUCTIONS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE634I01029",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532943",
                "SecurityId": "MDRASCMBBPH",
                "SecurityName": "MDRASCMBBPH",
                "FaceValue": "10",
                "ISINNo": "INE331A01029",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532944",
                "SecurityId": "ONMOBILE",
                "SecurityName": "ONMOBILE GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE809I01019",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532945",
                "SecurityId": "SHRIRAMEPC",
                "SecurityName": "SHRIRAM EPC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE964H01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532946",
                "SecurityId": "BANG",
                "SecurityName": "BANG OVERSEAS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE863I01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532947",
                "SecurityId": "IRB",
                "SecurityName": "IRB INFRASTRUCTURE DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE821I01014",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532948",
                "SecurityId": "TULSI",
                "SecurityName": "TULSI EXTRUSIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE474I01012",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532949",
                "SecurityId": "PIRAMGLAS",
                "SecurityName": "PIRAMAL GLASS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE748E01018",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532950",
                "SecurityId": "MANJUSHREE",
                "SecurityName": "MANJUSHREE TECHNOPACK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE435H01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532951",
                "SecurityId": "GSS",
                "SecurityName": "GSS INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE871H01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532952",
                "SecurityId": "NAHARCAP",
                "SecurityName": "NAHAR CAPITAL & FINANCIAL SERVICES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE049I01012",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532953",
                "SecurityId": "VGUARD",
                "SecurityName": "V-GUARD INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE951I01027",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532954",
                "SecurityId": "RELINFRBBPH",
                "SecurityName": "RELINFRBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532955",
                "SecurityId": "RECLTD",
                "SecurityName": "REC LTD",
                "FaceValue": "10",
                "ISINNo": "INE020B01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532956",
                "SecurityId": "SOLECTEMS",
                "SecurityName": "SOLECTRON EMS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE647I01013",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532957",
                "SecurityId": "GOKAKTEX",
                "SecurityName": "GOKAK TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE642I01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532958",
                "SecurityId": "ETCN",
                "SecurityName": "ETC NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE098J01017",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532959",
                "SecurityId": "GAMMNINFRA",
                "SecurityName": "GAMMON INFRASTRUCTURE PROJECTS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE181G01025",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532960",
                "SecurityId": "DHANI",
                "SecurityName": "DHANI SERVICES LTD",
                "FaceValue": "2",
                "ISINNo": "INE274G01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532961",
                "SecurityId": "SITASHREE",
                "SecurityName": "SITA SHREE FOOD PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE686I01011",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532962",
                "SecurityId": "DWSFTS43RG",
                "SecurityName": "DEUTSCHE MUTUAL FUND",
                "FaceValue": "10",
                "ISINNo": "INF223J01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532963",
                "SecurityId": "DWSFTS43RD",
                "SecurityName": "DEUTSCHE MUTUAL FUND",
                "FaceValue": "10",
                "ISINNo": "INF223J01028",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532964",
                "SecurityId": "DWSFTS43IG",
                "SecurityName": "DEUTSCHE MUTUAL FUND",
                "FaceValue": "10",
                "ISINNo": "INF223J01036",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532965",
                "SecurityId": "PATNIBBPH",
                "SecurityName": "PATNICOMPUT*",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532966",
                "SecurityId": "TWL",
                "SecurityName": "TITAGARH WAGONS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE615H01020",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532967",
                "SecurityId": "KIRIINDUS",
                "SecurityName": "KIRI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE415I01015",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532968",
                "SecurityId": "MASTKBBPH",
                "SecurityName": "MASTEK*",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532969",
                "SecurityId": "CHINVEST",
                "SecurityName": "CHI INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE975I01018",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532970",
                "SecurityId": "GREATOFBBPH",
                "SecurityName": "GREAT OFFSH*",
                "FaceValue": "10",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532971",
                "SecurityId": "SASKENBBPH",
                "SecurityName": "SASKEN COMM*",
                "FaceValue": "10",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532972",
                "SecurityId": "SANKHYAIN",
                "SecurityName": "SANKHYA INFOTECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE877A01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532973",
                "SecurityId": "GOLDIAMBBPH",
                "SecurityName": "GOLDIAM INT*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532974",
                "SecurityId": "BIRLAMONEY",
                "SecurityName": "ADITYA BIRLA MONEY LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE865C01022",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532975",
                "SecurityId": "AISHWARYA",
                "SecurityName": "AISHWARYA TECHNOLOGIES AND TELECOM LTD",
                "FaceValue": "5",
                "ISINNo": "INE778I01024",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532976",
                "SecurityId": "JAIBALAJI",
                "SecurityName": "JAI BALAJI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE091G01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532977",
                "SecurityId": "BAJAJ-AUTO",
                "SecurityName": "BAJAJ AUTO LTD.",
                "FaceValue": "10",
                "ISINNo": "INE917I01010",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532978",
                "SecurityId": "BAJAJFINSV",
                "SecurityName": "BAJAJ FINSERV LTD.",
                "FaceValue": "5",
                "ISINNo": "INE918I01018",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532979",
                "SecurityId": "PIRPHYTO",
                "SecurityName": "PIRAMAL PHYTOCARE LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE122J01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532980",
                "SecurityId": "GOKUL",
                "SecurityName": "GOKUL REFOILS & SOLVENT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE020J01029",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532981",
                "SecurityId": "ANULABS",
                "SecurityName": "ANU&#39;S LABORATORIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE450H01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532982",
                "SecurityId": "SRFLBBPH",
                "SecurityName": "SRF LIMITED*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532983",
                "SecurityId": "RPGLIFE",
                "SecurityName": "RPG LIFE SCIENCES LTD.",
                "FaceValue": "8",
                "ISINNo": "INE105J01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532984",
                "SecurityId": "ENSOSECUT",
                "SecurityName": "ENSO SECUTRACK LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE969A01018",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532985",
                "SecurityId": "KTKSENSEX",
                "SecurityName": "KOTAK SENSEX ETF",
                "FaceValue": "10",
                "ISINNo": "INF373I01031",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532986",
                "SecurityId": "NIRAJ",
                "SecurityName": "NIRAJ CEMENT STRUCTURALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE368I01016",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532987",
                "SecurityId": "RBL*",
                "SecurityName": "RANE BRAKE LINING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE244J01017",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532988",
                "SecurityId": "RANEENGINE",
                "SecurityName": "RANE ENGINE VALVE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE222J01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532989",
                "SecurityId": "BAFNAPHARM",
                "SecurityName": "BAFNA PHARMACEUTICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE878I01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532990",
                "SecurityId": "METKORE",
                "SecurityName": "METKORE ALLOYS & INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE592I01029",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532991",
                "SecurityId": "MVL",
                "SecurityName": "MVL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE744I01034",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532992",
                "SecurityId": "CHLLTD",
                "SecurityName": "CHL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE790D01020",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532993",
                "SecurityId": "SEZAL",
                "SecurityName": "SEZAL GLASS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE955I01036",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532994",
                "SecurityId": "ARCHIDPLY",
                "SecurityName": "ARCHIDPLY INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE877I01016",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532995",
                "SecurityId": "AVONCORP",
                "SecurityName": "AVON CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE207I01016",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532996",
                "SecurityId": "FIRSTWIN",
                "SecurityName": "FIRST WINNER INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE315J01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532997",
                "SecurityId": "KSK",
                "SecurityName": "KSK ENERGY VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE143H01015",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532998",
                "SecurityId": "LEHIL",
                "SecurityName": "LOTUS EYE HOSPITAL AND INSTITUTE LTD",
                "FaceValue": "10",
                "ISINNo": "INE947I01017",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "532999",
                "SecurityId": "NEXTGENT",
                "SecurityName": "NEXTGEN ANIMATION MEDIAA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE875I01010",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533000",
                "SecurityId": "ANGAUTOBBPH",
                "SecurityName": "ANG AUTO*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533001",
                "SecurityId": "SOMICONV",
                "SecurityName": "SOMI CONVEYOR BELTINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE323J01019",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533003",
                "SecurityId": "DWSFTS50AD",
                "SecurityName": "DEUTSCHE MUTUAL FUND - DWS FIXED TERM SERIES 50 - PLAN A - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF223J01069",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533005",
                "SecurityId": "DWSFTS50BD",
                "SecurityName": "DEUTSCHE MUTUAL FUND - DWS FIXED TERM SERIES 50 - PLAN B - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF223J01085",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533006",
                "SecurityId": "BIRLACOT",
                "SecurityName": "BIRLA COTSYN (INDIA) LTD.",
                "FaceValue": "1",
                "ISINNo": "INE655I01024",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533007",
                "SecurityId": "LGBFORGE",
                "SecurityName": "LGB FORGE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE201J01017",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533008",
                "SecurityId": "OISL",
                "SecurityName": "OCL IRON AND STEEL LTD.",
                "FaceValue": "1",
                "ISINNo": "INE196J01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533009",
                "SecurityId": "GDLBBPH",
                "SecurityName": "GATEWAYDIST*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533010",
                "SecurityId": "OCTAVINV",
                "SecurityName": "OCTAV INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE120J01019",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533011",
                "SecurityId": "CORAL-HUB",
                "SecurityName": "CORAL HUB LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE108J01030",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533012",
                "SecurityId": "LPDC",
                "SecurityName": "LANDMARK PROPERTY DEVELOPMENT COMPANY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE197J01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533013",
                "SecurityId": "GUJFLUOBBPH",
                "SecurityName": "GUJARAT FLU*",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533014",
                "SecurityId": "SICAGEN",
                "SecurityName": "SICAGEN INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE176J01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533015",
                "SecurityId": "NUTEK",
                "SecurityName": "NU TEK INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE318J01027",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533016",
                "SecurityId": "AUSTRAL",
                "SecurityName": "AUSTRAL COKE & PROJECTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE455J01027",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533017",
                "SecurityId": "RMMIL",
                "SecurityName": "RESURGERE MINES & MINERALS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE774I01031",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533018",
                "SecurityId": "SIMPLXMIL",
                "SecurityName": "SIMPLEX MILLS COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE457H01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533019",
                "SecurityId": "SIMPLXPAP",
                "SecurityName": "SIMPLEX PAPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE456H01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533020",
                "SecurityId": "RAINBBPH",
                "SecurityName": "RAIN COMMOD*",
                "FaceValue": "10",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533021",
                "SecurityId": "SURANABBPH",
                "SecurityName": "SURANA TELE*",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533022",
                "SecurityId": "20MICRONS",
                "SecurityName": "20 MICRONS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE144J01027",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533023",
                "SecurityId": "WABCOINDIA",
                "SecurityName": "WABCO INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE342J01019",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533024",
                "SecurityId": "VALIANTBBPH",
                "SecurityName": "VALIANT COM*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533025",
                "SecurityId": "HEGBBPH",
                "SecurityName": "HEG LIMITED*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533026",
                "SecurityId": "CHEMCEL",
                "SecurityName": "CHEMCEL BIO-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE213J01012",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533027",
                "SecurityId": "DLFBBPH",
                "SecurityName": "DLF LIMITED*",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533028",
                "SecurityId": "RSYSBBPH",
                "SecurityName": "RSYSTEM INT*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533029",
                "SecurityId": "ALKALI",
                "SecurityName": "ALKALI METALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE773I01017",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533030",
                "SecurityId": "MAESTROBBPH",
                "SecurityName": "MAESTROBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533031",
                "SecurityId": "FDCBBPH",
                "SecurityName": "FDC LIMITED*",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533032",
                "SecurityId": "IPCALABBBPH",
                "SecurityName": "IPCALABBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533033",
                "SecurityId": "ISGEC",
                "SecurityName": "ISGEC HEAVY ENGINEERING LTD",
                "FaceValue": "1",
                "ISINNo": "INE858B01029",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533034",
                "SecurityId": "GODRJCPBBPH",
                "SecurityName": "GODREJ CONS*",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533035",
                "SecurityId": "ALEMBICBBPH",
                "SecurityName": "ALEMBICBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533036",
                "SecurityId": "MONNETBBPH",
                "SecurityName": "MONNE ISPAT*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533037",
                "SecurityId": "SUPREMEBBPH",
                "SecurityName": "SUPREM IND*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533038",
                "SecurityId": "JINDPOLBBPH",
                "SecurityName": "JINDAL POLY*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533039",
                "SecurityId": "EIDPARYBBPH",
                "SecurityName": "EID PARRY*",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533040",
                "SecurityId": "BOSCHBBPH",
                "SecurityName": "BOSCHBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533041",
                "SecurityId": "IIFLBBPH",
                "SecurityName": "IIFLBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533042",
                "SecurityId": "SPRMPETBBPH",
                "SecurityName": "SPRMPETBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533043",
                "SecurityId": "NBVENTRBBPH",
                "SecurityName": "NAVBHAR VEN*",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533044",
                "SecurityId": "SELANBBPH",
                "SecurityName": "SELANBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533045",
                "SecurityId": "HOVBBPH",
                "SecurityName": "HOVBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533046",
                "SecurityId": "AUSTENGBBPH",
                "SecurityName": "AUSTENGBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533047",
                "SecurityId": "IMFA",
                "SecurityName": "INDIAN METALS & FERRO ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE919H01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533048",
                "SecurityId": "GISOLUTION",
                "SecurityName": "GI ENGINEERING SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE065J01016",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533049",
                "SecurityId": "MANGALCBBPH",
                "SecurityName": "MANGALAMCEM*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533050",
                "SecurityId": "GSSAMERBBPH",
                "SecurityName": "GSS AMERICA*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533051",
                "SecurityId": "HYDROSBBPH",
                "SecurityName": "HYDROSBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533052",
                "SecurityId": "TTKHEBBPH",
                "SecurityName": "TTKHEBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533053",
                "SecurityId": "LKPFINBBPH",
                "SecurityName": "LKP FIN*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533054",
                "SecurityId": "KLBRENGBBPH",
                "SecurityName": "KLBRENGBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533055",
                "SecurityId": "EDSERV",
                "SecurityName": "EDSERV SOFTSYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE889J01019",
                "Industry": "IT Training Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533056",
                "SecurityId": "VEDAVAAG",
                "SecurityName": "VEDAVAAG SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE359B01010",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533057",
                "SecurityId": "TVTNBBPH",
                "SecurityName": "TV TODAY*",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533058",
                "SecurityId": "ZENTECBBPH",
                "SecurityName": "ZENTECBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533059",
                "SecurityId": "BRANDHOUSE",
                "SecurityName": "BRANDHOUSE RETAILS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE317J01011",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533060",
                "SecurityId": "SANDESHBBPH",
                "SecurityName": "SANDESHBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533061",
                "SecurityId": "APLOTYRBBPH",
                "SecurityName": "APLOTYRBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533062",
                "SecurityId": "GITNJLIBBPH",
                "SecurityName": "GITNJLIBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533065",
                "SecurityId": "REISIXTEN",
                "SecurityName": "REI SIX TEN RETAIL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE849J01021",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533066",
                "SecurityId": "IBVENBBPH",
                "SecurityName": "IBVENBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533067",
                "SecurityId": "PENARINBBPH",
                "SecurityName": "PENARINBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533068",
                "SecurityId": "ARROWTEX",
                "SecurityName": "ARROW TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE933J01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533069",
                "SecurityId": "AVANTELBBPH",
                "SecurityName": "AVANTEL LTD*",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533070",
                "SecurityId": "DAICHIBBPH",
                "SecurityName": "DAICHIBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533071",
                "SecurityId": "GODRJBBPH",
                "SecurityName": "GODREJ IND*",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533078",
                "SecurityId": "MANJEERA",
                "SecurityName": "MANJEERA CONSTRUCTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320D01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533079",
                "SecurityId": "SANDPLAST",
                "SecurityName": "SAND PLAST (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122K01013",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533080",
                "SecurityId": "MOLDTKPAC",
                "SecurityName": "MOLD-TEK PACKAGING LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE893J01029",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533081",
                "SecurityId": "MERCKBBPH",
                "SecurityName": "MERCKBBPH",
                "FaceValue": "10",
                "ISINNo": "INE199A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533082",
                "SecurityId": "AROGRANBBPH",
                "SecurityName": "AROGRANBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533083",
                "SecurityId": "RDEVCAB",
                "SecurityName": "RISHABHDEV TECHNOCABLE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE685F01019",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533084",
                "SecurityId": "SM1AD",
                "SecurityName": "TATA SMART INVESTMENT PLAN- 1 SCHEME A - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K01022",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533085",
                "SecurityId": "SM1AG",
                "SecurityName": "TATA SMART INVESTMENT PLAN- 1 SCHEME A - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533086",
                "SecurityId": "SM1BD",
                "SecurityName": "TATA SMART INVESTMENT PLAN- 1 SCHEME B - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K01048",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533087",
                "SecurityId": "SM1BG",
                "SecurityName": "TATA SMART INVESTMENT PLAN- 1 SCHEME B - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K01030",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533088",
                "SecurityId": "MHRIL",
                "SecurityName": "MAHINDRA HOLIDAYS & RESORTS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE998I01010",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533089",
                "SecurityId": "TELEDATAIT",
                "SecurityName": "TELEDATA TECHNOLOGY SOLUTIONS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE391I01018",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533090",
                "SecurityId": "EXCEL",
                "SecurityName": "EXCEL REALTY N INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE688J01015",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533091",
                "SecurityId": "DCHLBBPH",
                "SecurityName": "DCHLBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533092",
                "SecurityId": "AEGISLOBBPH",
                "SecurityName": "AEGISLOBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533093",
                "SecurityId": "RAJOIL",
                "SecurityName": "RAJ OIL MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE294G01026",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533094",
                "SecurityId": "JINDLPOBBPH",
                "SecurityName": "JINDLPOBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533095",
                "SecurityId": "BENGALASM",
                "SecurityName": "BENGAL & ASSAM COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE083K01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533096",
                "SecurityId": "ADANIPOWER",
                "SecurityName": "ADANI POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE814H01011",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533097",
                "SecurityId": "SOFTSOLBBPH",
                "SecurityName": "SOFTSOLBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533098",
                "SecurityId": "NHPC",
                "SecurityName": "NHPC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE848E01016",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533099",
                "SecurityId": "PROVOGUBBPH",
                "SecurityName": "PROVOGUBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533100",
                "SecurityId": "PEIL",
                "SecurityName": "PREMIER ENERGY AND INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE429K01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533101",
                "SecurityId": "SURYAAMBA",
                "SecurityName": "SURYAAMBA SPINNING MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE360J01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533102",
                "SecurityId": "SRFBBPH",
                "SecurityName": "SRFBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533103",
                "SecurityId": "JINDCOT",
                "SecurityName": "JINDAL COTEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE904J01016",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533104",
                "SecurityId": "GLOBUSSPR",
                "SecurityName": "GLOBUS SPIRITS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE615I01010",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533106",
                "SecurityId": "OIL",
                "SecurityName": "OIL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE274J01014",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533107",
                "SecurityId": "RNAVAL",
                "SecurityName": "RELIANCE NAVAL AND ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE542F01012",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533108",
                "SecurityId": "BTTL",
                "SecurityName": "BHILWARA TECHNICAL TEXTILES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE274K01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533109",
                "SecurityId": "EUROMULTI",
                "SecurityName": "EURO MULTIVISION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE063J01011",
                "Industry": "Storage Media & Peripherals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533110",
                "SecurityId": "SPSL",
                "SecurityName": "SHREE PRECOATED STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE318K01025",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533120",
                "SecurityId": "BHAGYINBBPH",
                "SecurityName": "BHAGYINBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533121",
                "SecurityId": "EXPLEOSOL",
                "SecurityName": "EXPLEO SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE201K01015",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533122",
                "SecurityId": "RTNPOWER",
                "SecurityName": "RATTANINDIA POWER LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE399K01017",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533124",
                "SecurityId": "GOLDIMBBPH",
                "SecurityName": "GOLDIMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533130",
                "SecurityId": "PODDARBBPH",
                "SecurityName": "PODDARBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533137",
                "SecurityId": "DEN",
                "SecurityName": "DEN NETWORKS LTD",
                "FaceValue": "10",
                "ISINNo": "INE947J01015",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533138",
                "SecurityId": "ASTEC",
                "SecurityName": "ASTEC LIFESCIENCES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE563J01010",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533143",
                "SecurityId": "RBN",
                "SecurityName": "RELIANCE BROADCAST NETWORK LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE445K01018",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533144",
                "SecurityId": "COX&KINGS",
                "SecurityName": "COX & KINGS LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE008I01026",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533145",
                "SecurityId": "APCOTEXBBPH",
                "SecurityName": "APCOTEXBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533146",
                "SecurityId": "DLINKINDIA",
                "SecurityName": "D-LINK (INDIA) LTD",
                "FaceValue": "2",
                "ISINNo": "INE250K01012",
                "Industry": "IT Networking Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533147",
                "SecurityId": "BRITBRBBPH",
                "SecurityName": "BRITBRBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533148",
                "SecurityId": "JSWENERGY",
                "SecurityName": "JSW ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE121E01018",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533149",
                "SecurityId": "ESSARSEC",
                "SecurityName": "ESSAR SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE143K01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533150",
                "SecurityId": "GODREJPROP",
                "SecurityName": "GODREJ PROPERTIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE484J01027",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533151",
                "SecurityId": "DBCORP",
                "SecurityName": "D B CORP LTD",
                "FaceValue": "10",
                "ISINNo": "INE950I01011",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533152",
                "SecurityId": "MBLINFRA",
                "SecurityName": "MBL INFRASTRUCTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE912H01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533153",
                "SecurityId": "FDCLTDBBPH",
                "SecurityName": "FDCLTDBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533154",
                "SecurityId": "INFINITE",
                "SecurityName": "INFINITE COMPUTER SOLUTIONS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE486J01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533155",
                "SecurityId": "JUBLFOOD",
                "SecurityName": "JUBILANT FOODWORKS LTD",
                "FaceValue": "10",
                "ISINNo": "INE797F01012",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533156",
                "SecurityId": "VASCONEQ",
                "SecurityName": "VASCON ENGINEERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE893I01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533157",
                "SecurityId": "SYNCOM",
                "SecurityName": "SYNCOM HEALTHCARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE602K01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533158",
                "SecurityId": "THANGAMAYL",
                "SecurityName": "THANGAMAYIL JEWELLERY LTD",
                "FaceValue": "10",
                "ISINNo": "INE085J01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533159",
                "SecurityId": "AQUA",
                "SecurityName": "AQUA LOGISTICS LTD",
                "FaceValue": "1",
                "ISINNo": "INE544K01026",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533160",
                "SecurityId": "DBREALTY",
                "SecurityName": "D B REALTY LTD",
                "FaceValue": "10",
                "ISINNo": "INE879I01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533161",
                "SecurityId": "EMMBI",
                "SecurityName": "EMMBI INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE753K01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533162",
                "SecurityId": "HATHWAY",
                "SecurityName": "HATHWAY CABLE & DATACOM LTD",
                "FaceValue": "2",
                "ISINNo": "INE982F01036",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533163",
                "SecurityId": "ARSSINFRA",
                "SecurityName": "ARSS INFRASTRUCTURE PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE267I01010",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533164",
                "SecurityId": "TEXMOPIPES",
                "SecurityName": "TEXMO PIPES & PRODUCTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE141K01013",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533165",
                "SecurityId": "TIPSBBPH",
                "SecurityName": "TIPSBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533166",
                "SecurityId": "SUNDARAM",
                "SecurityName": "SUNDARAM MULTI PAP LTD.",
                "FaceValue": "1",
                "ISINNo": "INE108E01023",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533167",
                "SecurityId": "COROENGG",
                "SecurityName": "COROMANDEL ENGINEERING COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE312J01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533168",
                "SecurityId": "ROSSELLIND",
                "SecurityName": "ROSSELL INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE847C01020",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533169",
                "SecurityId": "MANINFRA",
                "SecurityName": "MAN INFRACONSTRUCTION LTD.",
                "FaceValue": "2",
                "ISINNo": "INE949H01023",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533170",
                "SecurityId": "TAMBOLI",
                "SecurityName": "TAMBOLI CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE864J01012",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533171",
                "SecurityId": "UNITEDBNK",
                "SecurityName": "UNITED BANK OF INDIA",
                "FaceValue": "10",
                "ISINNo": "INE695A01019",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533176",
                "SecurityId": "DQE",
                "SecurityName": "DQ ENTERTAINMENT (INTERNATIONAL) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE656K01010",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533177",
                "SecurityId": "IL&FSTRANS",
                "SecurityName": "IL&FS TRANSPORTATION NETWORKS LTD",
                "FaceValue": "10",
                "ISINNo": "INE975G01012",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533178",
                "SecurityId": "PRADIP",
                "SecurityName": "PRADIP OVERSEAS LTD",
                "FaceValue": "10",
                "ISINNo": "INE495J01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533179",
                "SecurityId": "PERSISTENT",
                "SecurityName": "PERSISTENT SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE262H01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533180",
                "SecurityId": "SGJHL",
                "SecurityName": "SHREE GANESH JEWELLERY HOUSE (I) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE553K01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533181",
                "SecurityId": "ISFT",
                "SecurityName": "INTRASOFT TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE566K01011",
                "Industry": "Internet & Catalogue Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533189",
                "SecurityId": "GOENKA",
                "SecurityName": "GOENKA DIAMOND & JEWELS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE516K01024",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533192",
                "SecurityId": "KCPSUGIND",
                "SecurityName": "KCP SUGAR & INDUSTRIES CORPORATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE790B01024",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533193",
                "SecurityId": "KECL",
                "SecurityName": "KIRLOSKAR ELECTRIC COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE134B01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533200",
                "SecurityId": "TALWALKARS",
                "SecurityName": "TALWALKARS BETTER VALUE FITNESS LTD",
                "FaceValue": "10",
                "ISINNo": "INE502K01016",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533201",
                "SecurityId": "MNAKSIABBPH",
                "SecurityName": "MNAKSIABBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533202",
                "SecurityId": "NEH",
                "SecurityName": "NEL HOLDINGS SOUTH LTD",
                "FaceValue": "10",
                "ISINNo": "INE639K01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533203",
                "SecurityId": "TARAPUR",
                "SecurityName": "TARAPUR TRANSFORMERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE747K01017",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533204",
                "SecurityId": "GBGLOBAL",
                "SecurityName": "GB GLOBAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE087J01028",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533205",
                "SecurityId": "GEODLTDBBPH",
                "SecurityName": "GEODLTDBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533206",
                "SecurityId": "SJVN",
                "SecurityName": "SJVN LTD",
                "FaceValue": "10",
                "ISINNo": "INE002L01015",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533207",
                "SecurityId": "JPINFRATEC",
                "SecurityName": "JAYPEE INFRATECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE099J01015",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533208",
                "SecurityId": "EMAMIPAP",
                "SecurityName": "EMAMI PAPER MILLS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE830C01026",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533209",
                "SecurityId": "SAMRUDDHI",
                "SecurityName": "SAMRUDDHI CEMENT LTD.",
                "FaceValue": "5",
                "ISINNo": "INE676K01018",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533210",
                "SecurityId": "KRITINUT",
                "SecurityName": "KRITI NUTRIENTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE798K01010",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533211",
                "SecurityId": "PARABDRUGS",
                "SecurityName": "PARABOLIC DRUGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE618H01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533212",
                "SecurityId": "GKB",
                "SecurityName": "GKB OPHTHALMICS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE265D01015",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533213",
                "SecurityId": "FRONTSEC",
                "SecurityName": "FRONTLINE SECURITIES LTD.",
                "FaceValue": "5",
                "ISINNo": "INE773B01020",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533214",
                "SecurityId": "PANACEABBPH",
                "SecurityName": "PANACEABBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533216",
                "SecurityId": "TECHNOFAB",
                "SecurityName": "TECHNOFAB ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE509K01011",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533217",
                "SecurityId": "HMVL",
                "SecurityName": "HINDUSTAN MEDIA VENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE871K01015",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533218",
                "SecurityId": "EMAMIREAL",
                "SecurityName": "EMAMI REALTY LTD",
                "FaceValue": "2",
                "ISINNo": "INE778K01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533219",
                "SecurityId": "SHRIASTER",
                "SecurityName": "SHRI ASTER SILICATES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE900K01012",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533220",
                "SecurityId": "MIDFIELD",
                "SecurityName": "MIDFIELD INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE091K01010",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533221",
                "SecurityId": "AHLWEST",
                "SecurityName": "ASIAN HOTELS (WEST) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE915K01010",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533226",
                "SecurityId": "HULTDBBPH",
                "SecurityName": "HULTDBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533227",
                "SecurityId": "AHLEAST",
                "SecurityName": "ASIAN HOTELS (EAST) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE926K01017",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533228",
                "SecurityId": "BHARATFIN",
                "SecurityName": "BHARAT FINANCIAL INCLUSION LTD",
                "FaceValue": "10",
                "ISINNo": "INE180K01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533229",
                "SecurityId": "BAJAJCON",
                "SecurityName": "BAJAJ CONSUMER CARE LTD",
                "FaceValue": "1",
                "ISINNo": "INE933K01021",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533239",
                "SecurityId": "PRAKASHSTL",
                "SecurityName": "PRAKASH STEELAGE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE696K01024",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533240",
                "SecurityId": "CNSDSECBBPH",
                "SecurityName": "CNSDSECBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533248",
                "SecurityId": "GPPL",
                "SecurityName": "GUJARAT PIPAVAV PORT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE517F01014",
                "Industry": "Marine Port & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533252",
                "SecurityId": "WELINV",
                "SecurityName": "WELSPUN INVESTMENTS AND COMMERCIALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE389K01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533256",
                "SecurityId": "HIRAFERRO",
                "SecurityName": "HIRA FERRO ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE573I01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533257",
                "SecurityId": "INDOSOLAR",
                "SecurityName": "INDOSOLAR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE866K01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533258",
                "SecurityId": "TIRUPATIINK",
                "SecurityName": "TIRUPATI INKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE493K01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533259",
                "SecurityId": "SASTASUNDR",
                "SecurityName": "SASTASUNDAR VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE019J01013",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533260",
                "SecurityId": "CAREERP",
                "SecurityName": "CAREER POINT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE521J01018",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533261",
                "SecurityId": "EROSMEDIA",
                "SecurityName": "EROS INTERNATIONAL MEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE416L01017",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533262",
                "SecurityId": "RAMKY",
                "SecurityName": "RAMKY INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE874I01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533263",
                "SecurityId": "GREENPOWER",
                "SecurityName": "ORIENT GREEN POWER COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE999K01014",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533264",
                "SecurityId": "ESL",
                "SecurityName": "ELECTROSTEEL STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE481K01021",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533265",
                "SecurityId": "GALLISPAT",
                "SecurityName": "GALLANTT ISPAT LTD.",
                "FaceValue": "1",
                "ISINNo": "INE528K01029",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533266",
                "SecurityId": "TECPRO",
                "SecurityName": "TECPRO SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE904H01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533267",
                "SecurityId": "CANTABIL",
                "SecurityName": "CANTABIL RETAIL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE068L01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533268",
                "SecurityId": "SEATV",
                "SecurityName": "SEA TV NETWORK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE351L01016",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533269",
                "SecurityId": "WABAG",
                "SecurityName": "VA TECH WABAG LTD.",
                "FaceValue": "2",
                "ISINNo": "INE956G01038",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533270",
                "SecurityId": "BEDMUTHA",
                "SecurityName": "BEDMUTHA INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE844K01012",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533271",
                "SecurityId": "ASHOKA",
                "SecurityName": "ASHOKA BUILDCON LTD.",
                "FaceValue": "5",
                "ISINNo": "INE442H01029",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533272",
                "SecurityId": "CEBBCO",
                "SecurityName": "COMMERCIAL ENGINEERS & BODY BUILDERS CO. LTD.",
                "FaceValue": "10",
                "ISINNo": "INE209L01016",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533273",
                "SecurityId": "OBEROIRLTY",
                "SecurityName": "OBEROI REALTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE093I01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533274",
                "SecurityId": "PRESTIGE",
                "SecurityName": "PRESTIGE ESTATES PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE811K01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533275",
                "SecurityId": "GAL",
                "SecurityName": "GYSCOAL ALLOYS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE482J01021",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533276",
                "SecurityId": "BSLIMITED",
                "SecurityName": "BS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE043K01029",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533277",
                "SecurityId": "CRISILBBPH",
                "SecurityName": "CRISILBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533278",
                "SecurityId": "COALINDIA",
                "SecurityName": "COAL INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE522F01014",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533281",
                "SecurityId": "TECHNO",
                "SecurityName": "TECHNO ELECTRIC AND ENGINEERING CO. LTD.",
                "FaceValue": "2",
                "ISINNo": "INE286K01024",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533282",
                "SecurityId": "GRAVITA",
                "SecurityName": "GRAVITA INDIA LTD.",
                "FaceValue": "2",
                "ISINNo": "INE024L01027",
                "Industry": "Other Non-Ferrous Metals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533283",
                "SecurityId": "SASKENBBPH",
                "SecurityName": "SASKENBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533284",
                "SecurityId": "RPPINFRA",
                "SecurityName": "RPP INFRA PROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE324L01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533285",
                "SecurityId": "RDBRIL",
                "SecurityName": "RDB REALTY & INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE245L01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533286",
                "SecurityId": "MOIL",
                "SecurityName": "MOIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE490G01020",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533287",
                "SecurityId": "ZEELEARN",
                "SecurityName": "ZEE LEARN LTD.",
                "FaceValue": "1",
                "ISINNo": "INE565L01011",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533288",
                "SecurityId": "CLARIS",
                "SecurityName": "CLARIS LIFESCIENCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE562G01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533289",
                "SecurityId": "KTIL",
                "SecurityName": "KESAR TERMINALS & INFRASTRUCTURE LTD.",
                "FaceValue": "5",
                "ISINNo": "INE096L01025",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533292",
                "SecurityId": "A2ZINFRA",
                "SecurityName": "A2Z INFRA ENGINEERING LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE619I01012",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533293",
                "SecurityId": "KIRLOSENG",
                "SecurityName": "KIRLOSKAR OIL ENGINES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE146L01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533294",
                "SecurityId": "RKDL",
                "SecurityName": "RAVI KUMAR DISTILLERIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE722J01012",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533295",
                "SecurityId": "PSB",
                "SecurityName": "PUNJAB & SIND BANK",
                "FaceValue": "10",
                "ISINNo": "INE608A01012",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533296",
                "SecurityId": "FMNL",
                "SecurityName": "FUTURE MARKET NETWORKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE360L01017",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533297",
                "SecurityId": "KBIL",
                "SecurityName": "KIRLOSKAR BROTHERS INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE920K01010",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533298",
                "SecurityId": "SURANASOL",
                "SecurityName": "SURANA SOLAR LIMITED",
                "FaceValue": "5",
                "ISINNo": "INE272L01022",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533301",
                "SecurityId": "SPYL",
                "SecurityName": "SHEKHAWATI POLY-YARN LTD.",
                "FaceValue": "1",
                "ISINNo": "INE268L01020",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533302",
                "SecurityId": "KICL",
                "SecurityName": "KALYANI INVESTMENT COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE029L01018",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533303",
                "SecurityId": "BFINVEST",
                "SecurityName": "BF INVESTMENT LTD.",
                "FaceValue": "5",
                "ISINNo": "INE878K01010",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533304",
                "SecurityId": "CMAHENDRA",
                "SecurityName": "C. MAHENDRA EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE378J01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533305",
                "SecurityId": "SRSREAL",
                "SecurityName": "SRS REAL INFRASTRUCTURE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE953I01023",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533306",
                "SecurityId": "SUMMITSEC",
                "SecurityName": "SUMMIT SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE519C01017",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533309",
                "SecurityId": "DALMIABHA",
                "SecurityName": "DALMIA BHARAT LTD.",
                "FaceValue": "2",
                "ISINNo": "INE439L01019",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533310",
                "SecurityId": "MIDVAL",
                "SecurityName": "MIDVALLEY ENTERTAINMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE422B01016",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533313",
                "SecurityId": "HINCOMBBPH",
                "SecurityName": "HINCOMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533314",
                "SecurityId": "AGSINFO",
                "SecurityName": "AGS INFOTECH LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE966E01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533315",
                "SecurityId": "INOVSYNTH",
                "SecurityName": "INNOVASSYNTH INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE690J01011",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533316",
                "SecurityId": "STEL",
                "SecurityName": "STEL HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE577L01016",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533317",
                "SecurityId": "OMKARCHEM",
                "SecurityName": "OMKAR SPECIALITY CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE474L01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533320",
                "SecurityId": "JUBLINDS",
                "SecurityName": "JUBILANT INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE645L01011",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533321",
                "SecurityId": "BILENERGY",
                "SecurityName": "BIL ENERGY SYSTEMS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE607L01029",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533326",
                "SecurityId": "TEXRAIL",
                "SecurityName": "TEXMACO RAIL & ENGINEERING LTD.",
                "FaceValue": "1",
                "ISINNo": "INE621L01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533329",
                "SecurityId": "INDTERRAIN",
                "SecurityName": "INDIAN TERRAIN FASHIONS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE611L01021",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533330",
                "SecurityId": "ACROPETAL",
                "SecurityName": "ACROPETAL TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE055L01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533331",
                "SecurityId": "BCMLBBPH",
                "SecurityName": "BCMLBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533332",
                "SecurityId": "SUDAR",
                "SecurityName": "SUDAR INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE384L01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533333",
                "SecurityId": "FCL",
                "SecurityName": "FINEOTEX CHEMICAL LTD.",
                "FaceValue": "2",
                "ISINNo": "INE045J01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533336",
                "SecurityId": "DHUNINV",
                "SecurityName": "DHUNSERI INVESTMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320L01011",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533339",
                "SecurityId": "ZENTEC",
                "SecurityName": "ZEN TECHNOLOGIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE251B01027",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533343",
                "SecurityId": "LOVABLE",
                "SecurityName": "LOVABLE LINGERIE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE597L01014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533344",
                "SecurityId": "PFS",
                "SecurityName": "PTC INDIA FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE560K01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533388",
                "SecurityId": "RELINFBBPH",
                "SecurityName": "RELINFBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533389",
                "SecurityId": "SHILPI",
                "SecurityName": "SHILPI CABLE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE510K01019",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533390",
                "SecurityId": "HEGLTDBBPH",
                "SecurityName": "HEGLTDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533393",
                "SecurityId": "TCIDEVELOP",
                "SecurityName": "TCI DEVELOPERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE662L01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533396",
                "SecurityId": "ADSLBBPH",
                "SecurityName": "ADSLBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533397",
                "SecurityId": "ICSILBBPH",
                "SecurityName": "ICSILBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533398",
                "SecurityId": "MUTHOOTFIN",
                "SecurityName": "MUTHOOT FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE414G01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533399",
                "SecurityId": "PARAPRINT",
                "SecurityName": "PARAMOUNT PRINTPACKAGING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE702L01010",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533400",
                "SecurityId": "FCONSUMER",
                "SecurityName": "FUTURE CONSUMER LTD",
                "FaceValue": "6",
                "ISINNo": "INE220J01025",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533401",
                "SecurityId": "SERVALL",
                "SecurityName": "SERVALAKSHMI PAPER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE431L01016",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533402",
                "SecurityId": "INNOIND",
                "SecurityName": "INNOVENTIVE INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE549I01011",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533407",
                "SecurityId": "CNOVAPETRO",
                "SecurityName": "CIL NOVA PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE672K01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533411",
                "SecurityId": "SANGHVIFOR",
                "SecurityName": "SANGHVI FORGING AND ENGINEERING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE263L01013",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533412",
                "SecurityId": "DRDATSONS",
                "SecurityName": "DR.DATSONS LABS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE928K01013",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533427",
                "SecurityId": "VMS",
                "SecurityName": "VMS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE932K01015",
                "Industry": "Marine Port & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533444",
                "SecurityId": "TIMBOR",
                "SecurityName": "TIMBOR HOME LTD.",
                "FaceValue": "10",
                "ISINNo": "INE346L01016",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533451",
                "SecurityId": "KARMAENG",
                "SecurityName": "KARMA ENERGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE725L01011",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533452",
                "SecurityId": "EBIXFOREX",
                "SecurityName": "EBIXCASH WORLD MONEY INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE726L01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533453",
                "SecurityId": "PVRBBPH",
                "SecurityName": "PVRBBPH",
                "FaceValue": "10",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533462",
                "SecurityId": "TELEMARINE",
                "SecurityName": "TELEDATA MARINE SOLUTIONS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE473I01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533469",
                "SecurityId": "BIRLAPAC",
                "SecurityName": "BIRLA PACIFIC MEDSPA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE341L01017",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533470",
                "SecurityId": "RUSHIL",
                "SecurityName": "RUSHIL DECOR LTD.",
                "FaceValue": "10",
                "ISINNo": "INE573K01017",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533477",
                "SecurityId": "ENKEIWHEL",
                "SecurityName": "ENKEI WHEELS (INDIA) LTD.",
                "FaceValue": "5",
                "ISINNo": "INE130L01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533482",
                "SecurityId": "KRIINFRA",
                "SecurityName": "KRIDHAN INFRA LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE524L01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533495",
                "SecurityId": "WELGLOB",
                "SecurityName": "WELSPUN GLOBAL BRANDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE390K01016",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533498",
                "SecurityId": "ZEEENTBBPH",
                "SecurityName": "ZEEENTBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533499",
                "SecurityId": "BGLOBAL",
                "SecurityName": "BHARATIYA GLOBAL INFOMEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE224M01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533506",
                "SecurityId": "INVENTURE",
                "SecurityName": "INVENTURE GROWTH & SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE878H01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533519",
                "SecurityId": "L&TFH",
                "SecurityName": "L&T FINANCE HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE498L01015",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533520",
                "SecurityId": "YAARII",
                "SecurityName": "YAARII DIGITAL INTEGRATED SERVICES LTD",
                "FaceValue": "2",
                "ISINNo": "INE126M01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533525",
                "SecurityId": "HEALTHTECH",
                "SecurityName": "HEALTHFORE TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE402L01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533540",
                "SecurityId": "TREEHOUSE",
                "SecurityName": "TREE HOUSE EDUCATION & ACCESSORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE040M01013",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533543",
                "SecurityId": "BROOKS",
                "SecurityName": "BROOKS LABORATORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE650L01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533552",
                "SecurityId": "RUPA",
                "SecurityName": "RUPA & COMPANY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE895B01021",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533553",
                "SecurityId": "TDPOWERSYS",
                "SecurityName": "T D POWER SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE419M01019",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533562",
                "SecurityId": "ONMOBBBPH",
                "SecurityName": "ONMOBBBPH",
                "FaceValue": "10",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533569",
                "SecurityId": "SRSLTD",
                "SecurityName": "SRS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE219H01039",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533573",
                "SecurityId": "APLLTD",
                "SecurityName": "ALEMBIC PHARMACEUTICALS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE901L01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533576",
                "SecurityId": "VASWANI",
                "SecurityName": "VASWANI INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE590L01019",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533581",
                "SecurityId": "PGEL",
                "SecurityName": "PG ELECTROPLAST LTD.",
                "FaceValue": "10",
                "ISINNo": "INE457L01011",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533602",
                "SecurityId": "LESHAIND",
                "SecurityName": "LESHA INDUSTRIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE050L01048",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533605",
                "SecurityId": "SETUINFRA",
                "SecurityName": "SETUBANDHAN INFRASTRUCTURE LTD",
                "FaceValue": "1",
                "ISINNo": "INE023M01027",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533608",
                "SecurityId": "RDBRL",
                "SecurityName": "RDB RASAYANS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE123M01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533617",
                "SecurityId": "EONBBPH",
                "SecurityName": "EONBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533629",
                "SecurityId": "TIJARIA",
                "SecurityName": "TIJARIA POLYPIPES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE440L01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533632",
                "SecurityId": "ONELIFECAP",
                "SecurityName": "ONELIFE CAPITAL ADVISORS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE912L01015",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533638",
                "SecurityId": "FLEXITUFF",
                "SecurityName": "FLEXITUFF VENTURES INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE060J01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533639",
                "SecurityId": "TAKSHEEL",
                "SecurityName": "TAKSHEEL SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE889I01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533644",
                "SecurityId": "UJAAS",
                "SecurityName": "UJAAS ENERGY LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE899L01022",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533655",
                "SecurityId": "TRITURBINE",
                "SecurityName": "TRIVENI TURBINE LTD.",
                "FaceValue": "1",
                "ISINNo": "INE152M01016",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533676",
                "SecurityId": "INDOTHAI",
                "SecurityName": "INDO THAI SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE337M01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533699",
                "SecurityId": "RAINCOBBPH",
                "SecurityName": "RAINCOBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533704",
                "SecurityId": "ESSARSHPNG",
                "SecurityName": "ESSAR SHIPPING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE122M01019",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533711",
                "SecurityId": "GEMINIBBPH",
                "SecurityName": "GEMINIBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533748",
                "SecurityId": "AVANTEBBPH",
                "SecurityName": "AVANTEBBPH",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533758",
                "SecurityId": "APLAPOLLO",
                "SecurityName": "APL APOLLO TUBES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE702C01027",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533761",
                "SecurityId": "GPTINFRA",
                "SecurityName": "GPT INFRAPROJECTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE390G01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533790",
                "SecurityId": "KGNENT",
                "SecurityName": "KGN ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE755B01019",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533799",
                "SecurityId": "PRAJINDBBPH",
                "SecurityName": "PRAJINDBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533809",
                "SecurityId": "BOROSILBBPH",
                "SecurityName": "BOROSILBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533868",
                "SecurityId": "ANSALHSBBPH",
                "SecurityName": "ANSALHSBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533888",
                "SecurityId": "RELBANK",
                "SecurityName": "R* SHARES BANKING EXCHANGE TRADED FUND",
                "FaceValue": "10",
                "ISINNo": "INF733I01028",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533896",
                "SecurityId": "FERVENTSYN",
                "SecurityName": "FERVENT SYNERGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE258M01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533906",
                "SecurityId": "AMTEKBBPH",
                "SecurityName": "AMTEKBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533911",
                "SecurityId": "VALCOMBBPH",
                "SecurityName": "VALCOMBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533941",
                "SecurityId": "THOMASCOTT",
                "SecurityName": "THOMAS SCOTT (INDIA) LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE480M01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533960",
                "SecurityId": "RELINDBBPH",
                "SecurityName": "RELINDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533982",
                "SecurityId": "TERASOFT",
                "SecurityName": "TERA SOFTWARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE482B01010",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "533983",
                "SecurityId": "GEECEEBBPH",
                "SecurityName": "GEECEEBBPH",
                "FaceValue": "10",
                "ISINNo": "INE916G01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534015",
                "SecurityId": "ACCELYABBPH",
                "SecurityName": "ACCELYABBPH",
                "FaceValue": "10",
                "ISINNo": "INE793A01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534026",
                "SecurityId": "KIROILBBPH",
                "SecurityName": "KIROILBBPH",
                "FaceValue": "2",
                "ISINNo": "INE146L01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534044",
                "SecurityId": "JKLAKBBPH",
                "SecurityName": "JKLAKBBPH",
                "FaceValue": "5",
                "ISINNo": "INE786A01032",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534060",
                "SecurityId": "PMCFIN",
                "SecurityName": "PMC FINCORP LIMITED",
                "FaceValue": "1",
                "ISINNo": "INE793G01035",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534063",
                "SecurityId": "FUTSOL",
                "SecurityName": "FUTURISTIC SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE241F01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534064",
                "SecurityId": "AIML",
                "SecurityName": "ALLIANCE INTEGRATED METALIKS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE123D01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534076",
                "SecurityId": "ORIENTREF",
                "SecurityName": "ORIENT REFRACTORIES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE743M01012",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534091",
                "SecurityId": "MCX",
                "SecurityName": "MULTI COMMODITY EXCHANGE OF INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE745G01035",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534109",
                "SecurityId": "PYXISFIN",
                "SecurityName": "PYXIS FINVEST LTD",
                "FaceValue": "10",
                "ISINNo": "INE883L01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534125",
                "SecurityId": "JSWISPLBBPH",
                "SecurityName": "JSWISPLBBPH",
                "FaceValue": "10",
                "ISINNo": "INEPHYC01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534139",
                "SecurityId": "SCHNEIDER",
                "SecurityName": "SCHNEIDER ELECTRIC INFRASTRUCTURE LTD.",
                "FaceValue": "2",
                "ISINNo": "INE839M01018",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534184",
                "SecurityId": "NAGAROIL",
                "SecurityName": "NAGARJUNA OIL REFINERY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE453M01018",
                "Industry": "Exploration & Production",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534190",
                "SecurityId": "OLPCL",
                "SecurityName": "OLYMPIC CARDS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE550L01013",
                "Industry": "Gift Articles-Toys & Cards ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534309",
                "SecurityId": "NBCC",
                "SecurityName": "NBCC (INDIA) LTD",
                "FaceValue": "1",
                "ISINNo": "INE095N01031",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534312",
                "SecurityId": "MTEDUCARE",
                "SecurityName": "MT EDUCARE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE472M01018",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534328",
                "SecurityId": "HEXATRADEX",
                "SecurityName": "HEXA TRADEX LTD.",
                "FaceValue": "2",
                "ISINNo": "INE750M01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534338",
                "SecurityId": "MAXHEIGHTS",
                "SecurityName": "MAXHEIGHTS INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE393F01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534339",
                "SecurityId": "INDBULBBPH",
                "SecurityName": "INDBULBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534369",
                "SecurityId": "TBZ",
                "SecurityName": "TRIBHOVANDAS BHIMJI ZAVERI LTD.",
                "FaceValue": "10",
                "ISINNo": "INE760L01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534392",
                "SecurityId": "VSSL",
                "SecurityName": "VARDHMAN SPECIAL STEELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE050M01012",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534401",
                "SecurityId": "UPLBBPH",
                "SecurityName": "UPLBBPH",
                "FaceValue": "2",
                "ISINNo": "INE628A01036",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534422",
                "SecurityId": "LOOKS",
                "SecurityName": "LOOKS HEALTH SERVICES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE204N01013",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534425",
                "SecurityId": "SPECIALITY",
                "SecurityName": "SPECIALITY RESTAURANTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE247M01014",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534479",
                "SecurityId": "LKPFINABBPH",
                "SecurityName": "LKPFINABBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534523",
                "SecurityId": "ALCARGOBBPH",
                "SecurityName": "ALCARGOBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534532",
                "SecurityId": "LYPSAGEMS",
                "SecurityName": "LYPSA GEMS & JEWELLERY LTD",
                "FaceValue": "10",
                "ISINNo": "INE142K01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534535",
                "SecurityId": "BGPL",
                "SecurityName": "BIO GREEN PAPERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE958L01018",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534563",
                "SecurityId": "MASL",
                "SecurityName": "MAX ALERT SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE253N01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534564",
                "SecurityId": "ASEEMG",
                "SecurityName": "ASEEM GLOBAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE439C01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534567",
                "SecurityId": "VKSPL",
                "SecurityName": "VKS PROJECTS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE213M01024",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534597",
                "SecurityId": "RTNINFRA",
                "SecurityName": "RATTANINDIA INFRASTRUCTURE LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE834M01019",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534598",
                "SecurityId": "SEPOWER",
                "SecurityName": "S.E. POWER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE735M01018",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534600",
                "SecurityId": "JTLINFRA",
                "SecurityName": "JTL INFRA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE391J01016",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534612",
                "SecurityId": "AMTL",
                "SecurityName": "ADVANCE METERING TECHNOLOGY LTD.",
                "FaceValue": "5",
                "ISINNo": "INE436N01029",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534615",
                "SecurityId": "NECCLTD",
                "SecurityName": "NORTH EASTERN CARRYING CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE553C01016",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534618",
                "SecurityId": "SAREL",
                "SecurityName": "SANGAM RENEWABLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE299N01013",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534623",
                "SecurityId": "JUPITERIN",
                "SecurityName": "JUPITER INFOMEDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE524N01014",
                "Industry": "Internet & Catalogue Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534639",
                "SecurityId": "VINAYAKPOL",
                "SecurityName": "VINAYAK POLYCON INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE581M01016",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534656",
                "SecurityId": "KANORIABBPH",
                "SecurityName": "KANORIABBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534659",
                "SecurityId": "JOINTECAED",
                "SecurityName": "JOINTECA EDUCATION SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE131N01018",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534674",
                "SecurityId": "DUCON",
                "SecurityName": "DUCON INFRATECHNOLOGIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE741L01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534675",
                "SecurityId": "PROZONINTU",
                "SecurityName": "PROZONE INTU PROPERTIES LIMITED",
                "FaceValue": "2",
                "ISINNo": "INE195N01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534680",
                "SecurityId": "SRGHFL",
                "SecurityName": "SRG HOUSING FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE559N01010",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534690",
                "SecurityId": "LAKSHVILAS",
                "SecurityName": "LAKSHMI VILAS BANK LTD.",
                "FaceValue": "10",
                "ISINNo": "INE694C01018",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534691",
                "SecurityId": "COMCL",
                "SecurityName": "COMFORT COMMOTRADE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE456N01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534707",
                "SecurityId": "ADITRI",
                "SecurityName": "ADITRI INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE636N01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534708",
                "SecurityId": "RCRL",
                "SecurityName": "RCL RETAIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE892L01019",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534711",
                "SecurityId": "CINEMAXIND",
                "SecurityName": "CINEMAX INDIA LTD.",
                "FaceValue": "5",
                "ISINNo": "INE460N01011",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534731",
                "SecurityId": "BITL",
                "SecurityName": "BRONZE INFRA-TECH LTD.",
                "FaceValue": "10",
                "ISINNo": "INE830N01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534732",
                "SecurityId": "INTELLADV",
                "SecurityName": "INTELLIVATE CAPITAL ADVISORS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE176N01021",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534733",
                "SecurityId": "SUPREMEX",
                "SecurityName": "SUPREMEX SHINE STEELS LTD",
                "FaceValue": "1",
                "ISINNo": "INE175N01023",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534734",
                "SecurityId": "RMCHEM",
                "SecurityName": "RAM MINERALS AND CHEMICALS LTD",
                "FaceValue": "1",
                "ISINNo": "INE174N01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534740",
                "SecurityId": "BCHL",
                "SecurityName": "BHANOT CONSTRUCTION & HOUSING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE484K01017",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534741",
                "SecurityId": "VIRTUALG",
                "SecurityName": "VIRTUAL GLOBAL EDUCATION LTD.",
                "FaceValue": "1",
                "ISINNo": "INE247C01023",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534742",
                "SecurityId": "ZUARI",
                "SecurityName": "ZUARI AGRO CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE840M01016",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534743",
                "SecurityId": "MASTEKLBBPH",
                "SecurityName": "MASTEKLBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534748",
                "SecurityId": "STEELXIND",
                "SecurityName": "STEEL EXCHANGE INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE503B01013",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534755",
                "SecurityId": "TRIOMERC",
                "SecurityName": "TRIO MERCANTILE & TRADING LTD.",
                "FaceValue": "10",
                "ISINNo": "INE234G01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534756",
                "SecurityId": "TARAJEWELS",
                "SecurityName": "TARA JEWELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE799L01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534757",
                "SecurityId": "FOCUSIRL",
                "SecurityName": "FOCUS INDUSTRIAL RESOURCES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE603E01023",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534758",
                "SecurityId": "CIGNITI",
                "SecurityName": "CIGNITI TECHNOLOGIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE675C01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534796",
                "SecurityId": "CDG",
                "SecurityName": "CDG PETCHEM LTD",
                "FaceValue": "10",
                "ISINNo": "INE198N01017",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534804",
                "SecurityId": "CARERATING",
                "SecurityName": "CARE RATINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE752H01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534809",
                "SecurityId": "PCJEWELLER",
                "SecurityName": "PC JEWELLER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE785M01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534816",
                "SecurityId": "INDUSTOWER",
                "SecurityName": "INDUS TOWERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE121J01017",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534839",
                "SecurityId": "EFPL",
                "SecurityName": "ECO FRIENDLY FOOD PROCESSING PARK LTD.",
                "FaceValue": "1",
                "ISINNo": "INE178O01025",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534860",
                "SecurityId": "GRADBBPH",
                "SecurityName": "GRADBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534920",
                "SecurityId": "COVIDH",
                "SecurityName": "COVIDH TECHNOLOGIES LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE899M01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534927",
                "SecurityId": "EBFL",
                "SecurityName": "ESTEEM BIO ORGANIC FOOD PROCESSING  LTD.",
                "FaceValue": "1",
                "ISINNo": "INE209O01028",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "534976",
                "SecurityId": "VMART",
                "SecurityName": "V-MART RETAIL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE665J01013",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535063",
                "SecurityId": "KRBLBBPH",
                "SecurityName": "KRBLBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535136",
                "SecurityId": "NIBE",
                "SecurityName": "NIBE LTD",
                "FaceValue": "10",
                "ISINNo": "INE149O01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535141",
                "SecurityId": "SRDL",
                "SecurityName": "SUNSTAR REALTY DEVELOPMENT LTD.",
                "FaceValue": "1",
                "ISINNo": "INE338O01025",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535142",
                "SecurityId": "CNEL",
                "SecurityName": "CHANNEL NINE ENTERTAINMENT LTD.",
                "FaceValue": "1",
                "ISINNo": "INE437O01025",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535204",
                "SecurityId": "MUKTA",
                "SecurityName": "MUKTA AGRICULTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE160O01031",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535205",
                "SecurityId": "MYSTICELE",
                "SecurityName": "MYSTIC ELECTRONICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE159O01033",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535217",
                "SecurityId": "HPCBL",
                "SecurityName": "HPC BIOSCIENCES LTD.",
                "FaceValue": "1",
                "ISINNo": "INE507O01025",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535218",
                "SecurityId": "PANAMABBPH",
                "SecurityName": "PANAMABBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535267",
                "SecurityId": "COMFINCAP",
                "SecurityName": "COMFORT FINCAP LTD.",
                "FaceValue": "10",
                "ISINNo": "INE274M01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535276",
                "SecurityId": "SBISENSEX",
                "SecurityName": "SBI MUTUAL FUND - SBI SENSEX ETF",
                "FaceValue": "10",
                "ISINNo": "INF200K01VT2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535279",
                "SecurityId": "BMAL",
                "SecurityName": "BOTHRA METALS & ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE583M01012",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535298",
                "SecurityId": "SHRIDINBBPH",
                "SecurityName": "SHRIDINBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535322",
                "SecurityId": "REPCOHOME",
                "SecurityName": "REPCO HOME FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE612J01015",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535387",
                "SecurityId": "LAKHOTIA",
                "SecurityName": "LAKHOTIA POLYESTERS (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE191O01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535431",
                "SecurityId": "GCMSECU",
                "SecurityName": "GCM SECURITIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE168O01026",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535458",
                "SecurityId": "NIBL",
                "SecurityName": "NRB INDUSTRIAL BEARINGS LTD.",
                "FaceValue": "2",
                "ISINNo": "INE047O01014",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535466",
                "SecurityId": "SRL",
                "SecurityName": "SAMRUDDHI REALTY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE621O01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535467",
                "SecurityId": "AIFL",
                "SecurityName": "ASHAPURA INTIMATES FASHION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE428O01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535486",
                "SecurityId": "DELTALTD",
                "SecurityName": "DELTA LEASING & FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE874N01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535501",
                "SecurityId": "IDFCEOS1RD",
                "SecurityName": "IDFC EQUITY OPPORTUNITY- SERIES 1- REGULAR PLAN- DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF194K012N1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535502",
                "SecurityId": "IDFCEOS1DD",
                "SecurityName": "IDFC EQUITY OPPORTUNITY- SERIES 1- DIRECT PLAN- DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF194K01I45",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535503",
                "SecurityId": "RLL",
                "SecurityName": "ROSELABS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE479C01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535514",
                "SecurityId": "PRIMECAPM",
                "SecurityName": "PRIME CAPITAL MARKET LTD.",
                "FaceValue": "10",
                "ISINNo": "INE748D01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535566",
                "SecurityId": "KIFS",
                "SecurityName": "KIFS FINANCIAL SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE902D01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535571",
                "SecurityId": "ZENTECHBBPH",
                "SecurityName": "ZENTECHBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535585",
                "SecurityId": "MAHSEAMBBPH",
                "SecurityName": "MAHSEAMBBPH",
                "FaceValue": "5",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535599",
                "SecurityId": "AVANTBBPH",
                "SecurityName": "AVANTBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535600",
                "SecurityId": "SMSPHABBPH",
                "SecurityName": "SMSPHABBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535601",
                "SecurityId": "SREEL*",
                "SecurityName": "SREELEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE099F01013",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535602",
                "SecurityId": "SHARDA",
                "SecurityName": "SHARDA MOTOR INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE597I01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535620",
                "SecurityId": "BINNYMILLS",
                "SecurityName": "BINNY MILLS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE160L01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535621",
                "SecurityId": "SVGLOBAL",
                "SecurityName": "S V GLOBAL MILL LTD.",
                "FaceValue": "5",
                "ISINNo": "INE159L01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535634",
                "SecurityId": "HTMEDIBBPH",
                "SecurityName": "HTMEDIBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535647",
                "SecurityId": "SDC",
                "SecurityName": "SDC TECHMEDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE807O01011",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535648",
                "SecurityId": "JUSTDIAL",
                "SecurityName": "JUST DIAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE599M01018",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535657",
                "SecurityId": "OONE",
                "SecurityName": "OBJECTONE INFORMATION SYSTEMS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE860E01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535658",
                "SecurityId": "PAWANSUT",
                "SecurityName": "PAWANSUT HOLDINGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE260M01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535667",
                "SecurityId": "IFINSEC",
                "SecurityName": "INDIA FINSEC LTD.",
                "FaceValue": "10",
                "ISINNo": "INE474O01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535682",
                "SecurityId": "INFINITBBPH",
                "SecurityName": "INFINITBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535692",
                "SecurityId": "PENNARBBPH",
                "SecurityName": "PENNARBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535693",
                "SecurityId": "BRAHMINFRA",
                "SecurityName": "BRAHMAPUTRA INFRASTRUCTURE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE320I01017",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535694",
                "SecurityId": "EDSL",
                "SecurityName": "EDYNAMICS SOLUTIONS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE899O01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535714",
                "SecurityId": "SPICEMBBPH",
                "SecurityName": "SPICEMBBPH",
                "FaceValue": "3",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535719",
                "SecurityId": "QUEST",
                "SecurityName": "QUEST SOFTECH (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE989J01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535729",
                "SecurityId": "MOTILALBBPH",
                "SecurityName": "MOTILALBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535730",
                "SecurityId": "KHOOBSURAT",
                "SecurityName": "KHOOBSURAT LTD",
                "FaceValue": "1",
                "ISINNo": "INE731G01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535754",
                "SecurityId": "ORIENTCEM",
                "SecurityName": "ORIENT CEMENT LTD",
                "FaceValue": "1",
                "ISINNo": "INE876N01018",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535755",
                "SecurityId": "ABFRL",
                "SecurityName": "ADITYA BIRLA FASHION AND RETAIL LTD",
                "FaceValue": "10",
                "ISINNo": "INE647O01011",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535762",
                "SecurityId": "CGPOWERBBPH",
                "SecurityName": "CGPOWERBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535789",
                "SecurityId": "IBULHSGFIN",
                "SecurityName": "INDIABULLS HOUSING FINANCE LTD",
                "FaceValue": "2",
                "ISINNo": "INE148I01020",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535790",
                "SecurityId": "APTECHBBPH",
                "SecurityName": "APTECHBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535910",
                "SecurityId": "MMLF",
                "SecurityName": "MONEY MASTERS LEASING & FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE340O01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535916",
                "SecurityId": "ALSL",
                "SecurityName": "ALACRITY SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE030P01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535917",
                "SecurityId": "GCMCOMM",
                "SecurityName": "GCM COMMODITY & DERIVATIVES LTD",
                "FaceValue": "10",
                "ISINNo": "INE200P01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "535958",
                "SecurityId": "INTEGRA",
                "SecurityName": "INTEGRA GARMENTS AND TEXTILES LTD",
                "FaceValue": "3",
                "ISINNo": "INE418N01027",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536072",
                "SecurityId": "ECLERXBBPH",
                "SecurityName": "ECLERXBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536073",
                "SecurityId": "SILINFRA",
                "SecurityName": "SILVERPOINT INFRATECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE890N01019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536105",
                "SecurityId": "GESHIPBBPH",
                "SecurityName": "GESHIPBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536128",
                "SecurityId": "VKJINFRA",
                "SecurityName": "VKJ INFRADEVELOPERS LTD",
                "FaceValue": "1",
                "ISINNo": "INE211P01021",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536160",
                "SecurityId": "JBFINDBBPH",
                "SecurityName": "JBFINDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536161",
                "SecurityId": "NITINBBPH",
                "SecurityName": "NITINBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536170",
                "SecurityId": "KUSHAL",
                "SecurityName": "KUSHAL LTD",
                "FaceValue": "2",
                "ISINNo": "INE647N01021",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536264",
                "SecurityId": "TIGERLOGS",
                "SecurityName": "TIGER LOGISTICS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE906O01011",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536265",
                "SecurityId": "JSPLBBPH",
                "SecurityName": "JSPLBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536456",
                "SecurityId": "RJBIOTECH",
                "SecurityName": "R J BIO-TECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE594O01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536492",
                "SecurityId": "ATWL",
                "SecurityName": "ACE TOURS WORLDWIDE LTD",
                "FaceValue": "10",
                "ISINNo": "INE926O01019",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536493",
                "SecurityId": "JK AGRI",
                "SecurityName": "JK AGRI GENETICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE690O01011",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536507",
                "SecurityId": "FLFL",
                "SecurityName": "FUTURE LIFESTYLE FASHIONS LTD",
                "FaceValue": "2",
                "ISINNo": "INE452O01016",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536565",
                "SecurityId": "TRIMURTHI",
                "SecurityName": "TRIMURTHI LTD",
                "FaceValue": "10",
                "ISINNo": "INE314I01036",
                "Industry": "Food & Drugs Retailing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536591",
                "SecurityId": "GRFIBREBBPH",
                "SecurityName": "GRFIBREBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536592",
                "SecurityId": "SKFL",
                "SecurityName": "SATKAR FINLEASE LTD",
                "FaceValue": "1",
                "ISINNo": "INE279P01036",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536644",
                "SecurityId": "NEWEVER",
                "SecurityName": "NEWEVER TRADE WINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE596O01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536659",
                "SecurityId": "PVVINFRA",
                "SecurityName": "PVV INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE428B01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536666",
                "SecurityId": "SFCL",
                "SecurityName": "STAR FERRO AND CEMENT LTD",
                "FaceValue": "1",
                "ISINNo": "INE935O01010",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536671",
                "SecurityId": "SUBHTEX",
                "SecurityName": "SUBH TEX (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE427O01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536672",
                "SecurityId": "VCU",
                "SecurityName": "VCU DATA MANAGEMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE962O01014",
                "Industry": "IT Networking Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536709",
                "SecurityId": "INDRENEW",
                "SecurityName": "IND RENEWABLE ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE138O01029",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536710",
                "SecurityId": "SRGSFL",
                "SecurityName": "S R G SECURITIES FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE326P01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536737",
                "SecurityId": "ACFSL",
                "SecurityName": "AMRAPALI CAPITAL AND FINANCE SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE218P01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536738",
                "SecurityId": "STELLAR",
                "SecurityName": "STELLAR CAPITAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE325P01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536751",
                "SecurityId": "FIVEXTRADE",
                "SecurityName": "FIVE X TRADECOM LTD",
                "FaceValue": "10",
                "ISINNo": "INE750L01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536767",
                "SecurityId": "AIMLBBPH",
                "SecurityName": "AIMLBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536773",
                "SecurityId": "JPOLYINVST",
                "SecurityName": "JINDAL POLY INVESTMENT AND FINANCE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE147P01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536799",
                "SecurityId": "SRSFIN",
                "SecurityName": "SRS FINANCE LTD",
                "FaceValue": "1",
                "ISINNo": "INE002J01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536820",
                "SecurityId": "BOSTONTEK",
                "SecurityName": "BOSTON TEKNOWSYS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE777E01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536846",
                "SecurityId": "YURANUS",
                "SecurityName": "YURANUS INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE156M01017",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536868",
                "SecurityId": "INTELSOFT",
                "SecurityName": "INTEGRA TELECOMMUNICATION & SOFTWARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE256F01019",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536960",
                "SecurityId": "M100",
                "SecurityName": "MOTILAL OSWAL MUTUAL FUND - MOTILAL OSWAL MOST SHARES MIDCAP 100 ETF- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF247L01023",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536965",
                "SecurityId": "BPCAP",
                "SecurityName": "B. P. CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE947C01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536974",
                "SecurityId": "CPL",
                "SecurityName": "CAPTAIN POLYPLAST LTD",
                "FaceValue": "2",
                "ISINNo": "INE536P01021",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536992",
                "SecurityId": "RCLEDPLADD",
                "SecurityName": "RELIANCE MUTUAL FUND - RELIANCE CLOSE ENDED EQUITY FUND- SERIES A - DIRECT PLAN DVDND PYOT OPTIN",
                "FaceValue": "10",
                "ISINNo": "INF204K018R7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536993",
                "SecurityId": "RCLEDPLADG",
                "SecurityName": "RELIANCE MUTUAL FUND- RELIANCE CLOSE ENDED EQUITY FUND- SERIES A - DIRECT PLAN  GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204K017R9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536994",
                "SecurityId": "RCLENDPLAD",
                "SecurityName": "RELIANCE MUTUAL FUND- RELIANCE CLOSE ENDED EQUITY FUND- SERIES A - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204K016R1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "536995",
                "SecurityId": "RCLENDPLAG",
                "SecurityName": "RELIANCE MUTUAL FUND- RELIANCE CLOSE ENDED EQUITY FUND- SERIES A - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204K015R3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537007",
                "SecurityId": "ICICINIFTY",
                "SecurityName": "ICICI PRUDENTIAL NIFTY ETF",
                "FaceValue": "10",
                "ISINNo": "INF109K012R6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537008",
                "SecurityId": "ICICINF100",
                "SecurityName": "ICICI PRUDENTIAL NIFTY 100 ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KA1962",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537068",
                "SecurityId": "SATYA",
                "SecurityName": "SATYA MINERS & TRANSPORTERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE394F01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537069",
                "SecurityId": "ARNOLD",
                "SecurityName": "ARNOLD HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE185K01036",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537092",
                "SecurityId": "MODEX",
                "SecurityName": "MODEX INTERNATIONAL SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE072D01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537119",
                "SecurityId": "TENTIMETAL",
                "SecurityName": "TENTIWALA METAL PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE486P01011",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537198",
                "SecurityId": "RCLEDPLBDD",
                "SecurityName": "RELIANCE MUTUAL FUND - RELIANCE CLOSE ENDED EQUITY FUND- SERIES B - DIRECT PLAN DIVIDEND PAYO O",
                "FaceValue": "10",
                "ISINNo": "INF204K012S8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537199",
                "SecurityId": "RCLEDPLBDG",
                "SecurityName": "RELIANCE MUTUAL FUND- RELIANCE CLOSE ENDED EQUITY FUND- SERIES B - DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204K011S0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537200",
                "SecurityId": "RCLENDPLBD",
                "SecurityName": "RELIANCE MUTUAL FUND- RELIANCE CLOSE ENDED EQUITY FUND- SERIES B - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204K010S2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537201",
                "SecurityId": "RCLENDPLBG",
                "SecurityName": "RELIANCE MUTUAL FUND- RELIANCE CLOSE ENDED EQUITY FUND- SERIES B - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204K019R5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537253",
                "SecurityId": "SUNLOC",
                "SecurityName": "SUNIL HEALTHCARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE671C01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537254",
                "SecurityId": "RCIIND",
                "SecurityName": "RCI INDUSTRIES & TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE140B01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537259",
                "SecurityId": "SUYOG",
                "SecurityName": "SUYOG TELEMATICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE442P01014",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537268",
                "SecurityId": "CAIRNBBPH",
                "SecurityName": "CAIRNBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537291",
                "SecurityId": "NATHBIOGEN",
                "SecurityName": "NATH BIO-GENES (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE448G01010",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537292",
                "SecurityId": "AGRITECH",
                "SecurityName": "AGRI- TECH (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE449G01018",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537325",
                "SecurityId": "ISGECBBPH",
                "SecurityName": "ISGECBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537326",
                "SecurityId": "CHEMTECH",
                "SecurityName": "CHEMTECH INDUSTRIAL VALVES LTD",
                "FaceValue": "10",
                "ISINNo": "INE212P01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537373",
                "SecurityId": "GAILBBPH",
                "SecurityName": "GAILBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537392",
                "SecurityId": "TAAZAINT",
                "SecurityName": "TAAZA INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE392H01018",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537411",
                "SecurityId": "IDFCEOS2RD",
                "SecurityName": "IDFC MUTUAL FUND- IDFC EQUITY OPPORTUNITY- SERIES 2 - REGULAR PLAN- DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF194KA1FU5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537412",
                "SecurityId": "IDFCEOS2DD",
                "SecurityName": "IDFC MUTUAL FUND - IDFC EQUITY OPPORTUNITY- SERIES 2 - DIRECT PLAN- DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF194KA1FS9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537483",
                "SecurityId": "NETFNIF100",
                "SecurityName": "NIPPON INDIA ETF NIFTY 100",
                "FaceValue": "10",
                "ISINNo": "INF204K014N5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537484",
                "SecurityId": "RELNIFTY",
                "SecurityName": "RELIANCE MUTUAL FUND - R SHARES NIFTY ETF",
                "FaceValue": "10",
                "ISINNo": "INF204KA1EU6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537492",
                "SecurityId": "AGRIMONY",
                "SecurityName": "AGRIMONY COMMODITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE567P01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537524",
                "SecurityId": "VIAANINDUS",
                "SecurityName": "VIAAN INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE324N01027",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537536",
                "SecurityId": "DENISCHEM",
                "SecurityName": "DENIS CHEM LAB LTD",
                "FaceValue": "10",
                "ISINNo": "INE051G01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537573",
                "SecurityId": "POLYMAC",
                "SecurityName": "POLYMAC THERMOFORMERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE826P01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537582",
                "SecurityId": "UNISHIRE",
                "SecurityName": "UNISHIRE URBAN INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE210P01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537644",
                "SecurityId": "BSLFEFS1RG",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND- BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 1 - REGULAR PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1771",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537645",
                "SecurityId": "BSLFEFS1RN",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND- BIRLA SUN LIFE FOCUSED EQUITY FUND-SERIES 1-REGULAR PLAN-DIVIND PAYOT",
                "FaceValue": "10",
                "ISINNo": "INF209KA1789",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537646",
                "SecurityId": "BSLFEFS1DG",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND - BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 1 - DIRECT PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1797",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537647",
                "SecurityId": "BSLFEFS1DN",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND - BIRLA SUN LIFE FOCUSED EQUITY FUND-SERIES 1-DIRECT PLAN-DIVIDND PAYOT",
                "FaceValue": "10",
                "ISINNo": "INF209KA1805",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537648",
                "SecurityId": "IPRU2262",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND - ICICI PRUDENTIAL EQUITY SAVINGS FUND SERIES 1-REGLR PLAN CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KA1LJ0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537649",
                "SecurityId": "IPRU2263",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND - ICICI PRUDENTIAL EQUITY SAVINGS FUND SERIES 1-REGULAR PLAN DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF109KA1LK8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537650",
                "SecurityId": "IPRU8462",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND - ICICI PRUDENTIAL EQUITY SAVINGS FUND SERIES 1-DIRECT PLAN CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KA1LL6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537651",
                "SecurityId": "IPRU8463",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND - ICICI PRUDENTIAL EQUITY SAVINGS FUND SERIES 1 - DIRECT PLAN DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF109KA1LM4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537652",
                "SecurityId": "DCMSLBBPH",
                "SecurityName": "DCMSLBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537669",
                "SecurityId": "LPNAVAL",
                "SecurityName": "L. P. NAVAL AND ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE920P01019",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537700",
                "SecurityId": "IDFCEOS3RD",
                "SecurityName": "IDFC MUTUAL FUND- IDFC EQUITY OPPORTUNITY- SERIES 3 - REGULAR PLAN- DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF194KA1IS3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537701",
                "SecurityId": "IDFCEOS3DD",
                "SecurityName": "IDFC MUTUAL FUND - IDFC EQUITY OPPORTUNITY- SERIES 3 - DIRECT PLAN- DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF194KA1IQ7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537706",
                "SecurityId": "INDOBCLBBPH",
                "SecurityName": "INDOBCLBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537707",
                "SecurityId": "ETT",
                "SecurityName": "ETT LTD",
                "FaceValue": "10",
                "ISINNo": "INE546I01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537708",
                "SecurityId": "KOTAKNIFTY",
                "SecurityName": "KOTAK MAHINDRA MUTUAL FUND - KOTAK NIFTY ETF",
                "FaceValue": "1",
                "ISINNo": "INF174K014P6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537709",
                "SecurityId": "HANSUGAR",
                "SecurityName": "SHREE HANUMAN SUGAR & INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE101H01013",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537750",
                "SecurityId": "KIRANVYPAR",
                "SecurityName": "KIRAN VYAPAR LTD",
                "FaceValue": "10",
                "ISINNo": "INE555P01013",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537766",
                "SecurityId": "BCP",
                "SecurityName": "B.C. POWER CONTROLS LTD",
                "FaceValue": "2",
                "ISINNo": "INE905P01028",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537784",
                "SecurityId": "KCSL",
                "SecurityName": "KARNIMATA COLD STORAGE LTD",
                "FaceValue": "10",
                "ISINNo": "INE576P01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537785",
                "SecurityId": "ANISHAIMPEX",
                "SecurityName": "ANISHA IMPEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE084Q01012",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537800",
                "SecurityId": "MANGIND",
                "SecurityName": "MANGALAM INDUSTRIAL FINANCE LTD",
                "FaceValue": "1",
                "ISINNo": "INE717C01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537820",
                "SecurityId": "VFL",
                "SecurityName": "VIJI FINANCE LTD",
                "FaceValue": "1",
                "ISINNo": "INE159N01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537838",
                "SecurityId": "NEGOTIUM",
                "SecurityName": "NEGOTIUM INTERNATIONAL TRADE LTD",
                "FaceValue": "1",
                "ISINNo": "INE339P01046",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537839",
                "SecurityId": "PHOENIXTN",
                "SecurityName": "PHOENIX TOWNSHIP LTD",
                "FaceValue": "10",
                "ISINNo": "INE977M01024",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537840",
                "SecurityId": "RAUNAQEPC",
                "SecurityName": "RAUNAQ EPC INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE523K01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537866",
                "SecurityId": "HRGESSRG2",
                "SecurityName": "HDFC MUTUAL FUND - HDFC RAJIV GANDHI EQUITY SAVINGS SCHEME - SERIES 2 - REGULAR PLAN - G O",
                "FaceValue": "10",
                "ISINNo": "INF179KA1BN8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537867",
                "SecurityId": "HRGESSRD2",
                "SecurityName": "HDFC MUTUAL FUND - HDFC RAJIV GANDHI EQUITY SAVINGS SCHEME - SERIES 2-REGULAR PLAN- D P O",
                "FaceValue": "10",
                "ISINNo": "INF179KA1BO6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537868",
                "SecurityId": "HRGESSDG2",
                "SecurityName": "HDFC MUTUAL FUND - HDFC RAJIV GANDHI EQUITY SAVINGS SCHEME - SERIES 2- DIRECT PLAN- GROWTH O",
                "FaceValue": "10",
                "ISINNo": "INF179KA1BP3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537869",
                "SecurityId": "HRGESSDD2",
                "SecurityName": "HDFC MUTUAL FUND - HDFC RAJIV GANDHI EQUITY SAVINGS SCHEME - SERIES 2- DIRECT PLAN- DVDD P O",
                "FaceValue": "10",
                "ISINNo": "INF179KA1BQ1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537922",
                "SecurityId": "IPRU2296",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 3(REGULAR DIVIDEND OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KB1X42",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537923",
                "SecurityId": "IPRU8496",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 3(DIRECT DIVIDEND OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KB1X59",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537954",
                "SecurityId": "SKP",
                "SecurityName": "SHRI KRISHNA PRASADAM LTD",
                "FaceValue": "10",
                "ISINNo": "INE009Q01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537958",
                "SecurityId": "LICNFR2GP",
                "SecurityName": "LIC MF RGESS FUND SERIES- 2 REGULAR PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01BP0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537959",
                "SecurityId": "LICNFR2DP",
                "SecurityName": "LIC MF RGESS FUND SERIES 2 REGULAR PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01BQ8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537960",
                "SecurityId": "LICNFR2G1",
                "SecurityName": "LIC MF RGESS FUND SERIES 2 DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01BR6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537961",
                "SecurityId": "LICNFR2D1",
                "SecurityName": "LIC MF RGESS FUND SERIES 2 DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01BS4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "537985",
                "SecurityId": "INFRONICS",
                "SecurityName": "INFRONICS SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE463B01036",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538015",
                "SecurityId": "BSLFEFS2RG",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND- BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 2 - REGULAR PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1DF4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538016",
                "SecurityId": "BSLFEFS2RN",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND- BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 2 - REGULAR PLAN - D P",
                "FaceValue": "10",
                "ISINNo": "INF209KA1DG2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538017",
                "SecurityId": "BSLFEFS2DG",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND-  BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 2 - DIRECT PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1DH0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538018",
                "SecurityId": "BSLFEFS2DN",
                "SecurityName": "BIRLA SUN LIFE MUTUAL FUND-  BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 2 - DIRECT PLAN-DIVIDEND P",
                "FaceValue": "10",
                "ISINNo": "INF209KA1DI8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538019",
                "SecurityId": "OBIL",
                "SecurityName": "OCEANAA BIOTEK INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE732P01018",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538057",
                "SecurityId": "CPSEETF",
                "SecurityName": "CPSE ETF",
                "FaceValue": "10",
                "ISINNo": "INF457M01133",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538081",
                "SecurityId": "HARIAAPL",
                "SecurityName": "HARIA APPARELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE493N01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538092",
                "SecurityId": "JOONKTOLL",
                "SecurityName": "JOONKTOLLEE TEA & INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE574G01013",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538119",
                "SecurityId": "RNBDENIMS",
                "SecurityName": "R&B DENIMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE012Q01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538128",
                "SecurityId": "WOMENSNEXT",
                "SecurityName": "WOMENS NEXT LOUNGERIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE174Q01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538180",
                "SecurityId": "GOLDLINE",
                "SecurityName": "GOLD LINE INTERNATIONAL FINVEST LTD",
                "FaceValue": "1",
                "ISINNo": "INE204P01026",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538212",
                "SecurityId": "SHARPINV",
                "SecurityName": "SHARP INVESTMENTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE909D01026",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538220",
                "SecurityId": "EFSLBBPH",
                "SecurityName": "EFSLBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538268",
                "SecurityId": "WONDERLA",
                "SecurityName": "WONDERLA HOLIDAYS LTD",
                "FaceValue": "10",
                "ISINNo": "INE066O01014",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538273",
                "SecurityId": "RESPONSINF",
                "SecurityName": "RESPONSE INFORMATICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE401B01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538287",
                "SecurityId": "TARANG",
                "SecurityName": "TARANG PROJECTS & CONSULTANT LTD",
                "FaceValue": "10",
                "ISINNo": "INE538P01019",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538295",
                "SecurityId": "KAUSAMBI",
                "SecurityName": "KAUSAMBI VANIJYA LTD",
                "FaceValue": "1",
                "ISINNo": "INE223P01026",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538309",
                "SecurityId": "IPRU2365",
                "SecurityName": "IPRU2365",
                "FaceValue": "10",
                "ISINNo": "INF109KB19K8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538310",
                "SecurityId": "IPRU2366",
                "SecurityName": "IPRU2366",
                "FaceValue": "10",
                "ISINNo": "INF109KB18K0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538311",
                "SecurityId": "IPRU8565",
                "SecurityName": "IPRU8565",
                "FaceValue": "10",
                "ISINNo": "INF109KB11L3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538312",
                "SecurityId": "IPRU8566",
                "SecurityName": "IPRU8566",
                "FaceValue": "10",
                "ISINNo": "INF109KB10L5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538319",
                "SecurityId": "GCMCAPI",
                "SecurityName": "GCM CAPITAL ADVISORS LTD",
                "FaceValue": "10",
                "ISINNo": "INE191P01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538351",
                "SecurityId": "AFEL",
                "SecurityName": "A.F. ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE663P01015",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538364",
                "SecurityId": "BCPAL",
                "SecurityName": "BIRDHI CHAND PANNALAL AGENCIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE795P01023",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538365",
                "SecurityId": "ADHUNIKIND",
                "SecurityName": "ADHUNIK INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE452L01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538382",
                "SecurityId": "WESTLEIRES",
                "SecurityName": "WEST LEISURE RESORTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE266P01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538395",
                "SecurityId": "NAM",
                "SecurityName": "NAM SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE792G01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538400",
                "SecurityId": "MMSINFRA",
                "SecurityName": "MMS INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE317N01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538401",
                "SecurityId": "METSL",
                "SecurityName": "MAESTROS ELECTRONICS & TELECOMMUNICATIONS SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE318N01011",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538402",
                "SecurityId": "SPS",
                "SecurityName": "SPS FINQUEST LTD",
                "FaceValue": "10",
                "ISINNo": "INE502O01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538418",
                "SecurityId": "RCLEDIIADD",
                "SecurityName": "RELIANCE CLOSE ENDED EQUITY FUND II- SERIES A - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1OW1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538419",
                "SecurityId": "RCLEDIIADG",
                "SecurityName": "RELIANCE CLOSE ENDED EQUITY FUND II- SERIES A - DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1OV3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538420",
                "SecurityId": "RCLENDIIAD",
                "SecurityName": "RELIANCE CLOSE ENDED EQUITY FUND II- SERIES A - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1OU5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538421",
                "SecurityId": "RCLENDIIAG",
                "SecurityName": "RELIANCE CLOSE ENDED EQUITY FUND II- SERIES A - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1OT7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538422",
                "SecurityId": "JACKSON",
                "SecurityName": "JACKSON INVESTMENTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE508N01025",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538423",
                "SecurityId": "ALPSMOTOR",
                "SecurityName": "ALPS MOTOR FINANCE LTD",
                "FaceValue": "1",
                "ISINNo": "INE729P01022",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538432",
                "SecurityId": "DIVINUS",
                "SecurityName": "DIVINUS FABRICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE478P01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538433",
                "SecurityId": "CCFCL",
                "SecurityName": "CLASSIC GLOBAL FINANCE & CAPITAL LTD",
                "FaceValue": "1",
                "ISINNo": "INE854P01028",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538446",
                "SecurityId": "MONEYBOXX",
                "SecurityName": "MONEYBOXX FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE296Q01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538450",
                "SecurityId": "DHYANAFIN",
                "SecurityName": "DHYANA FINSTOCK LTD",
                "FaceValue": "10",
                "ISINNo": "INE774P01010",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538451",
                "SecurityId": "WORTH",
                "SecurityName": "WORTH INVESTMENT & TRADING CO LTD",
                "FaceValue": "10",
                "ISINNo": "INE114O01012",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538452",
                "SecurityId": "QUASAR",
                "SecurityName": "QUASAR INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE855P01033",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538464",
                "SecurityId": "TPROJECT",
                "SecurityName": "THIRANI PROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE901C01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538465",
                "SecurityId": "AMARSEC",
                "SecurityName": "AMARNATH SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE745P01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538476",
                "SecurityId": "CTL",
                "SecurityName": "CAPITAL TRADE LINKS LTD",
                "FaceValue": "1",
                "ISINNo": "INE172D01021",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538496",
                "SecurityId": "TARINI",
                "SecurityName": "TARINI INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE849M01017",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538501",
                "SecurityId": "IPRU2401",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 1 (REGULAR DIVIDEND PAYOUT)",
                "FaceValue": "10",
                "ISINNo": "INF109KB13R6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538502",
                "SecurityId": "IPRU8601",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 1 (DIRECT DIVIDEND OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KB12R8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538503",
                "SecurityId": "MARICOKAYEL",
                "SecurityName": "MARICO KAYA ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE432P01015",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538504",
                "SecurityId": "CML",
                "SecurityName": "CREATIVE MERCHANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE896P01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538520",
                "SecurityId": "SBL",
                "SecurityName": "SHIVAMSHREE BUSINESSES LTD",
                "FaceValue": "1",
                "ISINNo": "INE857P01021",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538521",
                "SecurityId": "CROWNTOURS",
                "SecurityName": "CROWN TOURS LTD",
                "FaceValue": "10",
                "ISINNo": "INE969E01010",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538537",
                "SecurityId": "OMANSH",
                "SecurityName": "OMANSH ENTERPRISES LTD",
                "FaceValue": "2",
                "ISINNo": "INE378P01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538538",
                "SecurityId": "WELSPUNENT",
                "SecurityName": "WELSPUN    ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE072P01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538539",
                "SecurityId": "JTAPARIA",
                "SecurityName": "J. TAPARIA PROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE075K01013",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538540",
                "SecurityId": "RLFL",
                "SecurityName": "RAMCHANDRA LEASING & FINANCE LTD",
                "FaceValue": "1",
                "ISINNo": "INE516P01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538541",
                "SecurityId": "INCEPTUM",
                "SecurityName": "INCEPTUM ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE784P01035",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538542",
                "SecurityId": "GOLDCOINHF",
                "SecurityName": "GOLD COIN HEALTH FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE634J01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538546",
                "SecurityId": "BRPL",
                "SecurityName": "BANSAL ROOFING PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE319Q01012",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538547",
                "SecurityId": "OASIS",
                "SecurityName": "OASIS TRADELINK LTD",
                "FaceValue": "10",
                "ISINNo": "INE189Q01019",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538548",
                "SecurityId": "VITANAGRO",
                "SecurityName": "VITAN AGRO INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE186Q01023",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538556",
                "SecurityId": "ARMAN",
                "SecurityName": "ARMAN HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE510P01018",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538557",
                "SecurityId": "SAIBABA",
                "SecurityName": "SAI BABA INVESTMENT AND COMMERCIAL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE706P01038",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538562",
                "SecurityId": "SKIPPER",
                "SecurityName": "SKIPPER LTD",
                "FaceValue": "1",
                "ISINNo": "INE439E01022",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538563",
                "SecurityId": "ADARSH",
                "SecurityName": "ADARSH MERCANTILE LTD",
                "FaceValue": "10",
                "ISINNo": "INE673E01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538564",
                "SecurityId": "JAMESWARREN",
                "SecurityName": "JAMES WARREN TEA LTD",
                "FaceValue": "10",
                "ISINNo": "INE718P01017",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538565",
                "SecurityId": "VISTARAMAR",
                "SecurityName": "VISTAR AMAR LTD",
                "FaceValue": "10",
                "ISINNo": "INE878P01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538566",
                "SecurityId": "APOLLOTRI",
                "SecurityName": "APOLLO TRICOAT TUBES LTD",
                "FaceValue": "2",
                "ISINNo": "INE919P01029",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538567",
                "SecurityId": "GULFOILLUB",
                "SecurityName": "GULF OIL LUBRICANTS INDIA LTD",
                "FaceValue": "2",
                "ISINNo": "INE635Q01029",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538568",
                "SecurityId": "FRUTION",
                "SecurityName": "FRUITION VENTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE836C01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538569",
                "SecurityId": "TRIVENIENT",
                "SecurityName": "TRIVENI ENTERPRISES LTD",
                "FaceValue": "1",
                "ISINNo": "INE916P01025",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538570",
                "SecurityId": "ACHAL",
                "SecurityName": "ACHAL INVESTMENTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE860P01025",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538575",
                "SecurityId": "SOLISMAR",
                "SecurityName": "SOLIS MARKETING LTD",
                "FaceValue": "1",
                "ISINNo": "INE717P01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538576",
                "SecurityId": "BHANDERI",
                "SecurityName": "BHANDERI INFRACON LTD",
                "FaceValue": "10",
                "ISINNo": "INE336Q01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538577",
                "SecurityId": "IPRU2428",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 2 (REGULAR PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1804",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538578",
                "SecurityId": "IPRU8628",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 2 (DIRECT PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1796",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538579",
                "SecurityId": "TRIVIKRAMA",
                "SecurityName": "TRIVIKRAMA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE641Q01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538591",
                "SecurityId": "NCAPBULADD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES A - DIRECT PLAN DIVIDEND PAYOUT OPTIO",
                "FaceValue": "10",
                "ISINNo": "INF204KA1RY0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538592",
                "SecurityId": "NCAPBULADG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES A - DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1RX2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538593",
                "SecurityId": "NCAPBUILAD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES A - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1RW4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538594",
                "SecurityId": "NCAPBUILAG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES A - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1RV6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538595",
                "SecurityId": "GLOSTER",
                "SecurityName": "GLOSTER LTD",
                "FaceValue": "10",
                "ISINNo": "INE652C01016",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538596",
                "SecurityId": "QUANTBUILD",
                "SecurityName": "QUANTUM BUILD-TECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE222B01028",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538597",
                "SecurityId": "TTIENT",
                "SecurityName": "TTI ENTERPRISE LTD",
                "FaceValue": "10",
                "ISINNo": "INE404F01031",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538598",
                "SecurityId": "VISHAL",
                "SecurityName": "VISHAL FABRICS LTD",
                "FaceValue": "5",
                "ISINNo": "INE755Q01025",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538607",
                "SecurityId": "TOYAMIND",
                "SecurityName": "TOYAM INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE457P01020",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538608",
                "SecurityId": "DWITIYA",
                "SecurityName": "DWITIYA TRADING LTD",
                "FaceValue": "2",
                "ISINNo": "INE222P01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538609",
                "SecurityId": "GAJANANSEC",
                "SecurityName": "GAJANAN SECURITIES SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE868G01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538610",
                "SecurityId": "UNISON",
                "SecurityName": "UNISON METALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE099D01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538611",
                "SecurityId": "RTFL",
                "SecurityName": "REAL TOUCH FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE840I01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538630",
                "SecurityId": "NCAPBULBDD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES B - DIRECT DVDND PLAN- DVDND PYOT",
                "FaceValue": "10",
                "ISINNo": "INF204KA1SW2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538631",
                "SecurityId": "NCAPBULBDG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES B - DIRECT GROWTH PLAN- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1SV4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538632",
                "SecurityId": "NCAPBUILBD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES B - DIVIDEND PLAN-DIVIDEND PYUT OTIN",
                "FaceValue": "10",
                "ISINNo": "INF204KA1SU6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538633",
                "SecurityId": "NCAPBUILBG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND - NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FUND- SERIES B - GROWTH PLAN- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1ST8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538634",
                "SecurityId": "VRL",
                "SecurityName": "VASUNDHARA RASAYANS LTD",
                "FaceValue": "10",
                "ISINNo": "INE406F01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538635",
                "SecurityId": "SNOWMAN",
                "SecurityName": "SNOWMAN LOGISTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE734N01019",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538646",
                "SecurityId": "QGO",
                "SecurityName": "QGO FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE837C01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538647",
                "SecurityId": "PURSHOTTAM",
                "SecurityName": "PURSHOTTAM INVESTOFIN LTD",
                "FaceValue": "10",
                "ISINNo": "INE729C01020",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538648",
                "SecurityId": "IPRU2487",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 5 (REGULAR CUMULATIVE OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1242",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538649",
                "SecurityId": "IPRU2488",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 5 (REGULAR DIVIDEND OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1234",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538650",
                "SecurityId": "IPRU8687",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 5 (DIRECT CUMULATIVE OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1267",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538651",
                "SecurityId": "IPRU8688",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 5 (DIRECT DIVIDEND OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1259",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538652",
                "SecurityId": "HCLTD",
                "SecurityName": "HIND COMMERCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE691J01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538653",
                "SecurityId": "EML",
                "SecurityName": "EJECTA MARKETING LTD",
                "FaceValue": "10",
                "ISINNo": "INE649L01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538666",
                "SecurityId": "SHARDACROP",
                "SecurityName": "SHARDA CROPCHEM LTD",
                "FaceValue": "10",
                "ISINNo": "INE221J01015",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538667",
                "SecurityId": "SIROHIA",
                "SecurityName": "SIROHIA & SONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE785O01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538668",
                "SecurityId": "NAYSAA",
                "SecurityName": "NAYSAA SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE898Q01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538674",
                "SecurityId": "CITYONLINE",
                "SecurityName": "CITY ONLINE SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE158C01014",
                "Industry": "Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538683",
                "SecurityId": "NETFSENSEX",
                "SecurityName": "NIPPON INDIA ETF SENSEX",
                "FaceValue": "10",
                "ISINNo": "INF204KA1UN7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538684",
                "SecurityId": "ENCASH",
                "SecurityName": "ENCASH ENTERTAINMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE552Q01018",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538685",
                "SecurityId": "SHEMAROO",
                "SecurityName": "SHEMAROO ENTERTAINMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE363M01019",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538686",
                "SecurityId": "PARTANI",
                "SecurityName": "PARTANI APPLIANCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE217P01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538706",
                "SecurityId": "ULTRACAB",
                "SecurityName": "ULTRACAB (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE010R01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538707",
                "SecurityId": "RCCL",
                "SecurityName": "RAJASTHAN CYLINDERS & CONTAINERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE929D01016",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538708",
                "SecurityId": "ETIL",
                "SecurityName": "ECONO TRADE (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE937K01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538709",
                "SecurityId": "SEOFIGR",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES I- REGULAR PLAN -GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF200KA1RM9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538710",
                "SecurityId": "SEOFIDR",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES I-REGULAR PLAN- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF200KA1RN7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538711",
                "SecurityId": "SEOFIGD",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES I-DIRECT PLAN -GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF200KA1RO5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538712",
                "SecurityId": "SEOFIDD",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES I-DIRECT PLAN - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF200KA1RP2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538713",
                "SecurityId": "ATISHAY",
                "SecurityName": "ATISHAY LTD",
                "FaceValue": "10",
                "ISINNo": "INE011R01013",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538714",
                "SecurityId": "SUCHITRA",
                "SecurityName": "SUCHITRA FINANCE & TRADING COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE475D01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538715",
                "SecurityId": "DHABRIYA",
                "SecurityName": "DHABRIYA POLYWOOD LTD",
                "FaceValue": "10",
                "ISINNo": "INE260R01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538716",
                "SecurityId": "ARYACAPM",
                "SecurityName": "ARYAMAN CAPITAL MARKETS LTD",
                "FaceValue": "10",
                "ISINNo": "INE229R01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538717",
                "SecurityId": "RCAPBULCDD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND- SERIES C - DIRECT DIVIDEND PLAN- DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1UE6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538718",
                "SecurityId": "RCAPBULCDG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND- SERIES C - DIRECT GROWTH PLAN- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1UD8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538719",
                "SecurityId": "RCAPBUILCD",
                "SecurityName": "RELIANCE MUTUAL FUND- RELIANCE CAPITAL BUILDER FUND- SERIES C - DIVIDEND PLAN-DIVIDEND P O",
                "FaceValue": "10",
                "ISINNo": "INF204KA1UC0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538720",
                "SecurityId": "RCAPBUILCG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND- SERIES C - GROWTH PLAN- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1UB2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538728",
                "SecurityId": "IPRU2511",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 3 (REGULAR PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1CO7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538729",
                "SecurityId": "IPRU8711",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 3 (DIRECT PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KC1CN9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538730",
                "SecurityId": "PDSMFL",
                "SecurityName": "PDS MULTINATIONAL FASHIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE111Q01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538731",
                "SecurityId": "HANMAN",
                "SecurityName": "HANMAN FIT LTD",
                "FaceValue": "10",
                "ISINNo": "INE982Q01017",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538732",
                "SecurityId": "VGCL",
                "SecurityName": "VIBRANT GLOBAL CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE761Q01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538733",
                "SecurityId": "STARLIT",
                "SecurityName": "STARLIT POWER SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE909P01012",
                "Industry": "Other Non-Ferrous Metals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538734",
                "SecurityId": "CEINSYSTECH",
                "SecurityName": "CEINSYS TECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE016Q01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538742",
                "SecurityId": "PANACHE",
                "SecurityName": "PANACHE INNOVATIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE516E01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538743",
                "SecurityId": "MUDUNURU",
                "SecurityName": "MUDUNURU LTD",
                "FaceValue": "2",
                "ISINNo": "INE491C01027",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538755",
                "SecurityId": "BSLFEFS3RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 3 REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1RR9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538756",
                "SecurityId": "BSLFEFS3RN",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 3 REGULAR DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KA1RS7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538757",
                "SecurityId": "BSLFEFS3DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 3 DIRECT GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1RT5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538758",
                "SecurityId": "BSLFEFS3DN",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 3 DIRECT DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KA1RU3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538763",
                "SecurityId": "IPRU2530",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 4 (REGULAR PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KA1Y68",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538764",
                "SecurityId": "IPRU8730",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 4 (DIRECT PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KA1Y76",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538765",
                "SecurityId": "JSHL",
                "SecurityName": "JLA INFRAVILLE SHOPPERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE401Q01018",
                "Industry": "Internet & Catalogue Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538770",
                "SecurityId": "CRANEINFRA",
                "SecurityName": "CRANE INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE176L01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538771",
                "SecurityId": "PINCON",
                "SecurityName": "PINCON SPIRIT LTD",
                "FaceValue": "10",
                "ISINNo": "INE675G01018",
                "Industry": "Breweries & Distilleries",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538772",
                "SecurityId": "NIYOGIN",
                "SecurityName": "NIYOGIN FINTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE480D01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538777",
                "SecurityId": "ASIACAP",
                "SecurityName": "ASIA CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE131Q01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538778",
                "SecurityId": "AKASHDEEP",
                "SecurityName": "AKASHDEEP METAL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE149Q01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538786",
                "SecurityId": "CIL",
                "SecurityName": "CITIZEN INFOLINE LTD",
                "FaceValue": "10",
                "ISINNo": "INE473L01018",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538787",
                "SecurityId": "GBFL",
                "SecurityName": "GOENKA BUSINESS & FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE997C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538788",
                "SecurityId": "GILADAFINS",
                "SecurityName": "GILADA FINANCE & INVESTMENTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE918C01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538789",
                "SecurityId": "BUDGE BUDGE",
                "SecurityName": "BUDGE BUDGE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE948C01026",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538793",
                "SecurityId": "TEJINFOWAY",
                "SecurityName": "TEJ INFOWAYS LTD",
                "FaceValue": "10",
                "ISINNo": "INE530C01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538794",
                "SecurityId": "JETINFRA",
                "SecurityName": "JET INFRAVENTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE155R01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538795",
                "SecurityId": "SAPPL",
                "SecurityName": "SHREE AJIT PULP AND PAPER LTD",
                "FaceValue": "10",
                "ISINNo": "INE185C01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538808",
                "SecurityId": "D3YRCEERG",
                "SecurityName": "DSP BLACKROCK 3 YEARS CLOSE ENDED EQUITY FUND-REGULAR- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF740KA1CY3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538809",
                "SecurityId": "D3YRCEERDP",
                "SecurityName": "DSP BLACKROCK 3 YEARS CLOSE ENDED EQUITY FUND-REGULAR- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF740KA1CZ0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538810",
                "SecurityId": "D3YRCEEDG",
                "SecurityName": "DSP BLACKROCK 3 YEARS CLOSE ENDED EQUITY FUND- DIRECT PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF740KA1DA1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538811",
                "SecurityId": "D3YRCEEDDP",
                "SecurityName": "DSP BLACKROCK 3 YEARS CLOSE ENDED EQUITY FUND-DIRECT PLAN - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF740KA1DB9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538812",
                "SecurityId": "AANCHALISP",
                "SecurityName": "AANCHAL ISPAT LTD",
                "FaceValue": "10",
                "ISINNo": "INE322R01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538817",
                "SecurityId": "CAPPIPES",
                "SecurityName": "CAPTAIN PIPES LTD",
                "FaceValue": "10",
                "ISINNo": "INE513R01018",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538818",
                "SecurityId": "SEOFIIGR",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES II-REGULAR PLAN -GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF200K018Y7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538819",
                "SecurityId": "SEOFIIDR",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES II-REGULAR PLAN- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF200K019Y5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538820",
                "SecurityId": "SEOFIIGD",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES II-DIRECT PLAN -GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF200K010Z1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538821",
                "SecurityId": "SEOFIIDD",
                "SecurityName": "SBI EQUITY OPPORTUNITIES FUND - SERIES II-DIRECT PLAN - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF200K011Z9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538833",
                "SecurityId": "ANUBHAV",
                "SecurityName": "ANUBHAV INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE891N01017",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538834",
                "SecurityId": "MEL",
                "SecurityName": "MEENAKSHI ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE242Q01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538835",
                "SecurityId": "INTELLECT",
                "SecurityName": "INTELLECT DESIGN ARENA LTD",
                "FaceValue": "5",
                "ISINNo": "INE306R01017",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538836",
                "SecurityId": "MONTECARLO",
                "SecurityName": "MONTE CARLO FASHIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE950M01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538837",
                "SecurityId": "JSTL",
                "SecurityName": "JEEVAN SCIENTIFIC TECHNOLOGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE237B01018",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538838",
                "SecurityId": "ICL",
                "SecurityName": "INDO COTSPIN LTD",
                "FaceValue": "10",
                "ISINNo": "INE407P01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538841",
                "SecurityId": "BSLFEFS4RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 4 REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1UL6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538842",
                "SecurityId": "BSLFEFS4RN",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 4 REGULAR DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KA1UM4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538843",
                "SecurityId": "BSLFEFS4DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 4 DIRECT GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1UN2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538844",
                "SecurityId": "BSLFEFS4DN",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 4 DIRECT DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KA1UO0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538853",
                "SecurityId": "RCBFIIADD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES A - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1YL3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538854",
                "SecurityId": "RCBFIIADG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES A - DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1YK5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538855",
                "SecurityId": "RCBFIIAD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES A -  DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1YJ7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538856",
                "SecurityId": "RCBFIIAG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES A -  GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1YI9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538857",
                "SecurityId": "SCC",
                "SecurityName": "SCINTILLA COMMERCIAL & CREDIT LTD",
                "FaceValue": "10",
                "ISINNo": "INE892C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538858",
                "SecurityId": "CAMSONBIO",
                "SecurityName": "CAMSON BIO TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE845E01012",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538859",
                "SecurityId": "RSYSTEMBBPH",
                "SecurityName": "RSYSTEMBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538860",
                "SecurityId": "PANAFIC",
                "SecurityName": "PANAFIC INDUSTRIALS LTD",
                "FaceValue": "1",
                "ISINNo": "INE655P01029",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538861",
                "SecurityId": "AMSONS",
                "SecurityName": "AMSONS APPARELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE812P01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538862",
                "SecurityId": "MYMONEY",
                "SecurityName": "MY MONEY SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE232L01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538863",
                "SecurityId": "AMARNATH",
                "SecurityName": "SRI AMARNATH FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE985Q01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538868",
                "SecurityId": "CSL",
                "SecurityName": "CONTINENTAL SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE183Q01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538873",
                "SecurityId": "WINYCOMM",
                "SecurityName": "WINY COMMERCIAL & FISCAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE111C01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538874",
                "SecurityId": "NEXUSSURGL",
                "SecurityName": "NEXUS SURGICAL AND MEDICARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE370Q01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538875",
                "SecurityId": "SELLWIN",
                "SecurityName": "SELLWIN TRADERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE195F01019",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538876",
                "SecurityId": "STSERV",
                "SecurityName": "S T SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE907D01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538881",
                "SecurityId": "GALADAFIN",
                "SecurityName": "GALADA FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE243E01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538882",
                "SecurityId": "EMERALD",
                "SecurityName": "EMERALD LEASING FINANCE & INVESTMENT COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE030Q01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538890",
                "SecurityId": "MKEXIM",
                "SecurityName": "M.K. EXIM (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE227F01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538891",
                "SecurityId": "MCLOUD",
                "SecurityName": "MAGELLANIC CLOUD LTD",
                "FaceValue": "10",
                "ISINNo": "INE613C01018",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538892",
                "SecurityId": "IPRU2586",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 6 (REGULAR PLAN - DIVIDEND PAYOUT)",
                "FaceValue": "10",
                "ISINNo": "INF109KA14O3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538893",
                "SecurityId": "IPRU8788",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 6 (DIRECT PLAN - DIVIDEND PAYOUT)",
                "FaceValue": "10",
                "ISINNo": "INF109KA15O0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538894",
                "SecurityId": "OCTAL",
                "SecurityName": "OCTAL CREDIT CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE513C01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538895",
                "SecurityId": "MIHIKA",
                "SecurityName": "MIHIKA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE779Q01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538896",
                "SecurityId": "KANCHI",
                "SecurityName": "KANCHI KARPOORAM LTD",
                "FaceValue": "10",
                "ISINNo": "INE081G01019",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538897",
                "SecurityId": "SHRINIWAS",
                "SecurityName": "SHRI NIWAS LEASING AND FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE201F01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538902",
                "SecurityId": "DHUNTEAIND",
                "SecurityName": "DHUNSERI TEA & INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE341R01014",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538918",
                "SecurityId": "VANICOM",
                "SecurityName": "VANI COMMERCIALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE661Q01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538919",
                "SecurityId": "SKILVEN",
                "SecurityName": "SKYLINE VENTURES INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE055Q01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538920",
                "SecurityId": "SPACEAGE",
                "SecurityName": "SPACEAGE PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE498Q01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538921",
                "SecurityId": "RAFL",
                "SecurityName": "RAGHUVANSH AGROFARMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE865P01016",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538922",
                "SecurityId": "COSYN",
                "SecurityName": "COSYN LTD",
                "FaceValue": "10",
                "ISINNo": "INE029B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538923",
                "SecurityId": "SOFCOM",
                "SecurityName": "SOFCOM SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE499Q01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538924",
                "SecurityId": "IPRU2591",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 7 (REGULAR PLAN - DIVIDEND PAYOUT)",
                "FaceValue": "10",
                "ISINNo": "INF109KA18P1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538925",
                "SecurityId": "IPRU8793",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 7 (DIRECT PLAN - DIVIDEND PAYOUT)",
                "FaceValue": "10",
                "ISINNo": "INF109KA19P9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538926",
                "SecurityId": "NAPL",
                "SecurityName": "NATURITE AGRO PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE223R01014",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538928",
                "SecurityId": "KARNAVATI",
                "SecurityName": "KARNAVATI FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE554R01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538935",
                "SecurityId": "ABHIFIN",
                "SecurityName": "ABHISHEK FINLEASE LTD",
                "FaceValue": "10",
                "ISINNo": "INE723C01015",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538936",
                "SecurityId": "IPRU2598",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 8 (REGULAR PLAN - DIVIDEND PAYOUT)",
                "FaceValue": "10",
                "ISINNo": "INF109KA19R5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538937",
                "SecurityId": "IPRU8800",
                "SecurityName": "ICICI PRUDENTIAL GROWTH FUND SERIES 8 (DIRECT PLAN - DIVIDEND PAYOUT)",
                "FaceValue": "10",
                "ISINNo": "INF109KA10S2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538938",
                "SecurityId": "RCBFIIBDD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES B - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1ZJ4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538939",
                "SecurityId": "RCBFIIBDG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES B - DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1ZI6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538940",
                "SecurityId": "RCBFIIBD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES B -  DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1ZH8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538941",
                "SecurityId": "RCBFIIBG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES B -  GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1ZG0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538942",
                "SecurityId": "MERCANTILE",
                "SecurityName": "MERCANTILE VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE689O01013",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538943",
                "SecurityId": "SONAL",
                "SecurityName": "SONAL MERCANTILE LTD",
                "FaceValue": "10",
                "ISINNo": "INE321M01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538952",
                "SecurityId": "ALFL",
                "SecurityName": "ABHINAV LEASING & FINANCE LTD",
                "FaceValue": "1",
                "ISINNo": "INE211D01027",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538957",
                "SecurityId": "LICNFR3G",
                "SecurityName": "LIC MF RGESS FUND SERIES- 3 -REGULAR PLAN- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01MX1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538958",
                "SecurityId": "LICNFR3D",
                "SecurityName": "LIC MF RGESS FUND SERIES -3- REGULAR PLAN -DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01MW3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538959",
                "SecurityId": "LICNFR3G1",
                "SecurityName": "LIC MF RGESS FUND SERIES -3 -DIRECT PLAN -GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01MZ6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538960",
                "SecurityId": "LICNFR3D1",
                "SecurityName": "LIC MF RGESS FUND SERIES- 3 -DIRECT PLAN -DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF767K01MY9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538961",
                "SecurityId": "GENUSPAPER",
                "SecurityName": "GENUS PAPER & BOARDS LTD",
                "FaceValue": "1",
                "ISINNo": "INE949P01018",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538962",
                "SecurityId": "MINDACORP",
                "SecurityName": "MINDA CORPORATION LTD",
                "FaceValue": "2",
                "ISINNo": "INE842C01021",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538963",
                "SecurityId": "PACT",
                "SecurityName": "PACT INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE494K01024",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538964",
                "SecurityId": "MERCURYLAB",
                "SecurityName": "MERCURY LABORATORIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE947G01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538965",
                "SecurityId": "CONCORD",
                "SecurityName": "CONCORD DRUGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE858L01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538970",
                "SecurityId": "WARDINMOBI",
                "SecurityName": "WARDWIZARD INNOVATIONS & MOBILITY LTD",
                "FaceValue": "10",
                "ISINNo": "INE945P01016",
                "Industry": "2/3 Wheelers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538975",
                "SecurityId": "SHREESEC",
                "SecurityName": "SHREE SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE397C01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538979",
                "SecurityId": "GRNLAMIND",
                "SecurityName": "GREENLAM INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE544R01013",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538980",
                "SecurityId": "HFEFDG",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN A - DIRECT OPTION- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KA1T68",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538981",
                "SecurityId": "HFEFDD",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN A - DIRECT OPTION- DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KA1T76",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538982",
                "SecurityId": "HFEFRG",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN A-REGULAR OPTION- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KA1T84",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538983",
                "SecurityId": "HFEFRD",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN A-REGULAR OPTION - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KA1T92",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538987",
                "SecurityId": "TALBROSENG",
                "SecurityName": "TALBROS ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE717E01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538992",
                "SecurityId": "SAPL",
                "SecurityName": "SAR AUTO PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE002E01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "538993",
                "SecurityId": "PUROHITCON",
                "SecurityName": "PUROHIT CONSTRUCTION LTD",
                "FaceValue": "10",
                "ISINNo": "INE147J01012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539005",
                "SecurityId": "JAYATMA",
                "SecurityName": "JAYATMA ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE246D01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539006",
                "SecurityId": "PTCIL",
                "SecurityName": "PTC INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE596F01018",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539007",
                "SecurityId": "PINCONLIFE",
                "SecurityName": "PINCON LIFESTYLE LTD",
                "FaceValue": "10",
                "ISINNo": "INE115R01012",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539008",
                "SecurityId": "TIRUFIN",
                "SecurityName": "TIRUPATI FINCORP LTD",
                "FaceValue": "10",
                "ISINNo": "INE642O01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539009",
                "SecurityId": "GBLIL",
                "SecurityName": "GBL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE003Q01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539010",
                "SecurityId": "SMADL",
                "SecurityName": "SHRI MAHALAXMI AGRICULTURAL DEVELOPMENT LTD",
                "FaceValue": "5",
                "ISINNo": "INE317G01025",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539011",
                "SecurityId": "CHENFERRO",
                "SecurityName": "CHENNAI FERROUS INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE777O01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539012",
                "SecurityId": "MEGRISOFT",
                "SecurityName": "MEGRI SOFT LTD",
                "FaceValue": "10",
                "ISINNo": "INE756R01013",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539013",
                "SecurityId": "GITARENEW",
                "SecurityName": "GITA RENEWABLE ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE776O01018",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539014",
                "SecurityId": "KALPACOMME",
                "SecurityName": "KALPA COMMERCIAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE059Q01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539015",
                "SecurityId": "ORTEL",
                "SecurityName": "ORTEL COMMUNICATIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE849L01019",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539016",
                "SecurityId": "NEIL",
                "SecurityName": "NEIL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE396C01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539017",
                "SecurityId": "ASHFL",
                "SecurityName": "AKME STAR HOUSING FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE526R01010",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539018",
                "SecurityId": "BEEKAY",
                "SecurityName": "BEEKAY STEEL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE213D01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539026",
                "SecurityId": "SSPNFIN",
                "SecurityName": "SSPN FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE820R01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539031",
                "SecurityId": "SETFBSE100",
                "SecurityName": "SBI MUTUAL FUND - SBI - ETF BSE 100",
                "FaceValue": "10",
                "ISINNo": "INF200KA1572",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539032",
                "SecurityId": "FRASER",
                "SecurityName": "FRASER AND COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE184Q01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539040",
                "SecurityId": "TTIL",
                "SecurityName": "TIRUPATI TYRES LTD",
                "FaceValue": "10",
                "ISINNo": "INE812Q01016",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539041",
                "SecurityId": "SVPHOUSING",
                "SecurityName": "SVP HOUSING LTD",
                "FaceValue": "10",
                "ISINNo": "INE369Q01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539042",
                "SecurityId": "AGIIL",
                "SecurityName": "AGI INFRA LTD",
                "FaceValue": "10",
                "ISINNo": "INE976R01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539043",
                "SecurityId": "BKMINDST",
                "SecurityName": "BKM INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE831Q01016",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539044",
                "SecurityId": "MANAKSTELTD",
                "SecurityName": "MANAKSIA STEELS LTD",
                "FaceValue": "1",
                "ISINNo": "INE824Q01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539045",
                "SecurityId": "MNKALCOLTD",
                "SecurityName": "MANAKSIA ALUMINIUM COMPANY LTD",
                "FaceValue": "1",
                "ISINNo": "INE859Q01017",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539046",
                "SecurityId": "MNKCMILTD",
                "SecurityName": "MANAKSIA COATED METALS & INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE830Q01018",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539052",
                "SecurityId": "BSLFEFS5RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 5 REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1B55",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539053",
                "SecurityId": "BSLFEFS5RN",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 5 REGULAR DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KA1B63",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539054",
                "SecurityId": "BSLFEFS5DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 5 DIRECT GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA1B71",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539055",
                "SecurityId": "BSLFEFS5DN",
                "SecurityName": "ADITYA BIRLA SUN LIFE FOCUSED EQUITY FUND - SERIES 5 DIRECT DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KA1B89",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539056",
                "SecurityId": "IMAGICAA",
                "SecurityName": "IMAGICAAWORLD ENTERTAINMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE172N01012",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539063",
                "SecurityId": "RCBFIICDD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES C - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1F86",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539065",
                "SecurityId": "RCBFIICDG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES C - DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1F78",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539067",
                "SecurityId": "RCBFIICD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES C -  DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1F60",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539070",
                "SecurityId": "RCBFIICG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND II - SERIES C -  GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA1F52",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539083",
                "SecurityId": "INOXWIND",
                "SecurityName": "INOX WIND LTD",
                "FaceValue": "10",
                "ISINNo": "INE066P01011",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539084",
                "SecurityId": "IPRU2619",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND - SERIES 1 (REGULAR PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KB1110",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539085",
                "SecurityId": "IPRU8821",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND - SERIES 1 (DIRECT PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KB1128",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539090",
                "SecurityId": "RAJPUTANA",
                "SecurityName": "RAJPUTANA INVESTMENT AND FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE751R01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539091",
                "SecurityId": "CITL",
                "SecurityName": "CONSECUTIVE INVESTMENT & TRADING COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE187R01011",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539092",
                "SecurityId": "IPRU8841",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 6- DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1573",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539093",
                "SecurityId": "IPRU8842",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 6- DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1581",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539094",
                "SecurityId": "IPRU2639",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 6- REGULAR PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1557",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539095",
                "SecurityId": "IPRU2640",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 6- REGULAR PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1565",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539096",
                "SecurityId": "AANANDALAK",
                "SecurityName": "AANANDA LAKSHMI SPINNING MILLS LTD",
                "FaceValue": "10",
                "ISINNo": "INE197R01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539097",
                "SecurityId": "HKG",
                "SecurityName": "HKG LTD",
                "FaceValue": "2",
                "ISINNo": "INE904R01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539098",
                "SecurityId": "FILTRA",
                "SecurityName": "FILTRA CONSULTANTS AND ENGINEERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE541R01019",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539099",
                "SecurityId": "ATHCON",
                "SecurityName": "ATHENA CONSTRUCTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE291R01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539100",
                "SecurityId": "IPRU2626",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND - SERIES 2 (REGULAR PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KB1292",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539101",
                "SecurityId": "IPRU8828",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND - SERIES 2 (DIRECT PLAN - DIVIDEND PAYOUT OPTION)",
                "FaceValue": "10",
                "ISINNo": "INF109KB1300",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539110",
                "SecurityId": "NORTHLINK",
                "SecurityName": "NORTHLINK FISCAL AND CAPITAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE736P01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539111",
                "SecurityId": "SHESHAINDS",
                "SecurityName": "SHESHADRI INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE193R01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539112",
                "SecurityId": "SAB",
                "SecurityName": "SAB INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE137M01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539113",
                "SecurityId": "PML",
                "SecurityName": "PAUL MERCHANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE291E01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539114",
                "SecurityId": "HINDSECR",
                "SecurityName": "HIND SECURITIES & CREDITS LTD",
                "FaceValue": "10",
                "ISINNo": "INE727Q01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539115",
                "SecurityId": "ALAN SCOTT",
                "SecurityName": "ALAN SCOTT INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE273F01022",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539116",
                "SecurityId": "OPCHAINS",
                "SecurityName": "O. P. CHAINS LTD",
                "FaceValue": "10",
                "ISINNo": "INE118Q01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539117",
                "SecurityId": "SUJALA",
                "SecurityName": "SUJALA TRADING & HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE029H01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539118",
                "SecurityId": "VRLLOG",
                "SecurityName": "VRL LOGISTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE366I01010",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539119",
                "SecurityId": "JAINMARMO",
                "SecurityName": "JAIN MARMO INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE780Q01015",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539120",
                "SecurityId": "BFFL",
                "SecurityName": "BANGALORE FORT FARMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE578R01011",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539121",
                "SecurityId": "PALCO",
                "SecurityName": "PALCO METALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE239L01013",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539122",
                "SecurityId": "BODHTREE",
                "SecurityName": "BODHTREE CONSULTING LTD",
                "FaceValue": "10",
                "ISINNo": "INE104F01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539123",
                "SecurityId": "VBIND",
                "SecurityName": "V B INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE334E01017",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539124",
                "SecurityId": "SARVOTTAM",
                "SecurityName": "SARVOTTAM FINVEST LTD",
                "FaceValue": "10",
                "ISINNo": "INE822Q01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539125",
                "SecurityId": "LAYLA",
                "SecurityName": "LAYLA TEXTILE AND TRADERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE914Q01010",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539126",
                "SecurityId": "MEP",
                "SecurityName": "MEP INFRASTRUCTURE DEVELOPERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE776I01010",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539131",
                "SecurityId": "CLLIMITED",
                "SecurityName": "CRESCENT LEASING LTD",
                "FaceValue": "10",
                "ISINNo": "INE767G01013",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539132",
                "SecurityId": "VEGETABLE",
                "SecurityName": "VEGETABLE PRODUCTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE761D01021",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539133",
                "SecurityId": "KTKKIGFG",
                "SecurityName": "KOTAK MAHINDRA MUTUAL FUND - KOTAK INDIA GROWTH FUND SERIES I NON DIRECT PLAN- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K01P24",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539134",
                "SecurityId": "HFEFBRG",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN B - REGULAR OPTION- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF179KA18O7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539135",
                "SecurityId": "KTKKIGFD",
                "SecurityName": "KOTAK MAHINDRA MUTUAL FUND - KOTAK INDIA GROWTH FUND SERIES I NON DIRECT PLAN- DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K01P32",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539136",
                "SecurityId": "KTKKIGFGD",
                "SecurityName": "KOTAK MAHINDRA MUTUAL FUND - KOTAK INDIA GROWTH FUND SERIES I DIRECT PLAN- GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K01P40",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539137",
                "SecurityId": "KTKKIGFDD",
                "SecurityName": "KOTAK MAHINDRA MUTUAL FUND - KOTAK INDIA GROWTH FUND SERIES I DIRECT PLAN- DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K01P57",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539138",
                "SecurityId": "HFEFBRD",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN B - REGULAR OPTION- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF179KA19O5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539139",
                "SecurityId": "HFEFBDG",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN A - DIRECT OPTION - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF179KA16O1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539140",
                "SecurityId": "HFEFBDD",
                "SecurityName": "HDFC FOCUSED EQUITY FUND PLAN A- DIRECT OPTION - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF179KA17O9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539141",
                "SecurityId": "UFO",
                "SecurityName": "UFO MOVIEZ INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE527H01019",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539142",
                "SecurityId": "NIFTYEES",
                "SecurityName": "EDELWEISS MUTUAL FUND - EDELWEISS EXCHANGE TRADED SCHEME - NIFTY ( NIFTY EES )",
                "FaceValue": "10",
                "ISINNo": "INF754K01EK3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539143",
                "SecurityId": "PANTH",
                "SecurityName": "PANTH INFINITY LTD",
                "FaceValue": "10",
                "ISINNo": "INE945O01019",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539148",
                "SecurityId": "SHIVALIK",
                "SecurityName": "SHIVALIK RASAYAN LTD",
                "FaceValue": "5",
                "ISINNo": "INE788J01021",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539149",
                "SecurityId": "ICSL",
                "SecurityName": "INTEGRATED CAPITAL SERVICES LTD",
                "FaceValue": "1",
                "ISINNo": "INE682B01023",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539150",
                "SecurityId": "PNCINFRA",
                "SecurityName": "PNC INFRATECH LTD",
                "FaceValue": "2",
                "ISINNo": "INE195J01029",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539151",
                "SecurityId": "ARFIN",
                "SecurityName": "ARFIN INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE784R01015",
                "Industry": "Aluminium",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539167",
                "SecurityId": "VLL",
                "SecurityName": "VIRAT LEASING LTD",
                "FaceValue": "10",
                "ISINNo": "INE347L01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539168",
                "SecurityId": "SPISYS",
                "SecurityName": "SPISYS LTD",
                "FaceValue": "10",
                "ISINNo": "INE805E01016",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539169",
                "SecurityId": "FUNNY",
                "SecurityName": "FUNNY SOFTWARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE105R01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539174",
                "SecurityId": "HELPAGE",
                "SecurityName": "HELPAGE FINLEASE LTD",
                "FaceValue": "10",
                "ISINNo": "INE738P01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539175",
                "SecurityId": "INDRAIND",
                "SecurityName": "INDRA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE924N01016",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539176",
                "SecurityId": "HAWAENG",
                "SecurityName": "HAWA ENGINEERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE230I01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539177",
                "SecurityId": "AIIL",
                "SecurityName": "AUTHUM INVESTMENT & INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE206F01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539178",
                "SecurityId": "PELTD",
                "SecurityName": "POSITIVE ELECTRONICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE813P01016",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539179",
                "SecurityId": "IPRU2670",
                "SecurityName": "ICICI PRUDENTIAL  VALUE FUND SERIES 7",
                "FaceValue": "10",
                "ISINNo": "INF109KB1EU1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539180",
                "SecurityId": "IPRU8872",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 7",
                "FaceValue": "10",
                "ISINNo": "INF109KB1EW7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539189",
                "SecurityId": "ADHBHUTIN",
                "SecurityName": "ADHBHUT INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE578L01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539190",
                "SecurityId": "DFL",
                "SecurityName": "DECILLION FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE848N01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539195",
                "SecurityId": "POEL",
                "SecurityName": "POCL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE035S01010",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539196",
                "SecurityId": "AEL",
                "SecurityName": "AMBA ENTERPRISES LTD",
                "FaceValue": "5",
                "ISINNo": "INE829P01020",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539197",
                "SecurityId": "DEVHARI",
                "SecurityName": "DEVHARI EXPORTS (INDIA) LTD",
                "FaceValue": "1",
                "ISINNo": "INE553P01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539198",
                "SecurityId": "CAPFIN",
                "SecurityName": "CAPFIN INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE960C01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539199",
                "SecurityId": "MOONGIPASEC",
                "SecurityName": "MOONGIPA SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE618R01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539200",
                "SecurityId": "NOBPOL",
                "SecurityName": "NOBLE POLYMERS LTD",
                "FaceValue": "5",
                "ISINNo": "INE203Q01026",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539201",
                "SecurityId": "SATIA",
                "SecurityName": "SATIA INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE170E01023",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539202",
                "SecurityId": "RCBFIIIAX",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND III SERIES A - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA16D0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539203",
                "SecurityId": "RCBFIIIAZ",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND III SERIES A- DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF204KA15D2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539204",
                "SecurityId": "RCBFIIIAD",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND III SERIES A- DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KA14D5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539205",
                "SecurityId": "RCBFIIIAG",
                "SecurityName": "RELIANCE CAPITAL BUILDER FUND III SERIES A- GROWTH PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF204KA13D7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539206",
                "SecurityId": "GVBL",
                "SecurityName": "GENOMIC VALLEY BIOTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE574D01010",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539207",
                "SecurityId": "MANPASAND",
                "SecurityName": "MANPASAND BEVERAGES LTD",
                "FaceValue": "10",
                "ISINNo": "INE122R01018",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539216",
                "SecurityId": "GARMNTMNTR",
                "SecurityName": "GARMENT MANTRA LIFESTYLE LTD",
                "FaceValue": "10",
                "ISINNo": "INE653S01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539217",
                "SecurityId": "SRESTHA",
                "SecurityName": "SRESTHA FINVEST LTD",
                "FaceValue": "2",
                "ISINNo": "INE606K01023",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539218",
                "SecurityId": "SAUMYA",
                "SecurityName": "SAUMYA CONSULTANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE563C01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539219",
                "SecurityId": "MUL",
                "SecurityName": "MAURIA UDYOG LTD",
                "FaceValue": "10",
                "ISINNo": "INE150D01019",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539220",
                "SecurityId": "MISHKA",
                "SecurityName": "MISHKA EXIM LTD",
                "FaceValue": "10",
                "ISINNo": "INE540S01019",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539221",
                "SecurityId": "SPORTKING",
                "SecurityName": "SPORTKING INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE885H01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539222",
                "SecurityId": "VMV",
                "SecurityName": "VMV HOLIDAYS LTD",
                "FaceValue": "10",
                "ISINNo": "INE451S01019",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539223",
                "SecurityId": "AMBITION",
                "SecurityName": "AMBITION MICA LTD",
                "FaceValue": "10",
                "ISINNo": "INE563S01011",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539224",
                "SecurityId": "HCKKVENTURE",
                "SecurityName": "HCKK VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE345Q01017",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539225",
                "SecurityId": "JIYAECO",
                "SecurityName": "JIYA ECO-PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE023S01016",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539226",
                "SecurityId": "RUDRA",
                "SecurityName": "RUDRA GLOBAL INFRA PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE027T01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539227",
                "SecurityId": "LOYAL",
                "SecurityName": "LOYAL EQUIPMENTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE876S01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539228",
                "SecurityId": "GGPL",
                "SecurityName": "GALA GLOBAL PRODUCTS LTD",
                "FaceValue": "5",
                "ISINNo": "INE480S01026",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539229",
                "SecurityId": "MRSS",
                "SecurityName": "MAJESTIC RESEARCH SERVICES AND SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE196R01012",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539230",
                "SecurityId": "CHEMIESYNT",
                "SecurityName": "CHEMIESYNTH (VAPI) LTD",
                "FaceValue": "10",
                "ISINNo": "INE829R01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539235",
                "SecurityId": "GPL",
                "SecurityName": "GRANDEUR PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE545R01010",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539246",
                "SecurityId": "JEL",
                "SecurityName": "JYOTIRGAMYA ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE805R01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539251",
                "SecurityId": "BALKRISHNA",
                "SecurityName": "BALKRISHNA PAPER MILLS LTD",
                "FaceValue": "10",
                "ISINNo": "INE875R01011",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539252",
                "SecurityId": "SCFL",
                "SecurityName": "SHYAM CENTURY FERROUS LTD",
                "FaceValue": "1",
                "ISINNo": "INE979R01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539253",
                "SecurityId": "SURYAINDIA",
                "SecurityName": "SURYA INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE446E01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539254",
                "SecurityId": "ADANITRANS",
                "SecurityName": "ADANI TRANSMISSION LTD",
                "FaceValue": "10",
                "ISINNo": "INE931S01010",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539255",
                "SecurityId": "STARDELTA",
                "SecurityName": "STAR DELTA TRANSFORMERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE541K01014",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539265",
                "SecurityId": "AMRAFIN",
                "SecurityName": "AMRAPALI FINCAP LTD",
                "FaceValue": "10",
                "ISINNo": "INE990S01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539266",
                "SecurityId": "CONCRETE",
                "SecurityName": "CONCRETE CREDIT LTD",
                "FaceValue": "10",
                "ISINNo": "INE719G01014",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539267",
                "SecurityId": "DRHABEEB",
                "SecurityName": "DR HABEEBULLAH LIFE SCIENCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE579N01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539268",
                "SecurityId": "SYNGENE",
                "SecurityName": "SYNGENE INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE398R01022",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539273",
                "SecurityId": "PECOS",
                "SecurityName": "PECOS HOTELS AND PUBS LTD",
                "FaceValue": "10",
                "ISINNo": "INE484S01010",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539274",
                "SecurityId": "BLFL",
                "SecurityName": "BOSTON LEASING AND FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE962P01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539275",
                "SecurityId": "MSL",
                "SecurityName": "MANGALAM SEEDS LTD",
                "FaceValue": "10",
                "ISINNo": "INE829S01016",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539276",
                "SecurityId": "KAYA",
                "SecurityName": "KAYA LTD",
                "FaceValue": "10",
                "ISINNo": "INE587G01015",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539277",
                "SecurityId": "ALSTONE",
                "SecurityName": "ALSTONE TEXTILES (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE184S01016",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539278",
                "SecurityId": "SYMBIOX",
                "SecurityName": "SYMBIOX INVESTMENT & TRADING COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE653R01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539285",
                "SecurityId": "IPRU8895",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 8 - DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1JK0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539286",
                "SecurityId": "IPRU2693",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 8 - REGULAR PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1JJ2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539287",
                "SecurityId": "ORTINLAABS",
                "SecurityName": "ORTIN LABORATORIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE749B01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539288",
                "SecurityId": "AVI",
                "SecurityName": "AVI POLYMERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE897N01014",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539289",
                "SecurityId": "MJCO",
                "SecurityName": "MAJESCO LTD",
                "FaceValue": "5",
                "ISINNo": "INE898S01029",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539290",
                "SecurityId": "BINDALAGRO",
                "SecurityName": "OSWAL GREENTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE143A01010",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539291",
                "SecurityId": "OZONEWORLD",
                "SecurityName": "OZONE WORLD LTD",
                "FaceValue": "10",
                "ISINNo": "INE583K01016",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539300",
                "SecurityId": "AKSPINTEX",
                "SecurityName": "A.K. SPINTEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE671K01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539301",
                "SecurityId": "ARVSMART",
                "SecurityName": "ARVIND SMARTSPACES LTD",
                "FaceValue": "10",
                "ISINNo": "INE034S01021",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539302",
                "SecurityId": "POWERMECH",
                "SecurityName": "POWER MECH PROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE211R01019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539303",
                "SecurityId": "MINDAFIN",
                "SecurityName": "MINDA FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE197E01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539304",
                "SecurityId": "CANOPYFIN",
                "SecurityName": "CANOPY FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE095R01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539309",
                "SecurityId": "RAMASTEEL",
                "SecurityName": "RAMA STEEL TUBES LTD",
                "FaceValue": "5",
                "ISINNo": "INE230R01027",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539310",
                "SecurityId": "THINKINK",
                "SecurityName": "THINKINK PICTUREZ LTD",
                "FaceValue": "5",
                "ISINNo": "INE365S01037",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539311",
                "SecurityId": "NFIL",
                "SecurityName": "NISHTHA FINANCE AND INVESTMENT (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE217Q01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539312",
                "SecurityId": "UTISENSETF",
                "SecurityName": "UTI- SENSEX ETF",
                "FaceValue": "10",
                "ISINNo": "INF789FB1X58",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539313",
                "SecurityId": "UTINIFTETF",
                "SecurityName": "UTI NIFTY ETF",
                "FaceValue": "10",
                "ISINNo": "INF789FB1X41",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539314",
                "SecurityId": "UNIAUTO",
                "SecurityName": "UNIVERSAL AUTOFOUNDRY LTD",
                "FaceValue": "10",
                "ISINNo": "INE203T01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539319",
                "SecurityId": "IPRU8917",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 3 - DIRECT PLAN - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1ME8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539320",
                "SecurityId": "IPRU8910",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 3 - DIRECT PLAN - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB1LQ4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539321",
                "SecurityId": "IPRU2715",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 3 - REGULAR PLAN - CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB1MD0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539322",
                "SecurityId": "IPRU2708",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 3 - REGULAR PLAN - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1LP6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539331",
                "SecurityId": "VETO",
                "SecurityName": "VETO SWITCHGEARS AND CABLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE918N01018",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539332",
                "SecurityId": "NAVKARCORP",
                "SecurityName": "NAVKAR CORPORATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE278M01019",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539333",
                "SecurityId": "PENPEBS",
                "SecurityName": "PENNAR ENGINEERED BUILDING SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE455O01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539334",
                "SecurityId": "SHREEPUSHK",
                "SecurityName": "SHREE PUSHKAR CHEMICALS & FERTILISERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE712K01011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539335",
                "SecurityId": "CHPLIND",
                "SecurityName": "CHPL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE714Q01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539336",
                "SecurityId": "GUJGAS",
                "SecurityName": "GUJARAT GAS LTD",
                "FaceValue": "2",
                "ISINNo": "INE844O01030",
                "Industry": "Integrated Oil & Gas",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539337",
                "SecurityId": "WAAREE",
                "SecurityName": "WAAREE TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE725P01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539346",
                "SecurityId": "SADBHIN",
                "SecurityName": "SADBHAV INFRASTRUCTURE PROJECT LTD",
                "FaceValue": "10",
                "ISINNo": "INE764L01010",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539347",
                "SecurityId": "PHL",
                "SecurityName": "PNEUMATIC HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE096T01010",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539351",
                "SecurityId": "PRABHAT",
                "SecurityName": "PRABHAT DAIRY LTD",
                "FaceValue": "10",
                "ISINNo": "INE302M01033",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539352",
                "SecurityId": "PBFL",
                "SecurityName": "P. B. FILMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE212Q01019",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539353",
                "SecurityId": "SALAUTO",
                "SecurityName": "SAL AUTOMOTIVE LTD",
                "FaceValue": "10",
                "ISINNo": "INE724G01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539354",
                "SecurityId": "POLYSPIN",
                "SecurityName": "POLYSPIN EXPORTS LTD",
                "FaceValue": "5",
                "ISINNo": "INE914G01029",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539359",
                "SecurityId": "PRITIKAUTO",
                "SecurityName": "PRITIKA AUTO INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE583R01011",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539363",
                "SecurityId": "SKC",
                "SecurityName": "SRI KRISHNA CONSTRUCTIONS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE094T01015",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539378",
                "SecurityId": "SML",
                "SecurityName": "SONI MEDICARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE848R01018",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539383",
                "SecurityId": "MINFY",
                "SecurityName": "MAHAVEER INFOWAY LTD",
                "FaceValue": "10",
                "ISINNo": "INE019D01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539384",
                "SecurityId": "KRISHNACAP",
                "SecurityName": "KRISHNA CAPITAL AND SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE897B01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539391",
                "SecurityId": "ACME",
                "SecurityName": "ACME RESOURCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE636B01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539392",
                "SecurityId": "SANGFROID",
                "SecurityName": "SANG FROID LABS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE345S01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539393",
                "SecurityId": "KCL",
                "SecurityName": "KABRA COMMERCIAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE926E01010",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539394",
                "SecurityId": "IPRU8923",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND- DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1MV2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539395",
                "SecurityId": "IPRU8924",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND- DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1MW0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539396",
                "SecurityId": "IPRU2721",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND- REGULAR PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1MX8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539397",
                "SecurityId": "IPRU2722",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND- REGULAR PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1MY6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539398",
                "SecurityId": "VISHALBL",
                "SecurityName": "VISHAL BEARINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE060T01024",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539399",
                "SecurityId": "BELLACASA",
                "SecurityName": "BELLA CASA FASHION & RETAIL LTD",
                "FaceValue": "10",
                "ISINNo": "INE344T01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539400",
                "SecurityId": "MALLCOM",
                "SecurityName": "MALLCOM (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE389C01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539401",
                "SecurityId": "PJL",
                "SecurityName": "PATDIAM JEWELLERY LTD",
                "FaceValue": "10",
                "ISINNo": "INE473T01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539402",
                "SecurityId": "VAL",
                "SecurityName": "VAKSONS AUTOMOBILES LTD",
                "FaceValue": "10",
                "ISINNo": "INE256S01012",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539403",
                "SecurityId": "ARAMBHAN",
                "SecurityName": "ARAMBHAN HOSPITALITY SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE800S01017",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539404",
                "SecurityId": "SATIN",
                "SecurityName": "SATIN CREDITCARE NETWORK LTD",
                "FaceValue": "10",
                "ISINNo": "INE836B01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539405",
                "SecurityId": "DITCO",
                "SecurityName": "DECOROUS INVESTMENT & TRADING CO LTD",
                "FaceValue": "10",
                "ISINNo": "INE183R01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539406",
                "SecurityId": "SWAGTAM",
                "SecurityName": "SWAGTAM TRADING & SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE150R01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539407",
                "SecurityId": "GENCON",
                "SecurityName": "GENERIC ENGINEERING CONSTRUCTION AND PROJECTS LTD",
                "FaceValue": "5",
                "ISINNo": "INE854S01022",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539408",
                "SecurityId": "KUBERJI",
                "SecurityName": "KUBER UDYOG LTD",
                "FaceValue": "10",
                "ISINNo": "INE594R01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539409",
                "SecurityId": "NEERAJ",
                "SecurityName": "NEERAJ PAPER MARKETING LTD",
                "FaceValue": "10",
                "ISINNo": "INE894J01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539410",
                "SecurityId": "MUZALI",
                "SecurityName": "MUZALI ARTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE873S01022",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539420",
                "SecurityId": "BSLFEFS6RG",
                "SecurityName": "BIRLA SUN LIFE FOCUSED EQUITY FUND- SERIES 6- REGULAR PLAN- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA18K0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539421",
                "SecurityId": "BSLFEFS6RN",
                "SecurityName": "BIRLA SUN LIFE FOCUSED EQUITY FUND- SERIES 6- REGULAR PLAN- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KA19K8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539422",
                "SecurityId": "BSLFEFS6DG",
                "SecurityName": "BIRLA SUN LIFE FOCUSED EQUITY FUND- SERIES 6- DIRECT PLAN- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KA10L5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539423",
                "SecurityId": "BSLFEFS6DN",
                "SecurityName": "BIRLA SUN LIFE FOCUSED EQUITY FUND- SERIES 6- DIRECT PLAN- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KA11L3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539428",
                "SecurityId": "TEJNAKSH",
                "SecurityName": "TEJNAKSH HEALTHCARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE030T01019",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539433",
                "SecurityId": "INDOGLOBAL",
                "SecurityName": "INDO-GLOBAL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE400S01016",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539434",
                "SecurityId": "BRIPORT",
                "SecurityName": "BRILLIANT PORTFOLIOS LTD",
                "FaceValue": "10",
                "ISINNo": "INE661F01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539435",
                "SecurityId": "RFSL",
                "SecurityName": "RICHFIELD FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE201C01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539436",
                "SecurityId": "COFFEEDAY",
                "SecurityName": "COFFEE DAY ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE335K01011",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539437",
                "SecurityId": "IDFCFIRSTB",
                "SecurityName": "IDFC FIRST BANK LTD",
                "FaceValue": "10",
                "ISINNo": "INE092T01019",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539446",
                "SecurityId": "KRISHFAB",
                "SecurityName": "KRISHANA FABRICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE627Q01018",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539447",
                "SecurityId": "BEARDSELL",
                "SecurityName": "BEARDSELL LTD",
                "FaceValue": "2",
                "ISINNo": "INE520H01022",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539448",
                "SecurityId": "INDIGO",
                "SecurityName": "INTERGLOBE AVIATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE646L01027",
                "Industry": "Airlines",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539449",
                "SecurityId": "IGCIL",
                "SecurityName": "IGC INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE099S01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539450",
                "SecurityId": "SHK",
                "SecurityName": "S H KELKAR AND COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE500L01026",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539455",
                "SecurityId": "ARYAVAN",
                "SecurityName": "ARYAVAN ENTERPRISE LTD",
                "FaceValue": "10",
                "ISINNo": "INE360S01012",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539456",
                "SecurityId": "IPRU8937",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 2 DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1OH7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539457",
                "SecurityId": "IPRU8938",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 2 DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1OI5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539458",
                "SecurityId": "IPRU2735",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 2 REGULAR PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1OF1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539459",
                "SecurityId": "IPRU2736",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 2 REGULAR PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1OG9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539468",
                "SecurityId": "TFSL",
                "SecurityName": "TYPHOON FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE761R01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539469",
                "SecurityId": "PANORAMA",
                "SecurityName": "PANORAMA STUDIOS INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE258R01010",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539470",
                "SecurityId": "SHREEGANES",
                "SecurityName": "SHREE GANESH BIOTECH (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE051N01018",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539479",
                "SecurityId": "GTV",
                "SecurityName": "GTV ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE910R01016",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539480",
                "SecurityId": "LICNETFN50",
                "SecurityName": "LIC MF EXCHANGE TRADED FUND- NIFTY 50",
                "FaceValue": "10",
                "ISINNo": "INF767K01OS7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539481",
                "SecurityId": "PARIKSHA",
                "SecurityName": "PARIKSHA FIN- INVEST- LEASE LTD",
                "FaceValue": "10",
                "ISINNo": "INE270F01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539486",
                "SecurityId": "GEETANJ",
                "SecurityName": "GEETANJALI CREDIT AND CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE263R01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539487",
                "SecurityId": "LICNETFSEN",
                "SecurityName": "LIC MF EXCHANGE TRADED FUND- SENSEX",
                "FaceValue": "10",
                "ISINNo": "INF767K01OT5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539488",
                "SecurityId": "TFLL",
                "SecurityName": "TIRUPATI FINLEASE LTD",
                "FaceValue": "10",
                "ISINNo": "INE027S01017",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539492",
                "SecurityId": "GARBIFIN",
                "SecurityName": "GARBI FINVEST LTD",
                "FaceValue": "10",
                "ISINNo": "INE721C01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539493",
                "SecurityId": "ADHARSHILA",
                "SecurityName": "ADHARSHILA CAPITAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE269F01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539494",
                "SecurityId": "SMARTFIN",
                "SecurityName": "SMART FINSEC LTD",
                "FaceValue": "10",
                "ISINNo": "INE766D01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539495",
                "SecurityId": "RAJKOTINV",
                "SecurityName": "RAJKOT INVESTMENT TRUST LTD",
                "FaceValue": "10",
                "ISINNo": "INE176R01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539506",
                "SecurityId": "ADCON",
                "SecurityName": "ADCON CAPITAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE805Q01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539515",
                "SecurityId": "QUINT",
                "SecurityName": "QUINT DIGITAL MEDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE641R01017",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539516",
                "SecurityId": "HDFCNIFETF",
                "SecurityName": "HDFC NIFTY ETF",
                "FaceValue": "761.25",
                "ISINNo": "INF179KB1KP3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539517",
                "SecurityId": "SXETF",
                "SecurityName": "HDFC SENSEX ETF - OPEN ENDED TRADED FUND",
                "FaceValue": "2503.61",
                "ISINNo": "INF179KB1KQ1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539518",
                "SecurityId": "UDAYJEW",
                "SecurityName": "UDAY JEWELLERY INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE551B01012",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539519",
                "SecurityId": "MAYUKH",
                "SecurityName": "MAYUKH DEALTRADE LTD",
                "FaceValue": "10",
                "ISINNo": "INE280E01012",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539520",
                "SecurityId": "SHAILJA",
                "SecurityName": "SHAILJA COMMERCIAL TRADE FRENZY LTD",
                "FaceValue": "10",
                "ISINNo": "INE195R01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539521",
                "SecurityId": "NAVIGANT",
                "SecurityName": "NAVIGANT CORPORATE ADVISORS LTD",
                "FaceValue": "10",
                "ISINNo": "INE364T01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539522",
                "SecurityId": "GROVY",
                "SecurityName": "GROVY INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE343C01012",
                "Industry": "Real Estate Investment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539523",
                "SecurityId": "ALKEM",
                "SecurityName": "ALKEM LABORATORIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE540L01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539524",
                "SecurityId": "LALPATHLAB",
                "SecurityName": "DR. LAL PATHLABS LTD",
                "FaceValue": "10",
                "ISINNo": "INE600L01024",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539525",
                "SecurityId": "NAVKETAN",
                "SecurityName": "NAVKETAN MERCHANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE365G01016",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539526",
                "SecurityId": "SCTL",
                "SecurityName": "SUNCARE TRADERS LTD",
                "FaceValue": "2",
                "ISINNo": "INE452S01025",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539527",
                "SecurityId": "CREATIVE",
                "SecurityName": "CREATIVE CASTINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE146E01015",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539528",
                "SecurityId": "AAYUSH",
                "SecurityName": "AAYUSH FOOD AND HERBS LTD",
                "FaceValue": "10",
                "ISINNo": "INE430R01015",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539533",
                "SecurityId": "ELITECON",
                "SecurityName": "ELITECON INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE669R01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539542",
                "SecurityId": "LUXIND",
                "SecurityName": "LUX INDUSTRIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE150G01020",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539543",
                "SecurityId": "VALLEY",
                "SecurityName": "VALLEY MAGNESITE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE834E01016",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539544",
                "SecurityId": "ABHIINFRA",
                "SecurityName": "ABHISHEK INFRAVENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE281P01016",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539545",
                "SecurityId": "APOORVA",
                "SecurityName": "APOORVA LEASING FINANCE AND INVESTMENT COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE217S01014",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539546",
                "SecurityId": "BNL",
                "SecurityName": "BEEKAY NIRYAT LTD",
                "FaceValue": "10",
                "ISINNo": "INE679E01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539551",
                "SecurityId": "NH",
                "SecurityName": "NARAYANA HRUDAYALAYA LTD",
                "FaceValue": "10",
                "ISINNo": "INE410P01011",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539552",
                "SecurityId": "FMEC",
                "SecurityName": "F MEC INTERNATIONAL FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE108T01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539559",
                "SecurityId": "DDIL",
                "SecurityName": "DEEP DIAMOND INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE005G01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539560",
                "SecurityId": "ABHIJIT",
                "SecurityName": "ABHIJIT TRADING COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE994N01019",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539561",
                "SecurityId": "REMLIFE",
                "SecurityName": "REMEDIUM LIFECARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE549S01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539562",
                "SecurityId": "AARNAV",
                "SecurityName": "AARNAV FASHIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE750R01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539570",
                "SecurityId": "ACEWIN",
                "SecurityName": "ACEWIN AGRITECK LTD",
                "FaceValue": "10",
                "ISINNo": "INE742R01013",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539574",
                "SecurityId": "SCL",
                "SecurityName": "SUNSHINE CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE974F01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539584",
                "SecurityId": "SIPTL",
                "SecurityName": "SHARANAM INFRAPROJECT AND TRADING LTD",
                "FaceValue": "1",
                "ISINNo": "INE104S01022",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539593",
                "SecurityId": "SHIVA",
                "SecurityName": "SHIVANSH FINSERVE LTD",
                "FaceValue": "10",
                "ISINNo": "INE728Q01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539594",
                "SecurityId": "MISHTANN",
                "SecurityName": "MISHTANN FOODS LTD",
                "FaceValue": "1",
                "ISINNo": "INE094S01041",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539595",
                "SecurityId": "NATECO",
                "SecurityName": "NATCO ECONOMICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE174S01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539596",
                "SecurityId": "DELTA",
                "SecurityName": "DELTA INDUSTRIAL RESOURCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE681Q01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539597",
                "SecurityId": "JSLHISAR",
                "SecurityName": "JINDAL STAINLESS (HISAR) LTD",
                "FaceValue": "2",
                "ISINNo": "INE455T01018",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539598",
                "SecurityId": "ORACLECR",
                "SecurityName": "ORACLE CREDIT LTD",
                "FaceValue": "10",
                "ISINNo": "INE727C01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539599",
                "SecurityId": "KEL",
                "SecurityName": "KOTIA ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE079C01012",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539600",
                "SecurityId": "CHAMAK",
                "SecurityName": "CHAMAK HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE049R01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539607",
                "SecurityId": "BLUECLOUDS",
                "SecurityName": "BLUE CLOUD SOFTECH SOLUTIONS LTD",
                "FaceValue": "2",
                "ISINNo": "INE373T01039",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539620",
                "SecurityId": "AINFRA",
                "SecurityName": "A INFRASTRUCTURE LTD",
                "FaceValue": "5",
                "ISINNo": "INE534E01020",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539621",
                "SecurityId": "BCLENTERPR",
                "SecurityName": "BCL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE368E01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539622",
                "SecurityId": "IPRU8957",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 3 - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1PZ6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539623",
                "SecurityId": "IPRU8958",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 3 - DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1QA7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539624",
                "SecurityId": "IPRU2755",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 3 - REGULAR PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1PX1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539625",
                "SecurityId": "IPRU2756",
                "SecurityName": "ICICI PRUDENTIAL BUSINESS CYCLE FUND SERIES 3 - REGULAR PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1PY9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539632",
                "SecurityId": "AARCOM",
                "SecurityName": "AAR COMMERCIAL COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE184K01013",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539636",
                "SecurityId": "PRECAM",
                "SecurityName": "PRECISION CAMSHAFTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE484I01029",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539637",
                "SecurityId": "BVL",
                "SecurityName": "BLUEBLOOD VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE562S01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539658",
                "SecurityId": "TEAMLEASE",
                "SecurityName": "TEAMLEASE SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE985S01024",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539659",
                "SecurityId": "VIDLI",
                "SecurityName": "VIDLI RESTAURANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE564S01019",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539660",
                "SecurityId": "BESTAGRO",
                "SecurityName": "BEST AGROLIFE LTD",
                "FaceValue": "10",
                "ISINNo": "INE052T01013",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539661",
                "SecurityId": "ACEMEN",
                "SecurityName": "ACE MEN ENGG WORKS LTD",
                "FaceValue": "10",
                "ISINNo": "INE023R01018",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539662",
                "SecurityId": "BFLAFL",
                "SecurityName": "BFL ASSET FINVEST LTD",
                "FaceValue": "10",
                "ISINNo": "INE948Q01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539669",
                "SecurityId": "RGF",
                "SecurityName": "RGF CAPITAL MARKETS LTD",
                "FaceValue": "1",
                "ISINNo": "INE684D01025",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539673",
                "SecurityId": "RCL",
                "SecurityName": "RADHAGOBIND COMMERCIAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE792P01012",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539678",
                "SecurityId": "QUICKHEAL",
                "SecurityName": "QUICK HEAL TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE306L01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539679",
                "SecurityId": "KAPILRAJ",
                "SecurityName": "KAPIL RAJ FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE332Q01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539680",
                "SecurityId": "GANGAPHARM",
                "SecurityName": "GANGA PHARMACEUTICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE615T01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539681",
                "SecurityId": "DAL",
                "SecurityName": "DYNAMIC ARCHISTRUCTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE874E01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539682",
                "SecurityId": "SESL",
                "SecurityName": "SYLPH EDUCATION SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE622Q01019",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539683",
                "SecurityId": "SHIVKAMAL",
                "SecurityName": "SHIVKAMAL IMPEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE429R01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539686",
                "SecurityId": "KPEL",
                "SecurityName": "K.P. ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE127T01013",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539691",
                "SecurityId": "RGCEL",
                "SecurityName": "REAL GROWTH COMMERCIAL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE836D01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539692",
                "SecurityId": "IFINSER",
                "SecurityName": "INTERACTIVE FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE064T01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539693",
                "SecurityId": "ALORA",
                "SecurityName": "ALORA TRADING COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE527R01018",
                "Industry": "Real Estate Investment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539697",
                "SecurityId": "HILIKS",
                "SecurityName": "HILIKS TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE966Q01010",
                "Industry": "Real Estate Investment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539708",
                "SecurityId": "IPRU2766",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 4 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1QX9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539709",
                "SecurityId": "IPRU2767",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 4 - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1QV3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539710",
                "SecurityId": "IPRU8968",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 4 - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1QY7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539711",
                "SecurityId": "IPRU8969",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 4 - DIRECT PLAN DIVIDEND  OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1QW1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539724",
                "SecurityId": "HYPERSOFT",
                "SecurityName": "HYPERSOFT TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE039D01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539725",
                "SecurityId": "GOKULAGRO",
                "SecurityName": "GOKUL AGRO RESOURCES LTD",
                "FaceValue": "2",
                "ISINNo": "INE314T01025",
                "Industry": "Edible Oils",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539730",
                "SecurityId": "FREDUN",
                "SecurityName": "FREDUN PHARMACEUTICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE194R01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539742",
                "SecurityId": "SIMBHALS",
                "SecurityName": "SIMBHAOLI SUGARS LTD",
                "FaceValue": "10",
                "ISINNo": "INE748T01016",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539760",
                "SecurityId": "RELICAB",
                "SecurityName": "RELICAB CABLE MANUFACTURING LTD",
                "FaceValue": "10",
                "ISINNo": "INE773T01014",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539761",
                "SecurityId": "VKAL",
                "SecurityName": "VANTAGE KNOWLEDGE ACADEMY LTD",
                "FaceValue": "10",
                "ISINNo": "INE427T01017",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539762",
                "SecurityId": "MODCL",
                "SecurityName": "MODERN CONVERTERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE250S01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539767",
                "SecurityId": "MNIL",
                "SecurityName": "MEGA NIRMAN AND INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE216Q01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539770",
                "SecurityId": "DARJEELING",
                "SecurityName": "DARJEELING ROPEWAY COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE830S01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539773",
                "SecurityId": "ADVIKCA",
                "SecurityName": "ADVIK CAPITAL LTD",
                "FaceValue": "1",
                "ISINNo": "INE178T01024",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539784",
                "SecurityId": "LICNFNHGP",
                "SecurityName": "LIC MF EXCHANGE TRADED FUND- NIFTY 100",
                "FaceValue": "10",
                "ISINNo": "INF767K01PC8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539785",
                "SecurityId": "PDMJEPAPER",
                "SecurityName": "PUDUMJEE PAPER PRODUCTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE865T01018",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539786",
                "SecurityId": "DUNE",
                "SecurityName": "DUNE MERCANTILE LTD",
                "FaceValue": "10",
                "ISINNo": "INE322Q01016",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539787",
                "SecurityId": "HCG",
                "SecurityName": "HEALTHCARE GLOBAL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE075I01017",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539788",
                "SecurityId": "KDML",
                "SecurityName": "KHEMANI DISTRIBUTORS & MARKETING LTD",
                "FaceValue": "5",
                "ISINNo": "INE030U01025",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539798",
                "SecurityId": "UMIYA",
                "SecurityName": "UMIYA TUBES LTD",
                "FaceValue": "10",
                "ISINNo": "INE173U01015",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539799",
                "SecurityId": "BHARATWIRE",
                "SecurityName": "BHARAT WIRE ROPES LTD",
                "FaceValue": "10",
                "ISINNo": "INE316L01019",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539800",
                "SecurityId": "CHDCHEM",
                "SecurityName": "CHD CHEMICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE043U01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539807",
                "SecurityId": "INFIBEAM",
                "SecurityName": "INFIBEAM AVENUES LTD",
                "FaceValue": "1",
                "ISINNo": "INE483S01020",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539814",
                "SecurityId": "RADHEY",
                "SecurityName": "RADHEY TRADE HOLDING LTD",
                "FaceValue": "10",
                "ISINNo": "INE204S01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539819",
                "SecurityId": "MUDRA",
                "SecurityName": "MUDRA FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE967S01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539833",
                "SecurityId": "SICLTD",
                "SecurityName": "SHIVOM INVESTMENT & CONSULTANCY LTD",
                "FaceValue": "10",
                "ISINNo": "INE074G01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539834",
                "SecurityId": "BALGOPAL",
                "SecurityName": "BALGOPAL COMMERCIAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE119R01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539835",
                "SecurityId": "SUPERIOR",
                "SecurityName": "SUPERIOR FINLEASE LTD",
                "FaceValue": "10",
                "ISINNo": "INE574R01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539836",
                "SecurityId": "RUBY",
                "SecurityName": "RUBY CABLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE812T01010",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539837",
                "SecurityId": "RPEL",
                "SecurityName": "RAGHAV PRODUCTIVITY ENHANCERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE912T01018",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539838",
                "SecurityId": "DRREDDYBBPH",
                "SecurityName": "DRREDDYBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539839",
                "SecurityId": "FRANKLIN",
                "SecurityName": "FRANKLIN LEASING AND FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE399S01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539840",
                "SecurityId": "EILBBPH",
                "SecurityName": "EILBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539841",
                "SecurityId": "LANCER",
                "SecurityName": "LANCER CONTAINER LINES LTD",
                "FaceValue": "10",
                "ISINNo": "INE359U01010",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539842",
                "SecurityId": "SYSCO",
                "SecurityName": "SYSCO INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE410U01011",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539843",
                "SecurityId": "NINSYS",
                "SecurityName": "NINTEC SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE395U01014",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539844",
                "SecurityId": "EQUITAS",
                "SecurityName": "EQUITAS HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE988K01017",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539853",
                "SecurityId": "GOLDENCAP",
                "SecurityName": "GOLDEN CAPITAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE135D01010",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539854",
                "SecurityId": "HALDER",
                "SecurityName": "HALDER VENTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE115S01010",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539861",
                "SecurityId": "SKIL",
                "SecurityName": "SKIL INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE429F01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539864",
                "SecurityId": "GHUSHINE",
                "SecurityName": "GHUSHINE FINTRRADE OCEAN LTD",
                "FaceValue": "10",
                "ISINNo": "INE009U01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539871",
                "SecurityId": "THYROCARE",
                "SecurityName": "THYROCARE TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE594H01019",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539872",
                "SecurityId": "BAJAJHCARE",
                "SecurityName": "BAJAJ HEALTHCARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE411U01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539873",
                "SecurityId": "BRONZE",
                "SecurityName": "BRONZE TRADING LTD",
                "FaceValue": "10",
                "ISINNo": "INE022R01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539874",
                "SecurityId": "UJJIVAN",
                "SecurityName": "UJJIVAN FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE334L01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539875",
                "SecurityId": "RSDFIN",
                "SecurityName": "RSD FINANCE LTD",
                "FaceValue": "5",
                "ISINNo": "INE616F01022",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539876",
                "SecurityId": "CROMPTON",
                "SecurityName": "CROMPTON GREAVES CONSUMER ELECTRICALS LTD",
                "FaceValue": "2",
                "ISINNo": "INE299U01018",
                "Industry": "Household Appliances",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539883",
                "SecurityId": "PILANIINVS",
                "SecurityName": "PILANI INVESTMENT AND INDUSTRIES CORPORATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE417C01014",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539884",
                "SecurityId": "DARSHANORNA",
                "SecurityName": "DARSHAN ORNA LTD",
                "FaceValue": "10",
                "ISINNo": "INE671T01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539885",
                "SecurityId": "IPRU2812",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND- INDIA RECOVERY FUND CUMULATIVE SERIES",
                "FaceValue": "10",
                "ISINNo": "INF109KB1UT9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539886",
                "SecurityId": "IPRU2813",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND-INDIA RECOVERY FUND- DIVIDEND PAYOUT OPTION- SE 5",
                "FaceValue": "10",
                "ISINNo": "INF109KB1UR3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539887",
                "SecurityId": "IPRU9014",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND-DIRECT PLAN-CUMULATIVE OPTION-SERIES 5",
                "FaceValue": "10",
                "ISINNo": "INF109KB1UU7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539888",
                "SecurityId": "IPRU9015",
                "SecurityName": "ICICI PRUDENTIAL MUTUAL FUND-DIRECT PLAN-DIVIDEND PAYOUT OPTION SERIES 5",
                "FaceValue": "10",
                "ISINNo": "INF109KB1US1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539889",
                "SecurityId": "PARAGMILK",
                "SecurityName": "PARAG MILK FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE883N01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539894",
                "SecurityId": "MADHAVIPL",
                "SecurityName": "MADHAV INFRA PROJECTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE631R01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539895",
                "SecurityId": "SAGL",
                "SecurityName": "SHALIMAR AGENCIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE631E01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539900",
                "SecurityId": "DIDL",
                "SecurityName": "DALMIA INDUSTRIAL DEVELOPMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE642P01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539909",
                "SecurityId": "LATENT",
                "SecurityName": "LATENT LIGHT FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE515K01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539910",
                "SecurityId": "KOCL",
                "SecurityName": "KOME-ON COMMUNICATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE833C01012",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539911",
                "SecurityId": "SNIM",
                "SecurityName": "SVARNIM TRADE UDYOG LTD",
                "FaceValue": "1",
                "ISINNo": "INE730R01034",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539917",
                "SecurityId": "NAGARFERT",
                "SecurityName": "NAGARJUNA FERTILIZERS AND CHEMICALS LTD",
                "FaceValue": "1",
                "ISINNo": "INE454M01024",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539921",
                "SecurityId": "SEIL",
                "SecurityName": "SHANTI EDUCATIONAL INITIATIVES LTD",
                "FaceValue": "10",
                "ISINNo": "INE440T01010",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539922",
                "SecurityId": "RGIL",
                "SecurityName": "ROTOGRAPHICS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE364S01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539927",
                "SecurityId": "LIKHAMI",
                "SecurityName": "LIKHAMI CONSULTING LTD",
                "FaceValue": "10",
                "ISINNo": "INE920T01011",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539938",
                "SecurityId": "MIL",
                "SecurityName": "MEDICO INTERCONTINENTAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE858Q01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539939",
                "SecurityId": "YASHCHEM",
                "SecurityName": "YASH CHEMEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE571U01010",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539940",
                "SecurityId": "MAXVIL",
                "SecurityName": "MAX VENTURES AND INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE154U01015",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539945",
                "SecurityId": "ICICINV20",
                "SecurityName": "ICICI PRUDENTIAL NV20 ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KB1WY5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539946",
                "SecurityId": "BAZELINTER",
                "SecurityName": "BAZEL INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE217E01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539947",
                "SecurityId": "JLL",
                "SecurityName": "JINDAL LEASEFIN LTD",
                "FaceValue": "10",
                "ISINNo": "INE919T01013",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539954",
                "SecurityId": "IPRU2821",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 7 - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1WI8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539955",
                "SecurityId": "IPRU9023",
                "SecurityName": "ICICI PRUDENTIAL INDIA RECOVERY FUND SERIES 7 - DIRECT PLAN - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1WJ6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539956",
                "SecurityId": "TAALENT",
                "SecurityName": "TAAL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE524T01011",
                "Industry": "Airlines",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539957",
                "SecurityId": "MGL",
                "SecurityName": "MAHANAGAR GAS LTD",
                "FaceValue": "10",
                "ISINNo": "INE002S01010",
                "Industry": "Utilities:Non-Elec.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539962",
                "SecurityId": "QFSL",
                "SecurityName": "QUEST FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE064D01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539963",
                "SecurityId": "ZEAL",
                "SecurityName": "ZEAL AQUA LTD",
                "FaceValue": "10",
                "ISINNo": "INE819S01017",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539978",
                "SecurityId": "QUESS",
                "SecurityName": "QUESS CORP LTD",
                "FaceValue": "10",
                "ISINNo": "INE615P01015",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539979",
                "SecurityId": "DIGJAMLTD",
                "SecurityName": "DIGJAM LTD",
                "FaceValue": "10",
                "ISINNo": "INE731U01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539980",
                "SecurityId": "ICICIMCAP",
                "SecurityName": "ICICI PRUDENTIAL MIDCAP SELECT ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KB1XT3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539981",
                "SecurityId": "MAXINDIA-",
                "SecurityName": "MAX INDIA LTD",
                "FaceValue": "2",
                "ISINNo": "INE153U01017",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539982",
                "SecurityId": "ASYL",
                "SecurityName": "ADVANCE SYNTEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE184U01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539983",
                "SecurityId": "SIDH",
                "SecurityName": "SIDH AUTOMOBILES LTD",
                "FaceValue": "10",
                "ISINNo": "INE403L01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539984",
                "SecurityId": "HUIL",
                "SecurityName": "HINDUSTHAN URBAN INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE799B01017",
                "Industry": "Electronic Components",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539985",
                "SecurityId": "TITAANIUM",
                "SecurityName": "TITAANIUM TEN ENTERPRISE LTD",
                "FaceValue": "10",
                "ISINNo": "INE120V01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539986",
                "SecurityId": "COMSYN",
                "SecurityName": "COMMERCIAL SYN BAGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE073V01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539991",
                "SecurityId": "CFEL",
                "SecurityName": "CONFIDENCE FUTURISTIC ENERGETECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE700F01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539992",
                "SecurityId": "LLOYDSTEEL",
                "SecurityName": "LLOYDS STEELS INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE093R01011",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "539997",
                "SecurityId": "KPL",
                "SecurityName": "KWALITY PHARMACEUTICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE552U01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540005",
                "SecurityId": "LTI",
                "SecurityName": "LARSEN & TOUBRO INFOTECH LTD",
                "FaceValue": "1",
                "ISINNo": "INE214T01019",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540006",
                "SecurityId": "EASTWEST",
                "SecurityName": "EAST WEST HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE595R01015",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540008",
                "SecurityId": "BSLSENETFG",
                "SecurityName": "BIRLA SUN LIFE SENSEX ETF",
                "FaceValue": "10",
                "ISINNo": "INF209KB1119",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540023",
                "SecurityId": "COLORCHIPS",
                "SecurityName": "COLORCHIPS NEW MEDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE621I01026",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540024",
                "SecurityId": "ASHARI",
                "SecurityName": "ASHARI AGENCIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE361S01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540025",
                "SecurityId": "ADVENZYMES",
                "SecurityName": "ADVANCED ENZYME TECHNOLOGIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE837H01020",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540026",
                "SecurityId": "LAHL",
                "SecurityName": "LADAM AFFORDABLE HOUSING LTD",
                "FaceValue": "5",
                "ISINNo": "INE213U01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540027",
                "SecurityId": "PTIL",
                "SecurityName": "PRABHAT TECHNOLOGIES (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE171P01019",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540046",
                "SecurityId": "ADFBBPH",
                "SecurityName": "ADFBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540047",
                "SecurityId": "DBL",
                "SecurityName": "DILIP BUILDCON LTD",
                "FaceValue": "10",
                "ISINNo": "INE917M01012",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540048",
                "SecurityId": "SPAL",
                "SecurityName": "S.P. APPARELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE212I01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540061",
                "SecurityId": "BIGBLOC",
                "SecurityName": "BIGBLOC CONSTRUCTION LTD",
                "FaceValue": "10",
                "ISINNo": "INE412U01017",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540062",
                "SecurityId": "GOLDENCREST",
                "SecurityName": "GOLDEN CREST EDUCATION & SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE222U01010",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540063",
                "SecurityId": "ECS",
                "SecurityName": "ECS BIZTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE925Q01024",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540064",
                "SecurityId": "FRETAIL",
                "SecurityName": "FUTURE RETAIL LTD",
                "FaceValue": "2",
                "ISINNo": "INE752P01024",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540065",
                "SecurityId": "RBLBANK",
                "SecurityName": "RBL BANK LTD",
                "FaceValue": "10",
                "ISINNo": "INE976G01028",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540066",
                "SecurityId": "AMS",
                "SecurityName": "AMS POLYMERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE345U01019",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540071",
                "SecurityId": "CAMSONSEEDS",
                "SecurityName": "CAMSON SEEDS LTD",
                "FaceValue": "10",
                "ISINNo": "INE494T01017",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540072",
                "SecurityId": "SHIVAEXPO",
                "SecurityName": "SHIVA GRANITO EXPORT LTD",
                "FaceValue": "10",
                "ISINNo": "INE191V01015",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540073",
                "SecurityId": "BLS",
                "SecurityName": "BLS INTERNATIONAL SERVICES LTD",
                "FaceValue": "1",
                "ISINNo": "INE153T01027",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540078",
                "SecurityId": "MITSU",
                "SecurityName": "MITSU CHEM PLAST LTD",
                "FaceValue": "10",
                "ISINNo": "INE317V01016",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540079",
                "SecurityId": "SPRAYKING",
                "SecurityName": "SPRAYKING AGRO EQUIPMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE537U01011",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540080",
                "SecurityId": "NARAYANI",
                "SecurityName": "NARAYANI STEELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE715T01015",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540081",
                "SecurityId": "GOVNOW",
                "SecurityName": "SAB EVENTS & GOVERNANCE NOW MEDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE860T01019",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540082",
                "SecurityId": "RSTL",
                "SecurityName": "RIDDHI STEEL AND TUBE LTD",
                "FaceValue": "10",
                "ISINNo": "INE367U01013",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540083",
                "SecurityId": "TVVISION",
                "SecurityName": "TV VISION LTD",
                "FaceValue": "10",
                "ISINNo": "INE871L01013",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540084",
                "SecurityId": "SPICY",
                "SecurityName": "SPICY ENTERTAINMENT AND MEDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE592O01019",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540097",
                "SecurityId": "VISCO",
                "SecurityName": "VISCO TRADE ASSOCIATES LTD",
                "FaceValue": "10",
                "ISINNo": "INE890S01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540098",
                "SecurityId": "BSLRIFS1RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 1 REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1499",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540099",
                "SecurityId": "BSLRIFS1RD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 1 REGULAR DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KB1507",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540100",
                "SecurityId": "BSLRIFS1RS",
                "SecurityName": "BIRLA SUN LIFE RESURGENT INDIA FUND SERIES - 1 - REGULAR PLAN - DIVIDEND SWEEP",
                "FaceValue": "10",
                "ISINNo": "INF209KB1192",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540101",
                "SecurityId": "BSLRIFS1DG",
                "SecurityName": "BIRLA SUN LIFE RESURGENT INDIA FUND SERIES - 1 - DIRECT PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1515",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540102",
                "SecurityId": "BSLRIFS1DD",
                "SecurityName": "BIRLA SUN LIFE RESURGENT INDIA FUND SERIES - 1 - DIRECT PLAN - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KB1523",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540103",
                "SecurityId": "BSLRIFS1DS",
                "SecurityName": "BIRLA SUN LIFE RESURGENT INDIA FUND SERIES - 1 - DIRECT PLAN - DIVIDEND SWEEP",
                "FaceValue": "10",
                "ISINNo": "INF209KB1259",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540108",
                "SecurityId": "TIAANC",
                "SecurityName": "TIAAN CONSUMER LTD",
                "FaceValue": "10",
                "ISINNo": "INE864T01011",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540115",
                "SecurityId": "LTTS",
                "SecurityName": "L&T TECHNOLOGY SERVICES LTD",
                "FaceValue": "2",
                "ISINNo": "INE010V01017",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540124",
                "SecurityId": "GNA",
                "SecurityName": "G N A AXLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE934S01014",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540125",
                "SecurityId": "RADHIKAJWE",
                "SecurityName": "RADHIKA JEWELTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE583V01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540132",
                "SecurityId": "SIIL",
                "SecurityName": "SABRIMALA INDUSTRIES INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE400R01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540133",
                "SecurityId": "ICICIPRULI",
                "SecurityName": "ICICI PRUDENTIAL LIFE INSURANCE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE726G01019",
                "Industry": "Life Insurance",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540134",
                "SecurityId": "IISL",
                "SecurityName": "ISHAAN INFRASTRUCTURES AND SHELTERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE818R01011",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540135",
                "SecurityId": "ARCFIN",
                "SecurityName": "ARC FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE202R01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540136",
                "SecurityId": "HPL",
                "SecurityName": "HPL ELECTRIC & POWER LTD",
                "FaceValue": "10",
                "ISINNo": "INE495S01016",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540143",
                "SecurityId": "SAGARSOFT",
                "SecurityName": "SAGARSOFT (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE184B01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540144",
                "SecurityId": "DRA",
                "SecurityName": "DRA CONSULTANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE746V01016",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540145",
                "SecurityId": "VALIANTORG",
                "SecurityName": "VALIANT ORGANICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE565V01010",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540146",
                "SecurityId": "ACML",
                "SecurityName": "ADITYA CONSUMER MARKETING LTD",
                "FaceValue": "10",
                "ISINNo": "INE427V01013",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540147",
                "SecurityId": "SHASHIJIT",
                "SecurityName": "SHASHIJIT INFRAPROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE700V01013",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540148",
                "SecurityId": "BINDALEXPO",
                "SecurityName": "BINDAL EXPORTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE564V01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540149",
                "SecurityId": "TRANSPEBBPH",
                "SecurityName": "TRANSPEBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540150",
                "SecurityId": "MHEL",
                "SecurityName": "MEWAR HI-TECH ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE957U01011",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540151",
                "SecurityId": "DIKSAT",
                "SecurityName": "DIKSAT TRANSWORLD LTD",
                "FaceValue": "10",
                "ISINNo": "INE942P01013",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540152",
                "SecurityId": "IGRL",
                "SecurityName": "INDIA GREEN REALITY LTD",
                "FaceValue": "10",
                "ISINNo": "INE373V01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540153",
                "SecurityId": "ENDURANCE",
                "SecurityName": "ENDURANCE TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE913H01037",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540154",
                "SecurityId": "IDFSENSEXE",
                "SecurityName": "IDFC SENSEX ETF",
                "FaceValue": "10",
                "ISINNo": "INF194KA1T91",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540159",
                "SecurityId": "PURPLE",
                "SecurityName": "PURPLE ENTERTAINMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE905R01016",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540168",
                "SecurityId": "SUPRAP",
                "SecurityName": "SUPRA PACIFIC MANAGEMENT CONSULTANCY LTD",
                "FaceValue": "10",
                "ISINNo": "INE268T01015",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540173",
                "SecurityId": "PNBHOUSING",
                "SecurityName": "PNB HOUSING FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE572E01012",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540174",
                "SecurityId": "SOUTHERNIN",
                "SecurityName": "SOUTHERN INFOSYS LTD",
                "FaceValue": "10",
                "ISINNo": "INE298B01010",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540175",
                "SecurityId": "REGENCY",
                "SecurityName": "REGENCY FINCORP LTD",
                "FaceValue": "10",
                "ISINNo": "INE964R01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540176",
                "SecurityId": "IPRU2880",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 9-CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB1I25",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540177",
                "SecurityId": "IPRU9082",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 9-DIRECT PLAN CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB1I41",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540178",
                "SecurityId": "IPRU9083",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 9-DIRECT PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB1I58",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540179",
                "SecurityId": "IPRU2881",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 9-DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB1I33",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540180",
                "SecurityId": "VBL",
                "SecurityName": "VARUN BEVERAGES LTD",
                "FaceValue": "10",
                "ISINNo": "INE200M01013",
                "Industry": "Non-alcoholic Beverages",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540181",
                "SecurityId": "SALEM",
                "SecurityName": "SALEM ERODE INVESTMENTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE894E01028",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540189",
                "SecurityId": "UPROTECH",
                "SecurityName": "UNIPRO TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE448F01012",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540190",
                "SecurityId": "FRANKLININD",
                "SecurityName": "FRANKLIN INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE789R01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540191",
                "SecurityId": "FCLBBPH",
                "SecurityName": "FCLBBPH",
                "FaceValue": "2",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540192",
                "SecurityId": "LKPSEC",
                "SecurityName": "LKP SECURITIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE341H01023",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540193",
                "SecurityId": "AMBIKCOBBPH",
                "SecurityName": "AMBIKCOBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540194",
                "SecurityId": "BSLRIFS2RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 2 REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1952",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540195",
                "SecurityId": "BSLRIFS2RN",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 2 REGULAR DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KB1960",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540196",
                "SecurityId": "BSLRIFS2DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 2 DIRECT GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1978",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540197",
                "SecurityId": "BSLRIFS2DN",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 2 DIRECT DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KB1986",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540198",
                "SecurityId": "OSIAJEE",
                "SecurityName": "OSIAJEE TEXFAB LTD",
                "FaceValue": "10",
                "ISINNo": "INE186R01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540199",
                "SecurityId": "CMBL",
                "SecurityName": "CORPORATE MERCHANT BANKERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE850R01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540203",
                "SecurityId": "SFL",
                "SecurityName": "SHEELA FOAM LTD",
                "FaceValue": "5",
                "ISINNo": "INE916U01025",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540204",
                "SecurityId": "NIDL",
                "SecurityName": "NARENDRA INVESTMENTS (DELHI) LTD",
                "FaceValue": "10",
                "ISINNo": "INE666Q01016",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540205",
                "SecurityId": "AVL",
                "SecurityName": "ADITYA VISION LTD",
                "FaceValue": "10",
                "ISINNo": "INE679V01019",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540210",
                "SecurityId": "TMRVL",
                "SecurityName": "THE MANDHANA RETAIL VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE759V01019",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540211",
                "SecurityId": "SIFL",
                "SecurityName": "SPARKLING (INDIA) FINSHARES LTD",
                "FaceValue": "10",
                "ISINNo": "INE181T01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540212",
                "SecurityId": "TCIEXP",
                "SecurityName": "TCI EXPRESS LTD",
                "FaceValue": "2",
                "ISINNo": "INE586V01016",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540221",
                "SecurityId": "SHASHANK",
                "SecurityName": "SHASHANK TRADERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE508R01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540222",
                "SecurityId": "LAURUSLABS",
                "SecurityName": "LAURUS LABS LTD",
                "FaceValue": "2",
                "ISINNo": "INE947Q01028",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540243",
                "SecurityId": "NEWLIGHT",
                "SecurityName": "NEW LIGHT APPARELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE835U01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540248",
                "SecurityId": "AXISE1D1D",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SERIES 1(1400 DAYS)DIRECT PLAN-DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF846K01YK5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540249",
                "SecurityId": "AXISE1DGG",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SERIES 1(1400 DAYS)DIRECT PLAN-GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01YJ7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540250",
                "SecurityId": "AXISE1DPD",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SERIES 1(1400 DAYS) REGULAR PLAN-DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF846K01YM1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540251",
                "SecurityId": "AXISE1GPG",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SERIES 1(1400 DAYS) REGULAR PLAN-GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01YL3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540252",
                "SecurityId": "VSL",
                "SecurityName": "VEERAM SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE607V01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540253",
                "SecurityId": "SNTCL",
                "SecurityName": "SHREE NIDHI TRADING CO. LTD",
                "FaceValue": "10",
                "ISINNo": "INE066E01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540254",
                "SecurityId": "MTPL",
                "SecurityName": "MARG TECHNO PROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE245H01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540255",
                "SecurityId": "IPRU2894",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 10- CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1L87",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540256",
                "SecurityId": "IPRU2895",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 10- DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1L95",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540257",
                "SecurityId": "IPRU9096",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 10- DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1M03",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540258",
                "SecurityId": "IPRU9097",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 10- DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1M11",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540259",
                "SecurityId": "SHANGAR",
                "SecurityName": "SHANGAR DECOR LTD",
                "FaceValue": "5",
                "ISINNo": "INE118R01024",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540266",
                "SecurityId": "GLCL",
                "SecurityName": "GLOBE COMMERCIALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE804Q01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540267",
                "SecurityId": "FLORACORP",
                "SecurityName": "FLORA CORPORATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE318U01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540268",
                "SecurityId": "DHANVARSHA",
                "SecurityName": "DHANVARSHA FINVEST LTD",
                "FaceValue": "10",
                "ISINNo": "INE615R01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540269",
                "SecurityId": "SKL",
                "SecurityName": "SUPER FINE KNITTERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE459U01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540270",
                "SecurityId": "RAIDEEPIND",
                "SecurityName": "RAIDEEP INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE923R01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540271",
                "SecurityId": "IPRU2896",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 11 - CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB1M60",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540272",
                "SecurityId": "IPRU2897",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 11 -  DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB1M78",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540273",
                "SecurityId": "IPRU9098",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 11 -  DIRECT PLAN CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB1M86",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540274",
                "SecurityId": "IPRU9099",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 11 -  DIRECT PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB1M94",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540293",
                "SecurityId": "PRICOLLTD",
                "SecurityName": "PRICOL LTD",
                "FaceValue": "1",
                "ISINNo": "INE726V01018",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540300",
                "SecurityId": "GHCLBBPH",
                "SecurityName": "GHCLBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540305",
                "SecurityId": "RAMCOBBPH",
                "SecurityName": "RAMCOBBPH",
                "FaceValue": "1",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540310",
                "SecurityId": "CFL",
                "SecurityName": "CLASSIC FILAMENTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE181U01018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540311",
                "SecurityId": "JITFINFRA",
                "SecurityName": "JITF INFRALOGISTICS LTD",
                "FaceValue": "2",
                "ISINNo": "INE863T01013",
                "Industry": "Transport Related Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540318",
                "SecurityId": "SUERYAAKNI",
                "SecurityName": "SUERYAA KNITWEAR LTD",
                "FaceValue": "10",
                "ISINNo": "INE249U01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540331",
                "SecurityId": "ICRABBPH",
                "SecurityName": "ICRABBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540332",
                "SecurityId": "TANVI",
                "SecurityName": "TANVI FOODS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE978V01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540333",
                "SecurityId": "AXISE2D1D",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SR 2 (1400 D)-DIRECT PLAN-DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF846K01YY6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540334",
                "SecurityId": "AXISE2DGG",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SR 2 (1400 D)-DIRECT PLAN-GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01YX8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540335",
                "SecurityId": "AXISE2DPD",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SR 2 (1400 D)-REGULAR PLAN-DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF846K01ZA3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540336",
                "SecurityId": "AXISE2GPG",
                "SecurityName": "AXIS EMERGING OPPORTUNITIES FUND-SR 2 (1400 D)-REGULAR PLAN-GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01YZ3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540358",
                "SecurityId": "RMC",
                "SecurityName": "RMC SWITCHGEARS LTD",
                "FaceValue": "10",
                "ISINNo": "INE655V01019",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540359",
                "SecurityId": "PARMAX",
                "SecurityName": "PARMAX PHARMA LTD",
                "FaceValue": "10",
                "ISINNo": "INE240T01014",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540360",
                "SecurityId": "LLFICL",
                "SecurityName": "LEADING LEASING FINANCE AND INVESTMENT COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE715Q01011",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540361",
                "SecurityId": "DANUBE",
                "SecurityName": "DANUBE INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE575D01033",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540366",
                "SecurityId": "RADIOCITY",
                "SecurityName": "MUSIC BROADCAST LTD",
                "FaceValue": "2",
                "ISINNo": "INE919I01024",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540376",
                "SecurityId": "DMART",
                "SecurityName": "AVENUE SUPERMARTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE192R01011",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540377",
                "SecurityId": "IFL",
                "SecurityName": "IFL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE714U01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540378",
                "SecurityId": "BSLRIFS3RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 3 REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1DL1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540379",
                "SecurityId": "BSLRIFS3RD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 3 REGULAR DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KB1DM9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540381",
                "SecurityId": "BSLRIFS3DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 3 DIRECT GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1DN7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540383",
                "SecurityId": "BSLRIFS3DD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 3 DIRECT DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KB1DO5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540385",
                "SecurityId": "KDLL",
                "SecurityName": "KD LEISURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE081R01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540386",
                "SecurityId": "ONTIC",
                "SecurityName": "ONTIC FINSERVE LTD",
                "FaceValue": "10",
                "ISINNo": "INE989S01034",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540393",
                "SecurityId": "SMLT",
                "SecurityName": "SARTHAK METALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE017W01010",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540394",
                "SecurityId": "JDML",
                "SecurityName": "JASH DEALMARK LTD",
                "FaceValue": "10",
                "ISINNo": "INE801W01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540395",
                "SecurityId": "CHEMCRUX",
                "SecurityName": "CHEMCRUX ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE298W01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540396",
                "SecurityId": "MANOMAY",
                "SecurityName": "MANOMAY TEX INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE784W01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540401",
                "SecurityId": "MAXIMUS",
                "SecurityName": "MAXIMUS INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE544W01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540402",
                "SecurityId": "MANAS",
                "SecurityName": "MANAS PROPERTIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE800W01019",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540403",
                "SecurityId": "CLEDUCATE",
                "SecurityName": "CL EDUCATE LTD",
                "FaceValue": "10",
                "ISINNo": "INE201M01011",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540404",
                "SecurityId": "PRIMEFRESH",
                "SecurityName": "PRIME FRESH LTD",
                "FaceValue": "10",
                "ISINNo": "INE442V01012",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540405",
                "SecurityId": "OCEANIC",
                "SecurityName": "OCEANIC FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE711V01010",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540416",
                "SecurityId": "OCTAWARE",
                "SecurityName": "OCTAWARE TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE208U01019",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540417",
                "SecurityId": "CA50T",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - ARBITRAGE PLAN 50% - DIRECT",
                "FaceValue": "10",
                "ISINNo": "INF179KB1E48",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540418",
                "SecurityId": "CA10T",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - ARBITRAGE PLAN 100% - DIRECT",
                "FaceValue": "10",
                "ISINNo": "INF179KB1E55",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540419",
                "SecurityId": "CD50T",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - DEBT PLAN 50% - DIRECT",
                "FaceValue": "10",
                "ISINNo": "INF179KB1E89",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540420",
                "SecurityId": "CD10T",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - DEBT PLAN 100% - DIRECT",
                "FaceValue": "10",
                "ISINNo": "INF179KB1E97",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540421",
                "SecurityId": "CA50R",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - ARBITRAGE PLAN50%  - REGULAR",
                "FaceValue": "10",
                "ISINNo": "INF179KB1E63",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540422",
                "SecurityId": "CA10R",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - ARBITRAGE PLAN 100% - REGULAR",
                "FaceValue": "10",
                "ISINNo": "INF179KB1E71",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540423",
                "SecurityId": "CD50R",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - DEBT PLAN 50%  - REGULAR",
                "FaceValue": "10",
                "ISINNo": "INF179KB1F05",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540424",
                "SecurityId": "CD10R",
                "SecurityName": "HDFC CHARITY FUND FOR CANCER CURE - DEBT PLAN 100% - REGULAR",
                "FaceValue": "10",
                "ISINNo": "INF179KB1F13",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540425",
                "SecurityId": "SHANKARA",
                "SecurityName": "SHANKARA BUILDING PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE274V01019",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540426",
                "SecurityId": "RELSTRUCT",
                "SecurityName": "RELSTRUCT BUILDCON LTD",
                "FaceValue": "10",
                "ISINNo": "INE792V01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540455",
                "SecurityId": "ESCORP",
                "SecurityName": "ESCORP ASSET MANAGEMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE953W01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540467",
                "SecurityId": "PARMSILK",
                "SecurityName": "PARMESHWARI SILK MILLS LTD",
                "FaceValue": "10",
                "ISINNo": "INE808R01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540468",
                "SecurityId": "KMSMEDI",
                "SecurityName": "KMS MEDISURGI LTD",
                "FaceValue": "10",
                "ISINNo": "INE870V01014",
                "Industry": "Healthcare Supplies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540469",
                "SecurityId": "IPRU2933",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 12 - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1X75",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540470",
                "SecurityId": "IPRU9135",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 12 - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB1X91",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540481",
                "SecurityId": "CLFL",
                "SecurityName": "CLASSIC LEASING & FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE949C01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540492",
                "SecurityId": "STARLENT",
                "SecurityName": "STARLINEPS ENTERPRISES LTD",
                "FaceValue": "5",
                "ISINNo": "INE594W01034",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540497",
                "SecurityId": "SCHAND",
                "SecurityName": "S CHAND AND COMPANY LTD",
                "FaceValue": "5",
                "ISINNo": "INE807K01035",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540515",
                "SecurityId": "KANUNGO",
                "SecurityName": "KANUNGO FINANCIERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE453S01015",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540519",
                "SecurityId": "MEERA",
                "SecurityName": "MEERA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE343X01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540530",
                "SecurityId": "HUDCO",
                "SecurityName": "HOUSING & URBAN DEVELOPMENT CORPORATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE031A01017",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540538",
                "SecurityId": "IPRU2955",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND- SERIES 13- DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB11C2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540539",
                "SecurityId": "IPRU9157",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES- 13- DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB13C8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540544",
                "SecurityId": "PSPPROJECT",
                "SecurityName": "PSP PROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE488V01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540545",
                "SecurityId": "BGJL",
                "SecurityName": "BHAKTI GEMS AND JEWELLERY LTD",
                "FaceValue": "10",
                "ISINNo": "INE986W01016",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540550",
                "SecurityId": "YUG",
                "SecurityName": "YUG DECOR LTD",
                "FaceValue": "10",
                "ISINNo": "INE796W01019",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540551",
                "SecurityId": "AXISAEDGG",
                "SecurityName": "AXIS EQUITY ADVANTAGE FUND- SERIES 1 DIRECT PLAN- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01ZJ4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540552",
                "SecurityId": "AXISAEGPG",
                "SecurityName": "AXIS EQUITY ADVANTAGE FUND- SERIES 1 REGULAR PLAN- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01ZK2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540570",
                "SecurityId": "VARIMAN",
                "SecurityName": "VARIMAN GLOBAL ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE717F01010",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540575",
                "SecurityId": "STARCEMENT",
                "SecurityName": "STAR CEMENT LTD",
                "FaceValue": "1",
                "ISINNo": "INE460H01021",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540586",
                "SecurityId": "IPRU2969",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 14 - CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB16J6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540587",
                "SecurityId": "IPRU2970",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 14 - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB17J4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540588",
                "SecurityId": "IPRU9171",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 14 - DIRECT PLAN CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB18J2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540589",
                "SecurityId": "IPRU9172",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 14 - DIRECT PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB19J0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540590",
                "SecurityId": "RIDDHICORP",
                "SecurityName": "RIDDHI CORPORATE SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE325X01015",
                "Industry": "BPO/KPO",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540591",
                "SecurityId": "HEOFDG1126",
                "SecurityName": "HDFC EOF- II- 1126D MAY 2017(1)  PLAN UNDER HDFC EQUITY OPPORTUNITIES FUND- SERIES 2- DIRECT",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AF4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540592",
                "SecurityId": "HEOFDD1126",
                "SecurityName": "HDFC EOF - II - 1126D MAY 2017(1)  PLAN UNDER HDFC EQUITY OPPORTUNITIES FUND - SERIES 2 - DIRECT",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AG2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540593",
                "SecurityId": "HEOFRG1126",
                "SecurityName": "HDFC EOF- II - 1126D MAY 2017(1) PLAN UNDER HDFC EQUITY OPPORTUNITIES FUND - SERIES2 - REGULAR",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AH0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540594",
                "SecurityId": "HEOFRD1126",
                "SecurityName": "HDFC EOF - II - 1126D MAY 2017(1)  PLAN UNDER HDFC EQUITY OPPORTUNITIES FUND - SERIES 2 - REGULAR",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AI8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540595",
                "SecurityId": "TEJASNET",
                "SecurityName": "TEJAS NETWORKS LTD",
                "FaceValue": "10",
                "ISINNo": "INE010J01012",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540596",
                "SecurityId": "ERIS",
                "SecurityName": "ERIS LIFESCIENCES LTD",
                "FaceValue": "1",
                "ISINNo": "INE406M01024",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540597",
                "SecurityId": "CONTAINER",
                "SecurityName": "CONTAINERWAY INTERNATIONAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE319U01014",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540602",
                "SecurityId": "GTPL",
                "SecurityName": "GTPL HATHWAY LTD",
                "FaceValue": "10",
                "ISINNo": "INE869I01013",
                "Industry": "Other Telecom Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540611",
                "SecurityId": "AUBANK",
                "SecurityName": "AU SMALL FINANCE BANK LTD",
                "FaceValue": "10",
                "ISINNo": "INE949L01017",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540612",
                "SecurityId": "ICICILOVOL",
                "SecurityName": "ICICI PRUDENTIAL NIFTY LOW VOL 30 ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KB10T8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540613",
                "SecurityId": "GEL",
                "SecurityName": "GAUTAM EXIM LTD",
                "FaceValue": "10",
                "ISINNo": "INE721X01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540614",
                "SecurityId": "GGENG",
                "SecurityName": "G G ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE694X01014",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540615",
                "SecurityId": "7NR",
                "SecurityName": "7NR RETAIL LTD",
                "FaceValue": "10",
                "ISINNo": "INE413X01019",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540616",
                "SecurityId": "ARTEMIS",
                "SecurityName": "ARTEMIS GLOBAL LIFE SCIENCES LTD",
                "FaceValue": "2",
                "ISINNo": "INE517U01013",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540617",
                "SecurityId": "BSLRIFS4RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 4 REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1HR9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540618",
                "SecurityId": "BSLRIFS4RD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 4 REGULAR DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KB1HS7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540619",
                "SecurityId": "BSLRIFS4DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 4 DIRECT GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1HT5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540620",
                "SecurityId": "BSLRIFS4DD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND Â SERIES 4 DIRECT DIVIDEND",
                "FaceValue": "10",
                "ISINNo": "INF209KB1HU3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540621",
                "SecurityId": "BHAGYAPROP",
                "SecurityName": "BHAGYANAGAR PROPERTIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE363W01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540626",
                "SecurityId": "IPRU2987",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND-SERIES 15-CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB16S7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540627",
                "SecurityId": "IPRU2988",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND-SERIES 15-DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB17S5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540629",
                "SecurityId": "IPRU9189",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND-SERIES 15-DIRECT PLAN CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KB18S3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540631",
                "SecurityId": "IPRU9190",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND-SERIES 15-DIRECT PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KB19S1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540634",
                "SecurityId": "HEOFDG1100",
                "SecurityName": "HDFC EQUITY OPPORTUNITIES FUND-II-1100D JUNE 2017(1) SERIES 2-DIRECT OPTION-GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AJ6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540635",
                "SecurityId": "HEOFDD1100",
                "SecurityName": "HDFC EQUITY OPPORTUNITIES FUND-II-1100D JUNE 2017(1) SERIES 2-DIRECT OPTION-DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AK4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540636",
                "SecurityId": "HEOFRG1100",
                "SecurityName": "HDFC EQUITY OPPORTUNITIES FUND-II-1100D JUNE 2017(1) SERIES 2-REGULAR OPTION-GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AL2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540637",
                "SecurityId": "HEOFRD1100",
                "SecurityName": "HDFC EQUITY OPPORTUNITIES FUND-II-1100D JUNE 2017(1) SERIES 2-REGULAR OPTION-DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KC1AM0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540642",
                "SecurityId": "SALASAR",
                "SecurityName": "SALASAR TECHNO ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE170V01019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540647",
                "SecurityId": "GANGESSEC",
                "SecurityName": "GANGES SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE335W01016",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540648",
                "SecurityId": "PALASHSEC",
                "SecurityName": "PALASH SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE471W01019",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540649",
                "SecurityId": "AVADHSUGAR",
                "SecurityName": "AVADH SUGAR & ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE349W01017",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540650",
                "SecurityId": "MAGADHSUGAR",
                "SecurityName": "MAGADH SUGAR & ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE347W01011",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540651",
                "SecurityId": "JIGAR",
                "SecurityName": "JIGAR CABLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE943X01015",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540652",
                "SecurityId": "CTCL",
                "SecurityName": "CAPTAIN TECHNOCAST LTD",
                "FaceValue": "10",
                "ISINNo": "INE931X01010",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540653",
                "SecurityId": "SINTEXPLAST",
                "SecurityName": "SINTEX PLASTICS TECHNOLOGY LTD",
                "FaceValue": "1",
                "ISINNo": "INE501W01021",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540654",
                "SecurityId": "GSTL",
                "SecurityName": "GLOBALSPACE TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE632W01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540669",
                "SecurityId": "UTINEXT50",
                "SecurityName": "UTI NIFTY NEXT 50 ETF",
                "FaceValue": "10",
                "ISINNo": "INF789FC1N82",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540670",
                "SecurityId": "JDBBPH",
                "SecurityName": "JDBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540671",
                "SecurityId": "AXISAHDGG",
                "SecurityName": "AXIS EQUITY ADVANTAGE FUND - SERIES 2 DIRECT PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01A86",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540672",
                "SecurityId": "AXISAHGPG",
                "SecurityName": "AXIS EQUITY ADVANTAGE FUND - SERIES 2 REGULAR PLAN - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01A94",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540673",
                "SecurityId": "SIS",
                "SecurityName": "SECURITY AND INTELLIGENCE SERVICES (INDIA) LTD",
                "FaceValue": "5",
                "ISINNo": "INE285J01028",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540678",
                "SecurityId": "COCHINSHIP",
                "SecurityName": "COCHIN SHIPYARD LTD",
                "FaceValue": "10",
                "ISINNo": "INE704P01017",
                "Industry": "Shipping",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540679",
                "SecurityId": "SMSLIFE",
                "SecurityName": "SMS LIFESCIENCES INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE320X01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540680",
                "SecurityId": "KIOCL",
                "SecurityName": "KIOCL LTD",
                "FaceValue": "10",
                "ISINNo": "INE880L01014",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540681",
                "SecurityId": "CHOTHANI",
                "SecurityName": "CHOTHANI FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE344X01016",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540682",
                "SecurityId": "IPRU2991",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 16 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB15T7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540683",
                "SecurityId": "IPRU2992",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 16 - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB16T5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540684",
                "SecurityId": "IPRU9193",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 16 - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB17T3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540685",
                "SecurityId": "IPRU9194",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 16 - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB18T1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540686",
                "SecurityId": "SMRUTHIORG",
                "SecurityName": "SMRUTHI ORGANICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE172E01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540691",
                "SecurityId": "ABCAPITAL",
                "SecurityName": "ADITYA BIRLA CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE674K01013",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540692",
                "SecurityId": "APEX",
                "SecurityName": "APEX FROZEN FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE346W01013",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540693",
                "SecurityId": "SHISHIND",
                "SecurityName": "SHISH INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE145Y01015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540694",
                "SecurityId": "ANG",
                "SecurityName": "ANG LIFESCIENCES INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE236W01016",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540695",
                "SecurityId": "DWL",
                "SecurityName": "DHRUV WELLNESS LTD",
                "FaceValue": "10",
                "ISINNo": "INE109Y01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540696",
                "SecurityId": "KCDGROUP",
                "SecurityName": "KCD INDUSTRIES INDIA LTD",
                "FaceValue": "5",
                "ISINNo": "INE185U01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540697",
                "SecurityId": "AMFL",
                "SecurityName": "A & M FEBCON LTD",
                "FaceValue": "10",
                "ISINNo": "INE319X01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540698",
                "SecurityId": "NEL",
                "SecurityName": "NOURITRANS EXIM LTD",
                "FaceValue": "10",
                "ISINNo": "INE555X01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540699",
                "SecurityId": "DIXON",
                "SecurityName": "DIXON TECHNOLOGIES (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE935N01012",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540700",
                "SecurityId": "BRNL",
                "SecurityName": "BHARAT ROAD NETWORK LTD",
                "FaceValue": "10",
                "ISINNo": "INE727S01012",
                "Industry": "Roads & Highways",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540701",
                "SecurityId": "DCAL",
                "SecurityName": "DISHMAN CARBOGEN AMCIS LTD",
                "FaceValue": "2",
                "ISINNo": "INE385W01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540702",
                "SecurityId": "LASA",
                "SecurityName": "LASA SUPERGENERICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE670X01014",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540703",
                "SecurityId": "PROVESTSER",
                "SecurityName": "PROVESTMENT SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE438C01010",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540704",
                "SecurityId": "MATRIMONY",
                "SecurityName": "MATRIMONY.COM LTD",
                "FaceValue": "5",
                "ISINNo": "INE866R01028",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540709",
                "SecurityId": "RELHOME",
                "SecurityName": "RELIANCE HOME FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE217K01011",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540710",
                "SecurityId": "CAPACITE",
                "SecurityName": "CAPACITE INFRAPROJECTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE264T01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540711",
                "SecurityId": "IPRU3003",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 17 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB11W0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540712",
                "SecurityId": "IPRU3004",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 17 - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB12W8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540713",
                "SecurityId": "IPRU9205",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 17 - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB19V5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540714",
                "SecurityId": "IPRU9206",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 17 - DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB10W2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540715",
                "SecurityId": "SAGAR",
                "SecurityName": "SAGAR DIAMONDS LTD",
                "FaceValue": "10",
                "ISINNo": "INE146Y01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540716",
                "SecurityId": "ICICIGI",
                "SecurityName": "ICICI LOMBARD GENERAL INSURANCE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE765G01017",
                "Industry": "General Insurance",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540717",
                "SecurityId": "PQIF",
                "SecurityName": "POLO QUEEN INDUSTRIAL AND FINTECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE689M01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540718",
                "SecurityId": "AKM",
                "SecurityName": "AKM LACE AND EMBROTEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE777X01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540719",
                "SecurityId": "SBILIFE",
                "SecurityName": "SBI LIFE INSURANCE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE123W01016",
                "Industry": "Life Insurance",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540724",
                "SecurityId": "DIAMONDYD",
                "SecurityName": "PRATAAP SNACKS LTD",
                "FaceValue": "5",
                "ISINNo": "INE393P01035",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540725",
                "SecurityId": "SISL",
                "SecurityName": "SHARE INDIA SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE932X01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540726",
                "SecurityId": "TTFL",
                "SecurityName": "TRIDENT TEXOFAB LTD",
                "FaceValue": "10",
                "ISINNo": "INE071Y01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540727",
                "SecurityId": "POOJA",
                "SecurityName": "POOJAWESTERN METALIKS LTD",
                "FaceValue": "10",
                "ISINNo": "INE973X01012",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540728",
                "SecurityId": "SAYAJIIND",
                "SecurityName": "SAYAJI INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE327G01032",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540729",
                "SecurityId": "VANTABIO",
                "SecurityName": "VANTA BIOSCIENCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE695X01011",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540730",
                "SecurityId": "MEHAI",
                "SecurityName": "MEHAI TECHNOLOGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE062Y01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540735",
                "SecurityId": "IRIS",
                "SecurityName": "IRIS BUSINESS SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE864K01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540736",
                "SecurityId": "SIDDH",
                "SecurityName": "SIDDHARTH EDUCATION SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE930X01012",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540737",
                "SecurityId": "SGRL",
                "SecurityName": "SHREE GANESH REMEDIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE414Y01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540738",
                "SecurityId": "STL",
                "SecurityName": "SHREEJI TRANSLOGISTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE402Y01010",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540739",
                "SecurityId": "ABSLRIF5RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 5 - REGULAR PLAN GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1IZ0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540740",
                "SecurityId": "ABSLRIF5RD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 5 - REGULAR PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KB1JA1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540741",
                "SecurityId": "ABSLRIF5DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 5 - DIRECT PLAN GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1JB9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540742",
                "SecurityId": "ABSLRIF5DD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 5 - DIRECT PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KB1JC7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540743",
                "SecurityId": "GODREJAGRO",
                "SecurityName": "GODREJ AGROVET LTD",
                "FaceValue": "10",
                "ISINNo": "INE850D01014",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540744",
                "SecurityId": "MESCON",
                "SecurityName": "MIDEAST INTEGRATED STEELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE170N01016",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540749",
                "SecurityId": "MASFIN",
                "SecurityName": "MAS FINANCIAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE348L01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540750",
                "SecurityId": "IEX",
                "SecurityName": "INDIAN ENERGY EXCHANGE LTD",
                "FaceValue": "1",
                "ISINNo": "INE022Q01020",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540755",
                "SecurityId": "GICRE",
                "SecurityName": "GENERAL INSURANCE CORPORATION OF INDIA",
                "FaceValue": "5",
                "ISINNo": "INE481Y01014",
                "Industry": "General Insurance",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540756",
                "SecurityId": "KAARYAFSL",
                "SecurityName": "KAARYA FACILITIES AND SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE282Y01016",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540757",
                "SecurityId": "SCPL",
                "SecurityName": "SHEETAL COOL PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE501Y01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540758",
                "SecurityId": "NCBFIVADD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD-IV SR A DR DVP 22OT20",
                "FaceValue": "10",
                "ISINNo": "INF204KB1RR3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540759",
                "SecurityId": "NCBFIVADG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD-IV SR A DR GWTH 22OT20",
                "FaceValue": "10",
                "ISINNo": "INF204KB1RQ5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540760",
                "SecurityId": "NCBFIVAD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD-IV SR A REG DVP 22OT20",
                "FaceValue": "10",
                "ISINNo": "INF204KB1RP7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540761",
                "SecurityId": "NCBFIVAG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD-IV SR A REG GWTH 22OT20",
                "FaceValue": "10",
                "ISINNo": "INF204KB1RO0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540762",
                "SecurityId": "TIINDIA",
                "SecurityName": "TUBE INVESTMENTS OF INDIA LTD",
                "FaceValue": "1",
                "ISINNo": "INE974X01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540763",
                "SecurityId": "IPRU3013",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 18 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB11Y6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540764",
                "SecurityId": "IPRU3014",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 18 - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB12Y4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540765",
                "SecurityId": "IPRU9215",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 18 - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB13Y2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540766",
                "SecurityId": "IPRU9216",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND SERIES 18 - DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB14Y0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540767",
                "SecurityId": "NAM-INDIA",
                "SecurityName": "NIPPON LIFE INDIA ASSET MANAGEMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE298J01013",
                "Industry": "Asset Management Cos.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540768",
                "SecurityId": "MAHLOG",
                "SecurityName": "MAHINDRA LOGISTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE766P01016",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540769",
                "SecurityId": "NIACL",
                "SecurityName": "THE NEW INDIA ASSURANCE COMPANY LTD",
                "FaceValue": "5",
                "ISINNo": "INE470Y01017",
                "Industry": "General Insurance",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540774",
                "SecurityId": "IFGLEXPOR",
                "SecurityName": "IFGL REFRACTORIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE133Y01011",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540775",
                "SecurityId": "KHADIM",
                "SecurityName": "KHADIM INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE834I01025",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540776",
                "SecurityId": "5PAISA",
                "SecurityName": "5PAISA CAPITAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE618L01018",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540777",
                "SecurityId": "HDFCLIFE",
                "SecurityName": "HDFC LIFE INSURANCE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE795G01014",
                "Industry": "Life Insurance",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540778",
                "SecurityId": "NIVBDDP",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD SR B DR DVP 06JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB12Y3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540779",
                "SecurityId": "NIVBDGP",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD SR B DR GWTH 06JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB11Y5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540780",
                "SecurityId": "NIVBDP",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD SR B RG DVP 06JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB10Y7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540781",
                "SecurityId": "NIVBGP",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD SR B RG GWTH 06JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB19X0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540782",
                "SecurityId": "SBRANDS",
                "SecurityName": "SANGHVI BRANDS LTD",
                "FaceValue": "10",
                "ISINNo": "INE204Y01010",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540783",
                "SecurityId": "VRLLOGBBPH",
                "SecurityName": "VRLLOGBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540786",
                "SecurityId": "SHARIKA",
                "SecurityName": "SHARIKA ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE669Y01014",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540787",
                "SecurityId": "ICICIB22",
                "SecurityName": "BHARAT 22 ETF - ICICI PRUDENTIAL AMC",
                "FaceValue": "10",
                "ISINNo": "INF109KB15Y7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540788",
                "SecurityId": "ASPIRA",
                "SecurityName": "ASPIRA PATHLAB & DIAGNOSTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE500C01017",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540789",
                "SecurityId": "DNAMEDIA",
                "SecurityName": "DILIGENT MEDIA CORPORATION LTD",
                "FaceValue": "1",
                "ISINNo": "INE016M01021",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540790",
                "SecurityId": "EASTERNGAS",
                "SecurityName": "EASTERN GASES LTD",
                "FaceValue": "10",
                "ISINNo": "INE846C01014",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540791",
                "SecurityId": "HHOF1140DG",
                "SecurityName": "HDFC HOF-I-1140D NOVEMBER 2017(1) - DIRECT OPTION - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KB1W61",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540792",
                "SecurityId": "HHOF1140DD",
                "SecurityName": "HDFC HOF-I-1140D NOVEMBER 2017(1) - DIRECT OPTION - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KB1W79",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540793",
                "SecurityId": "HHOF1140RG",
                "SecurityName": "HDFC HOF-I-1140D NOVEMBER 2017(1) - REGULAR OPTION - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KB1W87",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540794",
                "SecurityId": "HHOF1140RD",
                "SecurityName": "HDFC HOF-I-1140D NOVEMBER 2017(1) - REGULAR  OPTION - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF179KB1W95",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540795",
                "SecurityId": "DYNAMIC",
                "SecurityName": "DYNAMIC CABLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE600Y01019",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540796",
                "SecurityId": "RATNABHUMI",
                "SecurityName": "RATNABHUMI DEVELOPERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE821Y01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540797",
                "SecurityId": "SHALBY",
                "SecurityName": "SHALBY LTD",
                "FaceValue": "10",
                "ISINNo": "INE597J01018",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540798",
                "SecurityId": "FSC",
                "SecurityName": "FUTURE SUPPLY CHAIN SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE935Q01015",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540799",
                "SecurityId": "DACEFRG",
                "SecurityName": "DSP BLACKROCK A.C.E. FUND SERIES 1 - REGULAR GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF740KA1CU1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540800",
                "SecurityId": "DACEFRDP",
                "SecurityName": "DSP BLACKROCK A.C.E. FUND SERIES 1 - REGULAR-DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF740KA1CV9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540801",
                "SecurityId": "DACEFDG",
                "SecurityName": "DSP BLACKROCK A.C.E. FUND SERIES 1 - DIRECT GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF740KA1CW7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540802",
                "SecurityId": "DACEFDDP",
                "SecurityName": "DSP BLACKROCK A.C.E. FUND SERIES 1 - DIRECT - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF740KA1CX5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540809",
                "SecurityId": "MRCEXIM",
                "SecurityName": "MRC EXIM LTD",
                "FaceValue": "10",
                "ISINNo": "INE333Y01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540810",
                "SecurityId": "SREELBBPH",
                "SecurityName": "SREELBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540811",
                "SecurityId": "DML",
                "SecurityName": "DIGGI MULTITRADE LTD",
                "FaceValue": "10",
                "ISINNo": "INE158R01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540812",
                "SecurityId": "KMSL",
                "SecurityName": "KIDS MEDICAL SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE457Y01014",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540821",
                "SecurityId": "SADHNA",
                "SecurityName": "SADHNA BROADCAST LTD",
                "FaceValue": "10",
                "ISINNo": "INE994R01010",
                "Industry": "Broadcasting & Cable TV",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540822",
                "SecurityId": "SANTOSHIND",
                "SecurityName": "SANTOSH INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE576C01017",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540823",
                "SecurityId": "VITESSE",
                "SecurityName": "VITESSE AGRO LTD",
                "FaceValue": "10",
                "ISINNo": "INE550U01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540824",
                "SecurityId": "ASTRON",
                "SecurityName": "ASTRON PAPER & BOARD MILL LTD",
                "FaceValue": "10",
                "ISINNo": "INE646X01014",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540825",
                "SecurityId": "NCBFIVBCD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SR C DR DVP 20MR21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1ST7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540826",
                "SecurityId": "NCBFIVCG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SR C RG GWTH 20MR21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1SQ3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540827",
                "SecurityId": "NCBFIVBCG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SR C DR GWTH 20MR21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1SS9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540828",
                "SecurityId": "NCBFIVCD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SR C RG DVP 20MR21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1SR1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540829",
                "SecurityId": "CHANDRIMA",
                "SecurityName": "CHANDRIMA MERCANTILES LTD",
                "FaceValue": "10",
                "ISINNo": "INE371F01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540843",
                "SecurityId": "RITHWIKFMS",
                "SecurityName": "RITHWIK FACILITY MANAGEMENT SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE819Y01015",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540850",
                "SecurityId": "JFL",
                "SecurityName": "JHANDEWALAS FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE841Y01019",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540861",
                "SecurityId": "IPRU3018",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 19 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB16Z2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540862",
                "SecurityId": "IPRU3019",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 19  - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB17Z0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540863",
                "SecurityId": "IPRU9220",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 19  - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB18Z8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540864",
                "SecurityId": "IPRU9221",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 19  -DIRECT PLAN DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KB19Z6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540874",
                "SecurityId": "7SEASL",
                "SecurityName": "7SEAS ENTERTAINMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE454F01010",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540879",
                "SecurityId": "APOLLO",
                "SecurityName": "APOLLO MICRO SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE713T01010",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540896",
                "SecurityId": "NCBFIVDDD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SRD DR DVP 18JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1TJ6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540897",
                "SecurityId": "NCBFIVDG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SRD RG GWTH 18JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1TG2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540898",
                "SecurityId": "NCBFIVDDG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SRD DR GWTH 18JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1TI8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540899",
                "SecurityId": "NCBFIVDD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  CAPITAL BUILDER FD IV SRD RG DVP 18JN21",
                "FaceValue": "10",
                "ISINNo": "INF204KB1TH0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540900",
                "SecurityId": "NEWGEN",
                "SecurityName": "NEWGEN SOFTWARE TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE619B01017",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540901",
                "SecurityId": "PRAXIS",
                "SecurityName": "PRAXIS HOME RETAIL LTD",
                "FaceValue": "5",
                "ISINNo": "INE546Y01022",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540902",
                "SecurityId": "AMBER",
                "SecurityName": "AMBER ENTERPRISES INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE371P01015",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540903",
                "SecurityId": "CRPRISK",
                "SecurityName": "CRP RISK MANAGEMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE422M01013",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540904",
                "SecurityId": "TIRUFOAM",
                "SecurityName": "TIRUPATI FOAM LTD",
                "FaceValue": "10",
                "ISINNo": "INE115G01015",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540914",
                "SecurityId": "SRUSTEELS",
                "SecurityName": "SRU STEELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE425C01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540923",
                "SecurityId": "AML",
                "SecurityName": "ASHOKA METCAST LTD",
                "FaceValue": "10",
                "ISINNo": "INE760Y01011",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540935",
                "SecurityId": "GALAXYSURF",
                "SecurityName": "GALAXY SURFACTANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE600K01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540936",
                "SecurityId": "GGL",
                "SecurityName": "GAUTAM GEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE063Z01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540937",
                "SecurityId": "MEDICO",
                "SecurityName": "MEDICO REMEDIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE630Y01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540938",
                "SecurityId": "GUJHYSPIN",
                "SecurityName": "GUJARAT HY-SPIN LTD",
                "FaceValue": "10",
                "ISINNo": "INE578V01013",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540945",
                "SecurityId": "FSSPL",
                "SecurityName": "FOCUS SUITES SOLUTIONS & SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE180Z01019",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540947",
                "SecurityId": "IPRU3034",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 20 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1333",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540949",
                "SecurityId": "IPRU3035",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 20 - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1341",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540950",
                "SecurityId": "IPRU9236",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 20 - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1358",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540951",
                "SecurityId": "IPRU9237",
                "SecurityName": "ICICI PRUDENTIAL VALUE FUND - SERIES 20 - DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1366",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540952",
                "SecurityId": "LAL",
                "SecurityName": "LORENZINI APPARELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE740X01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540953",
                "SecurityId": "KENVI",
                "SecurityName": "KENVI JEWELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE923Y01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540954",
                "SecurityId": "IWP",
                "SecurityName": "THE INDIAN WOOD PRODUCTS COMPANY LTD",
                "FaceValue": "2",
                "ISINNo": "INE586E01020",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540955",
                "SecurityId": "TDSL",
                "SecurityName": "TASTY DAIRY SPECIALITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE773Y01014",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540956",
                "SecurityId": "BHATIA",
                "SecurityName": "BHATIA COMMUNICATIONS & RETAIL (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE341Z01017",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540961",
                "SecurityId": "SHIVAMILLS",
                "SecurityName": "SHIVA MILLS LTD",
                "FaceValue": "10",
                "ISINNo": "INE644Y01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540975",
                "SecurityId": "ASTERDM",
                "SecurityName": "ASTER DM HEALTHCARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE914M01019",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540980",
                "SecurityId": "YSL",
                "SecurityName": "THE YAMUNA SYNDICATE LTD",
                "FaceValue": "100",
                "ISINNo": "INE868X01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540981",
                "SecurityId": "KTKIND4RG",
                "SecurityName": "KOTAK INDIA GROWTH FUND   SERIES 4 - REGULAR PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K017S3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540982",
                "SecurityId": "KTKIND4RD",
                "SecurityName": "KOTAK INDIA GROWTH FUND   SERIES 4 - REGULAR PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K018S1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540983",
                "SecurityId": "KTKIND4DD",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 4 DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K010T6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "540984",
                "SecurityId": "KTKIND4DG",
                "SecurityName": "KOTAK INDIA GROWTH FUND   SERIES 4 - DIRECT PLAN GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K019S9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541005",
                "SecurityId": "KANCOTEA",
                "SecurityName": "KANCO TEA & INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE398L01017",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541006",
                "SecurityId": "ANGEL",
                "SecurityName": "ANGEL FIBERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE339Z01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541019",
                "SecurityId": "HGINFRA",
                "SecurityName": "H.G. INFRA ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE926X01010",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541053",
                "SecurityId": "EIS",
                "SecurityName": "EAST INDIA SECURITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE482Z01019",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541068",
                "SecurityId": "AXISCBD1D",
                "SecurityName": "AXIS CAPITAL BUILDER FUND - SERIES 1 (1540 DAYS) - DIRECT PLAN  - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF846K01B77",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541069",
                "SecurityId": "AXISCBDGG",
                "SecurityName": "AXIS CAPITAL BUILDER FUND - SERIES 1 (1540 DAYS) - DIRECT PLAN  - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01B69",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541070",
                "SecurityId": "AXISCBDPD",
                "SecurityName": "AXIS CAPITAL BUILDER FUND - SERIES 1 (1540 DAYS) - REGULAR PLAN  - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF846K01B93",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541071",
                "SecurityId": "AXISCBGPG",
                "SecurityName": "AXIS CAPITAL BUILDER FUND - SERIES 1 (1540 DAYS) - REGULAR PLAN  - GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF846K01B85",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541083",
                "SecurityId": "INFLAME",
                "SecurityName": "INFLAME APPLIANCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE464Z01017",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541084",
                "SecurityId": "DACE2RG",
                "SecurityName": "DSP BLACKROCK A C E FUND - SERIES 2 - REGULAR PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF740KA1EK8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541085",
                "SecurityId": "DACE2RDP",
                "SecurityName": "DSP BLACKROCK A C E FUND - SERIES 2 - REGULAR PLAN - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF740KA1EL6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541086",
                "SecurityId": "DACE2DG",
                "SecurityName": "DSP BLACKROCK A C E FUND - SERIES 2 - DIRECT PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF740KA1EM4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541087",
                "SecurityId": "DACE2DDP",
                "SecurityName": "DSP BLACKROCK A C E FUND - SERIES 2 - DIRECT PLAN - DIVIDEND PAYOUT  OPTION",
                "FaceValue": "10",
                "ISINNo": "INF740KA1EN2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541092",
                "SecurityId": "ABSLRIF6RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 6- REGULAR PLAN- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1LL4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541093",
                "SecurityId": "ABSLRIF6RD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 6- REGULAR PLAN- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KB1LM2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541094",
                "SecurityId": "ABSLRIF6DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 6- DIRECT PLAN- GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF209KB1LN0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541095",
                "SecurityId": "ABSLRIF6DD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 6- DIRECT PLAN- DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KB1LO8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541096",
                "SecurityId": "BPLPHARMA",
                "SecurityName": "BHARAT PARENTERALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE365Y01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541112",
                "SecurityId": "SHREESHAY",
                "SecurityName": "SHREESHAY ENGINEERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE452Z01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541133",
                "SecurityId": "ACFL",
                "SecurityName": "APEX CAPITAL AND FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE758W01019",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541143",
                "SecurityId": "BDL",
                "SecurityName": "BHARAT DYNAMICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE171Z01018",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541144",
                "SecurityId": "ACTIVE",
                "SecurityName": "ACTIVE CLOTHING CO LTD",
                "FaceValue": "10",
                "ISINNo": "INE380Z01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541151",
                "SecurityId": "RIDINGS",
                "SecurityName": "RIDINGS CONSULTING ENGINEERS INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE314Z01014",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541152",
                "SecurityId": "ADVITIYA",
                "SecurityName": "ADVITIYA TRADE INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE705X01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541153",
                "SecurityId": "BANDHANBNK",
                "SecurityName": "BANDHAN BANK LTD",
                "FaceValue": "10",
                "ISINNo": "INE545U01014",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541154",
                "SecurityId": "HAL",
                "SecurityName": "HINDUSTAN AERONAUTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE066F01012",
                "Industry": "Aerospace",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541161",
                "SecurityId": "KARDA",
                "SecurityName": "KARDA CONSTRUCTIONS LTD",
                "FaceValue": "2",
                "ISINNo": "INE278R01026",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541163",
                "SecurityId": "SANDHAR",
                "SecurityName": "SANDHAR TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE278H01035",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541167",
                "SecurityId": "YASHO",
                "SecurityName": "YASHO INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE616Z01012",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541178",
                "SecurityId": "BENARA",
                "SecurityName": "BENARA BEARINGS AND PISTONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE495Z01011",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541179",
                "SecurityId": "ISEC",
                "SecurityName": "ICICI SECURITIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE763G01038",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541195",
                "SecurityId": "MIDHANI",
                "SecurityName": "MISHRA DHATU NIGAM LTD",
                "FaceValue": "10",
                "ISINNo": "INE099Z01011",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541196",
                "SecurityId": "LEX",
                "SecurityName": "LEX NIMBLE SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE860Y01019",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541206",
                "SecurityId": "OBCL",
                "SecurityName": "ORISSA BENGAL CARRIER LTD",
                "FaceValue": "10",
                "ISINNo": "INE426Z01016",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541228",
                "SecurityId": "TRL",
                "SecurityName": "TAYLORMADE RENEWABLES LTD",
                "FaceValue": "10",
                "ISINNo": "INE459Z01017",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541233",
                "SecurityId": "LEMONTREE",
                "SecurityName": "LEMON TREE HOTELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE970X01018",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541252",
                "SecurityId": "ABSLRIF7RG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 7 - REGULAR PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF209KB1NP1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541253",
                "SecurityId": "ABSLRIF7RD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 7 - REGULAR PLAN - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KB1NQ9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541254",
                "SecurityId": "ABSLRIF7DG",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 7 - DIRECT PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF209KB1NR7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541255",
                "SecurityId": "ABSLRIF7DD",
                "SecurityName": "ADITYA BIRLA SUN LIFE RESURGENT INDIA FUND - SERIES 7 - DIRECT PLAN - DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF209KB1NS5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541269",
                "SecurityId": "CHEMFABALKA",
                "SecurityName": "CHEMFAB ALKALIS LTD",
                "FaceValue": "10",
                "ISINNo": "INE783X01023",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541276",
                "SecurityId": "HARDWYN",
                "SecurityName": "HARDWYN INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE626Z01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541299",
                "SecurityId": "DLCL",
                "SecurityName": "DR LALCHANDANI LABS LTD",
                "FaceValue": "10",
                "ISINNo": "INE871Z01013",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541301",
                "SecurityId": "ORIENTELEC",
                "SecurityName": "ORIENT ELECTRIC LTD",
                "FaceValue": "1",
                "ISINNo": "INE142Z01019",
                "Industry": "Consumer Electronics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541302",
                "SecurityId": "DHRUV",
                "SecurityName": "DHRUV CONSULTANCY SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE506Z01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541303",
                "SecurityId": "AKSHAR",
                "SecurityName": "AKSHAR SPINTEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE256Z01017",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541304",
                "SecurityId": "INDOUS",
                "SecurityName": "INDO US BIO-TECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE250Z01010",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541309",
                "SecurityId": "IPRU3095",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 2 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1CV2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541310",
                "SecurityId": "IPRU3096",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 2 - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1CW0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541311",
                "SecurityId": "IPRU9297",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 2 - DIRECT PLAN CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1CX8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541312",
                "SecurityId": "IPRU9298",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 2 - DIRECT PLAN DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1CY6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541313",
                "SecurityId": "ICICI500",
                "SecurityName": "ICICI PRUDENTIAL S&P BSE 500 ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KC1CZ3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541336",
                "SecurityId": "INDOSTAR",
                "SecurityName": "INDOSTAR CAPITAL FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE896L01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541337",
                "SecurityId": "MILEFUR",
                "SecurityName": "MILESTONE FURNITURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE424Z01011",
                "Industry": "Furniture-Furnishing-Paints",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541338",
                "SecurityId": "UHZAVERI",
                "SecurityName": "U. H. ZAVERI LTD",
                "FaceValue": "10",
                "ISINNo": "INE556Z01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541341",
                "SecurityId": "KTKIND5RG",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 5 - REGULAR PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K011X6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541343",
                "SecurityId": "KTKIND5RD",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 5 - REGULAR PLAN - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K012X4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541345",
                "SecurityId": "KTKIND5DG",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 5 - DIRECT PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K013X2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541346",
                "SecurityId": "KTKIND5DD",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 5 - DIRECT PLAN - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174K014X0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541347",
                "SecurityId": "PARVATI",
                "SecurityName": "PARVATI SWEETNERS AND POWER LTD",
                "FaceValue": "5",
                "ISINNo": "INE295Z01015",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541352",
                "SecurityId": "MEGASTAR",
                "SecurityName": "MEGASTAR FOODS LTD",
                "FaceValue": "10",
                "ISINNo": "INE00EM01016",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541353",
                "SecurityId": "INNOVATORS",
                "SecurityName": "INNOVATORS FACADE SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE870Z01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541358",
                "SecurityId": "SHWL",
                "SecurityName": "SHREE WORSTEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE926R01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541400",
                "SecurityId": "ZIMLAB",
                "SecurityName": "ZIM LABORATORIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE518E01015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541401",
                "SecurityId": "ARIHANTINS",
                "SecurityName": "ARIHANT INSTITUTE LTD",
                "FaceValue": "10",
                "ISINNo": "INE997Z01016",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541402",
                "SecurityId": "AFFORDABLE",
                "SecurityName": "AFFORDABLE ROBOTIC & AUTOMATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE692Z01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541403",
                "SecurityId": "DOLLAR",
                "SecurityName": "DOLLAR INDUSTRIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE325C01035",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541418",
                "SecurityId": "NGIL",
                "SecurityName": "NAKODA GROUP OF INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE236Y01012",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541444",
                "SecurityId": "PALMJEWELS",
                "SecurityName": "PALM JEWELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE838Z01012",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541445",
                "SecurityId": "WAA",
                "SecurityName": "WAA SOLAR LTD",
                "FaceValue": "10",
                "ISINNo": "INE799N01012",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541450",
                "SecurityId": "ADANIGREEN",
                "SecurityName": "ADANI GREEN ENERGY LTD",
                "FaceValue": "10",
                "ISINNo": "INE364U01010",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541485",
                "SecurityId": "MCLEODBBPH",
                "SecurityName": "MCLEODBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541503",
                "SecurityId": "UNICK",
                "SecurityName": "UNICK FIX-A-FORM AND PRINTERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE250G01010",
                "Industry": "Comm.Printing/Stationery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541540",
                "SecurityId": "SOLARA",
                "SecurityName": "SOLARA ACTIVE PHARMA SCIENCES LTD",
                "FaceValue": "10",
                "ISINNo": "INE624Z01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541545",
                "SecurityId": "TALWGYM",
                "SecurityName": "TALWALKARS HEALTHCLUBS LTD",
                "FaceValue": "10",
                "ISINNo": "INE627Z01019",
                "Industry": "Other Leisure Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541546",
                "SecurityId": "GAYAHWS",
                "SecurityName": "GAYATRI HIGHWAYS LTD",
                "FaceValue": "2",
                "ISINNo": "INE287Z01012",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541556",
                "SecurityId": "RITES",
                "SecurityName": "RITES LTD",
                "FaceValue": "10",
                "ISINNo": "INE320J01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541557",
                "SecurityId": "FINEORG",
                "SecurityName": "FINE ORGANIC INDUSTRIES LTD",
                "FaceValue": "5",
                "ISINNo": "INE686Y01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541578",
                "SecurityId": "VARROC",
                "SecurityName": "VARROC ENGINEERING LTD",
                "FaceValue": "1",
                "ISINNo": "INE665L01035",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541601",
                "SecurityId": "RAJNISH",
                "SecurityName": "RAJNISH WELLNESS LTD",
                "FaceValue": "10",
                "ISINNo": "INE685Z01017",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541627",
                "SecurityId": "HITECHWIND",
                "SecurityName": "HI-TECH WINDING SYSTEMS LTD",
                "FaceValue": "10",
                "ISINNo": "INE173V01013",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541633",
                "SecurityId": "STRAEXPO",
                "SecurityName": "SOPHIA TRAEXPO LTD",
                "FaceValue": "10",
                "ISINNo": "INE268X01017",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541634",
                "SecurityId": "RAWEDGE",
                "SecurityName": "RAW EDGE INDUSTRIAL SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE960Z01014",
                "Industry": "Construction Materials",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541672",
                "SecurityId": "IPRU3143",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND  - SERIES 3 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1GQ3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541673",
                "SecurityId": "IPRU3144",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND  - SERIES 3 - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1GR1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541674",
                "SecurityId": "IPRU9345",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND  - SERIES 3 -  DIRECT PLAN - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1GS9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541675",
                "SecurityId": "IPRU9346",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND  - SERIES 3 - DIRECT PLAN - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1GT7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541676",
                "SecurityId": "TVF1D",
                "SecurityName": "TATA VAUE FUND SERIES 1 - REGULAR PLAN - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K010H7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541677",
                "SecurityId": "TVF1DZ",
                "SecurityName": "TATA VAUE FUND SERIES 1 - DIRECT PLAN - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K012H3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541678",
                "SecurityId": "TVF1G",
                "SecurityName": "TATA VAUE FUND SERIES 1 - REGULAR PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K019G0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541679",
                "SecurityId": "TVF1GZ",
                "SecurityName": "TATA VAUE FUND SERIES 1 - DIRECT PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K011H5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541700",
                "SecurityId": "TCNSBRANDS",
                "SecurityName": "TCNS CLOTHING CO. LTD",
                "FaceValue": "2",
                "ISINNo": "INE778U01029",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541701",
                "SecurityId": "SUPERSHAKT",
                "SecurityName": "SUPERSHAKTI METALIKS LTD",
                "FaceValue": "10",
                "ISINNo": "INE00SY01011",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541702",
                "SecurityId": "ASHNI",
                "SecurityName": "ASHNISHA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE694W01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541703",
                "SecurityId": "GFIL",
                "SecurityName": "GANESH FILMS INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE00WY01013",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541725",
                "SecurityId": "IDFCEOS6RG",
                "SecurityName": "IDFC EQUITY OPPORTUNITY - SERIES 6 - REGULAR PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF194KA14U2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541726",
                "SecurityId": "IDFCEOS6RD",
                "SecurityName": "IDFC EQUITY OPPORTUNITY - SERIES 6 - REGULAR PLAN - DIVIDEND PAYOUT  OPTION",
                "FaceValue": "10",
                "ISINNo": "INF194KA15U9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541727",
                "SecurityId": "IDFCEOS6DG",
                "SecurityName": "IDFC EQUITY OPPORTUNITY - SERIES 6 - DIRECT PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF194KA17U5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541728",
                "SecurityId": "IDFCEOS6DD",
                "SecurityName": "IDFC EQUITY OPPORTUNITY - SERIES 6 - DIRECT PLAN - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF194KA18U3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541729",
                "SecurityId": "HDFCAMC",
                "SecurityName": "HDFC ASSET MANAGEMENT COMPANY LTD",
                "FaceValue": "5",
                "ISINNo": "INE127D01025",
                "Industry": "Asset Management Cos.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541730",
                "SecurityId": "RADIOCITYPH",
                "SecurityName": "RADIOCITYPH",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541735",
                "SecurityId": "VIVANTA",
                "SecurityName": "VIVANTA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE299W01014",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541741",
                "SecurityId": "TINNATFL",
                "SecurityName": "TINNA TRADE LTD",
                "FaceValue": "10",
                "ISINNo": "INE401Z01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541742",
                "SecurityId": "TVF2D",
                "SecurityName": "TATA VALUE FUND SERIES  2 - REGULAR PLAN  - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K014J5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541743",
                "SecurityId": "TVF2DZ",
                "SecurityName": "TATA VALUE FUND SERIES  2 - DIRECT PLAN  - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K016J0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541744",
                "SecurityId": "TVF2G",
                "SecurityName": "TATA VALUE FUND SERIES  2 - REGULAR  PLAN  - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K013J7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541745",
                "SecurityId": "TVF2GZ",
                "SecurityName": "TATA VALUE FUND SERIES  2 - DIRECT PLAN  - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF277K015J2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541770",
                "SecurityId": "CREDITACC",
                "SecurityName": "CREDITACCESS GRAMEEN LTD",
                "FaceValue": "10",
                "ISINNo": "INE741K01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541771",
                "SecurityId": "AMINTAN",
                "SecurityName": "AMIN TANNERY LTD",
                "FaceValue": "1",
                "ISINNo": "INE572Z01017",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541778",
                "SecurityId": "DEEP",
                "SecurityName": "DEEP POLYMERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE00IY01012",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541799",
                "SecurityId": "SMEL",
                "SecurityName": "SUNGOLD MEDIA AND ENTERTAINMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE266S01011",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541804",
                "SecurityId": "IPRU3168",
                "SecurityName": "ICICI PRU BHARAT CONSUMPTION FUND - SR 4 - CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KC1IU1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541805",
                "SecurityId": "IPRU3169",
                "SecurityName": "ICICI PRU BHARAT CONSUMPTION FUND - SR 4 - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1IV9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541806",
                "SecurityId": "IPRU9370",
                "SecurityName": "ICICI PRU BHARAT CONSUMPTION FUND - SR 4 - DIRECT PLAN CUMULATIVE",
                "FaceValue": "10",
                "ISINNo": "INF109KC1IW7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541807",
                "SecurityId": "IPRU9371",
                "SecurityName": "ICICI PRU BHARAT CONSUMPTION FUND - SR 4 - DIRECT PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF109KC1IX5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541809",
                "SecurityId": "ICICINXT50",
                "SecurityName": "ICICI PRUDENTIAL NIFTY NEXT 50 ETF",
                "FaceValue": "1",
                "ISINNo": "INF109KC1NS5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541865",
                "SecurityId": "ASRL",
                "SecurityName": "ADD-SHOP ERETAIL LTD",
                "FaceValue": "10",
                "ISINNo": "INE01B501018",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541879",
                "SecurityId": "KTKIND7RG",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 7 - REGULAR PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174KA1509",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541881",
                "SecurityId": "KTKIND7RD",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 7 - REGULAR PLAN - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174KA1517",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541882",
                "SecurityId": "KTKIND7DG",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 7 - DIRECT PLAN - GROWTH OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174KA1525",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541884",
                "SecurityId": "KTKIND7DD",
                "SecurityName": "KOTAK INDIA GROWTH FUND SERIES 7 - DIRECT PLAN - DIVIDEND OPTION",
                "FaceValue": "10",
                "ISINNo": "INF174KA1533",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541890",
                "SecurityId": "SPACEINCUBA",
                "SecurityName": "SPACE INCUBATRICS TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE797Z01010",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541929",
                "SecurityId": "SGIL",
                "SecurityName": "SYNERGY GREEN INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE00QT01015",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541936",
                "SecurityId": "THYROBBPH",
                "SecurityName": "THYROBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541945",
                "SecurityId": "RANJEET",
                "SecurityName": "RANJEET MECHATRONICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE01A501019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541956",
                "SecurityId": "IRCON",
                "SecurityName": "IRCON INTERNATIONAL LTD",
                "FaceValue": "2",
                "ISINNo": "INE962Y01021",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541967",
                "SecurityId": "SKYGOLD",
                "SecurityName": "SKY GOLD LTD",
                "FaceValue": "10",
                "ISINNo": "INE01IU01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541972",
                "SecurityId": "SETFSN50",
                "SecurityName": "SBI - ETF - SENSEX NEXT 50",
                "FaceValue": "10",
                "ISINNo": "INF200KA1VQ2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541973",
                "SecurityId": "MACH",
                "SecurityName": "MAC HOTELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE004Z01011",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541974",
                "SecurityId": "MANORAMA",
                "SecurityName": "MANORAMA INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE00VM01010",
                "Industry": "Other Food Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541983",
                "SecurityId": "INNOVATIVE",
                "SecurityName": "INNOVATIVE IDEALS AND SERVICES (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE492Y01011",
                "Industry": "Sp.Consumer Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541984",
                "SecurityId": "NIOSAG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  INDIA OPPT FD  SR A RG GWTH31JN22",
                "FaceValue": "10",
                "ISINNo": "INF204KB1I24",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541985",
                "SecurityId": "NIOSAD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  INDIA OPPT FD  SR A RG DVP31JN22",
                "FaceValue": "10",
                "ISINNo": "INF204KB1I32",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541986",
                "SecurityId": "NIOSADG",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  INDIA OPPT FD  SR A DR GWTH31JN22",
                "FaceValue": "10",
                "ISINNo": "INF204KB1I40",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541987",
                "SecurityId": "NIOSADD",
                "SecurityName": "NIPPON INDIA MUTUAL FUND  INDIA OPPT FD  SR A DR DVP31JN22",
                "FaceValue": "10",
                "ISINNo": "INF204KB1I57",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541988",
                "SecurityId": "AAVAS",
                "SecurityName": "AAVAS FINANCIERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE216P01012",
                "Industry": "Housing Finance ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "541999",
                "SecurityId": "GENERAAGRI",
                "SecurityName": "GENERA AGRI CORP LTD",
                "FaceValue": "10",
                "ISINNo": "INE993L01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542002",
                "SecurityId": "DCL",
                "SecurityName": "DEVOTED CONSTRUCTION LTD",
                "FaceValue": "10",
                "ISINNo": "INE061Z01011",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542011",
                "SecurityId": "GRSE",
                "SecurityName": "GARDEN REACH SHIPBUILDERS & ENGINEERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE382Z01011",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542012",
                "SecurityId": "AAL",
                "SecurityName": "A-1 ACID LTD",
                "FaceValue": "10",
                "ISINNo": "INE911Z01017",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542013",
                "SecurityId": "DOLFIN",
                "SecurityName": "DOLFIN RUBBERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE666Y01010",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542014",
                "SecurityId": "TDPOWBBPH",
                "SecurityName": "TDPOWBBPH",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542019",
                "SecurityId": "SHUBHAM",
                "SecurityName": "SHUBHAM POLYSPIN LTD",
                "FaceValue": "10",
                "ISINNo": "INE01J501010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542020",
                "SecurityId": "AKI",
                "SecurityName": "AKI INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE642Z01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542025",
                "SecurityId": "SUNRETAIL",
                "SecurityName": "SUN RETAIL LTD",
                "FaceValue": "10",
                "ISINNo": "INE206Z01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542034",
                "SecurityId": "SMGOLD",
                "SecurityName": "S. M. GOLD LTD",
                "FaceValue": "10",
                "ISINNo": "INE00Q901014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542046",
                "SecurityId": "VIVIDM",
                "SecurityName": "VIVID MERCANTILE LTD",
                "FaceValue": "10",
                "ISINNo": "INE647Z01017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542057",
                "SecurityId": "BCPL",
                "SecurityName": "BCPL RAILWAY INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE00SW01015",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542066",
                "SecurityId": "ATGL",
                "SecurityName": "ADANI TOTAL GAS LTD",
                "FaceValue": "1",
                "ISINNo": "INE399L01023",
                "Industry": "Integrated Oil & Gas",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542090",
                "SecurityId": "LAXMIBBPH",
                "SecurityName": "LAXMIBBPH",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542117",
                "SecurityId": "NETRIPPLES",
                "SecurityName": "NETRIPPLES SOFTWARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE478B01018",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542123",
                "SecurityId": "PHOSPHATE",
                "SecurityName": "THE PHOSPHATE COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE398C01016",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542131",
                "SecurityId": "MAN50ETF",
                "SecurityName": "MIRAE ASSET NIFTY 50 ETF (MAN50ETF)",
                "FaceValue": "100",
                "ISINNo": "INF769K01EG9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542141",
                "SecurityId": "TECHNOE",
                "SecurityName": "TECHNO ELECTRIC & ENGINEERING COMPANY LTD",
                "FaceValue": "2",
                "ISINNo": "INE285K01026",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542145",
                "SecurityId": "RONI",
                "SecurityName": "RONI HOUSEHOLDS LTD",
                "FaceValue": "10",
                "ISINNo": "INE02AP01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542146",
                "SecurityId": "SKIFL",
                "SecurityName": "SHREE KRISHNA INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE951Z01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542155",
                "SecurityId": "DGL",
                "SecurityName": "DIKSHA GREENS LTD",
                "FaceValue": "10",
                "ISINNo": "INE01GR01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542156",
                "SecurityId": "IPRU3218",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 5 - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1NO4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542158",
                "SecurityId": "IPRU3219",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 5 - DIVIDEND PAYOUT OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1NP1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542160",
                "SecurityId": "IPRU9420",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 5 -  DIRECT PLAN - CUMULATIVE OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1NQ9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542163",
                "SecurityId": "IPRU9421",
                "SecurityName": "ICICI PRUDENTIAL BHARAT CONSUMPTION FUND - SERIES 5 -  DIRECT PLAN - DIVIDEND PAYOUT  OPTION",
                "FaceValue": "10",
                "ISINNo": "INF109KC1NR7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542168",
                "SecurityId": "ORIENTBBPH",
                "SecurityName": "ORIENTBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542176",
                "SecurityId": "ARYAN",
                "SecurityName": "ARYAN SHARE AND STOCK BROKERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE016X01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542206",
                "SecurityId": "NIVAKA",
                "SecurityName": "NIVAKA FASHIONS LTD",
                "FaceValue": "1",
                "ISINNo": "INE139E01028",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542216",
                "SecurityId": "DALBHARAT",
                "SecurityName": "DALMIA BHARAT LTD",
                "FaceValue": "2",
                "ISINNo": "INE00R701025",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542230",
                "SecurityId": "ABSLNN50ET",
                "SecurityName": "ADITYA BIRLA SUN LIFE NIFTY NEXT 50 ETF",
                "FaceValue": "10",
                "ISINNo": "INF209KB1B87",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542231",
                "SecurityId": "NILASPACES",
                "SecurityName": "NILA SPACES LTD",
                "FaceValue": "1",
                "ISINNo": "INE00S901012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542232",
                "SecurityId": "SRD",
                "SecurityName": "SHANKAR LAL RAMPAL DYE-CHEM LTD",
                "FaceValue": "10",
                "ISINNo": "INE01NE01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542233",
                "SecurityId": "TREJHARA",
                "SecurityName": "TREJHARA SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE00CA01015",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542244",
                "SecurityId": "AXISCCDID",
                "SecurityName": "AXIS&#160;CAPITAL&#160;BUILDER&#160;FUND&#160;-&#160;SERIES&#160;4&#160;(1582&#160;DAYS)&#160;-",
                "FaceValue": "10",
                "ISINNo": "INF846K01K76",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542245",
                "SecurityId": "AXISCCDGG",
                "SecurityName": "AXIS&#160;CAPITAL&#160;BUILDER&#160;FUND&#160;-&#160;SERIES&#160;4&#160;(1582&#160;DAYS)&#160;-DIRECT&#160;PLAN&#160;-&#160;GROWTH&#160;OPTION",
                "FaceValue": "10",
                "ISINNo": "INF846K01K68",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542246",
                "SecurityId": "AXISCCDPD",
                "SecurityName": "AXIS&#160;CAPITAL&#160;BUILDER&#160;FUND&#160;-&#160;SERIES&#160;4&#160;(1582&#160;DAYS)&#160;-REGULAR&#160;PLAN&#160;-&#160;DIVIDEND&#160;PAYOUT&#160;OPTION",
                "FaceValue": "10",
                "ISINNo": "INF846K01K92",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542247",
                "SecurityId": "AXISCCGPG",
                "SecurityName": "AXIS&#160;CAPITAL&#160;BUILDER&#160;FUND&#160;-&#160;SERIES&#160;4&#160;(1582&#160;DAYS)&#160;- REGULAR&#160;PLAN&#160;-&#160;GROWTH&#160;OPTION",
                "FaceValue": "10",
                "ISINNo": "INF846K01K84",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542248",
                "SecurityId": "DECCAN",
                "SecurityName": "DECCAN HEALTH CARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE452W01019",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542276",
                "SecurityId": "SETLBBPH",
                "SecurityName": "SETLBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542285",
                "SecurityId": "AXITA",
                "SecurityName": "AXITA COTTON LTD",
                "FaceValue": "10",
                "ISINNo": "INE02EZ01014",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542323",
                "SecurityId": "KPIGLOBAL",
                "SecurityName": "K.P.I. GLOBAL INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE542W01017",
                "Industry": "Electric Utilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542332",
                "SecurityId": "HIKLASS",
                "SecurityName": "HI-KLASS TRADING AND INVESTMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE302R01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542333",
                "SecurityId": "CESCVENTURE",
                "SecurityName": "CESC VENTURES LTD",
                "FaceValue": "10",
                "ISINNo": "INE425Y01011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542337",
                "SecurityId": "SPENCER",
                "SecurityName": "SPENCERS RETAIL LTD",
                "FaceValue": "5",
                "ISINNo": "INE020801028",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542351",
                "SecurityId": "GLOSTERLTD",
                "SecurityName": "GLOSTER LTD",
                "FaceValue": "10",
                "ISINNo": "INE350Z01018",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542367",
                "SecurityId": "XELPMOC",
                "SecurityName": "XELPMOC DESIGN AND TECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE01P501012",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542376",
                "SecurityId": "SPACAPS",
                "SecurityName": "SPA CAPITAL SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE726X01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542377",
                "SecurityId": "AARSHYAM",
                "SecurityName": "AAR SHYAM INDIA INVESTMENT COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE512R01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542383",
                "SecurityId": "RITCO",
                "SecurityName": "RITCO LOGISTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE01EG01016",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542399",
                "SecurityId": "CHALET",
                "SecurityName": "CHALET HOTELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE427F01016",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542400",
                "SecurityId": "PSLBBPH",
                "SecurityName": "PSLBBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542409",
                "SecurityId": "CYIENTBBPH",
                "SecurityName": "CYIENTBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542437",
                "SecurityId": "ANMOL",
                "SecurityName": "ANMOL INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE02AR01019",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542446",
                "SecurityId": "JONJUA",
                "SecurityName": "JONJUA OVERSEAS LTD",
                "FaceValue": "10",
                "ISINNo": "INE793Z01027",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542459",
                "SecurityId": "KRANTI",
                "SecurityName": "KRANTI INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE911T01010",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542460",
                "SecurityId": "ANUP",
                "SecurityName": "THE ANUP ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE294Z01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542477",
                "SecurityId": "GLEAM",
                "SecurityName": "GLEAM FABMAT LTD",
                "FaceValue": "10",
                "ISINNo": "INE03CM01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542484",
                "SecurityId": "ARVINDFASN",
                "SecurityName": "ARVIND FASHIONS LTD",
                "FaceValue": "4",
                "ISINNo": "INE955V01021",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542503",
                "SecurityId": "MAHIP",
                "SecurityName": "MAHIP INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE00CX01017",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542513",
                "SecurityId": "UTISXN50",
                "SecurityName": "UTI S&P BSE SENSEX NEXT 50 ETF",
                "FaceValue": "10",
                "ISINNo": "INF789F1AHR6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542524",
                "SecurityId": "AIHL",
                "SecurityName": "AMBASSADOR INTRA HOLDINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE342U01016",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542525",
                "SecurityId": "MAZDALTDBP",
                "SecurityName": "MAZDALTDBP",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542530",
                "SecurityId": "INFYBBPH",
                "SecurityName": "INFYBBPH",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542544",
                "SecurityId": "JYOTIN",
                "SecurityName": "JYOT INTERNATIONAL MARKETING LTD",
                "FaceValue": "10",
                "ISINNo": "INE043R01016",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542579",
                "SecurityId": "AGOL",
                "SecurityName": "ASHAPURI GOLD ORNAMENT LTD",
                "FaceValue": "10",
                "ISINNo": "INE05FR01011",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542580",
                "SecurityId": "AARTECH",
                "SecurityName": "AARTECH SOLONICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE01C001018",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542592",
                "SecurityId": "HBEL",
                "SecurityName": "HUMMING BIRD EDUCATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE02PC01019",
                "Industry": "Education",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542597",
                "SecurityId": "MSTC",
                "SecurityName": "MSTC LTD",
                "FaceValue": "10",
                "ISINNo": "INE255X01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542599",
                "SecurityId": "ROOPSHRI",
                "SecurityName": "ROOPSHRI RESORTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE03WT01017",
                "Industry": "Hotels",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542627",
                "SecurityId": "CHANDNIMACH",
                "SecurityName": "CHANDNI MACHINES LTD",
                "FaceValue": "10",
                "ISINNo": "INE01GZ01011",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542628",
                "SecurityId": "NSL",
                "SecurityName": "NORTHERN SPIRITS LTD",
                "FaceValue": "10",
                "ISINNo": "INE01BL01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542641",
                "SecurityId": "CKFSL",
                "SecurityName": "COX & KINGS FINANCIAL SERVICE LTD",
                "FaceValue": "10",
                "ISINNo": "INE391Z01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542649",
                "SecurityId": "RVNL",
                "SecurityName": "RAIL VIKAS NIGAM LTD",
                "FaceValue": "10",
                "ISINNo": "INE415G01027",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542650",
                "SecurityId": "METROPOLIS",
                "SecurityName": "METROPOLIS HEALTHCARE LTD",
                "FaceValue": "2",
                "ISINNo": "INE112L01020",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542651",
                "SecurityId": "KPITTECH",
                "SecurityName": "KPIT TECHNOLOGIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE04I401011",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542652",
                "SecurityId": "POLYCAB",
                "SecurityName": "POLYCAB INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE455K01017",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542653",
                "SecurityId": "JINAAM",
                "SecurityName": "JINAAMS DRESS LTD",
                "FaceValue": "10",
                "ISINNo": "INE011201014",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542654",
                "SecurityId": "VRFILMS",
                "SecurityName": "V R FILMS & STUDIOS LTD",
                "FaceValue": "10",
                "ISINNo": "INE06LG01010",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542655",
                "SecurityId": "VIKASMCORP",
                "SecurityName": "VIKAS MULTICORP LTD",
                "FaceValue": "1",
                "ISINNo": "INE161L01027",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542665",
                "SecurityId": "NEOGEN",
                "SecurityName": "NEOGEN CHEMICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE136S01016",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542666",
                "SecurityId": "GKP",
                "SecurityName": "G. K. P. PRINTING & PACKAGING LTD",
                "FaceValue": "10",
                "ISINNo": "INE05QJ01015",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542667",
                "SecurityId": "WORL",
                "SecurityName": "WHITE ORGANIC RETAIL LTD",
                "FaceValue": "10",
                "ISINNo": "INE06CG01019",
                "Industry": "Food & Drugs Retailing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542668",
                "SecurityId": "EVANS",
                "SecurityName": "EVANS ELECTRIC LTD",
                "FaceValue": "10",
                "ISINNo": "INE06TD01010",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542669",
                "SecurityId": "BMW",
                "SecurityName": "BMW INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE374E01021",
                "Industry": "Iron & Steel Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542670",
                "SecurityId": "ARTEMISELC",
                "SecurityName": "ARTEMIS ELECTRICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE757T01017",
                "Industry": "Non-Durable Household Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542677",
                "SecurityId": "MAHESH",
                "SecurityName": "MAHESH DEVELOPERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE01MO01013",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542678",
                "SecurityId": "CHCL",
                "SecurityName": "CIAN HEALTHCARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE05BN01019",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542679",
                "SecurityId": "DEL",
                "SecurityName": "DHANASHREE ELECTRONICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE413F01016",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542682",
                "SecurityId": "HARISH",
                "SecurityName": "HARISH TEXTILE ENGINEERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE01K301012",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542683",
                "SecurityId": "SUICH",
                "SecurityName": "SUICH INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE00CU01013",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542684",
                "SecurityId": "PPL",
                "SecurityName": "PRAKASH PIPES LTD",
                "FaceValue": "10",
                "ISINNo": "INE050001010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542685",
                "SecurityId": "DGCONTENT",
                "SecurityName": "DIGICONTENT LTD",
                "FaceValue": "2",
                "ISINNo": "INE03JI01017",
                "Industry": "Publishing",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542694",
                "SecurityId": "PARSHVA",
                "SecurityName": "PARSHVA ENTERPRISES LTD",
                "FaceValue": "10",
                "ISINNo": "INE05MV01019",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542721",
                "SecurityId": "ARL",
                "SecurityName": "ANAND RAYONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE02GA01012",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542724",
                "SecurityId": "EARUM",
                "SecurityName": "EARUM PHARMACEUTICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE060601015",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542725",
                "SecurityId": "SBC",
                "SecurityName": "SBC EXPORTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE04AK01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542726",
                "SecurityId": "INDIAMART",
                "SecurityName": "INDIAMART INTERMESH LTD",
                "FaceValue": "10",
                "ISINNo": "INE933S01016",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542727",
                "SecurityId": "CPML",
                "SecurityName": "CITY PULSE MULTIPLEX LTD",
                "FaceValue": "10",
                "ISINNo": "INE056001014",
                "Industry": "Specialty Retail",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542728",
                "SecurityId": "SKIEL",
                "SecurityName": "SK INTERNATIONAL EXPORT LTD",
                "FaceValue": "10",
                "ISINNo": "INE04ST01013",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542729",
                "SecurityId": "DCMNVL",
                "SecurityName": "DCM NOUVELLE LTD",
                "FaceValue": "10",
                "ISINNo": "INE08KP01019",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542730",
                "SecurityId": "ICICIBANKN",
                "SecurityName": "ICICI PRUDENTIAL BANK ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KC1E27",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542747",
                "SecurityId": "NETFSNX150",
                "SecurityName": "NIPPON INDIA ETF SENSEX NEXT 50",
                "FaceValue": "10",
                "ISINNo": "INF204KB15D0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542752",
                "SecurityId": "AFFLE",
                "SecurityName": "AFFLE (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE00WC01019",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542753",
                "SecurityId": "SEACOAST",
                "SecurityName": "SEACOAST SHIPPING SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE277T01016",
                "Industry": "Distributors",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542758",
                "SecurityId": "ICICIBANKP",
                "SecurityName": "ICICI PRUDENTIAL PRIVATE BANKS ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KC1E35",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542759",
                "SecurityId": "SPANDANA",
                "SecurityName": "SPANDANA SPHOORTY FINANCIAL LTD",
                "FaceValue": "10",
                "ISINNo": "INE572J01011",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542760",
                "SecurityId": "SWSOLAR",
                "SecurityName": "STERLING AND WILSON SOLAR LTD",
                "FaceValue": "1",
                "ISINNo": "INE00M201021",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542771",
                "SecurityId": "NOVATEOR",
                "SecurityName": "NOVATEOR RESEARCH LABORATORIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE08JY01013",
                "Industry": "Personal Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542772",
                "SecurityId": "IIFLWAM",
                "SecurityName": "IIFL WEALTH MANAGEMENT LTD",
                "FaceValue": "2",
                "ISINNo": "INE466L01020",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542773",
                "SecurityId": "IIFLSEC*",
                "SecurityName": "IIFL SECURITIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE489L01022",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542774",
                "SecurityId": "APMFINVEST",
                "SecurityName": "APM FINVEST LTD",
                "FaceValue": "2",
                "ISINNo": "INE08KJ01012",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542801",
                "SecurityId": "MISQUITA",
                "SecurityName": "MISQUITA ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE957W01025",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542802",
                "SecurityId": "GALACTICO",
                "SecurityName": "GALACTICO CORPORATE SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE906Y01010",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542803",
                "SecurityId": "ELLORATRAD",
                "SecurityName": "ELLORA TRADING LTD",
                "FaceValue": "10",
                "ISINNo": "INE560T01015",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542804",
                "SecurityId": "NIESSPJ",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB12F2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542805",
                "SecurityId": "NIESSPI",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT QUARTERLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB13E3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542806",
                "SecurityId": "NIESSPB",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT BONUS PLAN BONUS",
                "FaceValue": "10",
                "ISINNo": "INF204KB10E9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542807",
                "SecurityId": "NIESSPO",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - QUARTERLY DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB10F6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542808",
                "SecurityId": "NIESSPC",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB14E1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542809",
                "SecurityId": "NIESSPP",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - QUARTERLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB11F4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542810",
                "SecurityId": "NIESSPA",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - BONUS PLANBONUS",
                "FaceValue": "10",
                "ISINNo": "INF204KB18E2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542811",
                "SecurityId": "NIESSPG",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT MONTHLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB17E4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542812",
                "SecurityId": "FLUOROCHEM",
                "SecurityName": "GUJARAT FLUOROCHEMICALS LTD",
                "FaceValue": "1",
                "ISINNo": "INE09N301011",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542813",
                "SecurityId": "NIEHSPA",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1- DIRECT DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB10G4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542814",
                "SecurityId": "NIEHSPB",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIRECT MONTHLY DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB11G2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542815",
                "SecurityId": "NIEHSPC",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIRECT MONTHLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB12G0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542816",
                "SecurityId": "NIEHSPF",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - QUARTERLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB15G3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542817",
                "SecurityId": "NIEHSPI",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIRECT QUARTERLY DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB17F1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542818",
                "SecurityId": "NIEHSPK",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIRECT QUARTERLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB18F9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542819",
                "SecurityId": "NIEHSPM",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIRECT DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB19F7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542820",
                "SecurityId": "NIEHSPN",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - MONTHLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB19G5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542830",
                "SecurityId": "IRCTC",
                "SecurityName": "INDIAN RAILWAY CATERING AND TOURISM CORPORATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE335Y01012",
                "Industry": "Travel Support Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542836",
                "SecurityId": "NIESSPD",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB15E8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542837",
                "SecurityId": "NIESSPE",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT GROWTH PLAN GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF204KB11E7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542838",
                "SecurityId": "NIESSPF",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT MONTHLY DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB16E6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542839",
                "SecurityId": "NIEHSPD",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - GROWTH PLAN GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF204KB13G8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542840",
                "SecurityId": "NIESSPH",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIRECT QUARTERLY DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB12E5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542841",
                "SecurityId": "NIEHSPE",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - QUARTERLY DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB14G6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542842",
                "SecurityId": "NIESSPK",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB13F0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542843",
                "SecurityId": "NIEHSPG",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIRECT GROWTH PLAN GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF204KB16F3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542844",
                "SecurityId": "NIEHSPH",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB16G1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542845",
                "SecurityId": "NIEHSPJ",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB17G9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542846",
                "SecurityId": "NIEHSPL",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -&#160;SEGREGATED PORTFOLIO 1 - MONTHLY DIVIDEND PLAN DIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB18G7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542847",
                "SecurityId": "NIESSPL",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - GROWTH PLAN GROWTH",
                "FaceValue": "10",
                "ISINNo": "INF204KB19E0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542848",
                "SecurityId": "NIESSPM",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - MONTHLY DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB14F8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542849",
                "SecurityId": "NIESSPN",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND - SEGREGATED PORTFOLIO 1 - MONTHLY DIVIDEND PLAN REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB15F5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542850",
                "SecurityId": "GOBLIN",
                "SecurityName": "GOBLIN INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE492Z01018",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542851",
                "SecurityId": "GENSOL",
                "SecurityName": "GENSOL ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE06H201014",
                "Industry": "Consulting Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542852",
                "SecurityId": "VISHWARAJ",
                "SecurityName": "VISHWARAJ SUGAR INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE430N01014",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542857",
                "SecurityId": "GREENPANEL",
                "SecurityName": "GREENPANEL INDUSTRIES LTD",
                "FaceValue": "1",
                "ISINNo": "INE08ZM01014",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542862",
                "SecurityId": "SHAHLON",
                "SecurityName": "SHAHLON SILK INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE052001018",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542863",
                "SecurityId": "ABSLBANETF",
                "SecurityName": "ADITYA BIRLA SUN LIFE BANKING ETF",
                "FaceValue": "10",
                "ISINNo": "INF209KB1O58",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542864",
                "SecurityId": "MOUNT",
                "SecurityName": "MOUNT HOUSING AND INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE444X01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542865",
                "SecurityId": "ANUROOP",
                "SecurityName": "ANUROOP PACKAGING LTD",
                "FaceValue": "10",
                "ISINNo": "INE490Z01012",
                "Industry": "Containers & Packaging",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542866",
                "SecurityId": "JSGLEASING",
                "SecurityName": "JSG LEASING LTD",
                "FaceValue": "10",
                "ISINNo": "INE317W01014",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542867",
                "SecurityId": "CSBBANK",
                "SecurityName": "CSB BANK LTD",
                "FaceValue": "10",
                "ISINNo": "INE679A01013",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542904",
                "SecurityId": "UJJIVANSFB",
                "SecurityName": "UJJIVAN SMALL FINANCE BANK LTD",
                "FaceValue": "10",
                "ISINNo": "INE551W01018",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542905",
                "SecurityId": "SHIL",
                "SecurityName": "SOMANY HOME INNOVATION LTD",
                "FaceValue": "2",
                "ISINNo": "INE05AN01011",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542906",
                "SecurityId": "EASUN",
                "SecurityName": "EASUN CAPITAL MARKETS LTD",
                "FaceValue": "10",
                "ISINNo": "INE771C01014",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542907",
                "SecurityId": "PRINCEPIPE",
                "SecurityName": "PRINCE PIPES AND FITTINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE689W01016",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542911",
                "SecurityId": "ASSAMENT",
                "SecurityName": "ASSAM ENTRADE LTD",
                "FaceValue": "10",
                "ISINNo": "INE165G01010",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542918",
                "SecurityId": "GIANLIFE",
                "SecurityName": "GIAN LIFE CARE LTD",
                "FaceValue": "10",
                "ISINNo": "INE063601012",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542919",
                "SecurityId": "ARTEMISMED",
                "SecurityName": "ARTEMIS MEDICARE SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE025R01013",
                "Industry": "Healthcare Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542920",
                "SecurityId": "SUMICHEM",
                "SecurityName": "SUMITOMO CHEMICAL INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE258G01013",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542921",
                "SecurityId": "ICICIM150",
                "SecurityName": "ICICI PRUDENTIAL MIDCAP 150 ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KC1G82",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542922",
                "SecurityId": "MANXT50ETF",
                "SecurityName": "MIRAE ASSET NIFTY NEXT 50 ETF (MANXT50ETF)",
                "FaceValue": "275",
                "ISINNo": "INF769K01FN2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542924",
                "SecurityId": "JANUSCORP",
                "SecurityName": "JANUS CORPORATION LTD",
                "FaceValue": "10",
                "ISINNo": "INE04OV01018",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542931",
                "SecurityId": "VARDHANCFL",
                "SecurityName": "VARDHAN CAPITAL & FINANCE LTD",
                "FaceValue": "10",
                "ISINNo": "INE128G01018",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542932",
                "SecurityId": "BIRLATYRES",
                "SecurityName": "BIRLA TYRES LTD",
                "FaceValue": "10",
                "ISINNo": "INE0AEJ01013",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542933",
                "SecurityId": "UNIVPHOTO",
                "SecurityName": "UNIVERSUS PHOTO IMAGINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE03V001013",
                "Industry": "Photographic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542934",
                "SecurityId": "CBPL",
                "SecurityName": "CHANDRA BHAGAT PHARMA LTD",
                "FaceValue": "10",
                "ISINNo": "INE07QQ01016",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542935",
                "SecurityId": "ICLORGANIC",
                "SecurityName": "ICL ORGANIC DAIRY PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE0AU701018",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "542938",
                "SecurityId": "OCTAVIUSPL",
                "SecurityName": "OCTAVIUS PLANTATIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE117S01016",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543064",
                "SecurityId": "SUVENPHAR",
                "SecurityName": "SUVEN PHARMACEUTICALS LTD",
                "FaceValue": "1",
                "ISINNo": "INE03QK01018",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543065",
                "SecurityId": "SMAUTO",
                "SecurityName": "SM AUTO STAMPING LTD",
                "FaceValue": "10",
                "ISINNo": "INE0C4I01011",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543066",
                "SecurityId": "SBICARD",
                "SecurityName": "SBI CARDS AND PAYMENT SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE018E01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543144",
                "SecurityId": "08MPD",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - MONTHLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB11T5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543145",
                "SecurityId": "08ADR",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB12S5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543146",
                "SecurityId": "08MPR",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - MONTHLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB12T3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543147",
                "SecurityId": "08AMD",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT MONTHLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB13S3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543148",
                "SecurityId": "08AMR",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT MONTHLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB14S1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543149",
                "SecurityId": "08BPB",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - BONUS PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB15S8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543150",
                "SecurityId": "08GPG",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - GROWTH PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB16S6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543151",
                "SecurityId": "08ABB",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT BONUS PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB17R6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543152",
                "SecurityId": "08QPD",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - QUARTERLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB17S4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543153",
                "SecurityId": "08AGG",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT GROWTH PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB18R4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543154",
                "SecurityId": "08QPR",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - QUARTERLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB18S2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543155",
                "SecurityId": "08AQD",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT QUARTERLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB19R2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543156",
                "SecurityId": "08DPD",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB19S0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543168",
                "SecurityId": "08AQR",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT QUARTERLY DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB10S9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543169",
                "SecurityId": "08DPR",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB10T7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543170",
                "SecurityId": "08ADD",
                "SecurityName": "NIPPON INDIA EQUITY SAVINGS FUND -  SEGREGATED PORTFOLIO 2 - DIRECT DIVIDEND PLAN(ISSUE PRICE: 0.01)",
                "FaceValue": "10",
                "ISINNo": "INF204KB11S7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543171",
                "SecurityId": "ROJL",
                "SecurityName": "RO JEWELS LTD",
                "FaceValue": "10",
                "ISINNo": "INE0BDU01010",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543172",
                "SecurityId": "COSPOWER",
                "SecurityName": "COSPOWER ENGINEERING LTD",
                "FaceValue": "10",
                "ISINNo": "INE0CQB01010",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543173",
                "SecurityId": "11DPR",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIVIDEND PLANDIVIDEND REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB14U7",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543174",
                "SecurityId": "11GPG",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2GROWTH PLAN GROWTH OPTIONGROWTH",
                "FaceValue": "10",
                "ISINNo": "INF204KB10U5",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543175",
                "SecurityId": "11MPD",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2MONTHLY DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB15U4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543176",
                "SecurityId": "11MPR",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2MONTHLY DIVIDEND PLANREINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB16U2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543177",
                "SecurityId": "11QPD",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2QUARTERLY DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB11U3",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543178",
                "SecurityId": "11QPR",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2QUARTERLY DIVIDEND PLANREINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB12U1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543179",
                "SecurityId": "11ADD",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIRECT PLAN DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB16T4",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543180",
                "SecurityId": "11ADR",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIRECT PLAN DIVIDEND PLANDIVIDEND REINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB17T2",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543181",
                "SecurityId": "11AGG",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIRECT GROWTH PLAN GROWTH OPTIONGROWTH",
                "FaceValue": "10",
                "ISINNo": "INF204KB13T1",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543182",
                "SecurityId": "11AMD",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIRECT MONTHLY DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB18T0",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543183",
                "SecurityId": "11AMR",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIRECT MONTHLY DIVIDEND PLANREINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB19T8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543184",
                "SecurityId": "11AQD",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIRECT QUARTERLY DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB14T9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543185",
                "SecurityId": "11AQR",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIRECT QUARTERLY DIVIDEND PLANREINVESTMENT",
                "FaceValue": "10",
                "ISINNo": "INF204KB15T6",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543186",
                "SecurityId": "11DPD",
                "SecurityName": "NIPPON INDIA EQUITY HYBRID FUND -  SEGREGATED PORTFOLIO 2DIVIDEND PLANDIVIDEND PAYOUT",
                "FaceValue": "10",
                "ISINNo": "INF204KB13U9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543187",
                "SecurityId": "POWERINDIA",
                "SecurityName": "ABB POWER PRODUCTS AND SYSTEMS INDIA LTD",
                "FaceValue": "2",
                "ISINNo": "INE07Y701011",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543193",
                "SecurityId": "DJML",
                "SecurityName": "DJ MEDIAPRINT & LOGISTICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE0B1K01014",
                "Industry": "Transportation - Logistics",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543207",
                "SecurityId": "NATURAL",
                "SecurityName": "NATURAL BIOCON (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE06MM01016",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543208",
                "SecurityId": "BHARTIA",
                "SecurityName": "BHARTIA BACHAT LTD",
                "FaceValue": "10",
                "ISINNo": "INE745R01016",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543209",
                "SecurityId": "BILLWIN",
                "SecurityName": "BILLWIN INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE0CRS01012",
                "Industry": "Diversified",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543210",
                "SecurityId": "AARTISURF",
                "SecurityName": "AARTI SURFACTANTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE09EO01013",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543211",
                "SecurityId": "BONLON",
                "SecurityName": "BONLON INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE0B9A01018",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543212",
                "SecurityId": "BOROLTD",
                "SecurityName": "BOROSIL LTD",
                "FaceValue": "1",
                "ISINNo": "INE02PY01013",
                "Industry": "Houseware",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543213",
                "SecurityId": "ROSSARI",
                "SecurityName": "ROSSARI BIOTECH LTD",
                "FaceValue": "2",
                "ISINNo": "INE02A801020",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543214",
                "SecurityId": "NDRAUTO",
                "SecurityName": "NDR AUTO COMPONENTS LTD",
                "FaceValue": "10",
                "ISINNo": "INE07OG01012",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543218",
                "SecurityId": "SBGLP",
                "SecurityName": "SURATWWALA BUSINESS GROUP LTD",
                "FaceValue": "10",
                "ISINNo": "INE05ST01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543219",
                "SecurityId": "ICICIALPLV",
                "SecurityName": "&#160;ICICI PRUDENTIAL ALPHA LOW VOL 30 ETF&#160;",
                "FaceValue": "10",
                "ISINNo": "INF109KC1N59",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543220",
                "SecurityId": "MAXHEALTH",
                "SecurityName": "MAX HEALTHCARE INSTITUTE LTD",
                "FaceValue": "10",
                "ISINNo": "INE027H01010",
                "Industry": "Healthcare Facilities",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543221",
                "SecurityId": "ICICITECH",
                "SecurityName": "ICICI PRUDENTIAL IT ETF",
                "FaceValue": "10",
                "ISINNo": "INF109KC1N67",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543223",
                "SecurityId": "MAXINDIA",
                "SecurityName": "MAX INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE0CG601016",
                "Industry": "Holding Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543224",
                "SecurityId": "HBANKETF",
                "SecurityName": "HDFC BANKING ETF",
                "FaceValue": "223.31",
                "ISINNo": "INF179KC1AN8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543226",
                "SecurityId": "UTIBANKETF",
                "SecurityName": "UTI BANK EXCHANGE TRADED FUND (UTI BANK ETF)",
                "FaceValue": "10",
                "ISINNo": "INF789F1AUN8",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543227",
                "SecurityId": "HAPPSTMNDS",
                "SecurityName": "HAPPIEST MINDS TECHNOLOGIES LTD",
                "FaceValue": "2",
                "ISINNo": "INE419U01012",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543228",
                "SecurityId": "ROUTE",
                "SecurityName": "ROUTE MOBILE LTD",
                "FaceValue": "10",
                "ISINNo": "INE450U01017",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543229",
                "SecurityId": "SAMRATFORG",
                "SecurityName": "SAMRAT FORGINGS LTD",
                "FaceValue": "10",
                "ISINNo": "INE412J01010",
                "Industry": "Other Industrial Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543230",
                "SecurityId": "ADVAIT",
                "SecurityName": "ADVAIT INFRATECH LTD",
                "FaceValue": "10",
                "ISINNo": "INE0ALI01010",
                "Industry": "Other Elect.Equip./ Prod.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543231",
                "SecurityId": "ADL",
                "SecurityName": "ARCHIDPLY DECOR LTD",
                "FaceValue": "10",
                "ISINNo": "INE0CHO01012",
                "Industry": "Forest Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543232",
                "SecurityId": "CAMS",
                "SecurityName": "COMPUTER AGE MANAGEMENT SERVICES LTD",
                "FaceValue": "10",
                "ISINNo": "INE596I01012",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543233",
                "SecurityId": "CHEMCON",
                "SecurityName": "CHEMCON SPECIALITY CHEMICALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE03YM01018",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543234",
                "SecurityId": "SECMARK",
                "SecurityName": "SECMARK CONSULTANCY LTD",
                "FaceValue": "10",
                "ISINNo": "INE0BTM01013",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543235",
                "SecurityId": "ANGELBRKG",
                "SecurityName": "ANGEL BROKING LTD",
                "FaceValue": "10",
                "ISINNo": "INE732I01013",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543236",
                "SecurityId": "ATAM",
                "SecurityName": "ATAM VALVES LTD",
                "FaceValue": "10",
                "ISINNo": "INE09KD01013",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543237",
                "SecurityId": "MAZDOCK",
                "SecurityName": "MAZAGON DOCK SHIPBUILDERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE249Z01012",
                "Industry": "Defence",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543238",
                "SecurityId": "UTIAMC",
                "SecurityName": "UTI ASSET MANAGEMENT COMPANY LTD",
                "FaceValue": "10",
                "ISINNo": "INE094J01016",
                "Industry": "Asset Management Cos.",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543239",
                "SecurityId": "GMPL",
                "SecurityName": "G M POLYPLAST LTD",
                "FaceValue": "10",
                "ISINNo": "INE0E2801015",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543240",
                "SecurityId": "LIKHITHA",
                "SecurityName": "LIKHITHA INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE060901019",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543241",
                "SecurityId": "VGIL",
                "SecurityName": "VEER GLOBAL INFRACONSTRUCTION LTD",
                "FaceValue": "10",
                "ISINNo": "INE244W01010",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543242",
                "SecurityId": "HEMIPROP",
                "SecurityName": "HEMISPHERE PROPERTIES INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE0AJG01018",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543243",
                "SecurityId": "EQUITASBNK",
                "SecurityName": "EQUITAS SMALL FINANCE BANK LTD",
                "FaceValue": "10",
                "ISINNo": "INE063P01018",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543244",
                "SecurityId": "SHINEFASH",
                "SecurityName": "SHINE FASHIONS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE0BLY01015",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543245",
                "SecurityId": "GLAND",
                "SecurityName": "GLAND PHARMA LTD",
                "FaceValue": "1",
                "ISINNo": "INE068V01023",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543246",
                "SecurityId": "MAESGETF",
                "SecurityName": "MIRAE ASSET ESG SECTOR LEADERS ETF",
                "FaceValue": "17.5",
                "ISINNo": "INF769K01GS9",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543248",
                "SecurityId": "BURGERKING",
                "SecurityName": "BURGER KING INDIA LTD",
                "FaceValue": "10",
                "ISINNo": "INE07T201019",
                "Industry": "Restaurants",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543249",
                "SecurityId": "TARC",
                "SecurityName": "ANANT RAJ GLOBAL LTD",
                "FaceValue": "2",
                "ISINNo": "INE0EK901012",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543251",
                "SecurityId": "RVHL",
                "SecurityName": "RAVINDER HEIGHTS LTD",
                "FaceValue": "1",
                "ISINNo": "INE09E501017",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543252",
                "SecurityId": "FAIRCHEMOR",
                "SecurityName": "FAIRCHEM ORGANICS LTD",
                "FaceValue": "10",
                "ISINNo": "INE0DNW01011",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543253",
                "SecurityId": "BECTORFOOD",
                "SecurityName": "MRS. BECTORS FOOD SPECIALITIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE495P01012",
                "Industry": "Packaged Foods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543254",
                "SecurityId": "AWHCL",
                "SecurityName": "ANTONY WASTE HANDLING CELL LTD",
                "FaceValue": "5",
                "ISINNo": "INE01BK01022",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "543255",
                "SecurityId": "INFRABEES",
                "SecurityName": "NIPPON INDIA ETF INFRA BEES",
                "FaceValue": "10",
                "ISINNo": "INF732E01268",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "555555",
                "SecurityId": "ICICISENSX",
                "SecurityName": "ICICI PRUDENTIAL SENSEX ETF",
                "FaceValue": "10",
                "ISINNo": "INF346A01034",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "570001",
                "SecurityId": "TATAMTRDVR",
                "SecurityName": "TATA MOTORS  LTD - DVR",
                "FaceValue": "2",
                "ISINNo": "IN9155A01020",
                "Industry": "Commercial Vehicles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "570002",
                "SecurityId": "FELDVR",
                "SecurityName": "FUTURE ENTERPRISES LTD_DVR",
                "FaceValue": "2",
                "ISINNo": "IN9623B01058",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "570003",
                "SecurityId": "GUJNREDVR",
                "SecurityName": "GUJARAT NRE COKE  LTD",
                "FaceValue": "10",
                "ISINNo": "IN9110D01011",
                "Industry": "Coal",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "570004",
                "SecurityId": "JISLDVREQS",
                "SecurityName": "JAIN IRRIGATION SYSTEMS LTD.",
                "FaceValue": "2",
                "ISINNo": "IN9175A01010",
                "Industry": "Plastic Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "570005",
                "SecurityId": "SCAPDVR",
                "SecurityName": "STAMPEDE CAPITAL LTD - DVR",
                "FaceValue": "1",
                "ISINNo": "INE224E01036",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "580001",
                "SecurityId": "STAN",
                "SecurityName": "STANDARD CHARTERED PLC",
                "FaceValue": "0.05",
                "ISINNo": "INE028L21018",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590001",
                "SecurityId": "CHETTICE",
                "SecurityName": "CHETTINAD CEMENT CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE132B01011",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590003",
                "SecurityId": "KARURVYSYA",
                "SecurityName": "KARUR VYSYA BANK LTD.",
                "FaceValue": "2",
                "ISINNo": "INE036D01028",
                "Industry": "Banks",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590004",
                "SecurityId": "LOTTE",
                "SecurityName": "LOTTE INDIA CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE185A01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590005",
                "SecurityId": "TIDEWATER",
                "SecurityName": "TIDE WATER OIL (INDIA) LTD.",
                "FaceValue": "5",
                "ISINNo": "INE484C01022",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590006",
                "SecurityId": "AMRUTANJAN",
                "SecurityName": "AMRUTANJAN HEALTH CARE LTD.-$",
                "FaceValue": "1",
                "ISINNo": "INE098F01031",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590009",
                "SecurityId": "GRABAL",
                "SecurityName": "GRABAL ALOK IMPEX LTD.",
                "FaceValue": "10",
                "ISINNo": "INE012D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590011",
                "SecurityId": "MOVINGPI",
                "SecurityName": "MOVING PICTURE COMPANY (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE691B01016",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590012",
                "SecurityId": "USHAINTL",
                "SecurityName": "USHA INTERNATIONAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE717B01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590013",
                "SecurityId": "XPROINDIA",
                "SecurityName": "XPRO INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE445C01015",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590015",
                "SecurityId": "UTIUS64I10",
                "SecurityName": "UTIUS64I10",
                "FaceValue": "10",
                "ISINNo": "INF189A01103",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590016",
                "SecurityId": "UTIUS64I12",
                "SecurityName": "UTIUS64I12",
                "FaceValue": "10",
                "ISINNo": "INF189A01095",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590018",
                "SecurityId": "HISARMET",
                "SecurityName": "HISAR METAL INDUSTRIES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE598C01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590021",
                "SecurityId": "BHARATRAS",
                "SecurityName": "BHARAT RASAYAN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE838B01013",
                "Industry": "Agrochemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590022",
                "SecurityId": "EASTSILK",
                "SecurityName": "EASTERN SILK INDUSTRIES LTD.",
                "FaceValue": "2",
                "ISINNo": "INE962C01027",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590023",
                "SecurityId": "ELGIRUB",
                "SecurityName": "ELGI RUBBER COMPANY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE257B01024",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590024",
                "SecurityId": "FACT",
                "SecurityName": "FERTILIZERS & CHEMICALS TRAVANCORE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE188A01015",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590025",
                "SecurityId": "GINNIFILA",
                "SecurityName": "GINNI FILAMENTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE424C01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590026",
                "SecurityId": "GODAVARIF",
                "SecurityName": "GODAVARI FERTILISERS & CHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE507C01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590027",
                "SecurityId": "JAYPEE",
                "SecurityName": "JAYPEE HOTELS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE851A01018",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590028",
                "SecurityId": "NICCO",
                "SecurityName": "NICCO CORPORATION LTD.",
                "FaceValue": "2",
                "ISINNo": "INE241C01026",
                "Industry": "Telecom Cables",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590029",
                "SecurityId": "RPGTRAN",
                "SecurityName": "RPG TRANSMISSION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE621A01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590030",
                "SecurityId": "SPIC",
                "SecurityName": "SOUTHERN PETROCHEMICALS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE147A01011",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590031",
                "SecurityId": "DENORA",
                "SecurityName": "DE NORA INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE244A01016",
                "Industry": "Other Industrial Goods",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590033",
                "SecurityId": "SEPSL",
                "SecurityName": "SCHNEIDER ELECTRIC PRESIDENT SYSTEMS LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE155D01018",
                "Industry": "Industrial Machinery",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590035",
                "SecurityId": "TIRFOAM",
                "SecurityName": "TIRUPATI FOAM LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INF115G01015",
                "Industry": "Auto Tyres & Rubber Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590036",
                "SecurityId": "KALPAPER",
                "SecurityName": "KALPTARU PAPERS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE783C01019",
                "Industry": "Paper & Paper Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590037",
                "SecurityId": "STEELXIND1",
                "SecurityName": "STEEL EXCHANGE INDIA LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE503B01013",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590038",
                "SecurityId": "VISUINTL",
                "SecurityName": "VISU INTERNATIONAL LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE965A01016",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590041",
                "SecurityId": "KAVVERITEL",
                "SecurityName": "KAVVERI TELECOM PRODUCTS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE641C01019",
                "Industry": "Telecom Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590043",
                "SecurityId": "HARITASEAT",
                "SecurityName": "HARITA SEATING SYSTEMS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE939D01015",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590045",
                "SecurityId": "TEZPORE",
                "SecurityName": "TEZPORE TEA COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE846B01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590046",
                "SecurityId": "SMRUTHI",
                "SecurityName": "SMRUTHI ORGANICS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590050",
                "SecurityId": "CSSTECH2",
                "SecurityName": "CSS TECHNERGY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE029B01019",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590051",
                "SecurityId": "SAKSOFT",
                "SecurityName": "SAKSOFT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE667G01015",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590053",
                "SecurityId": "KARMOB",
                "SecurityName": "KAR MOBILES LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE916E01011",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590054",
                "SecurityId": "JEYPORE",
                "SecurityName": "JEYPORE SUGAR COMPANY LTD.",
                "FaceValue": "10",
                "ISINNo": "INE180E01014",
                "Industry": "Sugar",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590055",
                "SecurityId": "POLYSPIN1",
                "SecurityName": "POLYSPIN EXPORTS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE914G01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590056",
                "SecurityId": "SALONACOT",
                "SecurityName": "SALONA COTSPIN LTD.",
                "FaceValue": "10",
                "ISINNo": "INE498E01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590057",
                "SecurityId": "PROSEED",
                "SecurityName": "PROSEED INDIA LTD",
                "FaceValue": "1",
                "ISINNo": "INE217G01027",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590061",
                "SecurityId": "BRUSHMAN",
                "SecurityName": "BRUSHMAN (INDIA) LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE357C01012",
                "Industry": "Other Leisure Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590062",
                "SecurityId": "ANDHRSUGAR",
                "SecurityName": "ANDHRA SUGARS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE715B01013",
                "Industry": "Commodity Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590063",
                "SecurityId": "DUNCANSLTD",
                "SecurityName": "DUNCANS INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE508A01022",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590065",
                "SecurityId": "IMPAL",
                "SecurityName": "INDIA MOTOR PARTS & ACCESSORIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE547E01014",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590066",
                "SecurityId": "KCP",
                "SecurityName": "K.C.P.LTD.",
                "FaceValue": "1",
                "ISINNo": "INE805C01028",
                "Industry": "Cement & Cement Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590068",
                "SecurityId": "KHAITANLTD",
                "SecurityName": "KHAITAN (INDIA) LTD.",
                "FaceValue": "10",
                "ISINNo": "INE731C01018",
                "Industry": "Comm.Trading  & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590070",
                "SecurityId": "RADAAN",
                "SecurityName": "RADAAN MEDIAWORKS (I) LTD.",
                "FaceValue": "2",
                "ISINNo": "INE874F01027",
                "Industry": "Movies & Entertainment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590071",
                "SecurityId": "SUNDARMFIN",
                "SecurityName": "SUNDARAM FINANCE LTD.",
                "FaceValue": "10",
                "ISINNo": "INE660A01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590072",
                "SecurityId": "SUNDRMBRAK",
                "SecurityName": "SUNDARAM BRAKE LININGS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE073D01013",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590073",
                "SecurityId": "WHEELS",
                "SecurityName": "WHEELS INDIA LTD.",
                "FaceValue": "10",
                "ISINNo": "INE715A01015",
                "Industry": "Auto Parts & Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590074",
                "SecurityId": "ORTIN",
                "SecurityName": "ORTIN LABORATORIES    LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE749B01012",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590075",
                "SecurityId": "LAMBODHARA",
                "SecurityName": "LAMBODHARA TEXTILES LTD.-$",
                "FaceValue": "5",
                "ISINNo": "INE112F01022",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590076",
                "SecurityId": "CAMSONBIO2",
                "SecurityName": "CAMSON BIO TECHNOLOGIES  LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE845E01012",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590077",
                "SecurityId": "RANKLIN",
                "SecurityName": "RANKLIN SOLUTIONS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE619C01015",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590078",
                "SecurityId": "MAITHANALL",
                "SecurityName": "MAITHAN ALLOYS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE683C01011",
                "Industry": "Iron & Steel/Interm.Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590079",
                "SecurityId": "JOONKTOLL1",
                "SecurityName": "JOONKTOLLEE TEA & INDUSTRIES  LTD",
                "FaceValue": "10",
                "ISINNo": "INE574G01013",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590080",
                "SecurityId": "EASTERNGAS1",
                "SecurityName": "EASTERN GASES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE111111111",
                "Industry": "Oil Marketing & Distribution",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590081",
                "SecurityId": "BRAHMANAN",
                "SecurityName": "BRAHMANAND HIMGHAR LTD.",
                "FaceValue": "5",
                "ISINNo": "INE318G01015",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590082",
                "SecurityId": "LOHIASEC",
                "SecurityName": "LOHIA SECURITIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE803B01017",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590083",
                "SecurityId": "MAVENSBIO",
                "SecurityName": "MAVENS BIOTECH LTD.",
                "FaceValue": "1",
                "ISINNo": "INE856C01047",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590084",
                "SecurityId": "KANCOENT",
                "SecurityName": "KANCO ENTERPRISES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE248D01011",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590085",
                "SecurityId": "GLOSTER2",
                "SecurityName": "GLOSTER LTD.",
                "FaceValue": "10",
                "ISINNo": "INE652C01016",
                "Industry": "Jute & Jute Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590086",
                "SecurityId": "ORISSAMINE",
                "SecurityName": "ORISSA MINERALS DEVELOPMENT COMPANY LTD.",
                "FaceValue": "1",
                "ISINNo": "INE725E01024",
                "Industry": "Mining",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590087",
                "SecurityId": "ELLENBARR",
                "SecurityName": "ELLENBARRIE INDUSTRIAL GASES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE236E01014",
                "Industry": "Industrial Gases",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590088",
                "SecurityId": "ADINATHBI",
                "SecurityName": "ADINATH BIO-LABS LTD.",
                "FaceValue": "1",
                "ISINNo": "INE129D01039",
                "Industry": "Biotechnology",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590089",
                "SecurityId": "CIGNITI2",
                "SecurityName": "CIGNITI TECHNOLOGIES  LTD",
                "FaceValue": "10",
                "ISINNo": "INE675C01017",
                "Industry": "Internet Software & Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590090",
                "SecurityId": "SWAGRO",
                "SecurityName": "SWARNAJYOTHI AGROTECH & POWER LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE846A01026",
                "Industry": "Specialty Chemicals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590091",
                "SecurityId": "TRINETHRA",
                "SecurityName": "TRINETHRA INFRA VENTURES LTD.-$",
                "FaceValue": "2",
                "ISINNo": "INE922D01029",
                "Industry": "Construction & Engineering",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590093",
                "SecurityId": "TRIMURTHI1",
                "SecurityName": "TRIMURTHI DRUGS & PHARMACEUTICALS LTD.-$",
                "FaceValue": "10",
                "ISINNo": "INE314I01036",
                "Industry": "Pharmaceuticals",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590094",
                "SecurityId": "FARMAXIND",
                "SecurityName": "FARMAX INDIA LIMITED-$",
                "FaceValue": "1",
                "ISINNo": "INE890I01035",
                "Industry": "Household Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590102",
                "SecurityId": "UTISUNDER",
                "SecurityName": "UTI MUTUAL FUND - S&P CNX NIFTY UTI NOTIONAL DEPOSITORY RECEIPT (SUNDER)",
                "FaceValue": "100",
                "ISINNo": "INF789F01042",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590103",
                "SecurityId": "NIFTYBEES",
                "SecurityName": "NIPPON INDIA ETF NIFTY BEES",
                "FaceValue": "1",
                "ISINNo": "INF204KB14I2",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590104",
                "SecurityId": "JUNIORBEES",
                "SecurityName": "NIPPON INDIA ETF JUNIOR BEES",
                "FaceValue": "1.25",
                "ISINNo": "INF732E01045",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590106",
                "SecurityId": "BANKBEES",
                "SecurityName": "NIPPON INDIA ETF BANK BEES",
                "FaceValue": "1",
                "ISINNo": "INF204KB15I9",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590107",
                "SecurityId": "KOTAKPSUBK",
                "SecurityName": "KOTAK MAHINDRA MUTUAL FUND - KOTAK PSU BANK ETF",
                "FaceValue": "10",
                "ISINNo": "INF373I01023",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590108",
                "SecurityId": "PSUBNKBEES",
                "SecurityName": "NIPPON INDIA ETF PSU BANK BEES",
                "FaceValue": "1",
                "ISINNo": "INF204KB16I7",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590109",
                "SecurityId": "SHARIABEES",
                "SecurityName": "NIPPON INDIA ETF SHARIAH BEES",
                "FaceValue": "10",
                "ISINNo": "INF732E01128",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590110",
                "SecurityId": "QNIFTY",
                "SecurityName": "QUANTUM MUTUAL FUND - QUANTUM INDEX FUND ETF",
                "FaceValue": "10",
                "ISINNo": "INF082J01028",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590111",
                "SecurityId": "VAISHNAVI",
                "SecurityName": "VAISHNAVI GOLD LIMITED-$",
                "FaceValue": "10",
                "ISINNo": "INE535B01015",
                "Industry": "Other Apparels & Accessories",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590112",
                "SecurityId": "KOTAKNIFTY2",
                "SecurityName": "KOTAK MAHINDRA MUTUAL FUND - PERMITTED",
                "FaceValue": "10",
                "ISINNo": "INF174K01518",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590114",
                "SecurityId": "TAAZAINT2",
                "SecurityName": "TAAZA INTERNATIONAL LIMITED",
                "FaceValue": "10",
                "ISINNo": "INE392H01018",
                "Industry": "Department Stores",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590115",
                "SecurityId": "M50",
                "SecurityName": "MOTILAL OSWAL MOST SHARES M50 ETF",
                "FaceValue": "7",
                "ISINNo": "INF247L01536",
                "Industry": "Other Financial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590116",
                "SecurityId": "7SEAS",
                "SecurityName": "7SEAS TECHNOLOGIES LTD-$",
                "FaceValue": "10",
                "ISINNo": "INF454F01010",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590117",
                "SecurityId": "MAHAVEERINF",
                "SecurityName": "MAHAVEER       INFOWAY LTD-$",
                "FaceValue": "10",
                "ISINNo": "INE019D01016",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590118",
                "SecurityId": "INFRONICS1",
                "SecurityName": "INFRONICS SYSTEMS LTD.",
                "FaceValue": "5",
                "ISINNo": "INE463B01028",
                "Industry": "IT Software Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590120",
                "SecurityId": "PROVEST",
                "SecurityName": "PROVESTMENT SERVICES LTD.",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": "Diversified Commercial Services",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590121",
                "SecurityId": "PILANIINV",
                "SecurityName": "PILANI INVESTMENT AND INDUSTRIES CORPORATION LTD.",
                "FaceValue": "10",
                "ISINNo": "INE417C01014",
                "Industry": "Investment Companies",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590122",
                "SecurityId": "ASHIKACR",
                "SecurityName": "ASHIKA CREDIT CAPITAL LTD.",
                "FaceValue": "10",
                "ISINNo": "INE094B01013",
                "Industry": "Finance (including NBFCs)",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590123",
                "SecurityId": "DENORABBPH",
                "SecurityName": "DENORABBPH",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590124",
                "SecurityId": "RUNEECHA",
                "SecurityName": "RUNEECHA TEXTILES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE373L01010",
                "Industry": "Textiles",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590126",
                "SecurityId": "GRADIENTE",
                "SecurityName": "GRADIENTE INFOTAINMENT LTD.",
                "FaceValue": "10",
                "ISINNo": "INE361K01017",
                "Industry": "Advertising & Media",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590128",
                "SecurityId": "SHREETULSI",
                "SecurityName": "SHREE TULSI ONLINE.COM LTD.",
                "FaceValue": "10",
                "ISINNo": "INE130D01037",
                "Industry": "IT Consulting & Software",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590129",
                "SecurityId": "STARDELTA2",
                "SecurityName": "STAR DELTA TRANSFORMERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE541K01014",
                "Industry": "Heavy Electrical Equipment",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590130",
                "SecurityId": "OKANCOTEA",
                "SecurityName": "KANCO TEA & INDUSTRIES LTD.",
                "FaceValue": "10",
                "ISINNo": "INF398L01017",
                "Industry": "Tea & Coffee",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590131",
                "SecurityId": "SREEL2",
                "SecurityName": "SREELEATHERS LTD.",
                "FaceValue": "10",
                "ISINNo": "INE099F01013",
                "Industry": "Footwear",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590132",
                "SecurityId": "JAYMAHESH",
                "SecurityName": "JAY MAHESH INFRAVENTURES LTD.",
                "FaceValue": "10",
                "ISINNo": "INE984L01014",
                "Industry": "Realty",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590133",
                "SecurityId": "GENERA",
                "SecurityName": "GENERA AGRI CORP LTD.",
                "FaceValue": "10",
                "ISINNo": "INC993L01015",
                "Industry": "Other Agricultural Products",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "590134",
                "SecurityId": "MADRASFERT",
                "SecurityName": "MADRAS FERTILIZERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE414A01015",
                "Industry": "Fertilizers",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700002",
                "SecurityId": "$SAKTI52",
                "SecurityName": "SAKTHI SUG L",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700004",
                "SecurityId": "PRESYNT",
                "SecurityName": "PREMIER SYNTHETICS LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700005",
                "SecurityId": "BLBLEND",
                "SecurityName": "BLUE BLENDS (INDIA) LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700006",
                "SecurityId": "BLBLNDS",
                "SecurityName": "BLUE BLENDS INDIA LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700007",
                "SecurityId": "SAKSUGL",
                "SecurityName": "SAKTH SVGAR",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700009",
                "SecurityId": "STELCORDM",
                "SecurityName": "STELCO GUJ",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700010",
                "SecurityId": "SKUMSYN",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700011",
                "SecurityId": "ICICICRP",
                "SecurityName": "ICICI CRP",
                "FaceValue": "10",
                "ISINNo": "INE005A04015",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700012",
                "SecurityId": "ICICR",
                "SecurityName": "ICICI CRP2",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700013",
                "SecurityId": "SAKSUGR",
                "SecurityName": "SHAKTISUGAR",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700015",
                "SecurityId": "BILINDS",
                "SecurityName": "BIL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700016",
                "SecurityId": "BILINDT",
                "SecurityName": "BIL INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700017",
                "SecurityId": "BLUEBLN",
                "SecurityName": "BLUEBLEND",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700018",
                "SecurityId": "BLEBLNI",
                "SecurityName": "BLUE BLENDS (INDIA) LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700019",
                "SecurityId": "UMSPLST",
                "SecurityName": "UMI SPL ST",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700020",
                "SecurityId": "CTRMFGR",
                "SecurityName": "CTR MNFR IND",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700021",
                "SecurityId": "ICICORPO",
                "SecurityName": "ICICORPORATI",
                "FaceValue": "10",
                "ISINNo": "INE005A04031",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700022",
                "SecurityId": "ICIPFP2",
                "SecurityName": "ICICORPORATE",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700023",
                "SecurityId": "RAJMECH",
                "SecurityName": "RAJENDRA MEC",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700024",
                "SecurityId": "MANSCRP",
                "SecurityName": "MANSAROVER PAPER & INDUSTRIES LTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700025",
                "SecurityId": "ICICICRP4",
                "SecurityName": "ICICI CRP4DM",
                "FaceValue": "10",
                "ISINNo": "INE005A04056",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700027",
                "SecurityId": "AAMTOPR",
                "SecurityName": "AAMCOL TL PR",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700028",
                "SecurityId": "SKSUGAR",
                "SecurityName": "SAKTHI SUGARS LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700029",
                "SecurityId": "ICIP5",
                "SecurityName": "ICICI CRP P5",
                "FaceValue": "10",
                "ISINNo": "INE005A04072",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700030",
                "SecurityId": "ICICPR6",
                "SecurityName": "ICICI CRP P6",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700031",
                "SecurityId": "ICICPR7",
                "SecurityName": "ICICI CRP P7",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700032",
                "SecurityId": "ICICPR8",
                "SecurityName": "ICICI CRP P8",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700033",
                "SecurityId": "ICIP9",
                "SecurityName": "ICICI CRP P9",
                "FaceValue": "10",
                "ISINNo": "INE005A04106",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700034",
                "SecurityId": "ICIPT",
                "SecurityName": "ICIC CRP P10",
                "FaceValue": "10",
                "ISINNo": "INE005A04114",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700035",
                "SecurityId": "ICICRPE",
                "SecurityName": "ICIC CRP P11",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700036",
                "SecurityId": "ICICTWL",
                "SecurityName": "ICIC CRP P12",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700037",
                "SecurityId": "ICICRTH",
                "SecurityName": "ICIC CRP P13",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700038",
                "SecurityId": "ICICRPF",
                "SecurityName": "ICIC CRP P14",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700039",
                "SecurityId": "SHBEARI",
                "SecurityName": "SHRIRAM BEARINGS LTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700040",
                "SecurityId": "SAKTSUG",
                "SecurityName": "SAKTI SUGARS",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700041",
                "SecurityId": "BINYLTD",
                "SecurityName": "BINNYLIMITED",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700042",
                "SecurityId": "MIRCHTECHNO",
                "SecurityName": "MIRCH TECHNL",
                "FaceValue": "100",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700043",
                "SecurityId": "SWRUBER",
                "SecurityName": "SWASTIK RUBBER PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700044",
                "SecurityId": "SWSTRUB",
                "SecurityName": "SWASTIK RUBBER PRODUCTS LTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700045",
                "SecurityId": "TATACNS",
                "SecurityName": "TATACONSTRUS",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700046",
                "SecurityId": "PNYAMCM",
                "SecurityName": "PANYAM CEMNT",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700047",
                "SecurityId": "ORENTAB",
                "SecurityName": "ORIENT ABRES",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700048",
                "SecurityId": "SWSURFC",
                "SecurityName": "SWASTIK SURFACTANTS LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700049",
                "SecurityId": "FABWRT",
                "SecurityName": "UNIWORTH TEXTILES LTD",
                "FaceValue": "30",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700050",
                "SecurityId": "MAHESWR",
                "SecurityName": "MAHESHWARI M",
                "FaceValue": "80",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700051",
                "SecurityId": "MCHFERT",
                "SecurityName": "MANGLORCHFRT",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700052",
                "SecurityId": "MCFRTS",
                "SecurityName": "MANGLORCHFRT",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700053",
                "SecurityId": "CITRIC",
                "SecurityName": "CITRICINDIA",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700054",
                "SecurityId": "JKINVESTODM",
                "SecurityName": "J K INVESTO",
                "FaceValue": "100",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700055",
                "SecurityId": "DERCOOL",
                "SecurityName": "DERCO COLING",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700057",
                "SecurityId": "KIRTINV",
                "SecurityName": "KIRTINVEST",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700058",
                "SecurityId": "NAVBHARDM",
                "SecurityName": "NAV BHARAT",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700059",
                "SecurityId": "MEDICRTDM",
                "SecurityName": "MEDI CORPTEK",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700060",
                "SecurityId": "APARI",
                "SecurityName": "APAR IND",
                "FaceValue": "4",
                "ISINNo": "INE372A04019",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700061",
                "SecurityId": "SKSYNFB",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700062",
                "SecurityId": "SKSNFAB",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700063",
                "SecurityId": "SKUMSNB",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700064",
                "SecurityId": "SKUMARF",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700065",
                "SecurityId": "SKUMSYF",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700066",
                "SecurityId": "SRIMSPG",
                "SecurityName": "SRI MALINI SPINNING MILLS LTD",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700067",
                "SecurityId": "MANTHCO",
                "SecurityName": "MANTRI HOUSI",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700068",
                "SecurityId": "MANTRHS",
                "SecurityName": "MANTRI HOUSI",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700069",
                "SecurityId": "GRESCDM",
                "SecurityName": "GREAT EASCOL",
                "FaceValue": "10",
                "ISINNo": "INE017A04010",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700070",
                "SecurityId": "GRAESDM",
                "SecurityName": "GREAT EASTCO",
                "FaceValue": "10",
                "ISINNo": "INE017A04028",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700071",
                "SecurityId": "GESPCDM",
                "SecurityName": "GREAT ESTCOL",
                "FaceValue": "10",
                "ISINNo": "INE017A04036",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700072",
                "SecurityId": "GRESHDM",
                "SecurityName": "GREAT ESIPCO",
                "FaceValue": "10",
                "ISINNo": "INE017A04044",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700073",
                "SecurityId": "UNIABEX",
                "SecurityName": "UNI ABEX ALO",
                "FaceValue": "100",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700074",
                "SecurityId": "KDLBIOL",
                "SecurityName": "KDL BIOTECHL",
                "FaceValue": "100",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700075",
                "SecurityId": "GRATEAC",
                "SecurityName": "GREAT ESHCOL",
                "FaceValue": "10",
                "ISINNo": "INE017A04051",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700076",
                "SecurityId": "GREATESTC",
                "SecurityName": "GREAT EASTCL",
                "FaceValue": "10",
                "ISINNo": "INE017A04069",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700077",
                "SecurityId": "GLENCRPC",
                "SecurityName": "GLENMRK CRP",
                "FaceValue": "100",
                "ISINNo": "INE935A04013",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700078",
                "SecurityId": "MARICOCRP",
                "SecurityName": "MARICO INDUS",
                "FaceValue": "10",
                "ISINNo": "INE196A04012",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700079",
                "SecurityId": "SUNPHCRP",
                "SecurityName": "SUN PHARMA",
                "FaceValue": "1",
                "ISINNo": "INE044A04014",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700080",
                "SecurityId": "ASHOKLEFCRP",
                "SecurityName": "ASH LEYL FIN",
                "FaceValue": "100",
                "ISINNo": "INE251A04023",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700081",
                "SecurityId": "MAHASEAMCRP",
                "SecurityName": "MAHARSH SEAM",
                "FaceValue": "10",
                "ISINNo": "INE271B03013",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700083",
                "SecurityId": "JAYAGCRP",
                "SecurityName": "JAYAN AG OG",
                "FaceValue": "5",
                "ISINNo": "INE785A04012",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700084",
                "SecurityId": "THRPRE",
                "SecurityName": "THERMAX",
                "FaceValue": "10",
                "ISINNo": "INE152A04015",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700086",
                "SecurityId": "UNITEDPHCRP",
                "SecurityName": "UNITEDPHOCRP",
                "FaceValue": "10",
                "ISINNo": "INE628A04014",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700089",
                "SecurityId": "ARROWPS",
                "SecurityName": "ARROW PS",
                "FaceValue": "10",
                "ISINNo": "INE397A04016",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700090",
                "SecurityId": "ESTERCRPS",
                "SecurityName": "ESTER CRPS",
                "FaceValue": "50",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700091",
                "SecurityId": "TIMEXPS",
                "SecurityName": "TIMEX GROUP INDIA LTD",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700092",
                "SecurityId": "ASAHIPS",
                "SecurityName": "ASAHI CRPD",
                "FaceValue": "10",
                "ISINNo": "INE439A04016",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700093",
                "SecurityId": "ALOKCRPS",
                "SecurityName": "ALOK 10%CRPS",
                "FaceValue": "10",
                "ISINNo": "INE270A04023",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700094",
                "SecurityId": "NICHOLASPS",
                "SecurityName": "NICHOLAS CRP",
                "FaceValue": "100",
                "ISINNo": "INE140A04036",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700095",
                "SecurityId": "NICHOPS",
                "SecurityName": "NICHO CRPS",
                "FaceValue": "10",
                "ISINNo": "INE140A04028",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700096",
                "SecurityId": "FEROALCRP",
                "SecurityName": "FERROALL CRP",
                "FaceValue": "100",
                "ISINNo": "INE912A04012",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700097",
                "SecurityId": "MERCATORCRP",
                "SecurityName": "MERCALIN CRP",
                "FaceValue": "100",
                "ISINNo": "INE934B04014",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700098",
                "SecurityId": "ESSARSTPS",
                "SecurityName": "ESSAR ST PS",
                "FaceValue": "10",
                "ISINNo": "INE127A04017",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700100",
                "SecurityId": "FACORALLPS",
                "SecurityName": "FACOR ALL PS",
                "FaceValue": "100",
                "ISINNo": "INE828G04017",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700101",
                "SecurityId": "FACORSTEPS",
                "SecurityName": "FACOR STE PS",
                "FaceValue": "100",
                "ISINNo": "INE829G04015",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700106",
                "SecurityId": "MARKSANPS",
                "SecurityName": "MARKS PRFSH",
                "FaceValue": "100",
                "ISINNo": "INE750C04012",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700114",
                "SecurityId": "MASLCRPS",
                "SecurityName": "MAHIND CRPS",
                "FaceValue": "31",
                "ISINNo": "INE536H04014",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700118",
                "SecurityId": "THOMASPSA",
                "SecurityName": "THOMS RPSA",
                "FaceValue": "10",
                "ISINNo": "INE332A04013",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "700123",
                "SecurityId": "AMRITBNPS",
                "SecurityName": "AMRITBNPS",
                "FaceValue": "10",
                "ISINNo": "INE221G04015",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710010",
                "SecurityId": "PRUCORPDM",
                "SecurityName": "PRUDENTIALSG",
                "FaceValue": "50",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710012",
                "SecurityId": "$SHENTCH",
                "SecurityName": "SHENTRACON CHEMICALS LTD",
                "FaceValue": "50",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710013",
                "SecurityId": "$PRECICT",
                "SecurityName": "PRECI CONTNR",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710015",
                "SecurityId": "CLFMENT",
                "SecurityName": "CLASS FILAME",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710016",
                "SecurityId": "SREINFN",
                "SecurityName": "SREI INTERNA",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710017",
                "SecurityId": "SRINFIN",
                "SecurityName": "SREI INTERNATIONAL FINANCE",
                "FaceValue": "75",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710020",
                "SecurityId": "SCHLENG",
                "SecurityName": "SCHLAFHORST",
                "FaceValue": "0",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710022",
                "SecurityId": "GULOILI",
                "SecurityName": "GULF OIL IND",
                "FaceValue": "0",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710023",
                "SecurityId": "PRSYNLT",
                "SecurityName": "PARASRAMPURIA SYNTHETICS LTD",
                "FaceValue": "75",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710024",
                "SecurityId": "CRDFINL",
                "SecurityName": "CREDENTIAL FINANCE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710025",
                "SecurityId": "BALJHOT",
                "SecurityName": "BALAJI HOTELS AND ENTERPRISES LTD",
                "FaceValue": "80",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710027",
                "SecurityId": "SKUMSNF",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710028",
                "SecurityId": "SKSYNFA",
                "SecurityName": "S. KUMARS NATIONWIDE LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710031",
                "SecurityId": "NAGCCPSDM",
                "SecurityName": "NAG AGR CCPS",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710034",
                "SecurityId": "SRAMASM",
                "SecurityName": "SH RAM ASMGT",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710035",
                "SecurityId": "MANGALP",
                "SecurityName": "MANGALAMCEME",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710036",
                "SecurityId": "TAFINANDM",
                "SecurityName": "TATAFINANCE",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710037",
                "SecurityId": "MANTHSG",
                "SecurityName": "MANTRI HOUSI",
                "FaceValue": "10",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710038",
                "SecurityId": "KOPDRLT",
                "SecurityName": "KDL BIOTECH LTD",
                "FaceValue": "100",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710039",
                "SecurityId": "ASLEFDM",
                "SecurityName": "ASH LEYL FIN",
                "FaceValue": "50",
                "ISINNo": "INE251A04015",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710040",
                "SecurityId": "MAHASEADM",
                "SecurityName": "MAHARASH SEAM",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710041",
                "SecurityId": "TATFINCDM",
                "SecurityName": "TATA FINANCE",
                "FaceValue": "100",
                "ISINNo": "INE265A04015",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710042",
                "SecurityId": "FLEXOOCPS",
                "SecurityName": "FLEXOFILM PS",
                "FaceValue": "100000",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710043",
                "SecurityId": "UBROCPS",
                "SecurityName": "UNITED BREWE",
                "FaceValue": "100",
                "ISINNo": "INE686F03013",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710044",
                "SecurityId": "PIDRUGOCPS",
                "SecurityName": "PI DRUGS PS",
                "FaceValue": "10",
                "ISINNo": "INE807F03015",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710050",
                "SecurityId": "NETW18PCCPS",
                "SecurityName": "NETW18PCCPS",
                "FaceValue": "200",
                "ISINNo": "IN9870H03017",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710051",
                "SecurityId": "TRENTCCCPSA",
                "SecurityName": "TRENTCCCPSA",
                "FaceValue": "10",
                "ISINNo": "INE849A03018",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "710052",
                "SecurityId": "TRENTCCCPSB",
                "SecurityName": "TRENTCCCPSB",
                "FaceValue": "10",
                "ISINNo": "INE849A03026",
                "Industry": " ",
                "Instrument": "Preference Shares"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750255",
                "SecurityId": "TAINCOR",
                "SecurityName": "TATA INV COR",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750256",
                "SecurityId": "SQLSRIN",
                "SecurityName": "SQL STAR INT",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750257",
                "SecurityId": "JIKINDU",
                "SecurityName": "JIK INDUSTRI",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750280",
                "SecurityId": "HITACHI",
                "SecurityName": "HITACHI HOME",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750281",
                "SecurityId": "SANGIR",
                "SecurityName": "SANGAM INDIA",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750282",
                "SecurityId": "ALEMBR",
                "SecurityName": "ALEMBIC LTD",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750283",
                "SecurityId": "SAINTR",
                "SecurityName": "SAINT-GOBAIN",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750284",
                "SecurityId": "DGOLDR",
                "SecurityName": "DECCAN GOLD",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750285",
                "SecurityId": "TELDATAR",
                "SecurityName": "TEL DATA IN",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750286",
                "SecurityId": "MUKANDR",
                "SecurityName": "MUKAND",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750287",
                "SecurityId": "SPICENETR",
                "SecurityName": "SPICE NET",
                "FaceValue": "5",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750309",
                "SecurityId": "VARUNSHIPLR",
                "SecurityName": "VARUN SHIPNG",
                "FaceValue": "0",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750310",
                "SecurityId": "SCHENHELR",
                "SecurityName": "SCHENECTADY",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750312",
                "SecurityId": "NEEMTEKLR",
                "SecurityName": "NEEMTEK ORGA",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750313",
                "SecurityId": "DEWANHLR",
                "SecurityName": "DEWAN HOU FI",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750314",
                "SecurityId": "KAIRALR",
                "SecurityName": "KAIRA CAN CO",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750315",
                "SecurityId": "KTKBAKLR",
                "SecurityName": "KARNATAKA B",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750316",
                "SecurityId": "SHALPRDLR",
                "SecurityName": "SHALIMARPRD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750317",
                "SecurityId": "INGVYSYALR",
                "SecurityName": "ING VYSYA BK",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750318",
                "SecurityId": "SIRPURLR",
                "SecurityName": "SIRPUR PAP",
                "FaceValue": "0.05",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750319",
                "SecurityId": "SAREGAMALR",
                "SecurityName": "SAREGAMA IND",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750320",
                "SecurityId": "SOUTHIRLR",
                "SecurityName": "SOUTH IRN ST",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750321",
                "SecurityId": "BALPHLR",
                "SecurityName": "BAL PHARMA",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750322",
                "SecurityId": "OUDHLR",
                "SecurityName": "OUDHSUGARLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750323",
                "SecurityId": "BATALR",
                "SecurityName": "BATAINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750324",
                "SecurityId": "TRENTLR",
                "SecurityName": "TRENT LR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750325",
                "SecurityId": "JMCPROJLR",
                "SecurityName": "JMCPROJETCLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750326",
                "SecurityId": "ENNOREFOULR",
                "SecurityName": "ENNOREFOULR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750327",
                "SecurityId": "APPAPERLR",
                "SecurityName": "APPAPERLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750328",
                "SecurityId": "GATILR",
                "SecurityName": "GATILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750329",
                "SecurityId": "NICPIRMALLR",
                "SecurityName": "NICPIRAMALLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750330",
                "SecurityId": "GVFILMSLR",
                "SecurityName": "GVFILMSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750331",
                "SecurityId": "COMFINTELR",
                "SecurityName": "COMFORINTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750332",
                "SecurityId": "TILAKINDLR",
                "SecurityName": "TILAKINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750333",
                "SecurityId": "COLORCHLR",
                "SecurityName": "COLORCHIPSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750334",
                "SecurityId": "SIMBALSLR",
                "SecurityName": "SIMBALSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750335",
                "SecurityId": "SOBTLR",
                "SecurityName": "SOBTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750336",
                "SecurityId": "CENTEXTRLR",
                "SecurityName": "CENTEXTRLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750337",
                "SecurityId": "SHPRECOATLR",
                "SecurityName": "SHPRECOATLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750338",
                "SecurityId": "LAKSHMIPRLR",
                "SecurityName": "LAKSHMIPRLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750339",
                "SecurityId": "APWPRESLR",
                "SecurityName": "APWPRESLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750340",
                "SecurityId": "HINDALCOLR",
                "SecurityName": "HINDALCOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750341",
                "SecurityId": "RAMCOSYSLR",
                "SecurityName": "RAMCOSYSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750342",
                "SecurityId": "DOLPHINMELR",
                "SecurityName": "DOLPHINMEDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750343",
                "SecurityId": "PRIYADSPLR",
                "SecurityName": "PRIYASPINLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750344",
                "SecurityId": "PANTARETLR",
                "SecurityName": "PANTARETLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750345",
                "SecurityId": "CEATLR",
                "SecurityName": "CEATLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750346",
                "SecurityId": "AGRODUTCLR",
                "SecurityName": "AGRODUTCHLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750347",
                "SecurityId": "BGWTATOLR",
                "SecurityName": "BGWTATOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750348",
                "SecurityId": "UNITWESBKLR",
                "SecurityName": "UNITWESBKLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750349",
                "SecurityId": "KLBRENGLR",
                "SecurityName": "KILBURNENGLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750350",
                "SecurityId": "DEEPAKNITLR",
                "SecurityName": "DEEPAKNITLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750351",
                "SecurityId": "NCLINDLR",
                "SecurityName": "NCLINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750352",
                "SecurityId": "TITANLR",
                "SecurityName": "TITANLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750353",
                "SecurityId": "DSKULKARLR",
                "SecurityName": "DSKULKARLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750355",
                "SecurityId": "MOHITINDLR",
                "SecurityName": "MOHITINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750356",
                "SecurityId": "SHBHAWLR",
                "SecurityName": "SHBHAWLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750357",
                "SecurityId": "GOACARBLR",
                "SecurityName": "GOACARBLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750358",
                "SecurityId": "CALIFORLR",
                "SecurityName": "CALIFORLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750359",
                "SecurityId": "KAYCEELR",
                "SecurityName": "KAYCEELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750360",
                "SecurityId": "DIGVIJAYLR",
                "SecurityName": "DIGVIJAYLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750361",
                "SecurityId": "VAMAINDLR",
                "SecurityName": "VAMA INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750362",
                "SecurityId": "BHGEARSLR",
                "SecurityName": "BHGEARSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750363",
                "SecurityId": "HESTERLR",
                "SecurityName": "HESTERLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750364",
                "SecurityId": "UPPERGANLR",
                "SecurityName": "UPPERGANLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750365",
                "SecurityId": "HBLNIFELR",
                "SecurityName": "HBLNIFELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750366",
                "SecurityId": "PONDYOXILR",
                "SecurityName": "PONDYOXILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750367",
                "SecurityId": "BASAGROLR",
                "SecurityName": "BASAGROLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750368",
                "SecurityId": "HINDOILEXLR",
                "SecurityName": "HINDOILEXLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750369",
                "SecurityId": "MORARTEXTLR",
                "SecurityName": "MORARTEXTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750370",
                "SecurityId": "GRUHFINLR",
                "SecurityName": "GRUHFINLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750371",
                "SecurityId": "OCLINDLR",
                "SecurityName": "OCLINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750372",
                "SecurityId": "TTLTDLR",
                "SecurityName": "TTLTDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750373",
                "SecurityId": "ITDCEMENLR",
                "SecurityName": "ITDCEMENLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750374",
                "SecurityId": "GEELTDLR",
                "SecurityName": "GEE LIMITED",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750375",
                "SecurityId": "JMCPROLR",
                "SecurityName": "JMCPROLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750376",
                "SecurityId": "RSSOFTLR",
                "SecurityName": "RSSOFTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750377",
                "SecurityId": "SUNSHIELDLR",
                "SecurityName": "SUNSHIELDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750378",
                "SecurityId": "TATACOFFELR",
                "SecurityName": "TATACOFFELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750379",
                "SecurityId": "TATATELELR",
                "SecurityName": "TATATELELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750380",
                "SecurityId": "BIRLAVXLLR",
                "SecurityName": "BIRLAVXLLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750381",
                "SecurityId": "SPICELR",
                "SecurityName": "SPICELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750382",
                "SecurityId": "BAJAJFINLR",
                "SecurityName": "BAJAJFINLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750383",
                "SecurityId": "BAJFINNCDLR",
                "SecurityName": "BAJFINNCDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750384",
                "SecurityId": "ABNUVOLTDLR",
                "SecurityName": "ABNUVOLTDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750385",
                "SecurityId": "DUTRONPOLR",
                "SecurityName": "DUTRONPOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750386",
                "SecurityId": "KIRLOFERRLR",
                "SecurityName": "KIRLOFERRLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750387",
                "SecurityId": "ENNOREEQLR",
                "SecurityName": "ENNOREEQLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750388",
                "SecurityId": "ENNOREWRTLR",
                "SecurityName": "ENNOREWRTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750389",
                "SecurityId": "BENGALTEALR",
                "SecurityName": "BENGALTEALR",
                "FaceValue": "0.05",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750390",
                "SecurityId": "LGBALALR",
                "SecurityName": "LGBALALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750391",
                "SecurityId": "VIMALOILLR",
                "SecurityName": "VIMALOILLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750392",
                "SecurityId": "UNIPRDCTLR",
                "SecurityName": "UNIPRDCTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750393",
                "SecurityId": "ACGLLR",
                "SecurityName": "ACGLLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750394",
                "SecurityId": "TRENTLTDLR",
                "SecurityName": "TRENTLTDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750395",
                "SecurityId": "INFODRIVELR",
                "SecurityName": "INFODRIVELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750396",
                "SecurityId": "SRINISHIPLR",
                "SecurityName": "SRINISHIPLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750397",
                "SecurityId": "ORIENTPAPLR",
                "SecurityName": "ORIENTPAPLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750398",
                "SecurityId": "SHALIMARLR",
                "SecurityName": "SHALIMARLR",
                "FaceValue": "0.05",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750399",
                "SecurityId": "DAGGERFORLR",
                "SecurityName": "DAGGERFORLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750400",
                "SecurityId": "KANPURPLALR",
                "SecurityName": "KANPURPLALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750401",
                "SecurityId": "GTLINFRALR",
                "SecurityName": "GTLINFRALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750402",
                "SecurityId": "FORTISFSLR",
                "SecurityName": "FORTISFSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750403",
                "SecurityId": "CHOLAMDBSLR",
                "SecurityName": "CHOLAMDBSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750404",
                "SecurityId": "ORBITEXPOLR",
                "SecurityName": "ORBITEXPOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750405",
                "SecurityId": "FEDMOGULLR",
                "SecurityName": "FEDMOGULLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750406",
                "SecurityId": "ITDCEMENTLR",
                "SecurityName": "ITDCEMENTLR",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750407",
                "SecurityId": "DICINDIALR",
                "SecurityName": "DICINDIALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750408",
                "SecurityId": "SAKTHIFINLR",
                "SecurityName": "SAKTHIFINLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750409",
                "SecurityId": "TATASTLEQLR",
                "SecurityName": "TATASTLEQLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750410",
                "SecurityId": "TATACCPSLR",
                "SecurityName": "TATACCPSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750411",
                "SecurityId": "HINDUSOILLR",
                "SecurityName": "HINDUSOILLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750412",
                "SecurityId": "FEDERALBKLR",
                "SecurityName": "FEDERALBKLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750413",
                "SecurityId": "EXIDEINDLR",
                "SecurityName": "EXIDEINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750414",
                "SecurityId": "KHAITANWELR",
                "SecurityName": "KHAITANWELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750415",
                "SecurityId": "NANDANEXILR",
                "SecurityName": "NANDANEXILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750416",
                "SecurityId": "UBENGINELR",
                "SecurityName": "UBENGINELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750417",
                "SecurityId": "STATEBNKLR",
                "SecurityName": "STATEBNKLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750418",
                "SecurityId": "DLAKSHMILR",
                "SecurityName": "DLAKSHMILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750419",
                "SecurityId": "MUKESHSTLR",
                "SecurityName": "MUKESHSTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750420",
                "SecurityId": "INDHOTEQLR",
                "SecurityName": "INDHOTEQLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750421",
                "SecurityId": "INDHOTNCDLR",
                "SecurityName": "INDHOTNCDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750422",
                "SecurityId": "BODALCHELR",
                "SecurityName": "BODALCHELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750423",
                "SecurityId": "NETWORK18LR",
                "SecurityName": "NETWORK18LR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750424",
                "SecurityId": "GODREJCONLR",
                "SecurityName": "GODREJCONLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750425",
                "SecurityId": "VISAGARPOLR",
                "SecurityName": "VISAGARPOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750426",
                "SecurityId": "FILMCITYMLR",
                "SecurityName": "FILMCITYMLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750427",
                "SecurityId": "UNITDBREWLR",
                "SecurityName": "UNITDBREWLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750428",
                "SecurityId": "CENTURYEXLR",
                "SecurityName": "CENTURYEXLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750429",
                "SecurityId": "SANGMEDIALR",
                "SecurityName": "SANGMEDIALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750430",
                "SecurityId": "ENTEGRALR",
                "SecurityName": "ENTEGRALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750431",
                "SecurityId": "OUDHSUGARLR",
                "SecurityName": "OUDHSUGARLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750432",
                "SecurityId": "JKTYRELR",
                "SecurityName": "JKTYRELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750433",
                "SecurityId": "FACTENTLR",
                "SecurityName": "FACTENTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750434",
                "SecurityId": "HINDALINDLR",
                "SecurityName": "HINDALINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750435",
                "SecurityId": "DAMODARLR",
                "SecurityName": "DAMODARLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750436",
                "SecurityId": "TATAMOEQLR",
                "SecurityName": "TATAMOEQLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750437",
                "SecurityId": "TATAMOAEQLR",
                "SecurityName": "TATAMOAEQLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750438",
                "SecurityId": "SADHANALR",
                "SecurityName": "SADHANALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750439",
                "SecurityId": "TATAINVLR",
                "SecurityName": "TATAINVLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750440",
                "SecurityId": "TAYOROLLSLR",
                "SecurityName": "TAYOROLLSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750441",
                "SecurityId": "RESTILELR",
                "SecurityName": "RESTILELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750442",
                "SecurityId": "UGARSUGARLR",
                "SecurityName": "UGARSUGARLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750443",
                "SecurityId": "FEDEMOGULLR",
                "SecurityName": "FEDEMOGULLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750444",
                "SecurityId": "SUMEETINDLR",
                "SecurityName": "SUMEETINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750445",
                "SecurityId": "DISHTVLR",
                "SecurityName": "DISHTVLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750446",
                "SecurityId": "THOMASCKLR",
                "SecurityName": "THOMASCKLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750447",
                "SecurityId": "MULTIPLUSLR",
                "SecurityName": "MULTIPLUSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750448",
                "SecurityId": "MONEYMATTLR",
                "SecurityName": "MONEYMATTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750449",
                "SecurityId": "CHEMPLASTLR",
                "SecurityName": "CHEMPLASTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750450",
                "SecurityId": "GANGOTRILR",
                "SecurityName": "GANGOTRILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750451",
                "SecurityId": "ALOKINDLR",
                "SecurityName": "ALOKINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750452",
                "SecurityId": "HIGHENERGLR",
                "SecurityName": "HIGHENERGLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750453",
                "SecurityId": "SYNCOMFORLR",
                "SecurityName": "SYNCOMFORLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750454",
                "SecurityId": "NOUVEAULR",
                "SecurityName": "NOUVEAULR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750455",
                "SecurityId": "PIRGLASSLR",
                "SecurityName": "PIRGLASSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750456",
                "SecurityId": "JMCLR",
                "SecurityName": "JMCLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750457",
                "SecurityId": "TINPLATEQLR",
                "SecurityName": "TINPLATEQLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750458",
                "SecurityId": "TINPLATEFCD",
                "SecurityName": "TINPLATEFCD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750459",
                "SecurityId": "GREENPLYLR",
                "SecurityName": "GREENPLYLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750460",
                "SecurityId": "PERFECTLR",
                "SecurityName": "PERFECTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750461",
                "SecurityId": "TELE18LR",
                "SecurityName": "TELE18LR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750462",
                "SecurityId": "GREYCELLSLR",
                "SecurityName": "GREYCELLSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750463",
                "SecurityId": "WIRELR",
                "SecurityName": "WIRELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750464",
                "SecurityId": "FORTISLR",
                "SecurityName": "FORTISLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750465",
                "SecurityId": "MORARJEELR",
                "SecurityName": "MORARJEELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750466",
                "SecurityId": "IMPEXLR",
                "SecurityName": "IMPEXLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750467",
                "SecurityId": "CITYUNIONLR",
                "SecurityName": "CITYUNIONLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750468",
                "SecurityId": "INFOMEDIALR",
                "SecurityName": "INFOMEDIALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750469",
                "SecurityId": "RELIGARELR",
                "SecurityName": "RELIGARELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750470",
                "SecurityId": "JAYSHREELR",
                "SecurityName": "JAYSHREELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750471",
                "SecurityId": "SWARAJLR",
                "SecurityName": "SWARAJLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750472",
                "SecurityId": "APPMILLSLR",
                "SecurityName": "APPMILLSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750473",
                "SecurityId": "ARIHANTLR",
                "SecurityName": "ARIHANTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750474",
                "SecurityId": "IBN18LR",
                "SecurityName": "IBN18LR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750475",
                "SecurityId": "INTELLCAPLR",
                "SecurityName": "INTELLCAPLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750476",
                "SecurityId": "TULIVEDEVLR",
                "SecurityName": "TULIVEDEVLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750477",
                "SecurityId": "HINDUJAFOLR",
                "SecurityName": "HINDUJAFOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750478",
                "SecurityId": "VIDEOCONLR",
                "SecurityName": "VIDEOCONLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750479",
                "SecurityId": "ADANIENTLR",
                "SecurityName": "ADANIENTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750480",
                "SecurityId": "PREMIERLR",
                "SecurityName": "PREMIERLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750481",
                "SecurityId": "SUZLONLR",
                "SecurityName": "SUZLONLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750482",
                "SecurityId": "COMFORTLR",
                "SecurityName": "COMFORTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750483",
                "SecurityId": "REIAGROLR",
                "SecurityName": "REIAGROLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750484",
                "SecurityId": "ECEINDLR",
                "SecurityName": "ECEINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750485",
                "SecurityId": "TRENTRLR",
                "SecurityName": "TRENTRLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750486",
                "SecurityId": "GULFOILLR",
                "SecurityName": "GULFOILLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750487",
                "SecurityId": "CAMFINELR",
                "SecurityName": "CAMFINELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750488",
                "SecurityId": "SADBHAVLR",
                "SecurityName": "SADBHAVLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750489",
                "SecurityId": "SBMLR",
                "SecurityName": "SBMLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750490",
                "SecurityId": "ROHITLR",
                "SecurityName": "ROHITLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750491",
                "SecurityId": "RAMKASHLR",
                "SecurityName": "RAMKASHLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750492",
                "SecurityId": "MURUDCELR",
                "SecurityName": "MURUDCELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750493",
                "SecurityId": "JYOTISTLR",
                "SecurityName": "JYOTISTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750494",
                "SecurityId": "KMCSHILLR",
                "SecurityName": "KMCSHILLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750495",
                "SecurityId": "EIHLR",
                "SecurityName": "EIHLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750496",
                "SecurityId": "KARBANKLR",
                "SecurityName": "KARBANKLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750497",
                "SecurityId": "ARROWTEXLR",
                "SecurityName": "ARROWTEXLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750498",
                "SecurityId": "CBILR",
                "SecurityName": "CBILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750499",
                "SecurityId": "TULSYANLR",
                "SecurityName": "TULSYANLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750500",
                "SecurityId": "SBBJLR",
                "SecurityName": "SBBJLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750501",
                "SecurityId": "KRYPTONLR",
                "SecurityName": "KRYPTONLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750503",
                "SecurityId": "ASALLR",
                "SecurityName": "ASALLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750504",
                "SecurityId": "BAMPSLLR",
                "SecurityName": "BAMPSLLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750505",
                "SecurityId": "MUTHTFNLR",
                "SecurityName": "MUTHTFNLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750506",
                "SecurityId": "ELFORGELR",
                "SecurityName": "ELFORGELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750507",
                "SecurityId": "JKPPRLR",
                "SecurityName": "JKPPRLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750508",
                "SecurityId": "ARVINDLR",
                "SecurityName": "ARVINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750509",
                "SecurityId": "VELHOLR",
                "SecurityName": "VELHOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750510",
                "SecurityId": "ATULAUTOLR",
                "SecurityName": "ATULAUTOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750511",
                "SecurityId": "BAJAJHINDLR",
                "SecurityName": "BAJAJHINDLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750512",
                "SecurityId": "VELJANDENLR",
                "SecurityName": "VELJANDENLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750513",
                "SecurityId": "FAMEINDIALR",
                "SecurityName": "FAMEINDIALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750514",
                "SecurityId": "AAREYDRUGLR",
                "SecurityName": "AAREYDRUGLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750515",
                "SecurityId": "ARCPRLR",
                "SecurityName": "ARCPRLR",
                "FaceValue": "0.05",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750516",
                "SecurityId": "GAYATRIPRLR",
                "SecurityName": "GAYATRIPRLR",
                "FaceValue": "0.05",
                "ISINNo": "0",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750517",
                "SecurityId": "LGBFORGELR",
                "SecurityName": "LGBFORGELR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750518",
                "SecurityId": "NEULANDLR",
                "SecurityName": "NEULANDLR",
                "FaceValue": "0.05",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750519",
                "SecurityId": "ARIHANTSLR",
                "SecurityName": "ARIHANTSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750520",
                "SecurityId": "EPCINLR",
                "SecurityName": "EPCINLR",
                "FaceValue": "0.05",
                "ISINNo": "NA          ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750521",
                "SecurityId": "NATIONALOXY",
                "SecurityName": "NATIONAL OXY",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750522",
                "SecurityId": "SUNPARMALR",
                "SecurityName": "SUN PARMALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750523",
                "SecurityId": "NETWORK18R",
                "SecurityName": "NETWORK18LR",
                "FaceValue": "0.05",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750524",
                "SecurityId": "UTTAMSUGRLR",
                "SecurityName": "UTTAMSUGRLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750525",
                "SecurityId": "SUDITILR",
                "SecurityName": "SUDITILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750526",
                "SecurityId": "BAJAJFINSLR",
                "SecurityName": "BAJAJFINSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750527",
                "SecurityId": "TV18BRDCSTR",
                "SecurityName": "TV18BRDCSTR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750528",
                "SecurityId": "EIHASSOLR",
                "SecurityName": "EIHASSOLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750529",
                "SecurityId": "CITYUNIBKLR",
                "SecurityName": "CITYUNIBKLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750530",
                "SecurityId": "BHUSHANSTLR",
                "SecurityName": "BHUSHANSTLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750531",
                "SecurityId": "BAJFINANCER",
                "SecurityName": "BAJAJ FINANCE LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750532",
                "SecurityId": "HITACHILR",
                "SecurityName": "HITACHILR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750533",
                "SecurityId": "MEDIAMATLR",
                "SecurityName": "MEDIAMATLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750534",
                "SecurityId": "ALOKINDSLR",
                "SecurityName": "ALOKINDSLR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750535",
                "SecurityId": "KESORAMLR",
                "SecurityName": "KESORAM INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750536",
                "SecurityId": "DALALSTLR",
                "SecurityName": "DALAL STREET INVESTMENTS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750537",
                "SecurityId": "RELMEDIALR",
                "SecurityName": "RELIANCE MEDIAWORKS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750538",
                "SecurityId": "KOKUYOLR",
                "SecurityName": "KOKUYO CAMLIN LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750539",
                "SecurityId": "ASAHILR",
                "SecurityName": "ASAHI INDIA GLASS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750540",
                "SecurityId": "GODREJPROLR",
                "SecurityName": "GODREJ PROPERTIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750541",
                "SecurityId": "WATERBASELR",
                "SecurityName": "WATERBASE LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750542",
                "SecurityId": "UNIPHOSLR",
                "SecurityName": "UNIPHOS ENTERPRISES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750543",
                "SecurityId": "COROENGLR",
                "SecurityName": "COROMANDEL ENGINEERING COMPANY LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750544",
                "SecurityId": "MUKANDLR",
                "SecurityName": "MUKAND LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750545",
                "SecurityId": "WHEELSLR",
                "SecurityName": "WHEELS INDIA LTD",
                "FaceValue": "10",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750546",
                "SecurityId": "TATAPOWERLR",
                "SecurityName": "TATA POWER CO LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750547",
                "SecurityId": "GOLDENGOLR",
                "SecurityName": "GOLDEN GOENKA FINCORP LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750548",
                "SecurityId": "EASUNREYRLR",
                "SecurityName": "EASUN REYROLLE LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750549",
                "SecurityId": "ECOFRIENDLR",
                "SecurityName": "ECO FRIENDLY FOOD PROCESSING PARK LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750550",
                "SecurityId": "ILFSTNLLR",
                "SecurityName": "IL&FS TRANSPORTATION NETWORKS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750551",
                "SecurityId": "RAMCOSYLR",
                "SecurityName": "RAMCO SYSTEMS  LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750552",
                "SecurityId": "DENISCHEMLR",
                "SecurityName": "DENIS CHEM LAB LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750553",
                "SecurityId": "INDHOTELLR",
                "SecurityName": "INDIAN HOTELS CO LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750554",
                "SecurityId": "LAKVILBKLR",
                "SecurityName": "LAKSHMI VILAS BANK LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750555",
                "SecurityId": "MIRCELELR",
                "SecurityName": "MIRC ELECTRONICS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750556",
                "SecurityId": "NEULABLR",
                "SecurityName": "NEULAND LABORATORIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750557",
                "SecurityId": "NCCLTDLR",
                "SecurityName": "NCC LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750558",
                "SecurityId": "ESTBIOLR",
                "SecurityName": "ESTEEM BIO ORGANIC FOOD PROCESSING LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750559",
                "SecurityId": "CHANNINELR",
                "SecurityName": "CHANNEL NINE ENTERTAINMENT LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750560",
                "SecurityId": "FUTURERELR",
                "SecurityName": "FUTURE ENTERPRISES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750561",
                "SecurityId": "FUTUREDVRLR",
                "SecurityName": "FUTURE ENTERPRISES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750562",
                "SecurityId": "CANFINHOMLR",
                "SecurityName": "CAN FIN HOMES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750563",
                "SecurityId": "STBKOFTRALR",
                "SecurityName": "STATE BANK OF TRAVANCORE",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750564",
                "SecurityId": "GMRINFRASLR",
                "SecurityName": "GMR INFRASTRUCTURE LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750565",
                "SecurityId": "ZEEMEDIALR",
                "SecurityName": "ZEE MEDIA CORPORATION LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750566",
                "SecurityId": "TATAMOTORLR",
                "SecurityName": "TATA MOTORS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750567",
                "SecurityId": "TATAMODVRLR",
                "SecurityName": "TATA MOTORS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750568",
                "SecurityId": "MCKTRCOLDLR",
                "SecurityName": "MACK TRADING CO LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750569",
                "SecurityId": "VASCONEQLR",
                "SecurityName": "VASCON ENGINEERS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750570",
                "SecurityId": "UNICABLR",
                "SecurityName": "UNIVERSAL CABLES LTD.",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750572",
                "SecurityId": "DECNGOLDLR",
                "SecurityName": "DECCAN GOLD MINES LTD.",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750573",
                "SecurityId": "ILFSTRNLLR",
                "SecurityName": "IL&FS TRANSPORTATION NETWORKS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750574",
                "SecurityId": "ASHIMALR",
                "SecurityName": "ASHIMA LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750575",
                "SecurityId": "JMCPROJEQLR",
                "SecurityName": "JMC PROJECTS (INDIA) LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750576",
                "SecurityId": "BNRATHILR",
                "SecurityName": "BNRATHI SECURITIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750577",
                "SecurityId": "SPARCLR",
                "SecurityName": "SUN PHARMA ADVANCED RESEARCH COMPANY LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750578",
                "SecurityId": "THEINVESTR",
                "SecurityName": "FORTUNE FINANCIAL SERVICES (INDIA)",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750579",
                "SecurityId": "POLSONLR",
                "SecurityName": "POLSON LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750580",
                "SecurityId": "SINTEXLR",
                "SecurityName": "SINTEX INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750581",
                "SecurityId": "CRESTLR",
                "SecurityName": "CREST VENTURES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750582",
                "SecurityId": "KARNATAKALR",
                "SecurityName": "KARNATAKA BANK LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750583",
                "SecurityId": "CNOVAPETRLR",
                "SecurityName": "CIL NOVA PETROCHEMICALS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750584",
                "SecurityId": "ORIENTPPRLR",
                "SecurityName": "ORIENT PAPER & INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750585",
                "SecurityId": "IPRINGLTDLR",
                "SecurityName": "IP RINGS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750586",
                "SecurityId": "CANBKLR",
                "SecurityName": "CANARA BANK",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750587",
                "SecurityId": "SOUTHBANKLR",
                "SecurityName": "SOUTH INDIAN BANK LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750588",
                "SecurityId": "ARCHITORGLR",
                "SecurityName": "ARCHIT ORGANOSYS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750589",
                "SecurityId": "VISHALLR",
                "SecurityName": "VISHAL FABRICS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750590",
                "SecurityId": "KINGFALR",
                "SecurityName": "KINGFA SCIENCE & TECHNOLOGY (INDIA) LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750591",
                "SecurityId": "MAHLIFELR",
                "SecurityName": "MAHINDRA LIFESPACE DEVELOPERS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750592",
                "SecurityId": "VSSLLR",
                "SecurityName": "VARDHMAN SPECIAL STEELS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750593",
                "SecurityId": "ZENOTECHLR",
                "SecurityName": "ZENOTECH LABORATORIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750594",
                "SecurityId": "INTELLECTLR",
                "SecurityName": "INTELLECT DESIGN ARENA LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750595",
                "SecurityId": "DENISLR",
                "SecurityName": "DENIS CHEM LAB LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750596",
                "SecurityId": "BRPLLR",
                "SecurityName": "BANSAL ROOFING PRODUCTS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750597",
                "SecurityId": "INDHOTELR",
                "SecurityName": "INDIAN HOTELS CO LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750598",
                "SecurityId": "KARURVYSYLR",
                "SecurityName": "KARUR VYSYA BANK LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750599",
                "SecurityId": "HIRECTLR",
                "SecurityName": "HIND RECTIFIERS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750600",
                "SecurityId": "HCL-INSYSLR",
                "SecurityName": "HCL INFOSYSTEMS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750601",
                "SecurityId": "JOSTSLR",
                "SecurityName": "JOSTS ENGINEERING CO LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750602",
                "SecurityId": "VIPCLOTHLR",
                "SecurityName": "VIP CLOTHING LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750603",
                "SecurityId": "LAKSHVILALR",
                "SecurityName": "LAKSHVILALR",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750604",
                "SecurityId": "SUMEETINDLR",
                "SecurityName": "SUMEET INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750605",
                "SecurityId": "KANPRPLALR",
                "SecurityName": "KANPUR PLASTIPACK LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750606",
                "SecurityId": "PELLR",
                "SecurityName": "PIRAMAL ENTERPRISES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750607",
                "SecurityId": "TATASTFPLR",
                "SecurityName": "TATA STEEL LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750608",
                "SecurityId": "TATASTPPLR",
                "SecurityName": "TATA STEEL LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750609",
                "SecurityId": "IBVENTURELR",
                "SecurityName": "INDIABULLS VENTURES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750610",
                "SecurityId": "SCANPGEOMLR",
                "SecurityName": "SCANPOINT GEOMATICS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750611",
                "SecurityId": "ELPROINTLLR",
                "SecurityName": "ELPRO INTERNATIONAL LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750612",
                "SecurityId": "SHALPAINTLR",
                "SecurityName": "SHALIMAR PAINTS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750613",
                "SecurityId": "HATSUNLR",
                "SecurityName": "HATSUN AGRO PRODUCTS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750614",
                "SecurityId": "MAXVILLR",
                "SecurityName": "MAX VENTURES AND INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": "            ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750615",
                "SecurityId": "SHALIMALR",
                "SecurityName": "SHALIMAR PAINTS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750616",
                "SecurityId": "HCCLR",
                "SecurityName": "HINDUSTAN CONSTRUCTION CO LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750617",
                "SecurityId": "CIFLR",
                "SecurityName": "CAPITAL INDIA FINANCE LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750618",
                "SecurityId": "LGBFORGLR",
                "SecurityName": "LGB FORGE LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750619",
                "SecurityId": "KREBSBIOLR",
                "SecurityName": "KREBS BIOCHEMICALS & INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750620",
                "SecurityId": "IDEALR",
                "SecurityName": "VODAFONE IDEA LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750621",
                "SecurityId": "BHARATGRLR",
                "SecurityName": "BHARAT GEARS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750622",
                "SecurityId": "AIRTELLR",
                "SecurityName": "BHARTI AIRTEL  LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750623",
                "SecurityId": "SCANPOINTLR",
                "SecurityName": "SCANPOINT GEOMATICS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750624",
                "SecurityId": "TATASTLLPR",
                "SecurityName": "TATA SPONGE IRON LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750625",
                "SecurityId": "RELCHEMQLR",
                "SecurityName": "RELIANCE CHEMOTEX INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750626",
                "SecurityId": "ISHANCHLR",
                "SecurityName": "ISHAN DYES & CHEMICALS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750627",
                "SecurityId": "5PAISALR",
                "SecurityName": "5PAISA CAPITAL LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750628",
                "SecurityId": "PACIFICILR",
                "SecurityName": "PACIFIC INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750629",
                "SecurityId": "PATELENGLR",
                "SecurityName": "PATEL ENGINEERING LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750630",
                "SecurityId": "SKCILLR",
                "SecurityName": "SHRI KESHAV CEMENTS AND INFRA LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750631",
                "SecurityId": "PELTDLR",
                "SecurityName": "PIRAMAL ENTERPRISES LIMITED",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750632",
                "SecurityId": "BAJAJELECLR",
                "SecurityName": "BAJAJ ELECTRICALS LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750633",
                "SecurityId": "BROOKSLABLR",
                "SecurityName": "BROOKS LABORATORIES LTD",
                "FaceValue": "0.05",
                "ISINNo": " ",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750634",
                "SecurityId": "RELIANCELR",
                "SecurityName": "RELIANCE INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE002A20018",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750635",
                "SecurityId": "ARVINDF-RE",
                "SecurityName": "ARVIND FASHIONS LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE955V20013",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750636",
                "SecurityId": "ABFRL-RE",
                "SecurityName": "ADITYA BIRLA FASHION AND RETAIL LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE647O20011",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750637",
                "SecurityId": "SRTRANS-RE",
                "SecurityName": "SHRIRAM TRANSPORT FINANCE COMPANY LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE721A20013",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750638",
                "SecurityId": "PVR-RE",
                "SecurityName": "PVR LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE191H20014",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750639",
                "SecurityId": "REFEX-RE",
                "SecurityName": "REFEX INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE056I20017",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750640",
                "SecurityId": "M&MFIN-RE",
                "SecurityName": "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE774D20016",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750641",
                "SecurityId": "GDL-RE",
                "SecurityName": "GATEWAY DISTRIPARKS LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE852F20015",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750642",
                "SecurityId": "SPENCER-RE",
                "SecurityName": "SPENCER&#39;S RETAIL LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE020820010",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750643",
                "SecurityId": "SATIN-RE",
                "SecurityName": "SATIN CREDITCARE NETWORK LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE836B20017",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750644",
                "SecurityId": "MINDA-RE",
                "SecurityName": "MINDA INDUSTRIES LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE405E20015",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750645",
                "SecurityId": "RUSHIL-RE",
                "SecurityName": "RUSHIL DECOR LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE573K20017",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750646",
                "SecurityId": "DEEPAK-RE",
                "SecurityName": "DEEPAK FERTILIZERS &PETROCHEMICALS",
                "FaceValue": "0.05",
                "ISINNo": "INE501A20019",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750647",
                "SecurityId": "EIH-RE",
                "SecurityName": "EIH LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE230A20015",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750648",
                "SecurityId": "MOLDTEK-RE",
                "SecurityName": "MOLD-TEK PACKAGING LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE893J20011",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750649",
                "SecurityId": "SHOPER-RE",
                "SecurityName": "SHOPPERS STOP LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE498B20016",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750650",
                "SecurityId": "PRICOL-RE",
                "SecurityName": "PRICOL LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE726V20018",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "750651",
                "SecurityId": "URJA-RE",
                "SecurityName": "URJA GLOBAL LTD",
                "FaceValue": "0.05",
                "ISINNo": "INE550C20012",
                "Industry": " ",
                "Instrument": " "
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780001",
                "SecurityId": "JDL",
                "SecurityName": "JAISUKH DEALERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE190P01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780004",
                "SecurityId": "AUTUMN",
                "SecurityName": "AUTUMN BUILDERS LTD",
                "FaceValue": "10",
                "ISINNo": "INE733P01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780005",
                "SecurityId": "LEAP",
                "SecurityName": "LEARNING EDGE ACADEMY OF PROFESSIONALS LTD",
                "FaceValue": "10",
                "ISINNo": "INE301Q01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780006",
                "SecurityId": "DEKSON",
                "SecurityName": "DEKSON CASTINGS LTD",
                "FaceValue": "1000",
                "ISINNo": "INE465Q01013",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780007",
                "SecurityId": "PCPL",
                "SecurityName": "PREMIER CHENNAI PROPERTIES LTD",
                "FaceValue": "10",
                "ISINNo": "INE245Q01019",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780008",
                "SecurityId": "SUPERNOVA",
                "SecurityName": "SUPERNOVA ADVERTISING LTD",
                "FaceValue": "10",
                "ISINNo": "INE018Q01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780009",
                "SecurityId": "MCL",
                "SecurityName": "MADHUBAN CONSTRUCTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE145Q01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780010",
                "SecurityId": "KDTWL",
                "SecurityName": "K D TREND WEAR LTD",
                "FaceValue": "10",
                "ISINNo": "INE098Q01012",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780011",
                "SecurityId": "RICHWAY",
                "SecurityName": "RICHWAY INTERNATIONAL TRADE LTD",
                "FaceValue": "10",
                "ISINNo": "INE591Q01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780012",
                "SecurityId": "GOKULSOL",
                "SecurityName": "GOKUL SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE909Q01010",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780013",
                "SecurityId": "CITYON",
                "SecurityName": "CITYON SYSTEMS (INDIA) LTD",
                "FaceValue": "10",
                "ISINNo": "INE324P01014",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780014",
                "SecurityId": "HASJUICE",
                "SecurityName": "HAS LIFESTYLE LTD",
                "FaceValue": "10",
                "ISINNo": "INE888Q01016",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780015",
                "SecurityId": "SANASATECH",
                "SecurityName": "SANASA TECH FEB LTD",
                "FaceValue": "10",
                "ISINNo": "INE760Q01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780016",
                "SecurityId": "WEBSL",
                "SecurityName": "WEB ELEMENT SOLUTIONS LTD",
                "FaceValue": "10",
                "ISINNo": "INE562Q01017",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780018",
                "SecurityId": "ADHIRAJ",
                "SecurityName": "ADHIRAJ DISTRIBUTORS LTD",
                "FaceValue": "10",
                "ISINNo": "INE067R01015",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780019",
                "SecurityId": "JIGYASA",
                "SecurityName": "JIGYASA INFRASTRUCTURE LTD",
                "FaceValue": "10",
                "ISINNo": "INE107Q01011",
                "Industry": " ",
                "Instrument": "Equity"
            },
            {
                "Exchange": "BSE",
                "SecurityCode": "780020",
                "SecurityId": "PRITIKAST",
                "SecurityName": "PRITIKA AUTOCAST LTD",
                "FaceValue": "10",
                "ISINNo": "INE070R01019",
                "Industry": " ",
                "Instrument": "Equity"
            }
        
            
                  ];
