import { Component, OnInit, HostListener, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject } from 'rxjs';
import { Stocks } from '../Stocks'
import { Stock } from '../Stock';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationserviceService } from '../authenticationservice.service';
import { SharedserviceService } from '../sharedservice.service'

import { Router } from '@angular/router';

@Component({
  selector: 'app-viewprofile',
  templateUrl: './viewprofile.component.html',
  styleUrls: ['./viewprofile.component.css']
})
export class ViewprofileComponent implements OnInit, AfterViewInit {
  @ViewChild('box2') container2: ElementRef;
  posts: Observable<any[]>;
  downloadURL;
  emailId;
  FirstName;

  constructor(public firestore: AngularFirestore
    , public sharedserviceService: SharedserviceService
    , private authservice: AuthenticationserviceService,
    private router: Router) {

    // this.posts = firestore.collection<Stock>('/Posts', ref => ref.orderBy('createddate','desc')).valueChanges();

  }
  //contentRouterLink='some text with routerLink <a routerLink="/Gotourl/ABB" >ABB</a> some more text with routerLink <a routerLink="/Gotourl/INFY" >INFY</a>';

  contentRouterLink = 'some text with routerLink <a routerLink="/Gotourl/coffee" >coffee</a> some more text with routerLink <a routerLink="/Gotourl/tea" >tea</a>';
  ngAfterViewInit() {

    this.container2.nativeElement.innerHTML = this.contentRouterLink; // safe: 'html'


  }
  calculateDiff(dateStr) {

    return this.sharedserviceService.timeSincePost(dateStr);
  }
  ngOnInit(): void {

    this.downloadURL = this.authservice.getimageURl();
    this.emailId = this.authservice.authuser.email;
    this.FirstName = this.authservice.authuser.displayName;
    //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.orderBy('createddate','desc')).valueChanges();
    //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).orderBy('createddate','desc')).valueChanges();
    //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).where("symbols", "array-contains", "HDFC").orderBy('createddate','desc')).valueChanges();

    if(this.authservice.authuser.email=="dhan.tracker@gmail.com")
      this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).limit(5).orderBy('createddate', 'desc')).valueChanges({ idField: 'documentid' });

    else
    
    this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).where("isdeleted", "==", false).orderBy('createddate', 'desc')).valueChanges({ idField: 'documentid' });


  }
  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
    this.sharedserviceService.hostlistnerNavigateToSymbol(event);
  }
}
