
<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <span>{{ StockDeatils[0]?.companyName }}</span>
      <span class="text-muted">
        Updated {{ StockDeatils[0]?.Date | date: "dd/MM/yyyy"}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h1 class="d-inline">
        {{ StockDeatils[0]?.companyName }} {{ StockDeatils[0]?.price }}
      </h1>
      <span class="main-title-text"
        ><i class="bi bi-arrow-up font-weight-bold"></i
        >{{ StockDeatils[0]?.mainvalue | number: "2.1-2" }}
        <span
          >( {{ StockDeatils[0]?.maindiffValue | number: "2.1-2" }} % )</span
        ></span
      >
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h4 class="d-inline">Post-Market</h4>
      <span class="main-sub-title-text">
        <i class="bi bi-arrow-down"></i>
        {{ StockDeatils[0]?.pvalue | number: "2.1-2" }}
        <span>({{ StockDeatils[0]?.pdiffValue | number: "2.1-2" }} %)</span>
      </span>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-md-2 col-sm-2 col-xs-2 pr-0">
      <h5>
        <span class="text-success font-weight-bold price-card"
          ><i class="bi bi-arrow-up"></i
          >{{ StockDeatils[0]?.maindiffValue | number: "2.1-2" }} %</span
        >
        <span class="pb-1 price-text-set">Price</span>
      </h5>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-2 pr-0 pl-0">
      <h5>
        <span class="text-success font-weight-bold price-card"
          ><i class="bi bi-arrow-up"></i
          >{{ StockDeatils[0]?.sentimnet | number: "2.1-2" }} %</span
        >
        <span class="pb-1">Sentiment</span>
      </h5>
    </div>
    <div class="col-md-3 col-sm-3 col-xs-3 pr-0 pl-0">
      <h5>
        <span class="text-danger font-weight-bold price-card"
          ><i class="bi bi-arrow-down"></i>
          {{ StockDeatils[0]?.mVolumn | number: "2.1-2" }} %</span
        >
        <span class="pb-1">Message Volume</span>
      </h5>
    </div>
    <div class="col-md-5 col-sm-5 col-xs-5 pl-0 user-set-box">
      <div class="user-box">
        <span class="text-user-count">143,009</span
        ><i class="bi bi-people"></i>
        
      </div>
      <app-watchorunwatch [stickerName]="this.ToAddinWatchliststring"></app-watchorunwatch>
     
      <!-- <button type="button" class="btn btn-outline-primary" routerLink="/editprofile"><i class="bi bi-eye"></i> Watch</button> -->

    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <span class="text-close-date"
        >Closed: {{ todayDate | date: "long" }} Disclaimer</span
      >
      <span class="text-after-hours"
        >After hours {{ StockDeatils[0]?.price
        }}<span class="text-point"
          >{{ StockDeatils[0]?.pvalue | number: "2.1-2" }}
          <span>({{ StockDeatils[0]?.pdiffValue | number: "2.1-2" }} %)</span>
        </span></span
      >
    </div>
  </div>
  <div class="row ml-5 pt-2">
    
    <div *ngFor="let item of ValidRange; let i = index">
      <div class="box-indector">
        <span
          class="range-box"
          (click)="setIndectorChart(item, i)"
          [ngClass]="selectedChartTypeIndex === i ? 'active' : ''"
        >
          <span class="text-gray">{{ item }}</span>
        </span>
      </div>
    </div>
    
    <div ngbDropdown placement="bottom-right" class="d-inline-block"
    >
    <button class="btn" ngbDropdownToggle><i class="bi bi-bar-chart"></i></button>
   
    <div ngbDropdownMenu>

  
    <span class="dropdown-item" (click)="getAreaChart(StockDeatils[0].name)"><i class="bi bi-graph-up"></i></span>
    <span class="dropdown-item" (click)="getCandlestickChart(StockDeatils[0].name)" > <i class="bi bi-bar-chart"></i></span>
    </div>
  </div>
  </div>

    

  
 
  <div class="row">
    <div class="col-md-12 pt-4 container" id="container"></div>
  </div>
  <div class="row pt-4 mb-5">
    <div class="col-md-12 border-set">
      <div class="tabel-set">
        <table class="main-table-body">
          <tbody>
            <tr>
              <td class="td-text td-main">Open</td>
              <td class="td-text text-muted">{{ StockDeatils[0]?.price }}</td>
              <td class="td-text td-main">Mkt cap</td>
              <td class="td-text text-muted">766.10</td>
              <td class="td-text td-main">Prev close</td>
              <td class="td-text text-muted">796.22</td>
            </tr>
            <tr>
              <td class="td-text td-main">High</td>
              <td class="td-text text-muted">799.84</td>
              <td class="td-text td-main">P/E ratio</td>
              <td class="td-text text-muted">1,252.75</td>
              <td class="td-text td-main">52-wk high</td>
              <td class="td-text text-muted">900.40</td>
            </tr>
            <tr>
              <td class="td-text td-main">Low</td>
              <td class="td-text text-muted">762.64</td>
              <td class="td-text td-main">Div yied</td>
              <td class="td-text text-muted">-</td>
              <td class="td-text td-main">52-wk low</td>
              <td class="td-text text-muted">70.10</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  