<app-postcontrol [code]=""></app-postcontrol>

<br>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
  <li ngbNavItem="Recent">
    <a class="btn btn-outline-primary" ngbNavLink (click)="reloadPosts('recent')">Recent</a>
    <ng-template ngbNavContent>
      <p></p>
    </ng-template>
  </li>
  <li ngbNavItem="Watchlist">
    <a class="btn btn-outline-primary" ngbNavLink (click)="reloadPosts('watchlist')">Watchlist</a>
    <ng-template ngbNavContent>
      <p></p>
    </ng-template>
  </li>


</ul>

<div [ngbNavOutlet]="nav" class="ml-4"></div>


<app-fintwitscontrol [posts]="posts"></app-fintwitscontrol>