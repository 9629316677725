


<div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="play-box text-dark font-weight-bold">Trending Now
        <i [ngClass]="playButton == true ? 'bi bi-play-fill' : 'bi bi-pause-fill' " (click)="togglePlay(playButton)">
        </i>
      </div>
      <marquee width="100%" direction="left" id="stock" onmouseover="this.stop();" onmouseout="this.start();">
        <img src="assets\image\fire-icon.png" class="icon-set">
        <span class="text-muted pl-2" *ngFor="let s of stockName;">
          <span class="stock-name-text" (click)="goChart(s.symbol)">{{ s.symbol }}</span>
          <span [ngClass]="s.price > 0  ? 'stock-price-text-up' : 'stock-price-text-down'">
            <span [ngClass]="s.price > 0  ? 'stock-price-text-up' : 'stock-price-text-down'"><i
                [ngClass]="s.price > 0  ? 'bi bi-arrow-up' : 'bi bi-arrow-down'"></i></span>
            {{ s.price | number: '2.1-2'}} %
          </span>
        </span>
      </marquee>
    </div>
  </div>



    
       
           
       
       