<h1>Edit Profile</h1>

<table>
  <tr>
    <td class="align-top">

      <div class="container" *ngIf="downloadURL | async; let url">
        <img [src]="url" width="120" height="120" class="img-fluid z-depth-1 rounded-circle" alt="../assets/avatar.png"
          onerror="this.src='../assets/avatar.png';">
      </div>
    </td>
    <td class="align-top">
      <table>
        <tr>
          <td><b>{{emailId}}</b></td>
        </tr>
        <tr>
          <td>
            {{displayName}}
          </td>
        </tr>
        <tr>
          <td>

            <button type="button" class="btn btn-outline-primary" routerLink="/uploadphoto">upload Photo</button>

          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
<div class="row justify-content-between">
  <div class="col-sm-4">
    What assets do you trade most frequently?
  </div>
  <div class="col-sm-8">
    <div class="form-check">

      <label>
        <input type="checkbox" [(ngModel)]="equities" /> Equities</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="options" /> Options</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="forex" /> Forex</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="futures" /> Futures</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="bonds" /> Bonds</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="privateCompanies" /> Private Companies</label>

    </div>
  </div>
</div>
<br>

<div class="row justify-content-between">
  <div class="col-sm-4">
    What is your approach to trading?
  </div>
  <div class="col-sm-8">
    <div class="form-check">
      <!-- <label class="form-check-label"> -->
      <label>
        <input type="checkbox" [(ngModel)]="technical" /> Technical</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="fundamental" /> Fundamental</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="globalmacro" /> Global Macro</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="momentum" /> Momentum</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="growth" /> Growth</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="value" /> Value</label>

      <!-- </label> -->
    </div>
  </div>
</div>


<div class="row justify-content-between">
  <div class="col-sm-4">
    What is your primary holding period?
  </div>
  <div class="col-sm-8">
    <div class="form-check">
      <label>
        <input type="checkbox" [(ngModel)]="daytrader" /> Day Trader </label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="swingtrader" /> Swing Trader </label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="positiontrader" /> Position Trader </label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="longterminvestor" /> Long Term Investor </label>




    </div>
  </div>
</div>


<div class="row justify-content-between">
  <div class="col-sm-4">
    What is your experience as a trader?
  </div>
  <div class="col-sm-8">
    <div class="form-check">
      <label>
        <input type="checkbox" [(ngModel)]="novice" /> Novice</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="intermediate" /> Intermediate</label>
      <br>
      <label>
        <input type="checkbox" [(ngModel)]="professional" /> Professional</label>
      <br>

    </div>
  </div>
</div>

<button type="button" class="btn btn-outline-primary" (click)="SaveProfile()">Save Profile</button>

<!-- <label class="container"> 

<input type="checkbox" [(ngModel)]="theCheckbox" />tes
    Checkbox is <span *ngIf="marked">checked</span><span *ngIf="!marked">unchecked</span>

  <span class="checkmark"></span>
</label>
  
  <div>And <b>ngModel</b> also works, it's value is <b>{{theCheckbox}}</b></div> -->