import { Component, OnInit, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Stock } from '../Stock';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationserviceService } from '../authenticationservice.service'
import { SharedserviceService } from '../sharedservice.service'
import { BSE_Equity } from '../BSE_Equity'
import { NSE_Equity } from '../NSE_Equity'


@Component({
  selector: 'app-symbol',
  templateUrl: './symbol.component.html',
  styleUrls: ['./symbol.component.css']
})
export class SymbolComponent implements OnInit {

  initialCount: number = 10;
  item;
  code;
  posts: Observable<any[]>;
  downloadURL;
  ToAddinWatchliststring: string = "";
    
    IsSecurityNameFound: boolean = true;
  constructor(private route: ActivatedRoute
    , public auth: AngularFireAuth
    , public firestore: AngularFirestore
    , public sharedserviceService: SharedserviceService
    , private authservice: AuthenticationserviceService) { }


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.code = params.get("code");

      this.item=this.sharedserviceService.getCompanyNamefromCompanyCode(this.code);
if(this.item?.SecurityName == undefined)
{
  this.IsSecurityNameFound=false;
  return ;
  
}
this.IsSecurityNameFound=true;
      this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("symbols", "array-contains", this.code).where("isdeleted", "==", false).orderBy('createddate', 'desc')).valueChanges();
      //  this.firestore.collection<Stock>('/Posts', ref => ref.where("symbols", "array-contains", this.code).orderBy('createddate','desc')).valueChanges().subscribe((posts: any) => {
      //   this.posts = posts;
      // });

      if (this.auth.user) {
        //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).where("symbols", "array-contains", this.code).orderBy('createddate','desc')).valueChanges();


        //this.ToAddinWatchliststring = this.code + " (" + this.item.SecurityName + ")";
        this.ToAddinWatchliststring =  this.sharedserviceService.getYFCompanyCodeFromSecurityId(this.code) ;

      }

    })
    // this.code = this.route.snapshot.paramMap.get('code');
    //onsole.log("symbol id "+this.code);

    //this.downloadURL = this.authservice.getimageURl();
    //this.code="ABB";

    // this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).where("symbols", "array-contains", "HDFC").orderBy('createddate','desc')).valueChanges();
    //latest before ngoninit
    //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("symbols", "array-contains", this.code).orderBy('createddate','desc')).valueChanges();


    // this.getHero();
  }

  WatchOrUnwatch() {

  }
  calculateDiff(dateStr) {
    return this.sharedserviceService.timeSincePost(dateStr);
  }
  getHero(): void {

  }

  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
    this.sharedserviceService.hostlistnerNavigateToSymbol(event);
  }
}
