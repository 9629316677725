<ng-container *ngIf="(posts| async)?.length > 0; else showNoDataFound">
  <div *ngFor="let post of posts | async">

    <div class="container">
      <div class="row postCSS" style="padding-bottom: 10px; padding-top: 10px; ">
        <div class="fixed">
          <!-- <div class="container" *ngIf="downloadURLAsync;let url">
            <img [src]="url" width="50" height="50"   class="img-fluid z-depth-1 rounded-circle"
            alt="../assets/avatar.png"  onerror="this.src='../assets/avatar.png';">
          </div> -->

          <img [src]="post?.dpurl" width="50" height="50" class="img-fluid z-depth-1 rounded-circle"
            alt="../assets/avatar.png" onerror="this.src='../assets/avatar.png';">


        </div>

        <div class="col">
          <div class="row">
            <div class="col-7"><b>{{post?.displayName}}</b></div>
            <div class="col-4">
              <!-- <div [ngStyle]="post.bullbearnutral ==='Bullish' && {'font-weight': 'bold', 'font-style': 'italic','background-color': 'rgb(165, 207, 207)','color': 'blue'}">{{post.bullbearnutral}}</div>
                </div> -->
              <!-- <div 
    [ngStyle]="{'color': (post.bullbearnutral ==='Bullish' && {'font-weight': 'bold', 'font-style': 'italic','background-color': 'rgb(165, 207, 207)'}) ? 'green' : 
                          (post.bullbearnutral ==='Bearish') ? 'red' :
                            (post.bullbearnutral ==='Nutral') ? 'blue' :
                             ''}">{{post.bullbearnutral}}
  </div> -->
              <!-- <div  [ngClass]="{
    'BullishCSS' : post.bullbearnutral === 'Bullish',
    'BearishCSS' : post.bullbearnutral === 'Bearish',
    'NeutralCSS' : post.bullbearnutral === 'Nutral'
  }" >{{post.bullbearnutral}}</div> -->

              <div *ngIf="post?.bullbearnutral === 'Bullish'">
                <label class="bbtn btn-outline-success">

                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-graph-up" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M0 0h1v15h15v1H0V0zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5z" />
                  </svg>




                </label>
              </div>
              <div *ngIf="post?.bullbearnutral === 'Bearish'">
                <label class="bbtn btn-outline-danger">

                  <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-graph-down" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M0 0h1v15h15v1H0V0zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5z" />
                  </svg>


                </label>
              </div>
              <div *ngIf="post?.bullbearnutral == 'Nutral'">
                <label class="bbtn btn-outline-primary">

                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-megaphone" viewBox="0 0 16 16">
                    <path
                      d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975l1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
                  </svg>


                </label>
              </div>

            </div>
            <div class="col-1">{{calculateDiff(post.createddate.toDate())}}</div>
          </div>
          <div class="row" style="padding-bottom: 10px;">
            <div class="col-12">
              <!-- <div class="blueAnchors" [innerHTML]="post.post | linkify"></div> -->
              <div class="blueAnchors" [innerHTML]="post.post | linkify | utf8EmojisToImages:'twitter':12"></div>

            </div>
          </div>

          <div class="row">

            <div class="col">
              <div class="row">
                <div *ngIf="post?.likedby?.includes(this.authservice?.authuser?.email); else elseBlock">
                  <div class="btn-group btn-group-toggle">
                    <label ngbButtonLabel>
                      <input type="checkbox" ngbButton [(ngModel)]="trueValue" (click)="onUnLike(post)">
                      <!-- <fa-icon [icon]="faThumbsUp"></fa-icon> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi btn-outline-primary bi-hand-thumbs-up" viewBox="0 0 16 16">
                        <path
                          d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.964.22.817.533 2.512.062 4.51a9.84 9.84 0 0 1 .443-.05c.713-.065 1.669-.072 2.516.21.518.173.994.68 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.162 3.162 0 0 1-.488.9c.054.153.076.313.076.465 0 .306-.089.626-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.826 4.826 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.616.849-.231 1.574-.786 2.132-1.41.56-.626.914-1.279 1.039-1.638.199-.575.356-1.54.428-2.59z" />
                      </svg>
                      <label *ngIf="post?.likedcount>=1" >{{post.likedcount}}</label>
                    </label>
                  </div>
                  <!-- FutureEnhancement -->
                  <!-- <button *ngIf="post?.likedcount>=1" class="btn border-radius: 50%;"
                    (click)="openLikedBy(popupLikedBy)">{{post.likedcount}}</button> -->
                    

                </div>
                <ng-template #elseBlock>
                  <div class="btn-group btn-group-toggle">
                    <label ngbButtonLabel>
                      <input type="checkbox" ngbButton [(ngModel)]="falseValue" (click)="onLike(post)">
                      <!-- <fa-icon [icon]="faThumbsUp"></fa-icon> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi btn-outline-primary bi-hand-thumbs-up" viewBox="0 0 16 16">
                        <path
                          d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                      </svg>
                      <label *ngIf="post?.likedcount>=1">{{post.likedcount}}</label>
                    </label>
                  </div>
                  <!-- FutureEnhancement -->
                  <!-- <button *ngIf="post?.likedcount>=1" class="btn border-radius: 50%;"
                    (click)="openLikedBy(popupLikedBy)">{{post.likedcount}}</button> -->
                    
                </ng-template>
              </div>



            </div>
            <div class="col">

              <label class="btn border-radius: 50%;" (click)="open()">
                <!-- open(content) -->
                <!-- <fa-icon [icon]="faReply"></fa-icon>   -->
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi btn-outline-success bi-reply" viewBox="0 0 16 16">
                  <path d="M5.921 11.9L1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z"/>
                </svg> -->
               
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi btn-outline-primary bi-chat-dots" viewBox="0 0 16 16">
                  <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                  <path d="M2.165 15.803l.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                </svg>
              </label>

            </div>


            <div class="col">
              <div class="row">


                <div *ngIf="post.retweetby.includes(this.authservice?.authuser?.email); else retweetelseBlock">
                  <div class="btn-group btn-group-toggle">
                    <label ngbButtonLabel>
                      <input type="checkbox" ngbButton [(ngModel)]="trueValue" (click)="onUndoRetweet(post)">
                      <fa-icon class="btn-outline-primary" [icon]="faRetweet"></fa-icon>
                      <label *ngIf="post.retweetcount>=1" >{{post.retweetcount}}</label>
                    </label>
                  </div>
                  <!-- FutureEnhancement -->
                  <!-- <button *ngIf="post.retweetcount>=1" class="btn border-radius: 50%;"
                    (click)="openLikedBy(popupRetweetBy)">{{post.retweetcount}}</button> -->
                    
                </div>
                <ng-template #retweetelseBlock>
                  <div class="btn-group btn-group-toggle">
                    <label ngbButtonLabel>
                      <input type="checkbox" ngbButton [(ngModel)]="falseValue" (click)="onRetweet(post)">
                      <fa-icon class="btn-outline-primary" [icon]="faRetweet"></fa-icon>
                      <label *ngIf="post.retweetcount>=1" >{{post.retweetcount}}</label>
                    </label>
                  </div>
                   <!-- FutureEnhancement -->
                  <!-- <button *ngIf="post.retweetcount>=1" class="btn border-radius: 50%;"
                    (click)="openLikedBy(popupRetweetBy)">{{post.retweetcount}}</button> -->
                    
                </ng-template>

              </div>

            </div>


            <div ngbDropdown placement="bottom-right" class="col">

              <label class="btn border-radius: 50%;" ngbDropdownToggle>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi btn-outline-primary bi-upload" viewBox="0 0 16 16">
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path
                    d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
              </label>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="ShareTwitter(post)">Share to Twitter</button>
                <button ngbDropdownItem (click)="copyLink(post)">Copy Link to Post</button>
                <!-- <button ngbDropdownItem (click)="Mute(post)">Mute This user</button>
                  <button ngbDropdownItem routerLink="/posts">Report</button>
                  <button ngbDropdownItem routerLink="/posts">Blocked</button> -->
                <button ngbDropdownItem (click)="Delete(post)">Delete</button>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

<!-- FutureEnhancement -->
    <!-- <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Comment</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table>
          <tr>
            <td class="align-top">

              <div class="container" *ngIf="downloadURL | async; let url">
                <img [src]="url" width="50" height="50" class="img-fluid z-depth-1 rounded-circle"
                  alt="../assets/avatar.png" onerror="this.src='../assets/avatar.png';">
              </div>

            </td>
            <td class="align-top">
              <table>
                <tr>
                  <td><b>{{post?.displayName}}</b></td>
                  <td class="text-right">{{calculateDiff(post.createddate.toDate())}}</td>
                </tr>
                <tr>
                  <td>
                   
                  
                    <div class="blueAnchors" [innerHTML]="post.post"></div>

                  </td>
                </tr>
                <tr>
                  <td><b>Comment</b></td>
                  <td class="text-right"><input type="text"></td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

       

      </div>
      <div class="modal-footer">
        Comment <input width="100px">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Submit</button>
      </div>
    </ng-template> -->

      <!-- FutureEnhancement -->
    <!-- <ng-template #popupLikedBy let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Liked by</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li class="text" *ngFor="let likedby of post.likedby">
            <a>{{likedby}}</a>
          </li>
        </ul>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
      </div>
    </ng-template> -->
<!-- FutureEnhancement -->
    <!-- <ng-template #popupRetweetBy let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Retweet by</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul>
          <li class="text" *ngFor="let retweetby of post.retweetby">
            <a>{{retweetby}}</a>
          </li>
        </ul>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
      </div>
    </ng-template> -->
  </div>
</ng-container>

<ng-template #showNoDataFound>
  <h1>No Data Found. Please be the first to share about this company.</h1>
</ng-template>

<!-- above message can be shown dyncamically different messages   "Spread the word about this stock so that more people can see this about your view" 
-->