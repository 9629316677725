<table>
  <tr>
    <td class="align-top">

      <div class="container" *ngIf="downloadURL | async; let url">
        <img [src]="url" width="120" height="120" class="img-fluid z-depth-1 rounded-circle" alt="../assets/avatar.png"
          onerror="this.src='../assets/avatar.png';">


      </div>





    </td>
    <td class="align-top">
      <table>
        <tr>
          <td><b>{{emailId}}</b></td>

        </tr>
        <tr>
          <td>
            {{FirstName}}
          </td>
        </tr>

        <tr>
          <td>
            <button type="button" class="btn btn-outline-primary" routerLink="/editprofile">Edit Profile</button>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
<br>
<br>

<!-- <app-centralchart></app-centralchart> -->

<app-postcontrol [code]=""></app-postcontrol>

<app-fintwitscontrol [posts]="posts"></app-fintwitscontrol>