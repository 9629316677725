import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthenticationserviceService } from '../authenticationservice.service';
import { AngularFireStorage } from "@angular/fire/storage";
import { faUserEdit, faUserCog, faLightbulb, faEye, faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
//import {AuthenticationserviceService} from '../Services/authenticationservice.service'
import * as firebase from 'firebase/app';
import { AbstractExtendedWebDriver } from 'protractor/built/browser';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../../shared/must-match.validator';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  faUserEdit = faUserEdit;
  faUserCog = faUserCog;
  faLightbulb = faLightbulb;
  faEye = faEye;
  faSignOutAlt = faSignOutAlt;
  faSignInAlt = faSignInAlt;
  SearchType: string = "default";
  //UserDetails: Observable<any[]>;


  constructor(public authabc: AngularFireAuth, private router: Router
    , private toastr: ToastrService
    ,private formBuilder: FormBuilder
    , public authservice: AuthenticationserviceService
    , private storage: AngularFireStorage
    , public firestore: AngularFirestore) {


  }

  // constructor(public auth: AngularFireAuth,private authservice:AuthenticationserviceService) {
  //  //this.user=this.authservice.user;
  //  this.authservice.login();
  //  //this.auth=this.authservice.getuser();
  // }
  //   login()
  //   {
  //     this.authservice.login();
  //   }
  //     logout() { 
  //     this.authservice.logout();

  //   }
  loginWithTwitter()
  {
    //console.log("twitter");
    this.hide();
    this.authservice.loginWithTwitter();


  }
  loginWithGmail1() {
    this.hide();
    this.authservice.loginWithGmail();


  }
  logout() {
    // this.auth.signOut();
    // this.router.navigate(['/']);
    
    this.authabc.signOut().then(() => {
      this.authservice.isLoggedIn = false;
      this.router.navigate(['']);
    });
  }


  showModal: boolean;
  registerForm: FormGroup;
  signupForm: FormGroup;
  submitted = false;
  submitted2 = false;
  ErrorCode;
 isForgotPasswordClicked:boolean=false;


  show()
  {
    this.resetAllfields();

    this.showModal = true; // Show-Hide Modal Check
    
  }
  resetAllfields()
  {
    this.signupForm.reset();
this.registerForm.reset();
this.submitted=false;
this.submitted2=false;
  }
  // resetAllfields1()
  // {
   
    
  //   this.signupForm.reset();
  //   this.signupForm.controls['UserName'].setErrors(null);
   
  //   this.signupForm.controls['name'].setErrors(null);
  //   this.signupForm.controls['email'].setErrors(null);
  //   this.signupForm.controls['password2'].setErrors(null);
  //   this.signupForm.controls['confirmPassword'].setErrors(null);
  

  //   this.signupForm.controls['UserName'].updateValueAndValidity();

   
  //   this.signupForm.controls['name'].updateValueAndValidity();
  //   this.signupForm.controls['email'].updateValueAndValidity();
  //   this.signupForm.controls['password2'].updateValueAndValidity();
  //   this.signupForm.controls['confirmPassword'].updateValueAndValidity();


  //   this.registerForm.reset();
  //   this.registerForm.controls['email'].setErrors(null);
  //   this.registerForm.controls['password'].setErrors(null);
  //   //this.registerForm.reset();
  //   this.registerForm.controls['email'].updateValueAndValidity({onlySelf: true, emitEvent: false});
  //   this.registerForm.controls['password'].updateValueAndValidity({onlySelf: true, emitEvent: false});

  //   this.registerForm.controls['password'].markAsPristine

  
  // }
  //Bootstrap Modal Close event
 
  // hide1(fieldsForm): void {
  //   // this.myForm.reset();
  //   fieldsForm.reset({ email: "", password: "" });
  //   //
  // }
  hide()
  {
    // this.registerForm.reset();
    // this.signupForm.reset();
    //setTimeout(() => this.formGroupDirective.resetForm(), 200);
    //this.formGroupDirective.resetForm();
    

    
    //this.formRef.reset();
    this.showModal = false;
   
    this.resetAllfields();
    //this.signupForm.reset();
  }
//  async test(): Promise<string> 
//  {
//   const ttt=await this.authabc.user;
// console.log("ttt" + ttt);
//   return ttt[0].displayName;
//  }

//  getuserdetails(email)
//  {
//    console.log("getuserdetails" + email);
//  return this.firestore.collection('/users', ref => ref.limit(1)).valueChanges();
//  }
  ngOnInit() {
    //console.log("test ");

    //this.UserDetails = this.firestore.collection('/users', ref => ref.limit(1)).valueChanges();
   //console.log("emailid "+  this.authabc.currentUser);
  //console.log("emailid ddd"+  this.test());
   
    //console.log("emailid2 "+this.authservice.authuser.emailid);
    //console.log("user "+ this.authservice.authuser.user);

    //console.log("test " + firebase.auth());

    // if(firebase.auth()?.currentUser && firebase.auth().currentUser?.email)
    // {
    //   console.log("test " + firebase.auth()?.currentUser);
    // }
    // console.log("test " + firebase.auth()?.currentUser);
    // console.log("test " + firebase.auth()?.currentUser?.email);


    // console.log("test " + this.auth.currentUser);
    // console.log("test12 " + this.authservice.authuser?.displayName);

    // if (this.auth.user) {
    //   if(this.authservice.authuser?.displayName)
    //   {
    //     console.log("test " + this.authservice.authuser?.email);

    // console.log("test " + this.authservice.authuser?.displayName);
    //     this.UserDetails = this.firestore.collection('/users', ref => ref.where("emailid", "==", this.authservice.authuser.email)).valueChanges();
    //       //this.posts = this.firestore.collection<Stock>('/Posts', ref => ref.where("emailid", "==", this.authservice.authuser.email).where("symbols", "array-contains", this.code).orderBy('createddate','desc')).valueChanges();
  
    //   }
    // }
    
   
    this.registerForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email,Validators.maxLength(30)]],
        password: ['', [Validators.required, Validators.minLength(6),Validators.maxLength(30)]]
        //,rememberMe: false,
    });

    this.signupForm = this.formBuilder.group({
      
      UserName: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(30)]],
      name: ['', [Validators.required, Validators.minLength(3),Validators.maxLength(30)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(30)]],
      password2: ['', [Validators.required, Validators.minLength(6),Validators.maxLength(30)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password2', 'confirmPassword')
  });
}
// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }
get f2() { return this.signupForm.controls; }
async onSubmit(){
  //console.log("Test onSubmit");

  this.submitted = true;
 
  if(this.isForgotPasswordClicked && this.registerForm.controls['email'].valid)
  {
    //console.log("Fogot onSubmit invalid" );
    this.isForgotPasswordClicked =false;
    //call ForgotPassword method
    this.ForgotPassword();
    return;
  }

  if (this.registerForm.invalid) {
    return;
    }
  //call login function
  if(this.submitted)
  {
    this.isForgotPasswordClicked =false;
    //console.log("callLogin");
    await this.callLogin(); 
  }
  

  //console.log("All done");

}

async callLogin()
{

  const { email, password} = this.registerForm.value;
  //const { email, password, rememberMe } = this.registerForm.value;
// console.log(
//   `Username: ${email}\n` +
//   `Password: ${password}\n` +
//   `Remember me: ${rememberMe}`
// );

    
   // firebase.auth().setPersistence(this.remember.checked ? fireauth.Auth.Persistence.LOCAL : fireauth.Auth.Persistence.SESSION)
 // this.ErrorCode= await this.authservice.loginWithEmailAndPassword(this.registerForm.controls['email'].value,this.registerForm.controls['password'].value)
 this.ErrorCode= await this.authservice.loginWithEmailAndPassword(email,password)
   
 .then(res => {
    return res;
  })
  if (this.registerForm.valid) {
    
    //this.registerForm.reset();
  }
   if(this.ErrorCode!=undefined)
   {
    //this.registerForm.get('email').setErrors({ serverError: { message: 'Invalid Email or password)' } });
     //alert('Invalid Email or password');
     //this.toastr.error('Invalid Email or password!', '');
     this.toastr.error(this.ErrorCode, '', {
      timeOut: 4000,
    });
   }
   else
   {
  //this.UserDetails = this.firestore.collection('/users', ref => ref.limit(1)).valueChanges();

    this.showModal = false;
     this.registerForm.reset();

  }
  
}
async onSubmit1() {
  
  //console.log("Test onSubmit");
  
  
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    if(this.submitted)
    { 
   
    }
}
async onSignup() {
  console.log("Test signup");
  this.submitted2 = true;
  // stop here if form is invalid
  if (this.signupForm.invalid) {
      return;
  }
  if(this.submitted2)
  {
      this.ErrorCode= await this.authservice.SignUpWithEmailAndPassword(
        this.signupForm.controls['email'].value, this.signupForm.controls['password2'].value,this.signupForm.controls['name'].value,this.signupForm.controls['UserName'].value)
     .then(res => {  
      return res;
    })
    if (this.signupForm.valid) {
      //this.signupForm.reset();
    }
      if(this.ErrorCode!=undefined)
     {
       //alert('Please use different Email to Sign Up');
     //this.toastr.error('Please use different Email to Sign Up!', '');
     this.toastr.error(this.ErrorCode, '', {
      timeOut: 4000,
    });
     }
     else
     {
      //this.ModifiedDisplayName=this.signupForm.controls['name'].value;
       this.showModal = false;
       this.signupForm.reset();
     }
  }

}

ngAfterViewInit() {
  //console.log("emailid ngAfterViewInit"+   this.authservice.authuser);
  //console.log("emailid ngAfterViewInit emial"+   this.authservice.emailid);

  const signUpButton = document.getElementById('LsignUp');
const signInButton = document.getElementById('LsignIn');
const container = document.getElementById('container234');

signUpButton.addEventListener('click', () => {
//   this.signupForm.reset();
// this.registerForm.reset();
// this.signupForm.clearValidators();
// this.registerForm.clearValidators();
// this.signupForm.updateValueAndValidity();
// this.registerForm.updateValueAndValidity();

container.classList.add("right-panel-active");

this.resetAllfields();

});

signInButton.addEventListener('click', () => {
//   this.signupForm.reset();
// this.registerForm.reset();

// this.signupForm.clearValidators();
// this.registerForm.clearValidators();
// this.signupForm.updateValueAndValidity();
// this.registerForm.updateValueAndValidity();

container.classList.remove("right-panel-active");
this.resetAllfields();
});


// this.signupForm.controls.UserName.setErrors(null);
// this.signupForm.controls.password2.setErrors(null);

}
ForgotPassword1()
{
  this.isForgotPasswordClicked=true;

}
ForgotPassword()
{
  
  console.log("forgot");
  // this.registerForm.controls['email'].markAsTouched();
  // return ;
//console.log("forgot");
const email = this.registerForm.controls['email'].value;

this.authabc.sendPasswordResetEmail(email).then(
  () => {
    alert('we sent you a link to reset your password');
    
    // success, show some message
  },
  err => {
    // handle errors
    alert('Please check the email');

  }
);
}
}
