<p>uploadphoto works!</p>

<div id="upload_button" class="col-lg-8 col-xs-8">
    <label class="hand-cursor">
        <input type="file" id="file" (change)="fileChangeEvent($event)" accept=".png,.jpg" />
        <!-- <span class="fa fa-camera"></span>
     <span class="photo_text hidden-xs">Upload Photo</span> -->
        <span class="btn btn-outline-primary">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload"
                viewBox="0 0 16 16">
                <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                <path
                    d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
            </svg> Photo</span>
    </label>
</div>
<!-- <input type="file" (change)="fileChangeEvent($event)" /> -->

<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
    [resizeToWidth]="250" [resizeToHeight]="250" format="png" (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>

<img [src]="croppedImage" />