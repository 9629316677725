<div id="search-component" class="searchTop">


  <input #searchBox id="search-box" [(ngModel)]="changecharacter" (focus)="onfocus()" (focusout)="onfocusout()"
    placeholder="Search for symbols or companies" (blur)="onLocationBlur()" (keydown.ArrowUp)="onArrowUp()"
    (keydown.ArrowDown)="onArrowDown()" (keydown.enter)="onEnter($event)" (input)="this.changeInSearch($event)" />
  <label class="companysearch">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
      viewBox="0 0 16 16">
      <path
        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
    </svg>
  </label>
  <ul id="testid" class="search-result">
    <label *ngIf="(items)?.length > 0">Trending Stocks</label>
    <li #item [ngClass]="{'focus-background': focusElement == item.tabIndex}"
      *ngFor="let company of items | slice:0:10; let i = index" tabIndex="{{i}}" (mouseover)="onmouseoverChange(i)"
      (mousedown)="navigateOraddToWatchList(company.SecurityId,company.SecurityName)">
      <!-- <a href="symbol">&#8377;{{company.SecurityId}}({{company.SecurityId}})</a> -->
      <!-- <a (click)="select(element)">{{element}}</a>        -->
      <div class="row">
        <div class="col-10">

          <a routerLink='/symbol/{{company.SecurityId}}'>&#8377;{{company.SecurityId}}</a>

        </div>
        <div class="col-2">

          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-arrow-return-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z" />
          </svg>
        </div>

      </div>

      <div class="row">
        <div class="col" style="font-size: 10px;">
          {{company.SecurityName}}
        </div>
      </div>

    </li>
  </ul>
</div>