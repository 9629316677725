import { BSE_Equity_Interface } from './BSE_Equity_Interface';
//convert excel sheet to json
//http://beautifytools.com/excel-to-json-converter.php
//https://www.bseindia.com/corporates/List_Scrips.aspx
export const BSE_Equity_Top10byMCAP: BSE_Equity_Interface[] = [


    {
        "Exchange": "BSE",
        "SecurityCode": "500325",
        "SecurityId": "RELIANCE",
        "SecurityName": "RELIANCE INDUSTRIES LTD.",
        "FaceValue": "10",
        "ISINNo": "INE002A01018",
        "Industry": "Integrated Oil & Gas",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "532540",
        "SecurityId": "TCS",
        "SecurityName": "TATA CONSULTANCY SERVICES LTD.",
        "FaceValue": "1",
        "ISINNo": "INE467B01029",
        "Industry": "IT Consulting & Software",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "500180",
        "SecurityId": "HDFCBANK",
        "SecurityName": "HDFC BANK LTD",
        "FaceValue": "1",
        "ISINNo": "INE040A01034",
        "Industry": "Banks",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "500696",
        "SecurityId": "HINDUNILVR",
        "SecurityName": "HINDUSTAN UNILEVER LTD.",
        "FaceValue": "1",
        "ISINNo": "INE030A01027",
        "Industry": "Personal Products",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "500209",
        "SecurityId": "INFY",
        "SecurityName": "INFOSYS LTD.",
        "FaceValue": "5",
        "ISINNo": "INE009A01021",
        "Industry": "IT Consulting & Software",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "500010",
        "SecurityId": "HDFC",
        "SecurityName": "HOUSING DEVELOPMENT FINANCE CORP.LTD.",
        "FaceValue": "2",
        "ISINNo": "INE001A01036",
        "Industry": "Housing Finance ",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "500247",
        "SecurityId": "KOTAKBANK",
        "SecurityName": "KOTAK MAHINDRA BANK LTD.",
        "FaceValue": "5",
        "ISINNo": "INE237A01028",
        "Industry": "Banks",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "532174",
        "SecurityId": "ICICIBANK",
        "SecurityName": "ICICI BANK LTD.",
        "FaceValue": "2",
        "ISINNo": "INE090A01021",
        "Industry": "Banks",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "500034",
        "SecurityId": "BAJFINANCE",
        "SecurityName": "BAJAJ FINANCE LIMITED",
        "FaceValue": "2",
        "ISINNo": "INE296A01024",
        "Industry": "Finance (including NBFCs)",
        "Instrument": "Equity"
    },
    {
        "Exchange": "BSE",
        "SecurityCode": "500875",
        "SecurityId": "ITC",
        "SecurityName": "ITC LTD.",
        "FaceValue": "1",
        "ISINNo": "INE154A01025",
        "Industry": "Cigarettes-Tobacco Products",
        "Instrument": "Equity"
    }




];
