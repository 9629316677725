import { Component, OnInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { SharedserviceService } from '../sharedservice.service';
import { BSE_Equity } from '../BSE_Equity'
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationserviceService } from '../authenticationservice.service';
import { ToastrService } from 'ngx-toastr';
import { NSE_Equity } from '../NSE_Equity';
import { BSE_Equity_Top10byMCAP } from '../BSE_Equity_Top10byMCAP';
import { Utf8EmojisToImagesPipe } from '../Utf8EmojisToImages.pipe';
//import { analyzeAndValidateNgModules, IfStmt } from '@angular/compiler';
//import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
//import { positionElements } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-postcontrol',
  templateUrl: './postcontrol.component.html',
  styleUrls: ['./postcontrol.component.css'],
  providers: [ Utf8EmojisToImagesPipe ]
})
export class PostcontrolComponent implements OnInit {

  //@Input() 
  placeholder: string = "Share a fintwit (use $ for ₹ before ticker:eg ₹SYMBL)";

  
  @Input() code: string;

  @ViewChild("tagDiv") tagDivElement: ElementRef;

  changecharacter;
  symbols: string[] = [];
  isuserbackspace: boolean;
  isvisisblecompanylist: boolean;
  ispositionrequired: boolean;
  selectedcompany;
  selectedcompanywithLink;
  items;
  bullbearnutral = "Nutral";
  isShareButtonDisable: boolean = true;
  isPasted: boolean = false;
  charactersLeft: number = 1000;
  retweetby: string[] = [];
  likedby: string[] = [];
firstTimeCode;
intArrowleftrightchanged : number=0;
isFirstTimeCode: boolean = false;
  public focusElement: number = -1;
  downloadURLAsync;
  contentBeforeDel;
  caretPosOnSymbolType;
  value ;
  innerHTMLLength;
  innerHTMLLengthCount;
  constructor(public sharedserviceService: SharedserviceService
    ,private domSanitizer: DomSanitizer
    ,private Utf8EmojisToImages: Utf8EmojisToImagesPipe
    , public firestore: AngularFirestore
    , private toastr: ToastrService
    , public authservice: AuthenticationserviceService
  ) {
   
    
   // this.changecharacter=this.getInnerHTMLValue();
   }
  public textArea: string = '';
  public isEmojiPickerVisible: boolean;
  public isEntered: boolean;
//https://github.com/lentschi/ng-utf8-emojis-to-images
  //https://www.npmjs.com/package/ng-utf8-emojis-to-images
  //https://stackoverflow.com/questions/56935735/angular-pipe-to-replace-emoji-mart-emojis
  //https://gist.github.com/lentschi/8600f9b6ea7e7f5178f40a36f3053de7
//https://medium.com/wizdm-genesys/universal-emoji-support-in-angular-41c3a7ad60c3
//https://github.com/ahkohd/ngx-emoji-picker
//above one is smaller one but it closes on focus out
  //https://stackblitz.com/edit/angular-emojis?file=src%2Fapp%2Fapp.component.ts
  //https://stackoverflow.com/questions/51818776/how-to-make-input-field-or-textarea-with-emoji-picker-in-angular
  //https://www.npmjs.com/package/angular-emoji
  //https://www.npmjs.com/package/@ctrl/ngx-emoji-mart
  //https://vaiz10.medium.com/add-emoji-to-your-angular-application-c753e0d5cf84

//   emoji replace to downloaedd sprite images instead of unpack website in current pipe
//   https://stackoverflow.com/questions/56935735/angular-pipe-to-replace-emoji-mart-emojis
// use below last link 
//used curerntly from below
// https://gist.github.com/lentschi/8600f9b6ea7e7f5178f40a36f3053de7

  toggleEmojiPicker()
  {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;

    //event.emoji.native.focus();
  }
  onBlurEmoji()
  {
    //console.log("onBlurEmoji");
   //this.isEmojiPickerVisible = !this.isEmojiPickerVisible;

    //this.isEmojiPickerVisible = false;
    //this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value +2 , this.value+2,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);

  }
  public addEmoji(event) {
   
    //console.log(this.tagDivElement.nativeElement.innerText.length);
    var contet=this.sharedserviceService.getFocusNodeTextContent(this.tagDivElement.nativeElement, this.value, this.value);
//console.log("emoji " + contet);
let el=this.tagDivElement.nativeElement;
     //this.changecharacter = `${this.changecharacter}${event.emoji.native}`;
    //this.value= this.sharedserviceService.getSelectionDirection(el) !== 'forward' ? this.sharedserviceService.getSelectionStart(el) : this.sharedserviceService.getSelectionEnd(el);
    var firstHalf=this.tagDivElement.nativeElement.innerText.substring(0,this.value);
    var secondHalf=this.tagDivElement.nativeElement.innerText.substring(this.value);

     var prev =this.tagDivElement.nativeElement.textContent;
     this.tagDivElement.nativeElement.innerText=firstHalf +`${event.emoji.native}` + secondHalf ;
     this.isEmojiPickerVisible = false;
     this.replaceToLinks_New();
     this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value +2 , this.value+2,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);

     this.replaceToLinksOnEmoji();
   
     
     this.value=+2;
    //console.log(this.tagDivElement.nativeElement.innerText.length);
    //console.log(this.charactersLeft);
    
    this.charactersLeft=this.charactersLeft-2;
    //console.log(this.charactersLeft);
    this.fucntionForHeightShareButtonAndCharactersLeft();
    // this.tagDivElement.nativeElement.textContent=this.tagDivElement.nativeElement.textContent;
  // this.replaceToLinks_New();
  // this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value  , this.value,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);
//this.ReplaceEmojis.transform(text);
  }
  ngAfterViewInit() {
    
    if(this.code==undefined)
    {return;}
    this.firstTimeCode= "<a href=\"symbol/" + this.code + "\" title=\"" + this.code + "\">₹" + this.code + "</a>" + '&nbsp;'
    
    this.tagDivElement.nativeElement.innerHTML=this.firstTimeCode;// + '&nbsp;';
    this.isFirstTimeCode=true;
  
}
  ngOnChanges(): void {
    
   
    if(this.code==undefined)
    {return;}
   if(this.isFirstTimeCode==true)
   {
     
    this.firstTimeCode= "<a href=\"symbol/" + this.code + "\" title=\"" + this.code + "\">₹" + this.code + "</a>" + '&nbsp;';
    
    this.tagDivElement.nativeElement.innerHTML=this.firstTimeCode;// ;
  }
  }
  ngOnInit(): void {
    this.GetdownloadURL().then(d => {
    this.downloadURLAsync = d;
      //console.log(d);
    }
    );
  }
  onPaste(event)
  {
this.isPasted=true;
    //this.toastr.success('Pasted!', '');
  }
  replaceToLinksOnEmoji()
  {
    //const replacePattern5 = /(^|\B)₹(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/gim;
    //this.tagDivElement.nativeElement.innerText = this.tagDivElement.nativeElement.innerText.replace(replacePattern5, '<a href="symbol/$2" target="_blank">₹$2</a>');
    
    //(event.target as HTMLInputElement).innerHTML = document.getElementById("box").innerText.replace(replacePattern5, '<a href="symbol/$2">₹$2</a>');


  }
  replaceToLinks()
  {
    //const replacePattern5 = /(^|\B)₹(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/gim;
    //(event.target as HTMLInputElement).innerHTML = document.getElementById("box").innerText.replace(replacePattern5, '<a href="symbol/$2" target="_blank">₹$2</a>');
    
    //(event.target as HTMLInputElement).innerHTML = document.getElementById("box").innerText.replace(replacePattern5, '<a href="symbol/$2">₹$2</a>');


  }
  replaceToLinks_New()
  {
    //this.ReplaceEmojis.emojisToImages(document.getElementById("box").innerText);
    let test21=this.tagDivElement.nativeElement.innerHTML;
   // console.log(test21);
    let test22=  this.Utf8EmojisToImages.emojisToImages(test21,'twitter',12);
    //console.log(test22);
    this.tagDivElement.nativeElement.innerHTML=test22;
    const replacePattern5 = /(^|\B)₹(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/gim;
    
   // (event.target as HTMLInputElement).innerHTML = document.getElementById("box").innerText.replace(replacePattern5, '<a href="symbol/$2" target="_blank">₹$2</a>');
    this.tagDivElement.nativeElement.innerHTML=document.getElementById("box").innerHTML.replace(replacePattern5, '<a href="symbol/$2" target="_blank">₹$2</a>');
    //(event.target as HTMLInputElement).innerHTML = document.getElementById("box").innerText.replace(replacePattern5, '<a href="symbol/$2">₹$2</a>');
    
  }
  
  // document.addEventListener('keydown', event => {
  //   if (event.key === 'Enter') {
  //     document.execCommand('insertLineBreak')
  //     event.preventDefault()
  //   }
  // })
  fucntionForHeightShareButtonAndCharactersLeft()
  {
    //  console.log("innerHTML " +document.getElementById("box").innerHTML);
    // console.log("textconet " +document.getElementById("box").textContent);
    // console.log("innerText " +document.getElementById("box").innerText);
    // console.log("innerText.length " +document.getElementById("box").innerText.length);

    // console.log("innerHTML.length " +document.getElementById("box").innerHTML.length);
    // console.log("innerHTML.length " +this.tagDivElement.nativeElement.innerHTML.length);
    // console.log("innerText.length " +this.tagDivElement.nativeElement.innerText.length);


    if (document.getElementById("box").innerText.length > 0) {
      //document.getElementById("box").style.height = '150px';
      this.isShareButtonDisable = false;
    }
    else {
      //document.getElementById("box").style.height = '80px';
      this.isShareButtonDisable = true;


    }
    this.charactersLeft = 1000 - document.getElementById("box").innerText.length;
    //console.log("this.charactersLeft " +this.charactersLeft);

    if(this.charactersLeft<=0)
    {
      this.isShareButtonDisable=true;
      //need to extra text colored as twiter (set selction) 2021
      //below selection is not possible.
      //this.sharedserviceService.colorSetSelectionRange(event.target, 10 , this.value);
    }
    //below if is only for safari as empty is showinf as '<br>'
    if (document.getElementById("box").innerHTML.length == 4 && document.getElementById("box").innerHTML == '<br>') {

      this.isShareButtonDisable = true;
      this.charactersLeft = 1000;
      //document.getElementById("box").style.height = '80px';
      this.isShareButtonDisable = true;
      document.getElementById("box").innerHTML="";  
    }
    
  }
  change(event) {

    //check below this is resolved what we wanted here
    //https://medium.com/its-tinkoff/controlvalueaccessor-and-contenteditable-in-angular-6ebf50b7475e

    // console.log("innerHTML " +document.getElementById("box").innerHTML);
    // console.log("textconet " +document.getElementById("box").textContent);
    // console.log("innerText " +document.getElementById("box").innerText);
    // console.log("innerText.length " +document.getElementById("box").innerHTML.length);

    //console.log("isvisisblecompanylist " +this.isvisisblecompanylist);
    this.value= this.sharedserviceService.getSelectionDirection(event.target) !== 'forward' ? this.sharedserviceService.getSelectionStart(event.target) : this.sharedserviceService.getSelectionEnd(event.target);
  //console.log("value " +this.value);
    
    let valueinnerhtml=this.sharedserviceService.getCaretPositionWithInnerHtml(event.target);
    //console.log("this.value " +this.value);
    this.innerHTMLLength=this.sharedserviceService.getFocusNodeTextContent(event.target, this.value, this.value).split('$')[0];
   

    this.innerHTMLLengthCount=this.sharedserviceService.getfocusOffset(event.target, this.value, this.value).split('$')[0];
   



    
    
  

    
    //console.log("valueinnerhtml " + valueinnerhtml);

    if(this.isPasted==true)
    {
      const regex = /\$/g;
      
      //(event.target as HTMLInputElement).innerHTML = document.getElementById("box").innerText.replace(regex, '₹');

      document.getElementById("box").innerText = document.getElementById("box").innerText.replace(regex, '₹');

      this.replaceToLinks_New();

      //(event.target as HTMLInputElement).innerHTML = document.getElementById("box").innerText.replace('$', '₹');
      //document.getElementById("box").innerHTML=document.getElementById("box").textContent;
      event.preventDefault();
      this.sharedserviceService.setCaretToEnd(event.target, this.ispositionrequired);
      this.isPasted=false;
    }

   
    

    this.fucntionForHeightShareButtonAndCharactersLeft();
// if(this.intArrowleftrightchanged!=0 && this.isvisisblecompanylist == true)
// {
//   console.log("intArrowleftrightchanged is not 0");
//   this.ifVisisbleCompanyListisTrue();
// }else

    
    if (this.isvisisblecompanylist == true) {
      //console.log("before backspace "+this.tagDivElement.nativeElement.textContent);
      this.ifVisisbleCompanyListisTrue();
      event.preventDefault();
    this.sharedserviceService.setSelectionRange(event.target, this.value , this.value ,this.ispositionrequired,this.tagDivElement.nativeElement.innerText.length );
    
    //console.log("after backspace "+this.tagDivElement.nativeElement.textContent);

    //console.log("isuserbackspace4 set caret called"+this.isuserbackspace);
    
    }
    //console.log("this.symbols:"+this.symbols);
    if (this.isuserbackspace == true) {
      

      this.ifBackspaceIsTrue();
       
    }
    else{
      //console.log("isuserbackspace3"+this.isuserbackspace);

    

    }
  //console.log("after everyhtin");
  //console.log("ispositionrequired " + this.ispositionrequired);
  

  // this.tagDivElement.nativeElement.textContent=this.tagDivElement.nativeElement.textContent;
  // this.replaceToLinks_New();
  // this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value  , this.value,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);
// if(this.isEntered)
//  {

//   this.isEntered = !this.isEntered;
//  }
//  else
//  {
// this.tagDivElement.nativeElement.textContent=this.tagDivElement.nativeElement.textContent;
//   this.replaceToLinks_New();
//   this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value  , this.value,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);

//  }

 //let test2=this.tagDivElement.nativeElement.innerText;
 //this.changecharacter=test2;


  }
// getInnerHTMLValue(){
//observation on march 6 2021 after emoji post fail in windows 7 --key up/key down can tackle and change (on input is  not required)

//https://allenhwkim.medium.com/angular-make-div-tag-editable-37f90b35acbd
  //https://javascript.plainenglish.io/how-to-find-the-caret-inside-a-contenteditable-element-955a5ad9bf81
  //https://stackoverflow.com/questions/56320588/how-to-achieve-2-way-data-binding-on-a-divs-innerhtml-property-using-ngmodel
  //https://stackoverflow.com/questions/47788809/angular-5-domsanitizer-with-hyperlinks
  //https://medium.com/@swarnakishore/angular-safe-pipe-implementation-to-bypass-domsanitizer-stripping-out-content-c1bf0f1cc36b
  //https://stackoverflow.com/questions/54357454/prettifying-innerhtml-from-contenteditable-div
  //https://stackoverflow.com/questions/47788809/angular-5-domsanitizer-with-hyperlinks
  //https://stackoverflow.com/questions/45351434/safe-value-must-use-property-binding-after-bypass-security-with-domsanitizer
//https://angular.io/guide/security#xss
//below is from above link and angular website
//https://stackblitz.com/angular/nvpmalmeokgm?file=src%2Fapp%2Fbypass-security.component.ts
//https://stackoverflow.com/questions/48474173/safevalue-must-use-property-binding-although-im-already-using-property-bindin
//https://stackoverflow.com/questions/48474173/safevalue-must-use-property-binding-although-im-already-using-property-bindin
//https://stackoverflow.com/questions/48946330/get-cursor-position-in-a-contenteditable-div-using-innerhtml-and-pipes
//https://stackoverflow.com/questions/45632580/keyup-event-on-contenteditable-div

//   return this.domSanitizer.bypassSecurityTrustHtml(this.changecharacter);
// }
  
  ifBackspaceIsTrue() {

    // const innerhtml = this.tagDivElement.nativeElement.innerHTML;

    // if (innerhtml != '' && innerhtml.split('</a></div>').pop() == '') {

    //   this.symbols = this.symbols.filter(item => item !== this.selectedcompany);

    //   let beforeupdate = (event.target as HTMLInputElement).innerHTML;
    //   var thiscomp = this.selectedcompanywithLink;
    //   let afterupdate = beforeupdate.replace(thiscomp, ' ');
    //   (event.target as HTMLInputElement).innerHTML = afterupdate;
    //   this.isuserbackspace = false;
    // }
    // else if (innerhtml != '' && innerhtml.split('</a>').pop() == '') {
    //   this.symbols = this.symbols.filter(item => item !== this.selectedcompany);
    //   //let removethis=innerhtml.split('<a href=').pop();
    //   let removethis = innerhtml.split('<a routerLink=').pop();

    //   this.tagDivElement.nativeElement.innerHTML = innerhtml.split(removethis)[0];
    //   this.isuserbackspace = false;
    // }
    this.fucntionForHeightShareButtonAndCharactersLeft();
    this.isuserbackspace = false;

  }

  ifVisisbleCompanyListisTrue() {
   //console.log("ifVisisbleCompanyListisTrue :"+ this.isvisisblecompanylist);
    const innerHtml = (event.target as HTMLInputElement).innerHTML;
    (event.target as HTMLInputElement).innerHTML = innerHtml.replace('$', '₹');
    //console.log("2" + (event.target as HTMLInputElement).innerHTML);
    //console.log("2" + (event.target as HTMLInputElement).textContent);

    if ((event.target as HTMLInputElement).innerHTML.includes('₹') || (event.target as HTMLInputElement).innerHTML.includes('$')) 
    
    {
      //console.log("3");  
      this.ispositionrequired = true;
      var firstHalf=(event.target as HTMLInputElement).innerText.substring(0,this.value);
      var secondHalf=(event.target as HTMLInputElement).innerText.substring(this.value);

      // console.log("splits1 " + firstHalf);
      // console.log("splits2 " + secondHalf);
    let term = firstHalf.split('₹').pop().toUpperCase().trim();

      //let term = (event.target as HTMLInputElement).innerHTML.split('₹').pop().toUpperCase();
      //console.log("term1: "+term);
      // if (term.includes('</DIV>')) {
      //   term = term.split('</DIV>')[0];
      // }
     // term='';
      //console.log("term2: "+term);

      
      if (term === "" ) {
        //this.items = BSE_Equity.slice(0,10);  
        //console.log("term2: "+term);
        this.items = BSE_Equity_Top10byMCAP;
        //console.log("term3: "+this.items);
      }
      else {
        //console.log("term4: "+this.items);

        this.ispositionrequired = false;
        this.items = BSE_Equity.filter(function (tag) {
          if (tag.SecurityName.indexOf(term) == 0 || tag.SecurityId.indexOf(term) == 0)
            return true;
        });
        if (this.items.length == 0) {
          this.items = NSE_Equity.filter(function (tag) {
            if (tag.SecurityName.indexOf(term) == 0 || tag.SecurityId.indexOf(term) == 0)
              return true;
          });
        }
      }
      this.focusElement = 0;
    }
    else {
      //console.log("4");
      this.items = null;


    }

  }

  onbackspace() {

    //console.log("on backspace "+this.tagDivElement.nativeElement.textContent);
this.contentBeforeDel=this.tagDivElement.nativeElement.innerText;
    this.isuserbackspace = true;

    var checkIfSymbol=(event.target as HTMLInputElement).innerText.substring(this.value-1,this.value);
    if(checkIfSymbol==='₹')
    {
      this.isvisisblecompanylist = false;
    }
    // else{
    //   this.isvisisblecompanylist = false;


    // }

    //this.isvisisblecompanylist = false;

    //  if(this.tagDivElement.nativeElement.innerHTML.split('</a>').pop()=='')
    // {
    //   let removethis=this.tagDivElement.nativeElement.innerHTML.split('<a href=').pop();
    //   this.tagDivElement.nativeElement.innerHTML=this.tagDivElement.nativeElement.innerHTML.split(removethis)[0];
    //   this.setCaretToEnd(this.tagDivElement.nativeElement);
    // }
  }
  onkeyup(event)
  {
    //console.log("space Entered3");
  if (event.key === " " || event.code === "Space" || event.keyCode === 32 || event.which === 32) {
    //console.log("space Entered31");
   
    this.replaceToLinks_New();
   this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value  , this.value,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);
  
  
  }
  
  
  }
  onkeydown(event) {
    this.ispositionrequired = false;
    if (event.key === "Backspace" || event.code === "Backspace" || event.keyCode === 8 || event.which === 8) {
      event.target.style.height="0px";
    event.target.style.height=  (event.target.scrollHeight +25) + "px";
    
    }

    else if (event.key === "Enter" || event.code === "Enter" || event.keyCode === 13 || event.which === 13) {
      event.target.style.height="0px";
      event.target.style.height=  (event.target.scrollHeight +25) + "px";
       //console.log("Enter Entered");
    
//  document.addEventListener('keydown', event => {
//     if (event.key === 'Enter') {
//       document.execCommand('insertLineBreak')
//       event.preventDefault()
//     }
//   })

this.isEntered = !this.isEntered;

   
      if (this.isvisisblecompanylist) {
        //console.log("this.focusElement"+this.focusElement);
        
        
        this.getSelectedCompany(this.items[this.focusElement]?.SecurityId);
        
        
      }
      else
      {
        this.isuserbackspace = false;
        document.execCommand('insertLineBreak')
        event.preventDefault()
        this.isvisisblecompanylist = false;
  
      }
      
      this.tagDivElement.nativeElement.scrollTop = this.tagDivElement.nativeElement.scrollHeight;
      
          
    }
    else if (event.key === " " || event.code === "Space" || event.keyCode === 32 || event.which === 32) {
      //console.log("space Entered");
      this.isvisisblecompanylist = false;
      this.isuserbackspace = false;
      //this.replaceToLinks_New();
     //this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value  , this.value,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);


    }
    else if (event.key === "$" || event.key === "₹") {
      //this.sharedserviceService.setRange(event.target, this.range);
      //console.log("$ Entered");
      this.isvisisblecompanylist = true;
      this.isuserbackspace = false;
this.caretPosOnSymbolType=this.value;
    }
    else if(event.keyCode===37 || event.which===37)

    {
//console.log("Left arrowpressed");
//console.log((event.target as HTMLInputElement).innerHTML);
//console.log((event.target as HTMLInputElement).selectionStart);
//console.log(event.selectionStart);
//event.preventDefault();
//this.sharedserviceService.setCaretToEnd(event.target, this.ispositionrequired);
//var val = event.value;
//console.log(val);

//console.log(val.slice(0, event.selectionStart).length);
this.intArrowleftrightchanged--;
//console.log("Left arrowpressed " + this.intArrowleftrightchanged);

}
    else if(event.keyCode===39 || event.which===39)

    {
//console.log("Right arrowpressed");
this.intArrowleftrightchanged++;
//console.log("Right arrowpressed " + this.intArrowleftrightchanged);

    }
  }
  getSelectedCompany(m) {
    this.isuserbackspace = false;
//console.log("selectedcompany " +m);

    if(m==undefined)
    {
      this.isvisisblecompanylist = false
      return;
    } 
    var el = this.tagDivElement.nativeElement;

    this.selectedcompany = m;
    this.symbols.push(this.selectedcompany);

    //this.selectedcompanywithLink = "<a href=\"symbol/" + this.selectedcompany + "\" title=\"" + this.selectedcompany + "\">₹" + this.selectedcompany + "</a>";
    
  // console.log("this.value "+ this.value);
  //  console.log("this.el.innerText "+ el.innerText);
  // console.log("this.el.innerHTML "+ el.innerHTML);
  // console.log("this.HTMLInputElement.innerText "+ (event.target as HTMLInputElement).innerText);
  // console.log("this.HTMLInputElement.innerHTML "+ (event.target as HTMLInputElement).innerHTML);


    //var firstHalf=(event.target as HTMLInputElement).innerText.substring(0,this.value);
    //var secondHalf=(event.target as HTMLInputElement).innerText.substring(this.value );
    var firstHalf=el.innerText.substring(0,this.value);
    var secondHalf=el.innerText.substring(this.value );
    
    // console.log("splits12 " + firstHalf);
    // console.log("splits22 " + secondHalf);
 
 //el.innerHTML = oldtext.substring(0, oldtext.lastIndexOf('₹')) + this.selectedcompanywithLink + '&nbsp;';
   
    var ifTyped= firstHalf.substring(0, firstHalf.lastIndexOf('₹')+1) ;
//this.selectedcompany=this.selectedcompany+' ';
    this.tagDivElement.nativeElement.innerText=ifTyped +  this.selectedcompany + '\u00a0' + secondHalf;

 

    this.isvisisblecompanylist = false
  
    //imp-check below uses innerText
    this.replaceToLinks();
//   console.log("this.value "+ this.value);
// console.log("this.ispositionrequired"+this.ispositionrequired);
this.tagDivElement.nativeElement.innerText=this.tagDivElement.nativeElement.innerText;
this.replaceToLinks_New();
    this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value+this.selectedcompany.length +1, this.value +this.selectedcompany.length +1 ,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);
    //this.sharedserviceService.setSelectionRange(this.tagDivElement.nativeElement, this.value +2 , this.value+2,this.ispositionrequired, this.tagDivElement.nativeElement.innerText.length);

    //console.log("this.value "+ this.value);
  //this.value+=this.selectedcompany.length;
  //console.log("this.value "+ this.value);
  event.preventDefault();
  this.isvisisblecompanylist = false;
  }
  getSelectedCompany1(m) {
    if(m==undefined)
    {
      this.isvisisblecompanylist = false
      return;
    }
    //var el = document.getElementById("box");
    var el = this.tagDivElement.nativeElement;
    this.selectedcompany = m;
    this.symbols.push(this.selectedcompany);
    let oldtext = el.innerHTML;
    var contet=this.sharedserviceService.getFocusNodeTextContent(event.target, this.value, this.value);
    // console.log("getSelectedCompany  contet" +contet);
    // console.log("getSelectedCompany innerHTMLLength " +this.innerHTMLLength);
    // console.log("getSelectedCompany currentinnerhtml " +el.innerHTML);
 var indexof= oldtext.indexOf(this.innerHTMLLength);
//  if(indexof >0)
//  {
//   var firstHalf=(event.target as HTMLInputElement).innerHTML.substring(0,indexof+4+this.selectedcompany.length);
//   var secondHalf=(event.target as HTMLInputElement).innerHTML.substring(indexof +5 +this.selectedcompany.length  );
//  }
//  else

//  {
//   var firstHalf=(event.target as HTMLInputElement).innerText.substring(0,this.value);
//   var secondHalf=(event.target as HTMLInputElement).innerText.substring(this.value +1  );
//  }
 //console.log("indexof " +indexof);
    //this.selectedcompanywithLink = "<a href=\"symbol/" + this.selectedcompany + "\" title=\"" + this.selectedcompany + "\">₹" + this.selectedcompany + "</a>";
    
    //el.textContent.substring(0,this.caretPosOnSymbolType);

    var firstHalf=(event.target as HTMLInputElement).innerText.substring(0,this.value);
    var secondHalf=(event.target as HTMLInputElement).innerText.substring(this.value +1  );
  //console.log("getSelectedCompany " +  this.innerHTMLLength);
    //var firstHalf=oldtext.substring(0,this.innerHTMLLength);
      //var secondHalf=oldtext.substring(this.innerHTMLLength +1  );
   

   var cut=this.innerHTMLLength;
   //console.log("cut " +this.innerHTMLLength);
 
 //el.innerHTML = oldtext.substring(0, oldtext.lastIndexOf('₹')) + this.selectedcompanywithLink + '&nbsp;';
    //console.log("el.innerHTML 2" +el.innerHTML);
    //var firstHalf=cut.substring(0,this.innerHTMLLengthCount);
    //var secondHalf=cut.substring(this.innerHTMLLengthCount+1);
 //console.log("firstHalf " +firstHalf);
   // console.log("secondHalf " +secondHalf);

//var replacetest=    firstHalf + this.selectedcompany + secondHalf;
//console.log("replacetest " +replacetest);

     //this.sharedserviceService.replaceSelectedText(replacetest);
    var ifTyped= firstHalf.substring(0, firstHalf.lastIndexOf('₹')+1) ;
    el.innerHTML=ifTyped +  this.selectedcompany + '&nbsp;' + secondHalf;
    //console.log("el.innerHTML 2" +el.innerHTML);

    this.isvisisblecompanylist = false
    // this.calltofocus();
    //this.setCaretToEnd(el);
    //console.log("el.innerHTML.length " +el.innerHTML.length);
    //console.log("el.innerHtml.length " +el.innerHtml.length);

    //console.log("el.innerText.length " +el.innerText.length);
    //console.log("this.value " +this.value);
    //imp-check below uses innerText
    this.replaceToLinks();
    this.sharedserviceService.setSelectionRange(event.target, this.value+this.selectedcompany.length , this.value +this.selectedcompany.length,this.ispositionrequired, el.innerText.length);
   
    //this.sharedserviceService.replaceSelectedText(replacetest);

    //this.sharedserviceService.setCaretToEnd(el, this.ispositionrequired);

    // this.tagDivElement.nativeElement.focus();
    // el.focus();
    // console.log(el.innerHTML);
    // this.setCaretToEnd(el);
  }
  onLocationBlur() {
    this.focusElement = -1;
    //this.isEmojiPickerVisible = !this.isEmojiPickerVisible;

  }
  onArrowUp() {
    if (this.focusElement > 0) {
      this.focusElement--;

    }
  }

  onArrowDown() {
    if (this.isvisisblecompanylist) {
    
      if (this.focusElement <= this.items.length - 2) {
      this.focusElement++;
    }
  }
  }
  onfocusout() {
    this.items = null;
    this.value= this.sharedserviceService.getSelectionDirection(event.target) !== 'forward' ? this.sharedserviceService.getSelectionStart(event.target) : this.sharedserviceService.getSelectionEnd(event.target);
    //this.isEmojiPickerVisible = false;
    //this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }
  onmouseoverChange(i) {
    this.focusElement = i;
  }

  async GetdownloadURL() {
    try {
      return await this.authservice.getimageURl().toPromise();

    } catch (error) {
      return "";
    }

  }
  AddMedia($event)
  {
    this.toastr.success('Coming soon', '');
  }
  post() {
    if(this.charactersLeft<=0)
    {
      return;
    }
   // var re = /(<title\b[^>]*>)[^<>]*(<\/title>)/i;
   // var find = /(<a\b[^>]*>)[^<>]*(<\/a>)/i;
//var rep=/(?<=<a.*>).+(?=<\/a>)/g;
  //important - replace all tags to only content just $INFY not all a tag -2021
    var find=/<(.|\n)*?>/gim;
    //console.log("post "+this.tagDivElement.nativeElement.innerText);

    document.getElementById("box").innerText = document.getElementById("box").innerText.replace(find, "");
    //document.getElementById("box").innerHTML = document.getElementById("box").innerHTML.replace(find, "");
    
    let postContent1 = document.getElementById("box").innerText.replace('href', 'routerLink');
    //console.log("post "+this.tagDivElement.nativeElement.innerText);
    //console.log("post innerHTML"+this.tagDivElement.nativeElement.innerHTML);

    //console.log("post "+document.getElementById("box").innerText);
let postContent=this.tagDivElement.nativeElement.innerHTML;

    //console.log(postContent);
    const postsCollection = this.firestore.collection('/Posts');
    postsCollection.add(
      {
        bullbearnutral: this.bullbearnutral,
        retweetby: this.retweetby,
        retweetcount: 0,
        likedby: this.likedby,
        likedcount: 0,
        symbolcount: this.symbols.length,
        symbols: this.symbols,
        useruid: this.authservice.authuser.uid,
        emailid: this.authservice.authuser.email,
        displayName: this.authservice.authuser.displayName,
        post: postContent,//this.changecharacter,
        createddate: new Date(),
        isdeleted: this.authservice.authuser.email=="dhan.tracker@gmail.com"? true :false,
        dpurl: this.downloadURLAsync
        //dpurl:"https://firebasestorage.googleapis.com/v0/b/desifintwit.appspot.com/o/desifintwit%2F"+this.authservice.authuser.uid+"?alt=media&token="+this.authservice.authuser.getIdToken()

      });
    this.changecharacter = '';
    this.tagDivElement.nativeElement.innerText='';
    this.charactersLeft = 1000;
    document.getElementById("box").style.height = '80px';
    this.toastr.success('Thank you for sharing!', '');
  }

  //not sure if i am using this
  @HostListener("click", ['$event'])
  onClick(event: MouseEvent) {
    this.sharedserviceService.hostlistnerNavigateToSymbol(event);
  }
  // getUpdatedContent(event: KeyboardEvent)
  // {
  //   event.preventDefault();
  //   const innerHtml =  (event.target as HTMLInputElement).innerHTML;
  //   (event.target as HTMLInputElement).innerHTML = innerHtml.replace(/foo/i, '<a href="www.bar.com" title="Description">bar</a>');

  //   //(event.target as HTMLInputElement).innerHTML = innerHtml.replace(/foo/i, '<a href="www.bar.com" title="Description">bar</a>');
  //   this.setCaretToEnd(event.target);
  // }


  // onLocationFocus() {
  //   this.items = BSE_Equity.slice(0,10);
  //   this.focusElement = 0;

  // }

  // onEnter()
  //   {
  //     console.log(this.items[this.focusElement].SecurityId);
  //     //this.router.navigate(['/symbol']);
  //    // this.items=null;
  //     //this.changecharacter=null;
  //     this.getSelectedCompany(this.items[this.focusElement].SecurityId)
  //   }



  // //not used
  // @ViewChild("tagcompanylist") tagcompanylistElement: ElementRef;

  // //not used
  // calltofocus()
  // {
  // this.tagDivElement.nativeElement.focus();

  // }

}
